<template>
    <pendo-card title="Scheduling">
        <template slot="headerRight">
            <pendo-button
                v-if="!isEditing"
                type="link"
                prefix-icon="edit-2"
                label="Edit"
                @click="toggleEditing(true)" />
            <pendo-button
                v-if="isEditing"
                theme="via"
                type="tertiary"
                label="Cancel"
                @click="toggleEditing(false)" />
            <pendo-button
                v-if="isEditing"
                :disabled="!isDateValid"
                theme="via"
                type="primary"
                size="mini"
                label="Save"
                @click="updateGuide" />
        </template>
        <template slot="body">
            <div
                v-if="!isEditing"
                class="schedule-info">
                <div class="schedule-info--date">
                    {{ primaryDate }}
                </div>
                <div
                    v-if="primaryTime"
                    class="schedule-info--time">
                    {{ primaryTime }}
                </div>
                <div
                    v-if="scheduleText"
                    class="schedule-info--text">
                    {{ scheduleText }}
                </div>
            </div>
            <div
                v-if="isEditing"
                class="schedule-edit">
                <div
                    v-if="!isDateValid"
                    class="schedule-edit-row">
                    <pendo-alert
                        :center="true"
                        title="Start date must come before expiration date."
                        type="error" />
                </div>
                <div class="schedule-edit--row">
                    <div class="schedule-edit--row--body">
                        <pendo-radio-group
                            :value="selectedFrequency"
                            :options="frequencyOptions"
                            :labels="{ top: 'Delivery Frequency' }"
                            class="schedule-edit--frequency-chooser"
                            direction="vertical"
                            @change="toggleFrequency" />
                        <div
                            v-if="selectedFrequency === 'customInterval'"
                            class="frequency--interval">
                            <pendo-input-number
                                v-model="frequencyCount"
                                :always-show-arrows="true"
                                :max="365"
                                input-width="80px"
                                class="frequency--interval--count" />
                            <span>Day(s)</span>
                        </div>
                        <span
                            v-if="selectedFrequency === 'oneTimeOnly'"
                            class="frequency--onetime-warning">
                            This guide will show until the user dismisses the guide.
                        </span>
                    </div>
                </div>
                <div class="schedule-edit--row">
                    <div
                        class="schedule-edit--row--body schedule-edit--date-time"
                        data-cy="edit-showsafter">
                        <pendo-multiselect
                            :value="startDateTime"
                            :options="[
                                {
                                    label: 'Immediately',
                                    value: false
                                },
                                {
                                    label: 'Start On',
                                    value: true
                                }
                            ]"
                            :popper-options="{ class: 'schedule-edit--start-panel' }"
                            full-width
                            value-key="value"
                            class="schedule-edit--start"
                            @select="toggleShowsAfterUpdated">
                            <template slot="topLabel">
                                Start Date / Time
                                <pendo-icon
                                    v-pendo-tooltip="{ arrow: true, content: tooltipText, multiline: true }"
                                    size="14"
                                    display="inline"
                                    type="info" />
                            </template>
                        </pendo-multiselect>
                        <pendo-date-picker
                            v-model="showsAfter"
                            :disabled="!hasShowsAfter"
                            min-trigger-width="100%"
                            placeholder="--"
                            class="schedule-edit--date" />
                        <pendo-time-picker
                            v-model="showsAfterTime"
                            :disabled="!hasShowsAfter"
                            placeholder="--"
                            class="schedule-edit--time" />
                    </div>
                </div>
                <div class="schedule-edit--row">
                    <div
                        class="schedule-edit--row--body schedule-edit--date-time"
                        data-cy="edit-expiresat">
                        <pendo-multiselect
                            :labels="{ top: 'Expiration Date / Time' }"
                            :value="expirationDateTime"
                            :options="[
                                {
                                    label: 'Never',
                                    value: false
                                },
                                {
                                    label: 'Expire On',
                                    value: true
                                }
                            ]"
                            :popper-options="{ class: 'schedule-edit--expire-panel' }"
                            full-width
                            value-key="value"
                            class="schedule-edit--expire"
                            @select="toggleExpiresAfterUpdated" />
                        <pendo-date-picker
                            v-model="expiresAfter"
                            :disabled="!hasExpiresAfter"
                            min-trigger-width="100%"
                            placeholder="--"
                            class="schedule-edit--date" />
                        <pendo-time-picker
                            v-model="expiresAfterTime"
                            :disabled="!hasExpiresAfter"
                            placeholder="--"
                            class="schedule-edit--time" />
                    </div>
                </div>
            </div>
        </template>
        <div
            v-if="!isEditing"
            slot="footer"
            class="schedule-footer">
            <div class="delivery-frequency">
                <div class="delivery-frequency--label">
                    Delivery Frequency
                </div>
                {{ fullFrequency }}
            </div>
            <div class="start-date">
                <div class="start-date--label">
                    Start Date / Time
                </div>
                {{ fullStart }}
            </div>
            <div class="expiration-date">
                <div class="expiration-date--label">
                    Expiration Date / Time
                </div>
                {{ fullExpire }}
            </div>
        </div>
    </pendo-card>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import {
    PendoAlert,
    PendoButton,
    PendoCard,
    PendoDatePicker,
    PendoIcon,
    PendoInputNumber,
    PendoRadioGroup,
    PendoTimePicker,
    PendoMultiselect,
    PendoLoading,
    PendoTooltip
} from '@pendo/components';
import { convertToSubscriptionTimezone, splitTimeParts, DATE_FORMAT } from '@/utils/moment';

const ONE_DAY = 1000 * 60 * 60 * 24;

export default {
    name: 'GuideSchedule',
    components: {
        PendoAlert,
        PendoButton,
        PendoCard,
        PendoDatePicker,
        PendoIcon,
        PendoInputNumber,
        PendoRadioGroup,
        PendoTimePicker,
        PendoMultiselect
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    data () {
        return {
            isEditing: false,
            hasExpiresAfter: false,
            hasShowsAfter: false,
            frequencyOptions: [
                {
                    value: 'oneTimeOnly',
                    label: 'One Time Only'
                },
                {
                    value: 'customInterval',
                    label: 'Custom Interval'
                }
            ]
        };
    },
    computed: {
        ...mapState({
            local: (state) => state.guides.local
        }),
        ...mapGetters({
            guide: 'guides/active'
        }),
        startDateTime () {
            if (this.hasShowsAfter) {
                return {
                    label: 'Start On',
                    value: true
                };
            }

            return {
                label: 'Immediately',
                value: false
            };
        },
        expirationDateTime () {
            if (this.hasExpiresAfter) {
                return {
                    label: 'Expire On',
                    value: true
                };
            }

            return {
                label: 'Never',
                value: false
            };
        },
        primaryDate () {
            if (!this.primaryDisplayProp) {
                return '---';
            }

            return convertToSubscriptionTimezone(this.guide[this.primaryDisplayProp]).format(DATE_FORMAT.short);
        },
        primaryTime () {
            if (!this.primaryDisplayProp) {
                return '';
            }

            return convertToSubscriptionTimezone(this.guide[this.primaryDisplayProp]).format(DATE_FORMAT.time);
        },
        scheduleText () {
            let text;

            switch (this.primaryDisplayProp) {
                case 'showsAfter':
                    text = 'start date';
                    break;
                case 'expiresAfter':
                    text = 'expiration date';
                    break;
                default:
                    text = '';
            }

            return text;
        },
        primaryDisplayProp () {
            const { expiresAfter, showsAfter } = this.guide;
            const hasExpDate = !!expiresAfter;
            const hasStartDate = !!showsAfter;
            let type;

            if (hasExpDate && hasStartDate) {
                const hasStarted = convertToSubscriptionTimezone(showsAfter).isBefore(Date.now());
                if (hasStarted) {
                    type = 'expiresAfter';
                } else {
                    type = 'showsAfter';
                }
            } else if (hasExpDate) {
                type = 'expiresAfter';
            } else if (hasStartDate) {
                type = 'showsAfter';
            } else {
                type = '';
            }

            return type;
        },
        fullStart () {
            return this.formatFullDate(this.guide.showsAfter) || 'Immediately';
        },
        fullExpire () {
            return this.formatFullDate(this.guide.expiresAfter) || 'Never';
        },
        fullFrequency () {
            if (this.selectedFrequency === 'oneTimeOnly') {
                return 'One Time Only';
            }

            return `Every ${this.frequencyCount} Day(s)`;
        },
        isDateValid () {
            const { hasExpiresAfter, hasShowsAfter } = this;

            return (
                !hasExpiresAfter ||
                !hasShowsAfter ||
                convertToSubscriptionTimezone(this.showsAfter).isBefore(this.expiresAfter)
            );
        },
        selectedFrequency () {
            // guide.recurrence == 0, undefined, or null means `oneTimeOnly`
            return this.getLocalGuideProp('recurrence') ? 'customInterval' : 'oneTimeOnly';
        },
        tooltipText () {
            let text;

            if (this.guide.state === 'public') {
                text = 'The Guide is currently PUBLIC. Changing these settings will impact guide delivery.';
            } else {
                text = 'This guide will appear for users as soon as it is set to PUBLIC status.';
            }

            return text;
        },
        // getters/setters using state.guides.local so we can track changes in vuex without
        // changing the "source of truth" guide that should match backend
        frequencyCount: {
            get () {
                const recurrence = this.getLocalGuideProp('recurrence');

                return recurrence / ONE_DAY;
            },
            set (days) {
                this.setLocal({ prop: 'recurrence', value: days * ONE_DAY });
            }
        },
        showsAfter: {
            get () {
                return this.getLocalGuideProp('showsAfter');
            },
            set (showsAfter) {
                if (!showsAfter) {
                    this.setLocal({ prop: 'showsAfter', value: null });

                    return;
                }

                const next = this.mergeDateAndTime(showsAfter, this.showsAfterTime);

                this.setLocal({ prop: 'showsAfter', value: next });
            }
        },
        showsAfterTime: {
            get () {
                const showsAfter = this.getLocalGuideProp('showsAfter');
                if (!showsAfter) {
                    return null;
                }

                return convertToSubscriptionTimezone(showsAfter).format(DATE_FORMAT.time);
            },
            set (time) {
                if (!time) {
                    this.setLocal({ prop: 'showsAfter', value: null });

                    return;
                }

                const showsAfter = this.mergeDateAndTime(this.showsAfter, time);

                this.setLocal({ prop: 'showsAfter', value: showsAfter });
            }
        },
        expiresAfter: {
            get () {
                return this.getLocalGuideProp('expiresAfter');
            },
            set (expiresAfter) {
                if (!expiresAfter) {
                    this.setLocal({ prop: 'expiresAfter', value: null });

                    return;
                }

                const next = this.mergeDateAndTime(expiresAfter, this.expiresAfterTime);

                this.setLocal({ prop: 'expiresAfter', value: next });
            }
        },
        expiresAfterTime: {
            get () {
                const expiresAfter = this.getLocalGuideProp('expiresAfter');
                if (!expiresAfter) {
                    return null;
                }

                return convertToSubscriptionTimezone(expiresAfter).format(DATE_FORMAT.time);
            },
            set (time) {
                if (!time) {
                    this.setLocal({ prop: 'expiresAfter', value: null });

                    return;
                }

                const expiresAfter = this.mergeDateAndTime(this.expiresAfter, time);

                this.setLocal({ prop: 'expiresAfter', value: expiresAfter });
            }
        }
    },
    methods: {
        ...mapMutations({
            setLocal: 'guides/setLocal'
        }),
        ...mapActions({
            patch: 'guides/patch'
        }),
        formatFullDate (timestamp) {
            if (!timestamp) {
                return '';
            }

            return convertToSubscriptionTimezone(timestamp).format(DATE_FORMAT.full);
        },
        toggleEditing (isEditing) {
            if (isEditing) {
                this.hasExpiresAfter = !!this.getLocalGuideProp('expiresAfter');
                this.hasShowsAfter = !!this.getLocalGuideProp('showsAfter');
            }
            this.setLocal({ reset: true });
            this.isEditing = isEditing;
        },
        async updateGuide () {
            const props = {
                expiresAfter: this.expiresAfter,
                showsAfter: this.showsAfter,
                recurrence: this.getLocalGuideProp('recurrence') || 0
            };
            const isGuideScheduled = this.guide.showsAfter && this.guide.showsAfter > Date.now();

            // if a guide is scheduled to display in the future but the start date is removed
            // set the guide to `draft` to prevent it from becoming public immediately
            if (isGuideScheduled && !props.showsAfter) {
                props.state = 'draft';
            }

            await this.patch({
                props,
                guideId: this.guide.id
            });
            this.toggleEditing(false);
        },
        toggleExpiresAfterUpdated ({ value }) {
            this.hasExpiresAfter = value;
            if (!value) {
                this.expiresAfter = null;
            } else {
                const add14days = convertToSubscriptionTimezone(Date.now())
                    .add(14, 'days')
                    .valueOf();
                this.setLocal({ prop: 'expiresAfter', value: add14days });
            }
        },
        toggleShowsAfterUpdated ({ value }) {
            this.hasShowsAfter = value;
            if (!value) {
                this.showsAfter = null;
            } else {
                this.setLocal({ prop: 'showsAfter', value: Date.now() });
            }
        },
        getLocalGuideProp (prop) {
            if (typeof this.local[prop] === 'undefined') {
                return this.guide[prop];
            }

            return this.local[prop];
        },
        // we need to set the date to have a time of 12:00pm to avoid timezone weirdness when the
        // user is located out of the subscription timezone
        mergeDateAndTime (date, time) {
            const { hours, minutes } = splitTimeParts(time);
            let noonDate = date;

            if (!(date instanceof Date)) {
                noonDate = new Date(date);
            }

            noonDate = new Date(noonDate.setHours(12, 0, 0, 0));

            // dates need to run through `convertToSubscriptionTimezone` to be translated to sub timezone
            return convertToSubscriptionTimezone(noonDate.valueOf())
                .hours(hours)
                .minutes(minutes)
                .seconds(0)
                .valueOf();
        },
        toggleFrequency (val) {
            const value = val === 'oneTimeOnly' ? 0 : this.getLocalGuideProp('recurrence') || ONE_DAY;

            this.setLocal({ prop: 'recurrence', value });
        }
    }
};
</script>

<style lang="scss">
.guide-details--schedule {
    .pendo-card__body {
        display: grid;
        grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
    }

    .pendo-card__footer {
        max-height: 155px;
    }

    .schedule-info {
        display: grid;
        justify-items: center;
        align-self: center;
        grid-gap: 0.5rem;

        &--date {
            font-size: 2em;
            line-height: 1em;
        }

        &--time,
        &--text {
            line-height: 1em;
            color: $gray-lighter-2;
        }

        &--time {
            font-size: 1.25em;
        }

        &--text {
            font-size: 0.875em;
            text-transform: uppercase;
        }
    }

    .schedule-edit {
        display: grid;
        grid-gap: 0.5em;
        grid-template-rows: max-content;

        &--row {
            margin-bottom: 1em;

            &--header {
                margin-bottom: 0.5em;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;

                .pendo-icon {
                    margin-left: 0.5em;

                    > svg {
                        stroke: $gray-lighter-4;
                    }
                }
            }

            &--body {
                display: grid;
            }
        }

        &--date-time {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
            align-items: end;
            grid-gap: 8px;
        }

        &--start {
            .pendo-multiselect__label--top {
                grid-auto-flow: column;
                grid-template-columns: repeat(2, max-content);
                grid-gap: 8px;
            }
        }

        &--date > div > span {
            width: 100%;
        }

        .shows-after-toggle,
        .expires-after-toggle {
            position: relative;
            justify-content: flex-start;

            .icon-suffix {
                position: absolute;
                right: 8px;
            }
        }

        &--frequency-chooser {
            margin-bottom: 1em;
        }

        .frequency--interval,
        .frequency--onetime-warning {
            margin-bottom: 1em;
        }

        .frequency--onetime-warning {
            margin-bottom: 1em;
            color: $gray-lighter-3;
            font-size: 0.875em;
            line-height: 1.25em;
        }

        .frequency--interval {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            margin-bottom: 0;

            &--count {
                margin-right: 0.5em;
            }
        }

        @media (max-width: 600px) {
            &--toggle,
            &--date,
            &--time {
                width: 100%;

                &:last-of-type {
                    margin-bottom: 0;
                }

                button,
                .pendo-date-picker,
                .pendo-time-picker,
                .el-date-editor,
                .el-input,
                .el-select .el-input__inner,
                .el-dropdown {
                    width: 100%;
                }

                .el-select {
                    width: 100%;
                    max-width: unset;
                }
            }
        }
    }

    .schedule-footer {
        .delivery-frequency,
        .start-date,
        .expiration-date {
            color: $gray-lighter-2;
            line-height: 1.5em;
            padding-bottom: 0.5em;

            &--label {
                font-weight: 700;
                color: $gray-primary;
            }
        }
    }
}
</style>
