<template>
    <div class="edit-extension-domain-patterns">
        <div class="extension-domain-patterns-list">
            {{ domainPatternsListString }}
        </div>
        <pendo-button
            id="edit-extension-domain-patterns-button"
            theme="via"
            type="link"
            label="Edit"
            @click="editDomainPatterns" />
        <pendo-add-update-extension-app-modal
            :visible="showEditModal"
            theme="via"
            :create-mode="false"
            :app="app"
            :saving="saving"
            :submission-error="submissionError"
            @update="updateDomainPatterns"
            @close="closeEditModal" />
    </div>
</template>
<script>
import { PendoButton, PendoAddUpdateExtensionAppModal } from '@pendo/components';
import { mapActions } from 'vuex';
import get from 'lodash/get';

export default {
    name: 'EditExtensionDomainPatterns',
    components: {
        PendoButton,
        PendoAddUpdateExtensionAppModal
    },
    props: {
        app: {
            required: true,
            type: Object
        }
    },
    data () {
        return {
            showEditModal: false,
            submissionError: null,
            saving: false
        };
    },
    computed: {
        domainPatterns () {
            return this.app.extensionDomainPatterns ? [...this.app.extensionDomainPatterns] : [];
        },
        domainPatternsListString () {
            return this.domainPatterns.length ? this.domainPatterns.join(', ') : 'No websites configured.';
        }
    },
    methods: {
        ...mapActions({
            updateExtensionDomainPatterns: 'apps/updateExtensionDomainPatterns'
        }),
        editDomainPatterns () {
            this.showEditModal = true;
        },
        closeEditModal () {
            this.showEditModal = false;
        },
        async updateDomainPatterns (domainPatterns) {
            this.submissionError = null;
            this.saving = true;
            try {
                await this.updateExtensionDomainPatterns({
                    id: this.app.id,
                    propertyName: 'extensionDomainPatterns',
                    propertyValue: domainPatterns
                });
                this.closeEditModal();
            } catch (error) {
                this.submissionError = {};
                this.submissionError.errorMessage = get(error, 'data.error', 'Unable to update websites at this time.');
                this.submissionError.duplicatePatternErrors = get(error, 'data.duplicates', null);
                this.submissionError.invalidPatternErrors = get(error, 'data.invalidPatterns', null);
            } finally {
                this.saving = false;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.extension-domain-patterns-list {
    line-height: 1.5;
}

#edit-extension-domain-patterns-button {
    height: auto;
    line-height: 1.5;
}
</style>
