import Vue from 'vue';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import http from '@/utils/http';

export function getInitialState () {
    return {
        map: {},
        errors: {},
        isFetching: false,
        hasFetchError: false
    };
}

export const state = getInitialState();

export const mutations = {
    setMap (state, { map }) {
        state.map = map;
    },
    setMapAtKey (state, { key }) {
        Vue.set(state.map, key.id, key);
    },
    deleteMapAtKey (state, { key }) {
        Vue.delete(state.map, key.id, key);
    },
    setIsFetching (state, { isFetching }) {
        state.isFetching = isFetching;
    },
    setFetchError (state, { hasFetchError }) {
        state.hasFetchError = hasFetchError;
    },
    reset (state) {
        Object.assign(state, getInitialState());
    }
};

export const actions = {
    async loadAll ({ commit, state }, { noCache = false } = {}) {
        if (!isEmpty(state.map) && !noCache) {
            return;
        }

        commit('setIsFetching', { isFetching: true });

        try {
            const keys = await fetchKeys();
            commit('setMap', { map: keyBy(keys, 'id') });
        } catch (err) {
            commit('setFetchError', { hasFetchError: true });
        }

        commit('setIsFetching', { isFetching: false });
    },
    async create ({ commit }, { createOptions } = {}) {
        try {
            const key = await createKey(createOptions);
            commit('setMapAtKey', { key });
        } catch (err) {
            throw err;
        }
    },
    async update ({ commit }, { key }) {
        try {
            const updatedKey = await updateKey(key);
            commit('setMapAtKey', { key: updatedKey });
        } catch (err) {
            throw err;
        }
    },
    async delete ({ commit }, { key }) {
        try {
            await deleteKey(key);
            commit('deleteMapAtKey', { key });
        } catch (err) {
            throw err;
        }
    }
};

export const getters = {
    list (state) {
        return Object.values(state.map);
    },
    listForActiveAccount (state, getters, rootState, rootGetters) {
        const activeAccount = rootGetters['subscriptions/activeAccount'];

        return getters.list.filter((key) => key.accountRestriction === activeAccount.id);
    }
};

function fetchKeys () {
    return http.get('/api/s/_SID_/integrationkey').then((res) => res.data);
}

function createKey (createOptions) {
    return http
        .post('/api/s/_SID_/integrationkey', {
            label: get(createOptions, 'label', ''),
            accessFlags: get(createOptions, 'accessFlags', {})
        })
        .then((res) => res.data);
}

function updateKey (key) {
    return http.put(`/api/s/_SID_/integrationkey/${key.id}`, key).then((res) => res.data);
}

function deleteKey (key) {
    return http.delete(`/api/s/_SID_/integrationkey/${key.id}`);
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
