<template>
    <div class="settings--user-settings">
        <pendo-card title="Information">
            <pendo-editable-content
                v-if="!_activeUsesV2Adopt"
                :value="email"
                :validation-rules="rules.email"
                :before-exit="updateEmail"
                label="Email">
                <template
                    slot="append"
                    slot-scope="{ confirm, cancel, pending }">
                    <pendo-button
                        :loading="pending"
                        theme="via"
                        icon="check"
                        type="primary"
                        @click="confirm" />
                    <pendo-button
                        theme="via"
                        type="tertiary"
                        label="Cancel"
                        @click="cancel" />
                </template>
            </pendo-editable-content>
            <div
                v-if="_activeUsesV2Adopt"
                class="pendo-editable-content has-label">
                <div class="pendo-editable-content__label pendo-editable-content__label--top">
                    Email
                </div>
                <div>
                    {{ email }}
                </div>
            </div>
            <pendo-editable-content
                :value="firstname"
                :before-exit="updateFirstName"
                label="First Name">
                <template
                    slot="append"
                    slot-scope="{ confirm, cancel, pending }">
                    <pendo-button
                        :loading="pending"
                        theme="via"
                        icon="check"
                        type="primary"
                        @click="confirm" />
                    <pendo-button
                        theme="via"
                        type="tertiary"
                        label="Cancel"
                        @click="cancel" />
                </template>
            </pendo-editable-content>
            <pendo-editable-content
                :value="lastname"
                :before-exit="updateLastName"
                label="Last Name">
                <template
                    slot="append"
                    slot-scope="{ confirm, cancel, pending }">
                    <pendo-button
                        :loading="pending"
                        theme="via"
                        icon="check"
                        type="primary"
                        @click="confirm" />
                    <pendo-button
                        theme="via"
                        type="tertiary"
                        label="Cancel"
                        @click="cancel" />
                </template>
            </pendo-editable-content>
            <div class="pendo-editable-content has-label">
                <div class="pendo-editable-content__label pendo-editable-content__label--top">
                    Password
                </div>
                <div
                    class="pendo-editable-content__text"
                    @click="openChangePasswordModal">
                    <span>Click to Change</span>
                </div>
            </div>
        </pendo-card>
    </div>
</template>

<script>
import get from 'lodash/get';
import { mapActions, mapState, mapGetters } from 'vuex';
import { PendoCard, PendoEditableContent, PendoButton } from '@pendo/components';

export default {
    name: 'UserProfile',
    components: {
        PendoCard,
        PendoEditableContent,
        PendoButton
    },
    data () {
        return {
            updateSettingsError: 'Unable to update setting.',
            rules: {
                email: [
                    { type: 'string', trigger: ['blur', 'change'] },
                    { type: 'email', message: 'Please input a valid email address', trigger: ['blur'] }
                ]
            }
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            hasUpdateSettingsError: (state) => state.auth.hasUpdateSettingsError
        }),
        ...mapGetters({
            _activeUsesV2Adopt: 'subscriptions/activeUsesV2Adopt'
        }),
        email () {
            return get(this.user, 'email', '');
        },
        firstname () {
            return get(this.user, 'firstname', 'Not Set');
        },
        lastname () {
            return get(this.user, 'lastname', 'Not Set');
        }
    },
    methods: {
        ...mapActions({
            _updateEmail: 'auth/updateEmail',
            _updateFirstName: 'auth/updateFirstName',
            _updateLastName: 'auth/updateLastName'
        }),
        async updateEmail (value) {
            await this._updateEmail({ email: value });
            if (this.hasUpdateSettingsError) {
                return Promise.reject(this.updateSettingsError);
            }

            return Promise.resolve();
        },
        async updateFirstName (value) {
            await this._updateFirstName({ firstname: value });
            if (this.hasUpdateSettingsError) {
                return Promise.reject(this.updateSettingsError);
            }

            return Promise.resolve();
        },
        async updateLastName (value) {
            await this._updateLastName({ lastname: value });
            if (this.hasUpdateSettingsError) {
                return Promise.reject(this.updateSettingsError);
            }

            return Promise.resolve();
        },
        openChangePasswordModal () {
            // modal is closed from within SettingsChangePassword.vue
            this.$modal.show('via-modal', {
                title: 'Change Password',
                component: 'SettingsChangePasswordModal'
            });
        }
    }
};
</script>

<style lang="scss">
.settings--user-settings {
    display: grid;
    grid-gap: 30px;

    .pendo-card__body {
        display: grid;
        grid-gap: 24px;
    }
}
</style>
