<template>
    <div class="metadata-rule">
        <pendo-multiselect
            :value="rule.operator"
            :allow-empty="false"
            preselect-first
            full-width
            value-key="value"
            :options="operatorOptions"
            :disabled="!operatorOptions"
            class="segment-rule--operator"
            @select="$emit('input', { field: 'operator', value: $event })" />
        <component
            :is="valueComponent.component"
            v-if="showValueComponent"
            v-bind="valueComponent.props"
            @input="$emit('input', $event)" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SegmentCsvUpload from '@/components/segments/builder/rule-components/SegmentCsvUpload.vue';
import NumberInput from '@/components/segments/builder/rule-components/NumberInput.vue';
import StringInput from '@/components/segments/builder/rule-components/StringInput.vue';
import AbsoluteDateRange from '@/components/segments/builder/rule-components/AbsoluteDateRange.vue';
import RelativeDateRange from '@/components/segments/builder/rule-components/RelativeDateRange.vue';
import SingleDate from '@/components/segments/builder/rule-components/SingleDate.vue';
import { SEGMENT_OPERATORS } from '@/constants/segments';
import { PendoDatePicker, PendoMultiselect, PendoInputNumber, PendoInput } from '@pendo/components';
import {
    getAbsoluteDateRangeConfig,
    getSegmentCsvConfig,
    getNumberConfig,
    getRelativeDateRangeConfig,
    getSingleDateConfig,
    getStringConfig
} from '@/utils/segments';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

export default {
    name: 'MetadataRule',
    components: {
        PendoMultiselect,
        PendoInputNumber,
        PendoInput,
        PendoDatePicker,
        AbsoluteDateRange,
        SegmentCsvUpload,
        NumberInput,
        RelativeDateRange,
        SingleDate,
        StringInput
    },
    props: {
        rule: {
            type: Object,
            required: true
        },
        checkValidRule: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            hasSegmentFlag: 'auth/hasSegmentFlag'
        }),
        operatorOptions () {
            const options = [...SEGMENT_OPERATORS[this.rule.schema.schema]];

            if (this.rule.schema.id === 'visitor_auto_id') {
                return options;
            }

            // filter out csv upload options
            return options.filter((option) => !['listContains', '!listContains'].includes(option.value));
        },
        showValueComponent () {
            return this.rule.operator && !this.rule.operator.unary;
        },
        valueComponent () {
            const schema = this.rule.schema.schema === 'list' ? this.rule.schema.elementType : this.rule.schema.schema;
            switch (schema) {
                case 'integer':
                case 'float':
                case 'number':
                case 'aggregation':
                    return getNumberConfig(this.rule, 'value');
                case 'time':
                case 'date':
                    if (['withinLast', '!withinLast', 'withinNext', '!withinNext'].includes(this.rule.operator.value)) {
                        return getRelativeDateRangeConfig(this.rule);
                    }

                    if (['between'].includes(this.rule.operator.value)) {
                        return getAbsoluteDateRangeConfig(this.rule);
                    }

                    if (['>=', '<='].includes(this.rule.operator.value)) {
                        return getSingleDateConfig(this.rule, 'value');
                    }

                    return;
                default:
                    if (['!listContains', 'listContains'].includes(this.rule.operator.value)) {
                        return getSegmentCsvConfig(this.rule);
                    }

                    return getStringConfig(this.rule, 'value');
            }
        }
    },
    created () {
        const isBoolean = get(this.rule, 'schema.schema') === 'boolean';
        const { value, operator } = this.rule;
        if (isNil(value) && isBoolean) {
            this.$emit('input', { field: 'operator', value: operator });
        }
    }
};
</script>
