<template>
    <pendo-tag
        :label="activeDateRange.label"
        type="filter" />
</template>

<script>
import { PendoTag } from '@pendo/components';
import { mapState } from 'vuex';

export default {
    name: 'ActiveDateRangeTag',
    components: {
        PendoTag
    },
    computed: {
        ...mapState({
            activeDateRange: (state) => state.filters.dateRange
        })
    }
};
</script>
