var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-header',{staticClass:"resource-center-header",attrs:{"title":_vm.headerTitle,"only-title":_vm.usesMultiApp,"loading":_vm.loading,"show-banner":_vm.showDisabledAppBanner,"specific-app":_vm.activeEditingApp},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.showBackButton)?_c('div',{staticClass:"resource-center-header--back"},[_c('pendo-button',{attrs:{"theme":"via","type":"link","size":"mini","prefix-icon":"chevron-left","label":"Back to Resource Center List"},on:{"click":_vm.onBackToRcListClick}})],1):_vm._e()]},proxy:true}])},[(!_vm.loading)?_c('template',{slot:"actions"},[(!!_vm.resourceCenter)?_c('pendo-button',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip.left-start",value:({ content: 'Delete Resource Center', offset: 5 }),expression:"{ content: 'Delete Resource Center', offset: 5 }",modifiers:{"left-start":true}}],attrs:{"data-cy":"delete-resource-center","theme":"via","type":"secondary","icon":"trash-2"},on:{"click":_vm.onDeleteResourceCenterClick}}):_vm._e(),(!!_vm.resourceCenter)?_c('div',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:(
                    !_vm.canPreviewResourceCenter && {
                        arrow: true,
                        multiline: true,
                        content: 'Your Resource Center is empty (no public content) and cannot be previewed.'
                    }
                ),expression:"\n                    !canPreviewResourceCenter && {\n                        arrow: true,\n                        multiline: true,\n                        content: 'Your Resource Center is empty (no public content) and cannot be previewed.'\n                    }\n                "}]},[_c('pendo-button',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip.left-start",value:(
                        _vm.canPreviewResourceCenter && { content: 'Preview Resource Center', offset: 5 }
                    ),expression:"\n                        canPreviewResourceCenter && { content: 'Preview Resource Center', offset: 5 }\n                    ",modifiers:{"left-start":true}}],attrs:{"disabled":!_vm.canPreviewResourceCenter,"data-cy":"preview-resource-center","theme":"via","type":"secondary","icon":"eye"},on:{"click":_vm.onPreviewResourceCenterClick}},[_vm._v(" Preview ")])],1):_vm._e(),(!!_vm.resourceCenter && _vm.canUpdatePublic)?_c('pendo-button',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip.left-start",value:({ content: 'Push Resource Center Updates', offset: 5 }),expression:"{ content: 'Push Resource Center Updates', offset: 5 }",modifiers:{"left-start":true}}],staticClass:"green-bg",attrs:{"data-cy":"update-resource-center","theme":"via","type":"primary","icon":"upload"},on:{"click":_vm.onUpdatePublicClick}}):_vm._e(),(!!_vm.resourceCenter)?_c('div',{staticClass:"production-toggle",attrs:{"data-cy":"rc-production-toggle"}},[_c('pendo-multiselect',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:(_vm.disableRCStateDropdown && _vm.messages.UNPUBLISHABLE_STATE),expression:"disableRCStateDropdown && messages.UNPUBLISHABLE_STATE"}],attrs:{"disabled":_vm.disableRCStateDropdown,"options":_vm.enabledStateOptions,"value":_vm.enabledState,"popper-options":{ placement: 'bottom-end' },"allow-empty":false,"min-menu-width":110,"value-key":"state"},on:{"select":_vm.onEnabledStateChange},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                    var option = ref.option;
return _c('pendo-guide-status-option',{attrs:{"option":option}})}}],null,false,3478457624)},[_c('pendo-guide-status-trigger',{attrs:{"slot":"trigger","type":"fill","size":"medium"},slot:"trigger"})],1)],1):_vm._e(),_c('div',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip.left-start",value:(
                    _vm.disableCreateButton && {
                        content: 'You\'ve added a Resource Center for all available integrated apps.',
                        arrow: true,
                        multiline: true
                    }
                ),expression:"\n                    disableCreateButton && {\n                        content: 'You\\'ve added a Resource Center for all available integrated apps.',\n                        arrow: true,\n                        multiline: true\n                    }\n                ",modifiers:{"left-start":true}}]},[(!_vm.resourceCenter)?_c('pendo-button',{attrs:{"data-cy":"create-resource-center","theme":"via","type":"primary","prefix-icon":"plus","label":"New Resource Center","disabled":_vm.disableCreateButton},on:{"click":_vm.createResourceCenter}}):_vm._e()],1),(!!_vm.resourceCenter && _vm.canUpdatePublic)?_c('div',{staticClass:"label-wrapper-update"},[_vm._v(" There are unpublished updates. Use "),_c('pendo-icon',{staticClass:"label-icon",attrs:{"type":"upload","display":"inline"}}),_vm._v(" to push "),_c('br'),_vm._v(" changes to the public resource center. ")],1):_vm._e()],1):_vm._e()],2),_c('resource-center-enable-modal',{attrs:{"is-visible":_vm.showEnableModal,"is-updating":_vm.isUpdating},on:{"onSave":_vm.submitEnable,"onCancel":_vm.cancelEnable}}),_c('resource-center-disable-modal',{attrs:{"is-visible":_vm.showDisableModal},on:{"onDisable":_vm.submitDisable,"onCancel":_vm.cancelDisable}}),_c('resource-center-delete-modal',{attrs:{"is-visible":_vm.showDeleteModal,"is-updating":_vm.isUpdating},on:{"onDelete":_vm.submitDelete,"onCancel":_vm.cancelDelete}}),_c('resource-center-update-public-modal',{attrs:{"is-visible":_vm.showUpdateModal,"is-updating":_vm.isUpdating},on:{"onUpdate":_vm.submitUpdate,"onCancel":_vm.cancelUpdate}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }