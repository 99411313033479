var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.targetsSelected)?_c('pendo-table',{directives:[{name:"pendo-loading",rawName:"v-pendo-loading:feather",value:(_vm.sourcePagesLoading || _vm.targetPagesLoading),expression:"sourcePagesLoading || targetPagesLoading",arg:"feather"}],staticClass:"select-target-pages-card",attrs:{"data":_vm.uniquePagesArray,"columns":_vm.columns,"max-height":448,"empty-text":"All Pages have been mapped","title":"Page Mapping"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_vm._v(" Page Mapping "),_c('pendo-icon',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:(_vm.pageMappingTooltip),expression:"pageMappingTooltip"}],attrs:{"display":"inline","type":"info","size":"14"}})],1)]},proxy:true},{key:"hasArrowHeader",fn:function(){return [_c('pendo-icon',{attrs:{"type":"arrow-right"}})]},proxy:true},{key:"hasArrow",fn:function(ref){
var row = ref.row;
return [(row.targetPage)?_c('pendo-icon',{attrs:{"type":"arrow-right"}}):_c('div')]}},{key:"targetPage",fn:function(ref){
var row = ref.row;
return [_c('pendo-multiselect',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:(_vm.automaticallyCreateNewpagesTooltip),expression:"automaticallyCreateNewpagesTooltip"}],attrs:{"loading":_vm.targetPagesLoading,"options":_vm.targetPages,"disabled":!_vm.targetPages.length,"min-trigger-width":220,"max-trigger-width":220,"placeholder":"-- Choose Page --","label-key":"name"},on:{"input":function($event){return _vm.pageSelected($event, row)}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var select = ref.select;
return [(!row.sourcePageNotFound)?_c('div',{staticClass:"step-page-selector__header"},[_c('div',{staticClass:"step-page-selector__option",on:{"click":function($event){select(_vm.createPageOnTargetAppOption(row.sourceSelectedPage))}}},[_c('span',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:(_vm.createNewPageDescriptionTooltip),expression:"createNewPageDescriptionTooltip"}],staticStyle:{"padding":"0 16px"}},[_vm._v(" Create New Page (from Existing Page Rule) ")])])]):_vm._e()]}},{key:"selectedLabel",fn:function(ref){
var option = ref.option;
return [(option.createPageOnTargetApp)?_c('div',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:(_vm.createNewPageTooltip),expression:"createNewPageTooltip"}]},[_vm._v(" Create New Page (from Existing Page Rule) ")]):_vm._e(),(!option.createPageOnTargetApp)?_c('pendo-group-option',{attrs:{"option":option}}):_vm._e()]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('pendo-group-option',{attrs:{"option":option}})]}}],null,true),model:{value:(row.targetPage),callback:function ($$v) {_vm.$set(row, "targetPage", $$v)},expression:"row.targetPage"}})]}}],null,false,855735139)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }