<template>
    <pendo-card
        :tags="getAppTags(app)"
        :title="app.displayName || 'Not Set'"
        :subtitle="platformLabel"
        shadow="hover">
        <template slot="image">
            <pendo-icon
                :type="iconLabel"
                size="85"
                aria-hidden="true"
                fill="#dadce5"
                stroke="#dadce5" />
        </template>
        <template slot="overlay">
            <router-link :to="`/settings/app/${app.id}/details`">
                <pendo-button
                    label="View App Details"
                    theme="app"
                    type="secondary" />
            </router-link>
        </template>
    </pendo-card>
</template>

<script>
import { PendoCard, PendoButton, PendoIcon } from '@pendo/components';
import get from 'lodash/get';

export default {
    name: 'AppCard',
    components: {
        PendoCard,
        PendoButton,
        PendoIcon
    },
    props: {
        app: {
            type: Object,
            default: () => ({})
        },
        iconLabel: {
            type: String,
            default: 'monitor'
        },
        platformLabel: {
            type: String,
            default: null
        }
    },
    methods: {
        getAppTags (app) {
            const tagArr = [];
            const integrationTag = {
                text: app.integrated ? 'Integrated' : 'Not Integrated',
                type: app.integrated ? 'success' : 'info',
                size: 'small'
            };

            const disabledTag = {
                text: 'Disabled',
                type: 'warning',
                size: 'small'
            };

            tagArr.push(integrationTag);
            if (get(app, 'applicationFlags.disableExtensionInjection')) {
                tagArr.push(disabledTag);
            }

            return tagArr;
        },
        handleAppSelect (app) {
            this.$emit('appSelect', app);
        }
    }
};
</script>

<style lang="scss">
.pendo-card__media-content {
    height: 100% !important; /* stylelint-disable-line */
    display: grid;
    justify-content: center;
    align-content: center;
    background-color: $gray-lighter-7;
}
</style>
