<template>
    <main class="visitor-list">
        <pendo-table
            ref="visitorListTable"
            v-pendo-loading:feather="isFetchingVisitorList"
            :data="visitorList"
            :filters="filters"
            :columns="columns"
            :default-sort="{
                prop: 'visitorId',
                order: 'ascending'
            }"
            :max-height="600"
            :csv-config="csvDownloadConfig"
            manage-columns
            csv-download
            title="Visitors"
            row-key="visitorId"
            class="visitor-list--table"
            @column-change="changeColumns">
            <div
                v-if="canUpload"
                slot="headerActions">
                <metadata-csv-upload @uploaded="fetchVisitorList" />
            </div>
            <template
                v-if="enableVisitorLink && routeToVisitorDetails"
                #visitorId="{ row }">
                <router-link :to="{ name: 'visitorDetails', params: { visitorId: encodeIdForUri(row.visitorId) } }">
                    {{ row.visitorId }}
                </router-link>
            </template>
            <div
                slot="empty"
                class="visitor-list--table--empty">
                <pendo-icon
                    type="alert-circle"
                    class="empty-icon"
                    stroke="#9a9ca5"
                    size="24" />
                <span class="empty-text">
                    No data found.
                </span>
            </div>
        </pendo-table>
    </main>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import { getVisitorList } from '@/aggregations/visitor-list';
import { PendoIcon, PendoTable, PendoLoading } from '@pendo/components';
import { rowFormatter, getColumnWidth, getColumnFormatSchema } from '@/utils/table-formatters';
import { convertToSubscriptionTimezone, DATE_FORMAT } from '@/utils/moment';
import { encodeIdForUri } from '@/utils/utils';
import MetadataCsvUpload from './MetadataCsvUpload.vue';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import { isCancel } from 'axios';

export default {
    name: 'AnalyticsVisitorList',
    components: {
        PendoIcon,
        PendoTable,
        MetadataCsvUpload
    },
    directives: {
        PendoLoading
    },
    props: {
        searchString: {
            type: String,
            default: ''
        },
        filterNoEvents: {
            type: Boolean,
            default: true
        },
        canUpload: {
            type: Boolean,
            default: false
        },
        enableVisitorLink: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            visitorList: [],
            unsubscribeFilterBarListener: null,
            isFetchingVisitorList: false,
            aggCancel: null,
            isColumnChooserVisible: false,
            dropdownOptions: [
                {
                    label: 'Manage Columns',
                    action: 'edit'
                }
            ]
        };
    },
    computed: {
        ...mapState({
            activeSegmentId: (state) => state.filters.activeSegmentId,
            _visitorListColumnNames: (state) => state.analytics.visitorListColumns,
            _defaultVisitorListColumns: (state) => state.analytics.defaultVisitorListColumns,
            appIdsFilter: (state) => state.filters.appIdsFilter
        }),
        ...mapGetters({
            activeTimeSeries: 'filters/activeTimeSeries',
            visitorListColumns: 'analytics/visitorListColumns',
            availableVisitorListColumns: 'analytics/availableVisitorListColumns',
            activeAppId: 'apps/activeId',
            activeUsesV2Adopt: 'subscriptions/activeUsesV2Adopt',
            hasSegmentFlag: 'auth/hasSegmentFlag',
            usesMultiApp: 'subscriptions/usesMultiApp',
            appsForAppIdFilter: 'filters/appsForAppIdFilter'
        }),
        routeToVisitorDetails () {
            return this.activeUsesV2Adopt;
        },
        filters () {
            return [
                {
                    prop: ['visitorId'],
                    value: this.searchString
                }
            ];
        },
        selectedColumnNames () {
            return this.visitorListColumns.map((col) => col.prop);
        },
        selectedColumns () {
            return this.visitorListColumns.map((column) => {
                const columnConfig = {
                    sortable: true,
                    visible: true,
                    ...column,
                    formatter: (row) => rowFormatter(row, column),
                    width: getColumnWidth(column)
                };

                return columnConfig;
            });
        },
        columns () {
            const unselectedColumns = this.availableVisitorListColumns
                .filter((col) => !this.selectedColumnNames.includes(col.prop))
                .map((col) => col);

            return this.selectedColumns.concat(unselectedColumns);
        },
        csvDownloadConfig () {
            const formattedColumns = this.selectedColumns.map((column) => {
                const singleColumn = {
                    ...column,
                    label: column.label,
                    formatter: (row) => this.formatDateForDownload(row, column)
                };

                return singleColumn;
            });

            return { columns: formattedColumns };
        }
    },
    watch: {
        async filterNoEvents (newVal, oldVal) {
            if (newVal !== oldVal) await this.fetchVisitorList();
        }
    },
    async created () {
        await this.fetchVisitorList();
        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, async () => {
            await this.fetchVisitorList();
        });
    },
    destroyed () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        ...mapActions({
            updateUserSetting: 'userSettings/updateAppNamespaceSetting'
        }),
        ...mapMutations({
            setVisitorListColumns: 'analytics/setVisitorListColumns'
        }),
        encodeIdForUri,
        openColumnChooser () {
            this.isColumnChooserVisible = true;
        },
        async changeColumns ({ columns }) {
            const shouldRefetch = this.didColumnsChange(columns);
            this.setVisitorListColumns({ visitorListColumns: columns });
            if (shouldRefetch) {
                await this.fetchVisitorList({
                    noCache: true
                });
            }
            await this.updateUserSetting({
                name: 'visitorListColumns',
                value: JSON.stringify(columns)
            });
        },
        didColumnsChange (newVisitorListColumns = []) {
            return !isEqual(newVisitorListColumns.slice().sort(), (this._visitorListColumnNames || []).slice().sort());
        },
        async fetchVisitorList () {
            this.isFetchingVisitorList = true;

            if (this.aggCancel) {
                this.aggCancel.abort();
            }

            this.aggCancel = new AbortController();

            try {
                const timeSeries = {
                    ...this.activeTimeSeries,
                    period: 'dayRange'
                };

                const appId = this.usesMultiApp ? this.appIdsFilter : this.activeAppId;
                const appList = this.usesMultiApp ? this.appsForAppIdFilter : [];

                const visitorList = await getVisitorList({
                    timeSeries,
                    segmentId: this.activeSegmentId,
                    appId,
                    appList,
                    columns: this.visitorListColumns,
                    signal: this.aggCancel.signal,
                    filterNoEvents: this.filterNoEvents,
                    addLastVisitByApp: this.usesMultiApp
                });

                this.isFetchingVisitorList = false;

                if (!this.usesMultiApp) {
                    this.visitorList = visitorList;

                    return;
                }

                const appFilterMap = keyBy(this.appsForAppIdFilter, 'id');

                this.visitorList = visitorList.map((row) => {
                    const noAppDisplayName = !row.lastVisitAppId || !appFilterMap[row.lastVisitAppId];
                    if (noAppDisplayName) {
                        row.lastVisitAppDisplayName = '';

                        return row;
                    }

                    row.lastVisitAppDisplayName = appFilterMap[row.lastVisitAppId].displayName;

                    return row;
                });
            } catch (err) {
                // If we've cancelled our aggs because a segment/app/whatever changed, things are still loading
                if (!isCancel(err)) {
                    this.isFetchingVisitorList = false;
                }
            }
        },
        formatDateForDownload (row, column) {
            const columnVal = get(row, column.prop);
            const schema = getColumnFormatSchema(column);
            const schemaIsDate = schema === 'date';
            const dateValue = columnVal && convertToSubscriptionTimezone(columnVal).format(DATE_FORMAT.isoDateTime);

            return schemaIsDate ? dateValue : columnVal;
        }
    }
};
</script>

<style lang="scss">
.visitor-list {
    &--table {
        &--empty {
            display: flex;
            align-items: center;
            justify-content: center;

            .pendo-icon {
                margin-right: 0.5em;
                display: flex;
            }

            .empty-text {
                color: $gray-primary;
            }
        }
    }
}
</style>
