/* eslint-disable no-await-in-loop */
import http from '@/utils/http';
import uuid from 'uuid';

const SAVED = 1;
const INITIALIZED = 5;
const UPLOADED = 10;
const STARTED = 20;
const COMPLETE = 100;

export function postToGCS (url, file, data) {
    const fd = new FormData();
    Object.keys(data).forEach((key) => {
        fd.append(key, data[key]);
    });
    fd.append('file', file); // must be the last entry

    return http.post(url, fd, { headers: { withCredentials: false } });
}

export async function getId (file) {
    if (file.id) {
        return { id: file.id };
    }

    return http.post('/api/s/_SID_/entitytag/visitor', { name: file.name }).then((res) => res.data);
}

export async function uploadEntitytagCsv (fileId, postBody = {}) {
    const { name, requestId } = postBody;

    return http.post(`/api/s/_SID_/entitytag/visitor/${fileId}/uploadcsv`, { name, requestId }).then((res) => res.data);
}

export async function processEntitytagCsv (fileId, postBody = {}) {
    const { name, requestId, excludeHeader, gcsUrl } = postBody;

    return http
        .post(`/api/s/_SID_/entitytag/visitor/${fileId}/processcsv`, { name, requestId, excludeHeader, gcsUrl })
        .then((res) => res.data);
}

export async function uploadMetadataCsv (kind, group, postBody = {}) {
    const { name, requestId } = postBody;

    return http.post(`/api/s/_SID_/metadata/${kind}/${group}/uploadcsv`, { name, requestId }).then((res) => res.data);
}

export async function processMetadataCsv (kind, group, postBody = {}) {
    const { name, requestId, gcsUrl } = postBody;

    return http
        .post(`/api/s/_SID_/metadata/${kind}/${group}/processcsv`, { name, requestId, gcsUrl })
        .then((res) => res.data);
}

export async function pollStatus (statusUrl, notify, shouldLogStatus) {
    let status = {};

    try {
        status = await http.get(statusUrl).then((res) => res.data);
    } catch (error) {
        throw error;
    }

    // Early status calls return {}
    if (!status.command) return 0;

    const { command } = status;

    if (command === 'progress' || command === 'processing') {
        const { entityTagsApplied, entityTagsTotal } = status;
        const progress = STARTED + Math.round((entityTagsApplied / entityTagsTotal) * (100 - STARTED));
        notify({ ...status, progress });

        // eslint-disable-next-line no-console
        if (shouldLogStatus) console.info('[CSV Upload] Status: ', status);

        return 0;
    }

    // status.command === 'finished || status.command === 'error'
    notify({ progress: status });

    return 1;
}

export function pollInterval (statusUrl, notify) {
    let pollingInterval;

    const pollingPromise = new Promise((resolve, reject) => {
        let timesPolled = 0;

        pollingInterval = window.setInterval(async () => {
            timesPolled++;
            const shouldLogStatus = !(timesPolled % 10);

            try {
                const isFinished = await pollStatus(statusUrl, notify, shouldLogStatus);
                if (!isFinished) return;

                window.clearInterval(pollingInterval);
                resolve();
            } catch (err) {
                reject(err);
            }
        }, 1000);
    });

    return { pollingPromise, pollingInterval };
}

export async function coordinateEntitytagCsvUpload (file, notify = () => {}, params = {}) {
    const requestId = params.requestId || `req-${uuid()}`;
    let pollingInterval;

    try {
        const { id } = await getId(file);
        notify({ progress: SAVED });
        file.id = id;

        const uploadPostBody = {
            name: file.name,
            requestId
        };

        const { uploadUrl, formData } = await uploadEntitytagCsv(file.id, uploadPostBody);

        notify({ progress: INITIALIZED });

        await postToGCS(uploadUrl, file, formData);
        notify({ progress: UPLOADED });

        const processPostBody = {
            requestId,
            name: file.name,
            gcsUrl: uploadUrl,
            excludeHeader: params.excludeHeader
        };

        await processEntitytagCsv(file.id, processPostBody);
        notify({ progress: STARTED });

        const statusUrl = `/api/s/_SID_/entitytag/status/${requestId}`;

        const pollingData = pollInterval(statusUrl, notify);

        pollingInterval = pollingData.pollingInterval;
        const { pollingPromise } = pollingData;

        return {
            entityTagId: id,
            pollingPromise,
            pollingInterval
        };
    } catch (error) {
        notify({ progress: { command: 'error', error } });
        window.clearInterval(pollingInterval);
        // eslint-disable-next-line no-console
        console.error('An unexpected error occurred while attempting to process a metadata csv upload', error);

        throw error;
    }
}

export async function coordinateMetadataCsvUpload (file, kind, group, notify = () => {}, params = {}) {
    const requestId = params.requestId || `req-${uuid()}`;

    try {
        const uploadPostData = {
            requestId,
            name: file.name
        };
        const { uploadUrl, formData } = await uploadMetadataCsv(kind, group, uploadPostData);
        notify({ progress: INITIALIZED });

        await postToGCS(uploadUrl, file, formData);
        notify({ progress: UPLOADED });

        await processMetadataCsv(kind, group, {
            name: file.name,
            gcsUrl: uploadUrl,
            requestId
        });

        notify({ progress: STARTED });

        const statusUrl = `/api/s/_SID_/metadata/status/${requestId}`;

        return pollInterval(statusUrl, notify);
    } catch (error) {
        notify({ progress: COMPLETE });
        throw error;
    }
}
