<template>
    <div class="export-lang">
        <span class="export-lang-text import-export-lang-text">
            <span class="authored-lang-text">
                Authored language
            </span>
            <span class="import-export-lang-name">
                {{ authoredLanguage.name }}
                <edit-authored-language
                    :languages="languages"
                    :authored-language="authoredLanguage"
                    :guide="guide" />
            </span>
            <span class="import-export-lang-code"> Source Language: {{ authoredLanguage.code }} </span>
        </span>

        <span class="export-action">
            <pendo-button
                class="import-export-button"
                theme="via"
                type="secondary"
                size="mini"
                label="Export"
                :loading="isLoading"
                @click="exportGuideLocalizationFile" />
        </span>
    </div>
</template>

<script>
import { exportGuideLocalizationFile, exportResourceCenterLocalizationFile } from '@/utils/localization.js';
import { PendoButton, PendoNotification } from '@pendo/components';
import EditAuthoredLanguage from '@/components/guides/settings/localization/EditAuthoredLanguage.vue';
import { mapGetters } from 'vuex';
import get from 'lodash/get';

export default {
    name: 'LanguageExport',
    components: {
        EditAuthoredLanguage,
        PendoButton
    },
    props: {
        authoredLanguage: {
            type: Object,
            required: true
        },
        guide: {
            type: Object,
            required: true
        },
        languages: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            isLoading: false
        };
    },
    computed: {
        ...mapGetters({
            getAppFromGuide: 'apps/appFromGuide'
        }),
        app () {
            return this.getAppFromGuide(this.guide);
        },
        appId () {
            return get(this.guide, 'appId', '');
        },
        isResourceCenter () {
            return (
                get(this, 'guide.draft.homeView.attributes.resourceCenter.isTopLevel', false) &&
                get(this, 'guide.draft.homeView.attributes.type') === 'building-block'
            );
        }
    },
    methods: {
        exportGuideLocalizationFile () {
            this.isLoading = true;
            const { id: guideId } = this.guide;
            const exportLocalizationFile = this.isResourceCenter
                ? exportResourceCenterLocalizationFile
                : exportGuideLocalizationFile;

            return exportLocalizationFile(guideId)
                .then((response) => {
                    this.downloadFile(response.data);
                    this.isLoading = false;
                })
                .catch(() => {
                    this.isLoading = false;
                    PendoNotification({
                        type: 'error',
                        message: 'There was an error downloading the file. Please try again',
                        duration: 0
                    });
                });
        },
        downloadFile (fileData) {
            let { name: guideName } = this.guide;
            if (this.isResourceCenter) guideName = this.app.displayName + this.guide.draft.homeView.name;

            const blob = new Blob([fileData], { type: 'application/xml' });

            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = `pendo_${guideName}_${this.authoredLanguage.code}.xliff`;

            document.body.appendChild(a);
            a.click();

            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(blob);
            }, 100);
        }
    }
};
</script>

<style lang="scss">
.export-lang {
    border-bottom: 1px solid $gray-lighter-5;
    padding: 16px;
    display: flex;
    align-items: flex-end;
}

.export-action {
    flex: 1;
    text-align: end;
}

.authored-lang-text {
    font-size: 12px;
    height: 14px;
    line-height: 1.17;
    font-weight: 600;
    color: $gray-lighter-3;
    text-transform: uppercase;
    display: block;
    margin-bottom: 10px;
}
</style>
