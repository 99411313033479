<template>
    <div class="invalid-token">
        <div class="invalid-token--main">
            <h2 class="invalid-token--header">
                {{ invalidTokenHeader }}
            </h2>
            <p class="invalid-token--message">
                {{ invalidTokenMessage }}
            </p>
        </div>
        <div class="invalid-token--footer">
            <router-link :to="{ name: 'login' }">
                <pendo-button
                    type="primary"
                    theme="via"
                    label="Back to Login" />
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { PendoButton } from '@pendo/components';

export default {
    name: 'SetPasswordFormModal',
    components: {
        PendoButton
    },
    computed: {
        ...mapState({
            hasResetPasswordTokenError: (state) => state.auth.hasResetPasswordTokenError,
            hasRegistrationTokenError: (state) => state.auth.hasRegistrationTokenError
        }),
        invalidTokenHeader () {
            if (this.hasResetPasswordTokenError) {
                return "That link isn't working.";
            }

            return 'Something went wrong.';
        },
        invalidTokenMessage () {
            if (this.hasResetPasswordTokenError) {
                return 'Password reset links expire after 3 hours if unused.';
            }

            return 'It looks like your invitation is no longer valid. Please contact your administrator for assistance.';
        }
    }
};
</script>

<style lang="scss">
.invalid-token {
    display: grid;
    grid-template-rows: 1fr auto;

    .invalid-token--header {
        margin: 0.25rem 0;
    }

    .invalid-token--message {
        margin-top: 0;
        margin-bottom: 1rem;
        line-height: 1.4;
    }

    .invalid-token--main {
        padding: 0 1.25rem;
    }

    .invalid-token--footer {
        padding: 1.25rem;
        border-top: 1px solid $gray-lighter-6;
        display: grid;
        grid-auto-flow: column;
        justify-content: end;
    }
}
</style>
