<template>
    <page-header
        only-title
        :title="title"
        :editable="canEditWorkflowName"
        :before-exit="onNameChanged"
        class="workflows-details-header"
        data-cy="workflows-details-header"
        @confirm="onNameChanged">
        <template #top>
            <div class="workflows-details-header__back-to-list">
                <router-link to="/workflows">
                    <pendo-button
                        theme="via"
                        type="link"
                        size="mini"
                        prefix-icon="chevron-left"
                        label="Back to Workflows List" />
                </router-link>
            </div>
        </template>
        <template #actions>
            <pendo-button
                v-if="canDeleteWorkflow"
                data-cy="workflows-details-delete-button"
                :disabled="isLoading"
                icon="trash-2"
                theme="via"
                type="tertiary"
                @click="onDeleteClicked" />
            <pendo-button
                data-cy="workflows-details-run-button"
                :disabled="runDisabled"
                label="Run"
                theme="via"
                :type="isReadOnly ? 'primary' : 'secondary'"
                @click="onRunClicked" />
            <pendo-button
                v-if="!isReadOnly"
                data-cy="workflows-details-save-button"
                :disabled="saveDisabled"
                :label="saveLabel"
                theme="via"
                type="primary"
                @click="onSaveClicked" />
        </template>
    </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import { PendoButton } from '@pendo/components';
import { mapGetters, mapState } from 'vuex';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

const DELETE_CLICKED_EVENT_NAME = 'deleteClicked';
const NAME_CHANGED_EVENT_NAME = 'nameChanged';
const RUN_CLICKED_EVENT_NAME = 'runClicked';
const SAVE_CLICKED_EVENT_NAME = 'saveClicked';

export default {
    name: 'WorkflowsDetailsHeader',
    components: {
        PageHeader,
        PendoButton
    },
    inject: ['$workflows'],
    computed: {
        ...mapGetters({
            isReadOnly: 'auth/isReadOnly'
        }),
        ...mapState({
            isFetching: (state) => state.workflows.isFetching,
            isSaving: (state) => state.workflows.isSaving
        }),
        canDeleteWorkflow () {
            return !this.isReadOnly && this.$workflows.id !== 'new';
        },
        canEditWorkflowName () {
            return !this.isReadOnly && !this.isLoading;
        },
        isLoading () {
            return this.$workflows.isAggregationInFlight || this.isFetching || this.isSaving;
        },
        runDisabled () {
            return this.isLoading || !this.workingWorkflowValid || !this.workingWorkflowHasChanged;
        },
        saveDisabled () {
            const { currentWorkflow, workingWorkflow } = this.$workflows;

            return this.isLoading || !this.workingWorkflowValid || isEqual(currentWorkflow, workingWorkflow);
        },
        saveLabel () {
            if (this.workingWorkflowValid && this.workingWorkflowHasChanged) {
                return 'Save & Run';
            }

            return 'Save';
        },
        title () {
            return !this.isReadOnly || get(this.$workflows, 'id', 'new') !== 'new'
                ? this.$workflows.workingWorkflow.name
                : 'Workflow';
        },
        workingWorkflowValid () {
            const { start, end } = this.$workflows.workingWorkflow;

            return !!start && !!end;
        },
        workingWorkflowHasChanged () {
            const { lastRanWorkflow, workingWorkflow } = this.$workflows;

            if (!lastRanWorkflow || !workingWorkflow) {
                return true;
            }

            const hasDateRangeChanged = !isEqual(lastRanWorkflow.dateRange, workingWorkflow.dateRange);
            const hasEndCountableChanged = !isEqual(lastRanWorkflow.end.id, workingWorkflow.end.id);
            const hasSegmentChanged = !isEqual(lastRanWorkflow.segment, workingWorkflow.segment);
            const hasStartCountableChanged = !isEqual(lastRanWorkflow.start.id, workingWorkflow.start.id);
            const hasMaxDurationChanged = !isEqual(
                lastRanWorkflow.maxDuration.value,
                workingWorkflow.maxDuration.value
            );
            const hasVisibilityChanged = !isEqual(lastRanWorkflow.shared, workingWorkflow.shared);

            return (
                hasDateRangeChanged ||
                hasEndCountableChanged ||
                hasSegmentChanged ||
                hasStartCountableChanged ||
                hasMaxDurationChanged ||
                hasVisibilityChanged
            );
        }
    },
    methods: {
        onDeleteClicked () {
            this.$emit(DELETE_CLICKED_EVENT_NAME);
        },
        onNameChanged (name) {
            this.$emit(NAME_CHANGED_EVENT_NAME, name);
        },
        onRunClicked () {
            this.$emit(RUN_CLICKED_EVENT_NAME);
        },
        onSaveClicked () {
            this.$emit(SAVE_CLICKED_EVENT_NAME);
        }
    }
};
</script>
