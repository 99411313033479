/* eslint-disable id-length */
import * as Agg2 from '@pendo/aggregations';
import { request } from '@/utils/aggregations';

export function getTimeOnGuide ({ appId, guideId, segmentId, byStep, ranges, timeSeries }) {
    const spec = buildTimeOnGuideSpec({ appId, guideId, segmentId, byStep, timeSeries, ranges });

    return request(spec);
}

export function buildTimeOnGuideSpec ({ appId, guideId, segmentId, byStep, timeSeries, ranges }) {
    const { operators: o, common: c } = Agg2;

    const groupField = byStep ? 'guideStepId' : 'guideId';

    return o.aggregation(
        `time-on-${byStep ? 'guide' : 'step'}`,
        o.pipeline(
            o.sources.guideEvents({
                appId: [appId],
                guideId,
                timeSeries
            }),
            o.segment(segmentId),
            o.group(
                [groupField, 'visitorId'],
                ...c.groupFields({
                    s: {
                        sequence: {
                            new: 'type == "guideSeen"',
                            sort: ['browserTime']
                        }
                    }
                })
            ),
            o.unwind('s', {
                index: 'sIndex'
            }),
            o.evalExpression('s[-1].duration', 's[-1].browserTime - s[0].browserTime'),
            o.group(
                [groupField, 'visitorId'],
                ...c.groupFields({
                    s: {
                        concat: 's'
                    }
                })
            ),
            o.evalExpression('s', 'reverse(s)'),
            o.evalExpression('totalDuration', 'sum(map(s,duration))'),
            o.group(
                [groupField, 'visitorId'],
                ...c.groupFields({
                    totalDuration: {
                        max: 'totalDuration'
                    }
                })
            ),
            o.fork(
                o.pipeline(
                    o.switchStatement(
                        'duration',
                        'totalDuration',
                        ...ranges.map((range) => {
                            const caseStatement = {
                                value: range.duration,
                                '>=': range.start
                            };

                            if (range.end) {
                                caseStatement['<'] = range.end;
                            }

                            return caseStatement;
                        })
                    ),
                    o.group(
                        [groupField, 'duration'],
                        ...c.groupFields({
                            total: o.count(null)
                        })
                    )
                ),
                o.pipeline(
                    o.sort('totalDuration'),
                    o.group(
                        groupField,
                        ...c.groupFields({
                            total: o.count(null),
                            totalDuration: o.sum('totalDuration'),
                            durations: { list: 'totalDuration' }
                        })
                    ),
                    o.evalExpression('averageDuration', 'if(total<=0, null, totalDuration/total)'),
                    o.evalExpression(
                        'medianDuration',
                        'if(total<=0, null, if(total%2==0, (durations[floor(total/2)] + durations[floor(total/2)-1])/2, durations[floor(total/2)]))'
                    ),
                    o.select(['averageDuration', 'medianDuration', 'total', groupField])
                )
            )
        )
    );
}
