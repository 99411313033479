<template>
    <main class="pages-list">
        <pendo-table
            ref="pagesListTable"
            v-pendo-loading:feather="isFetchingPageList"
            :data="getPages"
            :filters="filters"
            :columns="columns"
            :default-sort="sort"
            :auto-height="true"
            :resizable="true"
            :auto-height-offset="96"
            csv-download
            title="Pages"
            row-key="id"
            class="pages-list--table"
            @column-resize="onResizeColumn">
            <template #headerActions>
                <div
                    v-if="!activeIsDigitalAdoption"
                    class="pages-list--table-header">
                    <pendo-button
                        type="link"
                        theme="via"
                        prefix-icon="plus"
                        label="Add Custom Pages"
                        @click="openAddCustomPagesModal" />
                </div>
            </template>
            <template #actions="{ row }">
                <pendo-actions-cell
                    :row="row"
                    :actions="[
                        {
                            type: 'delete',
                            icon: 'trash-2',
                            disabled: !isCustomPage(row),
                            tooltip: deleteToolTip(row)
                        }
                    ]"
                    @delete="openDeleteCustomPageModal" />
            </template>
            <template #displayName="{ row }">
                <p
                    class="pages-list--table--display-name"
                    :class="{
                        'is-disabled': isCustomPageNameEditDisabled(row)
                    }">
                    <template v-if="isCustomPage(row)">
                        <pendo-editable-content
                            class="pages-list--table--display-name-inline-edit"
                            :value="row.displayName"
                            :before-exit="updateDisplayName.bind(null, row)"
                            @enterEditMode="onEnterDisplayNameEdit(row)"
                            @exitEditMode="onExitDisplayNameEdit">
                            <template
                                slot="append"
                                slot-scope="{ confirm, cancel, pending }">
                                <pendo-button
                                    :loading="pending"
                                    theme="app"
                                    icon="check"
                                    type="primary"
                                    @click="confirm" />
                                <pendo-button
                                    theme="app"
                                    type="secondary"
                                    icon="x"
                                    @click="cancel" />
                            </template>
                        </pendo-editable-content>
                    </template>
                    <template v-if="!isCustomPage(row)">
                        {{ row.displayName }}
                    </template>
                    <span
                        v-if="row.dirty && !isInstalled(row)"
                        class="page-list-table__name__tag">
                        Awaiting Install
                    </span>
                    <span
                        v-else-if="row.dirty"
                        class="page-list-table__name__tag">
                        Processing
                    </span>
                </p>
            </template>
            <div
                slot="empty"
                class="pages-list--table--empty">
                <pendo-icon
                    type="alert-circle"
                    class="empty-icon"
                    stroke="#9a9ca5"
                    size="24" />
                <span class="empty-text">
                    No data found.
                </span>
            </div>
        </pendo-table>
        <add-custom-pages-modal
            :visible="isAddCustomPagesModalVisible"
            :pages="getPages"
            @close="closeAddCustomPagesModal" />
        <delete-custom-entity-modal
            type="page"
            :visible="isDeleteCustomPageModalVisible"
            :entity="currentlyDeletingRow"
            @close="closeDeleteCustomPageModal" />
    </main>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import { formatTimeRangeForTable } from '@/utils/moment';
import { filterEntitiesByAppIds } from '@/utils/apps';
import AddCustomPagesModal from './AddCustomPagesModal';
import {
    PendoEditableContent,
    PendoActionsCell,
    PendoButton,
    PendoIcon,
    PendoTable,
    PendoLoading,
    PendoTooltip
} from '@pendo/components';
import get from 'lodash/get';
import { countableType, isCustomCountable, isInheritedCountable } from '@pendo/services/Countables';
import DeleteCustomEntityModal from '@/components/common/DeleteCustomEntityModal.vue';
import { filterFnPageByUrlMatch } from '@/utils/pages';
import { isValidUrl } from '@/utils/utils';

export default {
    name: 'PagesList',
    components: {
        PendoEditableContent,
        PendoActionsCell,
        PendoButton,
        PendoIcon,
        PendoTable,
        AddCustomPagesModal,
        DeleteCustomEntityModal
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    props: {
        searchString: {
            type: String,
            default: null
        },
        pageFilterSelection: {
            type: String,
            default: 'All Pages'
        }
    },
    data () {
        return {
            isAddCustomPagesModalVisible: false,
            isEditCustomPageModalVisible: false,
            isDeleteCustomPageModalVisible: false,
            currentlyEditingRow: {},
            currentlyDeletingRow: {},
            sort: {
                prop: 'numVisitors',
                order: 'descending'
            }
        };
    },
    computed: {
        ...mapGetters({
            pagesWithAnalytics: 'pages/listWithAnalytics',
            pages: 'pages/listAll',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            usesMultiApp: 'subscriptions/usesMultiApp',
            activeApp: 'apps/active'
        }),
        ...mapState({
            pagesError: (state) => state.pages.error,
            currentUser: (state) => state.auth.user,
            isFetchingPageList: (state) => state.pages.isFetchingWithAnalytics,
            appIdsFilter: (state) => state.filters.appIdsFilter,
            pagesListUserSettings: (state) => state.pages.pagesListColumns
        }),
        barePageList () {
            return filterEntitiesByAppIds(this.pages, this.appIdsFilter);
        },
        filters () {
            const filters = [
                {
                    filterFn: this.filterByPageUrl.bind(this)
                },
                {
                    prop: ['displayName'],
                    value: !isValidUrl(this.searchString) ? this.searchString : ''
                }
            ];

            if (this.pageFilterSelection === 'Custom Pages Only') {
                filters.push({
                    filterFn: (row) => this.isCustomPage(row)
                });
            }

            return filters;
        },
        columns () {
            const columns = [
                {
                    sortable: true,
                    prop: 'displayName',
                    label: 'Page Name',
                    allowResize: true,
                    minWidth: 288
                }
            ];

            if (this.activeIsDigitalAdoption) {
                columns.push({
                    sortable: true,
                    prop: 'type',
                    allowResize: false,
                    width: 120,
                    label: 'Type'
                });
            }

            if (this.usesMultiApp) {
                columns.push({
                    sortable: true,
                    prop: 'app.displayName',
                    allowResize: false,
                    label: 'Application'
                });
            }

            columns.push(
                {
                    sortable: true,
                    prop: 'numVisitors',
                    label: 'Number of Visitors',
                    allowResize: false,
                    async: true
                },
                {
                    sortable: true,
                    prop: 'pageLoads',
                    label: 'Number of Views',
                    allowResize: false,
                    async: true
                },
                {
                    sortable: true,
                    prop: 'avgTime',
                    label: 'Average Time on Page',
                    allowResize: false,
                    formatter: this.avgTimeOnPageFormatter,
                    async: true
                },
                {
                    type: 'actions',
                    allowResize: false,
                    width: 60
                }
            );

            if (!this.pagesListUserSettings) {
                return columns;
            }

            return columns.map((column) => {
                const savedColumn = this.pagesListUserSettings.find((col) => col.prop === column.prop);
                if (savedColumn && savedColumn.allowResize && savedColumn.width) {
                    column.width = savedColumn.width;
                }

                return column;
            });
        },
        getPages () {
            const pagesWithAnalyticsClone = [...this.pages];
            const pagesAndAnalytics = this.barePageList.reduce((acc, barePage) => {
                const isChildPage = get(barePage, 'isChildPage', false);
                if (isChildPage) return acc;

                const pageHasAnalytics = acc.find(
                    (existingPage) =>
                        existingPage.id === barePage.id &&
                        existingPage.numVisitors &&
                        existingPage.pageLoads &&
                        existingPage.avgTime
                );
                if (pageHasAnalytics) {
                    return acc;
                }
                if (isCustomCountable(barePage) || isInheritedCountable(barePage)) {
                    const pageToAdd = {
                        ...barePage,
                        numVisitors: 0,
                        pageLoads: 0,
                        avgTime: 0
                    };

                    acc.push(pageToAdd);
                }

                return acc;
            }, pagesWithAnalyticsClone);

            if (this.activeIsDigitalAdoption && pagesAndAnalytics.length) {
                return pagesAndAnalytics.map((page) => {
                    page.type = countableType(page);

                    return page;
                });
            }

            return pagesAndAnalytics || [];
        }
    },
    methods: {
        ...mapMutations({
            updatePage: 'pages/setMapAtKey',
            setPagesListColumns: 'pages/setPagesListColumns'
        }),
        ...mapActions({
            _updateCustomPage: 'pages/updateCustomPage',
            updateUserSetting: 'userSettings/updateAppNamespaceSetting'
        }),
        isCustomPageNameEditDisabled (row) {
            if (get(this.currentlyEditingRow, 'id')) {
                return this.currentlyEditingRow.id !== row.id;
            }

            return false;
        },
        isCustomPage (row) {
            return isCustomCountable(row);
        },
        avgTimeOnPageFormatter (row) {
            if (row.avgTime <= 0) return '---';

            return formatTimeRangeForTable(row.avgTime);
        },
        openAddCustomPagesModal () {
            this.isAddCustomPagesModalVisible = true;
        },
        closeAddCustomPagesModal () {
            this.isAddCustomPagesModalVisible = false;
        },
        openDeleteCustomPageModal (row) {
            this.currentlyDeletingRow = row;
            this.isDeleteCustomPageModalVisible = true;
        },
        closeDeleteCustomPageModal () {
            this.currentlyDeletingRow = {};
            this.isDeleteCustomPageModalVisible = false;
        },
        onResizeColumn (column) {
            const columns = cloneDeep(this.columns);
            const resizedColumn = columns.find((col) => col.column === column.prop);
            resizedColumn.width = column.width;

            this.updateUserSetting({
                name: 'pagesListColumns',
                value: JSON.stringify(columns)
            });
            this.setPagesListColumns({ pagesListColumns: columns });
        },
        isInstalled (row) {
            if (this.usesMultiApp) {
                return get(row, 'app.integrated');
            }

            return this.activeApp.integrated;
        },
        onEnterDisplayNameEdit (row) {
            this.currentlyEditingRow = row;
        },
        onExitDisplayNameEdit () {
            this.currentlyEditingRow = {};
        },
        async updateDisplayName (page, newDisplayName) {
            if (page.displayName === newDisplayName) {
                return;
            }

            const customPage = {
                pageId: page.id,
                name: newDisplayName,
                appId: page.appId,
                rules: page.rules
            };

            await this._updateCustomPage({ customPage });
            if (this.pagesError) {
                throw this.pagesError;
            }
        },
        filterByPageUrl (row) {
            if (!isValidUrl(this.searchString)) return true;

            return filterFnPageByUrlMatch(row, this.searchString);
        },
        deleteToolTip (row) {
            switch (countableType(row)) {
                case 'Inherited':
                    return 'Inherited Pages cannot be deleted';
                case 'Inactive':
                    return 'Inactive Pages cannot be deleted';
                default:
                    return null;
            }
        }
    }
};
</script>

<style lang="scss">
.pages-list {
    &--table {
        &-header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-grow: 1;
        }

        &--display-name {
            line-height: 23px;
            font-weight: 400;
            margin-top: 0;

            &.is-disabled {
                opacity: 0.7;
                pointer-events: none;
            }
        }

        &--is-custom-page {
            font-size: 10px;
            letter-spacing: 1.5px;
            margin-top: 0;
            line-height: 120%;
            color: $gray-lighter-2;
            text-transform: uppercase;
        }

        &--empty {
            display: flex;
            align-items: center;
            justify-content: center;

            .pendo-icon {
                margin-right: 0.5em;
                display: flex;
            }

            .empty-text {
                color: $gray-primary;
            }
        }

        .pendo-table__title-actions {
            grid-template-columns: auto 36px;
            flex-grow: 1;
        }

        .page-list-table__name__tag {
            font-style: italic;
            font-size: 12px;
            font-weight: 400;
            margin-left: 4px;
            color: $gray-lighter-2;
        }
    }

    .visibility-note {
        font-size: 0.875rem;
        padding-left: 10px;
    }
}
</style>
