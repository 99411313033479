<template>
    <div>
        <pendo-popover
            arrow
            :disabled="disableExtensionInjection">
            <pendo-button
                theme="app"
                :type="buttonType"
                :label="buttonLabel"
                :loading="loading"
                :disabled="updatingAppFlag === disableFlagName"
                @click="onButtonClick" />
            <template #popover>
                <div class="disable-extension-injection-popover">
                    <strong>Disabled applications</strong> do not inject Pendo onto websites associated with this app.
                    Guides are not displayed and data is not captured while an app is disabled.
                </div>
            </template>
        </pendo-popover>
        <pendo-modal
            theme="app"
            :title="modalTitle"
            height="auto"
            width="415px"
            :visible="showConfirmModal"
            :esc-to-close="true"
            :click-to-close="true"
            :confirm-button-config="confirmButtonConfig"
            @close="onCloseClick"
            @cancel="onCloseClick"
            @confirm="onConfirmClick">
            <template slot="body">
                <p class="disable-injection-modal-body">
                    <template v-if="disableExtensionInjection">
                        Enabling this application has the following effects:
                        <ul>
                            <li>Pendo will start being injected onto associated websites</li>
                            <li>
                                Guides will resume delivery (Any public guides will immediately begin displaying to
                                users)
                            </li>
                            <li>Analytics will resume being captured</li>
                        </ul>
                        Enabling an application may take up to 2 hours to propagate to all users.<br><br><strong>Are you sure you wish to continue?</strong>
                    </template>
                    <template v-if="!disableExtensionInjection">
                        Disabling this application has the following effects:
                        <ul>
                            <li>Pendo will not be injected onto associated websites</li>
                            <li>Guides will not be delivered</li>
                            <li>Analytics will not be captured</li>
                        </ul>
                        You may re-enable the application at any time. Disabling an application may take up to 2 hours
                        to propagate to all users.<br><br><strong>Are you sure you wish to continue?</strong>
                    </template>
                </p>
            </template>
        </pendo-modal>
    </div>
</template>

<script>
/* eslint-disable vue/no-v-html */
import { mapActions, mapState } from 'vuex';
import { PendoPopover, PendoButton, PendoModal, PendoNotification } from '@pendo/components';
import startCase from 'lodash/startCase';
import get from 'lodash/get';

export default {
    name: 'DisableExtensionInjection',
    components: { PendoPopover, PendoButton, PendoModal },
    directives: { PendoNotification },
    props: {
        app: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            loading: false,
            updatingFlag: false,
            showConfirmModal: false,
            disableFlagName: 'disableExtensionInjection'
        };
    },
    computed: {
        ...mapState({
            updatingAppFlag: (state) => state.apps.updatingFlag
        }),
        buttonType () {
            return this.disableExtensionInjection ? 'primary' : 'danger';
        },
        buttonLabel () {
            return this.disableExtensionInjection ? 'Enable' : 'Disable';
        },
        confirmButtonConfig () {
            return {
                disabled: this.disabled,
                theme: 'app',
                type: this.buttonType,
                label: `Yes, ${this.buttonLabel}`
            };
        },
        disableExtensionInjection () {
            return get(this.app, `applicationFlags.${this.disableFlagName}`, null);
        },
        modalTitle () {
            return `${this.buttonLabel} Application`;
        }
    },
    methods: {
        ...mapActions({
            updateAppFlag: 'apps/updateFlag'
        }),
        onButtonClick () {
            this.showConfirmModal = true;
        },
        onCloseClick () {
            this.showConfirmModal = false;
        },
        onConfirmClick () {
            this.showConfirmModal = false;
            this.setDisableState(!this.disableExtensionInjection);
        },
        async setDisableState (enabled) {
            try {
                await this.updateAppFlag({ appId: this.app.id, flag: this.disableFlagName, enabled });
            } catch (error) {
                this.displayErrorToast(enabled ? 'disabling application' : 'enabling application');
            }
        },
        displayErrorToast (actionCausingError) {
            PendoNotification({
                type: 'error',
                title: `Error ${startCase(actionCausingError)}`,
                message: 'Pendo encountered an error. Please refresh your page and try again.',
                duration: 7000
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.disable-status {
    margin-right: 8px;
}

.disable-extension-injection-popover {
    font-size: 14px;
    padding: 16px 24px;
    width: 325px;
}

.disable-injection-modal-body {
    margin-top: 0;

    /deep/ul {
        margin: 8px 0;
        padding-left: 25px;
    }
}
</style>
