<template>
    <div
        v-pendo-loading="loadingConfig"
        class="iframe-content-container"
        data-cy="iframe-content-container">
        <app-card-overlay
            :is-editing="isEditingIframeContent"
            :confirmation-modal-active="!!activeModal">
            <template slot="card">
                <pendo-card title="Content">
                    <template slot="headerRight">
                        <div class="iframe-content-save-cancel-buttons">
                            <pendo-button
                                v-if="isEditingIframeContent"
                                theme="via"
                                type="tertiary"
                                label="Cancel"
                                size="mini"
                                @click="onModalCancel" />
                            <pendo-button
                                v-if="isEditingIframeContent"
                                :disabled="!userHasChanges"
                                theme="via"
                                type="primary"
                                label="Save"
                                size="mini"
                                @click="toggleModal(MODALS.CONFIRM_SAVE)" />
                        </div>
                    </template>
                    <template #body>
                        <div
                            v-if="!editedIframeSrc && !isFetching"
                            class="no-iframe-url-msg-wrapper">
                            <div
                                v-if="!isModuleLoading"
                                class="no-iframe-url-msg">
                                <div class="title">
                                    You haven't added anything yet.
                                </div>
                                <div class="subtitle">
                                    Add a URL to see a preview here.
                                </div>
                                <pendo-form
                                    form-ref="emptyStateFormRef"
                                    :form-defaults="emptyStateForm"
                                    :call-validate="callValidateEmptyStateForm"
                                    @formValidated="handleValidEmptyStateForm"
                                    @invalidForm="handleInvalidEmptyStateForm">
                                    <pendo-form-item
                                        :rules="validationRules"
                                        prop="url">
                                        <pendo-input
                                            v-model="emptyStateForm.url"
                                            class="iframe-url-empty-input"
                                            :debounce="350"
                                            autofocus
                                            placeholder="https://example.com/"
                                            @input="validateEmptyStateForm"
                                            @keydown="emptyStateInputKeyDown">
                                            <template #append>
                                                <pendo-button
                                                    type="primary"
                                                    theme="via"
                                                    label="Preview URL"
                                                    :disabled="disableEmptyStateSubmitButton"
                                                    @click="submitEmptyStateForm" />
                                            </template>
                                        </pendo-input>
                                    </pendo-form-item>
                                </pendo-form>
                            </div>
                        </div>
                        <div
                            v-if="!!editedIframeSrc"
                            class="edit-iframe-content">
                            <div class="iframe-url-edit-wrapper">
                                <span class="iframe-content-label">
                                    External Content URL
                                </span>
                                <pendo-editable-content
                                    class="iframe-url-edit"
                                    :value="editedIframeSrc"
                                    allow-empty
                                    :validation-rules="validationRules"
                                    @confirm="updateIframeSrc">
                                    <template
                                        slot="append"
                                        slot-scope="{ pending, confirm, cancel }">
                                        <pendo-button
                                            theme="via"
                                            icon="check"
                                            :loading="pending"
                                            type="primary"
                                            @click="confirm" />
                                        <pendo-button
                                            theme="via"
                                            label="Cancel"
                                            type="tertiary"
                                            @click="cancel" />
                                    </template>
                                </pendo-editable-content>
                            </div>
                            <div class="iframe-preview-content">
                                <div class="iframe-preview-wrapper">
                                    <iframe
                                        class="iframe-preview"
                                        title="Iframe Preview"
                                        width="350"
                                        height="342"
                                        :src="editedIframeSrc" />
                                </div>
                            </div>
                        </div>
                    </template>
                </pendo-card>
            </template>
        </app-card-overlay>

        <pendo-modal
            :show-close="false"
            :visible="activeModal === MODALS.CONFIRM_SAVE"
            :confirm-button-config="{ type: 'primary', theme: 'via', label: 'Save Module' }"
            message="This module will be saved to the draft version of your Resource Center. To make your changes public please publish your updates."
            type="confirmation"
            title="Save Module?"
            width="400px"
            @confirm="confirmSave"
            @cancel="toggleModal" />
        <pendo-modal
            :show-close="false"
            :visible="activeModal === MODALS.CONFIRM_CANCEL"
            :confirm-button-config="{ type: 'primary', theme: 'via', label: 'Don\'t Save Changes' }"
            :cancel-button-config="{ type: 'secondary', theme: 'via', label: 'Continue Editing' }"
            title="You have unsaved changes!"
            type="confirmation"
            message="Any entered URL will be forgotten. Are you sure you don’t want to save changes?"
            width="400px"
            @confirm="doNotSave"
            @cancel="toggleModal" />
    </div>
</template>

<script>
import {
    PendoButton,
    PendoCard,
    PendoModal,
    PendoTooltip,
    PendoLoading,
    PendoEditableContent,
    PendoInput,
    PendoForm,
    PendoFormItem,
    PendoNotification
} from '@pendo/components';
import AppCardOverlay from '@/components/common/AppCardOverlay';
import { getModuleIframeBlock } from '@/utils/resource-center';
import { mapGetters, mapActions, mapState } from 'vuex';
import get from 'lodash/get';
import { BuildingBlockProperties } from '@pendo/services/BuildingBlocks';

const MODALS = Object.freeze({
    CONFIRM_SAVE: 'confirmSave',
    CONFIRM_CANCEL: 'confirmCancel',
    DO_NOT_SAVE: 'doNotSave'
});

export default {
    name: 'IframeContent',
    components: {
        PendoButton,
        PendoCard,
        PendoModal,
        AppCardOverlay,
        PendoEditableContent,
        PendoInput,
        PendoForm,
        PendoFormItem
    },
    directives: {
        PendoTooltip,
        PendoLoading,
        PendoNotification
    },
    props: {
        module: {
            type: Object,
            required: true
        },
        parentLoading: {
            type: Boolean,
            deafault: false
        }
    },
    data () {
        return {
            editedIframeSrc: '',
            loading: false,
            isEditingIframeContent: false,
            activeModal: null,
            MODALS,
            validationRules: [
                {
                    type: 'url',
                    message: 'Please input a valid URL starting with http:// or https://',
                    trigger: ['change']
                }
            ],
            emptyStateForm: {
                url: ''
            },
            isEmptyStateFormValid: false,
            callValidateEmptyStateForm: false
        };
    },
    computed: {
        ...mapState({
            isFetching: (state) => state.resourceCenter.isFetching,
            errorOnSave: (state) => state.resourceCenter.error
        }),
        ...mapGetters({
            resourceCenter: 'resourceCenter/getActiveResourceCenter'
        }),
        resourceCenterDraftHomeView () {
            return get(this.resourceCenter, 'draft.homeView');
        },
        moduleBuildingBlocks () {
            return get(this.module, 'steps[0].buildingBlocks', {});
        },
        originalIframeSrc () {
            const iframeBlock = getModuleIframeBlock(this.moduleBuildingBlocks);
            const src = BuildingBlockProperties.getPropertyByName(iframeBlock.properties, 'src');

            return get(src, 'value', '');
        },
        userHasChanges () {
            return this.editedIframeSrc !== this.originalIframeSrc;
        },
        loadingConfig () {
            return {
                loading: this.isModuleLoading,
                type: 'feather',
                spinnerProps: {
                    background: 'rgba(255, 255, 255, 0.5)'
                }
            };
        },
        isModuleLoading () {
            return this.loading || this.isFetching || this.parentLoading;
        },
        isEmptyStateFormEmpty () {
            return !this.emptyStateForm.url;
        },
        disableEmptyStateSubmitButton () {
            return this.isEmptyStateFormEmpty || !this.isEmptyStateFormValid;
        }
    },
    watch: {
        editedIframeSrc (newValue) {
            this.emptyStateForm.url = newValue;
        }
    },
    async created () {
        this.loading = true;
        await this.fetchGuides();
        this.editedIframeSrc = this.originalIframeSrc;
        this.loading = false;
    },
    methods: {
        ...mapActions({
            fetchGuides: 'guides/fetch',
            updateModuleIframeSrc: 'resourceCenter/updateModuleIframeSrc'
        }),
        updateIframeSrc (value) {
            this.editedIframeSrc = value;
            this.isEditingIframeContent = true;
        },
        validateEmptyStateForm () {
            this.callValidateEmptyStateForm = true;
        },
        handleValidEmptyStateForm () {
            this.isEmptyStateFormValid = true;
            this.callValidateEmptyStateForm = false;
        },
        handleInvalidEmptyStateForm () {
            this.isEmptyStateFormValid = false;
            this.callValidateEmptyStateForm = false;
        },
        async submitEmptyStateForm () {
            if (this.isEmptyStateFormValid) {
                this.updateIframeSrc(this.emptyStateForm.url);
            }
        },
        emptyStateInputKeyDown (event) {
            if (event.keyCode === 13 && this.isEmptyStateFormValid) {
                this.submitEmptyStateForm();
            }
        },
        toggleModal (modalName = null) {
            this.activeModal = modalName;
        },
        onModalCancel () {
            if (!this.userHasChanges) {
                this.doNotSave();

                return;
            }

            this.toggleModal(MODALS.CONFIRM_CANCEL);
        },
        async confirmSave () {
            this.toggleModal();
            this.isEditingIframeContent = false;
            this.loading = true;
            await this.updateModuleIframeSrc({ module: this.module, src: this.editedIframeSrc });
            this.loading = false;

            if (this.errorOnSave) {
                this.editedIframeSrc = this.originalIframeSrc;
                PendoNotification({
                    type: 'error',
                    title: 'Something went wrong!',
                    message: 'Action not available. Please try again later.',
                    duration: 5000
                });

                return;
            }

            this.$emit('on-save');
        },
        confirmCancel () {
            this.toggleModal();
        },
        doNotSave () {
            this.editedIframeSrc = this.originalIframeSrc;
            this.isEditingIframeContent = false;
            this.toggleModal();
        }
    }
};
</script>

<style scoped lang="scss">
.loading-container {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.edit-iframe-content {
    margin-top: 24px;
}

.iframe-url-edit-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin: 0 24px;

    .iframe-url-edit {
        margin-left: 100px;

        /deep/.el-input__inner {
            width: 350px;
        }
    }
}

.iframe-content-label {
    font-weight: 600;
}

.iframe-preview-content {
    background-color: $gray-lighter-7;
    margin-top: 24px;

    .iframe-preview-wrapper {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 451px;
        justify-content: center;
    }

    .iframe-preview {
        border: 0;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}

.no-iframe-url-msg-wrapper {
    align-items: top;
    display: flex;
    flex-direction: row;
    height: 537px;
    justify-content: center;
    padding-top: 150px;
    text-align: center;

    .title {
        font-weight: 300;
        font-size: 36px;
    }

    .subtitle {
        margin-top: 24px;
    }

    .iframe-url-empty-input {
        margin-top: 24px;
    }

    /deep/.pendo-input__field {
        border-radius: 3px;
        width: 350px;
    }

    /deep/.pendo-input__append {
        background: unset;
        border: 0;
    }
}
</style>
