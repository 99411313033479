import get from 'lodash/get';
import { applyTheme } from '@/utils/whitelabel';

export function theme (store) {
    store.subscribe(({ type }, state) => {
        const { subscriptions, apps } = state;
        const appPrimaryColor = get(apps.map[apps.activeAppUid], 'whiteLabelSettings.primaryColor', '#2a2c35');
        const subPrimaryColor = get(subscriptions[subscriptions.activeId], 'whiteLabelSettings.primaryColor', '#2a2c35');

        switch (type) {
            case 'apps/setActiveAppUid':
                applyTheme({ appPrimaryColor });
                break;
            case 'subscriptions/setActiveById':
            case 'subscriptions/setLocal':
            case 'subscriptions/setLocalById':
                applyTheme({ subPrimaryColor });
                break;
            case 'auth/reset':
                applyTheme({ subPrimaryColor: '#2a2c35', appPrimaryColor: '#2a2c35' });
                break;
        }
    });
}
