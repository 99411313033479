<template>
    <div class="resource-center-content-preview">
        <carousel
            :class="{ 'navigation-enabled': navigationEnabled }"
            :mouse-drag="false"
            :navigate-to="focusedPageIndex"
            :navigation-enabled="navigationEnabled"
            :pagination-enabled="navigationEnabled"
            :per-page="perPage"
            :per-page-custom="perPageCustom"
            :navigation-prev-label="navigationPrevArrow"
            :navigation-next-label="navigationNextArrow">
            <slide
                v-for="(dom, index) in domArr"
                :key="`module-preview-${index}`"
                class="module-wrapper">
                <aside
                    v-if="showAddModuleButton"
                    class="slide-overlay module-overlay">
                    <pendo-button
                        v-if="index === 0"
                        theme="via"
                        type="primary"
                        class="add-module-button"
                        prefix-icon="plus"
                        label="Add Module"
                        @click.native="$emit('showModuleChooser')" />
                    <pendo-button
                        v-if="index"
                        theme="via"
                        type="primary"
                        class="add-btn"
                        label="View Module Details"
                        @click.native="editModule(index)" />
                </aside>
                <div
                    v-if="isModuleDisabledForRemoval(index)"
                    class="disabled-warning">
                    <img
                        class="empty-state-img"
                        src="@/img/resource-center-empty-state.svg">
                    <div class="warning">
                        <pendo-icon type="alert-circle" />
                        <div class="warning-title">
                            Module Removed
                        </div>
                        <div
                            v-if="activeState === 'draft'"
                            class="warning-text">
                            This module has been removed from the Draft version of your Resource Center. Push changes
                            upward to completely remove this module from all versions of your Resource Center.
                        </div>
                    </div>
                </div>
                <guide-step-preview
                    v-if="!isModuleDisabledForRemoval(index)"
                    :dom="dom"
                    :step-count="domArr.length"
                    :guides="guidesToDisplay(index)"
                    :step="buildGuideForPreview(index)"
                    :is-resource-center-preview="true"
                    class="slide-content module-preview" />
                <resource-center-preview-info
                    :module="homeViewAndModules[index]"
                    :num-modules="modules.length"
                    :active-state="activeState" />
            </slide>
        </carousel>
    </div>
</template>

<script>
import { PendoButton, PendoIcon } from '@pendo/components';
import GuideStepPreview from '@/components/guides/settings/GuideStepPreview';
import { Carousel, Slide } from 'vue-carousel';
import ResourceCenterPreviewInfo from '@/components/resource-center/ResourceCenterPreviewInfo';
import { getModuleURLParam, getModuleTitleProperty } from '@/utils/resource-center';
import { navigationPrevArrow, navigationNextArrow } from '@/constants/svgs';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { mapGetters } from 'vuex';

export default {
    name: 'ResourceCenterContentPreview',
    components: {
        ResourceCenterPreviewInfo,
        GuideStepPreview,
        Carousel,
        Slide,
        PendoButton,
        PendoIcon
    },
    props: {
        modules: {
            type: Array,
            required: true
        },
        homeView: {
            type: Object,
            required: true
        },
        activeState: {
            type: String,
            required: true
        },
        showAddModuleButton: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            windowWidth: window.innerWidth,
            perPageCustom: [[1500, 3], [1100, 2]],
            focusedPageIndex: 0,
            navigationPrevArrow,
            navigationNextArrow
        };
    },
    computed: {
        ...mapGetters({
            getChildGuides: 'resourceCenter/getChildGuides',
            getPublicHomeViewAndModules: 'resourceCenter/getPublicHomeViewAndModules',
            getDraftHomeViewAndModules: 'resourceCenter/getDraftHomeViewAndModules'
        }),
        allModules () {
            return {
                draft: this.getDraftHomeViewAndModules,
                public: this.getPublicHomeViewAndModules
            };
        },
        domArr () {
            return this.homeViewAndModules.map((guide) => guide.steps[0].dom);
        },
        homeViewAndModules () {
            return this.allModules[this.activeState];
        },
        navigationEnabled () {
            return this.domArr.length > this.perPage;
        },
        perPage () {
            const matchingPerPageTuple = this.perPageCustom.find((tuple) => this.windowWidth >= tuple[0]) || [null, 1];

            return matchingPerPageTuple[1];
        }
    },
    mounted () {
        this.onResize = debounce(() => {
            this.windowWidth = window.innerWidth;
        }, 16);
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        guidesToDisplay (index) {
            const childGuides = this.getChildGuides({ parentGuide: this.homeViewAndModules[index] });
            const mockGuideFactoryGuides = childGuides.map((remoteGuide) => {
                const guide = cloneDeep(remoteGuide);
                const buildingBlocks = get(guide, 'steps[0].buildingBlocks');
                let { name } = guide;

                if (buildingBlocks) {
                    name = getModuleTitleProperty(buildingBlocks);
                }

                return {
                    id: guide.id,
                    attributes: guide.attributes,
                    name,
                    state: guide.state,
                    hasResourceCenterContent: true,
                    getTotalSteps: () => {
                        return get(guide, 'steps', []).length || 1;
                    },
                    getSeenSteps: () => {
                        return 1;
                    },
                    isComplete: () => {
                        return false;
                    }
                };
            });

            if (this.homeViewAndModules[index].attributes.resourceCenter.isTopLevel) {
                mockGuideFactoryGuides.push(this.homeViewAndModules[index]);
            }

            return mockGuideFactoryGuides;
        },
        buildGuideForPreview (index) {
            const clonedGuide = cloneDeep(this.homeViewAndModules[index]);
            const builtGuide = window.pendo.GuideFactory(clonedGuide);

            return builtGuide.steps[0];
        },
        editModule (index) {
            const moduleToEdit = this.homeViewAndModules[index];
            const moduleId = moduleToEdit.id;
            const moduleRouteParam = getModuleURLParam(get(moduleToEdit, 'attributes.resourceCenter.moduleId'));

            this.$router.push({ name: 'EditModule', params: { moduleRouteParam, moduleId } });
        },
        isModuleDisabledForRemoval (index) {
            return index !== 0 && get(this, `homeViewAndModules[${index}].state`, '') === 'disabled';
        }
    }
};
</script>

<style lang="scss">
.resource-center-content-preview {
    @include carousel;

    .module {
        &-wrapper {
            padding: 24px 20px;
        }

        &-overlay {
            height: 500px;
        }

        &-preview {
            margin-bottom: 20px;
            height: 450px;
        }
    }

    .disabled-warning {
        height: 450px;
        position: relative;
        display: flex;
        justify-content: center;

        .empty-state-img {
            margin-top: -22px;
            height: 478px;
        }

        .warning {
            position: absolute;
            top: 30%;
            width: 300px;
            text-align: center;
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .warning-title {
                font-size: 22px;
                margin-bottom: 8px;
                font-weight: 600;
            }

            .feather-alert-circle {
                width: 40px;
                height: 40px;
                color: map-get($statusColorMap, staged);
                margin-bottom: 11px;
            }
        }
    }
}
</style>
