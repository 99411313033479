<template>
    <pendo-filter-bar
        v-pendo-affix="{
            fixedStyles: {
                left: 0,
                right: 0,
                padding: '0 32px 0 120px'
            }
        }"
        class="global-filters">
        <div class="global-filters--inner global-filters--top">
            <slot name="left">
                <div class="filters">
                    <div
                        v-if="showSegmentPicker"
                        class="filters--item">
                        <segment-chooser
                            :value="activeSegmentId"
                            :disabled="updatingActiveApp"
                            :guide-targeting="false"
                            @input="updateActiveSegmentId" />
                    </div>
                    <div
                        v-if="showDatePicker"
                        class="filters--item">
                        <date-range-picker
                            :value="dateRange"
                            @change="updateDateRange" />
                    </div>
                    <div
                        v-if="usesMultiApp && showAppPicker"
                        class="filter--item">
                        <app-filter />
                    </div>
                </div>
            </slot>
            <slot name="right" />
        </div>
        <template #bottom>
            <div
                v-if="!!$slots.expansion"
                class="global-filters--inner global-filters--bottom">
                <div class="filters">
                    <slot name="expansion" />
                </div>
            </div>
        </template>
    </pendo-filter-bar>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { PendoFilterBar, PendoAffix } from '@pendo/components';
import SegmentChooser from '@/components/segments/SegmentChooser';
import DateRangePicker from '@/components/filters/DateRangePicker';
import AppFilter from '@/components/filters/AppFilter';

export default {
    name: 'GlobalFilters',
    components: {
        DateRangePicker,
        AppFilter,
        PendoFilterBar,
        SegmentChooser
    },
    directives: {
        PendoAffix
    },
    props: {
        showSegmentPicker: {
            type: Boolean,
            default: true
        },
        showDatePicker: {
            type: Boolean,
            default: true
        },
        showAppPicker: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapState({
            activeSegmentId: (state) => state.filters.activeSegmentId,
            updatingActiveApp: (state) => state.apps.updatingActive,
            dateRange: (state) => state.filters.dateRange
        }),
        ...mapGetters({
            usesMultiApp: 'subscriptions/usesMultiApp'
        })
    },
    methods: {
        ...mapActions({
            updateActiveSegmentId: 'filters/updateActiveSegmentId',
            updateDateRange: 'filters/updateDateRange'
        })
    }
};
</script>

<style lang="scss">
.global-filters {
    &--inner {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &--bottom {
        padding: 12px 0;
    }

    .filters {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        &--label,
        &--item {
            margin-right: 1em;
            font-size: 0.875em;
            line-height: 1.25em;
        }

        &--item {
            &:last-of-type {
                margin-right: unset;
            }
        }

        .date-filter {
            &--primary-text {
                margin-right: 0.5em;
            }

            &--secondary-text {
                color: $gray-lighter-3;
                font-size: 0.875em;
            }

            &--selected-label {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;

                .pendo-icon {
                    margin-right: 0.5em;
                }
            }
        }
    }
}
</style>
