<template>
    <div
        v-if="activeApp && activeApp.id"
        class="guide-list">
        <guide-list-empty
            v-if="showDAEmptyState"
            :loading="loading"
            @showGuideCreateChange="$emit('showGuideCreateChange', $event)" />
        <div v-if="!showDAEmptyState">
            <global-filters>
                <template #right>
                    <search
                        v-model="searchInput"
                        placeholder="Search Guides" />
                </template>
            </global-filters>
            <pendo-table
                ref="guideListTable"
                :data="filteredList"
                :filters="filters"
                :columns="columns"
                :default-sort="sort"
                :loading="loading"
                :row-class-name="getRowClassName"
                csv-download
                auto-height
                title="Guides"
                row-key="id"
                class="guide-list-table"
                :resizable="true"
                @column-resize="onColumnResized"
                @select-change="onSelectRowChange"
                @select-clear="onSelectRowClear">
                <template #headerLeft>
                    <div class="guide-list-table--table-header-left">
                        <div class="guide-list-table--table-title">
                            Guides
                        </div>
                        <div class="guide-list-table--active-count">
                            ({{ selectedAndTotalGuidesCount }})
                        </div>
                        <bulk-actions-header :selected-row-count="selectedTableRows.length">
                            <template #actions>
                                <pendo-button
                                    data-cy="guide-list--copy-guides"
                                    theme="via"
                                    type="link"
                                    prefix-icon="repeat"
                                    label="Copy to Account"
                                    @click="openBulkCopy" />
                            </template>
                        </bulk-actions-header>
                    </div>
                </template>
                <template #name="{ row }">
                    <router-link
                        :to="`guides/${row.id}/settings`"
                        class="guide-list-table--link">
                        {{ row.name }}
                    </router-link>
                </template>
                <template #state="{ row }">
                    <guide-status-changer
                        :guide="row"
                        :guide-type="type"
                        size="mini" />
                </template>
                <template #editorType="{ row }">
                    <div class="editor-type-cell">
                        <pendo-icon
                            size="14"
                            :type="editorTypeToHuman(row) === 'Adopt Studio' ? 'adopt-studio' : 'visual-design-studio'" />
                        {{ editorTypeToHuman(row) }}
                    </div>
                </template>
                <template #actions="{ row }">
                    <guide-utilities
                        :is-dropdown="false"
                        :guide="row"
                        @openGuideCopyModal="openGuideCopyModal" />
                </template>
                <div
                    slot="empty"
                    class="guide-list-table--empty">
                    <pendo-icon
                        type="alert-circle"
                        class="empty-icon"
                        stroke="#9a9ca5"
                        size="24" />
                    <span class="empty-text">
                        {{ emptyText }}
                    </span>
                </div>
            </pendo-table>
            <guide-copy-modal-v1
                :visible="showGuideCopyModalV1"
                :guides="selectedTableRows"
                @close="closeGuideCopyModalV1"
                @closed="onBulkCopyClose" />
            <guide-copy-modal
                :visible="showGuideCopyModal"
                :guide="guide"
                @close="closeGuideCopyModal" />
            <bulk-guide-copy-drawer
                ref="bulkCopyDrawer"
                :visible="showBulkCopyDrawer"
                :guides="selectedTableRows"
                @close="closeBulkCopyDrawer"
                @closed="onBulkCopyClose" />
        </div>
    </div>
</template>

<script>
import get from 'lodash/get';
import { mapActions, mapGetters, mapState } from 'vuex';
import { PendoIcon, PendoTable, PendoButton } from '@pendo/components';
import Search from '@/components/Search';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import GuideStatusChanger from '@/components/guides/common/GuideStatusChanger';
import GuideUtilities from '@/components/guides/common/GuideUtilities';
import GlobalFilters from '@/components/filters/GlobalFilters';
import { getInlineSegmentSummary, getMissingEntityText } from '@/utils/segments';
import GuideCopyModal from '@/components/guides/list/GuideCopyModal';
import GuideCopyModalV1 from '@/components/guides/list/GuideCopyModalV1';
import GuideListEmpty from '@/components/guides/list/GuideListEmpty';
import { filterEntitiesByAppIds } from '@/utils/apps';
import BulkGuideCopyDrawer from '@/components/guides/list/bulk-guide-copy/BulkGuideCopyDrawer';
import BulkActionsHeader from '@/components/common/BulkActionsHeader';

import cloneDeep from 'lodash/cloneDeep';
import { EDITOR_TYPES } from '@pendo/services/Constants';

export default {
    name: 'GuideListTable',
    components: {
        GuideCopyModal,
        GuideCopyModalV1,
        PendoIcon,
        PendoTable,
        PendoButton,
        GuideListEmpty,
        GuideStatusChanger,
        GuideUtilities,
        GlobalFilters,
        Search,
        BulkGuideCopyDrawer,
        BulkActionsHeader
    },
    props: {
        // eslint-disable-next-line vue/require-default-prop
        type: {
            type: String,
            validator: (type) => ['partner', 'organization'].includes(type)
        }
    },
    data () {
        return {
            searchInput: '',
            selectedTableRows: [],
            unsubscribeFilterBarListener: null,
            showGuideCopyModal: false,
            showGuideCopyModalV1: false,
            showBulkCopyDrawer: false,
            guide: null,
            sort: { prop: 'name', order: 'ascending' }
        };
    },
    computed: {
        ...mapState({
            segmentsMap: (state) => state.filters.segmentsMap,
            guideMap: (state) => state.guides.map,
            isFetchingGuides: (state) => state.guides.isFetching,
            pageMap: (state) => state.pages.map,
            featureMap: (state) => state.features.map,
            appIdsFilter: (state) => state.filters.appIdsFilter,
            guidesListUserSettings: (state) => state.guides.guidesListUserSettings
        }),
        ...mapGetters({
            list: 'guides/list',
            isActiveDASubAndHasMoreDASubs: 'subscriptions/isActiveDASubAndHasMoreDASubs',
            isActiveAEUSubAndHasMoreAEUSubs: 'subscriptions/isActiveAEUSubAndHasMoreAEUSubs',
            activeApp: 'apps/active',
            isAppIdsFilterInUse: 'filters/isAppIdsFilterInUse',
            hasMultipleAccounts: 'subscriptions/hasMultipleAccounts',
            activeUsesV2Adopt: 'subscriptions/activeUsesV2Adopt',
            activeIsTrainingSubscription: 'subscriptions/activeIsTrainingSubscription',
            activeUsesDADesigner: 'subscriptions/activeUsesDADesigner',
            activeSubIsDA: 'subscriptions/activeIsDigitalAdoption',
            usesMultiApp: 'subscriptions/usesMultiApp',
            hasSegmentFlag: 'auth/hasSegmentFlag'
        }),
        selectedAndTotalGuidesCount () {
            const totalGuidesLength = get(this.$refs, 'guideListTable.tableData.length', this.list.length);

            return this.selectedTableRows.length
                ? `${this.selectedTableRows.length} of ${totalGuidesLength} Selected`
                : totalGuidesLength;
        },
        canUseV2BulkGuideCopy () {
            return this.isActiveAEUSubAndHasMoreAEUSubs || this.isActiveDASubAndHasMoreDASubs;
        },
        isNotV2AndHasMultipleAccounts () {
            return !this.activeUsesV2Adopt && this.hasMultipleAccounts;
        },
        columns () {
            const columns = [];
            if (this.isNotV2AndHasMultipleAccounts || this.canUseV2BulkGuideCopy) {
                columns.push({
                    type: 'checkbox',
                    fixed: true,
                    allowResize: false
                });
            }

            columns.push({
                prop: 'name',
                label: 'Guide Name',
                sortable: true,
                width: get(this.guidesListUserSettings, 'sizes.name')
            });

            if (this.usesMultiApp) {
                columns.push({
                    prop: 'app.displayName',
                    label: 'Application',
                    sortable: true,
                    allowResize: false
                });
            }

            columns.push(
                {
                    prop: 'state',
                    label: 'Status',
                    sortable: true,
                    width: 140,
                    allowResize: false
                },
                {
                    prop: 'lastUpdatedByUser.username',
                    label: 'Last updated by',
                    sortable: true,
                    allowResize: false
                },
                {
                    prop: 'createdByUser.username',
                    label: 'Created by',
                    sortable: true,
                    allowResize: false
                },
                {
                    prop: 'launchMethod',
                    label: 'Activation',
                    formatter: (row) => this.activationToHuman(row),
                    sortable: true,
                    allowResize: false
                },
                {
                    prop: 'steps.length',
                    label: '# of Steps',
                    sortable: true,
                    allowResize: false
                },
                {
                    prop: 'numViews',
                    label: 'Guide Views',
                    sortable: true,
                    async: true,
                    allowResize: false
                },
                {
                    prop: 'segment',
                    formatter: (row) => this.segmentSummary(row),
                    label: 'Targeted Segment',
                    showOverflow: 'clamp',
                    allowResize: false
                },
                {
                    type: 'actions',
                    width: 120,
                    allowResize: false
                }
            );

            if (this.appHasTwoDesigners) {
                const index = columns.findIndex((col) => col.label === 'Created by');
                columns.splice(index, 0, {
                    prop: 'editorType',
                    label: 'Edited with',
                    sortable: true,
                    allowResize: false
                });
            }

            return columns;
        },
        loading () {
            return this.isFetchingGuides;
        },
        filters () {
            return [
                {
                    prop: [
                        'name',
                        'state',
                        'lastUpdatedByUser.username',
                        'createdByUser.username',
                        'launchMethod',
                        'app.displayName'
                    ],
                    type: 'includes',
                    value: this.searchInput
                }
            ];
        },
        filteredList () {
            if (this.usesMultiApp) {
                return filterEntitiesByAppIds(this.list, this.appIdsFilter);
            }

            const { id } = this.activeApp;

            return this.list.filter((guide) => guide.appId === id);
        },
        emptyText () {
            const noResultsText =
                this.searchInput || this.isAppIdsFilterInUse
                    ? 'There are no guides matching that search criteria.'
                    : "You haven't created any guides yet.";

            return this.type === 'organization' ? noResultsText : 'There are no public training guides available.';
        },
        showDAEmptyState () {
            if (!this.activeUsesDADesigner) return false;
            if (this.usesMultiApp) return !this.list.length;

            return !this.filteredList.length;
        },
        appHasTwoDesigners () {
            if (!this.activeSubIsDA) {
                return false;
            }

            let hasTwoDesigners = false;
            let isAdoptStudio = false;
            let isVDS = false;
            hasTwoDesigners = this.filteredList.some((guide) => {
                if (
                    guide.editorType === EDITOR_TYPES.ADOPT_UI ||
                    guide.editorType === EDITOR_TYPES.ADOPT_STUDIO ||
                    guide.editorType === ''
                ) {
                    isAdoptStudio = true;
                }
                if (guide.editorType === EDITOR_TYPES.ENGAGE_UI || guide.editorType === EDITOR_TYPES.VDS) {
                    isVDS = true;
                }

                return isAdoptStudio && isVDS;
            });

            return hasTwoDesigners;
        }
    },
    created () {
        this.fetchGuideViews();
        this.fetchGuides();
        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, () => {
            this.fetchGuideViews();
        });
    },
    destroyed () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        ...mapActions({
            updateGuide: 'guides/update',
            fetchGuides: 'guides/fetch',
            fetchGuideViews: 'guideAnalytics/fetchGuideViews',
            updateGuidesListUserSettings: 'guides/updateGuidesListUserSettings'
        }),
        activationToHuman (guide, override = false) {
            const activationMethods = guide.launchMethod.split('-').sort();

            return activationMethods
                .map((method) => {
                    return {
                        api: 'Launched via API',
                        auto: override ? 'Automatically with Override' : 'Automatically',
                        badge: 'Badge',
                        dom: 'Element Click',
                        launcher: 'Resource Center'
                    }[method];
                })
                .join(', ');
        },
        getRowClassName ({ row }) {
            const summary = this.segmentSummary(row);
            if (summary && summary.startsWith('Error')) {
                return 'entity-missing';
            }

            return '';
        },
        segmentSummary (guide) {
            const segmentId = get(guide, 'audienceUiHint.filters[0].segmentId');
            if (!segmentId) return 'Everyone';

            const segment = this.segmentsMap[segmentId];

            if (!segment) {
                return getMissingEntityText('segment', segmentId);
            }

            return getInlineSegmentSummary(segment, this.guideMap, this.pageMap, this.featureMap);
        },
        onSelectRowChange ({ selectedRows }) {
            this.selectedTableRows = selectedRows;
        },
        onSelectRowClear () {
            this.selectedTableRows = [];
        },
        openGuideCopyModal (guide) {
            this.showGuideCopyModal = true;
            this.guide = guide;
        },
        closeGuideCopyModal () {
            this.showGuideCopyModal = false;
        },
        openGuideCopyModalV1 () {
            this.showGuideCopyModalV1 = true;
        },
        closeGuideCopyModalV1 () {
            this.showGuideCopyModalV1 = false;
        },
        closeBulkCopyDrawer () {
            this.showBulkCopyDrawer = false;
        },
        onBulkCopyClose () {
            this.$refs.guideListTable.clearSelected();
        },
        openGuideCopyDrawer () {
            this.showBulkCopyDrawer = true;
        },
        openBulkCopy () {
            if (this.activeUsesV2Adopt) {
                this.openGuideCopyDrawer();
                this.$refs.bulkCopyDrawer.findUniquePages();
            } else {
                this.openGuideCopyModalV1();
            }
        },
        onColumnResized ({ column, width }) {
            const guidesListUserSettings = cloneDeep(this.guidesListUserSettings) || {};
            if (!guidesListUserSettings.sizes) {
                guidesListUserSettings.sizes = {};
            }

            guidesListUserSettings.sizes[column] = width;
            this.updateGuidesListUserSettings({
                guidesListUserSettings
            });
        },
        editorTypeToHuman (guide) {
            switch (guide.editorType) {
                case '':
                case EDITOR_TYPES.ADOPT_UI:
                case EDITOR_TYPES.ADOPT_STUDIO:
                    return 'Adopt Studio';
                case EDITOR_TYPES.ENGAGE_UI:
                case EDITOR_TYPES.VDS:
                    return 'VDS';
                default:
                    return '-';
            }
        }
    }
};
</script>

<style lang="scss">
.guide-list-table {
    .entity-missing {
        color: $red-error;
        font-weight: bold;
    }

    &--empty {
        display: flex;
        align-items: center;
        justify-content: center;

        .pendo-icon {
            margin-right: 0.5em;
            display: flex;
        }

        .empty-text {
            color: $gray-primary;
        }
    }

    .pendo-table__cell {
        word-break: unset;
    }

    &--table-header-left {
        align-items: center;
        display: flex;
        gap: 8px;
    }

    &--active-count {
        font-size: 18px;
        color: $gray-lighter-2;
        font-variant-numeric: tabular-nums;
    }
}

.editor-type-cell {
    display: flex;
    align-items: center;

    i {
        margin-right: 1em;
    }
}
</style>
