<template>
    <div class="reset-password">
        <div class="reset-password--main">
            <h2 class="reset-password--header">
                Reset Password
            </h2>
            <div class="reset-password--description">
                Enter your new password for your Adopt account.
            </div>
            <pendo-form
                :form-defaults="model"
                :form-ref="formRef">
                <pendo-form-item
                    :rules="rules.newPassword"
                    label="New Password"
                    prop="newPassword">
                    <pendo-input
                        v-model.trim="model.newPassword"
                        type="password"
                        show-password-strength
                        class="reset-password--input"
                        @score="handlePasswordStrengthChange" />
                </pendo-form-item>
                <pendo-form-item
                    :rules="rules.confirmNewPassword"
                    label="Confirm New Password"
                    prop="confirmNewPassword">
                    <pendo-input
                        v-model.trim="model.confirmNewPassword"
                        type="password"
                        class="reset-password--confirm-input"
                        @keyup.enter.native="resetPassword" />
                </pendo-form-item>
            </pendo-form>
        </div>
        <div class="reset-password--footer">
            <pendo-button
                :loading="isResetting"
                :disabled="disableSubmit"
                type="primary"
                theme="via"
                label="Change my Password"
                @click="resetPassword" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { PendoButton, PendoInput, PendoForm, PendoFormItem } from '@pendo/components';

export default {
    name: 'ResetPasswordForm',
    components: {
        PendoButton,
        PendoForm,
        PendoFormItem,
        PendoInput
    },
    data () {
        return {
            isResetting: false,
            formRef: 'changePasswordForm',
            model: {
                newPassword: '',
                confirmNewPassword: ''
            },
            passwordStrength: 0,
            rules: {
                confirmNewPassword: [
                    {
                        validator: this.validatePasswordMatch,
                        trigger: 'blur'
                    }
                ]
            }
        };
    },
    computed: {
        ...mapState({
            hasResetPasswordError: (state) => state.auth.hasResetPasswordError,
            resetPasswordToken: (state) => state.route.params.token,
            email: (state) => state.route.params.email
        }),
        validPassword () {
            const length = this.model.newPassword.length > 11;
            const strength = this.passwordStrength > 2;
            const notEmail = this.model.newPassword !== this.email;

            return length && strength && notEmail;
        },
        passwordsMatch () {
            return this.model.newPassword === this.model.confirmNewPassword;
        },
        disableSubmit () {
            return !this.validPassword || !this.passwordsMatch;
        }
    },
    methods: {
        ...mapActions({
            _login: 'auth/login',
            _resetPassword: 'auth/resetPassword'
        }),
        validatePasswordMatch (rule, value, callback) {
            if (value !== this.model.newPassword) {
                callback(new Error('Passwords must match'));
            } else {
                callback();
            }
        },
        handlePasswordStrengthChange (score) {
            this.passwordStrength = score;
        },
        async resetPassword () {
            if (this.disableSubmit) return;

            this.isResetting = true;
            await this._resetPassword({ token: this.resetPasswordToken, password: this.model.newPassword });

            if (this.hasResetPasswordError) {
                this.isResetting = false;

                return;
            }

            this.$router.push({ name: 'login' });
        }
    }
};
</script>

<style lang="scss">
.reset-password {
    .reset-password--header {
        margin: 0.25rem 0;
    }

    .reset-password--description {
        margin-top: 0;
        margin-bottom: 1rem;
        line-height: 1.4;
    }

    .reset-password--input {
        margin-bottom: 1em;
    }

    .reset-password--confirm-input {
        margin-bottom: 1em;
    }

    .reset-password--main {
        padding: 0 1.25rem;
    }

    .reset-password--footer {
        padding: 1.25rem;
        border-top: 1px solid $gray-lighter-6;
        display: grid;
        grid-auto-flow: column;
        justify-content: end;
        grid-gap: 1rem;
    }
}
</style>
