<template>
    <div class="powered-by">
        <div class="powered-by--content">
            <span
                :class="`powered-by--text--${textColor}`">
                Powered by
            </span>
            <a
                class="powered-by--link"
                href="https://www.pendo.io"
                target="_blank">
                <adopt-logo
                    :text-color="textColor"
                    class="powered-by--logo" />
            </a>
        </div>
    </div>
</template>

<script>
import AdoptLogo from '@/components/icons/AdoptLogo';

export default {
    name: 'PoweredBy',
    components: {
        AdoptLogo
    },
    props: {
        textColor: {
            type: String,
            default: 'dark',
            validator: (textColor) => ['light', 'dark'].includes(textColor)
        }
    }
};
</script>

<style lang="scss">
.powered-by {
    display: grid;
    padding: 2rem 0 2rem 2rem;
    align-items: center;
    justify-content: start;

    &--content {
        display: grid;
        align-items: center;
        grid-auto-flow: column;
        grid-gap: 8px;
    }

    &--logo {
        width: 104px;
        height: 17px;
        display: grid;
    }

    &--text {
        font-size: 0.75em;
        font-weight: 600;

        &--dark {
            color: $gray-lighter-2;
        }

        &--light {
            color: $white;
        }
    }
}
</style>
