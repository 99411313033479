<template>
    <div class="page-header-wrapper">
        <div
            v-if="showTopNavRow"
            class="top-nav-row">
            <div class="row">
                <app-select v-if="!usesMultiApp" />
                <div
                    class="brand"
                    :class="{ 'non-multi-app': !usesMultiApp }">
                    <img
                        v-if="brand.logoUrl"
                        :src="brand.logoUrl"
                        class="logo">
                    <svg
                        v-if="brand.productName"
                        class="divider">
                        <path d="M0 0v22" />
                    </svg>
                    <div
                        v-if="brand.productName"
                        class="product-name">
                        {{ brand.productName }}
                    </div>
                </div>
            </div>
        </div>
        <pendo-page-header
            :title="editable ? title : computedTitle"
            :loading="loading"
            class="page-header">
            <template #top>
                <slot name="top">
                    <div
                        v-if="!usesMultiApp"
                        data-cy="pendo-page-header--account-name"
                        class="page-header--account-name">
                        {{ accountName }}
                    </div>
                </slot>
            </template>
            <template #bottom>
                <slot name="bottom" />
            </template>
            <template #actions>
                <div class="page-header--actions">
                    <slot name="actions" />
                </div>
            </template>
            <template #title>
                <div class="pendo-page-header__title-alert">
                    <h1
                        v-if="!editable"
                        class="pendo-page-header__title">
                        {{ computedTitle }}
                    </h1>
                    <pendo-editable-content
                        v-if="editable"
                        content-slot-class="pendo-page-header__title"
                        auto-width
                        exit-event="blur"
                        :value="title"
                        :type="type"
                        :input-ref="inputRef"
                        :input-props="inputProps"
                        :empty-text="emptyText"
                        :before-exit="beforeExit"
                        :validation-rules="validationRules">
                        <template #content>
                            <h1 class="pendo-page-header__title">
                                {{ title }}
                            </h1>
                        </template>
                    </pendo-editable-content>
                    <div
                        v-if="showDisabledAppBanner"
                        data-cy="disable-app-banner"
                        class="page-header--disable-alert">
                        <pendo-alert
                            v-if="showBanner"
                            type="warning"
                            :title="disabledAppTitle" />
                    </div>
                </div>
            </template>
        </pendo-page-header>
    </div>
</template>

<script>
import { PendoPageHeader, PendoAlert, PendoEditableContent } from '@pendo/components';
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import AppSelect from '@/components/app-picker/AppSelect';

export default {
    name: 'PageHeader',
    components: {
        AppSelect,
        PendoPageHeader,
        PendoAlert,
        PendoEditableContent
    },
    inheritAttrs: false,
    props: {
        onlyTitle: {
            type: Boolean,
            default: false
        },
        showBanner: {
            type: Boolean,
            default: false
        },
        specificApp: {
            type: Object,
            default: () => {}
        },
        // pendo-page-header props
        title: {
            type: String,
            default: undefined
        },
        editable: {
            type: Boolean,
            default: false
        },
        beta: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        // pendo-editable-contnent props
        value: {
            type: [String, Number],
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        inputRef: {
            type: String,
            default: 'input'
        },
        inputProps: {
            type: Object,
            default: () => ({})
        },
        exitEvent: {
            type: String,
            default: undefined
        },
        emptyText: {
            type: String,
            default: 'Not Set'
        },
        beforeExit: {
            type: Function,
            default: null
        },
        validationRules: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        ...mapGetters({
            account: 'subscriptions/activeAccount',
            activeSub: 'subscriptions/active',
            isEmptyStateDigitalAdoption: 'subscriptions/isEmptyStateDigitalAdoption',
            activeUsesV2Adopt: 'subscriptions/activeUsesV2Adopt',
            usesMultiApp: 'subscriptions/usesMultiApp',
            hasSegmentFlag: 'auth/hasSegmentFlag',
            app: 'apps/active',
            manuallySelectedDisabledApps: 'filters/manuallySelectedDisabledApps'
        }),
        showTopNavRow () {
            const { usesLeftNav, usesMultiApp, brand } = this;
            const brandingExists = !!brand.logoUrl || !!brand.productName;

            return usesLeftNav && (!usesMultiApp || brandingExists);
        },
        usesLeftNav () {
            return this.hasSegmentFlag('adoptLeftNav') && this.activeUsesV2Adopt;
        },
        appName () {
            return this.app.displayName;
        },
        accountName () {
            return this.account.displayName || this.account.id;
        },
        computedTitle () {
            if (this.isEmptyStateDigitalAdoption) {
                return this.title;
            }

            const doNotShowAppName = this.onlyTitle || this.usesMultiApp;

            return doNotShowAppName ? this.title : `${this.appName} ${this.title}`;
        },
        brand () {
            const logoUrl = get(this.app, 'trainingAttributes.whiteLabelSettings.logoUrl');
            const productName = get(this.activeSub, 'trainingAttributes.productName');

            return {
                logoUrl,
                productName
            };
        },
        specificAppExtensionDisabled () {
            return !!get(this.specificApp, 'applicationFlags.disableExtensionInjection');
        },
        activeAppExtensionDisabled () {
            return !!get(this.app, 'applicationFlags.disableExtensionInjection');
        },
        hasDisabledApps () {
            const userHasDisabledAppsInFilter = !this.specificApp && !!this.manuallySelectedDisabledApps.length;

            return userHasDisabledAppsInFilter || this.specificAppExtensionDisabled;
        },
        showDisabledAppBanner () {
            if (this.usesMultiApp && this.showBanner) {
                return this.hasDisabledApps;
            }

            return this.activeAppExtensionDisabled && this.showBanner;
        },
        disabledAppTitle () {
            if (this.usesMultiApp && !this.specificApp && this.manuallySelectedDisabledApps.length) {
                const disabledAppNames = this.manuallySelectedDisabledApps.map((app) => app.displayName).join(', ');

                return `The following selected applications are disabled, and will not deliver guides or capture new analytics: ${disabledAppNames}`;
            }

            return 'The current application is disabled and will not deliver guides or capture new analytics';
        }
    }
};
</script>

<style lang="scss">
.page-header-wrapper {
    background-color: $white;

    .top-nav-row {
        padding: 16px 32px 0px 32px;

        .row {
            display: flex;
            justify-content: space-between;
        }
    }

    .page-header--account-name {
        font-size: 0.875rem;
    }

    .brand {
        &.non-multi-app {
            padding: 0 0 0 2rem;
        }
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .logo {
            max-width: 148px;
            max-height: 28px;
            vertical-align: middle;
            margin-right: 20px;
        }

        .divider {
            height: 22px;
            width: 1px;

            path {
                stroke: $gray-lighter-5;
                stroke-width: 2;
            }
        }

        .product-name {
            font-size: 0.75em;
            font-weight: bold;
            line-height: 0.9rem;
            color: $navbar-details-color;
            padding-left: 1rem;
            cursor: default;
            white-space: nowrap;
        }
    }

    .pendo-page-header {
        min-height: 70px !important; /* stylelint-disable-line */

        &__top-wrapper {
            flex-direction: column;
        }

        &__title-alert {
            display: flex;
            align-items: center;

            .page-header--disable-alert {
                margin-left: 16px;
            }
        }
    }

    .page-header--actions {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 8px;
    }
}
</style>
