/* eslint-disable id-length */
import * as Agg2 from '@pendo/aggregations';
import { request } from '@/utils/aggregations';

export function getAppUsage ({ appId, metadataField, defaultMetadataField, segmentId, timeSeries, signal }) {
    let spec;
    if (metadataField) {
        spec = buildAppUsageByMetadataSpec({ appId, metadataField, segmentId, timeSeries });
    } else {
        spec = buildAppUsageByTotalSpec({ appId, defaultMetadataField, segmentId, timeSeries });
    }

    return request(spec, { signal });
}

export function buildAppUsageByTotalSpec ({ appId, defaultMetadataField, segmentId, timeSeries }) {
    const { operators: o } = Agg2;

    return o.aggregation(
        'portfolio-app-usage-by-total',
        o.pipeline(
            o.sources.events({
                appId,
                timeSeries
            }),
            o.identified('visitorId'),
            o.segment(segmentId),
            o.filter('appId != -323232'),
            o.group(
                ['appId'],
                o.groupField('summedMinutes', o.sum('numMinutes')),
                o.groupField('visitors', o.count('visitorId')),
                o.groupField('visitorsByDay', o.count(['visitorId', 'day']))
            ),
            o.evalExpression({
                averageTime: 'if(isNull(visitors) || visitors == 0, 0, summedMinutes / visitors)',
                visitors: 'if(isNull(visitors), 0, visitors)',
                averageDailyTimeOnApp: 'if(isNull(visitors) || visitors == 0, 0, summedMinutes / visitorsByDay)',
                groupValue: `"${defaultMetadataField}"`
            }),
            o.select({
                id: 'appId',
                averageTime: 'averageTime',
                averageDailyTimeOnApp: 'averageDailyTimeOnApp',
                summedMinutes: 'summedMinutes',
                visitors: 'visitors',
                groupValue: 'groupValue'
            })
        )
    );
}

export function buildAppUsageByMetadataSpec ({ appId, metadataField, segmentId, timeSeries }) {
    const { operators: o } = Agg2;

    const { group, field } = metadataField;
    const visitorMetadataField = `visitor.${group}.${field}`;

    return o.aggregation(
        'portfolio-app-usage-by-metadata',
        o.pipeline(
            o.sources.events({
                appId,
                timeSeries
            }),
            o.identified('visitorId'),
            o.segment(segmentId),
            o.bulkExpand('visitor'),
            o.filter('appId != -323232'),
            o.evalExpression({
                groupValue: visitorMetadataField
            }),
            o.evalExpression({
                groupValue: 'if(isNull(groupValue), "(none)", groupValue)'
            }),
            o.evalExpression({
                groupValue: 'if(groupValue == true, "true", if(groupValue == false, "false", groupValue))'
            }),
            o.group(
                ['appId', 'groupValue'],
                o.groupField('summedMinutes', o.sum('numMinutes')),
                o.groupField('visitors', o.count('visitorId')),
                o.groupField('visitorsByDay', o.count(['visitorId', 'day']))
            ),
            o.evalExpression({
                averageTime: 'if(isNull(visitors) || visitors == 0, 0, summedMinutes / visitors)',
                visitors: 'if(isNull(visitors), 0, visitors)',
                averageDailyTimeOnApp: 'if(isNull(visitors) || visitors == 0, 0, summedMinutes / visitorsByDay)'
            }),
            o.select({
                id: 'appId',
                groupValue: 'groupValue',
                averageTime: 'averageTime',
                averageDailyTimeOnApp: 'averageDailyTimeOnApp',
                summedMinutes: 'summedMinutes',
                visitors: 'visitors'
            })
        )
    );
}

export function getAppUsageBreakdown ({ appId, metadataField, defaultMetadataField, segmentId, timeSeries, signal }) {
    let spec;
    if (metadataField) {
        spec = buildAppUsageBreakdownByMetadataSpec({ appId, metadataField, segmentId, timeSeries });
    } else {
        spec = buildAppUsageBreakdownByTotalSpec({ appId, defaultMetadataField, segmentId, timeSeries });
    }

    return request(spec, { rowsOnly: false, signal });
}

export function buildAppUsageBreakdownByTotalSpec ({ appId, defaultMetadataField, segmentId, timeSeries }) {
    const { operators: o } = Agg2;

    return o.aggregation(
        'portfolio-app-usage-breakdown-by-total',
        o.pipeline(
            o.sources.events({
                appId,
                timeSeries
            }),
            o.identified('visitorId'),
            o.segment(segmentId),
            o.filter('appId != -323232'),
            o.group(
                'appId',
                o.groupField('summedMinutes', o.sum('numMinutes')),
                o.groupField('visitors', o.count('visitorId')),
                o.groupField('visitorsByDay', o.count(['visitorId', 'day']))
            ),
            o.evalExpression({
                averageTime: 'if(isNull(visitors) || visitors == 0, 0, summedMinutes / visitors)',
                visitors: 'if(isNull(visitors), 0, visitors)',
                averageDailyTimeOnApp: 'if(isNull(visitors) || visitors == 0, 0, summedMinutes / visitorsByDay)',
                groupValue: `"${defaultMetadataField}"`
            }),
            o.select({
                id: 'appId',
                averageTime: 'averageTime',
                averageDailyTimeOnApp: 'averageDailyTimeOnApp',
                summedMinutes: 'summedMinutes',
                visitors: 'visitors',
                groupValue: 'groupValue'
            })
        )
    );
}

export function buildAppUsageBreakdownByMetadataSpec ({ appId, metadataField, segmentId, timeSeries }) {
    const { operators: o } = Agg2;

    const { group, field } = metadataField;
    const visitorMetadataField = `visitor.${group}.${field}`;

    return o.aggregation(
        'portfolio-app-usage-breakdown-by-metadata',
        o.pipeline(
            o.sources.events({
                appId,
                timeSeries
            }),
            o.identified('visitorId'),
            o.segment(segmentId),
            o.bulkExpand('visitor'),
            o.filter('appId != -323232'),
            o.evalExpression({
                groupValue: visitorMetadataField
            }),
            o.evalExpression({
                groupValue: 'if(isNull(groupValue), "(none)", groupValue)'
            }),
            o.evalExpression({
                groupValue: 'if(groupValue == true, "true", if(groupValue == false, "false", groupValue))'
            }),
            o.group(
                ['appId', 'groupValue'],
                o.groupField('summedMinutes', o.sum('numMinutes')),
                o.groupField('visitors', o.count('visitorId')),
                o.groupField('visitorsByDay', o.count(['visitorId', 'day']))
            ),
            o.evalExpression({
                averageTime: 'if(isNull(visitors) || visitors == 0, 0, summedMinutes / visitors)',
                visitors: 'if(isNull(visitors), 0, visitors)',
                averageDailyTimeOnApp: 'if(isNull(visitors) || visitors == 0, 0, summedMinutes / visitorsByDay)'
            }),
            o.select({
                id: 'appId',
                groupValue: 'groupValue',
                averageTime: 'averageTime',
                averageDailyTimeOnApp: 'averageDailyTimeOnApp',
                summedMinutes: 'summedMinutes',
                visitors: 'visitors'
            })
        )
    );
}
