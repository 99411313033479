<template>
    <csv-upload
        title="Upload Visitor CSV"
        description="Select and upload a file to create or update visitors. Visitors that did not exist will be created."
        is-icon
        upload-type="metadata"
        :has-error="hasError"
        :has-warning="hasWarning"
        :has-exclude-header-check="false"
        @error="onError"
        @warning="onWarning"
        @reset="onReset">
        <div
            slot="error-message"
            class="error-message">
            <div v-if="invalidHeaderError">
                CSV needs to begin with the following header: <strong>visitorId</strong>
            </div>
            <div v-else-if="invalidFieldName">
                <strong>Invalid Metadata Field Name</strong>
                <div class="capitalize">
                    {{ errorMessage }}
                </div>
            </div>
            <div v-else-if="tooManyMetadataFields">
                <strong>Too many metadata fields</strong>
                <div class="capitalize">
                    {{ errorMessage }}
                </div>
            </div>
            <div v-else>
                <div class="capitalize">
                    {{ errorMessage }}
                </div>
            </div>
        </div>
        <template #warning-message>
            <div v-if="someVisitorsNotUpdated">
                Some visitors did not update successfully ({{ successCount }}/{{ totalCount }})
            </div>
        </template>
        <template #success-message>
            <div>Visitor metadata processing can take up to 15 mintues to be reflected in the UI</div>
        </template>
    </csv-upload>
</template>

<script>
import CsvUpload from '@/components/segments/builder/rule-components/CsvUpload.vue';

export default {
    name: 'MetadataCsvUpload',
    components: {
        CsvUpload
    },
    data () {
        return {
            errorMessage: '',
            invalidHeaderError: false,
            invalidFieldName: false,
            tooManyMetadataFields: false,
            someVisitorsNotUpdated: false,
            totalCount: 0,
            successCount: 0,
            hasError: false,
            hasWarning: false
        };
    },
    methods: {
        onError (error) {
            switch (error.status) {
                case 400:
                    this.hasError = true;
                    this.errorMessage = error.error;

                    if (error.error.includes('field names may only contain letters')) {
                        this.invalidFieldName = true;
                        break;
                    }

                    if (error.error.includes('first field of header must match kind')) {
                        this.invalidHeaderError = true;
                        break;
                    }

                    if (error.error.includes('may not contain more than 24 metadata fields')) {
                        this.tooManyMetadataFields = true;
                        break;
                    }
                    break;
                default:
                    this.hasError = true;
                    this.errorMessage = error.error;
                    break;
            }
        },
        onWarning (message) {
            const hasUpdateCounts = !isNaN(message.success) && !isNaN(message.total);
            const someVisitorsNotUpdated = message.success < message.total;

            if (hasUpdateCounts && someVisitorsNotUpdated) {
                this.hasWarning = true;
                this.someVisitorsNotUpdated = true;
                this.successCount = message.success;
                this.totalCount = message.total;
            }
        },
        onReset () {
            this.hasError = false;
            this.hasWarning = false;

            this.invalidFieldName = false;
            this.invalidHeaderError = false;
            this.tooManyMetadataFields = false;
            this.someVisitorsNotUpdated = false;
            this.successCount = 0;
            this.totalCount = 0;

            this.errorMessage = '';
        }
    }
};
</script>

<style lang="scss" scoped>
.error-message {
    margin-top: 5px;
}

.capitalize::first-letter {
    text-transform: capitalize;
}
</style>
