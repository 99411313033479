<template>
    <div class="page-rule">
        <pendo-multiselect
            :value="rule.operator"
            :allow-empty="false"
            preselect-first
            full-width
            value-key="value"
            :options="operatorOptions"
            :disabled="!operatorOptions"
            class="segment-rule--operator"
            @select="$emit('input', { field: 'operator', value: $event })" />
        <pendo-multiselect
            :value="pageTimeLabelObject(rule.time)"
            value-key="value"
            label-key="label"
            min-trigger-width="100%"
            :allow-empty="false"
            :preselect-first="true"
            :options="pageTimeRangeOptions"
            class="segment-rule--page-time-operator"
            @select="handlePageTimeSelection" />
        <single-date
            v-if="rule.time === 'since'"
            class="segment-rule--row--item"
            :value="rule.value"
            @input="$emit('input', $event)" />
        <relative-date-range
            v-if="rule.time === 'withinlast'"
            class="segment-rule--row--item complex-rule"
            :value="{ count: rule.value[0], granularity: rule.value[1] }"
            @input="$emit('input', $event)" />
    </div>
</template>

<script>
import RelativeDateRange from '@/components/segments/builder/rule-components/RelativeDateRange.vue';
import SingleDate from '@/components/segments/builder/rule-components/SingleDate.vue';
import { SEGMENT_OPERATORS, PAGE_TIME_RANGE_OPTIONS } from '@/constants/segments';
import { PendoMultiselect } from '@pendo/components';

export default {
    name: 'PageRule',
    components: {
        PendoMultiselect,
        RelativeDateRange,
        SingleDate
    },
    props: {
        rule: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            pageTimeRangeOptions: PAGE_TIME_RANGE_OPTIONS
        };
    },
    computed: {
        operatorOptions () {
            return [...SEGMENT_OPERATORS[this.rule.schema.schema]];
        }
    },
    methods: {
        pageTimeLabelObject (timeString) {
            return this.pageTimeRangeOptions.find((opt) => opt.value === timeString);
        },
        handlePageTimeSelection ($event) {
            const { value } = $event;

            // only 'time' type to require value as an array
            if (value === 'withinlast') {
                this.$emit('input', { field: 'value', value: [30, 'days'] });
            }

            this.$emit('input', { field: 'time', value });
        }
    }
};
</script>
