<template>
    <pendo-drawer
        class="copy-guides-drawer"
        :visible="visible"
        size="large"
        :close-on-mask-click="false"
        @close="handleClose"
        @closed="onDrawerClose">
        <template
            #header
            class="copy-guides-drawer-header">
            <h3 class="pendo-drawer__title">
                {{ drawerTitle }}
            </h3>
            <div class="drawer-header-close">
                <pendo-button
                    theme="p2-light"
                    type="tertiary"
                    icon="x"
                    icon-size="20"
                    @click="handleClose" />
            </div>
        </template>
        <template #body>
            <template v-if="isDefaultState">
                <div class="warning-message">
                    <pendo-alert
                        v-if="ineligibleGuides.length"
                        type="warning">
                        The selected guide(s) have custom segments that must be modified to copy. This means the
                        guide(s) will be visible to <b>Everyone</b> and set to <b>Draft</b> state when copied.
                        <a
                            :href="HELP_ARTICLE"
                            target="_blank">Learn more</a>.
                    </pendo-alert>
                    <pendo-alert
                        v-else
                        type="warning">
                        The selected guide(s) will be set to <b>Draft</b> state when copied.
                        <a
                            :href="HELP_ARTICLE"
                            target="_blank">Learn more</a>.
                    </pendo-alert>
                </div>
                <select-target-location
                    :guides="guides"
                    @targetsSelected="handleTarget" />
                <select-page-mappings
                    v-if="!loadingUniquePages && filteredUniquePagesArray.length"
                    :unique-pages="uniquePages"
                    :unique-pages-arr="filteredUniquePagesArray"
                    :guides="guides"
                    :target-pages="targetPages"
                    :target-application="targetApplication"
                    :target-subscription="targetSubscription"
                    :target-pages-loading="targetPagesLoading"
                    :source-pages-loading="sourcePagesLoading"
                    @pageSelected="pageSelected" />
            </template>
            <template v-if="isProgressState">
                <div class="guide-copy-drawer__progress">
                    <pendo-progress-bar
                        ref="copyGuidesProgressBar"
                        class="copy-progress-bar"
                        :colors="progressBarColors"
                        :value="copyStatus.progress"
                        :width="400"
                        :height="8"
                        trickle />
                    <div class="guide-copy-drawer__progress-status">
                        {{ copyStatus.success }} of {{ copyStatus.total }}
                        {{ copyStatus.total > 1 ? 'Guides' : 'Guide' }} copied
                    </div>
                </div>
            </template>
            <template v-if="isResultState">
                <div class="guide-copy-drawer__status-container">
                    <pendo-alert
                        v-if="hasFailures"
                        type="error">
                        <div class="guide-copy-drawer__failure-alert">
                            {{ failedAlertText }}
                        </div>
                    </pendo-alert>
                    <pendo-alert
                        v-if="!hasFailures"
                        type="success">
                        <div class="guide-copy-drawer__success-alert">
                            {{ copyStatus.success }} {{ copyStatus.success > 1 ? 'Guides' : 'Guide' }} copied to
                            <strong>{{ targetApplication.displayName }}</strong> in
                            <strong>{{ targetSubscription.displayName }}</strong>.
                        </div>
                    </pendo-alert>
                    <div
                        v-if="hasFailures"
                        class="guide-copy-drawer__failure-list">
                        <div>The following guides failed to copy:</div>
                        <div
                            v-for="guide in failedGuideList"
                            :key="guide.id"
                            class="guide-copy-drawer__failed-guide">
                            {{ guide.name }}
                        </div>
                    </div>
                </div>
            </template>
        </template>
        <template
            #footer
            class="copy-guides-drawer-footer">
            <div
                v-if="isDefaultState"
                class="copy-guide-drawer__footer">
                <pendo-button
                    class="cancel-guide-copy"
                    label="Cancel"
                    type="secondary"
                    theme="via"
                    @click="handleClose" />
                <pendo-button
                    :disabled="!canCopy"
                    :loading="isCopying"
                    label="Copy Guides"
                    type="primary"
                    theme="via"
                    @click="copyGuides(undefined)" />
            </div>
            <div
                v-if="isResultState"
                class="copy-guide-drawer__footer">
                <pendo-button
                    label="Close"
                    data-cy="copy-guide-drawer--close"
                    theme="via"
                    type="secondary"
                    @click="handleClose" />
                <pendo-button
                    v-if="hasFailures"
                    label="Retry Failures"
                    data-cy="copy-guide-drawer--retry"
                    theme="via"
                    type="primary"
                    @click="retryFailedCopies" />
            </div>
        </template>
    </pendo-drawer>
</template>

<script>
import { PendoDrawer, PendoButton, PendoTooltip, PendoAlert, PendoProgressBar } from '@pendo/components';
import { mapGetters, mapActions } from 'vuex';
import SelectTargetLocation from '@/components/guides/list/bulk-guide-copy/SelectTargetLocation';
import SelectPageMappings from '@/components/guides/list/bulk-guide-copy/SelectPageMappings';
import { copyGuides as utilsCopyGuides } from '@/utils/copy-guides';
import { createCustomPage } from '@/state/modules/pages.module';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { loadTargetAppPages } from '@/utils/copy-guides';
import { PROGRESS_COLORS } from '@/constants/progress';

const DRAWER_STATES = Object.freeze({
    DEFAULT: 'default',
    PROGRESS: 'progress',
    RESULT: 'result'
});

const HELP_ARTICLE = 'https://adoptpartners.pendo.io/hc/en-us/articles/4416566040475';

export default {
    name: 'BulkGuideCopyDrawer',
    components: {
        PendoDrawer,
        PendoButton,
        PendoProgressBar,
        PendoAlert,
        SelectTargetLocation,
        SelectPageMappings
    },
    directives: {
        PendoTooltip
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        guides: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            targetSubscription: null,
            targetApplication: null,
            isCopying: false,
            uniquePages: {},
            loadingUniquePages: false,
            guidesWithPageMap: this.guides,
            targetPages: [],
            targetPagesLoading: false,
            sourcePagesLoading: false,
            filteredUniquePagesArray: [],
            HELP_ARTICLE,
            copyStatus: {
                failed: [],
                total: 0,
                percent: 0,
                success: 0
            }
        };
    },
    computed: {
        ...mapGetters({
            getGuideById: 'guides/getGuideById',
            getPageById: 'pages/pageById'
        }),
        isDefaultState () {
            return this.drawerState === DRAWER_STATES.DEFAULT;
        },
        isProgressState () {
            return this.drawerState === DRAWER_STATES.PROGRESS;
        },
        isResultState () {
            return this.drawerState === DRAWER_STATES.RESULT;
        },
        drawerState () {
            if (this.isCopying) {
                return DRAWER_STATES.PROGRESS;
            }

            if (this.copyStatus.percent === 100) {
                return DRAWER_STATES.RESULT;
            }

            return DRAWER_STATES.DEFAULT;
        },
        drawerTitle () {
            return {
                default: 'Copy Guides To Account',
                progress: 'Copying Guides',
                result: 'Copying Guides Complete'
            }[this.drawerState];
        },
        progressBarColors () {
            // default -> success
            let complete = PROGRESS_COLORS.SUCCESS;

            // warning -> some requests passed, some failed
            if (this.copyStatus.success !== this.copyStatus.total) {
                complete = PROGRESS_COLORS.WARNING;
            }

            // error -> all requests failed
            if (!this.copyStatus.success) {
                complete = PROGRESS_COLORS.ERROR;
            }

            return {
                default: PROGRESS_COLORS.DEFAULT,
                progress: PROGRESS_COLORS.PROGRESS,
                complete
            };
        },
        allPagesSelected () {
            return this.filteredUniquePagesArray.every((page) => page.targetPage);
        },
        anyGuideIsTargetingPages () {
            return this.guides.some((guide) => {
                if (!guide.steps) guide = this.getGuideById(guide.id);

                return guide.steps.some((step) => step.pageId);
            });
        },
        targetsSelected () {
            return this.targetApplication && this.targetSubscription;
        },
        guideIdsToCopy () {
            return this.guides.map((guide) => guide.id);
        },
        failedAlertText () {
            const failedCount = this.copyStatus.failed;
            const guidesLabel = failedCount > 1 ? 'Guides' : 'Guide';

            return `${failedCount} ${guidesLabel} failed to copy to ${this.targetApplication.displayName} in ${
                this.targetSubscription.displayName
            }.`;
        },
        ineligibleGuides () {
            return this.guides.filter((guide) => {
                const segmentId = get(guide, 'audienceUiHint.filters[0].segmentId', null);

                return segmentId && segmentId !== 'everyone';
            });
        },
        canCopy () {
            return (
                this.targetsSelected &&
                (!this.anyGuideIsTargetingPages || this.allPagesSelected) &&
                !this.targetPagesLoading
            );
        },
        uniquePagesArray () {
            return Object.values(this.uniquePages);
        },
        pagesToCreate () {
            return this.uniquePagesArray.filter((page) => page.action === 'create');
        },
        hasFailures () {
            return this.copyStatus.failed > 0;
        }
    },
    watch: {
        guides (newVal) {
            this.guidesWithPageMap = newVal;
        }
    },
    async created () {
        this.sourcePagesLoading = true;
        await this.fetchSourcePages();
        this.sourcePagesLoading = false;
    },
    methods: {
        ...mapActions({
            fetchSourcePages: 'pages/fetch'
        }),
        handleClose () {
            this.$emit('close');
        },
        async fetchTargetAppPages () {
            try {
                const response = await loadTargetAppPages({
                    targetSubId: this.targetSubscription.id,
                    targetAppId: this.targetApplication.id
                });
                this.targetPages = response.data;
            } catch (err) {
                this.targetPages = [];
            }
        },
        async createNewTargetPages () {
            const pageRequests = this.pagesToCreate.map(async (page) => {
                const { name, rules } = page.sourcePage;
                const customPage = {
                    name,
                    rules,
                    appId: this.targetApplication.id
                };

                const newTargetPage = await createCustomPage(customPage, this.targetSubscription.id).catch(() => false);
                if (!newTargetPage) {
                    return;
                }

                this.uniquePages[page.id].targetPage = newTargetPage;
                this.uniquePages[page.id].action = 'use';
            });

            await Promise.all(pageRequests);
        },
        findPageInTargetApp (sourcePage) {
            return this.targetPages.find(
                (page) => page.name === sourcePage.name && isEqual(page.rules, sourcePage.rules)
            );
        },
        async copyGuides (guideIds = this.guideIdsToCopy) {
            this.failedGuideList = [];
            const guidesToCopy = this.guidesWithPageMap.filter((guide) => guideIds.includes(guide.id));
            this.copyStatus.total = guidesToCopy.length;

            this.isCopying = true;
            await this.createNewTargetPages();
            this.generatePageMapForGuides();
            await this.$nextTick();
            this.$refs.copyGuidesProgressBar.start();

            const { failures } = await utilsCopyGuides(
                {
                    guides: guidesToCopy,
                    targetSubId: parseInt(this.targetSubscription.id),
                    targetAppId: parseInt(this.targetApplication.id)
                },
                this.isInProgress
            );

            this.failedGuideList = failures;
        },
        retryFailedCopies () {
            const guideIds = this.failedGuideList.map(({ id }) => id);
            this.copyStatus = {
                failed: [],
                total: guideIds.length,
                percent: 0,
                success: 0
            };
            this.copyGuides(guideIds);
        },
        isInProgress ({ total, successes, failed }) {
            const completed = successes + failed;
            const copyStatus = {
                ...this.copyStatus,
                failed,
                total,
                success: successes
            };

            copyStatus.percent = (completed / total) * 100;
            this.copyStatus = copyStatus;

            if (this.copyStatus.percent < 100) return;
            this.$refs.copyGuidesProgressBar.done();
            setTimeout(() => {
                this.isCopying = false;
            }, 1000);
        },
        async handleTarget (payload) {
            this.targetSubscription = payload.targetSub;
            this.targetApplication = payload.targetApp;
            if (this.targetApplication && this.anyGuideIsTargetingPages) {
                this.sourcePagesLoading = true;
                await this.fetchSourcePages();
                this.sourcePagesLoading = false;
                this.targetPagesLoading = true;
                await this.fetchTargetAppPages();
                this.preloadExistingPages();
                this.targetPagesLoading = false;
            }
        },
        findUniquePages () {
            this.loadingUniquePages = true;
            this.guides.forEach((guide) => {
                if (!guide.steps) guide = this.getGuideById(guide.id);
                guide.steps.forEach((step) => {
                    if (step.pageId) {
                        if (this.uniquePages[step.pageId]) {
                            if (!this.uniquePages[step.pageId].locations[guide.id]) {
                                this.uniquePages[step.pageId].locations[guide.id] = [];
                            }
                            this.uniquePages[step.pageId].locations[guide.id].push(step.id);
                        } else {
                            const locations = {
                                [guide.id]: [step.id]
                            };
                            const sourcePage = this.getPageById(step.pageId);
                            this.uniquePages[step.pageId] = { sourcePage, locations };
                        }
                        this.uniquePages[step.pageId].targetPage = null;
                        this.uniquePages[step.pageId].action = null;
                        this.uniquePages[step.pageId].id = step.pageId;
                    }
                });
            });
            this.loadingUniquePages = false;
        },
        preloadExistingPages () {
            this.uniquePagesArray.forEach((page) => {
                const { sourcePage, id } = page;
                const pageInTargetApp = this.findPageInTargetApp(sourcePage);
                const pageExists = !!pageInTargetApp;
                const createPage = this.createPageOnTargetAppOption(sourcePage);
                const hasTargetPages = this.targetPages.length;
                this.uniquePages[id].selected = false;
                if (!hasTargetPages) {
                    this.uniquePages[id].targetPage = createPage;
                } else if (pageExists) {
                    this.uniquePages[id].targetPage = pageInTargetApp;
                    this.uniquePages[id].selected = true;
                } else {
                    this.uniquePages[id].targetPage = null;
                }
                this.uniquePages[id].action = pageExists ? 'use' : 'create';
            });
            this.filteredUniquePagesArray = this.uniquePagesArray.filter((page) => !page.selected);
        },
        createPageOnTargetAppOption (page) {
            return {
                ...page,
                name: 'Create New Page (from Existing Page Rule)',
                createPageOnTargetApp: true
            };
        },
        pageSelected (uniquePagesObject) {
            this.uniquePages = uniquePagesObject;
        },
        onDrawerClose () {
            this.$emit('closed');
            this.reset();
        },
        reset () {
            this.targetSubscription = null;
            this.targetApplication = null;
            this.isCopying = false;
            this.uniquePages = {};
            this.loadingUniquePages = false;
            this.filteredUniquePagesArray = [];
            this.removeSegmentConfirmation = false;
            this.copyStatus = {
                failed: [],
                total: 0,
                percent: 0,
                success: 0
            };
        },
        generatePageMapForGuides () {
            this.uniquePagesArray.forEach((page) => {
                const { locations, targetPage } = page;
                Object.entries(locations).forEach((entry) => {
                    const guideId = entry[0];
                    const stepIds = entry[1];
                    const guide = this.guidesWithPageMap.find((guide) => guide.id === guideId);

                    stepIds.forEach((stepId) => {
                        if (!guide.pageMap) guide.pageMap = {};
                        guide.pageMap[stepId] = {
                            pageId: get(targetPage, 'id', null),
                            action: 'use'
                        };
                    });
                });
            });
        }
    }
};
</script>

<style scoped lang="scss">
.copy-guide-drawer__footer {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    justify-content: end;
}

.warning-message,
.select-target-card {
    margin: 20px;
    margin-bottom: 10px;
}

.select-target-pages-card {
    margin: 0 20px;
    margin-bottom: 20px;
}

.guide-copy-drawer__failed-guide {
    font-weight: 400;
}

.guide-copy-drawer__progress {
    margin: 0 20px;
    display: grid;
    grid-gap: 8px;
    padding-top: 50%;
}

.copy-progress-bar {
    margin: 0 auto;
}

.guide-copy-drawer__progress-status {
    color: $gray-lighter-2;
    font-weight: 600;
    text-align: center;
}

.guide-copy-drawer__success-alert,
.guide-copy-drawer__failure-alert {
    font-weight: 700;
}

.guide-copy-drawer__status-container {
    margin: 30px;
    display: grid;
    grid-gap: 16px;
}

.guide-copy-drawer__failure-list {
    font-weight: bold;
    display: grid;
    grid-gap: 4px;
}
</style>
