var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"pages-list"},[_c('pendo-table',{directives:[{name:"pendo-loading",rawName:"v-pendo-loading:feather",value:(_vm.isFetchingPageList),expression:"isFetchingPageList",arg:"feather"}],ref:"pagesListTable",staticClass:"pages-list--table",attrs:{"data":_vm.pages,"filters":_vm.filters,"columns":_vm.columns,"default-sort":{
            prop: 'numVisitors',
            order: 'descending'
        },"auto-height":true,"auto-height-offset":96,"csv-download":"","title":"Pages","row-key":"id"},scopedSlots:_vm._u([{key:"headerActions",fn:function(){return [_c('div',{staticClass:"pages-list--table-header child-pages"},[_c('div',{staticClass:"visibility-note"},[_vm._v(" Note: Only pages with page view data will appear below. ")]),(_vm.eligibleForCustomPages && _vm.hasSegmentFlag('customPageTaggingEnabled'))?_c('pendo-button',{attrs:{"type":"link","theme":"via","prefix-icon":"plus","label":"Add Custom Pages"},on:{"click":_vm.openAddCustomPagesModal}}):_vm._e()],1)]},proxy:true},{key:"actions",fn:function(ref){
        var row = ref.row;
return [(row.isChildPage && _vm.hasSegmentFlag('customPageTaggingEnabled'))?_c('pendo-actions-cell',{attrs:{"row":row,"actions":[
                    {
                        type: 'edit',
                        icon: 'edit-2',
                        tooltip: 'Rename Page'
                    },
                    {
                        type: 'delete',
                        icon: 'trash-2',
                        tooltip: 'Delete Page'
                    }
                ]},on:{"edit":_vm.openEditCustomPageModal,"delete":_vm.openDeleteCustomPageModal}}):_vm._e()]}},{key:"displayName",fn:function(ref){
                var row = ref.row;
return [(!row.isChildPage)?_c('p',{staticClass:"pages-list--table--display-name"},[_vm._v(" "+_vm._s(row.displayName)+" ")]):_vm._e(),(row.isChildPage)?_c('div',[_c('p',{staticClass:"pages-list--table--display-name"},[_vm._v(" "+_vm._s(row.displayName)+" ")]),_c('p',{staticClass:"pages-list--table--is-custom-page"},[_vm._v(" Custom Page ")])]):_vm._e()]}}])},[_c('div',{staticClass:"pages-list--table--empty",attrs:{"slot":"empty"},slot:"empty"},[_c('pendo-icon',{staticClass:"empty-icon",attrs:{"type":"alert-circle","stroke":"#9a9ca5","size":"24"}}),_c('span',{staticClass:"empty-text"},[_vm._v(" No data found. ")])],1)]),_c('add-custom-pages-modal',{attrs:{"visible":_vm.isAddCustomPagesModalVisible,"eligible-parent-pages":_vm.eligibleParentPages,"child-pages":_vm.childPages},on:{"close":_vm.closeAddCustomPagesModal}}),_c('edit-custom-page-modal',{attrs:{"visible":_vm.isEditCustomPageModalVisible,"custom-page":_vm.currentlyEditingRow},on:{"close":_vm.closeEditCustomPageModal}}),_c('delete-custom-page-modal',{attrs:{"visible":_vm.isDeleteCustomPageModalVisible,"custom-page":_vm.currentlyEditingRow},on:{"close":_vm.closeDeleteCustomPageModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }