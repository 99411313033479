<template>
    <div class="app-details">
        <pendo-card
            title="Basic Settings"
            body-min-height="0"
            class="settings-group">
            <div class="settings-row app-extension-platform-type">
                <div class="settings-label">
                    Application ID
                </div>
                <div class="settings-content">
                    <span>
                        {{ app.id }}
                    </span>
                </div>
            </div>
            <div class="settings-row app-display-name">
                <div class="settings-label">
                    Display Name
                </div>
                <div class="settings-content">
                    <pendo-editable-content
                        :value="app.displayName || 'Not Set'"
                        :before-exit="onDisplayNameChange">
                        <template #append="{ confirm, cancel, pending }">
                            <pendo-button
                                :loading="pending"
                                theme="app"
                                icon="check"
                                type="primary"
                                @click="confirm" />
                            <pendo-button
                                theme="app"
                                type="secondary"
                                icon="x"
                                @click="cancel" />
                        </template>
                    </pendo-editable-content>
                </div>
            </div>
            <div class="settings-row app-extension-platform-type">
                <div class="settings-label">
                    Extension Platform Type
                </div>
                <div class="settings-content">
                    <span>
                        {{ extensionPlatformType }}
                    </span>
                </div>
            </div>
            <div class="settings-row extension-domain-patterns">
                <div class="settings-label">
                    Websites
                </div>
                <div class="settings-content">
                    <edit-extension-domain-patterns :app="app" />
                </div>
            </div>
            <div class="settings-row flag-designer-launch-url-token">
                <div class="settings-label">
                    Disable Designer Launch URL Token
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="updatingAppFlag === 'disableDesignerUrlToken'"
                        :value="app.applicationFlags.disableDesignerUrlToken"
                        @change="handleFlagChange($event, 'disableDesignerUrlToken')" />
                </div>
            </div>
            <div class="settings-row flag-show-guides-anon-visitors">
                <div>
                    <div class="settings-label">
                        <div>
                            Show Guides to Anonymous Visitors
                        </div>
                        <small class="aside">
                            Turning this flag <strong>on or off</strong> requires
                            <strong>resetting the segment</strong> for each pre-existing guide you’d like this change to
                            take effect on.
                        </small>
                    </div>
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="updatingAppFlag === 'showGuidesToAnon'"
                        :value="app.applicationFlags.showGuidesToAnon"
                        @change="handleFlagChange($event, 'showGuidesToAnon')" />
                </div>
            </div>
            <div class="settings-row flag-disable-extension-text-capture">
                <div>
                    <div class="settings-label">
                        <span class="text-capture-label">
                            Text Captured on Feature Clicks
                        </span>
                        <pendo-icon
                            v-pendo-tooltip="{
                                arrow: true,
                                content: opsHelpText,
                                trigger: 'hover',
                                placement: 'right'
                            }"
                            class="ops-help-icon"
                            type="info"
                            size="14" />
                    </div>
                </div>
                <div class="settings-content">
                    {{ app.applicationFlags.disableExtensionTextCapture ? 'No' : 'Yes' }}
                </div>
            </div>
            <div
                v-if="app.extensionPlatformType !== 'other'"
                class="settings-row flag-disable-passdown">
                <div>
                    <div class="settings-label">
                        Disable Inherited Pages and Features
                    </div>
                </div>
                <div class="settings-content">
                    <pendo-checkbox
                        :disabled="updatingAppFlag === 'disablePassDown'"
                        :value="app.applicationFlags.disablePassDown"
                        @change="handleFlagChange($event, 'disablePassDown')" />
                </div>
            </div>
        </pendo-card>
        <custom-html-attributes
            :app="app"
            @error="displayErrorToast" />
    </div>
</template>

<script>
import {
    PendoButton,
    PendoCard,
    PendoCheckbox,
    PendoEditableContent,
    PendoIcon,
    PendoNotification,
    PendoTooltip
} from '@pendo/components';
import { mapActions, mapState } from 'vuex';
import EditExtensionDomainPatterns from '@/components/settings/app-settings/EditExtensionDomainPatterns';
import startCase from 'lodash/startCase';
import CustomHtmlAttributes from '@/components/settings/app-settings/CustomHtmlAttributes';
import { getLabelForPlatformType } from '@pendo/services/Apps';

export default {
    name: 'AppDetails',
    components: {
        PendoButton,
        PendoCard,
        PendoCheckbox,
        PendoIcon,
        PendoEditableContent,
        EditExtensionDomainPatterns,
        CustomHtmlAttributes
    },
    directives: {
        PendoNotification,
        PendoTooltip
    },
    props: {
        app: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            opsHelpText: 'Contact your Pendo representative for assistance changing this setting.'
        };
    },
    computed: {
        ...mapState({
            updatingAppFlag: (state) => state.apps.updatingFlag
        }),
        extensionPlatformType () {
            return getLabelForPlatformType(this.app.extensionPlatformType || 'other');
        }
    },
    methods: {
        ...mapActions({
            updateApp: 'apps/updateApp',
            updateAppFlag: 'apps/updateFlag'
        }),
        async onDisplayNameChange (value) {
            if (value !== this.app.displayName) {
                try {
                    await this.updateApp({
                        app: this.app,
                        updates: { displayName: value }
                    });
                } catch (error) {
                    this.displayErrorToast('setting display name');
                }
            }
        },
        async handleFlagChange (enabled, flag) {
            try {
                await this.updateAppFlag({ appId: this.app.id, flag, enabled });
            } catch (error) {
                this.displayErrorToast('changing setting');
            }
        },
        displayErrorToast (actionCausingError) {
            PendoNotification({
                type: 'error',
                title: `Error ${startCase(actionCausingError)}`,
                message: 'Pendo encountered an error. Please refresh your page and try again.',
                duration: 7000
            });
        }
    }
};
</script>
<style scoped lang="scss">
.app-details {
    display: grid;
    grid-gap: 32px;

    /deep/.aside {
        font-weight: normal;
        color: $gray-lighter-3;
    }

    /deep/.pendo-card__body {
        padding-top: 0;
        padding-bottom: 0;
    }

    /deep/.settings-row {
        border-bottom: 1px solid $gray-lighter-5;
        min-height: 40px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        align-items: center;

        .settings-label {
            font-weight: 600;
            padding: 8px 0;
            width: fit-content;

            .keyboard-shortcut-aside {
                padding-top: 5px;
            }
        }

        .settings-content {
            padding: 8px 0;
        }

        &:last-of-type {
            border-bottom: none;
        }
    }
}

.text-capture-label {
    display: inline-block;
}

.ops-help-icon {
    display: inline-block;
    padding-left: 8px;
    vertical-align: top;
}
</style>
