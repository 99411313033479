<template>
    <pendo-card
        class="confirmation-card"
        body-min-height="0"
        footer-min-height="0">
        <template slot="body">
            <div class="confirmation-header">
                <pendo-icon
                    type="user-plus"
                    class="empty-icon"
                    stroke="#3a3c45"
                    size="24" />
            </div>
            <h3 class="confirmation-info-title">
                {{ content.title }}
            </h3>
            <h3 class="confirmation-info-title confirmation-info-sub-name">
                {{ invitationTokenData.SubscriptionName }}
            </h3>
            <p>
                {{ content.subtitle }}
                <strong class="confirmation-info-sub-name">{{ invitationTokenData.SubscriptionName }}</strong>.
            </p>
        </template>
        <template slot="footer">
            <pendo-button
                data-cy="accept-invite-button"
                type="primary"
                theme="via"
                block
                :label="content.button"
                @click="confirm" />
        </template>
    </pendo-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { PendoCard, PendoButton, PendoIcon } from '@pendo/components';

export default {
    name: 'Confirmation',
    components: {
        PendoCard,
        PendoButton,
        PendoIcon
    },
    data: () => {
        return {
            content: {
                title: "You've been invited to a new subscription in Pendo:",
                button: 'Accept Invite to Subscription',
                subtitle: 'Accept the invite in order to be added to the subscription'
            }
        };
    },
    computed: {
        ...mapState({
            hasUserNotFoundError: (state) => state.auth.hasUserNotFoundError,
            hasInvalidInvitationError: (state) => state.auth.hasInvalidInvitationError,
            hasInvitationExpiredError: (state) => state.auth.hasInvitationExpiredError,
            hasInvalidInvitationKeyError: (state) => state.auth.hasInvalidInvitationKeyError,
            invitationTokenData: (state) => state.auth.invitationTokenData,
            invitationToken: (state) => state.auth.invitationToken
        })
    },
    methods: {
        ...mapActions({
            acceptInvitation: 'auth/acceptInvitation'
        }),
        async confirm () {
            const { invitationToken, invitationTokenData } = this;
            const { Email: email } = invitationTokenData;

            await this.acceptInvitation({ invitationToken });

            switch (true) {
                case this.hasUserNotFoundError:
                    this.navigateTo({ name: 'createAccount', params: { token: invitationToken, email } });
                    break;
                case this.hasInvalidInvitationError:
                    this.navigateTo({ name: 'BadRequest', params: { message: 'Invalid Invitation Data' } });
                    break;
                case this.hasInvitationExpiredError:
                    this.navigateTo({ name: 'BadRequest', params: { message: 'Invitation Expired' } });
                    break;
                case this.hasInvalidInvitationKeyError:
                    this.navigateTo({ name: 'UnprocessableEntity' });
                    break;
                case this.hasAcceptingInvitationError:
                    this.navigateTo({ name: 'notFound' });
                    break;
                default:
                    this.navigateTo({ path: '/' });
            }
        },
        navigateTo (path) {
            this.$router.push(path);
        }
    }
};
</script>

<style lang="scss" scoped>
span,
p,
div,
h3 {
    text-align: center;
}

.confirmation-card {
    border: none !important; // stylelint-disable-line declaration-no-important
    overflow: hidden;
    max-width: 100%;
    height: 100%;

    /deep/ .pendo-card__body {
        padding-bottom: 20px !important; // stylelint-disable-line declaration-no-important
    }
}

.confirmation-info-title {
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
}

.confirmation-info-sub-name {
    color: $gray-lighter-1;
}

.confirmation-header {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
