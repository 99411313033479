<template>
    <div>
        <section>
            <fieldset>
                <div class="role-type__wrapper">
                    <label
                        :data-cy="`role-type-${adminPerms.id}`"
                        class="role-type"
                        :for="adminPerms.id">
                        <input
                            :id="adminPerms.id"
                            :value="adminPerms.id"
                            name="role-type-item"
                            :checked="adminPerms.checked || false"
                            :data-cy="`role-type-radio-${adminPerms.id}`"
                            type="radio"
                            @change="handleRoleChange(adminPerms)">
                        <div class="role-type__radio">
                            <pendo-icon
                                class="role-type__icon"
                                type="check"
                                stroke-width="2.5"
                                size="18"
                                stroke="#FFFFFF" />
                        </div>
                        <div class="role-type-info">
                            <div class="role-type-info__title">
                                {{ adminPerms.title }}
                            </div>
                            <div class="role-type-info__description">
                                {{ adminPerms.description }}
                            </div>
                        </div>
                    </label>
                    <div v-if="adminPerms.checked && showAdminSecondaryOptions">
                        <p class="section-header">
                            Pendo Internal Permissions
                        </p>
                        <section class="secondary-section">
                            <span
                                v-for="option in adminPerms.secondaryOptions"
                                :key="option.id"
                                class="secondary-option">
                                <pendo-toggle
                                    v-pendo-tooltip="{
                                        content: isOptionDisabled(option),
                                        placement: 'top-end'
                                    }"
                                    :value="option.checked || false"
                                    :label="option.name"
                                    :disabled="!!isOptionDisabled(option)"
                                    label-position="left"
                                    :data-cy="`secondary-option-${option.id}`"
                                    @change="handleSecondaryOptionChange(option)" />
                            </span>
                        </section>
                    </div>
                </div>
                <div class="role-type__wrapper">
                    <label
                        :data-cy="`role-type-${userPerms.id}`"
                        class="role-type"
                        :for="userPerms.id">
                        <input
                            :id="userPerms.id"
                            :value="userPerms.id"
                            name="role-type-item"
                            :checked="userPerms.checked || false"
                            :data-cy="`role-type-radio-${userPerms.id}`"
                            type="radio"
                            @change="handleRoleChange(userPerms)">
                        <div class="role-type__radio">
                            <pendo-icon
                                class="role-type__icon"
                                type="check"
                                stroke-width="2.5"
                                size="18"
                                stroke="#FFFFFF" />
                        </div>
                        <div class="role-type-info">
                            <div class="role-type-info__title">
                                {{ userPerms.title }}
                            </div>
                        </div>
                    </label>
                </div>
            </fieldset>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { PendoIcon, PendoToggle, PendoTooltip } from '@pendo/components';
import {
    SUPER_ROLE,
    OPS_ROLE,
    SUBSCRIPTION_ADMIN_ROLE,
    SUBSCRIPTION_USER_ROLE,
    SHARE_SEGMENTS_ROLE_ID,
    SHARE_REPORTS_ROLE_ID,
    VIEWER,
    GUIDE_PUBLISHER,
    GUIDE_CREATOR,
    ANALYST,
    GUIDE_CONTENT_EDITOR
} from '@/constants/roles-and-permissions';
import { isOpsByUserFlag, isSuperByRole } from '@/utils/auth';

export default {
    name: 'RoleTypeContainer',
    components: {
        PendoIcon,
        PendoToggle
    },
    directives: { PendoTooltip },
    props: {
        originalUser: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        }
    },
    data () {
        return {
            roleTypeCheckList: [],
            adminPerms: null,
            userPerms: null
        };
    },
    computed: {
        ...mapGetters({
            isSuperUser: 'auth/isSuper',
            isOpsUser: 'auth/isOps',
            getAppList: 'apps/getList',
            listAll: 'apps/listAll'
        }),
        ...mapState({
            activeSubId: (state) => state.subscriptions.activeId
        }),
        applicationPermissionsMap () {
            const appList = this.getAppList(this.activeSubId);

            return appList.reduce(
                (map, app) => ({
                    ...map,
                    [app.id]: [VIEWER, GUIDE_PUBLISHER, GUIDE_CREATOR, ANALYST, GUIDE_CONTENT_EDITOR]
                }),
                {}
            );
        },
        adminOption () {
            return this.roleTypeCheckList.find(({ id }) => id === SUBSCRIPTION_ADMIN_ROLE);
        },
        opsOption () {
            return this.adminOption.secondaryOptions.find(({ id }) => id === OPS_ROLE);
        },
        superOption () {
            return this.adminOption.secondaryOptions.find(({ id }) => id === SUPER_ROLE);
        },
        userExists () {
            return !isEmpty(this.originalUser);
        },
        showAdminSecondaryOptions () {
            return !isEmpty(this.adminPerms.secondaryOptions);
        },
        showUserSecondaryOptions () {
            return !isEmpty(this.userPerms.secondaryOptions);
        },
        selectedSubRoleIds () {
            const selectedSubRoleIds = [];

            this.roleTypeCheckList.forEach(({ checked, id, secondaryOptions }) => {
                if (checked) {
                    selectedSubRoleIds.push(id);
                    // only pushing these values if the 'parent' option is checked allows us to
                    // persist secondaryOption state when toggling between options
                    // this may not work in the future, though.
                    if (secondaryOptions) {
                        secondaryOptions.forEach((option) => {
                            if (option.checked) {
                                selectedSubRoleIds.push(option.id);
                            }
                        });
                    }
                }
            });

            return selectedSubRoleIds;
        },
        isInvitation () {
            return isEmpty(this.originalUser);
        }
    },
    async created () {
        const { adminPermissions, userPermissions } = this.getInitialRoleOptions();
        this.roleTypeCheckList = cloneDeep([adminPermissions, userPermissions]);
        this.resetCheckedOptions(this.originalUser);

        this.adminPerms = this.roleTypeCheckList.find(({ id }) => id === SUBSCRIPTION_ADMIN_ROLE);
        this.userPerms = this.roleTypeCheckList.find(({ id }) => id === SUBSCRIPTION_USER_ROLE);
    },
    methods: {
        // this resets the checked value on all role options based on which roles exist on a given user
        resetCheckedOptions (user) {
            if (this.userExists && user.subscriptionRoles && user.subscriptionRoles.length > 0) {
                const subscriptionRoleIds = user.subscriptionRoles.map((id) => id);

                // Manually add Role Ids to the list for
                // each field we are not getting back from the API call
                // - ops is driven by a flag
                if (isOpsByUserFlag(user)) subscriptionRoleIds.push(OPS_ROLE);
                // - super is driven by a role number
                if (isSuperByRole(user)) subscriptionRoleIds.push(SUPER_ROLE);
                // - SUBSCRIPTION_USER_ROLE is the default "user" and no longer explicitly used within the API
                if (!subscriptionRoleIds.find((roleId) => roleId === SUBSCRIPTION_ADMIN_ROLE)) {
                    subscriptionRoleIds.push(SUBSCRIPTION_USER_ROLE);
                }

                this.roleTypeCheckList.forEach((role) => {
                    this.$set(role, 'checked', subscriptionRoleIds.includes(role.id));

                    if (role.secondaryOptions) {
                        role.secondaryOptions.forEach((option) => {
                            this.$set(option, 'checked', subscriptionRoleIds.includes(option.id));
                        });
                    }
                });
            } else if (this.userExists) {
                this.roleTypeCheckList.forEach((role) => {
                    this.$set(role, 'checked', role.id === SUBSCRIPTION_USER_ROLE);
                });
            }

            this.$emit('change-sub-level', this.selectedSubRoleIds);
        },
        // Return the tooltip message you would like to display if an item is disabled
        isOptionDisabled (roleOption) {
            if (!roleOption) throw Error();

            const disableChecks = {
                [SUPER_ROLE]: () => {
                    if (!this.isOpsUser) {
                        return 'You must be Ops to toggle Super permissions.';
                    }
                    if (this.isInvitation) {
                        return 'Invite new user first, then promote to Super!';
                    }
                    if (this.opsOption && this.opsOption.checked) {
                        return 'User must be Super if Ops is enabled.';
                    }
                },
                [OPS_ROLE]: () => {
                    if (!this.isOpsUser) {
                        return 'You must be Ops to toggle Ops permissions.';
                    }
                    if (!this.superOption || !this.superOption.checked) {
                        return 'User must be Super before they can be Ops';
                    }
                }
            };

            if (disableChecks[roleOption.id]) {
                return disableChecks[roleOption.id]();
            }

            return null;
        },
        getInitialAdminPermissionOptions () {
            // base default admin permissions
            const adminPermissions = {
                id: SUBSCRIPTION_ADMIN_ROLE,
                title: 'Admin Permissions',
                description:
                    'Admins have full access to the Adopt account. This includes all application settings, guide settings, and full analytics capabilities.',
                secondaryOptions: []
            };

            // ops users can see ops options
            // OR show super option if the user being editted is Ops
            if (this.isOpsUser || isSuperByRole(this.originalUser)) {
                adminPermissions.secondaryOptions.push({
                    id: SUPER_ROLE,
                    name: 'Super Permissions'
                });
            }

            // ops users can see ops options
            // OR show super option if the user being editted is Ops
            if (this.isOpsUser || isOpsByUserFlag(this.originalUser)) {
                adminPermissions.secondaryOptions.push({
                    id: OPS_ROLE,
                    name: 'Ops Permissions'
                });
            }

            return adminPermissions;
        },
        getInitialUserPermissionOptions () {
            return {
                id: SUBSCRIPTION_USER_ROLE,
                title: 'User Permissions',
                description: "Set a user's subscription sharing permissions and application level roles.",
                // setting these both to true initially for backward compatibility in Adopt
                secondaryOptions: [
                    {
                        id: SHARE_SEGMENTS_ROLE_ID,
                        name: 'Share Segments with Everyone',
                        checked: true
                    },
                    {
                        id: SHARE_REPORTS_ROLE_ID,
                        name: 'Share Reports with Everyone',
                        checked: true
                    }
                ]
            };
        },
        getInitialRoleOptions () {
            return {
                adminPermissions: this.getInitialAdminPermissionOptions(),
                userPermissions: this.getInitialUserPermissionOptions()
            };
        },
        handleRoleChange ({ id }) {
            this.roleTypeCheckList.forEach((role) => {
                this.$set(role, 'checked', role.id === id);
            });

            // temporary manner of granting all application roles to subscription users
            if (id === SUBSCRIPTION_USER_ROLE) this.handleAppLevelChange(this.applicationPermissionsMap);

            this.$emit('change-sub-level', this.selectedSubRoleIds);
        },
        handleSecondaryOptionChange (option) {
            this.$set(option, 'checked', !option.checked);
            this.$emit('change-sub-level', this.selectedSubRoleIds);
        },
        handleAppLevelChange (applicationPermissionsMap) {
            this.$emit('change-app-level', applicationPermissionsMap);
        }
    }
};
</script>

<style lang="scss" scoped>
label {
    display: block;
    user-select: none;

    input {
        // hide the radio visually
        background: none;
        border: none;
        display: block;
        margin: 0;
        outline: none;
        padding: 0;
        position: absolute;
        -webkit-appearance: none;

        &:checked ~ .role-type__radio {
            border: 1px solid $teal-primary;
            background-color: $teal-primary;
        }
    }
}

.role-type {
    display: flex;
    padding: 18px 22px;
    cursor: pointer;

    &__wrapper {
        border: 1px solid $gray-lighter-5;
        margin-bottom: 12px;
    }

    &__radio {
        border-radius: 50%;
        min-width: 30px;
        max-width: 30px;
        max-height: 30px;
        min-height: 30px;
        border: 1px solid $gray-lighter-5;
        transition: background-color 200ms;
    }

    &__icon {
        position: relative;
        top: 5px;
        left: 5px;
    }

    &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 24px;

        &__title {
            font-size: 16px;
            color: $gray-primary;
            margin-bottom: 5px;
            font-weight: 600;
        }

        &__description {
            font-size: 14px;
            font-weight: 400;
            color: $gray-lighter-2;
            line-height: 130%;
        }
    }
}

.secondary-section {
    margin: 0px 22px 12px;
}

.section-header {
    text-transform: uppercase;
    color: $gray-lighter-3;
    background-color: $gray-lighter-7;
    font-weight: 700;
    font-size: 12px;
    line-height: 2.3;
    margin-top: 12px;
    margin-bottom: 0px;
    padding-left: 24px;
    border-top: 1px solid $gray-lighter-5;
    border-bottom: 1px solid $gray-lighter-5;
}

/deep/.secondary-option {
    display: inline-block;
    padding: 12px 0;
    width: 100%;

    &:not(:last-child) {
        border-bottom: 1px solid $gray-lighter-5;
    }

    .pendo-toggle.is-inline-label {
        display: flex;
        justify-content: space-between;

        .pendo-toggle__label.pendo-toggle__label--top {
            font-weight: 400;
        }
    }
}
</style>
