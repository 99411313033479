var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-list"},[_c('pendo-table',{directives:[{name:"pendo-loading",rawName:"v-pendo-loading:feather",value:(_vm.isFetchingUserList),expression:"isFetchingUserList",arg:"feather"}],staticClass:"user-list-table",attrs:{"data":_vm.users,"columns":_vm.columns,"row-class-name":_vm.tableRowClassName,"auto-height-offset":96,"default-sort":{
            prop: 'email',
            order: 'ascending'
        },"auto-height":"","title":"Users","row-key":"id"},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
        var row = ref.row;
return (row.id !== _vm.currentUser.id)?_c('div',{staticClass:"user-list-table-buttons",attrs:{"data-user-id":row.id}},[(!row.hasLoggedIn)?_c('pendo-button',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:({
                    content: row.resendInviteSuccess
                        ? 'Invite Sent!'
                        : row.resendInviteError
                            ? 'Try again'
                            : 'Resend Invite',
                    delay: { show: 300, hide: 0 }
                }),expression:"{\n                    content: row.resendInviteSuccess\n                        ? 'Invite Sent!'\n                        : row.resendInviteError\n                            ? 'Try again'\n                            : 'Resend Invite',\n                    delay: { show: 300, hide: 0 }\n                }"}],staticClass:"user-list-table-buttons--resend-invite",class:{ 'is-success': row.resendInviteSuccess },attrs:{"icon":row.resendInviteSuccess ? 'check' : row.resendInviteError ? 'x' : 'send',"loading":row.isResendingInvite,"data-cy":"resend-invite","icon-size":"18","theme":"via","type":"tertiary","size":"medium"},on:{"click":function($event){return _vm.resendInvite(row)}}}):_vm._e(),_c('pendo-button',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:({ content: 'Remove User', delay: { show: 300, hide: 0 } }),expression:"{ content: 'Remove User', delay: { show: 300, hide: 0 } }"}],staticClass:"user-list-table-buttons--remove-user",attrs:{"data-cy":"remove-user","icon-size":"18","theme":"via","type":"tertiary","size":"medium","icon":"trash-2"},on:{"click":function($event){return _vm.confirmRemoveUser(row)}}})],1):_vm._e()}}],null,true)},[_c('div',{staticClass:"user-list-table--empty",attrs:{"slot":"empty"},slot:"empty"},[_c('span',{staticClass:"empty-text"},[_vm._v(" Invite users to join your team! ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }