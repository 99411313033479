<template>
    <div
        class="app-select"
        data-cy="app-select-dropdown">
        <pendo-multiselect
            ref="appSelect"
            :min-trigger-width="100"
            :min-menu-width="304"
            :max-menu-width="304"
            :max-menu-height="342"
            :disabled="updatingActiveApp"
            :popper-options="{ class: popperClasses }"
            stateless
            class="action--app-switcher app-switcher">
            <template #placeholder>
                <div class="app-switcher--label">
                    <pendo-icon
                        type="grid"
                        size="14" />
                    <span>
                        {{ placeholderText }}
                    </span>
                </div>
            </template>
            <template #options>
                <div class="app-list">
                    <pendo-collapse
                        v-if="!activeUsesV2Adopt"
                        v-model="expandedSections"
                        accordion>
                        <app-select-row
                            v-for="app in apps"
                            :key="app.uid"
                            :app="app"
                            :is-active="app.uid === activeApp.uid"
                            @app-selected="updateAppV1" />
                    </pendo-collapse>
                    <pendo-collapse
                        v-if="activeUsesV2Adopt"
                        v-model="expandedSections"
                        accordion>
                        <account-select-row
                            v-for="(apps, groupId, index) in appsWithAccounts"
                            :key="`${groupId}-${index}`"
                            :display-name="apps[0].displayName"
                            :apps="apps"
                            data-cy="app-count"
                            @app-selected="updateApp" />
                    </pendo-collapse>
                </div>
            </template>
            <template
                v-if="!usesLeftNav"
                #footer>
                <pendo-button
                    class="app-switcher--footer"
                    type="secondary"
                    block
                    label="Log Out"
                    @click="onLogout" />
            </template>
        </pendo-multiselect>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { PendoButton, PendoCollapse, PendoIcon, PendoMultiselect } from '@pendo/components';

import AppSelectRow from './AppSelectRow';
import AccountSelectRow from './AccountSelectRow';

export default {
    name: 'AppSelect',
    components: {
        PendoButton,
        PendoIcon,
        PendoCollapse,
        PendoMultiselect,
        AppSelectRow,
        AccountSelectRow
    },
    data () {
        return {
            expandedSections: []
        };
    },
    computed: {
        ...mapState({
            route: (state) => state.route,
            updatingActiveApp: (state) => state.apps.updatingActive
        }),
        ...mapGetters({
            apps: 'apps/listAll',
            appsWithAccounts: 'apps/listAllWithAccounts',
            activeApp: 'apps/active',
            activeUsesV2Adopt: 'subscriptions/activeUsesV2Adopt',
            activeAccount: 'subscriptions/activeAccount',
            getAccountList: 'subscriptions/getAccountList',
            areAggregationsInFlight: 'analytics/areAggregationsInFlight',
            hasSegmentFlag: 'auth/hasSegmentFlag'
        }),
        placeholderText () {
            return this.activeApp.displayName || 'Select App';
        },
        popperClasses () {
            let classes = 'app-switcher--panel';

            if (this.usesLeftNav) {
                classes += ' left-nav';
            }

            return classes;
        },
        usesLeftNav () {
            return this.hasSegmentFlag('adoptLeftNav') && this.activeUsesV2Adopt;
        }
    },
    methods: {
        ...mapActions({
            updateActiveApp: 'apps/updateActive',
            logout: 'auth/logout'
        }),
        async onLogout () {
            try {
                await this.logout();
                this.$router.push({ name: 'login', query: this.route.query });
            } catch (err) {
                window.location.reload(true);
            }
        },
        async updateAppV1 ({ app, accountId }) {
            const accounts = this.getAccountList(app);

            if (accounts.length > 1 && !accountId) {
                return;
            }

            this.$refs.appSelect.toggleMenu();
            await this.updateActiveApp({ app, accountId, validateRoute: true });
        },
        async updateApp ({ app, accountId }) {
            this.$refs.appSelect.toggleMenu();
            await this.updateActiveApp({ app, accountId, validateRoute: true });
        }
    }
};
</script>

<style lang="scss">
.app-switcher {
    border: 1px solid $gray-lighter-5;
    border-radius: 3px;

    .pendo-multiselect__trigger {
        transition: all 200ms;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.21);
        border-color: $transparent;
        padding-right: 16px;

        &.is-open {
            box-shadow: none;
            border-color: $transparent;
        }
    }

    .pendo-multiselect__suffix {
        display: none;
    }

    .app-switcher--label {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;

        .pendo-icon {
            margin-right: 0.5em;
        }
    }
}

.app-switcher--panel {
    .pendo-picker-popper__content {
        display: grid;
        grid-template-rows: 1fr 50px;
        border: 1px solid $gray-lighter-5;
        border-radius: 5px;
    }

    &.left-nav .pendo-picker-popper__content {
        grid-template-rows: 1fr;
    }

    .app-list {
        overflow-y: auto;

        .pendo-collapse {
            border-top: 0;
            border-bottom: 0;
        }

        .pendo-collapse-item {
            .pendo-collapse-item__header {
                height: 64px;
                grid-template-columns: auto 1fr auto;
                grid-gap: 8px;
                line-height: inherit;
                border-bottom-color: $gray-lighter-5;

                &:hover {
                    cursor: pointer;
                    background-color: $gray-lighter-6;
                }
            }

            .app--details {
                overflow: hidden;
            }

            .app--details--name {
                font-size: 1rem;
                font-weight: bold;
                line-height: 1.1875rem;
                @include ellipsis;
            }

            .pendo-collapse-item__content {
                padding-bottom: 0;
            }

            .pendo-collapse-item__suffix-icon svg {
                stroke: $gray-primary;
            }

            &:last-child {
                .pendo-collapse-item__header {
                    border-bottom: 0;
                }
            }

            &.is-active {
                .pendo-collapse-item__header {
                    background-color: $gray-lighter-7;
                }
            }

            &.is-expanded {
                border-bottom: 1px solid $gray-lighter-5;

                .pendo-collapse-item__header {
                    border-bottom-color: $transparent;
                }

                .pendo-collapse-item__header,
                .pendo-collapse-item__wrap {
                    background-color: $gray-lighter-8;
                }
            }
        }
    }

    .app-switcher--footer {
        height: 50px !important; /* stylelint-disable-line */
        background-color: $white;
        border-radius: 0;
        text-decoration: none;
        border-top: 1px solid $gray-lighter-5;

        &:hover {
            background-color: $gray-lighter-7;
        }
    }
}
</style>
