import http from '@/utils/http';
import { convertToSubscriptionTimezone } from '@/utils/moment';
import isNaN from 'lodash/isNaN';

const COMPLETE = 100;

export const METADATA_GROUP_COLORS = {
    agent: '#6a6c75',
    custom: '#8029e9'
};

export const ALL_TYPES = [
    {
        label: 'Text',
        value: 'string'
    },
    {
        label: 'Boolean',
        value: 'boolean'
    },
    {
        label: 'Number (int)',
        value: 'integer'
    },
    {
        label: 'Number (float)',
        value: 'float'
    },
    {
        label: 'Date',
        value: 'time'
    },
    {
        label: 'List',
        value: 'list'
    }
];

export const defaultAutoMetadataFieldsList = [
    {
        field: 'visitor.auto.id',
        name: 'Visitor ID',
        schema: 'string',
        type: 'metadata.auto.id'
    },
    {
        field: 'visitor.auto.accountid',
        name: 'Last Active Account',
        schema: 'string',
        type: 'metadata.auto.accountid'
    },
    {
        field: 'visitor.auto.accountids',
        name: 'All Accounts',
        schema: 'list',
        type: 'metadata.auto.accountids'
    },
    {
        field: 'visitor.auto.firstvisit',
        name: 'First Visit',
        schema: 'time',
        type: 'metadata.auto.firstvisit'
    },
    {
        field: 'visitor.auto.lastbrowsername',
        name: 'Browser Name',
        schema: 'string',
        type: 'metadata.auto.lastbrowsername'
    },
    {
        field: 'visitor.auto.lastoperatingsystem',
        name: 'Operating System',
        schema: 'string',
        type: 'metadata.auto.lastoperatingsystem'
    },
    {
        field: 'visitor.auto.lastupdated',
        name: 'Last Updated',
        schema: 'time',
        type: 'metadata.auto.lastupdated'
    },
    {
        field: 'visitor.auto.lastVisitAppDisplayName',
        name: 'Last Active App',
        schema: 'string',
        type: 'metadata.auto.lastVisitAppDisplayName'
    },
    {
        field: 'visitor.auto.lastvisit',
        name: 'Last Visit',
        schema: 'time',
        type: 'metadata.auto.lastvisit'
    }
];

export function augmentVisitorRowWithLastActiveApp (row) {
    const lastVisitedAppFieldRegex = /^visitor_auto_([0-9]+)_lastvisit$/;

    const appTimestampMap = Object.keys(row).reduce((acc, columnName) => {
        const appIdMatch = columnName.match(lastVisitedAppFieldRegex);
        if (!appIdMatch) return acc;

        const lastVisitTimestamp = row[columnName];
        const appId = parseInt(appIdMatch[1]);

        acc[appId] = lastVisitTimestamp;

        return acc;
    }, {});

    const { appId, lastVisit } = computeMostRecentAppFromMap(appTimestampMap);

    if (lastVisit) {
        row.lastVisitAppId = appId;
        row.lastVisit = lastVisit;
    }

    return row;
}

export function augmentSingleVisitorObjectWithLastActiveApp (visitor, appMap) {
    const autoMetadataRegex = /^auto_([0-9]+)$/;
    const { metadata } = visitor;

    const appTimestampMap = Object.keys(metadata).reduce((acc, metadataKey) => {
        const appIdMatch = metadataKey.match(autoMetadataRegex);
        if (!appIdMatch) return acc;

        const lastVisitTimestamp = metadata[metadataKey].lastvisit;
        const appId = parseInt(appIdMatch[1]);

        acc[appId] = lastVisitTimestamp;

        return acc;
    }, {});

    const { appId } = computeMostRecentAppFromMap(appTimestampMap);

    metadata.auto.lastVisitAppDisplayName = '';

    if (appId) {
        metadata.auto.lastVisitAppId = appId;
        metadata.auto.lastVisitAppDisplayName = appMap[appId].displayName;
    }

    return visitor;
}

export function computeMostRecentAppFromMap (appLastVisitMap = {}) {
    let lastVisitedAppId;
    let mostRecentVisitTimestamp = 0;

    Object.keys(appLastVisitMap).forEach((appId) => {
        const lastVisitTimestamp = appLastVisitMap[appId];

        if (lastVisitTimestamp > mostRecentVisitTimestamp) {
            mostRecentVisitTimestamp = lastVisitTimestamp;
            lastVisitedAppId = parseInt(appId);
        }
    });

    return {
        appId: lastVisitedAppId,
        lastVisit: mostRecentVisitTimestamp
    };
}

export async function fetchVisitorMetadata (visitorId) {
    return http
        .get(`/api/s/_SID_/visitor/${visitorId}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err.response;
        });
}

export async function uploadMetadata (body, notify = () => {}) {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return http
        .post('/api/s/_SID_/metadata/visitor/custom/value?create=true', body, config)
        .then((res) => {
            notify({ progress: COMPLETE });
            notify({ progress: { command: 'finish', warnings: res.data } });

            return res.data;
        })
        .catch((err) => {
            notify({ progress: COMPLETE });
            notify({ progress: { command: 'errors' } });
            throw err.response;
        });
}

export function isCorrectType (type, value) {
    const floatRegex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    const intRegex = /^[-+]?\d+$/;
    if (value === null) return true;

    switch (type) {
        case 'string':
            return true;
        case 'boolean':
            return value === 'true' || value === 'false';
        case 'integer':
            return intRegex.test(value);
        case 'float':
            return floatRegex.test(value);
        case 'time': {
            const isInvalidDate = value === 'Invalid date';

            return !isInvalidDate && convertToSubscriptionTimezone(value).format() === value;
        }
    }

    return false;
}

export async function updateVisitorMetadata (visitorId, fieldName, value) {
    if (value === undefined || isNaN(value)) value = null;
    const config = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    };
    const body = JSON.stringify(value);

    return http
        .put(`/api/s/_SID_/metadata/visitor/custom/value/${visitorId}/${fieldName}`, body, config)
        .then((res) => res.data);
}
