<template>
    <pendo-modal
        :visible="visible"
        class="delete-page-modal"
        height="auto"
        max-height="80%"
        type="basic"
        title="Delete Custom Page?"
        @close="closeModal">
        <template #body>
            <pendo-alert
                v-if="deleteCustomPageError"
                type="error">
                Something went wrong. Try again - if the problem persists contact Pendo support.
            </pendo-alert>
            <span>
                Are you sure you want to delete this custom page?
            </span>
            <span
                v-if="pageReferencedInGuideOrSegment">This page is referenced in one or more places and will break the following:
            </span>
            <div v-if="guidesUsingCustomPage.length">
                <strong
                    class="delete-page-modal--warning-spacing delete-page-modal--error-text">The following guides are targeted to this page and must be retargeted before you can delete</strong>
                <div
                    v-for="guide in guidesUsingCustomPage"
                    :key="guide.id">
                    {{ guide.name }}
                </div>
                <pendo-divider stroke="#dadce5" />
            </div>
            <div v-if="segmentsUsingCustomPage.length">
                <strong class="delete-page-modal--warning-spacing">Segments that reference this page</strong>
                <div
                    v-for="segment in segmentsUsingCustomPage"
                    :key="segment.id">
                    {{ segment.name }}
                </div>
            </div>
            <div v-if="guidesUsingSegmentsUsingCustomPage.length">
                <strong
                    class="delete-page-modal--warning-spacing">Guides using segments that reference this page</strong>
                <div
                    v-for="guide in guidesUsingSegmentsUsingCustomPage"
                    :key="guide.id">
                    {{ guide.name }}
                </div>
            </div>
        </template>
        <template #footer>
            <div class="delete-page-modal--footer">
                <pendo-button
                    theme="via"
                    type="secondary"
                    label="Cancel"
                    @click="closeModal" />
                <div
                    v-pendo-tooltip="
                        guidesUsingCustomPage.length && {
                            arrow: true,
                            multiline: true,
                            content: 'Resolve guide references to enable deletion of this page.'
                        }
                    ">
                    <pendo-button
                        :disabled="!!guidesUsingCustomPage.length"
                        :loading="isDeletingCustomPage"
                        theme="via"
                        type="danger"
                        label="Delete"
                        @click="handleDeleteCustomPage" />
                </div>
            </div>
        </template>
    </pendo-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PendoAlert, PendoButton, PendoDivider, PendoModal, PendoLoading, PendoTooltip } from '@pendo/components';
import sortBy from 'lodash/sortBy';

export default {
    name: 'DeleteCustomPageModal',
    components: {
        PendoAlert,
        PendoButton,
        PendoDivider,
        PendoModal
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        customPage: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            isDeletingCustomPage: false,
            deleteCustomPageError: false
        };
    },
    computed: {
        ...mapGetters({
            guidesList: 'guides/list',
            segmentsList: 'filters/segmentsList'
        }),
        pageReferencedInGuideOrSegment () {
            return (
                this.guidesUsingCustomPage.length ||
                this.segmentsUsingCustomPage.length ||
                this.guidesUsingSegmentsUsingCustomPage.length
            );
        },
        guidesUsingCustomPage () {
            const guidesUsingCustomPage = this.guidesList.filter((guide) => {
                return guide.steps.some((guideStep) => {
                    return guideStep.pageId === this.customPage.id;
                });
            });

            return sortBy(guidesUsingCustomPage, 'name');
        },
        segmentsUsingCustomPage () {
            const segmentsUsingCustomPage = this.segmentsList.filter((segment) => {
                if (!segment.pipeline) return;
                const segmentMatch = JSON.stringify(segment.pipeline).includes(this.customPage.id);

                return segmentMatch;
            });

            return sortBy(segmentsUsingCustomPage, 'name');
        },
        guidesUsingSegmentsUsingCustomPage () {
            const guidesUsingSegmentsUsingCustomPage = this.guidesList.filter((guide) => {
                if (!guide.audience) return;
                const stringifiedGuideSegment = JSON.stringify(guide.audience);
                const segmentMatch = this.segmentsUsingCustomPage.some((segment) =>
                    stringifiedGuideSegment.includes(segment.id)
                );

                return segmentMatch;
            });

            return sortBy(guidesUsingSegmentsUsingCustomPage, 'name');
        }
    },
    methods: {
        ...mapActions({
            deleteCustomPage: 'pages/deleteCustomChildPage'
        }),
        closeModal () {
            this.$emit('close');
        },
        async handleDeleteCustomPage () {
            this.deleteCustomPageError = false;
            this.isDeletingCustomPage = true;
            try {
                await this.deleteCustomPage({ customPage: this.customPage });
                this.closeModal();
            } catch (err) {
                this.deleteCustomPageError = true;
            }
            this.isDeletingCustomPage = false;
        }
    }
};
</script>

<style lang="scss">
.delete-page-modal {
    &--warning-spacing {
        display: block;
        margin: 8px 0;
    }

    &--error-text {
        color: $red-error;
    }

    &--footer {
        display: flex;
        justify-content: flex-end;
    }
}

.delete-page-modal {
    .pendo-button {
        margin-left: 8px;
    }

    .el-alert {
        margin-bottom: 18px;
    }
}
</style>
