<template>
    <div class="analytics-dashboard">
        <global-filters />
        <div class="analytics-dashboard--grid">
            <template v-if="!showFeaturesWidgets">
                <div class="analytics-dashboard--row">
                    <div class="analytics-dashboard--column">
                        <top-pages />
                    </div>
                    <div
                        v-if="!usesMultiApp"
                        class="analytics-dashboard--column">
                        <analytics-visitor-counts class="analytics-dashboard--visitor-counts" />
                        <analytics-visitor-overview class="analytics-dashboard--visitor-overview" />
                    </div>
                </div>
            </template>
            <template v-if="showFeaturesWidgets">
                <div
                    v-if="!usesMultiApp"
                    class="analytics-dashboard--row half-size-row">
                    <analytics-visitor-counts class="analytics-dashboard--visitor-counts" />
                    <analytics-visitor-overview class="analytics-dashboard--visitor-overview" />
                </div>
                <div class="analytics-dashboard--row">
                    <top-pages class="top-pages" />
                    <top-features />
                </div>
            </template>
            <div class="analytics-dashboard--row">
                <visitors-over-time class="analytics-dashboard--visitors-over-time" />
            </div>
            <div class="analytics-dashboard--row">
                <page-feature-usage-over-time v-if="!updatingActiveApp" />
            </div>
            <div class="analytics-dashboard--row">
                <page-use-by-visitor />
                <feature-use-by-visitor v-if="showFeaturesWidgets" />
            </div>
            <div class="analytics-dashboard--row">
                <analytics-visitor-list
                    :enable-visitor-link="activeUsesV2Adopt"
                    class="analytics-dashboard--visitor-list" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AnalyticsVisitorList from '@/components/analytics/AnalyticsVisitorList';
import AnalyticsVisitorCounts from '@/components/analytics/AnalyticsVisitorCounts';
import AnalyticsVisitorOverview from '@/components/analytics/AnalyticsVisitorOverview';
import TopPages from '@/components/analytics/pages/TopPages';
import TopFeatures from '@/components/analytics/features/TopFeatures';
import PageUseByVisitor from '@/components/analytics/pages/PageUseByVisitor';
import FeatureUseByVisitor from '@/components/analytics/features/FeatureUseByVisitor';
import PageFeatureUsageOverTime from '@/components/analytics/combined/PageFeatureUsageOverTime';
import VisitorsOverTime from '@/components/analytics/VisitorsOverTime';
import GlobalFilters from '@/components/filters/GlobalFilters';
import { PendoLoading } from '@pendo/components';

export default {
    name: 'AnalyticsDashboard',
    components: {
        AnalyticsVisitorList,
        AnalyticsVisitorCounts,
        AnalyticsVisitorOverview,
        TopPages,
        TopFeatures,
        PageFeatureUsageOverTime,
        PageUseByVisitor,
        FeatureUseByVisitor,
        VisitorsOverTime,
        GlobalFilters
    },
    directives: {
        PendoLoading
    },
    computed: {
        ...mapGetters({
            showFeaturesWidgets: 'features/showFeaturesWidgets',
            activeUsesV2Adopt: 'subscriptions/activeUsesV2Adopt',
            usesMultiApp: 'subscriptions/usesMultiApp'
        }),
        ...mapState({
            updatingActiveApp: (state) => state.apps.updatingActive
        })
    }
};
</script>

<style lang="scss">
.analytics-dashboard {
    .analytics-dashboard--grid {
        display: grid;
        grid-gap: 32px;
    }

    .analytics-dashboard--row {
        display: grid;
        grid-gap: 32px;
        grid-template-columns: repeat(auto-fit, minmax(504px, 1fr));

        &:not(:last-child) {
            min-height: 448px;
        }

        &.half-size-row {
            min-height: 224px;
        }
    }

    .analytics-dashboard--column {
        display: grid;
        grid-gap: 32px;
        grid-template-columns: repeat(auto-fit, minmax(504px, 1fr));
        min-height: 440px;
    }
}
</style>
