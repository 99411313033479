<template>
    <div :class="{ 'target-selectors': bulkCopy }">
        <div
            class="modal-description"
            data-cy="copy-modal-description">
            <p v-if="!bulkCopy">
                Choose the account where you would like a copy of this guide to appear. The copied guide will be set to
                <strong>Draft</strong> state when copied to the account.
            </p>
            <span
                v-if="bulkCopy"
                class="copy-modal-title">
                Copy Guides to
            </span>
            <br>
        </div>
        <div
            v-if="!bulkCopy"
            class="guide-selector"
            data-cy="guide-selector">
            <pendo-input
                :placeholder="guide.name"
                label="Guide"
                :disabled="true" />
            <br>
        </div>
        <div class="subscription-selector">
            <pendo-multiselect
                v-model="targetSubscription"
                full-width
                :labels="{ top: !bulkCopy && 'Account' }"
                :options="eligibleSubscriptions"
                placeholder="-- Choose Account --"
                label-key="displayName"
                @input="fetchApplications(targetSubscription)">
                <template #selectedLabel="{ option }">
                    <pendo-icon-option :option="option" />
                </template>
            </pendo-multiselect>
        </div>
        <br v-if="!bulkCopy">
        <span
            v-if="bulkCopy"
            class="separator">-</span>
        <div class="application-selector">
            <pendo-multiselect
                v-model="targetApplication"
                full-width
                :loading="isLoadingApplications"
                :labels="{ top: !bulkCopy && 'Application' }"
                :options="eligibleApplications"
                placeholder="-- Choose Application --"
                label-key="displayName">
                <template #selectedLabel="{ option }">
                    <pendo-icon-option :option="option" />
                </template>
            </pendo-multiselect>
        </div>
    </div>
</template>

<script>
import { PendoIconOption, PendoInput, PendoMultiselect } from '@pendo/components';
import { mapGetters } from 'vuex';
import get from 'lodash/get';

export default {
    name: 'SelectTarget',
    components: {
        PendoIconOption,
        PendoInput,
        PendoMultiselect
    },
    props: {
        guide: {
            type: Object,
            default: () => ({})
        },
        priorSelectedTarget: {
            type: Object,
            default: undefined
        },
        bulkCopy: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            applications: [],
            targetSubscription: null,
            targetApplication: null,
            isLoadingApplications: false
        };
    },
    computed: {
        ...mapGetters({
            subscriptions: 'subscriptions/listAll',
            activeSub: 'subscriptions/active',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            activeIsTrainingSubscription: 'subscriptions/activeIsTrainingSubscription',
            isActiveDASubAndHasMoreDASubs: 'subscriptions/isActiveDASubAndHasMoreDASubs',
            isActiveAEUSubAndHasMoreAEUSubs: 'subscriptions/isActiveAEUSubAndHasMoreAEUSubs'
        }),
        eligibleSubscriptions () {
            const { isActiveDASubAndHasMoreDASubs, isActiveAEUSubAndHasMoreAEUSubs } = this;
            const activeSubId = this.activeSub.id;

            return this.subscriptions.reduce((total, sub) => {
                if (sub.id === activeSubId) return total;
                if (isActiveDASubAndHasMoreDASubs && !get(sub, 'isDigitalAdoption', false)) return total;
                if (isActiveAEUSubAndHasMoreAEUSubs && !get(sub, 'isTrainingSubscription', false)) return total;

                return total.concat({
                    ...sub,
                    name: sub.displayName,
                    icon: { type: 'layers' }
                });
            }, []);
        },
        eligibleApplications () {
            const validApplications = this.applications.reduce((total, app) => {
                if (app.applicationFlags.disabled) return total;
                if (this.activeIsDigitalAdoption && app.platform !== 'extension') return total;
                if (this.activeIsTrainingSubscription && app.platform !== 'web') return total;

                return total.concat({
                    ...app,
                    name: app.displayName,
                    icon: { type: app.platform === 'extension' ? 'puzzle-piece' : 'monitor' }
                });
            }, []);

            return validApplications;
        },
        disableApplicationDropdown () {
            return (this.targetSubscription && this.eligibleApplications.length === 1) || !this.targetSubscription;
        },
        targetsSelected () {
            return this.targetSubscription && this.targetApplication;
        }
    },
    watch: {
        eligibleApplications (validApplications) {
            if (validApplications.length === 1) this.targetApplication = validApplications[0];
        },
        targetsSelected (newState, oldState) {
            if (newState === oldState) return;
            const payload = {
                targetSub: this.targetSubscription,
                targetApp: this.targetApplication
            };
            this.$emit('targetsSelected', payload);
        }
    },
    created () {
        if (this.priorSelectedTarget) {
            this.targetSubscription = this.priorSelectedTarget.targetSub;
            this.fetchApplications(this.targetSubscription, false);
            this.targetApplication = this.priorSelectedTarget.targetApp;
        }
    },
    methods: {
        fetchApplications (sub, changed = true) {
            this.targetApplication = null;
            this.applications = [];

            if (!sub) return;

            this.isLoadingApplications = true;
            this.applications = this.subscriptions.find((subscription) => subscription.id === sub.id).applications;
            this.isLoadingApplications = false;

            if (changed) this.$emit('targetSubChanged');
        },
        reset () {
            this.targetSubscription = null;
            this.targetApplication = null;
        }
    }
};
</script>

<style scoped lang="scss">
.target-selectors {
    display: grid;
    grid-template-columns: auto 1fr auto 1fr;
    grid-gap: 20px;
}

.copy-modal-title {
    min-width: 95px;
}

.modal-description {
    display: table;

    p {
        margin-top: 0;
    }

    span {
        display: table-cell;
        vertical-align: middle;
        white-space: nowrap;
    }
}

.separator {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
