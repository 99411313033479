<template>
    <div class="guide-details-metrics">
        <global-filters :show-app-picker="false" />
        <div class="guide-details-metrics--content">
            <div class="guide-details-metrics--row">
                <guide-activity-chart class="guide-details-metrics--activity-chart" />
            </div>
            <div
                v-if="isMultistep"
                class="guide-details-metrics--row">
                <visitors-per-step-chart
                    :current-step="currentStep"
                    :csv-download-agg-id="visitorPerStepCsvAggId"
                    :steps-seen-visitors-and-accounts="stepsSeenVisitorsAndAccounts"
                    class="guide-details-metrics--visitors-per-step-chart"
                    @update-current-step="updateCurrentStep"
                    @update-steps-seen-visitors-and-accounts="updateStepsSeenVisitorsAndAccounts"
                    @open-side-panel="openSidePanel" />
            </div>
            <div class="guide-details-metrics--row">
                <time-on-guide />
            </div>
            <div
                v-if="isAdoptV2Sub || canAccessNewAnalytics"
                class="guide-details-metrics--row">
                <guide-elements-activity-table
                    :ui-element="uiElement"
                    :csv-download-agg-id="guideElementsCsvAggId"
                    :guide-elements-clicks="guideElementsClicks"
                    class="guide-details-metrics--elements-table"
                    @update-ui-element="updateUiElement"
                    @update-guide-elements-clicks="updateGuideElementsClicks"
                    @open-side-panel="openSidePanel" />
            </div>
            <div
                v-if="!canAccessNewAnalytics"
                class="guide-details-metrics--row">
                <guide-activity-list class="guide-details-metrics--activity-list" />
            </div>
            <div
                v-if="isAdoptV2Sub || canAccessNewAnalytics"
                class="guide-details-metrics--row">
                <guide-activity-table class="guide-details-metrics--activity-table" />
            </div>
            <pendo-side-panel-drawer
                v-bind="sidePanelState"
                @download-csv="downloadSidePanelCsv"
                @create-segment="createSegment"
                @close="closeDrawer" />
        </div>
    </div>
</template>

<script>
import GuideElementsActivityTable from '@/components/guides/metrics/GuideElementsActivityTable';
import GuideActivityChart from '@/components/guides/metrics/GuideActivityChart';
import GuideActivityList from '@/components/guides/metrics/GuideActivityList';
import TimeOnGuide from '@/components/guides/metrics/TimeOnGuide';
import GlobalFilters from '@/components/filters/GlobalFilters';
import VisitorsPerStepChart from '@/components/guides/metrics/VisitorsPerStepChart';
import GuideActivityTable from '@/components/guides/metrics/GuideActivityTable';
import {
    PendoSidePanelDrawer,
    PendoGuideElementsDrawerHeader,
    PendoGuideMetricsDrawerContent
} from '@pendo/components';
import { getStepsSeenVisitorsAndAccountsCsv } from '@/aggregations/visitors-per-step';
import { getGuideElementsClicksCsv } from '@/aggregations/guide-elements-activity';
import { mapState, mapGetters } from 'vuex';
import get from 'lodash/get';

export default {
    name: 'GuideDetailsMetrics',
    components: {
        GlobalFilters,
        GuideActivityChart,
        GuideActivityList,
        TimeOnGuide,
        VisitorsPerStepChart,
        GuideElementsActivityTable,
        PendoSidePanelDrawer,
        GuideActivityTable
    },
    data () {
        return {
            sidePanelState: { visible: false },
            visitorPerStepCsvAggId: 'visitor-per-step-chart', // visitors-per-step-chart
            currentStep: null, // visitors-per-step-chart
            stepsSeenVisitorsAndAccounts: {}, // visitors-per-step-chart
            guideElementsCsvAggId: 'guide-elements', // guide-elements
            uiElement: null, // guide-elements
            guideElementsClicks: {} // guide-elements
        };
    },
    computed: {
        ...mapState({
            activeSegmentId: (state) => state.filters.activeSegmentId
        }),
        ...mapGetters({
            guide: 'guides/active',
            activeTimeSeries: 'filters/activeTimeSeries',
            isAdoptV2Sub: 'subscriptions/activeUsesV2Adopt',
            hasSegmentFlag: 'auth/hasSegmentFlag'
        }),
        appId () {
            return get(this.guide, 'appId', '');
        },
        visitorUrl () {
            if (!this.isAdoptV2Sub) return null;

            return '/analytics/visitors';
        },
        isMultistep () {
            return get(this.guide, 'steps.length') > 1;
        },
        timeSeries () {
            return {
                ...this.activeTimeSeries,
                period: 'dayRange'
            };
        },
        canAccessNewAnalytics () {
            return this.hasSegmentFlag('updateAdoptGuideAnalytics');
        }
    },
    methods: {
        updateCurrentStep (point) {
            this.currentStep = point;
        },
        updateStepsSeenVisitorsAndAccounts (data) {
            this.stepsSeenVisitorsAndAccounts = data;
        },
        updateUiElement (element) {
            this.uiElement = element;
        },
        updateGuideElementsClicks (data) {
            this.guideElementsClicks = data;
        },
        openSidePanel (state) {
            this.sidePanelState = { ...state };
        },
        closeDrawer () {
            this.sidePanelState.visible = false;
        },
        createSegment () {
            this.closeDrawer();
        },
        async downloadSidePanelCsv ({ activeTab, csvDownloadAggId }) {
            if (csvDownloadAggId === this.visitorPerStepCsvAggId) await this.downloadVisitorsPerStepCsv(activeTab);
            if (csvDownloadAggId === this.guideElementsCsvAggId) await this.downloadGuideElementsCsv(activeTab);
        },
        async downloadVisitorsPerStepCsv (activeTab) {
            try {
                const {
                    data: { url }
                } = await getStepsSeenVisitorsAndAccountsCsv({
                    appId: this.appId,
                    guideId: this.guide.id,
                    guideStepId: this.currentStep.id,
                    timeSeries: this.timeSeries,
                    segmentId: this.activeSegmentId,
                    kind: activeTab
                });
                this.openSidePanel({
                    header: {
                        props: {
                            title: this.currentStep.name
                        }
                    },
                    body: {
                        component: PendoGuideMetricsDrawerContent,
                        props: {
                            data: this.stepsSeenVisitorsAndAccounts,
                            loading: false,
                            csvModalStatus: 'resolved',
                            csvUrl: url,
                            visitorUrl: this.visitorUrl,
                            showAccountsTab: false,
                            theme: 'via'
                        }
                    },
                    visible: true
                });
            } catch (error) {
                this.openSidePanel({
                    header: {
                        props: {
                            title: this.currentStep
                        }
                    },
                    body: {
                        component: PendoGuideMetricsDrawerContent,
                        props: {
                            loading: false,
                            csvModalStatus: 'rejected',
                            csvUrl: ''
                        }
                    },
                    visible: true
                });
            }
        },
        async downloadGuideElementsCsv (activeTab) {
            try {
                const {
                    data: { url }
                } = await getGuideElementsClicksCsv({
                    appId: this.appId,
                    guideId: this.guide.id,
                    timeSeries: this.timeSeries,
                    segmentId: this.activeSegmentId,
                    uiElementId: this.uiElement.uiElementId,
                    kind: activeTab
                });
                this.openSidePanel({
                    header: {
                        component: PendoGuideElementsDrawerHeader,
                        props: {
                            uiElement: this.uiElement
                        }
                    },
                    body: {
                        component: PendoGuideMetricsDrawerContent,
                        props: {
                            data: this.guideElementsClicks,
                            loading: false,
                            csvModalStatus: 'resolved',
                            csvUrl: url,
                            visitorUrl: this.visitorUrl,
                            showAccountsTab: false,
                            theme: 'via'
                        }
                    },
                    visible: true
                });
            } catch (error) {
                this.openSidePanel({
                    header: {
                        props: {
                            uiElement: this.uiElement
                        }
                    },
                    body: {
                        component: PendoGuideMetricsDrawerContent,
                        props: {
                            loading: false,
                            csvModalStatus: 'rejected',
                            csvUrl: ''
                        }
                    },
                    visible: true
                });
            }
        }
    }
};
</script>

<style lang="scss">
.guide-details-metrics--content {
    display: grid;
    grid-gap: 32px;
}

.guide-details-metrics--row {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
}

.guide-details-metrics--activity-chart {
    .pendo-card__body {
        display: grid;
    }
}
</style>
