<template>
    <div>
        <pendo-card class="resource-center-content">
            <template slot="header">
                <div class="state-bar">
                    <div
                        v-for="state in publishStates"
                        :key="state.state"
                        :class="{ 'state-box--active': activeState === state.state }"
                        class="state-box"
                        @click="activeStateChange(state.state)">
                        {{ state.display }}
                        <div class="state-details">
                            {{ state.lastUpdatedAt }}
                        </div>
                    </div>
                </div>
                <div class="resource-center-content--actions">
                    <pendo-button
                        v-if="STATES.PUBLIC !== activeState"
                        type="link"
                        label="Add Modules"
                        prefix-icon="plus"
                        @click="$emit('showModuleChooser')" />
                </div>
            </template>
            <section
                slot="body"
                class="resource-center-content--body">
                <resource-center-content-preview
                    v-if="activeResourceCenter"
                    :modules="activeResourceCenter.modules"
                    :home-view="activeResourceCenter.homeView"
                    :active-state="activeState"
                    :show-add-module-button="STATES.PUBLIC !== activeState"
                    @showModuleChooser="$emit('showModuleChooser')" />
                <pendo-empty-state
                    v-if="!activeResourceCenter"
                    :title="notPublishedTitle"
                    :description="notPublishedDescription" />
            </section>
        </pendo-card>
        <div
            v-if="activeResourceCenter"
            class="resource-center-content last-child">
            <resource-center-details-activation
                v-if="activeState === STATES.DRAFT"
                :key="`details-activation-${STATES.DRAFT}`"
                v-pendo-loading="isFetching"
                :guide="draftResourceCenter.homeView"
                :editable="true"
                class="guide-details--activation" />
            <resource-center-details-activation
                v-if="activeState === STATES.PUBLIC"
                :key="`details-activation-${STATES.PUBLIC}`"
                v-pendo-loading="isFetching"
                :guide="publicResourceCenter.homeView"
                class="guide-details--activation" />
            <guide-details-localization
                v-if="isDraft && localizationEnabled"
                key="details-localization-draft"
                v-pendo-loading="loadingConfig"
                is-resource-center
                :guide="resourceCenter"
                :active-state="STATES.DRAFT"
                class="resource-center-details--localization" />
            <guide-details-localization
                v-if="hasActiveBeenPublishedBefore && !isDraft && localizationEnabled"
                key="details-localization-public"
                v-pendo-loading="loadingConfig"
                is-resource-center
                :guide="resourceCenter"
                :active-state="STATES.PUBLIC"
                class="resource-center-details--localization" />
        </div>
    </div>
</template>

<script>
import { PendoCard, PendoEmptyState, PendoButton, PendoLoading } from '@pendo/components';
import ResourceCenterDetailsActivation from '@/components/resource-center/ResourceCenterActivationInfo.vue';
import ResourceCenterContentPreview from '@/components/resource-center/ResourceCenterContentPreview';
import GuideDetailsLocalization from '@/components/guides/settings/GuideDetailsLocalization';
import { mapGetters, mapState } from 'vuex';
import { convertToSubscriptionTimezone, DATE_FORMAT } from '@/utils/moment';
import { STATES } from '@/utils/resource-center';
import get from 'lodash/get';

export default {
    name: 'ResourceCenterDetailsContent',
    components: {
        ResourceCenterDetailsActivation,
        ResourceCenterContentPreview,
        PendoButton,
        PendoCard,
        PendoEmptyState,
        GuideDetailsLocalization
    },
    directives: {
        PendoLoading
    },
    props: {
        resourceCenter: {
            type: Object,
            required: true
        },
        activeState: {
            type: String,
            required: true
        },
        localizationEnabled: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            notPublishedTitle: 'No content has been published yet',
            notPublishedDescription: 'Set your resource center to Public to view live content.',
            STATES
        };
    },
    computed: {
        ...mapGetters({
            hasActiveBeenPublishedBefore: 'resourceCenter/hasActiveBeenPublishedBefore'
        }),
        ...mapState({
            isFetching: (state) => state.resourceCenter.isFetching
        }),
        showDraft () {
            return (
                this.activeState === STATES.DRAFT &&
                this.resourceCenter[STATES.DRAFT] &&
                this.resourceCenter[STATES.DRAFT].homeView
            );
        },
        showPublic () {
            return (
                this.activeState === STATES.PUBLIC &&
                this.resourceCenter[STATES.PUBLIC] &&
                this.resourceCenter[STATES.PUBLIC].homeView
            );
        },
        draftResourceCenter () {
            const publicBadgeOpts = get(this, `resourceCenter[${STATES.PUBLIC}].homeView.attributes.badge`, null);
            const draftBadgeOpts = get(this, `resourceCenter[${STATES.DRAFT}].homeView.attributes.badge`, null);
            const badge = draftBadgeOpts || publicBadgeOpts;
            const attributes = { ...this.resourceCenter[STATES.DRAFT].homeView.attributes, badge };
            const homeView = { ...this.resourceCenter[STATES.DRAFT].homeView, attributes };

            return {
                ...this.resourceCenter[STATES.DRAFT],
                homeView
            };
        },
        publicResourceCenter () {
            return this.resourceCenter[STATES.PUBLIC];
        },
        isDraft () {
            return this.STATES.DRAFT === this.activeState;
        },
        activeHomeView () {
            return this.activeResourceCenter && this.activeResourceCenter.homeView;
        },
        activeResourceCenter () {
            return this.resourceCenter[this.activeState];
        },
        publishStates () {
            return [
                {
                    state: this.STATES.DRAFT,
                    display: 'Draft',
                    lastUpdatedAt: this.lastUpdatedAt('draft')
                },
                {
                    state: this.STATES.PUBLIC,
                    display: 'View Public',
                    lastUpdatedAt: this.lastUpdatedAt('public')
                }
            ];
        },
        loadingConfig () {
            return {
                loading: this.isFetching,
                type: 'feather',
                spinnerProps: {
                    background: 'rgba(255, 255, 255, 0.5)'
                }
            };
        }
    },
    methods: {
        lastUpdatedAt (state) {
            const date = get(this.resourceCenter, `${state}.homeView.lastUpdatedAt`);
            if (date) {
                return `Last Saved ${convertToSubscriptionTimezone(date).format(DATE_FORMAT.full)}`;
            }

            return '—';
        },
        activeStateChange (state) {
            this.$router.replace({
                name: 'resourceCenter',
                params: {
                    originId: this.resourceCenter.id,
                    activeState: state
                }
            });
        }
    }
};
</script>

<style lang="scss">
.resource-center-content {
    &:not(.last-child) {
        margin-bottom: 24px;
    }

    &.last-child {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        > div {
            width: 49%;
            min-height: 550px;
        }
    }

    &--body {
        position: relative;
        min-height: 500px;
        margin-bottom: 16px;
    }

    .state-box {
        font-size: 14px;
        color: $gray-primary;
        display: inline-block;
        margin: 16px 16px -2px 16px;
        cursor: pointer;
        height: 48px;
        line-height: 1.4;

        &:last-of-type {
            margin-right: 0;
        }

        .state-details {
            font-weight: normal;
            color: $gray-lighter-2;
        }
    }

    .state-box--active {
        border-bottom: 2px solid $gray-primary;
        font-weight: 600;
        cursor: default;
    }

    &--actions {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
}
</style>
