import * as Agg2 from '@pendo/aggregations';
import { request } from '@/utils/aggregations';
import { parseSegmentIdForAggregation } from '../utils/aggregations';

export function getGuideActivity ({ appId, timeSeries, guideId, segmentId, isWalkthrough, lastStepId }) {
    const spec = buildGuideActivitySpec({ appId, timeSeries, guideId, segmentId, isWalkthrough, lastStepId });

    return request(spec);
}

export function buildGuideActivitySpec ({ appId, timeSeries, guideId, segmentId, isWalkthrough, lastStepId }) {
    const { operators: o } = Agg2;

    return o.aggregation(
        'guide-activity',
        o.pipeline(
            o.sources.guideEvents({ appId: [appId], guideId, timeSeries }),
            o.segment(segmentId),
            o.group(
                ['guideId', 'guideStepId', 'visitorId'],
                o.groupField('s', {
                    sequence: {
                        new: 'type == "guideSeen"',
                        sort: ['browserTime']
                    }
                })
            ),
            o.unwind('s', { index: 'sIndex' }),
            o.evaluate({ 's[-1].duration': 's[-1].browserTime - s[0].browserTime' }),
            o.group(['visitorId'], o.groupField('s', { concat: 's' })),
            // eslint-disable-next-line
            o.evaluate({ s: 'reverse(s)' }),
            o.evaluate({ totalDuration: 'sum(map(s,duration))' }),
            !isWalkthrough && o.evaluate({ numSeen: 'sum(map(s,if(type == "guideSeen",1,0)))' }),
            isWalkthrough &&
                o.pipeline(
                    o.unwind('s', { index: 'sIndex' }),
                    o.evaluate({
                        guideId: 's.guideId',
                        guideStepId: 's.guideStepId'
                    }),
                    o.merge(
                        ['guideId', 'guideStepId'],
                        o.mappings({ stepIndex: 'stepIndex' }),
                        o.pipeline(
                            o.sources.guides({ appId }),
                            o.unwind('steps', { index: 'stepIndex' }),
                            o.select({
                                guideId: 'steps.guideId',
                                guideStepId: 'steps.id',
                                stepIndex: 'stepIndex'
                            })
                        )
                    ),
                    o.filter('!isNil(stepIndex)'),
                    o.evaluate({ 's.stepIndex': 'stepIndex + 1' }),
                    o.group(
                        ['visitorId'],
                        o.groupField('s', { list: 's' }),
                        o.groupField('stepsSeen', { list: 's.stepIndex' }),
                        o.groupField('numSeen', { max: 's.stepIndex' }),
                        o.groupField('totalDuration', { max: 'totalDuration' })
                    ),
                    o.evaluate({ stepsSeen: 'sortUnique(stepsSeen)' })
                ),
            o.evaluate({
                browserTime: 's[0].browserTime',
                hasCompletedGuide: `if("${lastStepId}" == s[0].guideStepId, true, false)`,
                isWalkthrough: `if(!isNil(${isWalkthrough ? 1 : 0}), true, false)`,
                type: 'if(s[0].type == "guideActivity", s[1].type, s[0].type)'
            }),
            {
                switch: {
                    stepStatus: {
                        type: [
                            {
                                value: isWalkthrough ? 'Advanced' : 'Action Taken',
                                '==': 'guideAdvanced'
                            },
                            {
                                value: 'Displayed',
                                '==': 'guideSeen'
                            },
                            {
                                value: 'Guide Snoozed',
                                '==': 'guideSnoozed'
                            }
                        ]
                    }
                }
            },
            o.evaluate({
                stepStatus:
                    'if((type == "guideDismissed" && isWalkthrough && hasCompletedGuide), "Completed", stepStatus)'
            }),
            o.evaluate({
                stepStatus:
                    'if(type == "guideDismissed" && (!isWalkthrough || !hasCompletedGuide), "Closed", stepStatus)'
            }),
            o.evaluate({ stepStatus: 'if(isNil(stepStatus), type, stepStatus)' }),
            o.sort(['-visitorId']),
            o.select(['numSeen', 'totalDuration', 'stepStatus', 'browserTime', 'stepsSeen', 's', 'visitorId'])
        )
    );
}

export function getVisitorsInGuideSegment ({ segmentId }) {
    const spec = buildVisitorsInGuideSegmentSpec({ segmentId: parseSegmentIdForAggregation(segmentId) });

    return request(spec);
}

export function buildVisitorsInGuideSegmentSpec ({ segmentId }) {
    const { operators: o } = Agg2;

    return o.aggregation('visitors-in-guide-segment', o.pipeline(o.sources.visitors(), o.segment(segmentId)));
}
