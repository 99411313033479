var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pendo-page-content',[_c('pendo-table',{directives:[{name:"pendo-loading",rawName:"v-pendo-loading:feather",value:(_vm.segmentsLoaded),expression:"segmentsLoaded",arg:"feather"}],ref:"visitorListTable",staticClass:"segment-list",attrs:{"data":_vm.segments,"columns":_vm.columns,"filters":_vm.filters,"default-sort":{
            prop: 'name',
            order: 'ascending'
        },"max-height":600,"title":"Segments","row-key":"id"},scopedSlots:_vm._u([{key:"headerActions",fn:function(){return [_c('search',{attrs:{"placeholder":"Search Segments"},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})]},proxy:true},{key:"actions",fn:function(ref){
        var row = ref.row;
return [_c('pendo-actions-cell',{attrs:{"row":row,"actions":[
                    {
                        type: 'edit',
                        icon: 'edit-2',
                        tooltip: 'Edit segment'
                    },
                    {
                        type: 'delete',
                        icon: 'trash-2',
                        tooltip: 'Delete segment'
                    }
                ]},on:{"delete":_vm.openDeleteSegmentModal,"edit":_vm.openSegmentBuilder}})]}}])},[_c('div',{staticClass:"guide-list-table--empty",attrs:{"slot":"empty"},slot:"empty"},[_c('pendo-icon',{staticClass:"empty-icon",attrs:{"type":"alert-circle","stroke":"#9a9ca5","size":"24"}}),_c('span',{staticClass:"empty-text"},[_vm._v(" "+_vm._s(_vm.emptyText)+" ")])],1)]),(_vm.showSegmentBuilder)?_c('app-segment-builder',{attrs:{"editing-segment":_vm.editingSegment},on:{"close":_vm.closeSegmentBuilder}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }