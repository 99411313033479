<template>
    <div class="pages-dashboard">
        <global-filters>
            <template #right>
                <div
                    v-if="eligibleForCustomPages"
                    data-cy="pages-dashboard--page-filter-dropdown"
                    class="pages-dashboard--header">
                    <pendo-multiselect
                        v-model="pageFilterSelection"
                        :options="['All Pages', 'Custom Pages Only']" />
                </div>
                <search
                    v-model="searchInput"
                    :placeholder="searchPlaceholder" />
            </template>
        </global-filters>
        <div class="pages-dashboard--grid">
            <div class="pages-dashboard--row">
                <child-pages-list
                    v-if="!activeUsesV2Adopt"
                    :eligible-for-custom-pages="pageListContainsParentPage"
                    :page-filter-selection="pageFilterSelection"
                    :search-string="normalizedValue"
                    class="pages-dashboard--pages-list" />
                <pages-list
                    v-if="activeUsesV2Adopt"
                    :page-filter-selection="pageFilterSelection"
                    :search-string="normalizedValue"
                    class="pages-dashboard--pages-list" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ChildPagesList from '@/components/analytics/pages/v1/ChildPagesList';
import PagesList from '@/components/analytics/pages/PagesList';
import Search from '@/components/Search';
import GlobalFilters from '@/components/filters/GlobalFilters';
import { PendoMultiselect } from '@pendo/components';
import { normalizeValue } from '@/utils/pages';

export default {
    name: 'PagesDashboard',
    components: {
        ChildPagesList,
        PagesList,
        Search,
        GlobalFilters,
        PendoMultiselect
    },
    data () {
        return {
            searchInput: '',
            pageFilterSelection: 'All Pages',
            normalizedValue: ''
        };
    },
    computed: {
        ...mapGetters({
            activeUsesV2Adopt: 'subscriptions/activeUsesV2Adopt',
            pageListContainsParentPage: 'pages/listContainsParentPage',
            hasSegmentFlag: 'auth/hasSegmentFlag'
        }),
        eligibleForCustomPages () {
            return (
                this.activeUsesV2Adopt ||
                (this.hasSegmentFlag('customPageTaggingEnabled') && this.pageListContainsParentPage)
            );
        },
        searchPlaceholder () {
            return 'Search pages by keyword or paste a URL';
        }
    },
    watch: {
        async searchInput (value) {
            this.normalizedValue = await normalizeValue(value);
        }
    },
    created () {
        this.fetchPages();
        this.fetchPagesWithAnalytics();
    },
    methods: {
        ...mapActions({
            fetchPages: 'pages/fetch',
            fetchPagesWithAnalytics: 'pages/fetchWithAnalytics'
        })
    }
};
</script>

<style lang="scss">
.pages-dashboard {
    .pages-dashboard--header {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        font-size: 0.875em;
        margin-left: 1em;
    }

    .pages-dashboard--grid {
        display: grid;
        grid-gap: 32px;
    }

    .pages-dashboard--row {
        display: grid;
        grid-gap: 32px;
        grid-template-columns: repeat(auto-fit, minmax(372px, 1fr));
    }
}
</style>
