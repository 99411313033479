import keyBy from 'lodash/keyBy';
import flatten from 'lodash/flatten';
import omit from 'lodash/omit';
import base64js from 'base64-js';
import http from '@/utils/http';
import pako from 'pako';

export function getRawEvents (params = {}) {
    if (params) {
        params = { params };
    }

    return http.get('/api/s/_SID_/rawevent?limit=25', params);
}

function decodeJzb (jzb) {
    while (jzb.length % 4 !== 0) {
        jzb += '=';
    }
    const compressed = base64js.toByteArray(jzb);
    const result = pako.inflate(compressed);
    const utf8decoder = new TextDecoder('utf-8');

    return JSON.parse(utf8decoder.decode(result));
}

export function decodedJZB (jzb) {
    try {
        return decodeJzb(jzb);
    } catch (err) {
        return false;
    }
}

function getValue (event, normalizedKeysList) {
    const keyMap = keyBy(normalizedKeysList);

    for (const rawEventKey in event) {
        if (Object.prototype.hasOwnProperty.call(event, rawEventKey)) {
            const normalizedEventKey = rawEventKey.toLowerCase().replace(/_/g, '');

            if (keyMap[normalizedEventKey]) {
                return event[rawEventKey];
            }
        }
    }
}

function getBrowserTime (event) {
    let browserTime = getValue(event, ['browsertime', 'timestamp', 'devicetime']);
    if (browserTime) {
        return browserTime;
    }

    browserTime = getValue(event, ['receivedat']);

    return browserTime || '';
}

function getVisitor (event) {
    const curVisitor = getValue(event, ['visitorid', 'userid']);

    return curVisitor || '';
}

function getAccount (event) {
    const curAccount = getValue(event, ['accountid', 'groupid']);

    return curAccount || '';
}

function getOtherAgent (event) {
    let eventObject = {};
    try {
        eventObject = JSON.parse(event.userAgent);
    } catch (err) {
        // Not a JSON string
    }

    if (eventObject.manufacturer) {
        return formatMobileUserAgentLabel(eventObject);
    }

    return '';
}

function formatMobileUserAgentLabel (mobileAgent) {
    if (mobileAgent.manufacturer.toLowerCase() === 'apple') {
        return `${mobileAgent.model} with ${mobileAgent.os} v. ${mobileAgent.osVersion}`;
    }

    const agentLabel = `${mobileAgent.manufacturer} ${mobileAgent.model} with ${mobileAgent.os} v. ${
        mobileAgent.osVersion
    }`;

    return agentLabel.charAt(0).toUpperCase() + agentLabel.slice(1);
}

export function decodeRawEvents (events) {
    const decodedEvents = flatten(
        events.map((result) => {
            const jzb = decodeJzb(result.jzb);

            result = omit(result, 'jzb');
            let jzbIndex = 0;

            return jzb.map((decodedEvent) => {
                Object.assign(decodedEvent, result);
                decodedEvent.displayId = `${decodedEvent.id}@INDEX${jzbIndex}`;
                jzbIndex += 1;
                if (decodedEvent.jzb) {
                    decodeJzb(decodedEvent.jzb).forEach((event) => {
                        decodedEvent = omit(decodedEvent, 'jzb');
                        decodedEvent.displayId = `${decodedEvent.id}@INDEX${jzbIndex}`;
                        jzbIndex += 1;
                        Object.assign(decodedEvent, event);
                    });
                }

                return decodedEvent;
            });
        })
    );

    return decodedEvents.map((event) => {
        return {
            ...event,
            displayBrowserTime: getBrowserTime(event),
            displayVisitor: getVisitor(event),
            displayAccount: getAccount(event),
            displayOtherAgent: getOtherAgent(event)
        };
    });
}

// these events are generated internally to help keep track of time-in-app as a result of AppInBackground, RAScreenLeft,
// and AppSessionEnd events. they are not sent by devices and we don't need to show them here
const SYNTHETIC_EVENTS = ['_AppActive', '_ScreenActive', '_SessionActive'];

export function filterRawEvents (events) {
    return events.filter(({ event }) => !SYNTHETIC_EVENTS.includes(event));
}

export function sortRawEvents (events) {
    return events.sort((left, right) =>
        (right.receivedTime !== left.receivedTime
            ? right.receivedTime - left.receivedTime
            : right.displayBrowserTime - left.displayBrowserTime)
    );
}

export function deleteTraitsFromDecodedEvent (event) {
    const decodedEvent = event;
    if (decodedEvent) {
        if (decodedEvent.traits && decodedEvent.traits.address) {
            delete decodedEvent.traits.address;
        }

        return decodedEvent;
    }

    return '';
}
