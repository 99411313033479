<template>
    <pendo-table
        v-if="targetsSelected"
        v-pendo-loading:feather="sourcePagesLoading || targetPagesLoading"
        :data="uniquePagesArray"
        :columns="columns"
        :max-height="448"
        empty-text="All Pages have been mapped"
        class="select-target-pages-card"
        title="Page Mapping">
        <template #header>
            <div>
                Page Mapping
                <pendo-icon
                    v-pendo-tooltip="pageMappingTooltip"
                    display="inline"
                    type="info"
                    size="14" />
            </div>
        </template>
        <template #hasArrowHeader>
            <pendo-icon type="arrow-right" />
        </template>
        <template #hasArrow="{ row }">
            <pendo-icon
                v-if="row.targetPage"
                type="arrow-right" />
            <div v-else />
        </template>
        <template #targetPage="{ row }">
            <pendo-multiselect
                v-model="row.targetPage"
                v-pendo-tooltip="automaticallyCreateNewpagesTooltip"
                :loading="targetPagesLoading"
                :options="targetPages"
                :disabled="!targetPages.length"
                :min-trigger-width="220"
                :max-trigger-width="220"
                placeholder="-- Choose Page --"
                label-key="name"
                @input="pageSelected($event, row)">
                <template #header="{ select }">
                    <div
                        v-if="!row.sourcePageNotFound"
                        class="step-page-selector__header">
                        <div
                            class="step-page-selector__option"
                            @click="select(createPageOnTargetAppOption(row.sourceSelectedPage))">
                            <span
                                v-pendo-tooltip="createNewPageDescriptionTooltip"
                                style="padding: 0 16px">
                                Create New Page (from Existing Page Rule)
                            </span>
                        </div>
                    </div>
                </template>
                <template #selectedLabel="{ option }">
                    <div
                        v-if="option.createPageOnTargetApp"
                        v-pendo-tooltip="createNewPageTooltip">
                        Create New Page (from Existing Page Rule)
                    </div>
                    <pendo-group-option
                        v-if="!option.createPageOnTargetApp"
                        :option="option" />
                </template>
                <template #option="{ option }">
                    <pendo-group-option :option="option" />
                </template>
            </pendo-multiselect>
        </template>
    </pendo-table>
</template>

<script>
import {
    PendoGroupOption,
    PendoMultiselect,
    PendoIcon,
    PendoTooltip,
    PendoTable,
    PendoLoading
} from '@pendo/components';
import { mapGetters } from 'vuex';

const TOOLTIP_CONFIG = {
    multiline: true,
    trigger: 'hover',
    placement: 'right',
    arrow: true
};

export default {
    name: 'SelectPageMappings',
    components: {
        PendoGroupOption,
        PendoMultiselect,
        PendoIcon,
        PendoTable
    },
    directives: {
        PendoTooltip,
        PendoLoading
    },
    props: {
        guides: {
            type: Array,
            required: true
        },
        targetApplication: {
            type: Object,
            default: undefined
        },
        targetSubscription: {
            type: Object,
            default: undefined
        },
        uniquePages: {
            type: Object,
            default: undefined
        },
        uniquePagesArr: {
            type: Array,
            default: () => []
        },
        targetPages: {
            type: Array,
            default: () => []
        },
        targetPagesLoading: {
            type: Boolean,
            default: false
        },
        sourcePagesLoading: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            createNewPageTooltip: {
                ...TOOLTIP_CONFIG,
                content: 'Create New Page (from Existing Page Rule)'
            },
            uniquePagesObject: this.uniquePages,
            uniquePagesArray: this.uniquePagesArr
        };
    },
    computed: {
        ...mapGetters({
            getGuideById: 'guides/getGuideById',
            getPageById: 'pages/pageById',
            sourcePages: 'pages/list'
        }),
        columns () {
            return [
                {
                    sortable: true,
                    prop: 'sourcePage.name',
                    label: 'Current Page',
                    allowResize: true,
                    minWidth: 200
                },
                {
                    prop: 'hasArrow',
                    slot: 'hasArrow',
                    sortable: false,
                    visible: true,
                    allowResize: false,
                    allowReorder: false,
                    fixed: true,
                    width: 50
                },
                {
                    sortable: true,
                    prop: 'targetPage',
                    slot: 'targetPage',
                    label: 'New Page',
                    allowResize: true,
                    minWidth: 200
                }
            ];
        },
        targetsSelected () {
            return this.targetApplication && this.targetSubscription;
        },
        anyGuideIsTargetingPages () {
            return this.guides.some((guide) => {
                if (!guide.steps) guide = this.getGuideById(guide.id);

                return guide.steps.some((step) => step.pageId);
            });
        },
        pageMappingTooltip () {
            return {
                ...TOOLTIP_CONFIG,
                content: `Pages in the "Current Page" column don't exist in your target app.
                    Map these pages to new or existing pages in your target app.`
            };
        },
        createNewPageDescriptionTooltip () {
            return {
                ...TOOLTIP_CONFIG,
                content: `This will re-create the page for each step in your guides that contains
                    this page as a new page in ${this.targetApplication.displayName}.
                    If multiple steps use this page, only one page will be created.
                    If the relevant page already exists, please select it from the list below.`
            };
        },
        automaticallyCreateNewpagesTooltip () {
            return (
                !this.targetPages.length && {
                    ...TOOLTIP_CONFIG,
                    content: `The target application does not have any pages. New page(s) will be
                    created in ${this.targetApplication.displayName} based on the source page(s)' rules.`
                }
            );
        }
    },
    watch: {
        uniquePages (newVal) {
            this.uniquePagesObject = newVal;
        },
        uniquePagesArr (newVal) {
            this.uniquePagesArray = newVal;
        }
    },
    methods: {
        async pageSelected ($e, row) {
            const { sourcePage } = row;
            const action = $e.createPageOnTargetApp ? 'create' : 'use';
            this.uniquePagesObject[sourcePage.id].action = action;
            this.$emit('pageSelected', this.uniquePagesObject);
        },
        createPageOnTargetAppOption (page) {
            return {
                ...page,
                name: 'Create New Page (from Existing Page Rule)',
                createPageOnTargetApp: true
            };
        }
    }
};
</script>

<style scoped lang="scss">
.step-page-selector__header {
    height: 40px;
    border-bottom: 1px solid $gray-lighter-5;
    display: flex;
    align-items: center;
    justify-content: center;

    .step-page-selector__option {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 16px;
        height: 26px;
        width: 100%;
        cursor: pointer;

        &:hover {
            background-color: $gray-lighter-7;
        }

        &.is-selected {
            background-color: $gray-lighter-6;
        }
    }
}

.select-page-list-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0px;
}
</style>
