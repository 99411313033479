<template>
    <div v-if="isEmptyStateDigitalAdoption">
        <empty-state />
    </div>
    <div
        v-else
        class="subscription-settings">
        <extension-api-key :subscription="subscription" />
        <app-list :subscription="subscription" />
        <general-settings :subscription="subscription" />
    </div>
</template>

<script>
import ExtensionApiKey from '@/components/settings/ExtensionApiKey';
import GeneralSettings from '@/components/settings/GeneralSettings';
import EmptyState from '@/components/settings/EmptyState';
import AppList from '@/components/settings/AppList';

import { mapGetters } from 'vuex';

export default {
    name: 'SubscriptionSettings',
    components: {
        EmptyState,
        ExtensionApiKey,
        GeneralSettings,
        AppList
    },
    computed: {
        ...mapGetters({
            subscription: 'subscriptions/active',
            isEmptyStateDigitalAdoption: 'subscriptions/isEmptyStateDigitalAdoption'
        })
    }
};
</script>
<style lang="scss">
.subscription-settings > * {
    &:not(:first-child) {
        margin-top: 32px;
    }
}
</style>
