<template>
    <span class="language-selector">
        <span class="language-title">Viewing: </span>
        <span v-if="languages.length === 1">{{ languages[0].label }}</span>
        <pendo-dropdown
            v-if="languages.length > 1"
            placement="bottom-start"
            :items="languages"
            @select="selectLanguage">
            <template slot="trigger">
                <pendo-button
                    theme="via"
                    type="secondary"
                    size="mini"
                    suffix-icon="chevron-down"
                    :loading="isLoading">{{ selectedLanguage.label }}</pendo-button>
            </template>
        </pendo-dropdown>
    </span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PendoButton, PendoDropdown } from '@pendo/components';
import { formatLanguages, isTranslated, GUIDE_TRANSLATION_UNTRANSLATED } from '@/utils/localization.js';
import get from 'lodash/get';

export default {
    name: 'LanguagePreviewDropdown',
    components: {
        PendoButton,
        PendoDropdown
    },
    props: {
        translationStates: {
            type: Object,
            default: () => {}
        },
        authoredLanguage: {
            type: String,
            required: true
        },
        isLoading: {
            type: Boolean,
            default: () => false
        },
        overrideValue: {
            type: String,
            default: () => ''
        }
    },
    data () {
        return {
            languages: [],
            selectedLanguage: {}
        };
    },
    computed: {
        ...mapGetters({
            app: 'guides/activeGuideApp',
            guide: 'guides/active'
        }),
        appId () {
            return get(this.guide, 'appId', '');
        },
        localizationSettings () {
            return get(this.app, 'localizationSettings', {});
        },
        defaultLanguage () {
            return this.languages.find((lang) => lang.value === this.authoredLanguage);
        }
    },
    watch: {
        translationStates () {
            this.reprocessLanguages();
        },
        authoredLanguage () {
            this.reprocessLanguages();
        },
        overrideValue (value, oldValue) {
            if (!!oldValue && value !== this.selectedLanguage.value) this.selectLanguage(value, false);
        }
    },
    beforeMount () {
        this.getEnabledLanguages();
    },
    methods: {
        ...mapActions({
            fetchLocalizationSettingsForApp: 'apps/fetchLocalizationSettingsForApp'
        }),
        async getEnabledLanguages () {
            await this.fetchLocalizationSettingsForApp({ app: this.app });
            const enabledLanguages = formatLanguages(this.localizationSettings, this.translationStates);

            this.languages = enabledLanguages.map((enabledLanguage) => {
                const { name: label, code: value, isDefaultLanguage, translatedState } = enabledLanguage;
                const isAuthored = this.authoredLanguage === value;
                const isDefault = !this.authoredLanguage && isDefaultLanguage;
                const languageOption = {
                    label,
                    value,
                    disabled: isAuthored || isDefault ? false : !isTranslated(translatedState),
                    isDefaultLanguage
                };

                if (isAuthored || isDefault) {
                    this.selectedLanguage = languageOption;
                }

                return languageOption;
            });
        },
        reprocessLanguages () {
            this.languages.forEach((lang) => {
                const translatedState = get(
                    this,
                    `translationStates.${lang.value}.state`,
                    GUIDE_TRANSLATION_UNTRANSLATED
                );

                lang.disabled = lang.value === this.authoredLanguage ? false : !isTranslated(translatedState);
                const defaultLang = this.languages.find((lang) => lang.isDefaultLanguage);

                if (this.selectedLanguage.label === lang.label && lang.disabled) {
                    this.selectLanguage(defaultLang.value);
                }
            });
        },
        selectLanguage (value, emit = true) {
            const selectedLanguageObj = this.languages.find((language) => language.value === value);
            this.selectedLanguage = selectedLanguageObj;
            if (emit) this.$emit('languageSelected', value, selectedLanguageObj.label);
        }
    }
};
</script>

<style scoped lang="scss">
.language-selector {
    display: flex;
    align-items: center;
}

.language-title {
    margin-right: 8px;
}
</style>
