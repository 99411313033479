<template>
    <pendo-input
        class="string-input"
        :class="`${field}-string-input`"
        :placeholder="placeholder"
        :value="value"
        debounce
        @input="onInputChange" />
</template>

<script>
import { PendoInput } from '@pendo/components';
import isString from 'lodash/isString';

export default {
    name: 'StringInput',
    components: {
        PendoInput
    },
    props: {
        value: {
            type: null,
            default: null
        },
        field: {
            type: String,
            default: 'value'
        },
        placeholder: {
            type: String,
            default: 'Type value here'
        }
    },
    created () {
        const value = isString(this.value) ? this.value : '';
        this.onInputChange(value);
    },
    methods: {
        // use change event to emit update on blur of input instead of every single keystroke
        onInputChange (value) {
            this.$emit('input', { field: this.field, value });
        }
    }
};
</script>
