<template>
    <pendo-page-content>
        <pendo-table
            ref="visitorListTable"
            v-pendo-loading:feather="segmentsLoaded"
            :data="segments"
            :columns="columns"
            :filters="filters"
            :default-sort="{
                prop: 'name',
                order: 'ascending'
            }"
            :max-height="600"
            title="Segments"
            row-key="id"
            class="segment-list">
            <template #headerActions>
                <search
                    v-model="searchInput"
                    placeholder="Search Segments" />
            </template>
            <template #actions="{ row }">
                <pendo-actions-cell
                    :row="row"
                    :actions="[
                        {
                            type: 'edit',
                            icon: 'edit-2',
                            tooltip: 'Edit segment'
                        },
                        {
                            type: 'delete',
                            icon: 'trash-2',
                            tooltip: 'Delete segment'
                        }
                    ]"
                    @delete="openDeleteSegmentModal"
                    @edit="openSegmentBuilder" />
            </template>
            <div
                slot="empty"
                class="guide-list-table--empty">
                <pendo-icon
                    type="alert-circle"
                    class="empty-icon"
                    stroke="#9a9ca5"
                    size="24" />
                <span class="empty-text">
                    {{ emptyText }}
                </span>
            </div>
        </pendo-table>
        <app-segment-builder
            v-if="showSegmentBuilder"
            :editing-segment="editingSegment"
            @close="closeSegmentBuilder" />
    </pendo-page-content>
</template>

<script>
import get from 'lodash/get';
import { mapActions, mapGetters, mapState } from 'vuex';
import { PendoActionsCell, PendoTable, PendoPageContent, PendoLoading, PendoIcon } from '@pendo/components';
import moment, { DATE_FORMAT } from '@/utils/moment';
import { getGuidesUsingSegment } from '@/utils/guides';
import Search from '@/components/Search';
import AppSegmentBuilder from '@/components/segments/builder/AppSegmentBuilder.vue';

export default {
    name: 'GuideListContent',
    components: {
        AppSegmentBuilder,
        PendoPageContent,
        PendoTable,
        Search,
        PendoIcon,
        PendoActionsCell
    },
    directives: {
        PendoLoading
    },
    data () {
        return {
            searchInput: '',
            showSegmentBuilder: false,
            editingSegment: null
        };
    },
    computed: {
        ...mapState({
            segmentsLoaded: 'filters/segmentsLoaded'
        }),
        ...mapGetters({
            segmentsListByGroup: 'filters/segmentsListByGroup',
            guideList: 'guides/list'
        }),
        emptyText () {
            if (!this.searchInput) return 'You have not created any custom segments.';

            return 'There are no segments matching that search criteria.';
        },
        segments () {
            return get(this.segmentsListByGroup, 'customList', []);
        },
        filters () {
            return [
                {
                    prop: ['name', 'createdByUser.username', 'lastUpdatedByUser.username'],
                    type: 'includes',
                    value: this.searchInput
                }
            ];
        },
        columns () {
            return [
                {
                    sortable: true,
                    prop: 'name',
                    label: 'Segment Name',
                    minWidth: 300
                },
                {
                    sortable: true,
                    prop: 'createdByUser.username',
                    label: 'Created By'
                },
                {
                    sortable: true,
                    prop: 'lastUpdatedByUser.username',
                    label: 'Last Updated By'
                },
                {
                    sortable: true,
                    prop: 'lastUpdatedAt',
                    label: 'Last Updated At',
                    formatter: (row) => moment(row.lastUpdatedAt).format(DATE_FORMAT.full),
                    minWidth: 100
                },
                {
                    type: 'actions',
                    width: 60
                }
            ];
        }
    },
    created () {
        this.fetchSegments();
        this.fetchGuides();
    },
    methods: {
        ...mapActions({
            deleteSegment: 'filters/deleteSegment',
            fetchSegments: 'filters/fetchSegments',
            fetchGuides: 'guides/fetch'
        }),
        openDeleteSegmentModal ({ id }) {
            const guidesUsingSegment = getGuidesUsingSegment(id, this.guideList);
            const title = guidesUsingSegment.length ? 'Guide Dependency Detected' : 'Delete Segment?';

            this.$modal.show('via-modal', {
                title,
                component: 'ItemDeleteWarning',
                componentProps: {
                    kind: 'segment',
                    impactedKind: 'guide',
                    impactedItems: guidesUsingSegment,
                    preventDeleteWithImpactedItems: true,
                    deleteFn: async () => {
                        this.$modal.hide('via-modal');
                        await this.deleteSegment({ id });
                    }
                }
            });
        },
        openSegmentBuilder (segment) {
            this.editingSegment = segment;
            this.showSegmentBuilder = true;
        },
        closeSegmentBuilder () {
            this.editingSegment = null;
            this.showSegmentBuilder = false;
        }
    }
};
</script>
<style lang="scss" scoped>
/deep/ .pendo-table__title-actions {
    width: 300px;
}
</style>
