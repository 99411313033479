const MESSAGE_TIMEOUT = 30000;

export function launchPreview (launchUrl, agentConfig) {
    const appWindow = window.open(launchUrl, '_blank');

    const message = {
        type: 'pendo-preview::launch',
        config: agentConfig
    };

    return new Promise((resolve, reject) => {
        window.addEventListener('message', listener);

        const failureTimeout = setTimeout(() => {
            window.removeEventListener('message', listener);
            reject();
        }, MESSAGE_TIMEOUT);

        function listener (msg) {
            if (msg.data.type === 'pendo::ready') {
                appWindow.postMessage(message, launchUrl);
            } else if (msg.data.type === 'pendo-preview::ready') {
                clearTimeout(failureTimeout);
                window.removeEventListener('message', listener);
                resolve(msg.data);
            }
        }
    });
}
