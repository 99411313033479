<template>
    <pendo-modal
        :visible="visible"
        class="delete-entity-modal"
        height="auto"
        max-height="80%"
        type="basic"
        :title="modalTitle"
        @close="closeModal">
        <template #body>
            <pendo-alert
                v-if="deleteCustomEntityError"
                type="error">
                Something went wrong. Try again - if the problem persists contact Pendo support.
            </pendo-alert>
            <span> Are you sure you want to delete this custom {{ type }}? </span>
            <span
                v-if="entityReferencedInGuideOrSegment">This {{ type }} is referenced in one or more places and will break the following:
            </span>
            <div v-if="guidesUsingCustomEntity.length">
                <strong
                    class="delete-entity-modal--warning-spacing delete-entity-modal--error-text">The following guides are targeted to this {{ type }} and must be retargeted before you can
                    delete</strong>
                <div
                    v-for="guide in guidesUsingCustomEntity"
                    :key="guide.id">
                    {{ guide.name }}
                </div>
                <pendo-divider stroke="#dadce5" />
            </div>
            <div v-if="segmentsUsingCustomEntity.length">
                <strong class="delete-entity-modal--warning-spacing">Segments that reference this {{ type }}</strong>
                <div
                    v-for="segment in segmentsUsingCustomEntity"
                    :key="segment.id">
                    {{ segment.name }}
                </div>
            </div>
            <div v-if="guidesUsingSegmentsUsingCustomEntity.length">
                <strong
                    class="delete-entity-modal--warning-spacing">Guides using segments that reference this {{ type }}</strong>
                <div
                    v-for="guide in guidesUsingSegmentsUsingCustomEntity"
                    :key="guide.id">
                    {{ guide.name }}
                </div>
            </div>
        </template>
        <template #footer>
            <div class="delete-entity-modal--footer">
                <pendo-button
                    theme="via"
                    type="secondary"
                    label="Cancel"
                    @click="closeModal" />
                <div
                    v-pendo-tooltip="
                        guidesUsingCustomEntity.length && {
                            arrow: true,
                            multiline: true,
                            content: 'Resolve guide references to enable deletion of this page.'
                        }
                    ">
                    <pendo-button
                        :disabled="!!guidesUsingCustomEntity.length"
                        :loading="isDeletingCustomEntity"
                        theme="via"
                        type="danger"
                        label="Delete"
                        @click="handleDeleteCustomEntity" />
                </div>
            </div>
        </template>
    </pendo-modal>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { PendoAlert, PendoButton, PendoDivider, PendoModal, PendoLoading, PendoTooltip } from '@pendo/components';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';

export default {
    name: 'DeleteCustomEntityModal',
    components: {
        PendoAlert,
        PendoButton,
        PendoDivider,
        PendoModal
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'page'
        },
        entity: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            isDeletingCustomEntity: false,
            deleteCustomEntityError: false
        };
    },
    computed: {
        ...mapState({
            error: (state) => state.pages.error || state.features.error
        }),
        ...mapGetters({
            guidesList: 'guides/list',
            segmentsList: 'filters/segmentsList',
            usesMultiApp: 'subscriptions/usesMultiApp'
        }),
        modalTitle () {
            return `Delete Custom ${startCase(this.type)}`;
        },
        entityReferencedInGuideOrSegment () {
            return (
                this.guidesUsingCustomEntity.length ||
                this.segmentsUsingCustomEntity.length ||
                this.guidesUsingSegmentsUsingCustomEntity.length
            );
        },
        guidesUsingCustomEntity () {
            const entityId = this.type === 'page' ? 'pageId' : 'featureId';
            const guidesUsingCustomEntity = this.guidesList.filter((guide) => {
                return guide.steps.some((guideStep) => {
                    return this.entity.id && guideStep[entityId] === this.entity.id;
                });
            });

            return sortBy(guidesUsingCustomEntity, 'name');
        },
        segmentsUsingCustomEntity () {
            const segmentsUsingCustomEntity = this.segmentsList.filter((segment) => {
                if (!segment.pipeline) return;
                const segmentMatch = JSON.stringify(segment.pipeline).includes(this.entity.id);

                return segmentMatch;
            });

            return sortBy(segmentsUsingCustomEntity, 'name');
        },
        guidesUsingSegmentsUsingCustomEntity () {
            const guidesUsingSegmentsUsingCustomEntity = this.guidesList.filter((guide) => {
                if (!guide.audience) return;
                const stringifiedGuideSegment = JSON.stringify(guide.audience);
                const segmentMatch = this.segmentsUsingCustomEntity.some((segment) =>
                    stringifiedGuideSegment.includes(segment.id)
                );

                return segmentMatch;
            });

            return sortBy(guidesUsingSegmentsUsingCustomEntity, 'name');
        }
    },
    methods: {
        ...mapActions({
            _deleteCustomPage: 'pages/deleteCustomPage',
            _deleteCustomFeature: 'features/deleteCustomFeature'
        }),
        closeModal () {
            this.$emit('close');
        },
        async handleDeleteCustomEntity () {
            this.deleteCustomEntityError = false;
            this.isDeletingCustomEntity = true;

            switch (this.type) {
                case 'page':
                    await this.handleDeleteCustomPage();
                    break;
                case 'feature':
                    await this.handleDeleteCustomFeature();
                    break;
            }

            this.isDeletingCustomEntity = false;

            if (this.error) {
                this.deleteCustomEntityError = true;

                return;
            }

            this.$emit('close');
        },
        async handleDeleteCustomFeature () {
            let { featureId, appId } = this.entity;

            if (this.usesMultiApp) {
                featureId = this.entity.id;
            }

            const customFeature = {
                featureId,
                appId
            };

            await this._deleteCustomFeature({ customFeature });
        },
        async handleDeleteCustomPage () {
            let { pageId, appId } = this.entity;

            if (this.usesMultiApp) {
                pageId = this.entity.id;
            }

            const customPage = {
                pageId,
                appId
            };

            await this._deleteCustomPage({ customPage });
        }
    }
};
</script>

<style lang="scss">
.delete-entity-modal {
    &--warning-spacing {
        display: block;
        margin: 8px 0;
    }

    &--error-text {
        color: $red-error;
    }

    &--footer {
        display: flex;
        justify-content: flex-end;
    }
}

.delete-entity-modal {
    .pendo-button {
        margin-left: 8px;
    }

    .el-alert {
        margin-bottom: 18px;
    }
}
</style>
