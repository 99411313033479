var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"pages-list"},[_c('pendo-table',{directives:[{name:"pendo-loading",rawName:"v-pendo-loading:feather",value:(_vm.isFetchingPageList),expression:"isFetchingPageList",arg:"feather"}],ref:"pagesListTable",staticClass:"pages-list--table",attrs:{"data":_vm.getPages,"filters":_vm.filters,"columns":_vm.columns,"default-sort":_vm.sort,"auto-height":true,"resizable":true,"auto-height-offset":96,"csv-download":"","title":"Pages","row-key":"id"},on:{"column-resize":_vm.onResizeColumn},scopedSlots:_vm._u([{key:"headerActions",fn:function(){return [(!_vm.activeIsDigitalAdoption)?_c('div',{staticClass:"pages-list--table-header"},[_c('pendo-button',{attrs:{"type":"link","theme":"via","prefix-icon":"plus","label":"Add Custom Pages"},on:{"click":_vm.openAddCustomPagesModal}})],1):_vm._e()]},proxy:true},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('pendo-actions-cell',{attrs:{"row":row,"actions":[
                    {
                        type: 'delete',
                        icon: 'trash-2',
                        disabled: !_vm.isCustomPage(row),
                        tooltip: _vm.deleteToolTip(row)
                    }
                ]},on:{"delete":_vm.openDeleteCustomPageModal}})]}},{key:"displayName",fn:function(ref){
                var row = ref.row;
return [_c('p',{staticClass:"pages-list--table--display-name",class:{
                    'is-disabled': _vm.isCustomPageNameEditDisabled(row)
                }},[(_vm.isCustomPage(row))?[_c('pendo-editable-content',{staticClass:"pages-list--table--display-name-inline-edit",attrs:{"value":row.displayName,"before-exit":_vm.updateDisplayName.bind(null, row)},on:{"enterEditMode":function($event){return _vm.onEnterDisplayNameEdit(row)},"exitEditMode":_vm.onExitDisplayNameEdit},scopedSlots:_vm._u([{key:"append",fn:function(ref){
                var confirm = ref.confirm;
                var cancel = ref.cancel;
                var pending = ref.pending;
return [_c('pendo-button',{attrs:{"loading":pending,"theme":"app","icon":"check","type":"primary"},on:{"click":confirm}}),_c('pendo-button',{attrs:{"theme":"app","type":"secondary","icon":"x"},on:{"click":cancel}})]}}],null,true)})]:_vm._e(),(!_vm.isCustomPage(row))?[_vm._v(" "+_vm._s(row.displayName)+" ")]:_vm._e(),(row.dirty && !_vm.isInstalled(row))?_c('span',{staticClass:"page-list-table__name__tag"},[_vm._v(" Awaiting Install ")]):(row.dirty)?_c('span',{staticClass:"page-list-table__name__tag"},[_vm._v(" Processing ")]):_vm._e()],2)]}}])},[_c('div',{staticClass:"pages-list--table--empty",attrs:{"slot":"empty"},slot:"empty"},[_c('pendo-icon',{staticClass:"empty-icon",attrs:{"type":"alert-circle","stroke":"#9a9ca5","size":"24"}}),_c('span',{staticClass:"empty-text"},[_vm._v(" No data found. ")])],1)]),_c('add-custom-pages-modal',{attrs:{"visible":_vm.isAddCustomPagesModalVisible,"pages":_vm.getPages},on:{"close":_vm.closeAddCustomPagesModal}}),_c('delete-custom-entity-modal',{attrs:{"type":"page","visible":_vm.isDeleteCustomPageModalVisible,"entity":_vm.currentlyDeletingRow},on:{"close":_vm.closeDeleteCustomPageModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }