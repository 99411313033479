import { render, staticRenderFns } from "./LanguagePreviewDropdown.vue?vue&type=template&id=0abb9a73&scoped=true&"
import script from "./LanguagePreviewDropdown.vue?vue&type=script&lang=js&"
export * from "./LanguagePreviewDropdown.vue?vue&type=script&lang=js&"
import style0 from "./LanguagePreviewDropdown.vue?vue&type=style&index=0&id=0abb9a73&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0abb9a73",
  null
  
)

export default component.exports