<template>
    <pendo-tag
        :label="activeSegment.name"
        type="filter" />
</template>

<script>
import { PendoTag } from '@pendo/components';
import { mapGetters } from 'vuex';

export default {
    name: 'ActiveSegmentTag',
    components: {
        PendoTag
    },
    computed: {
        ...mapGetters({
            activeSegment: 'filters/activeSegment'
        })
    }
};
</script>
