<template>
    <div class="remove-user-modal">
        <div class="pendo-modal__body remove-user-modal--body">
            <div class="remove-user-modal--message">
                {{ bodyText }}
            </div>
        </div>
        <div class="pendo-modal__footer remove-user-modal--footer">
            <pendo-button
                theme="via"
                type="tertiary"
                label="Cancel"
                @click="closeModal" />
            <pendo-button
                :loading="isRemovingUser"
                :disabled="showRemoveUserSuccessIcon"
                :prefix-icon="showRemoveUserSuccessIcon ? 'check' : undefined"
                theme="via"
                type="danger"
                label="Remove User"
                @click="removeUser" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { PendoButton, PendoNotification } from '@pendo/components';

export default {
    name: 'SettingsTeamInviteModal',
    components: {
        PendoButton
    },
    props: {
        userToRemove: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            showRemoveUserError: false,
            showRemoveUserSuccessIcon: false
        };
    },
    computed: {
        ...mapState({
            removeUserError: (state) => state.users.removeUserError,
            isRemovingUser: (state) => state.users.isRemovingUser
        }),
        ...mapGetters({
            isAdoptV2Sub: 'subscriptions/activeUsesV2Adopt'
        }),
        bodyText () {
            if (this.showRemoveUserError) {
                return 'There was an error removing the user. Please try again.';
            }

            return this.userToRemove.hasLoggedIn
                ? 'Guides created by this user will remain, but they will no longer have access to this team.'
                : 'Removing this user will disable their invitation and they will no longer be able to create an account  within this subscription.';
        }
    },
    methods: {
        ...mapActions({
            _removeUser: 'users/removeUser',
            _fetchUserList: 'users/fetchUserList'
        }),
        ...mapMutations({
            setUserOverlayVisible: 'users/setUserOverlayVisible'
        }),
        async removeUser () {
            await this._removeUser({ id: this.userToRemove.id });
            await this._fetchUserList({ noCache: true });

            if (this.removeUserError && !this.isAdoptV2Sub) {
                this.showRemoveUserError = true;

                return;
            }

            if (this.removeUserError && this.isAdoptV2Sub) {
                PendoNotification({
                    type: 'error',
                    message: `Failed to remove ${this.userToRemove.username}, please try again.`
                });

                return;
            }

            if (this.isAdoptV2Sub) {
                PendoNotification({
                    type: 'success',
                    message: `${this.userToRemove.username} has been successfully removed.`
                });
            }

            this.showRemoveUserSuccessIcon = true;
            this.setUserOverlayVisible({ isVisible: false });

            setTimeout(() => {
                this.closeModal();
            }, 2000);
        },
        closeModal () {
            this.$modal.hide('via-modal');
            this.showRemoveUserError = false;
            this.showRemoveUserSuccessIcon = false;
        }
    }
};
</script>
<style lang="scss">
.remove-user-modal {
    &--footer {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        justify-content: end;
    }
}
</style>
