<!-- will be deprecated in favor of the Guide Activity Table from APP-48943 -->
<template>
    <main class="visitor-list">
        <pendo-table
            ref="guideActivityTable"
            v-pendo-loading:feather="isFetchingGuideActivity"
            :data="guideActivity"
            :columns="columns"
            :max-height="600"
            :filters="filters"
            row-key="visitorId"
            csv-download
            manage-columns
            title="Guide Activity"
            class="guide-activity-list--table"
            @column-change="changeColumns">
            <template slot="filters">
                <pendo-multiselect
                    v-model="activityFilter"
                    :allow-empty="false"
                    :options="activityOptions"
                    full-width
                    trigger-size="mini" />
            </template>
            <div
                slot="empty"
                class="guide-activity-list--table--empty">
                <pendo-icon
                    type="alert-circle"
                    class="empty-icon"
                    stroke="#9a9ca5"
                    size="24" />
                <span class="empty-text">
                    No data found.
                </span>
            </div>
        </pendo-table>
    </main>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { PendoMultiselect, PendoTable, PendoLoading, PendoIcon } from '@pendo/components';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import { rowFormatter, getColumnWidth } from '@/utils/table-formatters';

export default {
    name: 'GuideActivityList',
    components: {
        PendoMultiselect,
        PendoTable,
        PendoIcon
    },
    directives: {
        PendoLoading
    },
    data () {
        return {
            activityFilter: {
                id: 'everyone',
                label: 'Everyone'
            },
            unsubscribeFilterBarListener: null,
            activityOptions: [
                {
                    id: 'everyone',
                    label: 'Everyone'
                },
                {
                    id: 'seen',
                    label: 'Has Seen Guide'
                },
                {
                    id: 'notSeen',
                    label: 'Has Not Seen Guide'
                }
            ]
        };
    },
    computed: {
        ...mapState({
            isFetchingGuideActivity: (state) => state.guideAnalytics.isFetchingGuideActivity,
            guideActivity: (state) => state.guideAnalytics.guideActivity
        }),
        ...mapGetters({
            guide: 'guides/active',
            selectedGuideColumnNames: 'guideAnalytics/guideActivityColumns',
            availableGuideColumns: 'guideAnalytics/availableGuideActivityColumns'
        }),
        filters () {
            const activeFilterId = this.activityFilter.id;

            return [
                {
                    filterFn: (row) => {
                        return {
                            everyone: true,
                            seen: !!row.numSeen || !!row.stepsSeen,
                            notSeen: !row.numSeen && !row.stepsSeen
                        }[activeFilterId];
                    }
                }
            ];
        },
        selectedColumnNames () {
            if (this.guide.isMultiStep) {
                const replaced = this.selectedGuideColumnNames.map((item) => {
                    return item.replace('numSeen', 'stepsSeen');
                });

                return [...new Set(replaced)];
            }

            const replaced = this.selectedGuideColumnNames.map((item) => {
                return item.replace('stepsSeen', 'numSeen');
            });

            return [...new Set(replaced)];
        },
        availableColumns () {
            if (this.guide.isMultiStep) {
                return this.availableGuideColumns.filter((item) => item.prop !== 'numSeen');
            }

            return this.availableGuideColumns.filter((item) => item.prop !== 'stepsSeen');
        },
        columns () {
            const selectedColumns = this.selectedColumnNames.map((name) => {
                const column = this.availableColumns.find((obj) => {
                    return obj.prop === name;
                });

                const columnConfig = {
                    prop: column.prop,
                    label: column.label,
                    sortable: true,
                    visible: true,
                    formatter: (row) => rowFormatter(row, column),
                    width: getColumnWidth(column)
                };

                return columnConfig;
            });

            const unselectedColumns = this.availableColumns
                .filter((col) => {
                    return !this.selectedColumnNames.includes(col.prop);
                })
                .map((col) => col);

            return selectedColumns.concat(unselectedColumns);
        }
    },
    async created () {
        await this.fetchGuideActivity({ guide: this.guide });
        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, () => {
            this.fetchGuideActivity({ guide: this.guide });
        });
    },
    destroyed () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        ...mapActions({
            fetchGuideActivity: 'guideAnalytics/fetchGuideActivity',
            updateUserSetting: 'userSettings/updateAppNamespaceSetting'
        }),
        ...mapMutations({
            setGuideActivityColumns: 'guideAnalytics/setGuideActivityColumns',
            setMultiStepColumns: 'guideAnalytics/setMultiStepColumns',
            setSingleStepColumns: 'guideAnalytics/setSingleStepColumns'
        }),
        async changeColumns (currentColumnsObject) {
            const currentColumns = currentColumnsObject.columns;
            this.setGuideActivityColumns({ guideActivityColumns: currentColumns });
            await this.updateUserSetting({
                name: 'guideActivityColumns',
                value: JSON.stringify(currentColumns)
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.guide-activity-list--table--empty {
    display: flex;
    align-items: center;
    justify-content: center;

    .pendo-icon {
        margin-right: 0.5em;
        display: flex;
    }

    .empty-text {
        color: $gray-primary;
    }
}
</style>
