<template>
    <main class="pages-list">
        <pendo-table
            ref="pagesListTable"
            v-pendo-loading:feather="isFetchingPageList"
            :data="pages"
            :filters="filters"
            :columns="columns"
            :default-sort="{
                prop: 'numVisitors',
                order: 'descending'
            }"
            :auto-height="true"
            :auto-height-offset="96"
            csv-download
            title="Pages"
            row-key="id"
            class="pages-list--table">
            <template #headerActions>
                <div class="pages-list--table-header child-pages">
                    <div class="visibility-note">
                        Note: Only pages with page view data will appear below.
                    </div>
                    <pendo-button
                        v-if="eligibleForCustomPages && hasSegmentFlag('customPageTaggingEnabled')"
                        type="link"
                        theme="via"
                        prefix-icon="plus"
                        label="Add Custom Pages"
                        @click="openAddCustomPagesModal" />
                </div>
            </template>
            <template #actions="{ row }">
                <pendo-actions-cell
                    v-if="row.isChildPage && hasSegmentFlag('customPageTaggingEnabled')"
                    :row="row"
                    :actions="[
                        {
                            type: 'edit',
                            icon: 'edit-2',
                            tooltip: 'Rename Page'
                        },
                        {
                            type: 'delete',
                            icon: 'trash-2',
                            tooltip: 'Delete Page'
                        }
                    ]"
                    @edit="openEditCustomPageModal"
                    @delete="openDeleteCustomPageModal" />
            </template>
            <template #displayName="{ row }">
                <p
                    v-if="!row.isChildPage"
                    class="pages-list--table--display-name">
                    {{ row.displayName }}
                </p>
                <div v-if="row.isChildPage">
                    <p class="pages-list--table--display-name">
                        {{ row.displayName }}
                    </p>
                    <p class="pages-list--table--is-custom-page">
                        Custom Page
                    </p>
                </div>
            </template>
            <div
                slot="empty"
                class="pages-list--table--empty">
                <pendo-icon
                    type="alert-circle"
                    class="empty-icon"
                    stroke="#9a9ca5"
                    size="24" />
                <span class="empty-text">
                    No data found.
                </span>
            </div>
        </pendo-table>
        <add-custom-pages-modal
            :visible="isAddCustomPagesModalVisible"
            :eligible-parent-pages="eligibleParentPages"
            :child-pages="childPages"
            @close="closeAddCustomPagesModal" />
        <edit-custom-page-modal
            :visible="isEditCustomPageModalVisible"
            :custom-page="currentlyEditingRow"
            @close="closeEditCustomPageModal" />
        <delete-custom-page-modal
            :visible="isDeleteCustomPageModalVisible"
            :custom-page="currentlyEditingRow"
            @close="closeDeleteCustomPageModal" />
    </main>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import { formatTimeRangeForTable } from '@/utils/moment';
import { filterFnPageByUrlMatch } from '@/utils/pages';
import { isValidUrl } from '@/utils/utils';
import AddCustomPagesModal from './AddCustomChildPagesModal';
import EditCustomPageModal from './EditCustomChildPageModal';
import DeleteCustomPageModal from './DeleteCustomChildPageModal';
import { PendoActionsCell, PendoButton, PendoIcon, PendoTable, PendoLoading, PendoTooltip } from '@pendo/components';

export default {
    name: 'PagesList',
    components: {
        PendoActionsCell,
        PendoButton,
        PendoIcon,
        PendoTable,
        AddCustomPagesModal,
        EditCustomPageModal,
        DeleteCustomPageModal
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    props: {
        searchString: {
            type: String,
            default: null
        },
        eligibleForCustomPages: {
            type: Boolean,
            default: false
        },
        pageFilterSelection: {
            type: String,
            default: 'All Pages'
        }
    },
    data () {
        return {
            isAddCustomPagesModalVisible: false,
            isEditCustomPageModalVisible: false,
            isDeleteCustomPageModalVisible: false,
            currentlyEditingRow: {}
        };
    },
    computed: {
        ...mapGetters({
            pagesWithAnalytics: 'pages/listWithAnalytics',
            hasSegmentFlag: 'auth/hasSegmentFlag'
        }),
        ...mapState({
            isFetchingPageList: (state) => state.pages.isFetchingWithAnalytics,
            pagesMap: (state) => state.pages.map
        }),
        barePageList () {
            return Object.values(this.pagesMap).sort((a, b) => {
                const aName = a.displayName || a.name;
                const bName = b.displayName || b.name;

                return aName.toLowerCase() > bName.toLowerCase() ? 1 : -1;
            });
        },
        fullPageList () {
            const pagesWithAnalyticsClone = [...this.pagesWithAnalytics];

            // "Custom" child pages need to appear in the page list even if they have 0 views,
            // so that users can edit/delete them
            const childPagesAndAnalytics = this.barePageList.reduce((acc, barePage) => {
                if (!barePage.isChildPage) return acc;

                const childPageHasAnalytics = acc.find((existingPage) => existingPage.id === barePage.id);
                if (childPageHasAnalytics) return acc;

                const childPageToAdd = {
                    ...barePage,
                    numVisitors: 0,
                    pageLoads: 0,
                    avgTime: 0
                };

                acc.push(childPageToAdd);

                return acc;
            }, pagesWithAnalyticsClone);

            return childPagesAndAnalytics;
        },
        pages () {
            if (!this.fullPageList) return [];

            if (this.pageFilterSelection === 'Custom Pages Only') {
                return this.childPages;
            }

            return this.fullPageList;
        },
        eligibleParentPages () {
            return this.barePageList.filter((page) => page.allowChildPages);
        },
        childPages () {
            return this.fullPageList.filter((page) => page.isChildPage);
        },
        filters () {
            return [
                {
                    filterFn: this.filterByPageUrl.bind(this)
                },
                {
                    prop: ['displayName'],
                    value: !isValidUrl(this.searchString) ? this.searchString : ''
                }
            ];
        },
        columns () {
            return [
                {
                    sortable: true,
                    prop: 'displayName',
                    label: 'Page Name',
                    minWidth: 200
                },
                {
                    sortable: true,
                    prop: 'numVisitors',
                    label: 'Number of Visitors',
                    async: true
                },
                {
                    sortable: true,
                    prop: 'pageLoads',
                    label: 'Number of Views',
                    async: true
                },
                {
                    sortable: true,
                    prop: 'avgTime',
                    label: 'Average Time on Page',
                    formatter: this.avgTimeOnPageFormatter,
                    async: true
                },
                {
                    type: 'actions',
                    width: 60
                }
            ];
        }
    },
    methods: {
        ...mapMutations({
            updatePage: 'pages/setMapAtKey'
        }),
        avgTimeOnPageFormatter (row) {
            if (row.avgTime <= 0) return '---';

            return formatTimeRangeForTable(row.avgTime);
        },
        filterByPageUrl (row) {
            if (!isValidUrl(this.searchString)) return true;

            return filterFnPageByUrlMatch(row, this.searchString);
        },
        openAddCustomPagesModal () {
            this.isAddCustomPagesModalVisible = true;
        },
        closeAddCustomPagesModal () {
            this.isAddCustomPagesModalVisible = false;
        },
        openDeleteCustomPageModal (row) {
            this.currentlyEditingRow = row;
            this.isDeleteCustomPageModalVisible = true;
        },
        closeDeleteCustomPageModal () {
            this.isDeleteCustomPageModalVisible = false;
        },
        openEditCustomPageModal (row) {
            this.currentlyEditingRow = row;
            this.isEditCustomPageModalVisible = true;
        },
        closeEditCustomPageModal (customPage) {
            if (customPage) {
                this.$refs.pagesListTable.updateRow(customPage);
                this.updatePage({ key: customPage });
            }

            this.isEditCustomPageModalVisible = false;
        }
    }
};
</script>

<style lang="scss">
.pages-list {
    &--table {
        &-header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-grow: 1;
        }

        &-header.child-pages {
            justify-content: space-between;
        }

        &--display-name {
            line-height: 23px;
            font-weight: 600;
            margin-top: 0;
        }

        &--is-custom-page {
            font-size: 10px;
            letter-spacing: 1.5px;
            margin-top: 0;
            line-height: 120%;
            color: $gray-lighter-2;
            text-transform: uppercase;
        }

        &--empty {
            display: flex;
            align-items: center;
            justify-content: center;

            .pendo-icon {
                margin-right: 0.5em;
                display: flex;
            }

            .empty-text {
                color: $gray-primary;
            }
        }

        .pendo-table__title-actions {
            grid-template-columns: auto 36px;
            flex-grow: 1;
        }
    }

    .visibility-note {
        font-size: 0.875rem;
        padding-left: 10px;
    }
}
</style>
