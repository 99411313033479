import http from '@/utils/http';

export function _inviteNewUser (email) {
    return http.post('/api/user', email).then((res) => res.data);
}

export function _inviteNewUserV2 (user) {
    return http.post('/api/s/_SID_/user', user).then((res) => res.data);
}

export function _updateUser (user) {
    return http.put(`/api/s/_SID_/user/${user.id}`, user).then((res) => res.data);
}

export function _updateUserFlag (id, { flagName, value }) {
    return http.put(`/api/s/_SID_/user/${id}/flag/${flagName}`, { set: value }).then((res) => res.data);
}

export function _removeUser (id) {
    return http.delete(`/api/user/${id}`).then((res) => res.data);
}

export function _removeV2SubUser (id) {
    return http.delete(`/api/s/_SID_/user/${id}`).then((res) => res.data);
}

export function _fetchUserList () {
    return http.get('/api/s/_SID_/user').then((res) => res.data);
}

export function _fetchUser (id) {
    return http.get(`/api/s/_SID_/user/${id}`).then((res) => res.data);
}

export function _resendInvite (id) {
    // you MUST pass `{}` as the body here. axios strips the content-type header when
    // posting with an empty body. failure to pass a body will result in 415 from pendo BE
    return http.post(`/api/s/_SID_/user/${id}/resendinvite`, {}).then((res) => res.data);
}

export async function _updateFlags ({ user, flags }) {
    const { falseRequests, trueRequests, adminChangeRequest } = Object.entries(flags).reduce(
        (acc, flagPair) => {
            const [flagName, flagValue] = flagPair;
            if (flagName === 'systemUser') return acc;
            if (flagName === 'admin') {
                acc.adminChangeRequest = {
                    flagName,
                    value: flagValue
                };

                return acc;
            }

            acc[flagValue === true ? 'trueRequests' : 'falseRequests'].push({
                flagName,
                value: flagValue
            });

            return acc;
        },
        {
            adminChangeRequest: null,
            falseRequests: [],
            trueRequests: []
        }
    );

    // If we're going to admin, do it first so certain flag combinations are allowed
    if (adminChangeRequest && adminChangeRequest.value === true) {
        await _updateUserFlag(user.id, adminChangeRequest);
    }

    await Promise.all(falseRequests.map((falseRequestPair) => _updateUserFlag(user.id, falseRequestPair)));
    await Promise.all(trueRequests.map((trueRequestsPair) => _updateUserFlag(user.id, trueRequestsPair)));
    // If we're going from admin, do it last after we removed disallowed certain flag combinations
    if (adminChangeRequest && adminChangeRequest.value === false) {
        await _updateUserFlag(user.id, adminChangeRequest);
    }
}
