<template>
    <pendo-drawer
        :title="title"
        :visible="visible"
        size="fullscreen"
        @close="handleClose">
        <div class="create-guide-drawer-body">
            <transition name="fade">
                <pendo-form
                    :call-validate="callValidate"
                    :form-defaults="model"
                    class="create-guide-form"
                    :class="{ 'multi-app': usesMultiApp }"
                    form-ref="createGuideForm"
                    @formValidated="handleFormValidation(true)"
                    @invalidForm="handleFormValidation(false)">
                    <pendo-card
                        title="Guide Name"
                        body-min-height="inherit"
                        class="create-guide-card create-guide-card--name">
                        <template #body>
                            <pendo-form-item
                                class="create-guide-card-content"
                                prop="name"
                                :rules="guideNameRules"
                                label="Name Your Guide">
                                <pendo-input
                                    ref="guideNameInput"
                                    v-model="model.name"
                                    class="create-guide-form-input"
                                    data-cy="guide-create--name-input"
                                    placeholder="Unnamed"
                                    autofocus />
                            </pendo-form-item>
                        </template>
                    </pendo-card>
                    <pendo-card
                        data-cy="guide-create--url"
                        body-min-height="inherit"
                        title="App Information"
                        class="create-guide-card create-guide-card--app">
                        <template #body>
                            <div class="create-guide-card-content">
                                <pendo-form-item
                                    v-if="usesMultiApp"
                                    :rules="appSelectRules"
                                    class="app-select"
                                    prop="appSelection">
                                    <pendo-multiselect
                                        v-model="model.appSelection"
                                        min-trigger-width="100%"
                                        :options="appOptions"
                                        label-key="displayName"
                                        data-cy="guide-create--app-dropdown"
                                        :labels="{ top: 'Select an app to start from' }"
                                        placeholder="- None Selected -"
                                        @select="onAppSelect" />
                                </pendo-form-item>
                                <pendo-form-item
                                    :rules="urlValidationRules"
                                    class="launch-url"
                                    prop="url">
                                    <div class="create-guide-app-url-field">
                                        <pendo-input
                                            v-model="model.url"
                                            data-cy="guide-create--url-input"
                                            class="create-guide-form-input"
                                            :labels="{ top: 'Guide Start URL' }"
                                            placeholder="https://yourapp.com"
                                            @change="onLaunchUrlChange">
                                            <template #prefix>
                                                <pendo-icon
                                                    :class="{ 'create-guide-app-url-icon--empty': !model.url }"
                                                    type="link"
                                                    size="16" />
                                            </template>
                                        </pendo-input>
                                    </div>
                                </pendo-form-item>
                            </div>
                            <div
                                v-if="isPendoUser"
                                class="super-only-lookaside">
                                <div>
                                    <pendo-button
                                        icon="git-merge"
                                        type="tertiary"
                                        class="toggle-lookaside-button"
                                        @click="isLookasideInputVisible = !isLookasideInputVisible">
                                        (Super Only) Provide Lookaside Host
                                    </pendo-button>
                                    <pendo-icon
                                        v-pendo-tooltip="{
                                            content:
                                                'Lookaside host option is only visible to users with @pendo.io in their emails',
                                            arrow: true,
                                            classes: 'is-multi-line'
                                        }"
                                        display="inline"
                                        class="lookaside-host-icon"
                                        type="info"
                                        size="14" />
                                </div>
                                <pendo-form-item
                                    v-if="isLookasideInputVisible"
                                    :rules="lookasideHostValidationRules"
                                    prop="lookasideHost">
                                    <label class="lookaside-host-label el-form-item__label">Lookaside Host</label>
                                    <pendo-input
                                        v-model="model.lookasideHost"
                                        autofocus
                                        placeholder="[name]-dot-pendo-[env].appspot.com (no trailing slash)">
                                        <template slot="prepend">
                                            https://
                                        </template>
                                    </pendo-input>
                                </pendo-form-item>
                            </div>
                        </template>
                    </pendo-card>
                </pendo-form>
            </transition>
        </div>
        <template #footer>
            <div class="create-guide-footer">
                <pendo-button
                    data-cy="guide-create--cancel-guide-builder-button"
                    class="cancel-guide-create"
                    label="Cancel"
                    type="secondary"
                    theme="via"
                    @click="handleClose" />
                <pendo-button
                    :disabled="!isValidForm"
                    :loading="launching"
                    data-cy="guide-create--launch-guide-builder-button"
                    class="create-guide-button"
                    :label="`Launch ${guideEditorName}`"
                    type="primary"
                    theme="via"
                    @click="createGuideAndLaunchBuilder" />
            </div>
        </template>
    </pendo-drawer>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
    PendoDrawer,
    PendoCard,
    PendoForm,
    PendoFormItem,
    PendoInput,
    PendoMultiselect,
    PendoButton,
    PendoIcon,
    PendoTooltip,
    PendoNotification
} from '@pendo/components';
import { writeTokenToLocalStorage, launchViaWindowOpener, DESIGNER_LAUNCH_TOKEN_LS_KEY } from '@/utils/designer';
import { getDesignerAppName } from '@/utils/apps';
import { urlValidationRules, lookasideHostValidationRules, isValidLaunchDesignerForm } from '@/utils/utils';

export const emptyModel = {
    name: '',
    url: '',
    lookasideHost: '',
    appSelection: null
};

export default {
    name: 'GuideCreate',
    components: {
        PendoDrawer,
        PendoCard,
        PendoForm,
        PendoFormItem,
        PendoInput,
        PendoMultiselect,
        PendoButton,
        PendoIcon
    },
    directives: {
        PendoTooltip
    },
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        guideId: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            model: Object.assign({}, emptyModel),
            urlValidationRules,
            lookasideHostValidationRules,
            callValidate: false,
            launching: false,
            isLookasideInputVisible: false,
            userManuallyEnteringUrl: false,
            appSelectRules: [
                {
                    required: true,
                    message: 'A target app is required to create a guide',
                    trigger: 'blur'
                }
            ],
            guideNameRules: [
                {
                    required: true,
                    message: 'A name is required for this guide',
                    trigger: 'blur'
                }
            ]
        };
    },
    computed: {
        ...mapState({
            designerUrl: (state) => state.designer.url,
            lastAppUrlMap: (state) => state.designer.lastAppUrlMap
        }),
        ...mapGetters({
            activeApp: 'apps/active',
            isPendoUser: 'auth/isPendoUser',
            usesMultiApp: 'subscriptions/usesMultiApp',
            activeUsesDADesigner: 'subscriptions/activeUsesDADesigner',
            listAllForActiveSubscription: 'apps/listAllForActiveSubscription'
        }),
        guideEditorName () {
            return getDesignerAppName(this.activeUsesDADesigner, 'guide');
        },
        title () {
            if (this.usesMultiApp) return 'Create Guide';

            return `Create ${this.activeApp.displayName} Guide`;
        },
        appOptions () {
            return this.listAllForActiveSubscription.map((app) => {
                return {
                    displayName: app.displayName,
                    id: app.id
                };
            });
        },
        isValidForm () {
            const { url, lookasideHost, name, appSelection } = this.model;

            if (!name) return false;
            if (this.usesMultiApp && !appSelection) return false;

            return isValidLaunchDesignerForm({ url, lookasideHost });
        }
    },
    watch: {
        visible (newVal) {
            if (this.usesMultiApp) return;

            if (newVal) this.model.url = this.designerUrl;
        }
    },
    methods: {
        ...mapActions({
            createGuide: 'guides/create',
            updateDesignerUrl: 'designer/updateDesignerUrl'
        }),
        handleFormValidation (valid) {
            this.callValidate = false;
            this.disabled = !valid;
        },
        handleClose () {
            this.$emit('close');
            this.model = Object.assign({}, emptyModel);
        },
        onAppSelect (app) {
            if (!app) return;

            this.callValidate = true;

            // Don't replace the url if the user has already pasted one in
            if (this.userManuallyEnteringUrl && this.model.url) return;

            const newLaunchUrl = this.lastAppUrlMap[app.id] || '';
            this.model.url = newLaunchUrl;
        },
        onLaunchUrlChange () {
            this.callValidate = true;
            this.userManuallyEnteringUrl = true;
        },
        async createGuideAndLaunchBuilder () {
            this.launching = true;
            const layout = { name: this.model.name, empty: true };
            const appId = this.usesMultiApp ? this.model.appSelection.id : null;

            try {
                await this.createGuide({ layout, appId });
                this.launchGuideBuilder();
                this.$router.push(`/guides/${this.guideId}/settings`);
            } catch (err) {
                this.launching = false;
                // eslint-disable-next-line no-console
                console.error(err);
                PendoNotification({
                    type: 'error',
                    title: `Failed to create guide and launch ${getDesignerAppName(
                        this.activeUsesDADesigner
                    )}. Please try again.`
                });
            }
        },
        launchGuideBuilder () {
            const {
                guideId,
                model: { url, lookasideHost }
            } = this;

            const idForApp = this.usesMultiApp ? this.model.appSelection.id : this.activeApp.id;

            const host = lookasideHost ? `https://${lookasideHost}` : window.location.origin;
            const token = {
                guideId,
                DADesigner: true,
                idForApp,
                type: 'guide',
                target: 'latest',
                host
            };

            this.updateDesignerUrl({ url, appId: idForApp });
            writeTokenToLocalStorage(DESIGNER_LAUNCH_TOKEN_LS_KEY, JSON.stringify(token));
            launchViaWindowOpener(url, token);
        }
    }
};
</script>

<style scoped lang="scss">
.create-guide-drawer-body {
    padding: 32px;
    width: 100vw;
}

.create-guide-form.multi-app {
    display: flex;
    flex-direction: row;

    .create-guide-card--name {
        width: 35vw;
        min-width: 300px;
        margin-right: 30px;
        margin-bottom: 0px;
    }

    .create-guide-card--app {
        flex-grow: 1;
        margin-bottom: 0px;

        .create-guide-card-content {
            max-width: inherit;
            display: flex;

            .app-select {
                width: 35%;
                box-sizing: border-box;
                padding-right: 20px;

                &.is-error {
                    /deep/ .pendo-multiselect__trigger {
                        border-color: $red-error;
                    }
                }
            }

            .launch-url {
                flex-grow: 1;
            }
        }
    }

    .create-guide-form-input {
        max-width: inherit;
    }
}

.lookaside-host-icon {
    margin-left: 5px;
}

.create-guide-footer {
    display: flex;
    justify-content: flex-end;

    .create-guide-button {
        flex-grow: 0;
    }
}

.create-guide {
    &-form {
        &-input {
            max-width: 390px;
        }
    }

    &-card {
        &--name {
            margin-bottom: $default-spacing;
        }

        &-content {
            max-width: 555px;
        }
    }

    &-app-url {
        &-description {
            display: block;
            margin-bottom: $default-spacing;
        }

        &-field {
            display: flex;
        }

        &-button {
            flex-grow: 1;
            margin-left: 8px;
        }

        &-icon--empty {
            color: $gray-lighter-5;
        }
    }
}
@media only screen and (min-width: 1281px) {
    .create-guide-drawer-body {
        padding: 37px 150px;
    }

    .create-guide-card-content {
        max-width: 100%;
    }
}
@media only screen and (min-width: 1921px) {
    .create-guide-drawer-body {
        padding: 37px 250px;
    }
}
</style>
