<template>
    <div class="forgot-password">
        <template v-if="!sendResetPasswordLinkSuccess">
            <div class="forgot-password--main">
                <h2 class="forgot-password--header">
                    Password Reset
                </h2>
                <p class="forgot-password--description">
                    To reset your password, enter the email address you use to sign in.
                </p>
                <pendo-form
                    :form-defaults="model"
                    :form-ref="formRef"
                    :call-validate="callValidate"
                    @invalidForm="handleInvalidForm"
                    @formValidated="handleValidForm">
                    <pendo-form-item
                        :rules="rules.email"
                        label="Email Address"
                        data-cy="forgot-password--email"
                        prop="email">
                        <pendo-input
                            v-model.trim="model.email"
                            :invalid="showSendResetPasswordLinkError" />
                        <div
                            v-if="showSendResetPasswordLinkError"
                            class="el-form-item__error">
                            There was an error. Please try again.
                        </div>
                    </pendo-form-item>
                </pendo-form>
            </div>
            <div class="forgot-password--footer">
                <router-link :to="{ name: 'login', query: { version: route.query.version } }">
                    <pendo-button
                        type="secondary"
                        theme="via"
                        label="Cancel" />
                </router-link>
                <pendo-button
                    :loading="isSendingPasswordResetLink"
                    type="primary"
                    theme="via"
                    label="Get Reset Link"
                    data-cy="forgot-password--reset-link"
                    @click="validateForm" />
            </div>
        </template>
        <template v-if="sendResetPasswordLinkSuccess">
            <div class="forgot-password--main">
                <h2 class="forgot-password--header">
                    Email Sent!
                </h2>
                <div class="forgot-password--description">
                    Check your <strong>{{ model.email }}</strong> inbox for instructions from us on how to reset your
                    password.
                </div>
            </div>
            <div class="forgot-password--footer">
                <router-link :to="{ name: 'login', query: { version: route.query.version } }">
                    <pendo-button
                        type="primary"
                        theme="via"
                        data-cy="forgot-password--back-to-login"
                        label="Back to Login" />
                </router-link>
            </div>
        </template>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { PendoButton, PendoInput, PendoForm, PendoFormItem } from '@pendo/components';

export default {
    name: 'ResetPasswordForm',
    components: {
        PendoButton,
        PendoForm,
        PendoFormItem,
        PendoInput
    },
    data () {
        return {
            isSendingPasswordResetLink: false,
            sendResetPasswordLinkSuccess: false,
            formRef: 'forgotPasswordForm',
            model: {
                email: ''
            },
            rules: {
                email: [{ type: 'email', message: 'Please input a valid email address', trigger: ['blur'] }]
            },
            callValidate: false,
            showSendResetPasswordLinkError: false
        };
    },
    computed: {
        ...mapState({
            sendResetPasswordLinkError: (state) => state.auth.sendResetPasswordLinkError,
            route: (state) => state.route
        })
    },
    created () {
        if (this.route.query.email) {
            this.model.email = this.route.query.email;
        }
    },
    methods: {
        ...mapActions({
            _sendResetPasswordLink: 'auth/sendResetPasswordLink'
        }),
        handleValidForm () {
            this.callValidate = false;

            this.sendResetPasswordLink(this.model);
        },
        handleInvalidForm () {
            this.callValidate = false;
        },
        validateForm () {
            this.callValidate = true;
        },
        async sendResetPasswordLink () {
            this.isSendingPasswordResetLink = true;
            await this._sendResetPasswordLink(this.model);
            this.isSendingPasswordResetLink = false;

            if (this.sendResetPasswordLinkError) {
                this.showResetPasswordLinkError = true;

                const unwatch = this.$watch(
                    'model',
                    () => {
                        this.showSendResetPasswordLinkError = false;
                        if (unwatch) unwatch();
                    },
                    { deep: true }
                );

                return;
            }

            this.sendResetPasswordLinkSuccess = true;
        }
    }
};
</script>

<style lang="scss">
.forgot-password {
    .forgot-password--header {
        margin: 0.25rem 0;
    }

    .forgot-password--description {
        margin-top: 0;
        margin-bottom: 1rem;
        line-height: 1.4;
    }

    .forgot-password--main {
        padding: 0 1.25rem;
    }

    .forgot-password--footer {
        padding: 1.25rem;
        border-top: 1px solid $gray-lighter-6;
        display: grid;
        grid-auto-flow: column;
        justify-content: end;
        grid-gap: 1rem;
    }
}
</style>
