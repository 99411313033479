<template>
    <div class="create-account">
        <invalid-token-error v-if="hasRegistrationTokenError" />
        <set-password-form v-if="!hasRegistrationTokenError" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import SetPasswordForm from '@/components/register/SetPasswordForm';
import InvalidTokenError from '@/components/register/InvalidTokenError';

export default {
    name: 'CreateAccount',
    components: {
        InvalidTokenError,
        SetPasswordForm
    },
    computed: {
        ...mapState({
            hasRegistrationTokenError: (state) => state.auth.hasRegistrationTokenError
        })
    }
};
</script>
