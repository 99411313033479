import store from '@/state/store';
import get from 'lodash/get';
import AnalyticsView from '@/views/Analytics';
import GuideDetailsView from '@/views/GuideDetails';
import SegmentListView from '@/views/SegmentList';
import GuideListView from '@/views/GuideList';
import ResourceCenter from '@/views/ResourceCenter';
import EditModule from '@/components/resource-center/edit-module/EditModule';
import SettingsView from '@/views/Settings';
import WorkflowsDetailsView from '@/views/WorkflowsDetails';
import WorkflowsListView from '@/views/WorkflowsList';
import PublicView from '@/views/Public';
import BadRequest from '@/views/400';
import UnprocessableEntity from '@/views/422';
import NotFound from '@/views/404';
import PathDetailsView from '@/views/PathDetails';
import VisitorDetailsView from '@/views/VisitorDetails';
import TermsOfUse from '@/components/register/TermsOfUse';
import PoweredBy from '@/components/PoweredBy';
import UserProfile from '@/components/settings/UserProfile';
import ManageTeam from '@/components/settings/ManageTeam';
import Integrations from '@/components/settings/Integrations';
import ManageMetadata from '@/components/settings/ManageMetadata';
import GuideSettings from '@/components/guides/settings/GuideDetailsSettings';
import GuideMetrics from '@/components/guides/metrics/GuideDetailsMetrics';
import GuidePollResponses from '@/components/guides/polls/GuideDetailsPollResponses';
import AnalyticsDashboard from '@/components/analytics/AnalyticsDashboard';
import AnalyticsPortfolioDashboard from '@/components/analytics/portfolio/PortfolioDashboard';
import AnalyticsPagesDashboard from '@/components/analytics/pages/PagesDashboard';
import AnalyticsFeaturesDashboard from '@/components/analytics/features/FeaturesDashboard';
import AnalyticsVisitorsDashboard from '@/components/analytics/visitors/VisitorsDashboard';
import AnalyticsPathsDashboard from '@/components/paths/PathsDashboard';
import SubscriptionSettings from '@/components/settings/SubscriptionSettings';
import AppSettingsView from '@/components/settings/app-settings/AppSettingsView';
import AppDetails from '@/components/settings/app-settings/AppDetails';
import RawEvents from '@/components/settings/RawEvents';

const routes = [
    {
        path: '/',
        name: 'root',
        meta: {
            beforeResolve: (to, from, next) => {
                if (store.getters['auth/isLoading']) {
                    store.watch(store.getters['auth/isLoaded'], () => proceed());
                } else {
                    proceed();
                }

                function proceed () {
                    if (!store.getters['auth/isAuthenticated']) {
                        next({ name: 'login', query: to.query });
                    } else if (store.getters['subscriptions/usesMultiApp']) {
                        next({ name: 'analyticsPortfolio', query: to.query });
                    } else {
                        next({ name: 'guideList', query: to.query });
                    }
                }
            },
            authRequired: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: PublicView,
        meta: {
            title: 'Login',
            component: 'LoginForm',
            beforeResolve (to, from, next) {
                if (store.getters['auth/isLoading']) {
                    store.watch(store.getters['auth/isLoaded'], () => proceed());
                } else {
                    proceed();
                }

                function proceed () {
                    if (store.getters['auth/isAuthenticated']) {
                        next({ name: 'root', query: to.query });
                    } else {
                        next();
                    }
                }
            }
        }
    },
    {
        path: '/forgot',
        name: 'forgotPassword',
        component: PublicView,
        meta: {
            component: 'ForgotPassword',
            title: 'Password Reset',
            beforeResolve: (to, from, next) => {
                if (store.getters['auth/isLoading']) {
                    store.watch(store.getters['auth/isLoaded'], () => proceed());
                } else {
                    proceed();
                }

                function proceed () {
                    if (!store.getters['auth/isAuthenticated']) {
                        next();
                    } else if (store.getters['subscriptions/usesMultiApp']) {
                        next({ name: 'analyticsPortfolio', query: to.query });
                    } else {
                        next({ name: 'guideList', query: to.query });
                    }
                }
            }
        }
    },
    {
        path: '/join/invite/:email/:token',
        name: 'createAccount',
        components: {
            default: PublicView,
            footer: TermsOfUse
        },
        meta: {
            component: 'CreateAccount',
            title: 'Create Account',
            async beforeResolve (to, from, next) {
                let unwatch;
                if (store.getters['auth/isLoading']) {
                    unwatch = store.watch(store.getters['auth/isLoaded'], () => proceed());
                } else {
                    proceed();
                }

                async function proceed () {
                    if (store.getters['auth/isAuthenticated']) {
                        await store.dispatch('auth/logout');
                    }
                    if (unwatch) unwatch();

                    const { token } = to.params;
                    await store.dispatch('auth/validateNewUserRegistrationToken', { token });
                    next();
                }
            }
        }
    },
    {
        path: '/newtoadopt/login',
        name: 'createAdoptAccount',
        components: {
            default: PublicView,
            footer: TermsOfUse
        },
        meta: {
            component: 'NewToAdoptLoginForm',
            title: 'Create Adopt Account',
            beforeResolve (to, from, next) {
                let unwatch;
                if (store.getters['auth/isLoading']) {
                    unwatch = store.watch(store.getters['auth/isLoaded'], () => proceed());
                } else {
                    proceed();
                }

                async function proceed () {
                    if (store.getters['auth/isAuthenticated']) {
                        await store.dispatch('auth/logout');
                    }
                    if (unwatch) unwatch();
                    next();
                }
            }
        }
    },
    {
        path: '/register/resetpassword/:email/:token',
        name: 'setPassword',
        components: {
            default: PublicView,
            footer: TermsOfUse
        },
        meta: {
            component: 'CreateAdoptAccount',
            title: 'Create Adopt Account',
            beforeResolve (to, from, next) {
                let unwatch;
                if (store.getters['auth/isLoading']) {
                    unwatch = store.watch(store.getters['auth/isLoaded'], () => proceed());
                } else {
                    proceed();
                }

                async function proceed () {
                    if (store.getters['auth/isAuthenticated']) {
                        await store.dispatch('auth/logout');
                    }
                    if (unwatch) unwatch();
                    next();
                }
            }
        }
    },
    {
        path: '/resetpassword/:email/expiredlink',
        name: 'expiredLinkPage',
        component: PublicView,
        meta: {
            component: 'ExpiredLinkPage',
            title: 'Expired Link Page',
            beforeResolve: (to, from, next) => {
                if (store.getters['auth/isLoading']) {
                    store.watch(store.getters['auth/isLoaded'], () => proceed());
                } else {
                    proceed();
                }

                function proceed () {
                    if (!store.getters['auth/isAuthenticated']) {
                        next();
                    } else if (store.getters['subscriptions/usesMultiApp']) {
                        next({ name: 'analyticsPortfolio', query: to.query });
                    } else {
                        next({ name: 'guideList', query: to.query });
                    }
                }
            }
        }
    },
    {
        path: '/invitation/confirm/:email/:invitationToken',
        name: 'confirmation',
        components: {
            default: PublicView,
            footer: TermsOfUse
        },
        meta: {
            component: 'Confirmation',
            title: 'confirmation',
            hideNavbar: true,
            async beforeResolve (to, from, next) {
                const { invitationToken } = to.params;
                store.dispatch('auth/validateInvitationToken', { invitationToken });
                if (store.state.auth.hasInvalidInvitationKeyError) {
                    next({ name: 'UnprocessableEntity' });

                    return;
                }

                return next();
            }
        }
    },
    {
        path: '/reset/:token',
        name: 'resetPassword',
        component: PublicView,
        meta: {
            component: 'ResetPassword',
            title: 'Reset Password',
            async beforeResolve (to, from, next) {
                const { token } = to.params;
                await store.dispatch('auth/validateResetPasswordToken', { token });

                return next();
            }
        }
    },
    {
        path: '/inactive',
        name: 'inactive',
        component: PublicView,
        meta: {
            title: 'Inactive',
            component: 'NoActiveSubscriptions',
            authRequired: true
        }
    },
    {
        path: '/analytics',
        name: 'analytics',
        components: {
            default: AnalyticsView,
            footer: PoweredBy
        },
        meta: {
            title: 'Analytics',
            authRequired: true,
            beforeResolve (to, from, next) {
                const hasAnalytics =
                    store.getters['subscriptions/activeHasAnalytics'] || store.getters['apps/activeHasAnalytics'];

                if (hasAnalytics) {
                    next();
                } else {
                    next({
                        name: 'guideList',
                        query: to.query
                    });
                }
            }
        },
        children: [
            {
                name: 'analyticsPortfolio',
                path: 'portfolio',
                component: AnalyticsPortfolioDashboard,
                meta: {
                    title: 'Portfolio Overview'
                },
                beforeEnter (to, from, next) {
                    if (!store.state.auth.segmentFlagsLoaded) {
                        store.watch(() => store.state.auth.segmentFlagsLoaded, proceed);
                    } else {
                        proceed();
                    }

                    function proceed () {
                        const hasAdoptMultiAppSegmentFlag = store.getters['auth/hasSegmentFlag']('adoptMultiApp');
                        const usesV2Adopt = store.getters['subscriptions/activeUsesV2Adopt'];
                        if (usesV2Adopt && hasAdoptMultiAppSegmentFlag) {
                            next();
                        } else {
                            next({ name: 'analyticsDashboard' });
                        }
                    }
                }
            },
            {
                name: 'analyticsDashboard',
                path: 'dashboard',
                component: AnalyticsDashboard,
                meta: {
                    title: 'Dashboard'
                },
                beforeEnter (to, from, next) {
                    if (!store.state.auth.segmentFlagsLoaded) {
                        store.watch(() => store.state.auth.segmentFlagsLoaded, proceed);
                    } else {
                        proceed();
                    }

                    function proceed () {
                        const hasAdoptMultiAppSegmentFlag = store.getters['auth/hasSegmentFlag']('adoptMultiApp');
                        const usesV2Adopt = store.getters['subscriptions/activeUsesV2Adopt'];
                        if (usesV2Adopt && hasAdoptMultiAppSegmentFlag) {
                            to.meta.title = 'Application Usage';
                        }
                        next();
                    }
                }
            },
            {
                name: 'pagesDashboard',
                path: 'pages',
                component: AnalyticsPagesDashboard,
                meta: {
                    title: 'Pages'
                }
            },
            {
                name: 'featuresDashboard',
                path: 'features',
                component: AnalyticsFeaturesDashboard,
                meta: {
                    title: 'Features'
                }
            },
            {
                name: 'visitorsDashboard',
                path: 'visitors',
                component: AnalyticsVisitorsDashboard,
                meta: {
                    title: 'Visitors'
                },
                beforeEnter (to, from, next) {
                    if (!store.state.auth.segmentFlagsLoaded) {
                        store.watch(() => store.state.auth.segmentFlagsLoaded, proceed);
                    } else {
                        proceed();
                    }

                    function proceed () {
                        const usesV2Adopt = store.getters['subscriptions/activeUsesV2Adopt'];
                        if (usesV2Adopt) {
                            next();
                        } else {
                            next({ name: 'analyticsDashboard' });
                        }
                    }
                }
            }
        ]
    },
    {
        name: 'pathsDashboard',
        path: '/paths',
        component: AnalyticsPathsDashboard,
        meta: {
            title: 'Paths'
        },
        beforeEnter (to, from, next) {
            if (!store.state.auth.segmentFlagsLoaded) {
                store.watch(() => store.state.auth.segmentFlagsLoaded, proceed);
            } else {
                proceed();
            }

            function proceed () {
                const hasAdoptV2PathsSegmentFlag = store.getters['auth/hasSegmentFlag']('adoptV2Paths');
                const usesV2Adopt = store.getters['subscriptions/activeUsesV2Adopt'];
                if (usesV2Adopt && hasAdoptV2PathsSegmentFlag) {
                    next();
                } else {
                    next({ name: 'analyticsDashboard' });
                }
            }
        }
    },
    {
        name: 'path',
        path: '/paths/:id?',
        components: {
            default: PathDetailsView,
            footer: PoweredBy
        },
        meta: {
            title: 'Path',
            authRequired: true
        },
        props: {
            default: true
        },
        beforeResolve (to, from, next) {
            const usesV2Adopt = store.getters['subscriptions/activeUsesV2Adopt'];
            if (usesV2Adopt) {
                next();
            } else {
                next({ name: 'analyticsDashboard' });
            }
        }
    },
    {
        path: '/analytics/visitors/:visitorId',
        name: 'visitorDetails',
        components: {
            default: VisitorDetailsView,
            footer: PoweredBy
        },
        meta: {
            title: 'Visitor',
            authRequired: true
        },
        props: {
            default: true
        },
        beforeResolve (to, from, next) {
            const usesV2Adopt = store.getters['subscriptions/activeUsesV2Adopt'];
            if (usesV2Adopt) {
                next();
            } else {
                next({ name: 'analyticsDashboard' });
            }
        }
    },
    {
        path: '/segments',
        name: 'segmentList',
        components: {
            default: SegmentListView,
            footer: PoweredBy
        },
        meta: {
            title: 'Segments',
            authRequired: true
        },
        beforeEnter (to, from, next) {
            // preventing flash of empty table for DA users
            if (!store.state.auth.segmentFlagsLoaded) {
                store.watch(() => store.state.auth.segmentFlagsLoaded, proceed);
            } else {
                proceed();
            }

            function proceed () {
                const usesV2Adopt = store.getters['subscriptions/activeUsesV2Adopt'];
                if (usesV2Adopt) {
                    next();
                } else {
                    next({ name: 'root' });
                }
            }
        }
    },
    {
        path: '/guides',
        name: 'guideList',
        components: {
            default: GuideListView,
            footer: PoweredBy
        },
        meta: {
            title: 'Guides',
            authRequired: true
        },
        beforeEnter (to, from, next) {
            // preventing flash of empty table for DA users
            if (!store.state.auth.segmentFlagsLoaded) {
                store.watch(() => store.state.auth.segmentFlagsLoaded, next);
            } else {
                next();
            }
        }
    },
    {
        path: '/guides/:id',
        name: 'guideDetails',
        components: {
            default: GuideDetailsView,
            footer: PoweredBy
        },
        meta: {
            title: 'Guide',
            authRequired: true
        },
        children: [
            {
                path: 'settings',
                name: 'guideSettings',
                component: GuideSettings,
                meta: {
                    title: 'Settings'
                }
            },
            {
                path: 'metrics',
                name: 'guideMetrics',
                component: GuideMetrics,
                meta: {
                    title: 'Metrics'
                }
            },
            {
                path: 'polls',
                name: 'guidePolls',
                component: GuidePollResponses,
                meta: {
                    title: 'Poll Responses'
                }
            }
        ]
    },
    {
        path: '/resource-center/:originId?/:activeState?',
        name: 'resourceCenter',
        components: {
            default: ResourceCenter,
            footer: PoweredBy
        },
        props: {
            default: true
        },
        beforeEnter (to, from, next) {
            if (!store.state.auth.segmentFlagsLoaded) {
                store.watch(() => store.state.auth.segmentFlagsLoaded, proceed);
            } else {
                proceed();
            }

            function proceed () {
                const activeHasResourceCenter = store.getters['subscriptions/activeHasResourceCenter'];
                if (activeHasResourceCenter) {
                    next();
                } else {
                    next({ name: 'root' });
                }
            }
        },
        meta: {
            title: 'Resource Center',
            authRequired: true
        }
    },
    {
        path: '/resource-center/:originId/:moduleRouteParam/:moduleId',
        name: 'EditModule',
        components: {
            default: EditModule,
            footer: PoweredBy
        },
        props: true,
        beforeEnter (to, from, next) {
            if (!store.state.auth.segmentFlagsLoaded) {
                store.watch(() => store.state.auth.segmentFlagsLoaded, proceed);
            } else {
                proceed();
            }

            async function proceed () {
                if (!store.getters['subscriptions/activeHasResourceCenter']) {
                    next({ name: 'root' });

                    return;
                }

                if (store.getters['subscriptions/usesMultiApp']) {
                    next();

                    return;
                }

                let activeResourceCenterId = get(store, 'state.resourceCenter.active.id', null);
                if (!activeResourceCenterId) {
                    await store.dispatch('resourceCenter/get');
                    activeResourceCenterId = get(store, 'state.resourceCenter.active.id', null);
                }

                const originId = get(to, 'params.originId', null);
                if (activeResourceCenterId === originId) {
                    next();

                    return;
                }

                next({
                    name: 'resourceCenter',
                    params: {
                        activeState: 'draft',
                        originId: activeResourceCenterId
                    }
                });
            }
        },
        meta: {
            title: 'Resource Center',
            authRequired: true,
            parentPath: '/resource-center/:originId?/:publishedState?'
        }
    },
    {
        path: '/settings/app/:id',
        name: 'appSettings',
        components: {
            default: AppSettingsView,
            footer: PoweredBy
        },
        meta: {
            title: 'App Settings',
            authRequired: true,
            beforeResolve (to, from, next) {
                const isDigitalAdoption = store.getters['subscriptions/activeIsDigitalAdoption'];
                if (store.getters['auth/isAdmin'] && isDigitalAdoption) {
                    next();
                } else {
                    next({ name: 'userProfile' });
                }
            }
        },
        children: [
            {
                path: 'details',
                name: 'appDetails',
                component: AppDetails,
                meta: {
                    title: 'Details',
                    authRequired: true
                }
            },
            {
                path: 'raw-events',
                name: 'rawEvents',
                component: RawEvents,
                meta: {
                    title: 'Raw Events',
                    authRequired: true,
                    beforeResolve (to, from, next) {
                        const isDigitalAdoption = store.getters['subscriptions/activeIsDigitalAdoption'];
                        if (store.getters['auth/isAdmin'] && isDigitalAdoption) {
                            next();
                        } else {
                            next({ name: 'userProfile' });
                        }
                    }
                }
            }
        ]
    },
    {
        path: '/settings',
        name: 'settings',
        components: {
            default: SettingsView,
            footer: PoweredBy
        },
        meta: {
            title: 'Settings',
            authRequired: true
        },
        children: [
            {
                path: 'profile',
                name: 'userProfile',
                component: UserProfile,
                meta: {
                    title: 'Profile'
                }
            },
            {
                path: 'team',
                name: 'manageTeam',
                component: ManageTeam,
                meta: {
                    title: 'Team',
                    beforeResolve (to, from, next) {
                        if (store.getters['auth/isAdmin']) {
                            store.dispatch('users/fetchUserList');
                            next();
                        } else {
                            next({ name: 'userProfile' });
                        }
                    }
                }
            },
            {
                path: 'integrations',
                name: 'integrations',
                component: Integrations,
                meta: {
                    title: 'Integrations',
                    beforeResolve (to, from, next) {
                        const isAdmin = store.getters['auth/isAdmin'];
                        if (!isAdmin) return next({ name: 'userProfile' });

                        const apiAccess = store.getters['apps/activeHasApiAccess'];
                        const isAdoptV2Sub = store.getters['subscriptions/activeUsesV2Adopt'];
                        const allowIntegrationKey = store.getters['subscriptions/activeSubHasFlag'](
                            'allowIntegrationKey'
                        );

                        const adoptV1Access = !isAdoptV2Sub && apiAccess;
                        const adoptV2Access = isAdoptV2Sub && allowIntegrationKey;

                        if (adoptV1Access || adoptV2Access) {
                            store.dispatch('integrationKeys/loadAll');
                            next();
                        } else {
                            next({ name: 'userProfile' });
                        }
                    }
                }
            },
            {
                path: 'metadata',
                name: 'manageMetadata',
                component: ManageMetadata,
                meta: {
                    title: 'Metadata',
                    beforeResolve (to, from, next) {
                        const activeIsDigitalAdoption = store.getters['subscriptions/activeIsDigitalAdoption'];
                        const isAdmin = store.getters['auth/isAdmin'];
                        if (isAdmin && activeIsDigitalAdoption) {
                            next();
                        } else {
                            next({ name: 'userProfile' });
                        }
                    }
                }
            },
            {
                path: 'subscription',
                name: 'subscriptionSettings',
                component: SubscriptionSettings,
                meta: {
                    title: 'Subscription',
                    beforeResolve (to, from, next) {
                        const isDigitalAdoption = store.getters['subscriptions/activeIsDigitalAdoption'];
                        if (store.getters['auth/isAdmin'] && isDigitalAdoption) {
                            next();
                        } else {
                            next({ name: 'userProfile' });
                        }
                    }
                }
            }
        ]
    },
    {
        path: '/workflows',
        name: 'workflowsList',
        components: {
            default: WorkflowsListView,
            footer: PoweredBy
        },
        meta: {
            title: 'Workflows',
            authRequired: true
        },
        beforeEnter (to, from, next) {
            const activeIsDigitalAdoption = store.getters['subscriptions/activeIsDigitalAdoption'];
            if (activeIsDigitalAdoption) {
                next();
            } else {
                next({ name: 'notFound' });
            }
        }
    },
    {
        path: '/workflows/:id',
        name: 'workflowsDetails',
        components: {
            default: WorkflowsDetailsView,
            footer: PoweredBy
        },
        meta: {
            title: 'Workflows',
            authRequired: true
        },
        props: {
            default: true
        },
        beforeEnter (to, from, next) {
            const activeIsDigitalAdoption = store.getters['subscriptions/activeIsDigitalAdoption'];
            if (activeIsDigitalAdoption) {
                next();
            } else {
                next({ name: 'notFound' });
            }
        }
    },
    {
        path: '/400',
        name: 'BadRequest',
        components: {
            default: BadRequest,
            footer: PoweredBy
        },
        meta: {
            title: 'Bad Request'
        }
    },
    {
        path: '/422',
        name: 'UnprocessableEntity',
        components: {
            default: UnprocessableEntity,
            footer: PoweredBy
        },
        meta: {
            title: 'Unprocessable Entity',
            hideNavbar: true
        }
    },
    {
        path: '/404',
        name: 'notFound',
        components: {
            default: NotFound,
            footer: PoweredBy
        },
        meta: {
            title: 'Not Found'
        }
    },
    {
        path: '*',
        redirect: '/404'
    }
];

export default routes;
