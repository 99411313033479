<template>
    <div class="view--guide-details">
        <div
            v-if="isLoading"
            class="loading-empty-state" />
        <template v-else>
            <guide-details-header @openGuideCopyModal="openGuideCopyModal" />
            <guide-details-content />
        </template>

        <guide-copy-modal
            :visible="showGuideCopyModal"
            :guide="guide"
            @close="closeGuideCopyModal" />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import { PendoLoading } from '@pendo/components';
import GuideDetailsHeader from '@/components/guides/GuideDetailsHeader';
import GuideDetailsContent from '@/components/guides/GuideDetailsContent';
import GuideCopyModal from '@/components/guides/list/GuideCopyModal';
import { generateAppUid } from '@/utils/apps';

export default {
    name: 'GuideDetailsView',
    components: {
        GuideDetailsHeader,
        GuideDetailsContent,
        GuideCopyModal
    },
    directives: {
        PendoLoading
    },
    data () {
        return {
            showGuideCopyModal: false
        };
    },
    computed: {
        ...mapState({
            isFetchingGuides: (state) => state.guides.isFetching,
            guideId: (state) => state.route.params.id,
            subscriptionId: (state) => state.subscriptions.activeId,
            activeAccountId: (state) => state.subscriptions.activeAccountId,
            activeGuideId: (state) => state.guides.activeId
        }),
        ...mapGetters({
            guide: 'guides/active',
            usesMultiApp: 'subscriptions/usesMultiApp'
        }),
        isLoading () {
            return this.isFetchingGuides || !this.activeGuideId;
        }
    },
    async created () {
        const fetchingPromise = this.fetchGuides();
        if (this.isFetchingGuides) {
            this.setShowFullscreenLoading(true);
            await fetchingPromise;
        }

        this.setActiveGuideById({ id: this.guideId });
        this.setShowFullscreenLoading(false);

        if (isEmpty(this.guide)) return this.$router.push({ name: 'notFound' });

        const { appId } = this.guide;

        if (this.usesMultiApp) {
            await Promise.all([this.updateGuidePreviewable({ appId }), this.updateSupportsWatermark({ appId })]);
        } else {
            const uid = generateAppUid(this.subscriptionId, appId);
            await this.updateActiveApp({ uid, accountId: this.activeAccountId });
        }

        this.formatDocumentTitleByRoute();
    },
    methods: {
        ...mapActions({
            updateGuidePreviewable: 'guides/updateGuidePreviewable',
            updateSupportsWatermark: 'guides/updateSupportsWatermark',
            updateActiveApp: 'apps/updateActive',
            fetchGuides: 'guides/fetch',
            formatDocumentTitleByRoute: 'router/formatDocumentTitleByRoute'
        }),
        ...mapMutations({
            setActiveGuideById: 'guides/setActiveById',
            setShowFullscreenLoading: 'router/setShowFullscreenLoading'
        }),
        openGuideCopyModal () {
            this.showGuideCopyModal = true;
        },
        closeGuideCopyModal () {
            this.showGuideCopyModal = false;
        }
    }
};
</script>
<style lang="scss" scoped>
.loading-empty-state {
    height: 100%;
    width: 100%;
}
</style>
