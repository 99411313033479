import get from 'lodash/get';
import has from 'lodash/has';
import cloneDeep from 'lodash/cloneDeep';
import isBoolean from 'lodash/isBoolean';
import { BuildingBlock, BuildingBlockLayouts } from '@pendo/services/BuildingBlocks';
import { version as sharedServiceVersion } from '@pendo/services/package.json';
import {
    createStepId,
    createModuleId,
    createResourceCenterModule,
    addModuleToResourceCenter
} from '@/utils/resource-center.js';
import { getBuildingBlocks } from '@/utils/guides';
import { EDITOR_TYPES } from '@pendo/services/Constants';

// Aliasing moduleIds to moduleTypes because that more closely describes what is stored in
// the object. It also helps to distinguish these values from places in the code where we are actually
// creating and using a true unique module id
const { moduleIds: moduleTypes } = BuildingBlockLayouts;

export const guidePatchFields = ['attributes', 'audience', 'audienceUiHint', 'launchMethod', 'state', 'name'];

export const stepPatchFields = [
    'buildingBlocks',
    'dom',
    'content',
    'contentJs',
    'contentCss',
    'attributes',
    'elementPathRule',
    'pageId'
];

function getFrameColor (buildingBlocks) {
    const titleContainer = BuildingBlock.findBlockByDomId(
        buildingBlocks,
        'pendo-resource-center-module-title-container'
    );
    const frameColorProperty = titleContainer.properties.find((property) => property.name === 'frameColor');

    return get(frameColorProperty, 'value', null);
}

function getFrameTop (buildingBlocks) {
    const titleContainer = BuildingBlock.findBlockByDomId(
        buildingBlocks,
        'pendo-resource-center-module-title-container'
    );
    const frameWidthProperty = titleContainer.properties.find((property) => property.name === 'frameTop');

    return get(frameWidthProperty, 'value', null);
}

function getWidthProperty (buildingBlocks) {
    const widthProperty = buildingBlocks.properties.find((property) => property.name === 'layout_width');

    return widthProperty.value;
}

function setModuleName (resourceCenterModule, moduleType) {
    switch (moduleType) {
        case moduleTypes.guideList:
            resourceCenterModule.name = 'Guide List Module';
            break;
        case moduleTypes.onboarding:
            resourceCenterModule.name = 'Onboarding Module';
            break;
        case moduleTypes.iframe:
            resourceCenterModule.name = 'External Website';
            break;
    }
}

function setBuildingBlocksAndDom (resourceCenterModule, moduleType, buildingBlocks) {
    const { createResourceCenterBuildingBlocksForLayout } = BuildingBlockLayouts;

    const moduleBuildingBlocks = createResourceCenterBuildingBlocksForLayout(
        moduleType,
        resourceCenterModule.steps[0].id,
        {
            frameColor: getFrameColor(buildingBlocks),
            frameTop: getFrameTop(buildingBlocks),
            moduleTitle: resourceCenterModule.name,
            width: getWidthProperty(buildingBlocks),
            iframeSrc: ''
        }
    );
    resourceCenterModule.steps[0].buildingBlocks = JSON.stringify(moduleBuildingBlocks);
    resourceCenterModule.steps[0].dom = JSON.stringify(BuildingBlock.buildingBlocksToDom(moduleBuildingBlocks));
}

async function createResourceCenterModuleObject (homeView, moduleType, additionalAttributes = {}) {
    const [moduleId, stepId] = await Promise.all([createModuleId(homeView.id), createStepId()]);

    const doNotResume = get(homeView, 'attributes.doNotResume');

    const module = {
        id: moduleId,
        state: 'draft',
        isMultiStep: false,
        supportsBuildingBlocks: true,
        audienceUiHint: {
            filters: []
        },
        name: 'Unnamed',
        launchMethod: 'api',
        appId: homeView.appId,
        authoredLanguage: homeView.authoredLanguage,
        editorType: EDITOR_TYPES.ADOPT_UI,
        attributes: {
            type: 'building-block',
            sharedServiceVersion,
            device: {
                type: 'desktop'
            },
            resourceCenter: {
                children: [],
                isTopLevel: false,
                // This is kind of confusing, but the moduleId property here should be set to a string that
                // represents the type of module this is. The different module types are defined in
                // BuildingBlockLayouts.moduleIds which itself has a confusing name
                moduleId: moduleType,
                // BE code is modeled in such a way that originId should point back to the _draft_ version
                // of the module
                originId: moduleId,
                homeViewId: homeView.id,
                isModule: true
            }
        },
        steps: [
            {
                id: stepId,
                guideId: moduleId,
                attributes: {},
                templateId: 'building-block-guide',
                rank: 5000000
            }
        ]
    };

    Object.assign(module.attributes, additionalAttributes);

    // This value is unset/undefined until it has been set once.
    // Don't worry about messing with it if it hasn't been set on the homeView before to a Boolean
    if (isBoolean(doNotResume)) {
        module.attributes.doNotResume = doNotResume;
    }

    return module;
}

function getModuleConfig (moduleType) {
    switch (moduleType) {
        case moduleTypes.guideList:
            return {
                title: 'Guide List Module',
                subtitle: 'Product Walkthroughs'
            };
        case moduleTypes.onboarding:
            return {
                title: 'Onboarding Module',
                subtitle: 'Onboarding Module'
            };
        case moduleTypes.iframe:
            return {
                title: 'External Website',
                subtitle: 'View External Content'
            };
    }
}

async function addStepContent (resourceCenterModule) {
    if (has(resourceCenterModule, 'steps[0].buildingBlocks') && has(resourceCenterModule, 'steps[0].dom')) {
        return Promise.resolve(resourceCenterModule.steps[0]);
    }

    const resourceCenterModuleStepsClone = cloneDeep(resourceCenterModule.steps);

    return getBuildingBlocks(resourceCenterModuleStepsClone).then((stepsWithContent) => stepsWithContent[0]);
}

export async function createModule (draftHomeView, moduleToAdd) {
    const moduleType = moduleToAdd.type ? moduleToAdd.type : moduleToAdd;
    const moduleConfig = getModuleConfig(moduleType, moduleToAdd);
    const [resourceCenterModule, draftHomeViewStepWithContent] = await Promise.all([
        await createResourceCenterModuleObject(draftHomeView, moduleType),
        await addStepContent(draftHomeView)
    ]);
    const buildingBlocks = JSON.parse(draftHomeViewStepWithContent.buildingBlocks);
    setModuleName(resourceCenterModule, moduleType);
    setBuildingBlocksAndDom(resourceCenterModule, moduleType, buildingBlocks);

    // create the module on the BE
    const savedResourceCenterModule = await createResourceCenterModule(draftHomeView.id, resourceCenterModule);

    return { module: savedResourceCenterModule, moduleConfig };
}

export async function addToResourceCenter (module, moduleConfig, homeViewId) {
    // add the newly created module to the resource center
    const { subtitle, title } = moduleConfig;
    const { id, name } = module;

    const updatedResourceCenter = await addModuleToResourceCenter(homeViewId, { id, title, subtitle, name });

    return updatedResourceCenter;
}
