<template>
    <pendo-app
        id="app"
        :sidebar-width="sidebarWidth"
        :topbar-height="topbarHeight"
        background-color="#F8F8F9">
        <div v-if="showNavigation">
            <left-navbar v-if="usesLeftNav" />
            <navbar v-else />
        </div>
        <pendo-page>
            <router-view
                id="view"
                :key="globalRouteKey" />
            <router-view name="footer" />
        </pendo-page>
        <via-modal />
        <div
            v-if="updatingActiveApp || fullscreenLoading"
            class="full-app-loading">
            <via-rings :loading="true" />
        </div>
    </pendo-app>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { PendoApp, PendoPage } from '@pendo/components';
import ViaModal from '@/components/ViaModal';
import ViaRings from '@/components/ViaRings';
import Navbar from '@/components/Navbar';
import LeftNavbar from '@/components/LeftNavbar';
import get from 'lodash/get';

export default {
    name: 'ViaUI',
    components: {
        PendoApp,
        PendoPage,
        ViaModal,
        ViaRings,
        Navbar,
        LeftNavbar
    },
    computed: {
        ...mapState({
            updatingActiveApp: (state) => state.apps.updatingActive,
            fullscreenLoading: (state) => state.router.showFullscreenLoading,
            globalRouteKey: (state) => state.router.globalRouteKey
        }),
        ...mapGetters({
            isAuthenticated: 'auth/isAuthenticated',
            hasSegmentFlag: 'auth/hasSegmentFlag',
            activeUsesV2Adopt: 'subscriptions/activeUsesV2Adopt',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption'
        }),
        usesLeftNav () {
            if (this.activeIsDigitalAdoption) return true;

            return this.hasSegmentFlag('adoptLeftNav') && this.activeUsesV2Adopt;
        },
        showNavigation () {
            const hideNavbar = get(this, '$route.meta.hideNavbar', false);

            return !hideNavbar && this.isAuthenticated;
        },
        sidebarWidth () {
            if (this.usesLeftNav) {
                return '72px;';
            }

            return 0;
        },
        topbarHeight () {
            if (this.usesLeftNav) {
                return '0px';
            }

            return '64px';
        }
    }
};
</script>

<style lang="scss">
@import 'styles/index';

.full-app-loading {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); // stylelint-disable-line sh-waqar/declaration-use-variable
}
</style>
