<template>
    <pendo-page-content id="workflows-dashboard-content">
        <div class="workflows-dashboard">
            <div
                class="workflows-dashboard-row"
                data-cy="workflows-query-builder-card">
                <pendo-card
                    ref="queryBuilderCard"
                    body-min-height="0"
                    title="Settings"
                    :collapsible="hasQueryRun"
                    @collapse-change="onQueryBuilderCollapsed">
                    <workflows-query-builder />
                </pendo-card>
            </div>

            <div
                v-if="queryBuilderCollapsed && !$workflows.isAggregationInFlight"
                class="workflows-dashboard-row"
                data-cy="workflows-query-tags">
                <workflows-query-viewer />
            </div>

            <div
                v-if="hasQueryRun && !hasNoData && !$workflows.isAggregationInFlight"
                class="workflows-dashboard-row">
                <workflows-stats />
            </div>

            <div
                v-if="hasQueryRun && !hasNoData"
                class="workflows-visitor-table"
                data-cy="workflows-visitor-table">
                <workflows-visitor-table />
            </div>

            <pendo-card v-if="hasNoData">
                <div
                    class="workflows-empty-state"
                    data-cy="workflows-empty-state">
                    <img
                        class="workflows-empty-state__image"
                        src="@/img/workflows-details-empty-state.png">

                    <h3 class="workflows-empty-state__title">
                        No Data Available
                    </h3>

                    <p class="workflows-empty-state__body">
                        Either adjust any applied filters or return to this Workflow once more data is available. Data
                        will appear here as usage occurs.
                    </p>
                </div>
            </pendo-card>
        </div>
    </pendo-page-content>
</template>

<script>
import WorkflowsQueryBuilder from './WorkflowsQueryBuilder';
import WorkflowsQueryViewer from './WorkflowsQueryViewer';
import WorkflowsVisitorTable from './WorkflowsVisitorTable';
import WorkflowsStats from './WorkflowsStats';
import { PendoCard, PendoPageContent } from '@pendo/components';

export default {
    name: 'WorkflowsDetailContent',
    components: {
        PendoCard,
        PendoPageContent,
        WorkflowsQueryBuilder,
        WorkflowsQueryViewer,
        WorkflowsVisitorTable,
        WorkflowsStats
    },
    inject: ['$workflows'],
    data () {
        return {
            queryBuilderCollapsed: false
        };
    },
    computed: {
        hasNoData () {
            const { isAggregationInFlight, workflowVisitors } = this.$workflows;

            return this.hasQueryRun && !isAggregationInFlight && workflowVisitors && workflowVisitors.length === 0;
        },
        hasQueryRun () {
            const { isAggregationInFlight, lastRanWorkflow } = this.$workflows;

            return Boolean(lastRanWorkflow) || isAggregationInFlight;
        }
    },
    watch: {
        '$workflows.isAggregationInFlight': function (val) {
            if (val) {
                this.$refs.queryBuilderCard.collapseCard();
                this.queryBuilderCollapsed = true;
            } else if (!this.$workflows.lastRanWorkflow) {
                this.$refs.queryBuilderCard.expandCard();
                this.queryBuilderCollapsed = false;
            }
        }
    },
    methods: {
        onQueryBuilderCollapsed ({ collapsed }) {
            this.queryBuilderCollapsed = collapsed;
        }
    }
};
</script>

<style lang="scss">
.workflows-dashboard {
    display: grid;
    grid-template-columns: minmax(372px, 1fr);
    grid-gap: 12px;
}

.workflows-empty-state {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    margin: 56px auto;
    text-align: left;
    width: 300px;

    &__image {
        margin: 0 25px;
        width: 250px;
    }

    &__title {
        font-size: 22px;
        font-weight: 600;
        line-height: 120%;
        margin: 24px 0 12px;
    }

    &__body {
        font-size: 18px;
        line-height: 25px;
        margin: 0;
    }
}
</style>
