<template>
    <page-header
        title="Guides"
        :show-banner="true">
        <template #actions>
            <pendo-button
                v-if="canManageTheme"
                theme="via"
                type="secondary"
                class="manage-theme"
                prefix-icon="droplet"
                label="Manage Theme"
                @click="manageTheme" />
            <pendo-button
                v-if="showInternalOnlyVdsButton"
                theme="via"
                type="secondary"
                class="create-guide"
                data-cy="guide-list--create-guide"
                prefix-icon="plus"
                label="Create VDS Guide"
                @click="internalOnlyCreateVdsGuide" />
            <pendo-button
                theme="via"
                type="primary"
                class="create-guide"
                data-cy="guide-list--create-guide"
                prefix-icon="plus"
                label="Create Guide"
                @click="handleCreateGuide" />
            <layout-chooser
                v-if="showChooser"
                @onChooseLayout="chooseLayout"
                @onClose="closeSelectionModal" />
            <guide-create
                :visible="showGuideCreate"
                :guide-id="guideId"
                @close="closeSelectionModal" />
            <guide-theme-app-chooser
                :visible="showThemeAppChooser"
                @close="closeSelectionModal"
                @launchThemeEditor="launchThemeEditor({ app: $event })" />
        </template>
    </page-header>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { PendoButton } from '@pendo/components';
import PageHeader from '@/components/PageHeader';
import LayoutChooser from '@/components/guides/layouts/LayoutChooser';
import GuideCreate from '@/components/guides/list/GuideCreate';
import GuideThemeAppChooser from '@/components/guides/list/GuideThemeAppChooser';
import { openDesignerHere } from '@/utils/designer';
import get from 'lodash/get';
import { EDITOR_TYPES } from '@pendo/services/Constants';

export default {
    name: 'GuideListHeader',
    components: {
        LayoutChooser,
        GuideCreate,
        PageHeader,
        PendoButton,
        GuideThemeAppChooser
    },
    props: {
        showGuideCreate: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            customerAppUrl: '',
            showChooser: false,
            showThemeAppChooser: false,
            forceVds: false
        };
    },
    computed: {
        ...mapState({
            guideId: (state) => state.guides.activeId,
            supportsWatermark: (state) => state.guides.supportsWatermark
        }),
        ...mapGetters({
            activeApp: 'apps/active',
            hasSegmentFlag: 'auth/hasSegmentFlag',
            productName: 'subscriptions/productName',
            activeUsesV2Adopt: 'subscriptions/activeUsesV2Adopt',
            activeUsesDADesigner: 'subscriptions/activeUsesDADesigner',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            theme: 'themes/defaultTheme',
            appWhiteLabelSettings: 'apps/whiteLabelSettings',
            isPendoUser: 'auth/isPendoUser',
            usesMultiApp: 'subscriptions/usesMultiApp'
        }),
        showInternalOnlyVdsButton () {
            return this.isPendoUser && this.activeIsDigitalAdoption && this.hasSegmentFlag('adoptInternalOnlyVds');
        },
        canManageTheme () {
            return this.supportsWatermark;
        }
    },
    methods: {
        ...mapActions({
            createGuide: 'guides/create',
            refreshGuide: 'guides/refreshGuide'
        }),
        async chooseLayout (layout, appId) {
            const editorType = this.forceVds ? EDITOR_TYPES.VDS : EDITOR_TYPES.ADOPT_UI;
            await this.createGuide({ layout, appId, editorType });
            // createGuide runs migrations for steps created from layouts. refreshGuide
            // is called to make sure the guide is updated and has the latest buldingBlocks and dom content
            await this.refreshGuide({ id: this.guideId });
            this.$router.push(`/guides/${this.guideId}/settings`);
        },
        manageTheme () {
            if (this.usesMultiApp) {
                return this.launchThemeAppChooser();
            }

            this.launchThemeEditor();
        },
        launchThemeAppChooser () {
            this.showThemeAppChooser = true;
        },
        closeSelectionModal () {
            this.forceVds = false;
            this.showChooser = false;
            this.$emit('showGuideCreateChange', false);
            this.showThemeAppChooser = false;
        },
        launchThemeEditor ({ app } = {}) {
            const appToEdit = app || this.activeApp;

            const whiteLabelToken = {
                ...this.appWhiteLabelSettings,
                productName: this.productName
            };

            openDesignerHere(
                {
                    adoptv2: this.activeUsesV2Adopt,
                    type: 'watermark',
                    idForApp: get(appToEdit, 'id'),
                    disableFetchAgentAPIKey: true
                },
                window.location,
                undefined,
                whiteLabelToken
            );

            this.showThemeAppChooser = false;
        },
        internalOnlyCreateVdsGuide () {
            this.forceVds = true;
            this.handleCreateGuide();
        },
        handleCreateGuide () {
            if (this.activeUsesDADesigner && !this.forceVds) {
                this.$emit('showGuideCreateChange', true);
            } else {
                this.showChooser = true;
            }
        }
    }
};
</script>
