<template>
    <pendo-page-content tabs>
        <pendo-tabs
            v-model="activeTab"
            :tabs="tabs"
            theme="via"
            class="analytics-tabs">
            <template #analyticsPortfolio>
                <portfolio-dashboard />
            </template>
            <template #analyticsDashboard>
                <analytics-dashboard />
            </template>
            <template #pagesDashboard>
                <pages-dashboard />
            </template>
            <template #featuresDashboard>
                <features-dashboard />
            </template>
            <template #visitorsDashboard>
                <visitors-dashboard />
            </template>
        </pendo-tabs>
    </pendo-page-content>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { PendoTabs, PendoPageContent } from '@pendo/components';
import PortfolioDashboard from '@/components/analytics/portfolio/PortfolioDashboard';
import AnalyticsDashboard from '@/components/analytics/AnalyticsDashboard';
import PagesDashboard from '@/components/analytics/pages/PagesDashboard';
import VisitorsDashboard from '@/components/analytics/visitors/VisitorsDashboard';
import FeaturesDashboard from '@/components/analytics/features/FeaturesDashboard';

export default {
    name: 'AnalyticsContent',
    components: {
        PendoTabs,
        PendoPageContent,
        PortfolioDashboard,
        AnalyticsDashboard,
        PagesDashboard,
        FeaturesDashboard,
        VisitorsDashboard
    },
    computed: {
        ...mapState({
            route: (state) => state.route
        }),
        ...mapGetters({
            showFeaturesWidgets: 'features/showFeaturesWidgets',
            hasSegmentFlag: 'auth/hasSegmentFlag',
            activeUsesV2Adopt: 'subscriptions/activeUsesV2Adopt'
        }),
        activeTab: {
            get () {
                const defaultRoute = this.showPortfolioTab ? 'analyticsPortfolio' : 'analyticsDashboard';

                return this.route.name || defaultRoute;
            },
            set (tab) {
                this.$router.push({ name: tab });
            }
        },
        showVisitorsTab () {
            return this.activeUsesV2Adopt;
        },
        showPortfolioTab () {
            return this.activeUsesV2Adopt && this.hasSegmentFlag('adoptMultiApp');
        },
        tabs () {
            return [
                this.showPortfolioTab && {
                    prop: 'analyticsPortfolio',
                    label: 'Portfolio Overview',
                    lazy: true
                },
                {
                    prop: 'analyticsDashboard',
                    label: this.showPortfolioTab ? 'Application Usage' : 'Dashboard',
                    lazy: true
                },
                {
                    prop: 'pagesDashboard',
                    label: 'Pages',
                    lazy: true
                },
                this.showFeaturesWidgets && {
                    prop: 'featuresDashboard',
                    label: 'Features',
                    lazy: true
                },
                this.showVisitorsTab && {
                    prop: 'visitorsDashboard',
                    label: 'Visitors',
                    lazy: true
                }
            ].filter(Boolean);
        }
    },
    watch: {
        showFeaturesWidgets (showFeatures) {
            if (!showFeatures && this.activeTab === 'featuresDashboard') {
                this.activeTab = this.showPortfolioTab ? 'analyticsPortfolio' : 'analyticsDashboard';
            }
        }
    }
};
</script>
