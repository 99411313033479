<template>
    <pendo-card
        class="poll-questions-card"
        title="Questions"
        body-min-height="0">
        <template slot="filters">
            <active-segment-tag />
            <active-date-range-tag />
        </template>
        <pendo-collapse :force-always-open="polls.length === 1 ? ['poll-question-0'] : []">
            <pendo-collapse-item
                v-for="(poll, index) in polls"
                :key="poll.id"
                :name="`poll-question-${index}`"
                :title="`${index + 1}. ${poll.question}`"
                type="card">
                <pendo-collapse-item
                    :name="`poll-question-${index}-details`"
                    expanded
                    title="Poll Details"
                    type="details">
                    <poll-details
                        :poll="poll"
                        :time-series="activeTimeSeries"
                        :segment="activeSegment" />
                </pendo-collapse-item>
                <pendo-collapse-item
                    v-if="shouldShowPollActivity(poll)"
                    :name="`poll-question-${index}-activity`"
                    expanded
                    title="Poll Activity"
                    type="details">
                    <poll-activity-chart
                        :poll="poll"
                        :time-series="activeTimeSeries"
                        :segment="activeSegment" />
                </pendo-collapse-item>
                <poll-response-list
                    :poll="poll"
                    :time-series="activeTimeSeries"
                    :segment="activeSegment" />
            </pendo-collapse-item>
        </pendo-collapse>
    </pendo-card>
</template>

<script>
import get from 'lodash/get';
import { PendoCard, PendoCollapse, PendoCollapseItem } from '@pendo/components';
import { mapGetters } from 'vuex';
import PollDetails from '@/components/guides/polls/PollDetails';
import PollActivityChart from '@/components/guides/polls/PollActivityChart';
import PollResponseList from '@/components/guides/polls/PollResponseList';
import ActiveDateRangeTag from '@/components/filters/ActiveDateRangeTag';
import ActiveSegmentTag from '@/components/filters/ActiveSegmentTag';

export default {
    name: 'PollQuestionsCard',
    components: {
        ActiveSegmentTag,
        ActiveDateRangeTag,
        PendoCard,
        PendoCollapse,
        PendoCollapseItem,
        PollActivityChart,
        PollResponseList,
        PollDetails
    },
    computed: {
        ...mapGetters({
            activeTimeSeries: 'filters/activeTimeSeries',
            activeSegment: 'filters/activeSegment',
            polls: 'guides/activePolls'
        })
    },
    methods: {
        shouldShowPollActivity (poll) {
            const pollType = get(poll, 'attributes.type');

            return pollType !== 'FreeForm';
        }
    }
};
</script>

<style lang="scss">
.poll-questions-card {
    .pendo-card__filters {
        border-bottom: 1px solid $gray-lighter-5;
        padding: 12px;
        display: flex;
        flex-wrap: wrap;
        margin: 0;

        .pendo-tag + .pendo-tag {
            margin-left: 4px;
        }

        > * {
            margin: 4px;
        }
    }
}
</style>
