<template>
    <page-header
        title="Analytics"
        class="analytics-header"
        :show-banner="true">
        <template #actions>
            <pendo-button
                v-if="showCreatePathAction"
                theme="via"
                type="primary"
                prefix-icon="plus"
                label="Create Path"
                @click="onCreatePathClick" />
            <pendo-button
                v-if="showLaunchPages"
                class="designer-launcher"
                theme="via"
                type="primary"
                prefix-icon="external-link"
                label="Tag Pages"
                @click="showPageModal" />
            <pendo-button
                v-if="showLaunchFeatures"
                class="designer-launcher"
                theme="via"
                type="primary"
                prefix-icon="external-link"
                label="Tag Features"
                @click="showFeatureModal" />
        </template>
    </page-header>
</template>

<script>
import { mapGetters } from 'vuex';
import PageHeader from '@/components/PageHeader';
import { PendoButton } from '@pendo/components';

export default {
    name: 'AnalyticsHeader',
    components: {
        PendoButton,
        PageHeader
    },

    computed: {
        ...mapGetters({
            app: 'apps/active',
            getListActiveApps: 'apps/getListActiveApps',
            hasSegmentFlag: 'auth/hasSegmentFlag',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            usesMultiApp: 'subscriptions/usesMultiApp'
        }),
        showCreatePathAction () {
            return this.$route.name === 'pathsDashboard' && this.hasSegmentFlag('adoptV2Paths');
        },
        hasIntegratedApps () {
            return this.getListActiveApps.some(({ integrated }) => integrated);
        },
        showLaunchPages () {
            return (
                this.$route.name === 'pagesDashboard' &&
                (this.app.integrated || (this.usesMultiApp && this.hasIntegratedApps)) &&
                this.activeIsDigitalAdoption
            );
        },
        showLaunchFeatures () {
            return (
                this.$route.name === 'featuresDashboard' &&
                (this.app.integrated || (this.usesMultiApp && this.hasIntegratedApps)) &&
                this.activeIsDigitalAdoption
            );
        }
    },
    methods: {
        onCreatePathClick () {
            this.$router.push({ name: 'path', params: { id: 'new' } });
        },
        showPageModal () {
            this.$modal.show('via-modal', {
                title: 'Launch to edit',
                component: 'LaunchDesignerModal',
                componentProps: {
                    type: 'page',
                    translationWarning: false
                }
            });
        },
        showFeatureModal () {
            this.$modal.show('via-modal', {
                title: "Enter your app's URL",
                component: 'LaunchDesignerModal',
                componentProps: {
                    type: 'feature'
                }
            });
        }
    }
};
</script>
