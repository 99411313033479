import { isValidUrl, normalizeUrl } from '@/utils/utils';

export function filterFnPageByUrlMatch (page, searchString) {
    return page.rules.find((rule) => {
        return RegExp(rule.parsedRule).test(searchString);
    });
}

export function filterPagesByNameOrUrl (pages, searchString) {
    const formattedSearchString = searchString.toLowerCase();

    if (isValidUrl(searchString)) {
        return pages.filter((page) => filterFnPageByUrlMatch(page, formattedSearchString));
    }

    return pages.filter((page) => {
        return page.displayName.toLowerCase().includes(formattedSearchString);
    });
}

export async function normalizeValue (value) {
    value = value.trim();
    if (!isValidUrl(value)) {
        return value;
    }

    return normalizeUrl(value);
}
