<template>
    <div class="portfolio-dashboard">
        <global-filters>
            <template #right>
                <div
                    v-if="usesMultiApp"
                    class="filters">
                    <pendo-button
                        :loading="isFetching"
                        type="link"
                        prefix-icon="repeat"
                        label="Pivot Data"
                        @click="pivotData" />
                    <view-by />
                </div>
            </template>
        </global-filters>
        <div class="portfolio-dashboard--grid">
            <div class="portfolio-dashboard--row">
                <portfolio-app-usage-chart class="portfolio-dashboard--app-usage-chart" />
            </div>
            <div class="portfolio-dashboard--row">
                <portfolio-app-usage-list class="portfolio-dashboard--apps-list" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { PendoButton } from '@pendo/components';
import GlobalFilters from '@/components/filters/GlobalFilters';
import PortfolioAppUsageChart from './PortfolioAppUsageChart';
import PortfolioAppUsageList from './PortfolioAppUsageList';
import ViewBy from '@/components/filters/ViewBy';

export default {
    name: 'PortfolioDashboard',
    components: {
        GlobalFilters,
        PortfolioAppUsageChart,
        PortfolioAppUsageList,
        ViewBy,
        PendoButton
    },
    computed: {
        ...mapGetters({
            usesMultiApp: 'subscriptions/usesMultiApp'
        }),
        ...mapState({
            isViewingApplicationsByMetadata: (state) => state.filters.isViewingApplicationsByMetadata,
            isFetchingChart: (state) => state.portfolio.isFetchingChart,
            isFetchingList: (state) => state.portfolio.isFetchingList
        }),
        isFetching () {
            return this.isFetchingChart || this.isFetchingList;
        }
    },
    methods: {
        ...mapActions({
            updatePivotDataToggle: 'filters/updatePivotDataToggle'
        }),
        pivotData () {
            this.updatePivotDataToggle({ isViewingApplicationsByMetadata: !this.isViewingApplicationsByMetadata });
        }
    }
};
</script>

<style lang="scss" scoped>
.portfolio-dashboard {
    .portfolio-dashboard--grid {
        display: grid;
        grid-gap: 32px;
    }

    .portfolio-dashboard--row {
        display: grid;
        grid-gap: 32px;
        grid-template-columns: repeat(auto-fit, minmax(372px, 1fr));
    }
}

.filters {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    column-gap: 10px;

    &--label,
    &--item {
        margin-right: 1em;
        font-size: 0.875em;
        line-height: 1.25em;
    }

    &--item {
        &:last-of-type {
            margin-right: unset;
        }
    }
}

.filters {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    column-gap: 15px;

    &--label,
    &--item {
        margin-right: 1em;
        font-size: 0.875em;
        line-height: 1.25em;
    }

    &--item {
        &:last-of-type {
            margin-right: unset;
        }
    }
}
</style>
