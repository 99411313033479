<template>
    <div class="workflows-stats-row">
        <workflows-stats-card
            status="Completed"
            :current-visitors="getStat('completedCount')"
            :total-visitors="totalVisitors"
            :percentage="roundedPercentages.completed"
            :loading="$workflows.isAggregationInFlight"
            description="Percentage of Visitors that completed the workflow." />
        <workflows-stats-card
            status="Incomplete"
            :current-visitors="getStat('incompletedCount')"
            :total-visitors="totalVisitors"
            :percentage="roundedPercentages.incompleted"
            :loading="$workflows.isAggregationInFlight"
            description="Percentage of Visitors that did not complete the workflow." />
        <workflows-stats-card
            status="Not Started"
            :current-visitors="getStat('neverStartedCount')"
            :total-visitors="totalVisitors"
            :percentage="roundedPercentages.notStarted"
            :loading="$workflows.isAggregationInFlight"
            description="Percentage of Visitors that did not start the workflow." />
    </div>
</template>

<script>
import WorkflowsStatsCard from './WorkflowsStatsCard';
import get from 'lodash/get';

export default {
    name: 'WorkflowsStats',
    components: {
        WorkflowsStatsCard
    },
    inject: ['$workflows'],
    computed: {
        // This is to make sure our percentages always add up to 100%.
        roundedPercentages () {
            const completedPercentage = this.getStat('completedPercentage');
            const incompletedPercentage = this.getStat('incompletedPercentage');
            const notStartedPercentage = this.getStat('neverStartedPercentage');

            if (notStartedPercentage === 0 && incompletedPercentage === 0) {
                return {
                    completed: 1,
                    incompleted: 0,
                    notStarted: 0
                };
            }

            if (notStartedPercentage === 0) {
                const completed = Math.round(completedPercentage * 100) / 100;

                return {
                    completed,
                    incompleted: 1 - completed,
                    notStarted: 0
                };
            }

            const completed = Math.round(completedPercentage * 100) / 100;
            const incompleted = Math.round(incompletedPercentage * 100) / 100;

            return {
                completed,
                incompleted,
                notStarted: 1 - (completed + incompleted)
            };
        },
        totalVisitors () {
            return (
                this.getStat('completedCount') + this.getStat('incompletedCount') + this.getStat('neverStartedCount')
            );
        }
    },
    methods: {
        getStat (key) {
            return get(this.$workflows, `workflowStats.${key}`, 0);
        }
    }
};
</script>

<style>
.workflows-stats-row {
    display: flex;
    gap: 12px;
}
</style>
