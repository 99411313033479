import { render, staticRenderFns } from "./PortfolioAppUsageList.vue?vue&type=template&id=581f21a0&scoped=true&"
import script from "./PortfolioAppUsageList.vue?vue&type=script&lang=js&"
export * from "./PortfolioAppUsageList.vue?vue&type=script&lang=js&"
import style0 from "./PortfolioAppUsageList.vue?vue&type=style&index=0&id=581f21a0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "581f21a0",
  null
  
)

export default component.exports