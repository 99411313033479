<template>
    <div>
        <pendo-drawer
            :title="title"
            size="fullscreen"
            :visible="visible"
            append-to="#app"
            @close="hide">
            <template #body>
                <transition name="fade">
                    <main>
                        <pendo-card
                            title="User Permission Settings"
                            class="card-container">
                            <template #body>
                                <pendo-card-section>
                                    <user-details
                                        class="card-section-content"
                                        :original-user="user"
                                        @valid="handleValidUserDetails"
                                        @invalid="handleInvalidUserDetails" />
                                </pendo-card-section>
                                <pendo-card-section>
                                    <div>
                                        <p class="card-title">
                                            Assign Adopt Access
                                        </p>
                                        <role-type-container
                                            class="role-type-card-section-content"
                                            :original-user="user"
                                            :selected-role-type="null"
                                            title="Adopt Permissions"
                                            @change-sub-level="handleSubscriptionRoleTypeChange"
                                            @change-app-level="handleAllApplicationPermissionChange" />
                                    </div>
                                </pendo-card-section>
                            </template>
                        </pendo-card>
                    </main>
                </transition>
            </template>
            <template #footer>
                <footer class="el-dialog__button-wrapper">
                    <pendo-button
                        v-if="canDelete"
                        :disabled="user.username === loggedInUser.email"
                        theme="via"
                        type="danger"
                        label="Remove User"
                        data-cy="delete-modal-action"
                        @click="$emit('remove-user', user)" />
                    <div class="user-modal-footer--gap" />
                    <pendo-button
                        theme="via"
                        type="secondary"
                        label="Cancel"
                        data-cy="cancel-overlay-action"
                        @click="hide" />
                    <pendo-button
                        :disabled="disableInviteUserButton"
                        :loading="busy"
                        theme="via"
                        type="primary"
                        :label="confirmLabel"
                        data-cy="save-overlay-action"
                        @click="handleSave" />
                </footer>
            </template>
        </pendo-drawer>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { PendoButton, PendoDrawer, PendoCard, PendoCardSection, PendoNotification } from '@pendo/components';
import { USER_ROLES, SUBSCRIPTION_USER_ROLE } from '@/constants/roles-and-permissions';
import {
    isRoleManagedByAPI,
    isAdminBySubscriptionRole,
    isAdminByFlag,
    isSuperBySubscriptionRole,
    isOpsBySubscriptionRole,
    isOpsByUserFlag
} from '@/utils/auth';
import RoleTypeContainer from '@/components/settings/RoleTypeContainer';
import UserDetails from '@/components/settings/UserDetails';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

export default {
    name: 'UserOverlay',
    components: {
        RoleTypeContainer,
        PendoButton,
        PendoCard,
        PendoCardSection,
        PendoDrawer,
        UserDetails
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            isDeleteModalVisible: false,
            userDetails: {},
            applicationRoles: undefined,
            subscriptionRoles: []
        };
    },
    computed: {
        ...mapState({
            visible: (state) => state.users.userOverlayVisible,
            inviteNewUserError: (state) => state.users.inviteNewUserError,
            isInvitingNewUser: (state) => state.users.isInvitingNewUser,
            updateUserError: (state) => state.users.updateUserError,
            isUpdatingUser: (state) => state.users.isUpdatingUser,
            fetchUserError: (state) => state.users.fetchUserError,
            isFetchingUser: (state) => state.users.isFetchingUser,
            loggedInUser: (state) => state.auth.user
        }),
        canDelete () {
            return !isEmpty(this.user);
        },
        confirmLabel () {
            return isEmpty(this.user) ? 'Invite User' : 'Save User';
        },
        title () {
            return isEmpty(this.user) ? 'Invite User' : 'Edit User';
        },
        updatedUser () {
            return {
                ...this.userDetails,
                applicationRoles: this.applicationRoles,
                subscriptionRoles: this.subscriptionRoles
            };
        },
        showAppPermissions () {
            return !(this.subscriptionRoles.indexOf('predefined_sub_1') > -1);
        },
        disableInviteUserButton () {
            return isEmpty(this.userDetails) || !this.subscriptionRoles.length;
        },
        busy () {
            return this.isInvitingNewUser || this.isUpdatingUser || this.isFetchingUser;
        },
        currentUpdateUserError () {
            return this.updateUserError || this.fetchUserError;
        }
    },
    watch: {
        visible (isVisible) {
            if (isVisible) this.userDetails = cloneDeep(this.user);
        },
        currentUpdateUserError (newError) {
            if (newError) PendoNotification({ type: 'error', message: this.currentUpdateUserError });
        }
    },
    methods: {
        ...mapMutations({
            setUserOverlayVisible: 'users/setUserOverlayVisible'
        }),
        ...mapActions('users', ['inviteNewUser', 'update', 'updateFlags', 'removeUser']),
        handleValidUserDetails (userFromForm) {
            this.$set(this, 'userDetails', userFromForm);
        },
        handleInvalidUserDetails () {
            this.$set(this, 'userDetails', {});
        },
        handleSubscriptionRoleTypeChange (subscriptionRoleIds) {
            this.$set(this, 'subscriptionRoles', subscriptionRoleIds);
        },
        handleAllApplicationPermissionChange (applicationPermissionsMap) {
            this.$set(this, 'applicationRoles', applicationPermissionsMap);
        },
        async handleSave () {
            if (isEmpty(this.user)) {
                await this.inviteUser(this.updatedUser);
            } else {
                await this.updateUser(this.user, this.updatedUser);
            }
        },
        hide () {
            this.isDeleteModalVisible = false;
            this.setUserOverlayVisible({ isVisible: false });
            this.$emit('closed');
        },
        async inviteUser (newUser) {
            const { username: email, first, last, applicationRoles = {}, subscriptionRoles } = newUser;
            const user = {
                email,
                role: USER_ROLES.USER,
                first,
                last,
                applicationRoles,
                // we no longer pass the user role with the collection of subscription roles
                // explicitly filter it so that we never include it
                subscriptionRoles: subscriptionRoles.filter((role) => role !== SUBSCRIPTION_USER_ROLE)
            };
            let type = 'success';
            let message = `${newUser.username} has been invited.`;

            await this.inviteNewUser({ user });
            if (this.inviteNewUserError) {
                type = 'error';
                message = this.inviteNewUserError;
            } else {
                this.hide();
            }
            PendoNotification({ type, message });
        },
        async updateUser (oldUser, updatedUser) {
            updatedUser.id = oldUser.id;
            updatedUser.role = USER_ROLES.USER;
            const updatedUserIsAdmin = isAdminBySubscriptionRole(updatedUser);
            const oldUserIsAdmin = isAdminByFlag(oldUser);

            if (updatedUserIsAdmin !== oldUserIsAdmin) {
                await this.updateFlags({
                    user: updatedUser,
                    flags: {
                        admin: updatedUserIsAdmin
                    }
                });
            }

            if (!this.currentUpdateUserError) {
                if (isSuperBySubscriptionRole(updatedUser)) {
                    updatedUser.role = USER_ROLES.SUPER;
                }

                await this.update({
                    user: {
                        ...updatedUser,
                        subscriptionRoles: updatedUser.subscriptionRoles.filter(isRoleManagedByAPI)
                    }
                });
            }

            if (!this.currentUpdateUserError) {
                const updatedUserIsOps = isOpsBySubscriptionRole(updatedUser);
                const oldUserIsOps = isOpsByUserFlag(oldUser);

                if (updatedUserIsOps !== oldUserIsOps) {
                    await this.updateFlags({
                        user: updatedUser,
                        flags: {
                            opsFlag: updatedUserIsOps
                        }
                    });
                }
            }

            if (!this.currentUpdateUserError) {
                this.hide();
                PendoNotification({
                    type: 'success',
                    message: `${updatedUser.username} has been successfully updated.`
                });
            }
        }
    }
};
</script>

<style scoped lang="scss">
.card-title {
    text-transform: uppercase;
    color: $gray-lighter-2;
    font-weight: 700;
    font-size: 12px;
    margin: 22px 24px;
}

.card-section-content {
    margin: 0px 24px 12px 24px;
}

.role-type-card-section-content {
    margin: 0px 24px 12px 24px;
}

.card-container {
    margin: 0 auto 2rem auto;
    max-width: 800px;
    min-width: 400px;
    width: 70%;
}
</style>
