<template>
    <div
        class="subscription-panel"
        tabindex="0">
        <div class="header-wrapper">
            <h2 class="header">
                Subscriptions
            </h2>
        </div>
        <div class="subscriptions-list">
            <div
                v-for="(sub, index) in sortedSubs"
                :key="sub.name"
                tabindex="0">
                <el-tooltip
                    :content="sub.displayName"
                    :disabled="disableSubDisplayNameTooltipMap['subDisplayName' + index]"
                    placement="top-start"
                    :visible-arrow="false"
                    popper-class="subscription-hover--details">
                    <div
                        v-if="sub.id === activeSub.id"
                        class="subscription subscription--active">
                        <pendo-icon
                            class="active-sub-check"
                            stroke="#E6416A"
                            stroke-width="3"
                            type="check"
                            size="20" />
                        <span
                            :ref="'subDisplayName' + index"
                            class="active-sub">{{ sub.displayName }}</span>
                    </div>
                    <div
                        v-else
                        class="subscription"
                        @click.prevent="onSubClick(sub)"
                        @keyup.enter="onSubClick(sub)">
                        <span
                            :ref="'subDisplayName' + index"
                            class="inactive-sub">{{ sub.displayName }}</span>
                    </div>
                </el-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import { PendoIcon } from '@pendo/components';
import { Tooltip } from 'element-ui';
import { mapGetters, mapActions } from 'vuex';
import sortBy from 'lodash/sortBy';

export default {
    name: 'SubscriptionPanel',
    components: {
        PendoIcon,
        ElTooltip: Tooltip
    },
    data () {
        return {
            disableSubDisplayNameTooltipMap: {}
        };
    },
    computed: {
        ...mapGetters({
            subscriptions: 'subscriptions/listAll',
            activeSub: 'subscriptions/active'
        }),
        sortedSubs () {
            const subsWithActiveSubSet = this.subscriptions.map((sub) => {
                if (sub.id === this.activeSub.id) {
                    return { ...sub, active: true };
                }

                return { ...sub, active: false };
            });

            return sortBy(subsWithActiveSubSet, [(sub) => !sub.active, (sub) => sub.displayName.toLowerCase()]);
        }
    },
    mounted () {
        this.disableSubDisplayNameTooltipMap = {};
        Object.keys(this.$refs).forEach((sub) => {
            this.disableSubDisplayNameTooltipMap[sub] =
                this.$refs[sub][0].scrollWidth <= this.$refs[sub][0].offsetWidth;
        });
    },
    methods: {
        ...mapActions({
            switchSubscription: 'subscriptions/updateActive'
        }),
        async onSubClick (sub) {
            this.$emit('close-subscription-panel');
            await this.switchSubscription({ subscriptionId: sub.id });
        }
    }
};
</script>

<style lang="scss" scoped>
$default-spacing: 8px;

.subscription-panel {
    width: 240px;
    border: 1px solid $gray-lighter-5;
    border-radius: 3px;
    background-color: $white;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
    z-index: 503;
    position: fixed;
    bottom: $default-spacing;

    .header-wrapper {
        margin-left: $default-spacing;
        display: flex;
        flex-direction: column;

        .header {
            height: 12px;
            color: $gray-lighter-2;
            font-size: 10px;
            font-weight: bold;
            text-transform: uppercase;
            padding-left: $default-spacing;
            letter-spacing: 1px;
        }
    }

    .subscriptions-list {
        overflow-y: auto;
        max-height: 415px;

        .subscription {
            font-size: 18px;
            padding: 3px 5px 3px 13px;
            display: grid;
            grid-template-columns: 20px 1fr;
            column-gap: 5px;
            grid-template-rows: auto;
            grid-template-areas: 'check name';
            border: 3px solid transparent;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $gray-primary;
            margin-bottom: $default-spacing;

            &:not(.subscription--active) {
                cursor: pointer;

                &:hover {
                    text-decoration: none;
                    color: $gray-primary;
                    background-color: $gray-lighter-5;
                    border: 3px solid $gray-lighter-5;
                }
            }

            .active-sub-check {
                grid-area: check;
                align-self: center;
            }

            .active-sub,
            .inactive-sub {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: $gray-primary;
            }

            .active-sub {
                grid-area: name;
                font-weight: bold;
            }

            .inactive-sub {
                grid-area: name;
            }
        }
    }
}
</style>
