import * as Agg2 from '@pendo/aggregations';
import { request } from '@/utils/aggregations';
import { getTimeSeries } from '@/utils/time-series';
import isArray from 'lodash/isArray';

export async function getVisitorsOverTime ({ appId, segmentId, dateRange, signal }) {
    const timeSeries = getTimeSeries(dateRange);
    const spec = buildVisitorsOverTimeSpec({ appId, timeSeries, segmentId });
    const { messages } = await request(spec, { rowsOnly: false, signal });

    return messages
        .map(({ time, rows }) => ({
            timestamp: time,
            visitorCount: rows[0].count
        }))
        .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
}

export function buildVisitorsOverTimeSpec ({ appId, timeSeries, segmentId }) {
    const { operators: o } = Agg2;

    const appIdParam = isArray(appId) ? appId : [appId];

    return o.aggregation(
        'visitors-over-time',
        o.pipeline(
            o.sources.events({ timeSeries, appId: appIdParam }),
            o.identified('visitorId'),
            o.segment(segmentId),
            o.reduce({
                count: o.count('visitorId')
            })
        )
    );
}
