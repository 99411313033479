<template>
    <pendo-card
        v-pendo-loading:feather="isFetching"
        body-min-height="0"
        title="Visitor Counts"
        class="visitor-counts">
        <template slot="filters">
            <active-segment-tag />
        </template>
        <div class="visitor-counts--metrics">
            <pendo-metric
                :value="todayCount"
                type="primary"
                label="Today" />
            <pendo-metric
                :value="yesterdayCount"
                type="primary"
                label="Yesterday" />
            <pendo-metric
                :value="last7Count"
                type="primary"
                label="Last 7 Days" />
            <pendo-metric
                :value="last30Count"
                type="primary"
                label="Last 30 Days" />
        </div>
    </pendo-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getVisitorCounts } from '@/aggregations/visitor-counts';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import { PendoCard, PendoMetric, PendoLoading } from '@pendo/components';
import ActiveSegmentTag from '@/components/filters/ActiveSegmentTag';
import { isCancel } from 'axios';

export default {
    name: 'AnalyticsVisitorCounts',
    components: {
        ActiveSegmentTag,
        PendoCard,
        PendoMetric
    },
    directives: {
        PendoLoading
    },
    data () {
        return {
            isFetching: false,
            aggCancel: null,
            unsubscribeFilterBarListener: null,
            visitorCounts: null,
            today: null,
            yesterday: null,
            last7: null,
            last30: null
        };
    },
    computed: {
        ...mapState({
            activeSegmentId: (state) => state.filters.activeSegmentId
        }),
        ...mapGetters({
            activeAppId: 'apps/activeId'
        }),
        todayCount () {
            if (this.today == null) {
                return '---';
            }

            return this.today;
        },
        yesterdayCount () {
            if (this.yesterday == null) {
                return '---';
            }

            return this.yesterday;
        },
        last7Count () {
            if (this.last7 == null) {
                return '---';
            }

            return this.last7;
        },
        last30Count () {
            if (this.last30 == null) {
                return '---';
            }

            return this.last30;
        }
    },
    async created () {
        await this.fetchVisitorCounts();
        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, () => {
            this.fetchVisitorCounts();
        });
    },
    destroyed () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        async fetchVisitorCounts () {
            this.isFetching = true;

            if (this.aggCancel) {
                this.aggCancel.abort();
            }

            this.aggCancel = new AbortController();

            try {
                const { today, yesterday, last7, last30 } = await getVisitorCounts({
                    appId: this.activeAppId,
                    segmentId: this.activeSegmentId,
                    signal: this.aggCancel.signal
                });

                this.today = today;
                this.yesterday = yesterday;
                this.last7 = last7;
                this.last30 = last30;

                this.isFetching = false;
            } catch (err) {
                // If we've cancelled our aggs because a segment/app/whatever changed, things are still loading
                if (!isCancel(err)) {
                    this.isFetching = false;
                }
            }
        }
    }
};
</script>

<style lang="scss">
.visitor-counts {
    .pendo-card__body {
        display: grid;
        grid-template-rows: auto 1fr;
    }

    &--metrics {
        display: grid;
        grid-gap: 16px;
        align-items: center;
        justify-items: center;
        justify-content: center;
        padding: 0 0 16px 0;
        grid-template-columns: repeat(auto-fit, minmax(68px, 1fr));
    }
}
</style>
