/*
 * This file will move to shared-services to avoid duplicating code in
 * appengine and via-ui repos. Will move after pendo-io/shared-services#804 merges. APP-50803.
 */

import isNumber from 'lodash/isNumber';
import moment, { DATE_FORMAT } from '@/utils/moment';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const limitUnitsDisplayed = (str, token, limitSize, type) => {
    if (type === 'long') {
        return false;
    }

    const tokenizedArr = str.split(token);

    return tokenizedArr.length >= limitSize;
};

export function prettyTime (input, type = 'short') {
    if (isNumber(input)) {
        let timeStamp = input;
        let prettyStr = '';

        if (timeStamp > DAY) {
            const day = Math.floor(timeStamp / DAY);
            timeStamp -= day * DAY;
            prettyStr = `${day}d`;
        }

        if (!limitUnitsDisplayed(prettyStr, ' ', 2, type) && timeStamp > HOUR) {
            const hour = Math.floor(timeStamp / HOUR);
            timeStamp -= hour * HOUR;
            if (prettyStr.length > 0) {
                prettyStr += ' ';
            }
            prettyStr += `${hour}h`;
        }

        if (!limitUnitsDisplayed(prettyStr, ' ', 2, type) && timeStamp > MINUTE) {
            const minute = Math.floor(timeStamp / MINUTE);
            timeStamp -= minute * MINUTE;
            if (prettyStr.length > 0) {
                prettyStr += ' ';
            }
            prettyStr += `${minute}m`;
        }

        if (!limitUnitsDisplayed(prettyStr, ' ', 2, type) && timeStamp > SECOND) {
            const sec = type === 'long' ? Math.floor(timeStamp / SECOND) : Math.round(timeStamp / SECOND);
            timeStamp -= sec * SECOND;
            if (prettyStr.length > 0) {
                prettyStr += ' ';
            }
            prettyStr += `${sec}s`;
        }

        if (timeStamp > 0) {
            const ms = Math.floor(timeStamp);
            if (ms > 0 && (prettyStr.length === 0 || type === 'long')) {
                if (prettyStr.length > 0) {
                    prettyStr += ' ';
                }
                prettyStr += `${ms}ms`;
            }
        }

        if (input === 0) {
            prettyStr = '0';
        }

        return prettyStr;
    }

    return '--';
}

export function formatValueForSchema (value, schema) {
    if (!schema) {
        return value;
    }

    switch (schema) {
        case 'responseCount':
            if (!isNumber(value)) {
                return value || '';
            }

            return `${value} responses`;
        case 'nps':
            if (!isNumber(value)) {
                return value || '';
            }

            return Math.round(value * 100);
        case 'time':
        case 'date':
            if (!isNumber(value)) {
                return value || '';
            }

            if (value === 0) {
                return '';
            }

            if ([undefined, null, NaN, Infinity, -Infinity].includes(value)) {
                return '--';
            }

            return moment(value).format(DATE_FORMAT.full);
        case 'list':
            if (value && value.length) {
                return value.join(', ');
            }

            return '';
        case 'prettyTime':
            if (isNumber(value) && value === 0) {
                return '--';
            }

            return prettyTime(value);
        case 'guideStatus':
            // Remove the "guide" prefix
            return (value || '').replace(/^guide/, '');
        case 'boolean':
            if (value) {
                return 'True';
            }

            return 'False';
        case 'number':
            return value || 0;
        case 'percentage':
            if (!isNumber(value)) {
                return 'N/A';
            }

            return `${value}%`;
        default:
            if (value === null) {
                return '';
            }

            return value;
    }
}

export function largestRemainderRound (numbers, total = 100, decimals = 0) {
    // hacked together implementation of https://stackoverflow.com/a/13483710
    const multiplier = 10 ** decimals;
    total *= multiplier;
    const result = numbers
        .map((number, index) => {
            number *= multiplier;

            return {
                floor: Math.floor(number),
                remainder: getRemainder(number, decimals + 4),
                index
            };
        })
        .sort((a, b) => b.remainder - a.remainder);
    const lowerSum = result.reduce((sum, current) => {
        return sum + current.floor;
    }, 0);
    const delta = total - lowerSum;
    if (delta <= result.length) {
        for (let i = 0; i < delta; i++) {
            result[i].floor++;
        }
    }

    return result.sort((a, b) => a.index - b.index).map((result) => (result.floor * 1.0) / multiplier);
}
function getRemainder (number, decimals = 4) {
    const remainder = number - Math.floor(number);

    return remainder.toFixed(decimals);
}
