<template>
    <div class="path-share-toggle">
        <pendo-radio-group
            v-model="shared"
            :disabled="disabled"
            label="Visibility"
            button
            :options="visibilityList" />
        <div class="path-share-toggle__explanation">
            <span
                v-show="shared">Everyone at <strong>{{ subscription.name }}</strong> can view and edit this report.</span>
            <span v-show="!shared">Only you can view and edit this report.</span>
        </div>
    </div>
</template>

<script>
import { PendoRadioGroup } from '@pendo/components';
import { mapGetters } from 'vuex';

export default {
    name: 'PathShareToggle',
    components: {
        PendoRadioGroup
    },
    props: {
        value: {
            type: [String, Boolean],
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            visibilityList: [
                {
                    disabled: false,
                    label: 'Only Me',
                    value: false
                },
                {
                    disabled: false,
                    label: 'Everyone',
                    value: true
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            subscription: 'subscriptions/active'
        }),
        shared: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit('input', val);
            }
        }
    }
};
</script>
<style scoped lang="scss">
.path-share-toggle__explanation {
    margin-top: 15px;
    color: $gray-lighter-2;
}

/deep/ .pendo-radio-button {
    label {
        font-weight: normal;
    }
}
</style>
