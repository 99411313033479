<template>
    <pendo-multiselect
        :value="status"
        :allow-empty="false"
        :min-menu-width="140"
        :options="options"
        :popper-options="{ placement: 'bottom-start', class: 'guide-status-changer-panel' }"
        value-key="status"
        @select="confirmStatusChange">
        <pendo-guide-status-trigger
            slot="trigger"
            :size="size"
            :type="size === 'medium' ? 'fill' : undefined" />
        <pendo-guide-status-option
            slot="option"
            slot-scope="{ option }"
            :option="option" />
    </pendo-multiselect>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import capitalize from 'lodash/capitalize';
import { PendoMultiselect, PendoGuideStatusOption, PendoGuideStatusTrigger } from '@pendo/components';
import { convertToSubscriptionTimezone, DATE_FORMAT } from '@/utils/moment';
import get from 'lodash/get';
const GUIDE_STATUS_MODAL_CONFIG = {
    public: {
        title: 'Set guide to Public?',
        body: 'This guide will become visible to all users upon changing status to Public.',
        confirm: 'Set to Public'
    },
    scheduled: {
        title: 'Schedule Guide',
        bodyComponent: 'ScheduledStatusModalBody',
        confirm: 'Schedule Guide'
    },
    draft: {
        title: 'Set guide to Draft?',
        body: 'This guide will not appear to any users while in this state and can still be edited at any time.',
        confirm: 'Set to Draft'
    },
    disabled: {
        title: 'Set guide to Disabled?',
        body: 'This guide will not appear to any users while in this state and can still be edited at any time.',
        confirm: 'Set to Disabled'
    }
};

export default {
    name: 'GuideStatusChanger',
    components: {
        PendoMultiselect,
        PendoGuideStatusOption,
        PendoGuideStatusTrigger
    },
    props: {
        size: {
            type: String,
            default: 'medium'
        },
        guide: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            pendingStatus: null
        };
    },
    computed: {
        ...mapGetters({
            appFromGuide: 'apps/appFromGuide'
        }),
        publicState () {
            if (this.isScheduled) {
                return { label: 'Scheduled', status: 'scheduled' };
            }

            return { label: 'Public', status: 'public' };
        },
        options () {
            return [
                this.publicState,
                {
                    label: 'Disabled',
                    status: 'disabled'
                },
                {
                    label: 'Draft',
                    status: 'draft'
                }
            ];
        },
        status () {
            if (this.guide.state === 'public') {
                return this.publicState;
            }

            return {
                label: capitalize(this.guide.state),
                status: this.guide.state
            };
        },
        isScheduled () {
            const { showsAfter } = this.guide;

            return showsAfter > Date.now();
        }
    },
    methods: {
        ...mapActions({
            patchGuide: 'guides/patch'
        }),
        confirmStatusChange ({ status }) {
            if (status === this.status.status) {
                return;
            }
            this.pendingStatus = status; // used by updateGuide

            let { title, body, confirm, bodyComponent } = GUIDE_STATUS_MODAL_CONFIG[status];
            const guideApp = this.appFromGuide(this.guide);
            const isAppDisabled = get(guideApp, 'applicationFlags.disableExtensionInjection');

            if (status === 'public' && isAppDisabled) {
                body =
                    'The application is disabled.  This guide will become visible to all users once the application is enabled.';
            }

            const { showsAfter, expiresAfter } = this.guide;

            this.$modal.show('via-modal', {
                title,
                body,
                bodyComponent,
                componentProps: {
                    showsAfter: convertToSubscriptionTimezone(showsAfter).format(DATE_FORMAT.full),
                    expiresAfter: expiresAfter && convertToSubscriptionTimezone(expiresAfter).format(DATE_FORMAT.full)
                },
                buttons: [
                    {
                        type: 'tertiary',
                        label: 'Cancel',
                        handler: this.$modal.hide('via-modal')
                    },
                    {
                        type: 'primary',
                        label: confirm,
                        handler: this.updateGuide
                    }
                ]
            });
        },
        async updateGuide () {
            const { id } = this.guide;
            let state = this.pendingStatus;

            if (state === 'scheduled') {
                state = 'public';
            }

            await this.patchGuide({
                guideId: id,
                props: { state }
            });
            this.$modal.hide('via-modal');
        }
    }
};
</script>
