/* eslint-disable no-empty-pattern */
import {
    isAdminByFlag,
    isSuperByRole,
    isOpsByUserFlag,
    setNullApplicationsForExtAppsToEmptyArray,
    isReadOnly,
    inviteConfirmationErrors as errors,
    parseJSONKey
} from '@/utils/auth';
import { updateAdoptV2UserState } from '@/utils/user-settings';
import get from 'lodash/get';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import http from '@/utils/http';
import isUndefined from 'lodash/isUndefined';
import { decodedJZB } from '@/utils/raw-events.js';

export const USER_LOAD_STATUS = {
    NOT_STARTED: 0,
    PENDING: 1,
    COMPLETE: 2
};

export function getInitialState () {
    return {
        user: {},
        userLoadStatus: USER_LOAD_STATUS.NOT_STARTED,
        segmentFlags: [],
        segmentFlagsLoaded: false,
        authenticated: false,
        hasLoginError: false,
        hasResetPasswordError: false,
        hasAcceptingInvitationError: false,
        hasUserNotFoundError: false,
        hasInvitationExpiredError: false,
        hasInvalidInvitationError: false,
        hasInvalidInvitationKeyError: false,
        hasResetPasswordTokenError: false,
        hasRegistrationTokenError: false,
        hasRegistrationPasswordError: false,
        hasUpdateSettingsError: false,
        sendResetPasswordLinkError: false,
        invitationToken: null,
        invitationTokenData: null
    };
}

export const state = getInitialState();

export const mutations = {
    setUserLoadStatus (state, { status }) {
        state.userLoadStatus = status;
    },
    setAuthenticated (state, { status }) {
        state.authenticated = status;
    },
    setUser (state, { user }) {
        state.user = user;
    },
    setUserSegmentFlags (state, { flags }) {
        state.segmentFlags = flags;
    },
    setUserSegmentFlagsLoaded (state, { loaded }) {
        state.segmentFlagsLoaded = loaded;
    },
    setRegistrationPasswordError (state, { hasRegistrationPasswordError }) {
        state.hasRegistrationPasswordError = hasRegistrationPasswordError;
    },
    setRegistrationTokenError (state, { hasRegistrationTokenError }) {
        state.hasRegistrationTokenError = hasRegistrationTokenError;
    },
    setResetPasswordTokenError (state, { hasResetPasswordTokenError }) {
        state.hasResetPasswordTokenError = hasResetPasswordTokenError;
    },
    setResetPasswordError (state, { hasResetPasswordError }) {
        state.hasResetPasswordError = hasResetPasswordError;
    },
    setInvitationToken (state, { invitationToken }) {
        state.invitationToken = invitationToken;
    },
    setAcceptingInvitationError (state, { hasAcceptingInvitationError }) {
        state.hasAcceptingInvitationError = hasAcceptingInvitationError;
    },
    setUserNotFoundError (state, { hasUserNotFoundError }) {
        state.hasUserNotFoundError = hasUserNotFoundError;
    },
    setInvitationExpiredError (state, { hasInvitationExpiredError }) {
        state.hasInvitationExpiredError = hasInvitationExpiredError;
    },
    setInvalidInvitationKeyError (state, { hasInvalidInvitationKeyError }) {
        state.hasInvalidInvitationKeyError = hasInvalidInvitationKeyError;
    },
    setInvitationTokenData (state, { invitationTokenData }) {
        state.invitationTokenData = invitationTokenData;
    },
    setInvalidInvitationError (state, { hasInvalidInvitationError }) {
        state.hasInvalidInvitationError = hasInvalidInvitationError;
    },
    setSendResetPasswordLinkError (state, { sendResetPasswordLinkError }) {
        state.sendResetPasswordLinkError = sendResetPasswordLinkError;
    },
    setLoginError (state, { hasLoginError }) {
        state.hasLoginError = hasLoginError;
    },
    setUpdateSettingsError (state, { hasUpdateSettingsError }) {
        state.hasUpdateSettingsError = hasUpdateSettingsError;
    },
    reset (state) {
        Object.assign(state, {
            ...getInitialState(),
            segmentFlags: state.segmentFlags
        });
    }
};

export const actions = {
    async init ({ dispatch }) {
        await dispatch('getUser');
    },
    async login ({ commit, dispatch }, { email, password } = {}) {
        // Make sure we remove pre-existing localStorage sub/account/app ids prior to login
        window.localStorage.clear();

        commit('setUserLoadStatus', { status: USER_LOAD_STATUS.PENDING });

        try {
            const { subscriptions, subscriptionId, ...user } = await _login(email, password);
            // The condition below causes us to request user data again when we have logged in a v2 user
            if (isUndefined(subscriptions) && !isUndefined(subscriptionId)) {
                return dispatch('getUser', { isAdoptV2: true, isLogin: true });
            }
            commit('setUser', { user });
            commit('setAuthenticated', { status: true });

            await dispatch('hydrate', { subscriptions });

            // router uses userLoadStatus to determine if it can continue resolving the route
            // it should always be set AFTER hydration occurs, as those values are used to determine next route
            commit('setUserLoadStatus', { status: USER_LOAD_STATUS.COMPLETE });
        } catch (error) {
            commit('setUserLoadStatus', { status: USER_LOAD_STATUS.COMPLETE });
            commit('setAuthenticated', { status: false });
            commit('setLoginError', { hasLoginError: true });
        }
    },
    async logout ({ commit }, { postLogout = true } = {}) {
        window.localStorage.clear();

        if (postLogout) {
            await http.post('/logout');
        }

        [
            'analytics',
            'apps',
            'designer',
            'filters',
            'resourceCenter',
            'guides',
            'guideAnalytics',
            'subscriptions',
            'users',
            'pages',
            'themes',
            'layouts',
            'features',
            'reports',
            'workflows'
        ].forEach((moduleName) => {
            commit(`${moduleName}/reset`, null, {
                root: true
            });
        });

        commit('reset');
    },
    async getUser ({ commit, dispatch }, { isAdoptV2, isLogin = false } = {}) {
        commit('setUserLoadStatus', { status: USER_LOAD_STATUS.PENDING });

        try {
            const { subscriptions: subs, ...user } = await _getUser(isAdoptV2);

            // Digital adoption subs that have no extension applications return null instead of []
            const subscriptions = setNullApplicationsForExtAppsToEmptyArray(subs);
            commit('setUser', { user });
            commit('setAuthenticated', { status: true });

            const { activeSubId } = user;

            await dispatch('hydrate', { subscriptions, activeSubId });

            // router uses userLoadStatus to determine if it can continue resolving the route
            // it should always be set AFTER hydration occurs, as those values are used to determine next route
            commit('setUserLoadStatus', { status: USER_LOAD_STATUS.COMPLETE });
        } catch (error) {
            commit('setUserLoadStatus', { status: USER_LOAD_STATUS.COMPLETE });
            commit('setAuthenticated', { status: false });
            if (isLogin) {
                commit('setLoginError', { hasLoginError: true });
            }
        }
    },
    async hydrate ({ commit, dispatch, rootGetters }, { subscriptions, activeSubId }) {
        if (isEmpty(subscriptions)) {
            return;
        }

        if (activeSubId === '') {
            try {
                activeSubId = subscriptions[0].id;
                const permissions = await updateAdoptV2UserState(activeSubId);
                const { applicationAccessControl, subscriptionAccessControl, flags } = permissions;
                const userWithUpdatedPermissions = {
                    ...state.user,
                    activeSubId,
                    applicationAccessControl,
                    subscriptionAccessControl,
                    flags
                };
                commit('setUser', { user: userWithUpdatedPermissions });
            } catch (error) {
                commit('subscriptions/setUpdateError', { message: error.message }, { root: true });
            }
        }

        await dispatch('subscriptions/hydrate', { subscriptions }, { root: true });

        const isAdoptV2 = rootGetters['subscriptions/subscriptionByIdUsesV2Adopt'](activeSubId);

        if (isAdoptV2) {
            const accountList = rootGetters['subscriptions/getAccountList']({ subscriptionId: activeSubId });
            const { id: accountId } = first(accountList);
            await dispatch(
                'subscriptions/updateActive',
                { subscriptionId: parseInt(activeSubId), accountId },
                { root: true }
            );
        }

        const isEmptyStateDigitalAdoption = rootGetters['subscriptions/isEmptyStateDigitalAdoption'];
        if (!isEmptyStateDigitalAdoption) {
            await dispatch('apps/hydrate', { subscriptions, activeSubId }, { root: true });
        }
    },
    async validateNewUserRegistrationToken ({ commit }, { token }) {
        try {
            await _validateToken(token);
        } catch (error) {
            commit('setRegistrationTokenError', { hasRegistrationTokenError: true });
        }
    },
    async validateResetPasswordToken ({ commit }, { token }) {
        try {
            await _validateToken(token);
        } catch (error) {
            commit('setResetPasswordTokenError', { hasResetPasswordTokenError: true });
        }
    },
    validateInvitationToken ({ commit }, { invitationToken }) {
        commit('setInvitationToken', { invitationToken });
        commit('setInvalidInvitationKeyError', { hasInvalidInvitationKeyError: false });
        commit('setInvitationTokenData', { invitationTokenData: null });
        let invitationTokenData = parseJSONKey(invitationToken);
        if (!invitationTokenData) invitationTokenData = decodedJZB(invitationToken);
        if (!invitationTokenData) commit('setInvalidInvitationKeyError', { hasInvalidInvitationKeyError: true });
        else commit('setInvitationTokenData', { invitationTokenData });
    },
    async acceptInvitation ({ commit }, { invitationToken }) {
        commit('setInvalidInvitationError', { hasInvalidInvitationError: false });
        commit('setInvitationExpiredError', { hasInvitationExpiredError: false });
        commit('setUserNotFoundError', { hasUserNotFoundError: false });
        commit('setInvalidInvitationKeyError', { hasInvalidInvitationKeyError: false });
        commit('setAcceptingInvitationError', { hasAcceptingInvitationError: false });

        const errResponse = await _confirmInvitation(invitationToken).catch((err) => err.response);
        if (!errResponse) return;

        const { data, status } = errResponse;
        const statusCode = parseInt(status);
        const errorMsg = data.trim();

        if (errResponse.data.includes('user not found:')) {
            commit('setUserNotFoundError', { hasUserNotFoundError: true });
        } else if (statusCode === 400 && errors.INVALID === errorMsg) {
            commit('setInvalidInvitationError', { hasInvalidInvitationError: true });
        } else if (statusCode === 400 && errors.EXPIRED === errorMsg) {
            commit('setInvitationExpiredError', { hasInvitationExpiredError: true });
        } else if (statusCode === 422) commit('setInvalidInvitationKeyError', { hasInvalidInvitationKeyError: true });
        else commit('setAcceptingInvitationError', { hasAcceptingInvitationError: true });
    },
    async setPassword ({ commit }, { token, password }) {
        try {
            await _setPassword(token, password);
        } catch (error) {
            commit('setRegistrationPasswordError', { hasRegistrationPasswordError: true });
        }
    },
    async setAdoptPassword ({ commit }, { token, password }) {
        try {
            await _setAdoptPassword(token, password);
        } catch (error) {
            if (error.toString().includes('403') || error.toString().includes('409')) {
                commit('setRegistrationTokenError', { hasRegistrationTokenError: true });
            } else {
                commit('setRegistrationPasswordError', { hasRegistrationPasswordError: true });
            }
        }
    },
    async resetPassword ({ commit }, { token, password }) {
        try {
            await _setPassword(token, password);
        } catch (error) {
            commit('setResetPasswordError', { hasResetPasswordError: true });
        }
    },
    async sendResetPasswordLink ({ commit }, { email }) {
        try {
            await _sendResetPasswordLink(email);
        } catch (error) {
            commit('setSendResetPasswordLinkError', { sendResetPasswordLinkError: true });
        }
    },
    async sendResetPasswordLinkV2 ({ commit }, { email }) {
        try {
            await _sendResetPasswordLinkV2(email);
        } catch (error) {
            commit('setSendResetPasswordLinkError', { sendResetPasswordLinkError: true });
        }
    },
    async changePassword ({}, { oldPass, newPass, isAdoptV2Sub }) {
        await (isAdoptV2Sub ? _changeV2SubPassword(oldPass, newPass) : _changePassword(oldPass, newPass));
    },
    async updateEmail ({ commit, state }, { email }) {
        commit('setUpdateSettingsError', { hasUpdateSettingsError: false });
        try {
            const updatedUser = await _updateEmail({ id: state.user.id, email });
            commit('setUser', { user: updatedUser });
        } catch (error) {
            commit('setUpdateSettingsError', { hasUpdateSettingsError: true });
        }
    },
    async updateFirstName ({ commit, state, rootGetters, rootState }, { firstname }) {
        commit('setUpdateSettingsError', { hasUpdateSettingsError: false });
        try {
            const user = await _updateName(
                { id: state.user.id, firstname },
                rootGetters['subscriptions/activeUsesV2Adopt']
            );
            commit('setUser', { user });
            const listUser = Object.assign({}, rootState.users.map[user.id], { first: firstname });
            commit('users/setUpdate', { user: listUser }, { root: true });
        } catch (error) {
            commit('setUpdateSettingsError', { hasUpdateSettingsError: true });
        }
    },
    async updateLastName ({ commit, state, rootGetters, rootState }, { lastname }) {
        commit('setUpdateSettingsError', { hasUpdateSettingsError: false });
        try {
            const user = await _updateName(
                { id: state.user.id, lastname },
                rootGetters['subscriptions/activeUsesV2Adopt']
            );
            commit('setUser', { user });
            const listUser = Object.assign({}, rootState.users.map[user.id], { last: lastname });
            commit('users/setUpdate', { user: listUser }, { root: true });
        } catch (error) {
            commit('setUpdateSettingsError', { hasUpdateSettingsError: true });
        }
    },
    updateSegmentFlags ({ commit }, { flags }) {
        commit('setUserSegmentFlags', { flags });
        commit('setUserSegmentFlagsLoaded', { loaded: true });
    }
};

export const getters = {
    isAuthenticated (state) {
        return !isEmpty(state.user) && state.authenticated;
    },
    isLoading (state) {
        return state.userLoadStatus === USER_LOAD_STATUS.PENDING;
    },
    // Every route can be optionally protected by `meta.authRequired`. If it's a protected route, we need
    // to check if user is loaded before checking if they're authenticated. If the `/user/me` call is currently in-flight,
    // we set up a watcher on `isLoaded` before proceeding to check if user is authenticated or not.
    // In order to properly apply our watcher on the store for that piece of state, we return a method here instead of just the state.
    isLoaded (state) {
        return function () {
            return state.userLoadStatus === USER_LOAD_STATUS.COMPLETE;
        };
    },
    isAdmin (state, getters, rootState, rootGetters) {
        if (rootGetters['subscriptions/activeUsesV2Adopt']) {
            return isAdminByFlag(state.user);
        }
        const activeAccountId = get(rootState, 'subscriptions.activeAccountId', null);

        const subscriptionAccounts = get(rootGetters['subscriptions/active'], 'accounts');

        if (!subscriptionAccounts) return false;

        // We do this instead of get, as the active account id may contain periods or other object-like
        // syntax, which `get` will interpret incorrectly
        if (!subscriptionAccounts[activeAccountId]) return false;

        return subscriptionAccounts[activeAccountId].isAdmin;
    },
    // state.user needs to be updated to include v2 user objects for this to work
    isSuper (state) {
        return isSuperByRole(state.user);
    },
    // state.user needs to be updated to include v2 user objects for this to work
    isOps (state) {
        return isOpsByUserFlag(state.user);
    },
    isPendoUser (state) {
        return state.user.email.includes('@pendo.io');
    },
    hasSegmentFlag: (state) => (flag) => {
        return state.segmentFlags.includes(flag);
    },
    isReadOnly (state) {
        return isReadOnly(state.user);
    }
};

// helper functions
function _getUser (isAdoptV2 = false) {
    return http.get('/user/me', { headers: { 'x-adopt-v2': isAdoptV2 } }).then((res) => {
        const { headers } = res;
        if (!headers['content-type'].includes('application/json')) {
            throw new Error('/user/me failed to retrieve json');
        }

        if (isEmpty(res.data.subscriptions)) {
            throw new Error('/user/me did not have a subscription key');
        }

        return res.data;
    });
}

function _login (username, password) {
    return http.post('/login', { username, password }).then((res) => res.data);
}

function _updateEmail (settings) {
    return http.put('/api/user/_UID_/', settings).then((res) => res.data);
}

function _updateName (settings, isAdoptV2 = false) {
    if (!isAdoptV2) {
        return http.put('/api/user/_UID_/', settings).then((res) => res.data);
    }

    const params = {
        id: settings.id,
        ...(settings.firstname && { first: settings.firstname }),
        ...(settings.lastname && { last: settings.lastname })
    };

    return http.put('/api/s/_SID_/user/_UID_/', params).then(() => _getUser());
}

function _changePassword (oldPass, newPass) {
    return http.put('/api/user/_UID_/password', { oldPass, newPass }).then((res) => res.data);
}

function _changeV2SubPassword (oldPass, newPass) {
    return http.put('/api/s/_SID_/user/_UID_/password', { old: oldPass, new: newPass }).then((res) => res.data);
}

function _confirmInvitation (token) {
    return http.get(`/api/signup/invitation/confirm/${token}`);
}

// register endpoints
function _validateToken (token) {
    return http.get(`/api/register/validate?token=${token}`).then((res) => res.data);
}

function _setPassword (token, newPass) {
    return http.post('/api/register/setpassword', { token, newPass }).then((res) => res.data);
}

function _setAdoptPassword (token, password) {
    return http.post(`/api/signup/user/resetpassword/${token}`, { password }).then((res) => res.data);
}

function _sendResetPasswordLink (email) {
    return http.post('/api/register/forgotpassword', { email }).then((res) => res.data);
}

function _sendResetPasswordLinkV2 (email) {
    return http.post('/api/signup/user/pwresetrequest', { email }).then((res) => res.data);
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
