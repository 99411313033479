var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings--user-settings"},[_c('pendo-card',{attrs:{"title":"Information"}},[(!_vm._activeUsesV2Adopt)?_c('pendo-editable-content',{attrs:{"value":_vm.email,"validation-rules":_vm.rules.email,"before-exit":_vm.updateEmail,"label":"Email"},scopedSlots:_vm._u([{key:"append",fn:function(ref){
var confirm = ref.confirm;
var cancel = ref.cancel;
var pending = ref.pending;
return [_c('pendo-button',{attrs:{"loading":pending,"theme":"via","icon":"check","type":"primary"},on:{"click":confirm}}),_c('pendo-button',{attrs:{"theme":"via","type":"tertiary","label":"Cancel"},on:{"click":cancel}})]}}],null,false,1966757080)}):_vm._e(),(_vm._activeUsesV2Adopt)?_c('div',{staticClass:"pendo-editable-content has-label"},[_c('div',{staticClass:"pendo-editable-content__label pendo-editable-content__label--top"},[_vm._v(" Email ")]),_c('div',[_vm._v(" "+_vm._s(_vm.email)+" ")])]):_vm._e(),_c('pendo-editable-content',{attrs:{"value":_vm.firstname,"before-exit":_vm.updateFirstName,"label":"First Name"},scopedSlots:_vm._u([{key:"append",fn:function(ref){
var confirm = ref.confirm;
var cancel = ref.cancel;
var pending = ref.pending;
return [_c('pendo-button',{attrs:{"loading":pending,"theme":"via","icon":"check","type":"primary"},on:{"click":confirm}}),_c('pendo-button',{attrs:{"theme":"via","type":"tertiary","label":"Cancel"},on:{"click":cancel}})]}}])}),_c('pendo-editable-content',{attrs:{"value":_vm.lastname,"before-exit":_vm.updateLastName,"label":"Last Name"},scopedSlots:_vm._u([{key:"append",fn:function(ref){
var confirm = ref.confirm;
var cancel = ref.cancel;
var pending = ref.pending;
return [_c('pendo-button',{attrs:{"loading":pending,"theme":"via","icon":"check","type":"primary"},on:{"click":confirm}}),_c('pendo-button',{attrs:{"theme":"via","type":"tertiary","label":"Cancel"},on:{"click":cancel}})]}}])}),_c('div',{staticClass:"pendo-editable-content has-label"},[_c('div',{staticClass:"pendo-editable-content__label pendo-editable-content__label--top"},[_vm._v(" Password ")]),_c('div',{staticClass:"pendo-editable-content__text",on:{"click":_vm.openChangePasswordModal}},[_c('span',[_vm._v("Click to Change")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }