<template>
    <div>
        <pendo-card
            class="guide-content"
            body-min-height="332px">
            <template slot="header">
                <h4 class="localization-header-title">
                    Content
                </h4>
                <language-preview-dropdown
                    v-if="isLocalizationEnabled && guide.id"
                    :translation-states="guide.translationStates"
                    :authored-language="guide.authoredLanguage"
                    :is-loading="isLoadingGuidePreview"
                    :override-value="previewLanguage"
                    @languageSelected="handleLanguageSelection" />
                <div class="guide-content--actions">
                    <pendo-button
                        v-if="displayDownloadPDFButton"
                        type="link"
                        label="Download PDF"
                        prefix-icon="download"
                        :disabled="!(steps && steps.length)"
                        @click="downloadPDF" />
                    <div
                        v-if="displayDownloadPDFButton"
                        class="divider" />
                    <pendo-button
                        type="link"
                        label="Preview"
                        prefix-icon="play-circle"
                        :disabled="!canPreview"
                        @click="previewGuideStep(guide.steps[0])" />
                    <div class="divider" />
                    <pendo-button
                        type="link"
                        label="Manage in my app"
                        suffix-icon="chevron-right"
                        @click="editGuide" />
                </div>
            </template>
            <section
                v-if="guide.id"
                slot="body"
                class="guide-content--preview">
                <pendo-empty-state
                    v-if="!steps.length"
                    description="Looks like this Guide doesn’t have any steps saved">
                    <template #icon>
                        <tooltip-guides-alert-image />
                    </template>
                    <template #actions>
                        <pendo-button
                            theme="via"
                            type="secondary"
                            label="Manage in my app"
                            @click="editGuide" />
                    </template>
                </pendo-empty-state>
                <carousel
                    v-if="steps.length"
                    :class="{ 'navigation-enabled': navigationEnabled }"
                    :mouse-drag="false"
                    :navigate-to="focusedPageIndex"
                    :navigation-enabled="navigationEnabled"
                    :pagination-enabled="navigationEnabled"
                    :per-page="perPage"
                    :per-page-custom="perPageCustom"
                    :navigation-prev-label="navigationPrevArrow"
                    :navigation-next-label="navigationNextArrow">
                    <slide
                        v-for="(step, index) in steps"
                        :key="`step-preview-${step.id}`"
                        class="step-wrapper">
                        <aside class="slide-overlay step-overlay">
                            <pendo-button
                                type="primary"
                                theme="via"
                                label="Manage In App"
                                @click="editGuideStep(step)" />
                        </aside>
                        <guide-step-preview
                            ref="guideStepPreview"
                            :dom="step.dom"
                            :guides="[guide]"
                            :step="step"
                            :watermark-dom="watermarkDom"
                            :step-count="steps.length"
                            class="slide-content" />
                        <div
                            v-if="steps.length !== 1"
                            class="step-preview--label">
                            Step {{ index + 1 }}
                        </div>
                    </slide>
                </carousel>
            </section>
        </pendo-card>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { Carousel, Slide } from 'vue-carousel';
import { PendoButton, PendoCard, PendoLoading, PendoNotification, PendoEmptyState } from '@pendo/components';
import TooltipGuidesAlertImage from '@/components/svgs/TooltipGuidesAlertImage';
import GuideStepPreview from '@/components/guides/settings/GuideStepPreview';
import LanguagePreviewDropdown from './localization/LanguagePreviewDropdown';
import { getTranslatedGuideSteps, isTranslated } from '@/utils/localization.js';
import { getBuildingBlocks } from '@/utils/guides';
import { navigationPrevArrow, navigationNextArrow } from '@/constants/svgs';
import get from 'lodash/get';
import values from 'lodash/values';
import { GuideToPDF } from '@pendo/services/BuildingBlocks';
import { exportPDF } from '@/utils/pdfExport.js';

export default {
    name: 'GuideDetailsContentPreview',
    components: {
        Carousel,
        GuideStepPreview,
        PendoButton,
        PendoCard,
        PendoEmptyState,
        TooltipGuidesAlertImage,
        Slide,
        LanguagePreviewDropdown
    },
    directives: {
        PendoLoading
    },
    props: {
        isLocalizationEnabled: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            perPageCustom: [[1100, 3]],
            windowWidth: window.innerWidth,
            focusedPageIndex: 0,
            navigationPrevArrow,
            navigationNextArrow,
            isLoadingGuidePreview: false,
            previewLanguage: '',
            useTranslatedSteps: false,
            translatedSteps: null
        };
    },
    computed: {
        ...mapState({
            canPreviewPublicGuide: (state) => state.guides.canPreviewPublicGuide,
            canPreviewNonPublicGuide: (state) => state.guides.canPreviewNonPublicGuide
        }),
        ...mapGetters({
            watermark: 'guides/watermark',
            guide: 'guides/active',
            hasSegmentFlag: 'auth/hasSegmentFlag'
        }),
        canPreview () {
            if (!get(this.steps, 'length')) return false;

            return this.guide.state === 'public' ? this.canPreviewPublicGuide : this.canPreviewNonPublicGuide;
        },
        perPage () {
            const matchingPerPageTuple = this.perPageCustom.find((tuple) => this.windowWidth >= tuple[0]) || [
                -Infinity,
                1
            ];

            return matchingPerPageTuple[1];
        },
        navigationEnabled () {
            return this.guide.steps.length > this.perPage;
        },
        watermarkDom () {
            if (get(this, 'watermark.state') !== 'public') return '';

            return get(this, 'watermark.steps[0].dom');
        },
        hasTranslations () {
            return values(this.guide.translationStates).some(({ state }) => isTranslated(state));
        },
        steps () {
            return this.useTranslatedSteps ? this.translatedSteps : this.guide.steps;
        },
        displayDownloadPDFButton () {
            return this.hasSegmentFlag('guideToPDF');
        }
    },
    watch: {
        guide: {
            async handler () {
                if (this.guide.id) {
                    // await this to ensure `unwatch` is defined by the time we get there
                    await this.loadGuideContent();
                }
            },
            immediate: true
        }
    },
    created () {
        this.previewLanguage = this.guide.authoredLanguage;
    },
    methods: {
        ...mapActions({
            fetchGuideContent: 'guides/getBuildingBlocks'
        }),
        editGuideStep (step) {
            this.$modal.show('via-modal', {
                title: 'Edit Guide Step',
                component: 'LaunchDesignerModal',
                width: '465',
                componentProps: {
                    guideId: step.guideId,
                    stepId: step.id,
                    type: 'guide',
                    translationWarning: this.hasTranslations
                }
            });
        },
        previewGuideStep (step) {
            if (!this.canPreview) return;
            this.$modal.show('via-modal', {
                title: 'Preview Guide',
                component: 'LaunchPreviewModal',
                componentProps: {
                    guideId: step.guideId,
                    stepId: step.id
                }
            });
        },
        editGuide () {
            const [step] = this.guide.steps;

            this.$modal.show('via-modal', {
                title: 'Edit Guide',
                component: 'LaunchDesignerModal',
                width: '465',
                componentProps: {
                    guideId: this.guide.id,
                    stepId: get(step, 'id', null),
                    type: 'guide',
                    translationWarning: this.hasTranslations
                }
            });
        },
        loadGuideContent () {
            const promises = [];
            promises.push(this.fetchGuideContent({ guide: this.guide }));
            if (this.watermark) {
                promises.push(this.fetchGuideContent({ guide: this.watermark }));
            }

            return Promise.all(promises);
        },
        handleLanguageSelection (selectedLanguage, selectedLabel) {
            const existingPreviewLanguage = this.previewLanguage;

            if (this.previewLanguage === selectedLanguage) return;
            if (selectedLanguage === this.guide.authoredLanguage) {
                this.previewLanguage = this.guide.authoredLanguage;
                this.useTranslatedSteps = false;

                return;
            }

            this.isLoadingGuidePreview = true;
            this.previewLanguage = selectedLanguage;

            return getTranslatedGuideSteps(this.guide.id, selectedLanguage)
                .then((translatedSteps) => {
                    const translatedStepsWithContent = translatedSteps.map(async (step) => {
                        const stepContent = await getBuildingBlocks(step);

                        return Object.assign(step, stepContent);
                    });

                    return Promise.all(translatedStepsWithContent);
                })
                .then((translatedStepsWithContent) => {
                    this.translatedSteps = translatedStepsWithContent;
                    this.useTranslatedSteps = true;
                    this.isLoadingGuidePreview = false;
                })
                .catch(() => {
                    PendoNotification({
                        duration: 5000,
                        type: 'error',
                        title: 'Something went wrong',
                        message: `There was a problem retrieving translated content for ${selectedLabel}.`
                    });
                    this.previewLanguage = existingPreviewLanguage;
                    this.isLoadingGuidePreview = false;
                });
        },
        downloadPDF () {
            const preparedDOM = GuideToPDF.guideToPDF(this.$refs.guideStepPreview, get(this.guide, 'name'));
            exportPDF(preparedDOM, get(this.guide, 'name'));
        }
    }
};
</script>

<style lang="scss">
.localization-header-title {
    font-weight: 500;
}

.guide-content {
    &--preview {
        position: relative;
        @include carousel;

        .step {
            &-wrapper {
                padding: 15px 20px;
                height: 300px;
                width: 33.3%;
            }

            &-overlay {
                height: 275px;
            }

            &-preview {
                flex: 1;
                margin-bottom: 20px;
                height: 250px;
                min-height: 250px;
                max-height: 250px;
                display: flex;
                align-items: center;

                &--label {
                    font-size: 0.875rem;
                    color: $gray-primary;
                    align-self: center;
                }
            }
        }
    }

    &--actions {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .divider {
            height: 22px;
            width: 1px;
            margin-left: 10px;
            margin-right: 10px;
            background-color: $gray-lighter-5;
        }
    }
}
</style>
