<template>
    <pendo-drawer
        :visible="visible"
        :title="title"
        size="fullscreen"
        class-name="segment-builder"
        @open="initRules"
        @closed="resetLocalState"
        @close="close">
        <template #aside>
            <div class="settings-panel">
                <pendo-card body-min-height="0">
                    <div class="settings-panel--segment-name">
                        <pendo-input
                            v-model="name"
                            :invalid="attemptedSubmit && !name"
                            maxlength="48"
                            placeholder="Segment Name"
                            label="Segment Name *" />
                    </div>
                    <div class="settings-panel--divider" />
                    <div class="settings-panel--segment-audience">
                        <segment-visitor-count
                            :app-id="appId"
                            :pipeline="pipeline"
                            :show-csv-upload-warning="showCsvUploadWarning"
                            :name="name" />
                    </div>
                </pendo-card>
            </div>
        </template>
        <template #body>
            <div
                v-if="visible"
                class="rules-panel">
                <div
                    v-if="guideTargeting && usesLastVisitSchema"
                    class="rules-panel--illegal-rule-warning">
                    <pendo-alert type="error">
                        Segments containing the “Last Visit” rule cannot be used to target guides. Please remove this
                        rule before saving the segment.
                        <a
                            target="_blank"
                            href="https://adoptpartners.pendo.io/hc/en-us/articles/360046163511-Segments">Learn More</a>.
                    </pendo-alert>
                </div>
                <pendo-card
                    v-for="(rule, index) in rules"
                    :key="`segment-rule-${index}`"
                    :title="`Rule ${index + 1}`"
                    body-min-height="0"
                    class="segment-rule">
                    <template slot="headerRight">
                        <pendo-icon
                            v-if="rules.length > 1"
                            type="trash-2"
                            size="14"
                            class="segment-rule--remove"
                            @click="removeRule(index)" />
                    </template>
                    <div
                        v-for="(orRule, orIndex) in rule"
                        :key="orRule.id"
                        class="segment-rule--row">
                        <pendo-icon
                            v-if="rule.length > 1 && orIndex === 0"
                            type="x"
                            size="14"
                            class="segment-rule--remove-or segment-rule--first-rule"
                            @click="removeRule(index, orIndex)" />
                        <div
                            v-if="rule.length > 1 && orIndex !== 0"
                            class="segment-rule--row--or-seperator">
                            <span class="segment-rule--row--or-seperator--text">
                                OR
                            </span>
                            <pendo-icon
                                type="x"
                                size="14"
                                class="segment-rule--remove-or"
                                @click="removeRule(index, orIndex)" />
                        </div>
                        <div class="segment-rule--row--wrapper">
                            <!-- source picker -->
                            <div class="segment-rule--row--item">
                                <pendo-multiselect
                                    :value="orRule.schema"
                                    :allow-empty="false"
                                    :options="groupedSchemaList"
                                    full-width
                                    label-key="name"
                                    value-key="id"
                                    class="segment-rule--schema"
                                    @select="onSchemaSelect($event, index, orIndex)">
                                    <template #optionGroup="{ option }">
                                        <div
                                            v-if="option.id === 'product-usage-header'"
                                            class="group-header-label">
                                            {{ option.label }}
                                        </div>
                                        <div
                                            v-else
                                            class="group-header-label">
                                            {{ option.label }} ({{ option.options.length }})
                                        </div>
                                    </template>
                                    <template #option="{ option }">
                                        <pendo-icon-option :option="option" />
                                    </template>
                                    <template #selectedLabel="{ option }">
                                        <pendo-icon-option :option="option" />
                                    </template>
                                </pendo-multiselect>
                            </div>
                            <!-- application usage picker -->
                            <div v-if="showAppChooser(orRule)">
                                <pendo-multiselect
                                    v-model="orRule.app"
                                    :allow-empty="false"
                                    :options="getAppOptionsList(orRule)"
                                    :searchable="true"
                                    :preselect-first="getPreselectFirst(orRule)"
                                    label-key="displayName"
                                    value-key="id"
                                    full-width
                                    placeholder="Select Application"
                                    class="segment-rule--app-select"
                                    @select="onAppSelect($event, orRule)" />
                            </div>
                            <!--  resource center application usage picker -->
                            <div
                                v-if="showRCAppChooser(orRule)"
                                class="resource-center-rule--app-chooser">
                                <pendo-multiselect
                                    v-model="orRule.app"
                                    :allow-empty="false"
                                    :options="resourceCenterAppOptions"
                                    :searchable="true"
                                    label-key="displayName"
                                    value-key="id"
                                    full-width
                                    placeholder="Select Application"
                                    class="segment-rule--app-select"
                                    @select="onAppSelect($event, orRule)" />
                            </div>
                            <!-- guide list picker -->
                            <div
                                v-if="isGuideRule(orRule)"
                                :class="{ 'guide-undefined': orRule.guideDeleted }"
                                class="segment-rule--row--item">
                                <pendo-multiselect
                                    v-model="orRule.guide"
                                    :allow-empty="false"
                                    :options="getEntitiesByAppSelection(orRule.app, guideList)"
                                    :searchable="true"
                                    label-key="name"
                                    value-key="id"
                                    full-width
                                    placeholder="Select Guide"
                                    class="segment-rule--guide-select"
                                    @select="onGuideSelect(orRule)">
                                    <template #option="{ option }">
                                        <pendo-guide-status-option
                                            tooltip
                                            :option="option" />
                                    </template>
                                    <template #selectedLabel="{ option }">
                                        <pendo-guide-status-option
                                            tooltip
                                            :option="option" />
                                    </template>
                                </pendo-multiselect>
                                <span
                                    v-if="orRule.guideDeleted"
                                    class="broken-rule-warning">
                                    The original guide for this rule has been deleted
                                </span>
                            </div>
                            <!-- resource center module picker -->
                            <div
                                v-if="isResourceCenterRule(orRule)"
                                :class="{ 'guide-undefined': orRule.guideDeleted }"
                                class="segment-rule--row--item">
                                <pendo-multiselect
                                    v-model="orRule.guide"
                                    :allow-empty="false"
                                    :options="getModulesForResourceCenter(orRule.app)"
                                    :searchable="true"
                                    label-key="name"
                                    value-key="id"
                                    full-width
                                    :extra-label-width="60"
                                    placeholder="Select Module"
                                    class="segment-rule--rc-module-select"
                                    @select="onGuideSelect(orRule)" />
                                <span
                                    v-if="orRule.guideDeleted"
                                    class="broken-rule-warning">
                                    The original module for this rule has been deleted
                                </span>
                            </div>
                            <!-- page list picker -->
                            <div
                                v-if="orRule.schema.schema === 'page'"
                                :class="{ 'page-undefined': orRule.pageDeleted }"
                                class="segment-rule--row--item">
                                <pendo-multiselect
                                    v-model="orRule.page"
                                    :allow-empty="false"
                                    :options="getEntitiesByAppSelection(orRule.app, filteredPageList)"
                                    :searchable="true"
                                    label-key="displayName"
                                    value-key="id"
                                    full-width
                                    placeholder="Select Page"
                                    class="segment-rule--page-select"
                                    @select="onPageSelect(orRule)" />
                                <span
                                    v-if="orRule.pageDeleted"
                                    class="broken-rule-warning">
                                    The original page for this rule has been deleted
                                </span>
                                <span
                                    v-else-if="isPageOrphaned(orRule)"
                                    class="broken-rule-warning">
                                    The page used by this rule is no longer being shared.
                                </span>
                            </div>
                            <!-- feature list picker -->
                            <div
                                v-if="orRule.schema.schema === 'feature'"
                                :class="{ 'feature-undefined': orRule.featureDeleted }"
                                class="segment-rule--row--item">
                                <pendo-multiselect
                                    v-model="orRule.feature"
                                    :allow-empty="false"
                                    :options="getEntitiesByAppSelection(orRule.app, featureList)"
                                    :searchable="true"
                                    label-key="displayName"
                                    value-key="id"
                                    full-width
                                    placeholder="Select Feature"
                                    class="segment-rule--feature-select"
                                    @select="onFeatureSelect(orRule)" />
                                <span
                                    v-if="orRule.featureDeleted"
                                    class="broken-rule-warning">
                                    The original feature for this rule has been deleted
                                </span>
                            </div>
                            <!-- poll guide list picker - same as guide list picker except filtered to guides with polls -->
                            <div
                                v-if="orRule.schema.schema === 'poll'"
                                :class="{ 'guide-undefined': orRule.guideDeleted }"
                                class="segment-rule--row--item">
                                <pendo-multiselect
                                    v-model="orRule.guide"
                                    :allow-empty="false"
                                    :options="getEntitiesByAppSelection(orRule.app, guidesWithPolls)"
                                    :searchable="true"
                                    label-key="name"
                                    value-key="id"
                                    full-width
                                    placeholder="Select Poll"
                                    class="segment-rule--guide-select"
                                    @select="onGuideSelect(orRule)">
                                    <template #option="{ option }">
                                        <pendo-guide-status-option
                                            tooltip
                                            :option="option" />
                                    </template>
                                    <template #selectedLabel="{ option }">
                                        <pendo-guide-status-option
                                            tooltip
                                            :option="option" />
                                    </template>
                                </pendo-multiselect>
                                <span
                                    v-if="orRule.guideDeleted"
                                    class="broken-rule-warning">
                                    The original guide for this rule has been deleted
                                </span>
                            </div>
                            <!-- poll question picker - choose from all available polls on a selected guide -->
                            <div
                                v-if="orRule.schema.schema === 'poll' && orRule.guide"
                                class="segment-rule--row--item">
                                <pendo-multiselect
                                    v-model="orRule.poll"
                                    :allow-empty="false"
                                    :options="getPollsForGuide(orRule.guide)"
                                    :searchable="true"
                                    :preselect-first="true"
                                    placeholder="Select Question"
                                    label-key="question"
                                    value-key="id"
                                    full-width
                                    class="segment-rule--poll-select" />
                            </div>

                            <page-rule
                                v-if="isPageRule(orRule)"
                                :rule="orRule"
                                @input="onRuleUpdate($event, index, orIndex)" />
                            <feature-rule
                                v-else-if="isFeatureRule(orRule)"
                                :rule="orRule"
                                @input="onRuleUpdate($event, index, orIndex)" />
                            <metadata-rule
                                v-else-if="isMetadataRule(orRule)"
                                :rule="orRule"
                                @input="onRuleUpdate($event, index, orIndex)" />
                            <application-rule
                                v-else-if="isApplicationRule(orRule)"
                                :rule="orRule"
                                @input="onRuleUpdate($event, index, orIndex)" />
                            <!-- comparison type picker - choose how to filter your data - contains, equals, responded, etc -->
                            <template v-else>
                                <div
                                    v-if="shouldShowOperatorField(orRule)"
                                    class="segment-rule--row--item">
                                    <pendo-multiselect
                                        :value="orRule.operator"
                                        :allow-empty="false"
                                        :preselect-first="true"
                                        :options="operators[orRule.schema.schema] || []"
                                        :disabled="!operators[orRule.schema.schema]"
                                        min-trigger-width="100%"
                                        value-key="value"
                                        class="segment-rule--operator"
                                        @select="onOperatorSelect($event, index, orIndex)" />
                                </div>
                                <!-- value input for comparison from the above picker - time ranges, number inputs, string inputs, etc -->
                                <template v-if="useSimpleValueInputs(orRule)">
                                    <div
                                        v-if="!isUnitCountValue(orRule)"
                                        class="segment-rule--row--item">
                                        <component
                                            :is="getValueComponent(orRule)"
                                            v-model="orRule.value"
                                            v-bind="getValueComponentProps(orRule)" />
                                    </div>
                                    <div
                                        v-if="isUnitCountValue(orRule)"
                                        class="segment-rule--row--item complex-rule">
                                        <pendo-input-number
                                            :value="orRule.value[0]"
                                            :min="1"
                                            @change="onCountChange($event, index, orIndex)" />
                                        <pendo-multiselect
                                            :value="orRule.value[1]"
                                            :allow-empty="false"
                                            :preselect-first="true"
                                            :min-trigger-width="90"
                                            :min-menu-width="90"
                                            :options="['days', 'weeks', 'months']"
                                            @select="onGranularityChange($event, index, orIndex)" />
                                    </div>
                                </template>
                            </template>
                            <!-- purely cosmetic disabled "Ever" dropdown for certain rules -->
                            <div
                                v-if="shouldShowCosmeticEver(orRule)"
                                class="segment-rule--row--item">
                                <pendo-multiselect
                                    :disabled="true"
                                    :preselect-first="true"
                                    :options="['ever']"
                                    value="ever"
                                    min-trigger-width="100%" />
                            </div>
                        </div>
                    </div>
                    <pendo-button
                        theme="via"
                        type="link"
                        prefix-icon="plus-circle"
                        label="OR"
                        class="segment-builder--create-or-rule"
                        @click="addOrRule(index)" />
                </pendo-card>
                <div
                    v-if="rules.length"
                    class="segment-builder--and-separator">
                    <pendo-button
                        theme="via"
                        type="link"
                        prefix-icon="plus-circle"
                        label="AND"
                        class="segment-builder--create-and-rule"
                        @click="addRule" />
                </div>
            </div>
        </template>
        <template #footer>
            <footer class="segment-builder--footer">
                <pendo-button
                    theme="via"
                    type="secondary"
                    label="Close"
                    class="segment-builder--close"
                    @click="close" />
                <pendo-button
                    :disabled="invalidSegment"
                    :loading="saving"
                    theme="via"
                    type="primary"
                    label="Save Segment"
                    class="segment-builder--save"
                    @click="save" />
            </footer>
        </template>
    </pendo-drawer>
</template>

<script>
import uuid from 'uuid';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import { mapGetters } from 'vuex';
import {
    PendoAlert,
    PendoButton,
    PendoCard,
    PendoDatePicker,
    PendoDrawer,
    PendoIcon,
    PendoInput,
    PendoInputNumber,
    PendoMultiselect,
    PendoTooltip,
    PendoIconOption,
    PendoGuideStatusOption
} from '@pendo/components';
import moment from '@/utils/moment';
import { validateAndBuildSegment, getRulesFromSegment } from '@/utils/segments';
import { SEGMENT_OPERATORS } from '@/constants/segments';
import SegmentVisitorCount from '@/components/segments/SegmentVisitorCount';
import MetadataRule from '@/components/segments/builder/rule-types/MetadataRule';
import PageRule from '@/components/segments/builder/rule-types/PageRule';
import FeatureRule from '@/components/segments/builder/rule-types/FeatureRule';
import ApplicationRule from '@/components/segments/builder/rule-types/ApplicationRule';

export default {
    name: 'SegmentBuilder',
    components: {
        PendoAlert,
        PendoButton,
        PendoCard,
        PendoDatePicker,
        PendoDrawer,
        PendoGuideStatusOption,
        PendoIcon,
        PendoIconOption,
        PendoInput,
        PendoInputNumber,
        PendoMultiselect,
        SegmentVisitorCount,
        MetadataRule,
        PageRule,
        FeatureRule,
        ApplicationRule
    },
    directives: {
        PendoTooltip
    },
    props: {
        appId: {
            required: true,
            type: Number
        },
        appFirstVisit: {
            type: Number,
            default: 0
        },
        editingSegment: {
            type: Object,
            default: () => null
        },
        schemaList: {
            type: Array,
            required: true
        },
        guideList: {
            type: Array,
            default: () => []
        },
        rcModulesList: {
            type: Array,
            default: () => []
        },
        guideMap: {
            type: Object,
            default: () => ({})
        },
        draftResourceCenterMap: {
            type: Object,
            default: () => ({})
        },
        pageList: {
            type: Array,
            default: () => []
        },
        pageMap: {
            type: Object,
            default: () => ({})
        },
        featureList: {
            type: Array,
            default: () => []
        },
        featureMap: {
            type: Object,
            default: () => ({})
        },
        appList: {
            type: Array,
            default: () => []
        },
        appMap: {
            type: Object,
            default: () => ({})
        },
        visible: {
            type: Boolean,
            default: false
        },
        saving: {
            type: Boolean,
            default: false
        },
        guideTargeting: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            operators: SEGMENT_OPERATORS,
            name: '',
            rules: [],
            attemptedSubmit: false,
            showCsvUploadWarning: false
        };
    },
    computed: {
        ...mapGetters({
            hasSegmentFlag: 'auth/hasSegmentFlag',
            usesMultiApp: 'subscriptions/usesMultiApp',
            activeHasResourceCenter: 'subscriptions/activeHasResourceCenter'
        }),
        pipelineJson () {
            return JSON.stringify(this.pipeline, null, 2);
        },
        isEditing () {
            return this.editingSegment !== null;
        },
        title () {
            return this.editingSegment ? 'Edit Segment' : 'Custom Segment';
        },
        builtSegment () {
            const segmentInfo = {
                rules: this.rules,
                name: this.name
            };

            if (!this.usesMultiApp) {
                segmentInfo.appId = this.appId;
            }

            const segment = validateAndBuildSegment(segmentInfo);

            return segment;
        },
        pipeline () {
            if (!this.builtSegment) return null;

            return this.builtSegment.pipeline;
        },
        invalidSegment () {
            return !this.pipeline || (this.guideTargeting && this.usesLastVisitSchema) || !this.builtSegment;
        },
        guidesWithPolls () {
            return this.guideList.filter((guide) => this.getPollsForGuide(guide).length);
        },
        filteredSchemaList () {
            return this.schemaList
                .filter((schema) => {
                    const isPendoGroup = schema.group === 'pendo';
                    const excludePendoGroupMetadataList = [
                        'visitor_pendo_designerenabled',
                        'visitor_pendo_donotprocess'
                    ];
                    const excludeAutoMetadataList = [
                        'visitor_auto_lastoperatingsystem',
                        'visitor_auto_lastbrowsername'
                    ];

                    if (!this.usesMultiApp && schema.id === 'application') {
                        return false;
                    }

                    if (schema.id === 'resourceCenter' && !this.activeHasResourceCenter) {
                        return false;
                    }

                    if (schema.schema === 'poll' && this.guidesWithPolls.length <= 0) {
                        return false;
                    }

                    if (!this.hasViewAutoMetadataEnabled && excludeAutoMetadataList.includes(schema.id)) {
                        return false;
                    }

                    if (isPendoGroup && excludePendoGroupMetadataList.includes(schema.id)) {
                        return false;
                    }

                    return true;
                })
                .map((option) => {
                    return {
                        ...option,
                        icon: {
                            type: this.getSchemaIcon(option.schema)
                        }
                    };
                });
        },
        groupedSchemaList () {
            const productUsage = ['guide', 'resourceCenter', 'page', 'feature', 'poll'];

            if (this.usesMultiApp) {
                productUsage.push('application');
            }

            if (Array.isArray(this.filteredSchemaList)) {
                const productUsageFields = this.filteredSchemaList.filter((schema) => {
                    return productUsage.includes(schema.id);
                });
                const metaFields = this.filteredSchemaList.filter((schema) => {
                    return !productUsage.includes(schema.id);
                });

                return [
                    {
                        id: 'product-usage-header',
                        label: 'Product Usage',
                        options: productUsageFields
                    },
                    {
                        id: 'visitor-data-header',
                        label: 'Visitor Data',
                        options: metaFields
                    }
                ];
            }

            return [];
        },
        appOptionsForAppUsage () {
            return this.appList.map((app) => {
                return {
                    id: app.id,
                    displayName: app.displayName,
                    resourceCenterId: get(this.draftResourceCenterMap, `${app.id}.homeView.id`)
                };
            });
        },
        appOptions () {
            const defaultOption = [
                {
                    displayName: 'All Applications',
                    id: null,
                    resourceCenterId: null
                }
            ];

            const appOptions = this.appList.map((app) => {
                return {
                    id: app.id,
                    displayName: app.displayName,
                    resourceCenterId: get(this.draftResourceCenterMap, `${app.id}.homeView.id`)
                };
            });

            return defaultOption.concat(appOptions);
        },
        resourceCenterAppOptions () {
            return this.appOptions.filter((app) => !!app.resourceCenterId);
        },
        filteredPageList () {
            if (this.hasSegmentFlag('adoptCustomPageSegments')) return this.pageList;

            return this.pageList.filter((page) => !page.isChildPage);
        },
        hasViewAutoMetadataEnabled () {
            return this.hasSegmentFlag('viewAutoMetadata');
        },
        yesterday () {
            return moment(Date.now())
                .subtract(1, 'day')
                .toDate();
        },
        usesLastVisitSchema () {
            return this.rules.some((orRules) =>
                orRules.some((orRule) => {
                    return orRule.schema.type === 'metadata.auto.lastvisit';
                })
            );
        }
    },
    methods: {
        getPreselectFirst (orRule) {
            return !this.isApplicationRule(orRule);
        },
        getAppOptionsList (orRule) {
            if (this.isApplicationRule(orRule)) {
                return this.appOptionsForAppUsage;
            }

            return this.appOptions;
        },
        isPageRule (orRule) {
            return this.getRuleType(orRule) === 'page-rule';
        },
        isFeatureRule (orRule) {
            return this.getRuleType(orRule) === 'feature-rule';
        },
        isMetadataRule (orRule) {
            return this.getRuleType(orRule) === 'metadata-rule';
        },
        isGuideRule (orRule) {
            return this.getRuleType(orRule) === 'guide-rule';
        },
        isResourceCenterRule (orRule) {
            return this.getRuleType(orRule) === 'resource-center-rule';
        },
        isApplicationRule (orRule) {
            return this.getRuleType(orRule) === 'application-rule';
        },
        showAppChooser (orRule) {
            if (!this.usesMultiApp) return false;
            if (this.isResourceCenterRule(orRule)) return false;

            const schemasWithAppSelections = ['guide', 'page', 'feature', 'poll', 'application'];
            const orRuleSchema = get(orRule, 'schema.schema', '');

            return schemasWithAppSelections.includes(orRuleSchema);
        },
        showRCAppChooser (orRule) {
            if (!this.usesMultiApp) return false;

            return this.isResourceCenterRule(orRule);
        },
        getEntitiesByAppSelection (app, entityList) {
            if (!entityList) return [];

            const appId = this.usesMultiApp ? get(app, 'id', null) : this.appId;

            if (appId === null) return entityList;

            return entityList.filter((entity) => entity.appId === appId);
        },
        onRuleUpdate ($event, ruleIndex, orIndex) {
            const { field, value } = $event;
            switch (field) {
                case 'operator':
                    this.onOperatorSelect(value, ruleIndex, orIndex);
                    break;
                case 'count':
                    this.onCountChange(value, ruleIndex, orIndex);
                    break;
                case 'granularity':
                    this.onGranularityChange(value, ruleIndex, orIndex);
                    break;
                case 'value':
                    this.onValueChange(value, ruleIndex, orIndex);
                    break;
                case 'start':
                case 'end':
                    this.$set(this.rules[ruleIndex][orIndex].value, field, value);
                    break;
                case 'tag':
                    this.showCsvUploadWarning = !!value.tagId;
                    this.$set(this.rules[ruleIndex][orIndex], 'tagId', value.tagId);
                    this.$set(this.rules[ruleIndex][orIndex], 'value', value.filename);
                    break;
                case 'time':
                    this.$set(this.rules[ruleIndex][orIndex], 'time', value);
                    break;
                default:
                    break;
            }
        },
        getRuleType (orRule) {
            // enable these types as we separate them out
            const { schema, id } = orRule.schema;
            switch (schema) {
                case 'guide':
                    if (id === 'resourceCenter') return 'resource-center-rule';

                    return 'guide-rule';
                case 'poll':
                case 'aggregation':
                    return null;
                case 'page':
                    return 'page-rule';
                case 'feature':
                    return 'feature-rule';
                case 'application':
                    return 'application-rule';
                default:
                    return 'metadata-rule';
            }
        },
        initRules () {
            if (this.editingSegment) {
                this.name = this.editingSegment.name;
                this.rules = getRulesFromSegment(this.editingSegment).map(this.mapIncomingGuideRules);
            } else {
                this.rules = [[this.generateRule()]];
            }
        },
        mapIncomingGuideRules (rules) {
            return rules.map((rule) => {
                if (rule.guideId) {
                    rule.guide = this.guideMap[rule.guideId];
                    rule.guideDeleted = !rule.guide;
                    delete rule.guideId;
                }

                if (rule.pollId) {
                    rule.poll = this.getPollsForGuide(rule.guide).find((poll) => poll.id === rule.pollId);
                    delete rule.pollId;
                }

                if (rule.pageId) {
                    rule.page = this.pageMap[rule.pageId];
                    rule.pageDeleted = !rule.page;
                    rule.isPageShared = get(rule.page, 'trainingSettings.enabled', false);
                    rule.isCustomPage = get(rule.page, 'rootVersionId', null);
                    delete rule.pageId;
                }

                if (rule.featureId) {
                    rule.feature = this.featureMap[rule.featureId];
                    rule.featureDeleted = !rule.feature;
                    delete rule.featureId;
                }

                // Careful, `selectedApp: { id: null }` is VALID as "all applications"
                // So check for the existence of "selectedApp" instead
                if (get(rule, 'schema.selectedApp')) {
                    const app = this.appOptions.find((opt) => opt.id === rule.schema.selectedApp.id);
                    rule.app = app;
                    rule.appDeleted = !rule.app;
                    delete rule.schema.selectedApp;
                }

                return rule;
            });
        },

        getPollsForGuide (guide) {
            if (!guide.polls || !guide.polls.length) return [];

            const polls = keyBy(guide.polls, 'id');

            return flatMap(guide.steps, (step) => step.pollIds)
                .map((id) => polls[id])
                .filter((poll) => poll);
        },
        getModulesForResourceCenter (app) {
            const appId = get(app, 'id');
            const homeViewId = get(this.draftResourceCenterMap, `${appId}.homeView.id`);

            return this.rcModulesList
                .filter((guide) => {
                    const resourceCenterId =
                        get(guide, 'attributes.resourceCenter.homeViewId') ||
                        get(guide, 'attributes.resourceCenter.originId');

                    return resourceCenterId === homeViewId;
                })
                .map((guide) => {
                    const isHomeView = get(guide, 'attributes.resourceCenter.isTopLevel');

                    return Object.assign(guide, {
                        guideId: guide.id, // can't be named moduleId because guidesSeenEver source only accepts 'guideId'
                        name: isHomeView ? 'Resource Center Home' : guide.name
                    });
                });
        },
        generateRule (schema = this.filteredSchemaList[0]) {
            return {
                schema,
                id: uuid(),
                operator: this.getDefaultOperatorForSchema(schema),
                value: this.getDefaultValueForSchema(schema)
            };
        },
        getDefaultOperatorForSchema ({ schema }) {
            const schemaOperators = this.operators[schema];

            return get(schemaOperators, '[0]', null);
        },
        getDefaultValueForSchema ({ schema }) {
            let defaultValue;

            switch (schema) {
                case 'integer':
                case 'float':
                    defaultValue = 0;
                    break;
                default:
                    defaultValue = null;
            }

            return defaultValue;
        },
        addRule () {
            this.rules.push([this.generateRule()]);
        },
        addOrRule (index) {
            this.rules[index].push(this.generateRule());
        },
        removeRule (index, orIndex) {
            if (orIndex == null) {
                this.rules.splice(index, 1);
            } else {
                this.rules[index].splice(orIndex, 1);
            }
        },
        onSchemaSelect (schema, ruleIndex, orIndex) {
            // if schema isn't the same, build a new rule with correct defaults
            if (schema.id !== this.rules[ruleIndex][orIndex].schema.id) {
                this.$set(this.rules[ruleIndex], orIndex, this.generateRule(schema));
            } else {
                this.$set(this.rules[ruleIndex][orIndex], 'schema', schema);
            }
        },
        onOperatorSelect (operator, ruleIndex, orIndex) {
            const { value, operator: previousOperator, schema } = this.rules[ruleIndex][orIndex];
            this.$set(this.rules[ruleIndex][orIndex], 'operator', operator);

            if (operator.multivalue) {
                const value = operator.value === 'between' ? { start: null, end: null } : [30, 'days'];
                this.$set(this.rules[ruleIndex][orIndex], 'value', value);

                return;
            }

            if (!operator.multivalue && previousOperator.multivalue) {
                this.$set(this.rules[ruleIndex][orIndex], 'value', null);

                return;
            }

            if (schema.schema === 'boolean') {
                this.$set(this.rules[ruleIndex][orIndex], 'value', true);

                return;
            }

            this.$set(this.rules[ruleIndex][orIndex], 'value', value || this.getDefaultValueForSchema(schema));
        },
        onAppSelect (selectedApp, rule) {
            const existingAppId = get(rule, 'app.id');
            if (selectedApp.id === existingAppId) return;

            const baseProperties = ['app', 'id', 'appDeleted', 'schema'];

            Object.keys(rule).forEach((prop) => {
                if (baseProperties.includes(prop)) return;

                this.$delete(rule, prop);
            });

            this.resetOperatorsForRule(rule);

            this.$set(rule, 'app', selectedApp);
            // Technically this probably isn't needed, but we'll do it for uniformity
            this.$set(rule, 'appDeleted', false);
        },
        onGuideSelect (rule) {
            this.$set(rule, 'guideDeleted', false);
        },
        onPageSelect (rule) {
            this.$set(rule, 'isPageShared', true);
            this.$set(rule, 'pageDeleted', false);
        },
        onFeatureSelect (rule) {
            this.$set(rule, 'featureDeleted', false);
        },
        resetOperatorsForRule (rule) {
            const validOperators = this.operators[rule.schema.schema] || [];

            this.$set(rule, 'operator', validOperators[0]);
        },
        useSimpleValueInputs (rule) {
            return rule.schema.schema !== 'guide' && this.shouldShowValueField(rule);
        },
        isDateRangeValue ({ operator, value }) {
            return value && operator && (operator.value === 'between' || operator.value === '!between');
        },
        isUnitCountValue ({ operator, value }) {
            return value && operator && (operator.value === 'withinLast' || operator.value === '!withinLast');
        },
        shouldShowValueField (orRule) {
            if (orRule.schema.schema === 'poll') {
                return orRule.poll && !/^(not)?responded$/.test(orRule.operator && orRule.operator.value);
            }

            return true;
        },
        shouldShowOperatorField (orRule) {
            if (orRule.schema.schema === 'poll' && (!orRule.guide || !orRule.poll)) {
                return false;
            }

            return true;
        },
        shouldShowCosmeticEver (orRule) {
            const { schema } = orRule.schema;
            const operatorValue = get(orRule, 'operator.value');
            const respondOperator = operatorValue === 'responded' || operatorValue === 'notresponded';

            if (schema === 'poll' && orRule.poll && respondOperator) {
                return true;
            }

            const operatorValueSupportsOnlyEver = ['viewed', 'notviewed'].includes(operatorValue);
            if (schema === 'guide' && operatorValueSupportsOnlyEver) return true;

            return false;
        },
        getValueComponent (orRule) {
            if (orRule.schema.schema === 'poll') {
                return {
                    PickList: 'PendoMultiselect',
                    FreeForm: 'PendoInput',
                    NumberScale: 'PendoMultiselect',
                    PositiveNegative: 'PendoMultiselect'
                }[orRule.poll.attributes.type];
            }

            return {
                string: 'PendoInput',
                list: 'PendoInput',
                integer: 'PendoInputNumber',
                float: 'PendoInputNumber',
                time: 'PendoDatePicker'
            }[orRule.schema.schema];
        },
        getValueComponentProps (orRule) {
            const {
                operator,
                schema: { schema }
            } = orRule;

            const types = ['PickList', 'NumberScale', 'PositiveNegative'];

            if (schema === 'poll' && types.includes(orRule.poll.attributes.type)) {
                return {
                    class: 'segment-rule--value',
                    allowEmpty: false,
                    fullWidth: true,
                    options: map(orRule.poll.idResponses, (label, id) => id),
                    formatOptionLabel (id) {
                        return orRule.poll.idResponses[id];
                    }
                };
            }

            const props = {
                time: {
                    shortcuts: false,
                    class: 'segment-rule--value--time',
                    type: this.isDateRangeValue(orRule) ? 'range' : 'single',
                    minDate: this.appFirstVisit,
                    maxDate: this.getMaxDate(operator)
                },
                float: {
                    precision: 2
                }
            }[schema];

            return {
                class: 'segment-rule--value',
                disabled: !this.operators[schema],
                ...props
            };
        },
        save () {
            this.attemptedSubmit = true;
            if (!this.name) {
                return;
            }

            const segment = Object.assign({}, this.builtSegment);
            if (this.editingSegment) {
                segment.id = this.editingSegment.id;
                // when we build a segment we only create certain properties
                const initialSegment = cloneDeep(
                    pick(this.editingSegment, ['name', 'id', 'definition', 'pipeline', 'shared'])
                );
                const noChanges = isEqual(initialSegment, segment);

                if (noChanges) {
                    this.close();

                    return;
                }
            }

            this.$emit('save', { segment });
        },
        resetLocalState () {
            this.name = '';
            this.rules = [];
            this.showCsvUploadWarning = false;
        },
        close () {
            this.$emit('close');
        },
        getMaxDate (operator) {
            if (!operator || !operator.label) {
                return null;
            }

            if (operator.label === 'since') {
                return this.yesterday;
            }

            return null;
        },
        onCountChange (count, ruleIndex, orIndex) {
            const rule = this.rules[ruleIndex][orIndex];
            this.$set(rule.value, 0, count);
        },
        onGranularityChange (granularity, ruleIndex, orIndex) {
            const rule = this.rules[ruleIndex][orIndex];
            this.$set(rule.value, 1, granularity);
        },
        onValueChange (value, ruleIndex, orIndex) {
            const rule = this.rules[ruleIndex][orIndex];
            this.$set(rule, 'value', value);
        },
        getSchemaIcon (schema) {
            const icon = {
                poll: 'list',
                time: 'calendar',
                guide: 'map',
                page: 'file',
                feature: 'feature',
                application: 'activity'
            }[schema];

            return icon || 'users';
        },
        isPageOrphaned (orRule) {
            return orRule.page && !orRule.isPageShared && !orRule.isCustomPage;
        }
    }
};
</script>

<style lang="scss">
.pendo-drawer--fullscreen .pendo-drawer__aside {
    overflow: auto;
}

.segment-builder {
    .guide-undefined,
    .page-undefined,
    .feature-undefined {
        .pendo-multiselect__trigger {
            border: 1px solid $red-error;
        }
    }

    .pendo-multiselect__placeholder {
        color: $gray-lighter-2;
    }

    .guide-ineligible-rule,
    .broken-rule-warning {
        display: inline-block;
        font-size: 0.75rem;
        line-height: 0.875rem;
        margin-top: 0.5em;
    }

    .broken-rule-warning {
        color: $red-error;
    }

    .guide-ineligible-rule {
        color: $gray-lighter-2;
    }

    .settings-panel {
        padding-left: 32px;

        &--segment-name {
            margin-bottom: 1em;
        }

        &--divider {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            margin-left: -1rem;
            margin-right: -1rem;
            border-top: 1px solid $gray-lighter-5;
        }

        &--pipeline {
            overflow: scroll;
        }
    }

    .rules-panel {
        overflow-y: auto;
        padding-right: 32px;
        max-width: 1140px;

        &--illegal-rule-warning {
            margin-bottom: 2em;
        }
    }

    &--footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .segment-rule {
        margin-bottom: 1em;

        &--remove,
        &--remove-or {
            & svg {
                stroke: $gray-lighter-2;
            }

            &:hover {
                cursor: pointer;
            }
        }

        &--first-rule {
            float: right;
        }

        &--selected-label {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;

            .pendo-icon {
                margin-right: 0.5em;
            }
        }

        &--row {
            &--wrapper {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
                grid-gap: 16px;
                margin-bottom: 1.5em;
            }

            &--or-seperator {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1.5em;
                border-top: 1px solid $gray-lighter-5;
                padding-top: 1.5em;

                &--text {
                    color: $gray-lighter-2;
                    font-weight: bold;
                }
            }

            &:first-of-type {
                .segment-rule--row--or-seperator {
                    border-top: none;
                    padding-top: 0;
                }
            }

            &--item {
                .segment-rule--value--time,
                .segment-rule--schema,
                .segment-rule--operator {
                    width: 100%;

                    > div > span {
                        display: block !important; /* stylelint-disable-line */
                    }
                }

                &.complex-rule {
                    display: grid;
                    grid-template-columns: auto auto;
                    grid-gap: 16px;
                }

                &.between-rule {
                    flex-basis: 17.5%;
                }
            }
        }

        .pendo-input-number .el-input-number {
            max-width: unset;
            grid-template-columns: 1fr max-content;
        }
    }

    &--and-separator {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        // full width button for the lines around "+ AND"
        button {
            width: 100%;
            overflow: hidden;
        }

        button:before,
        button:after {
            background-color: $gray-lighter-5;
            content: '';
            display: inline-block;
            height: 1px;
            position: relative;
            vertical-align: middle;
            width: 50%;
        }

        button:before {
            right: 2em;
            margin-left: -50%;
        }

        button:after {
            left: 2em;
            margin-right: -50%;
        }
    }

    &--close svg {
        stroke: $gray-lighter-2;
    }
}

.metadata-rule,
.page-rule,
.application-rule,
.feature-rule {
    display: contents;
}
</style>
