<template>
    <div class="login-form">
        <h2 class="login--header">
            Log in to Your Account
        </h2>
        <pendo-form
            :call-validate="callValidate"
            :form-defaults="model"
            form-ref="viaLoginForm"
            @formValidated="handleValidForm"
            @invalidForm="handleInvalidForm">
            <pendo-form-item
                :rules="rules.email"
                class="pendo-form-item--email"
                prop="email"
                label="Email">
                <pendo-input
                    v-model.trim="model.email"
                    :invalid="!!hasLoginError"
                    class="input--email"
                    type="text"
                    placeholder="Email"
                    @input="enableSubmitButton" />
                <div
                    v-if="hasLoginError"
                    class="el-form-item__error">
                    There was an error logging in. Please try again.
                </div>
            </pendo-form-item>
            <pendo-form-item
                :rules="rules.password"
                class="login-form--password-input"
                prop="password"
                label="Password">
                <pendo-input
                    v-model.trim="model.password"
                    :type="passwordInputType"
                    :invalid="!!hasLoginError"
                    class="input--password"
                    placeholder="Password"
                    @input="enableSubmitButton"
                    @keyup.enter.native="validateForm">
                    <a
                        slot="suffix"
                        class="password-visibility-toggle"
                        @click="toggleShowPassword">
                        <pendo-icon
                            v-if="!showPassword"
                            type="eye-off"
                            stroke="#dadce5"
                            size="18" />
                        <pendo-icon
                            v-if="showPassword"
                            stroke="#2a2c35"
                            type="eye"
                            size="18" />
                    </a>
                </pendo-input>
            </pendo-form-item>
            <pendo-button
                class="login-form--forgot-password"
                theme="via"
                type="link"
                size="mini"
                label="Forgot Password?"
                data-cy="login-form--forgot-password"
                @click="navigateToForgotPassword" />
        </pendo-form>
        <div class="content-footer">
            <pendo-button
                :loading="isLoggingIn"
                :disabled="!!hasLoginError"
                type="primary"
                theme="via"
                block
                label="Log In"
                data-cy="login-form--login-action"
                @click="validateForm" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { PendoButton, PendoInput, PendoIcon, PendoFormItem, PendoForm } from '@pendo/components';

export default {
    name: 'LoginForm',
    components: {
        PendoButton,
        PendoInput,
        PendoIcon,
        PendoFormItem,
        PendoForm
    },
    data () {
        return {
            rules: {
                email: [
                    { required: true, message: 'Email address is required', trigger: ['blur', 'change'] },
                    { type: 'email', message: 'Please input a valid email address', trigger: ['blur'] }
                ],
                password: [{ required: true, message: 'Password is required', trigger: ['change', 'blur'] }]
            },
            model: {
                email: '',
                password: ''
            },
            showPassword: false,
            callValidate: false,
            isLoggingIn: false
        };
    },
    computed: {
        ...mapState({
            hasLoginError: (state) => state.auth.hasLoginError,
            route: (state) => state.route
        }),
        ...mapGetters({
            subscriptions: 'subscriptions/listAll',
            isEmptyStateDigitalAdoption: 'subscriptions/isEmptyStateDigitalAdoption'
        }),
        passwordInputType () {
            return this.showPassword ? 'text' : 'password';
        }
    },
    methods: {
        ...mapActions({
            _login: 'auth/login'
        }),
        ...mapMutations({
            setLoginError: 'auth/setLoginError'
        }),
        async login ({ email, password }) {
            this.isLoggingIn = true;
            await this._login({ email, password });
            this.isLoggingIn = false;

            if (this.hasLoginError) {
                return;
            }

            if (!this.subscriptions.length) {
                this.$router.push({ name: 'inactive' });

                return;
            }

            if (this.isEmptyStateDigitalAdoption) {
                this.$router.push({ name: 'subscriptionSettings' });

                return;
            }

            this.$router.push(this.route.query.after || { name: 'root' });
        },
        enableSubmitButton () {
            if (!this.hasLoginError) {
                return;
            }
            this.setLoginError({ hasLoginError: false });
        },
        navigateToForgotPassword () {
            this.setLoginError({ hasLoginError: false });
            this.$router.push({
                name: 'forgotPassword',
                query: {
                    ...this.route.query,
                    ...(this.model.email.length && { email: this.model.email })
                }
            });
        },
        toggleShowPassword () {
            this.showPassword = !this.showPassword;
        },
        validateForm () {
            this.callValidate = true;
        },
        handleValidForm () {
            this.callValidate = false;

            this.login(this.model);
        },
        handleInvalidForm () {
            this.callValidate = false;
        }
    }
};
</script>

<style lang="scss">
.login-form {
    padding: 0 1.25rem 1.5rem;

    .login--header {
        margin: 0.25rem 0 1rem;
    }

    .login-form--password-input {
        margin-bottom: 0.25rem;
    }

    .login-form--forgot-password {
        float: right;
        margin-bottom: 1rem;
    }
}
</style>
