<template>
    <pendo-card title="Activation">
        <template slot="headerRight">
            <pendo-button
                v-if="!isEditing && isDraft"
                type="link"
                theme="via"
                class="manage-in-app--button"
                label="Edit"
                prefix-icon="edit-2"
                :disabled="isUpdating"
                @click="handleEditing" />
            <pendo-button
                v-if="isEditing"
                theme="via"
                type="tertiary"
                label="Cancel"
                :disabled="isUpdating"
                @click="resetActiveItems" />
            <pendo-button
                v-if="isEditing"
                theme="via"
                type="primary"
                size="mini"
                label="Save"
                :disabled="isUpdating || isDisabled"
                @click="updateActivation" />
        </template>
        <template slot="body">
            <div
                v-if="!isEditing"
                class="launch-method">
                <div
                    :class="{
                        'launch-method--item': true,
                        'with-alert': hasMultipleLaunchMethods || hasCorruptedBadge || hasInvalidMethod
                    }">
                    <pendo-alert
                        v-if="hasMultipleLaunchMethods"
                        type="info"
                        title="It looks like you've selected multiple Launch Methods, which means you can't select another. Only one Activation Setting can be selected." />
                    <pendo-alert
                        v-if="!hasMultipleLaunchMethods && hasInvalidMethod"
                        type="info"
                        title="It looks like you've selected an unavailable Launch Method" />
                    <img
                        v-if="!hasCorruptedBadge && isLauncherType"
                        class="chrome-extension-preview-img"
                        src="@/img/chrome-extension-preview.svg">
                    <img
                        v-if="!hasCorruptedBadge && isBadgeType"
                        :class="['badge-icon-preview-img', `badge-${badgeName}`]"
                        :src="badgeImage">
                    <pendo-alert
                        v-if="hasCorruptedBadge"
                        type="error"
                        title="Your activation widget is corrupt, but your Resource Center is safe. To fix this, edit and resave the activation widget to get it working again." />
                </div>
            </div>
            <div v-if="isEditing">
                <div
                    v-if="isDigitalAdoption"
                    class="activation-text">
                    <div class="activation-text--label">
                        Launch Method
                    </div>
                    <pendo-multiselect
                        class="launcher-selection-dropdown"
                        :value="launcherLabel"
                        label-key="label"
                        :allow-empty="false"
                        :options="availableLauncherOptions"
                        @select="selectLauncher" />
                </div>
                <div
                    v-if="!isLauncherType"
                    class="badge-settings">
                    <div class="badge-explanation">
                        Resource Center appears {{ LAUNCH_METHODS.badge.text }}.
                    </div>
                    <div class="badge-icon">
                        <div class="badge-icon--label">
                            Badge Icon
                        </div>
                        <pendo-multiselect
                            :allow-empty="false"
                            :options="icons"
                            @select="(icon) => handleChange(BADGE_OPTIONS.ICON, icon)">
                            <template #placeholder>
                                <div class="multiselect-option">
                                    <img :src="badgeImage">
                                    <span>
                                        {{ badgeName }}
                                    </span>
                                </div>
                            </template>
                            <template
                                slot="option"
                                slot-scope="{ option }">
                                <div class="multiselect-option">
                                    <img :src="option.src">
                                    <span>{{ option.name }}</span>
                                </div>
                            </template>
                        </pendo-multiselect>
                    </div>
                    <div class="badge-color">
                        <div class="badge-color--label">
                            Badge Color
                        </div>
                        <pendo-color-picker
                            :initial-color="badgeColor"
                            :class="{ 'label-color-picker': true, updating: isColorPickerHidden || isUpdating }"
                            value-key="hex"
                            disable-presets
                            disable-alpha
                            disable-fields
                            @change="(color) => handleChange(BADGE_OPTIONS.COLOR, color)"
                            @active="(color) => handleChange(BADGE_OPTIONS.COLOR, color)"
                            @input="(color) => handleChange(BADGE_OPTIONS.COLOR, color)" />
                    </div>
                    <div class="badge-position">
                        <div class="badge-position--label">
                            Badge Position
                        </div>
                        <pendo-multiselect
                            :value="badgePosition"
                            :allow-empty="false"
                            :options="positions"
                            @select="(position) => handleChange(BADGE_OPTIONS.POSITION, position.id)" />
                    </div>
                </div>
            </div>
        </template>
        <div
            v-if="!isEditing"
            slot="footer"
            class="footer-badge-settings">
            <div class="activation-text">
                <div class="activation-text--label">
                    Launch Method
                </div>
                {{ launchText }}.
            </div>
            <div
                v-if="!isLauncherType && !hasInvalidMethod"
                class="badge-icon">
                <div class="badge-icon--label">
                    Badge Icon
                </div>
                {{ badgeName }}
            </div>
            <div
                v-if="!isLauncherType && !hasInvalidMethod"
                class="badge-position">
                <div class="badge-position--label">
                    Badge Position
                </div>
                {{ badgePosition }}
            </div>
            <div
                v-if="!isLauncherType && !hasInvalidMethod"
                class="badge-color">
                <div class="badge-color--label">
                    Color
                </div>
                <pendo-color-picker
                    :class="{ 'label-color-picker': true, updating: isColorPickerHidden || isUpdating }"
                    :initial-color="badgeColor"
                    value-key="hex"
                    disable-presets
                    disable-alpha
                    disabled />
            </div>
        </div>
    </pendo-card>
</template>

<script>
import {
    PendoCard,
    PendoButton,
    PendoMultiselect,
    PendoNotification,
    PendoColorPicker,
    PendoAlert
} from '@pendo/components';
import {
    STATES,
    BADGE_POSITIONS,
    getDefaultBadgesSVGs,
    updateBadgePosition,
    updateBadgeImage,
    updateBadgeColor,
    updateBadgeIconName,
    getBadgePosition,
    getBadgeIconName,
    getBadgeImage,
    getBadgeColor,
    changeSVGColor,
    createDefaultBadgeObject
} from '@/utils/resource-center';
import { mapGetters, mapActions, mapState } from 'vuex';
import { LAUNCH_METHODS } from '@/utils/guides';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import has from 'lodash/has';

export default {
    name: 'ResourceCenterActivationInfo',
    components: {
        PendoCard,
        PendoButton,
        PendoMultiselect,
        PendoColorPicker,
        PendoAlert
    },
    props: {
        guide: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            isEditing: false,
            isColorPickerHidden: false,
            isDisabled: false,
            activeGuide: {},
            activeLaunchMethod: {},
            LAUNCH_METHODS,
            availableLauncherOptions: [
                { ...LAUNCH_METHODS.extensionIcon, id: 'extensionIcon' },
                { ...LAUNCH_METHODS.badge, id: 'badge' }
            ],
            BADGE_OPTIONS: Object.freeze({
                ICON: 'icon',
                COLOR: 'color',
                POSITION: 'position'
            }),
            badgeName: null,
            badgePosition: null,
            badgeColor: null,
            badgeImage: null
        };
    },
    computed: {
        ...mapState({
            error: (state) => state.resourceCenter.error,
            isUpdating: (state) => state.resourceCenter.isUpdating
        }),
        ...mapGetters({
            isDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            appForGuide: 'apps/appForGuide'
        }),
        guidePlatform () {
            return this.appForGuide(this.guide).platform;
        },
        lastUpdatedAt () {
            return this.guide.lastUpdatedAt;
        },
        isDraft () {
            return this.guide.state === STATES.DRAFT;
        },
        hasInvalidMethod () {
            return !this.availableLauncherOptions.find((option) => option.id === this.activeGuide.launchMethod);
        },
        hasMultipleLaunchMethods () {
            return Array.isArray(this.guide.launchMethod) && this.guide.launchMethod.length > 1;
        },
        hasCorruptedBadge () {
            const badge = get(this, 'activeGuide.attributes.badge', null);

            return (this.isBadgeType || this.isLauncherType) && !badge;
        },
        launchMethod () {
            let { launchMethod } = {
                launchMethod: 'badge',
                ...this.activeGuide
            };
            launchMethod = launchMethod.split('-')[0];

            return launchMethod;
        },
        launchText () {
            return `This Resource Center appears ${
                (LAUNCH_METHODS[this.launchMethod] || { text: this.launchMethod }).text
            }`;
        },
        launcherLabel () {
            return (LAUNCH_METHODS[this.launchMethod] || { label: '--' }).label;
        },
        isLauncherType () {
            return this.launchMethod === 'extensionIcon';
        },
        isBadgeType () {
            return this.launchMethod === 'badge';
        },
        positions () {
            return Object.keys(BADGE_POSITIONS).map((id) => ({ id, label: BADGE_POSITIONS[id] }));
        },
        icons () {
            const svgs = getDefaultBadgesSVGs();
            const res = svgs.map((badge) => {
                let { name, src: svg } = badge;
                svg = changeSVGColor(svg, this.badgeColor);

                return {
                    name,
                    src: svg
                };
            });

            return res;
        }
    },
    watch: {
        lastUpdatedAt () {
            this.resetActiveItems();
        }
    },
    created () {
        this.resetActiveItems();
    },
    methods: {
        ...mapActions({
            updateModule: 'resourceCenter/updateModule'
        }),
        handleEditing () {
            if (this.hasInvalidMethod) {
                this.setDefaultBadgeOptions();
                this.setActiveValues();
                if (this.guidePlatform === 'extension') {
                    this.selectLauncher({ id: 'extensionIcon' });
                }
            }

            this.isEditing = true;
        },
        setDefaultBadgeOptions () {
            this.activeGuide.attributes.badge = createDefaultBadgeObject(this.activeGuide.id);
            this.activeGuide.attributes.activation = { selector: 'body' };
            this.activeGuide.launchMethod = 'badge';
            this.activeGuide.steps[0].elementPathRule = 'body';
        },
        resetActiveItems () {
            this.isEditing = false;
            this.isColorPickerHidden = false;
            this.isDisabled = false;
            this.activeGuide = cloneDeep(this.guide);
            this.setActiveValues();
        },
        setActiveValues () {
            if (this.hasCorruptedBadge) this.setDefaultBadgeOptions();
            this.badgeName = getBadgeIconName(this.activeGuide) || 'logo';
            this.badgeColor = getBadgeColor(this.activeGuide);
            this.badgeImage = getBadgeImage(this.activeGuide);
            this.badgePosition = getBadgePosition(this.activeGuide);
        },
        selectLauncher ({ id }) {
            this.activeGuide.launchMethod = id;
            if (this.isLauncherType) {
                const newGuide = updateBadgePosition(this.activeGuide, 'top-right');
                this.activeGuide = newGuide;
            }
        },
        handleChange (type, value) {
            let newGuide = this.activeGuide;

            switch (type) {
                case this.BADGE_OPTIONS.POSITION:
                    newGuide = updateBadgePosition(this.activeGuide, value);
                    break;
                case this.BADGE_OPTIONS.COLOR:
                    if (!/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/gim.test(value)) return;
                    newGuide = updateBadgeColor(this.activeGuide, value);
                    newGuide = updateBadgeImage(
                        this.activeGuide,
                        this.icons.find(({ name }) => this.badgeName === name).src
                    );
                    break;
                case this.BADGE_OPTIONS.ICON:
                    newGuide = updateBadgeIconName(this.activeGuide, value.name);
                    newGuide = updateBadgeImage(this.activeGuide, value.src);
                    break;
            }

            this.activeGuide = newGuide;
            this.setActiveValues();
        },
        async updateActivation () {
            if (this.isDisabled) return;
            if (this.isLauncherType || this.isBadgeType) {
                this.removeTargetElementProperties();
            }
            this.isDisabled = true;
            this.isColorPickerHidden = true;

            await this.updateModule({ updatedModule: this.activeGuide });
            this.resetActiveItems();

            if (this.error) {
                this.showErrorToast();
            }
        },
        removeTargetElementProperties () {
            if (has(this, 'activeGuide.attributes')) {
                delete this.activeGuide.attributes.dom;
                delete this.activeGuide.attributes.elementSelectionType;
                delete this.activeGuide.attributes.activation;
            }
            const isJSON = typeof this.activeGuide.steps[0].buildingBlocks === 'string';
            const buildingBlocks = isJSON
                ? JSON.parse(this.activeGuide.steps[0].buildingBlocks)
                : this.activeGuide.steps[0].buildingBlocks;
            const clonedBuildingBlocks = cloneDeep(buildingBlocks);
            if (has(clonedBuildingBlocks, 'views[0].web')) {
                delete clonedBuildingBlocks.views[0].web.verticalAlignment;
                delete clonedBuildingBlocks.views[0].web.relativeAlignment;
            }
            this.activeGuide.steps[0].buildingBlocks = clonedBuildingBlocks;
        },
        showErrorToast () {
            PendoNotification({
                type: 'error',
                title: 'Something went wrong!',
                message: 'Please try again or contact support if the issue persists.',
                duration: 7000
            });
        }
    }
};
</script>

<style lang="scss">
.multiselect-option {
    display: flex;
    align-items: center;

    > :first-child {
        padding-right: 10px;
    }
}

.label-color-picker.updating {
    height: 0px;
    overflow: hidden;
}

.guide-details--activation {
    min-height: 450px;

    .pendo-card__body {
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    }

    .launch-method {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-evenly;
        align-items: center;

        &--item {
            display: flex;
            flex-flow: column nowrap;
            height: 200px;
            justify-content: center;
            align-items: center;

            &.disabled {
                opacity: 0.4;
            }

            &--text {
                text-transform: uppercase;
                font-size: 0.875em;
                margin-top: 1em;
            }

            .badge-icon-preview-img {
                height: 50%;
            }
        }

        &--item.with-alert {
            justify-content: flex-start;
            gap: 10px;
        }
    }

    .launcher-selection-dropdown {
        width: 100%;
    }

    .badge-settings {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: space-between;

        > div:first-child {
            flex: 1 90%;
        }

        > div {
            flex: 0 45%;
            margin-top: 14px;
        }
    }

    .footer-badge-settings {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: space-between;

        > div {
            flex: 0 45%;
            margin-top: 14px;
        }
    }

    .activation-text,
    .badge-icon,
    .badge-color,
    .badge-position {
        color: $gray-lighter-2;
        font-weight: 400;
        line-height: 1.5em;
        padding-bottom: 0.5em;

        &--label {
            font-weight: 700;
            color: $gray-primary;
            padding-bottom: 0.25em;
        }
    }
}
</style>
