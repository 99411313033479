<template>
    <div class="view--workflows">
        <workflows-details-header
            @deleteClicked="onDeleteClicked"
            @nameChanged="onNameChanged"
            @runClicked="runAggregation"
            @saveClicked="onSaveClicked" />
        <workflows-details-content />

        <workflows-delete-modal
            :visible="deleteModalVisible"
            :name="workflowName"
            @deleteWorkflow="onDeleteWorkflow"
            @modalClose="onDeleteModalClose" />
    </div>
</template>

<script>
import { runWorkflow } from '@/aggregations/workflows';
import WorkflowsDetailsHeader from '@/components/workflows/details/WorkflowsDetailsHeader.vue';
import WorkflowsDetailsContent from '@/components/workflows/details/WorkflowsDetailsContent.vue';
import WorkflowsDeleteModal from '@/components/workflows/WorkflowsDeleteModal.vue';
import moment, { DATE_FORMAT } from '@/utils/moment.js';
import { getStartDateForCount } from '@/utils/time-series';
import { PendoNotification } from '@pendo/components';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
    name: 'WorkflowsDetailsView',
    components: {
        WorkflowsDeleteModal,
        WorkflowsDetailsHeader,
        WorkflowsDetailsContent
    },
    props: {
        id: {
            type: String,
            default: null
        }
    },
    provide () {
        return {
            $workflows: this
        };
    },
    data () {
        const now = new Date();

        return {
            deleteModalVisible: false,
            isAggregationInFlight: false,
            lastRanWorkflow: null,
            workflowStats: {},
            workflowVisitors: [],
            workingWorkflow: {
                classification: 'nonrecurring',
                dateRange: {
                    id: 'last30days',
                    label: 'Last 30 Days',
                    count: 30,
                    value: {
                        start: moment(getStartDateForCount(30)).format(DATE_FORMAT.iso),
                        end: moment(getStartDateForCount(1)).format(DATE_FORMAT.iso)
                    }
                },
                end: null,
                id: null,
                name: `New Workflow - ${moment(now).format(DATE_FORMAT.short)} @ ${moment(now).format(
                    DATE_FORMAT.time
                )}`,
                segment: 'everyone',
                shared: false,
                start: null,
                maxDuration: {
                    label: '24 Hours',
                    value: 86400,
                    icon: {
                        type: 'clock'
                    }
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            workflowById: 'workflows/workflowById'
        }),
        currentWorkflow () {
            if (this.id === 'new') {
                return null;
            }

            return this.workflowById(this.id);
        },
        workflowName () {
            return this.currentWorkflow ? this.currentWorkflow.name : '';
        }
    },
    async created () {
        await this.fetchWorkflows();

        if (this.id !== 'new') {
            if (!this.currentWorkflow) {
                this.$router.replace({
                    name: 'workflowsList'
                });

                PendoNotification({
                    type: 'error',
                    message: 'Workflow does not exists.'
                });

                return;
            }

            this.workingWorkflow = cloneDeep(this.currentWorkflow);
            this.lastRanWorkflow = cloneDeep(this.currentWorkflow);
            this.runAggregation();
        }
    },
    methods: {
        ...mapActions({
            createWorkflow: 'workflows/create',
            fetchWorkflows: 'workflows/fetch',
            removeWorkflow: 'workflows/remove',
            updateWorkflow: 'workflows/update'
        }),
        ...mapMutations({
            removeWorkflowFromState: 'workflows/deleteMapKey'
        }),
        handleSavingError (error) {
            if (this.id !== 'new' && get(error, 'response.status', 500) === 404) {
                this.removeWorkflowFromState({
                    id: this.id
                });

                this.$router.replace({
                    name: 'workflowsList'
                });

                PendoNotification({
                    type: 'error',
                    message: `${this.workingWorkflow.name} has been deleted.`
                });
            } else {
                PendoNotification({
                    type: 'error',
                    message: `There was an error saving ${this.workingWorkflow.name}. Please try again.`
                });
            }
        },
        onDeleteClicked () {
            this.deleteModalVisible = true;
        },
        onDeleteModalClose () {
            this.deleteModalVisible = false;
        },
        async onDeleteWorkflow () {
            const workflow = cloneDeep(this.currentWorkflow);

            try {
                await this.removeWorkflow({ id: workflow.id });
                PendoNotification({
                    type: 'success',
                    message: `${workflow.name} deleted!`
                });

                this.$router.push({
                    name: 'workflowsList'
                });
            } catch (error) {
                PendoNotification({
                    type: 'error',
                    message: `There was an error deleting ${workflow.name}. Please try again.`
                });
            }

            this.deleteModalVisible = false;
        },
        getCompletedStatus (maxStep) {
            if (maxStep === 0 || !maxStep) {
                return 'Not Started';
            }
            if (maxStep === 1) {
                return 'Incomplete';
            }
            if (maxStep === 2) {
                return 'Completed';
            }
        },
        async onNameChanged (name) {
            if (this.id !== 'new') {
                const workflow = cloneDeep(this.currentWorkflow);
                workflow.name = name;

                try {
                    await this.updateWorkflow({
                        workflow
                    });
                } catch (error) {
                    this.handleSavingError(error);
                }
            }

            this.workingWorkflow.name = name;
        },
        async onSaveClicked () {
            try {
                if (this.id === 'new') {
                    this.workingWorkflow.id = await this.createWorkflow({
                        workflow: this.workingWorkflow
                    });

                    this.$router.push({
                        name: 'workflowsDetails',
                        params: {
                            id: this.workingWorkflow.id
                        }
                    });
                } else {
                    await this.updateWorkflow({
                        workflow: this.workingWorkflow
                    });
                }

                this.runAggregation();

                PendoNotification({
                    type: 'success',
                    message: `${this.workingWorkflow.name} saved!`
                });
            } catch (error) {
                this.handleSavingError(error);
            }
        },
        async runAggregation () {
            this.isAggregationInFlight = true;
            try {
                this.workflowStats = {};
                this.workflowVisitors = [];
                const workflowsAggRes = await runWorkflow(this.workingWorkflow);
                workflowsAggRes.forEach((row) => {
                    if ('completedPercentage' in row) {
                        this.workflowStats = row;
                    } else {
                        row.status = this.getCompletedStatus(row.maxStep);
                        delete row.maxStep;
                        this.workflowVisitors.push(row);
                    }
                });
                this.lastRanWorkflow = cloneDeep(this.workingWorkflow);
            } catch (error) {
                PendoNotification({
                    type: 'error',
                    message: `There was an error getting data for ${this.workingWorkflow.name}. Please try again.`
                });
            }
            this.isAggregationInFlight = false;
        }
    }
};
</script>
