<template>
    <div
        v-pendo-loading:feather="loading"
        class="poll-activity-chart">
        <div
            ref="poll-activity"
            class="pendo-highcharts-container" />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getPollResponseCount } from '@/aggregations/poll-responses';
import { PendoLoading } from '@pendo/components';
import Highcharts from '@/utils/highcharts';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';

export default {
    name: 'PollActivityChart',
    directives: {
        PendoLoading
    },
    props: {
        poll: {
            type: Object,
            required: true
        },
        timeSeries: {
            type: Object,
            required: true
        },
        segment: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            loading: false,
            pollResponses: null
        };
    },
    computed: {
        ...mapState({
            guideId: (state) => state.guides.activeId
        }),
        ...mapGetters({
            guide: 'guides/active'
        }),
        appId () {
            return get(this.guide, 'appId', '');
        },
        pollType () {
            return get(this.poll, 'attributes.type');
        },
        series () {
            const lookup = keyBy(this.pollResponses, 'pollResponse');

            return [
                {
                    name: 'Responses',
                    data: this.categories.map((category) => {
                        return get(lookup, `['${category}'].count`, 0);
                    })
                }
            ];
        },
        categories () {
            if (this.pollType === 'NumberScale') {
                return this.poll.numericResponses;
            }

            return Object.keys(this.poll.idResponses);
        },
        segmentId () {
            const id = get(this.segment, 'id');
            const isEveryone = id === 'everyone';

            return isEveryone ? null : id;
        }
    },
    watch: {
        timeSeries () {
            this.getPollResponses(this.segmentId, this.timeSeries);
        },
        segment () {
            this.getPollResponses(this.segmentId, this.timeSeries);
        }
    },
    created () {
        this.getPollResponses(this.segmentId, this.timeSeries);
    },
    methods: {
        async getPollResponses (segmentId, timeSeries) {
            this.loading = true;
            this.pollResponses = await getPollResponseCount({
                appId: this.appId,
                timeSeries,
                guideId: this.guideId,
                pollId: this.poll.id,
                segmentId
            });
            this.loading = false;
            this.chartConfig = this.getChartConfig();
            if (this.$refs['poll-activity']) {
                this.chart = Highcharts.chart(this.$refs['poll-activity'], this.chartConfig);
            }
        },
        getChartConfig () {
            const vm = this;

            return {
                series: this.series,
                chart: {
                    type: 'bar',
                    height: 65 * this.categories.length,
                    spacing: [0, 0, 0, 0]
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                xAxis: {
                    categories: this.categories,
                    crosshair: false,
                    labels: {
                        formatter () {
                            if (get(vm, 'poll.attributes.type') === 'NumberScale') {
                                return this.value;
                            }

                            return vm.poll.idResponses[this.value];
                        },
                        y: null,
                        align: 'center'
                    }
                },
                yAxis: {
                    title: {
                        text: null
                    },
                    labels: {
                        enabled: false
                    },
                    min: 0,
                    allowDecimals: false,
                    gridLineWidth: 0,
                    minorGridLineWidth: 0
                },
                tooltip: {
                    enabled: false
                },
                colors: ['#229CA8']
            };
        }
    }
};
</script>

<style lang="scss">
.poll-activity-chart {
    padding: 16px;

    .pendo-highcharts-container {
        min-height: 130px;
    }
}
</style>
