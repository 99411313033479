<template>
    <div class="absolute-date-range">
        <pendo-date-picker
            class="absolute-date-range__start"
            is-required
            :value="start"
            :max-date="end"
            full-width
            @select-date="updateStart" />
        <div class="absolute-date-range__and-label">
            and
        </div>
        <pendo-date-picker
            class="absolute-date-range__end"
            is-required
            :value="end"
            :min-date="start"
            full-width
            @select-date="updateEnd" />
    </div>
</template>

<script>
import { PendoDatePicker } from '@pendo/components';
import moment, { DATE_FORMAT } from '@/utils/moment';

export default {
    name: 'AbsoluteDateRange',
    components: {
        PendoDatePicker
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        start () {
            return moment(this.value.start).toDate();
        },
        end () {
            return moment(this.value.end).toDate();
        },
        isStartValid () {
            return moment(this.value.start, DATE_FORMAT.iso, true).isValid();
        },
        isEndValid () {
            return moment(this.value.end, DATE_FORMAT.iso, true).isValid();
        }
    },
    created () {
        let start = moment(this.value.start);
        let end = moment(this.value.end);

        if (!this.isStartValid) {
            start = moment(new Date()).subtract(1, 'days');
        }

        if (!this.isEndValid || end.isBefore(start)) {
            end = moment(start).add(1, 'days');
        }

        this.updateStart({ value: start });
        this.updateEnd({ value: end });
    },
    methods: {
        updateStart (date) {
            const value = moment(date.value).format(DATE_FORMAT.iso);
            this.$emit('input', { field: 'start', value });
        },
        updateEnd (date) {
            const value = moment(date.value).format(DATE_FORMAT.iso);
            this.$emit('input', { field: 'end', value });
        }
    }
};
</script>

<style lang="scss">
.absolute-date-range {
    align-items: center;
    grid-column: span 2;
    display: grid;
    grid-template-columns: 1fr 36px 1fr;
}

.absolute-date-range__and-label {
    color: $gray-lighter-2;
    margin: auto;
}
</style>
