var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resource-chooser"},[_c('div',{staticClass:"resource-chooser__filters"},[_c('pendo-multiselect',{attrs:{"value":_vm.selectedResourceType,"options":_vm.resourceTypeOptions,"disabled":_vm.disabled,"placeholder":"Select event type"},on:{"select":_vm.onResourceTypeSelect},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('pendo-data-measure-trigger')]},proxy:true},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('pendo-icon-option',{attrs:{"option":option}})]}}])}),(_vm.usesMultiApp)?[_c('span',{staticClass:"resource-chooser__text"},[_vm._v("from")]),_c('pendo-multiselect',{attrs:{"value":_vm.selectedApp,"options":_vm.appOptions,"disabled":_vm.disabled,"placeholder":_vm.isLoading ? 'Loading...' : 'Select an app'},on:{"select":_vm.onAppSelect},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('pendo-data-measure-trigger')]},proxy:true}],null,false,3054313053)})]:_vm._e()],2),_c('pendo-multiselect',{attrs:{"value":_vm.resource,"loading":_vm.isLoading,"placeholder":_vm.isLoading ? 'Loading...' : _vm.resourcePlaceholder,"searchable":false,"options":_vm.resourceOptions,"disabled":_vm.disabled,"label-key":"name","min-menu-width":300,"no-results-text":("No " + (_vm.selectedResourceType.label) + "s found. Consider changing the search query.")},on:{"select":_vm.onResourceSelect},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var updateInputValue = ref.updateInputValue;
return [_c('div',{staticClass:"resource-chooser__search"},[_c('pendo-multiselect-input',{staticClass:"resource-chooser__search-input",attrs:{"update-input-value":updateInputValue,"is-search-icon-visible":"","computed-placeholder":_vm.inputPlaceholder}})],1)]}},{key:"selectedLabel",fn:function(ref){
var option = ref.option;
return [_c('pendo-icon-option',{attrs:{"option":option}})]}},{key:"trigger",fn:function(){return [_c('div',{staticClass:"resource-chooser__trigger",attrs:{"data-cy":"resource-chooser"}},[_c('pendo-data-source-trigger',{attrs:{"slot":"trigger"},slot:"trigger"})],1)]},proxy:true},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('pendo-icon-option',{attrs:{"option":option}})]}},{key:"noData",fn:function(){return [_c('div',{staticClass:"no-data-message"},[(_vm.usesMultiApp)?_c('span',[_vm._v(" No "+_vm._s(_vm.selectedResourceType.label)+"s found. Please select a different app or data source. ")]):_c('span',[_vm._v(" No "+_vm._s(_vm.selectedResourceType.label)+"s found. ")])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }