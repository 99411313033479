<template>
    <div class="select-page">
        <pendo-alert
            v-if="!targetPages.length && !loading"
            data-cy="no-target-pages-copy-modal-error"
            class="copy-modal-error"
            type="warning">
            <slot>
                You cannot copy a guide with page activated steps to an application without pages. Please create the
                pages you need for the selected application.
            </slot>
        </pendo-alert>
        <div
            class="modal-description"
            data-cy="copy-modal-description">
            <p>
                Select pages from your destination application for each page activated step in your guide.
            </p>
        </div>
        <br>
        <div v-if="loading">
            <pendo-loading-indicator
                data-cy="loading-page-lists"
                :loading="loading"
                size="large" />
        </div>
        <div
            v-if="!loading"
            class="select-page-list">
            <div class="select-page-list-header">
                <div class="select-page-list-header-item">
                    <strong>Guide</strong>
                </div>
                <div class="select-page-list-header-item">
                    <strong>Activation Page</strong>
                </div>
            </div>
            <pendo-divider
                direction="horizontal"
                width="360px"
                height="1px"
                stroke="#DADCE5" />
            <div
                v-for="step in steps"
                :key="step.id">
                <div class="select-page-list-row">
                    <div class="step-info">
                        {{ step.name }}
                        <br>
                        <div
                            v-if="!step.sourcePageNotFound"
                            class="source-page-found">
                            {{ 'Current: ' + step.sourceSelectedPage.name }}
                        </div>
                        <div
                            v-if="step.sourcePageNotFound"
                            class="source-page-not-found">
                            Error: Source page not found
                        </div>
                    </div>
                    <div
                        v-if="!step.sitewide"
                        class="step-page-selector">
                        <pendo-multiselect
                            v-model="step.targetPage"
                            :loading="loading"
                            :options="targetPages"
                            :min-trigger-width="160"
                            :max-trigger-width="160"
                            placeholder="-- Choose Page --"
                            label-key="name"
                            @input="pageSelected">
                            <template #header="{ select }">
                                <div
                                    v-if="!step.sourcePageNotFound"
                                    class="step-page-selector__header">
                                    <div
                                        class="step-page-selector__option"
                                        @click="select(createPageOnTargetAppOption(step.sourceSelectedPage))">
                                        <span
                                            v-pendo-tooltip="createNewPageDescriptionTooltip"
                                            style="padding: 0 16px">
                                            Create New Page (from Existing Page Rule)
                                        </span>
                                    </div>
                                </div>
                            </template>
                            <template #selectedLabel="{ option }">
                                <div
                                    v-if="option.createPageOnTargetApp"
                                    v-pendo-tooltip="createNewPageTooltip">
                                    Create New Page (from Existing Page Rule)
                                </div>
                                <pendo-group-option
                                    v-if="!option.createPageOnTargetApp"
                                    :option="option" />
                            </template>
                            <template #option="{ option }">
                                <pendo-group-option :option="option" />
                            </template>
                        </pendo-multiselect>
                    </div>
                    <div
                        v-if="step.sitewide"
                        class="sitewide-step">
                        Sitewide
                    </div>
                </div>
                <pendo-divider
                    direction="horizontal"
                    width="360px"
                    height="1px"
                    stroke="#DADCE5"
                    stroke-dasharray="2" />
            </div>
        </div>
    </div>
</template>

<script>
import {
    PendoMultiselect,
    PendoGroupOption,
    PendoDivider,
    PendoLoadingIndicator,
    PendoAlert,
    PendoTooltip
} from '@pendo/components';
import { mapActions, mapGetters } from 'vuex';

const TOOLTIP_CONFIG = {
    multiline: true,
    trigger: 'hover',
    placement: 'right',
    arrow: true
};

export default {
    name: 'SelectPage',
    components: {
        PendoMultiselect,
        PendoGroupOption,
        PendoDivider,
        PendoLoadingIndicator,
        PendoAlert
    },
    directives: {
        PendoTooltip
    },
    props: {
        guide: {
            type: Object,
            default: () => ({})
        },
        targetSub: {
            type: Object,
            default: undefined
        },
        targetApp: {
            type: Object,
            default: undefined
        },
        targetPageMap: {
            type: Object,
            default: undefined
        },
        targetPages: {
            type: Array,
            default: undefined
        }
    },
    data () {
        return {
            steps: [],
            loading: true,
            createNewPageDescriptionTooltip: {
                ...TOOLTIP_CONFIG,
                content: `This will re-create the page for this step as a new page in ${this.targetApp.displayName}.
                    If this option is selected for multiple steps only one page will be created.
                    If the relevant page already exists, please select it from the list below.`
            },
            createNewPageTooltip: {
                ...TOOLTIP_CONFIG,
                content: 'Create New Page (from Existing Page Rule)'
            }
        };
    },
    computed: {
        ...mapGetters({
            getGuideById: 'guides/getGuideById',
            pageById: 'pages/pageById'
        }),
        guideWithSteps () {
            return this.guide.steps ? this.guide : this.getGuideById(this.guide.id);
        },
        allPagesSelected () {
            if (!this.steps.length) return false;

            return !this.steps.some((step) => !step.sitewide && !step.targetPage);
        },
        nonSitewideSteps () {
            return this.steps.filter((step) => !step.sitewide);
        }
    },
    async created () {
        this.loading = true;
        this.populateSteps();
        this.loading = false;
    },
    methods: {
        ...mapActions({
            fetchSourcePages: 'pages/fetch'
        }),
        populateSteps () {
            this.guideWithSteps.steps.forEach((step, index) => {
                const newStep = {
                    id: step.id,
                    name: `Step ${index + 1}`,
                    targetPage: null
                };

                if (step.pageId) {
                    const page = this.pageById(step.pageId);
                    const pageExists = this.pageExistsInTargetSub(step.pageId);
                    if (pageExists) {
                        newStep.targetPage = page;
                    }

                    if (page) {
                        newStep.sourceSelectedPage = page;
                    } else {
                        newStep.sourcePageNotFound = true;
                    }
                } else {
                    newStep.sourceSelectedPage = { name: 'Sitewide' };
                    newStep.sitewide = true;
                }

                this.steps.push(newStep);
            });

            if (this.targetPageMap) {
                this.mapTargetPageSelections(this.targetPageMap);
            }

            this.pageSelected();
        },
        pageExistsInTargetSub (pageId) {
            return this.targetPages.find((page) => page.id === pageId);
        },
        mapTargetPageSelections (pageMap) {
            const previouslySelectedPages = Object.entries(pageMap);
            previouslySelectedPages.forEach(([stepId, previouslySelectedPage]) => {
                const step = this.steps.find((step) => step.id === stepId);
                if (step) {
                    if (previouslySelectedPage.action === 'create') {
                        step.targetPage = this.createPageOnTargetAppOption(
                            this.pageById(previouslySelectedPage.pageId)
                        );
                    } else if (previouslySelectedPage.action === 'use') {
                        step.targetPage = this.targetPages.find((page) => page.id === previouslySelectedPage.pageId);
                    } else {
                        step.targetPage = this.targetPages.find((page) => page.id === previouslySelectedPage);
                    }
                }
            });
            this.pageSelected();
        },
        createPageOnTargetAppOption (page) {
            return {
                ...page,
                name: 'Create New Page (from Existing Page Rule)',
                createPageOnTargetApp: true
            };
        },
        pageSelected () {
            if (this.allPagesSelected) {
                const pagesToCreate = [];
                const pageMap = this.nonSitewideSteps
                    .map((step) => {
                        const { createPageOnTargetApp } = step.targetPage;
                        const targetPageId = step.targetPage.id;

                        if (createPageOnTargetApp && !pagesToCreate.find((pageId) => pageId === targetPageId)) {
                            pagesToCreate.push(targetPageId);
                        }

                        return [step.id, targetPageId, createPageOnTargetApp];
                    })
                    .reduce(
                        (map, [stepId, targetPageId, createPageOnTargetApp]) => ({
                            ...map,
                            [stepId]: {
                                pageId: targetPageId,
                                action: createPageOnTargetApp ? 'create' : 'use'
                            }
                        }),
                        {}
                    );

                const payload = {
                    pageMap,
                    pagesToCreate
                };

                this.$emit('pageSelected', payload);
            } else {
                this.$emit('pageSelected', {});
            }
        }
    }
};
</script>

<style scoped lang="scss">
.copy-modal-error {
    margin-bottom: 14px;
}

.modal-description p:first-child {
    margin-top: 0;
}

.select-page-list-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .select-page-list-header-item {
        width: 160px;
    }
}

.select-page-list-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0px;

    .step-info {
        width: 160px;

        .source-page-found {
            word-break: break-all;
            color: $gray-lighter-2;
        }

        .source-page-not-found {
            color: $red-error;
        }
    }

    .sitewide-step {
        width: 160px;
    }
}

.step-page-selector__header {
    height: 40px;
    border-bottom: 1px solid $gray-lighter-5;
    display: flex;
    align-items: center;
    justify-content: center;

    .step-page-selector__option {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 16px;
        height: 26px;
        width: 100%;
        cursor: pointer;

        &:hover {
            background-color: $gray-lighter-7;
        }

        &.is-selected {
            background-color: $gray-lighter-6;
        }
    }
}
</style>
