var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pendo-multiselect',{attrs:{"label-key":"displayName","value-key":"id","multiple":"","hide-selected-options":false,"searchable":false,"close-on-select":false,"min-menu-width":300,"max-menu-height":400,"options":_vm.filteredOptions},on:{"click-outside":_vm.applySelections},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var toggleMenu = ref.toggleMenu;
return [_c('pendo-button',{attrs:{"prefix-icon":"plus","theme":"app","type":"link","label":_vm.triggerLabel},on:{"click":toggleMenu}})]}},{key:"beforeList",fn:function(ref){
var model = ref.model;
var removeSelection = ref.removeSelection;
return _c('div',{staticClass:"checkbox-select__selected",class:{ empty: !model.length }},[_c('div',{staticClass:"checkbox-select__selected-header"},[_c('div',{staticClass:"checkbox-select__selected-group-label"},[_vm._v(" Selected ("+_vm._s(_vm.internalSelectedOptions.length)+") ")]),(model.length)?_c('pendo-button',{attrs:{"type":"link","label":"Clear"},on:{"click":_vm.clearSelections}}):_vm._e()],1),_c('div',{staticClass:"checkbox-select__selected-options"},_vm._l((_vm.internalSelectedOptions),function(selectedOption,index){return _c('pendo-checkbox-option',{key:index,attrs:{"option":selectedOption},on:{"click":function($event){return removeSelection(selectedOption)}}})}),1)])}},{key:"option",fn:function(ref){
var option = ref.option;
return _c('pendo-checkbox-option',{attrs:{"option":option}})}},{key:"footer",fn:function(ref){
var toggleMenu = ref.toggleMenu;
return _c('div',{staticClass:"checkbox-select__footer"},[_c('pendo-button',{attrs:{"size":"mini","type":"tertiary","theme":"via","label":"Cancel"},on:{"click":function($event){return _vm.cancelSelections(toggleMenu)}}}),_c('pendo-button',{attrs:{"size":"mini","theme":"via","label":"Apply"},on:{"click":function($event){return _vm.applySelections(toggleMenu)}}})],1)}}]),model:{value:(_vm.internalSelectedOptions),callback:function ($$v) {_vm.internalSelectedOptions=$$v},expression:"internalSelectedOptions"}},[_c('div',{staticClass:"checkbox-select__header",attrs:{"slot":"header"},slot:"header"},[_c('pendo-multiselect-input',{staticClass:"checkbox-select__input",attrs:{"is-search-icon-visible":true,"computed-placeholder":_vm.searchLabel,"update-input-value":_vm.updateSearchString}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }