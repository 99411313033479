<template>
    <svg
        :x="x"
        :y="y"
        :width="width"
        :height="height"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img">
        <path
            d="M16 0L0 16H16V32L32 16V0H16Z"
            fill="#FF4876" />
    </svg>
</template>

<script>
export default {
    name: 'DefaultChevron',
    props: {
        x: {
            type: Number,
            default: undefined
        },
        y: {
            type: Number,
            default: undefined
        },
        width: {
            type: Number,
            default: 32
        },
        height: {
            type: Number,
            default: 32
        }
    }
};
</script>
