<template>
    <div class="application-rule">
        <pendo-multiselect
            :value="rule.operator"
            :allow-empty="false"
            full-width
            value-key="value"
            :options="operatorOptions"
            :disabled="!operatorOptions"
            class="segment-rule--operator"
            placeholder="Select Usage"
            @select="handleAppUsageOperatorSelection" />

        <pendo-multiselect
            disabled
            preselect-first
            :options="['ever']"
            value="ever"
            min-trigger-width="100%" />
    </div>
</template>

<script>
import { SEGMENT_OPERATORS, APP_USAGE_TIME_RANGE_OPTIONS } from '@/constants/segments';
import { PendoMultiselect } from '@pendo/components';

export default {
    name: 'ApplicationRule',
    components: {
        PendoMultiselect
    },
    props: {
        rule: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            appUsageTimeRangeOptions: APP_USAGE_TIME_RANGE_OPTIONS,
            appUsed: true
        };
    },
    computed: {
        operatorOptions () {
            return [...SEGMENT_OPERATORS[this.rule.schema.schema]];
        }
    },
    mounted () {
        this.$emit('input', { field: 'time', value: 'ever' });
        this.$emit('input', { field: 'value', value: 1 });
    },
    methods: {
        appUsageTimeLabelObject (timeString) {
            return this.appUsageTimeRangeOptions.find((opt) => opt.value === timeString);
        },
        handleAppUsageOperatorSelection ($event) {
            this.appUsed = $event.value === 'used';
            this.$emit('input', { field: 'operator', value: $event });

            // will be removed wwhen working on APP-57676 or APP-57677
            if (this.appUsed) {
                this.$emit('input', { field: 'value', value: 1 });
            } else {
                this.$emit('input', { field: 'value', value: 0 });
            }
        }
    }
};
</script>
