<template>
    <div class="visitors-per-step-chart">
        <guide-chart-card
            :show-summary="false"
            :is-fetching="isFetching"
            title="Visitors Per Step">
            <template slot="chart">
                <div
                    ref="visitors-per-step"
                    :class="{ empty: totalVisitors <= 0 }"
                    class="pendo-highcharts-container" />
            </template>
        </guide-chart-card>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import GuideChartCard from '@/components/guides/metrics/GuideChartCard';
import Highcharts from '@/utils/highcharts';
import { PendoGuideMetricsDrawerContent } from '@pendo/components';
import { getStepName, getStepNumber } from '@/utils/guides';
import { getVisitorsPerStep, getStepsSeenVisitorsAndAccounts } from '@/aggregations/visitors-per-step';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import { addHrefToVisitors } from '@/utils/utils';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import maxBy from 'lodash/maxBy';

export default {
    name: 'VisitorsPerStepChart',
    components: {
        GuideChartCard
    },
    props: {
        csvDownloadAggId: {
            type: String,
            default: () => 'visitor-per-step-chart'
        },
        currentStep: {
            type: Object,
            default: null
        },
        stepsSeenVisitorsAndAccounts: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            chart: null,
            isFetching: false,
            visitorsPerStep: [],
            unsubscribeFilterBarListener: null
        };
    },
    computed: {
        ...mapState({
            activeSegmentId: (state) => state.filters.activeSegmentId
        }),
        ...mapGetters({
            isAdoptV2Sub: 'subscriptions/activeUsesV2Adopt',
            activeTimeSeries: 'filters/activeTimeSeries',
            guide: 'guides/active'
        }),
        appId () {
            return get(this.guide, 'appId', '');
        },
        visitorUrl () {
            if (!this.isAdoptV2Sub) return null;

            return '/analytics/visitors';
        },
        totalVisitors () {
            return get(maxBy(this.visitorsPerStep, 'visitorCount'), 'visitorCount', 0);
        },
        series () {
            const resultsByStepId = keyBy(this.visitorsPerStep, 'guideStepId');

            return [
                {
                    name: 'All Steps',
                    data: this.guide.steps.map((step) => {
                        const { visitorCount = 0, viewCount = 0 } = resultsByStepId[step.id] || {};
                        const name = this.buildStepNameForDisplay(step.id, this.guide);
                        const visitorCountPercent = Math.round((100 * visitorCount) / this.totalVisitors);

                        return {
                            name,
                            id: step.id,
                            visitorCount,
                            viewCount,
                            visitorCountPercent,
                            count: visitorCount,
                            y: visitorCount
                        };
                    })
                }
            ];
        }
    },
    watch: {
        series () {
            if (!this.chart) return;
            this.chart.update({
                series: this.series
            });
        },
        'guide.steps.length': function () {
            this.initChart();
        }
    },
    created () {
        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, () => {
            this.refreshChart();
        });
    },
    mounted () {
        this.initChart();
    },
    destroyed () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        async initChart () {
            await this.refreshChart();
            const chartConfig = this.getChartConfig();
            if (this.$refs['visitors-per-step']) {
                this.chart = Highcharts.chart(this.$refs['visitors-per-step'], chartConfig);
            }
        },
        async refreshChart () {
            this.isFetching = true;
            const timeSeries = {
                ...this.activeTimeSeries,
                period: 'dayRange'
            };

            this.visitorsPerStep = await getVisitorsPerStep({
                appId: this.appId,
                guideId: this.guide.id,
                timeSeries,
                segmentId: this.activeSegmentId
            });

            this.isFetching = false;
        },
        buildStepNameForDisplay (stepId, guide) {
            const stepNumber = getStepNumber(stepId, guide);
            const stepName = getStepName(stepId, guide);

            if (stepName) {
                return `Step ${stepNumber} - ${stepName}`;
            }

            return `Step ${stepNumber}`;
        },
        getChartConfig () {
            return {
                series: this.series,
                chart: {
                    type: 'column'
                },
                plotOptions: {
                    column: {
                        events: {
                            click: (event) => this.openDrawer(event.point)
                        },
                        stacking: 'normal',
                        cursor: 'pointer'
                    }
                },
                legend: {
                    enabled: false
                },
                xAxis: {
                    categories: this.series[0].data.map((step) => step.name),
                    crosshair: false
                },
                yAxis: {
                    stackLabels: {
                        enabled: true,
                        style: {
                            color: '#BABCC5',
                            fontWeight: 'normal',
                            textOutline: null
                        },
                        formatter () {
                            return this.total !== 0 ? this.total : '';
                        }
                    },
                    labels: {
                        align: 'center'
                    },
                    title: {
                        text: 'Visitors'
                    },
                    min: 0,
                    allowDecimals: false
                },
                tooltip: {
                    pointFormat: '<b>Visitors: </b>{point.y}<br/>{point.visitorCountPercent}% of Total Visitors',
                    useHTML: true
                },
                colors: ['#229CA8']
            };
        },
        async getStepsSeenVisitorsAndAccountsWithHref (aggParams) {
            const response = await getStepsSeenVisitorsAndAccounts(aggParams);
            const visitorsWithHref = addHrefToVisitors(response[0]);

            return visitorsWithHref;
        },
        async openDrawer (point) {
            const timeSeries = {
                ...this.activeTimeSeries,
                period: 'dayRange'
            };
            this.$emit('update-current-step', point);

            this.setSidePanelState({
                point,
                loading: true
            });
            const visitors = await this.getStepsSeenVisitorsAndAccountsWithHref({
                appId: this.appId,
                guideId: this.guide.id,
                guideStepId: point.id,
                timeSeries,
                segmentId: this.activeSegmentId
            });

            this.$emit('update-steps-seen-visitors-and-accounts', visitors);

            this.setSidePanelState({
                point,
                data: visitors,
                loading: false
            });
        },
        setSidePanelState ({ point, data, loading, csvModalStatus = 'loading', csvUrl = '' }) {
            const state = {
                header: {
                    props: {
                        title: point.name
                    }
                },
                body: {
                    component: PendoGuideMetricsDrawerContent,
                    props: {
                        csvDownloadAggId: this.csvDownloadAggId,
                        data,
                        loading,
                        csvModalStatus,
                        csvUrl,
                        visitorUrl: this.visitorUrl,
                        showAccountsTab: false,
                        theme: 'via'
                    }
                }
            };
            this.$emit('open-side-panel', { ...state, visible: true });
        }
    }
};
</script>
