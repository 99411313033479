/* eslint-disable id-length */
import * as Agg2 from '@pendo/aggregations';
import { request } from '@/utils/aggregations';

export function getTimeOnApp ({ appId, timeSeries, segmentId }) {
    const spec = buildTimeOnAppSpec({ appId, timeSeries, segmentId });

    return request(spec).then((result) => result[0]);
}

export function buildTimeOnAppSpec ({ appId, timeSeries, segmentId }) {
    const { operators: o, common: c } = Agg2;

    return o.aggregation(
        'visitor-timeonsite',
        o.pipeline(
            o.sources.events({ timeSeries, appId: [appId] }),
            o.identified('visitorId'),
            o.segment(segmentId),
            o.reduce({
                uniqueVisitors: o.count('visitorId'),
                totalTimeInMinutes: o.sum('numMinutes'),
                visitorsByDay: o.count(['visitorId', 'day'])
            }),
            o.select({
                uniqueVisitors: 'uniqueVisitors',
                avgTime: `if(visitorsByDay > 0, ${c.minutesToMilliseconds('totalTimeInMinutes')} / visitorsByDay, 0)`
            })
        )
    );
}
