<template>
    <svg
        width="200"
        height="78"
        viewBox="0 0 200 78"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.482875 28.5621C0.216191 26.0248 2.05692 23.7517 4.59426 23.485L62.6402 17.3841C65.1775 17.1175 67.4506 18.9582 67.7173 21.4955L71.5838 58.2827C71.8505 60.8201 70.0098 63.0932 67.4724 63.3599L49.4052 65.2588C48.3583 65.3688 47.3804 65.8331 46.6333 66.5748L41.8736 71.3003C40.3346 72.8282 37.9399 73.0799 36.1169 71.9054L30.4786 68.2727C29.5937 67.7026 28.5407 67.4518 27.4938 67.5618L9.4265 69.4607C6.88916 69.7274 4.61605 67.8867 4.34936 65.3493L0.482875 28.5621Z"
            fill="#EAECF1" />
        <g opacity="0.8">
            <path
                opacity="0.3"
                d="M32.0234 29.0703L20.9071 52.9943C20.6797 53.5049 20.59 54.0661 20.6469 54.6222C20.7038 55.1782 20.9052 55.7096 21.2313 56.1636C21.5573 56.6176 21.9965 56.9783 22.5052 57.2099C23.0139 57.4414 23.5744 57.5357 24.1309 57.4834L51.0947 54.6494C51.6499 54.5849 52.1785 54.3761 52.6279 54.0439C53.0774 53.7116 53.432 53.2675 53.6565 52.7556C53.881 52.2437 53.9676 51.682 53.9076 51.1263C53.8476 50.5706 53.6432 50.0403 53.3146 49.5882L37.4671 28.4982C37.1342 28.0602 36.694 27.7154 36.1891 27.4971C35.6841 27.2788 35.1314 27.1943 34.5842 27.2518C34.0371 27.3093 33.514 27.5069 33.0655 27.8254C32.6169 28.144 32.258 28.5727 32.0234 29.0703V29.0703Z"
                stroke="#A973F2"
                stroke-width="3.72182"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M35.6052 36.9657L36.2744 43.3326"
                stroke="#A973F2"
                stroke-width="3.72182"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <ellipse
                cx="36.7762"
                cy="48.1078"
                rx="1.60049"
                ry="1.60049"
                transform="rotate(-6 36.7762 48.1078)"
                fill="#A973F2" />
        </g>
        <path
            d="M132.283 21.4955C132.549 18.9582 134.822 17.1174 137.36 17.3841L195.406 23.485C197.943 23.7517 199.784 26.0248 199.517 28.5621L195.651 65.3493C195.384 67.8867 193.111 69.7274 190.574 69.4607L172.506 67.5618C171.459 67.4517 170.406 67.7025 169.521 68.2727L163.883 71.9053C162.06 73.0799 159.665 72.8282 158.126 71.3003L153.367 66.5747C152.62 65.8331 151.642 65.3688 150.595 65.2588L132.528 63.3598C129.99 63.0931 128.15 60.82 128.416 58.2827L132.283 21.4955Z"
            fill="#EAECF1" />
        <g opacity="0.8">
            <path
                opacity="0.3"
                d="M162.64 30.4005L146.793 51.4904C146.464 51.9426 146.26 52.4729 146.2 53.0286C146.14 53.5843 146.226 54.146 146.451 54.6579C146.675 55.1697 147.03 55.6139 147.479 55.9461C147.929 56.2784 148.457 56.4872 149.013 56.5517L175.976 59.3857C176.533 59.438 177.093 59.3437 177.602 59.1122C178.111 58.8806 178.55 58.5199 178.876 58.0659C179.202 57.6119 179.403 57.0805 179.46 56.5245C179.517 55.9684 179.427 55.4072 179.2 54.8966L168.084 30.9726C167.849 30.475 167.49 30.0462 167.042 29.7277C166.593 29.4092 166.07 29.2116 165.523 29.1541C164.976 29.0966 164.423 29.1811 163.918 29.3994C163.413 29.6177 162.973 29.9625 162.64 30.4005V30.4005Z"
                stroke="#FF4876"
                stroke-width="3.72182"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M164.502 38.8679L163.833 45.2348"
                stroke="#FF4876"
                stroke-width="3.72182"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <ellipse
                cx="163.331"
                cy="50.0101"
                rx="1.60049"
                ry="1.60049"
                transform="rotate(5.99999 163.331 50.0101)"
                fill="#FF4876" />
        </g>
        <path
            d="M57.0441 4.61956C57.0441 2.38716 58.8538 0.577445 61.0862 0.577445H138.914C141.146 0.577445 142.956 2.38716 142.956 4.61956V54.6342C142.956 56.8666 141.146 58.6763 138.914 58.6763H113.713C112.533 58.6763 111.388 59.078 110.467 59.8152L102.525 66.1683C101.049 67.3493 98.9512 67.3493 97.475 66.1683L89.5336 59.8152C88.6121 59.078 87.4671 58.6763 86.287 58.6763H61.0862C58.8538 58.6763 57.0441 56.8666 57.0441 54.6342V4.61956Z"
            fill="#F8F8F9"
            stroke="#EAECF1"
            stroke-width="1.15489" />
        <g
            opacity="0.8"
            clip-path="url(#clip0)">
            <path
                opacity="0.3"
                d="M96.8778 14.3724L83.3216 37.0034C83.0421 37.4874 82.8942 38.0362 82.8927 38.5951C82.8911 39.154 83.0359 39.7036 83.3127 40.1892C83.5895 40.6748 83.9886 41.0794 84.4703 41.3629C84.952 41.6463 85.4996 41.7987 86.0584 41.8049H113.171C113.73 41.7987 114.277 41.6463 114.759 41.3629C115.241 41.0794 115.64 40.6748 115.917 40.1892C116.193 39.7036 116.338 39.154 116.337 38.5951C116.335 38.0362 116.187 37.4874 115.908 37.0034L102.351 14.3724C102.066 13.902 101.664 13.5131 101.185 13.2432C100.706 12.9733 100.165 12.8315 99.6146 12.8315C99.0645 12.8315 98.5236 12.9733 98.0442 13.2432C97.5648 13.5131 97.1631 13.902 96.8778 14.3724V14.3724Z"
                stroke="#128297"
                stroke-width="3.72182"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M99.6147 22.5989V29.0009"
                stroke="#128297"
                stroke-width="3.72182"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <ellipse
                cx="99.6146"
                cy="33.8023"
                rx="1.60049"
                ry="1.60049"
                fill="#128297" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect
                    width="38.4118"
                    height="38.4118"
                    fill="white"
                    transform="translate(80.4088 8.19452)" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'TooltipGuidesAlertImage'
};
</script>
