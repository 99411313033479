<template>
    <page-header title="Settings">
        <template #actions>
            <div>
                <pendo-button
                    v-if="route.name === 'manageTeam'"
                    data-cy="invite-user"
                    theme="via"
                    type="primary"
                    prefix-icon="plus"
                    label="Invite User"
                    @click="openInviteUserModal" />
                <add-app-modal-wrapper
                    v-if="!isEmptyStateDigitalAdoption"
                    :show-add-app-button="showAddAppButton" />
            </div>
        </template>
    </page-header>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { PendoButton } from '@pendo/components';
import PageHeader from '@/components/PageHeader';
import AddAppModalWrapper from './app-settings/AddAppModalWrapper.vue';

export default {
    name: 'SettingsHeader',
    components: {
        PageHeader,
        PendoButton,
        AddAppModalWrapper
    },
    computed: {
        ...mapState({
            route: (state) => state.route
        }),
        ...mapGetters({
            activeUsesV2Adopt: 'subscriptions/activeUsesV2Adopt',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            isEmptyStateDigitalAdoption: 'subscriptions/isEmptyStateDigitalAdoption'
        }),
        showAddAppButton () {
            return (
                this.activeIsDigitalAdoption &&
                this.route.name === 'subscriptionSettings' &&
                !this.isEmptyStateDigitalAdoption
            );
        }
    },
    methods: {
        ...mapMutations({
            setUserOverlayVisible: 'users/setUserOverlayVisible'
        }),
        openInviteUserModal () {
            if (this.activeUsesV2Adopt) {
                this.setUserOverlayVisible({ isVisible: true });
            } else {
                // modal is closed from within InviteNewUserModal.vue
                this.$modal.show('via-modal', {
                    title: 'Invite Team Members',
                    component: 'InviteNewUserModal'
                });
            }
        }
    }
};
</script>
