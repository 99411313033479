<template>
    <expired-link @request-new-link="requestNewLink">
        <template #logo>
            <slot name="logo" />
        </template>
    </expired-link>
</template>

<script>
import { ExpiredLink } from '@pendo/login';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'ExpiredLinkPage',
    components: {
        ExpiredLink
    },
    computed: {
        ...mapState({
            route: (state) => state.route
        })
    },
    methods: {
        ...mapActions({
            _sendResetPasswordLinkV2: 'auth/sendResetPasswordLinkV2'
        }),
        requestNewLink () {
            this._sendResetPasswordLinkV2({ email: this.route.params.email });
        }
    }
};
</script>
