var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pendo-form',{attrs:{"form-defaults":_vm.$workflows.workingWorkflow,"form-ref":"workflow-settings"}},[_c('div',{staticClass:"workflows-query-container"},[_c('div',{staticClass:"workflows-query-datepicker",attrs:{"data-cy":"workflows-datepicker-multiselect"}},[_c('pendo-form-item',{attrs:{"label":"Date Range"}},[_c('date-range-picker',{attrs:{"disabled":!_vm.canEditWorkflow,"value":_vm.$workflows.workingWorkflow.dateRange,"full-width":""},on:{"change":function($event){return _vm.onFieldUpdated('dateRange', $event.dateRange)}}})],1)],1),_c('div',{staticClass:"workflows-query-segment-chooser",attrs:{"data-cy":"workflows-segment-multiselect"}},[_c('pendo-form-item',{attrs:{"label":"Segment"}},[_c('segment-chooser',{attrs:{"disabled":!_vm.canEditWorkflow,"full-width":"","guide-targeting":false,"value":_vm.$workflows.workingWorkflow.segment},on:{"input":function($event){return _vm.onFieldUpdated('segment', $event.id)}}})],1)],1),_c('div',{staticClass:"workflows-query-max-time",attrs:{"data-cy":"workflows-max-time-multiselect"}},[_c('pendo-form-item',[_c('pendo-multiselect',{staticClass:"workflows-query-max-time--dropdown",attrs:{"disabled":!_vm.canEditWorkflow,"full-width":"","options":_vm.maxDurationOptions,"value-key":"value","label-key":"label","value":_vm.$workflows.workingWorkflow.maxDuration},on:{"select":function($event){return _vm.onFieldUpdated('maxDuration', $event)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_c('pendo-icon-option',{attrs:{"option":option}})]}},{key:"selectedLabel",fn:function(ref){
var option = ref.option;
return [_c('pendo-icon-option',{attrs:{"option":option}})]}}])},[_c('template',{slot:"topLabel"},[_vm._v(" Maximum Time Between Steps "),_c('pendo-icon',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:({
                                trigger: 'hover',
                                arrow: true,
                                content: _vm.maxDurationTooltipText,
                                multiline: true
                            }),expression:"{\n                                trigger: 'hover',\n                                arrow: true,\n                                content: maxDurationTooltipText,\n                                multiline: true\n                            }"}],attrs:{"size":"14","display":"inline","type":"info"}})],1)],2)],1)],1),_c('div',{staticClass:"workflows-query-app-start",attrs:{"data-cy":"workflows-start-app-multiselect"}},[_c('pendo-form-item',{attrs:{"label":"Workflow Start","prop":"start","rules":_vm.rules.start}},[_c('pendo-multiselect',{attrs:{"disabled":!_vm.canEditWorkflow,"full-width":"","value":_vm.appSelected.start,"options":_vm.appList,"value-key":"id","label-key":"displayName","placeholder":"Search Apps..."},on:{"select":function($event){return _vm.onAppSelected('start', $event)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_vm._v(" Select an App ")]},proxy:true},{key:"option",fn:function(ref){
                            var option = ref.option;
return [_c('pendo-icon-option',{attrs:{"option":option}})]}},{key:"selectedLabel",fn:function(ref){
                            var option = ref.option;
return [_c('pendo-icon-option',{attrs:{"option":option}})]}}])})],1)],1),_c('div',{staticClass:"workflows-query-countable workflows-query-countable-start",attrs:{"data-cy":"workflows-start-tag-multiselect"}},[_c('pendo-multiselect',{attrs:{"disabled":!_vm.appSelected.start || !_vm.canEditWorkflow,"full-width":"","group-options-key":"options","group-label-key":"key","value":_vm.$workflows.workingWorkflow.start,"label-key":"name","options":_vm.getDropdownOptions('start'),"placeholder":"Search Pages and Features...","searchable":"","value-key":"id"},on:{"select":function($event){return _vm.onFieldUpdated('start', $event)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_vm._v(" Select a Page or Feature ")]},proxy:true},{key:"noData",fn:function(){return [_vm._v(" There are no tagged Pages or Features for this App. ")]},proxy:true},{key:"selectedLabel",fn:function(ref){
                            var option = ref.option;
return [_c('pendo-icon-option',{attrs:{"option":option}})]}}])})],1),_c('div',{staticClass:"workflows-query-app-end",attrs:{"data-cy":"workflows-end-app-multiselect"}},[_c('pendo-form-item',{attrs:{"label":"Workflow End","prop":"end","rules":_vm.rules.end}},[_c('pendo-multiselect',{attrs:{"disabled":!_vm.canEditWorkflow,"full-width":"","value":_vm.appSelected.end,"options":_vm.appList,"value-key":"id","label-key":"displayName","placeholder":"Search Apps..."},on:{"select":function($event){return _vm.onAppSelected('end', $event)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_vm._v(" Select an App ")]},proxy:true},{key:"option",fn:function(ref){
                            var option = ref.option;
return [_c('pendo-icon-option',{attrs:{"option":option}})]}},{key:"selectedLabel",fn:function(ref){
                            var option = ref.option;
return [_c('pendo-icon-option',{attrs:{"option":option}})]}}])})],1)],1),_c('div',{staticClass:"workflows-query-countable workflows-query-countable-end",attrs:{"data-cy":"workflows-end-tag-multiselect"}},[_c('pendo-multiselect',{attrs:{"disabled":!_vm.appSelected.end || !_vm.canEditWorkflow,"full-width":"","group-options-key":"options","group-label-key":"key","label-key":"name","value":_vm.$workflows.workingWorkflow.end,"options":_vm.getDropdownOptions('end'),"placeholder":"Search Pages or Features...","searchable":"","value-key":"id"},on:{"select":function($event){return _vm.onFieldUpdated('end', $event)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_vm._v(" Select a Page or Feature ")]},proxy:true},{key:"noData",fn:function(){return [_vm._v(" There are no tagged Pages or Features for this App. ")]},proxy:true},{key:"selectedLabel",fn:function(ref){
                            var option = ref.option;
return [_c('pendo-icon-option',{attrs:{"option":option}})]}}])})],1)]),(!_vm.isReadOnly)?_c('hr',{staticClass:"workflows-query-divider"}):_vm._e(),(!_vm.isReadOnly)?_c('div',{staticClass:"workflows-query-visibility",attrs:{"data-cy":"workflows-visibility-multiselect"}},[_c('pendo-form-item',{attrs:{"label":"Visibility","prop":"shared"}},[_c('pendo-multiselect',{attrs:{"disabled":!_vm.canEditWorkflow || !_vm.canEditVisibility,"full-width":"","value":_vm.visibility,"options":_vm.visibilityList,"value-key":"id","label-key":"name"},on:{"select":function($event){return _vm.onFieldUpdated('shared', $event.id)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                            var option = ref.option;
return [_c('pendo-icon-option',{attrs:{"option":option}})]}},{key:"selectedLabel",fn:function(ref){
                            var option = ref.option;
return [_c('pendo-icon-option',{attrs:{"option":option}})]}}],null,false,1889016357)})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }