import Highcharts from 'highcharts';

export const CHART_COLORS = {
    green100: 'rgba(34, 153, 143, 1.0)',
    green50: 'rgba(34, 153, 143, 0.5)',
    green10: 'rgba(34, 153, 143, 0.1)'
};

export const chartColors = [
    '#229CA8',
    '#FF4876',
    '#8DDDB6',
    '#6A6C75',
    '#A973F2',
    '#EFBE53',
    '#07699B',
    '#65D036',
    '#E14CD3',
    '#FF9237'
];

Highcharts.setOptions({
    chart: {
        style: {
            fontFamily: 'Inter'
        },
        spacing: [32, 0, 0, 0]
    },
    credits: {
        enabled: false
    },
    title: {
        text: null
    },
    tooltip: {
        backgroundColor: '#FFFFFF',
        enabled: true,
        padding: 16,
        headerFormat: '<span style="font-size: 12px">{point.key}</span><br/>',
        style: {
            fontSize: '12px',
            color: '#2a2c35',
            fill: '#2a2c35',
            lineHeight: '19px'
        },
        crosshairs: true,
        hideDelay: 100
    },
    xAxis: {
        crosshair: {
            color: '#DADCE5'
        },
        gridLineColor: '#DADCE5',
        lineColor: '#DADCE5',
        labels: {
            overflow: 'justify',
            y: 32,
            style: {
                color: '#6A6C75',
                fontSize: '12px',
                padding: '16px'
            }
        },
        minPadding: 0,
        maxPadding: 0,
        tickLength: 0, // hide ticks by default
        title: {
            text: null
        }
    },
    yAxis: {
        gridLineColor: '#DADCE5',
        min: 0,
        title: {
            text: null
        },
        labels: {
            align: 'left',
            x: 0,
            y: -8
        },
        minPadding: 0,
        tickPixelInterval: 92,
        allowDecimals: false,
        softMax: 1
    },
    plotOptions: {
        column: {
            pointPadding: 0
        }
    },
    colors: [CHART_COLORS.green100, CHART_COLORS.green50, CHART_COLORS.green10]
});

export const SCATTER_CHART_BASE = {
    series: [],
    chart: {
        zoomType: 'xy',
        type: 'scatter'
    },
    tooltip: {
        headerFormat: '',
        formatter: null,
        padding: 10,
        crosshairs: false
    },
    yAxis: [
        {
            min: 0,
            crosshair: {
                width: 0
            },
            title: {},
            tickPixelInterval: 72
        }
    ],
    xAxis: [
        {
            title: {},
            crosshair: {
                width: 0
            },
            endOnTick: false,
            maxPadding: 0.05,
            type: 'linear',
            tickLength: 10,
            tickPixelInterval: 100,
            min: 0,
            allowDecimals: false
        }
    ]
};

export const LINE_CHART_BASE = {
    series: [],
    chart: {
        zoomType: 'xy',
        type: 'line'
    },
    tooltip: {
        headerFormat: '',
        formatter: null,
        padding: 10
    },
    yAxis: [
        {
            allowDecimals: false,
            min: 0,
            title: {}
        }
    ],
    xAxis: [
        {
            startOfWeek: 0,
            dateTimeLabelFormats: {
                day: '%b %d',
                week: '%b %e',
                month: '%b %Y'
            },
            type: 'datetime',
            labels: {
                style: {
                    whiteSpace: 'nowrap',
                    textOverflow: 'none'
                }
            }
        }
    ]
};

export default Highcharts;
