<template>
    <pendo-multiselect
        v-model="internalSelectedOptions"
        label-key="displayName"
        value-key="id"
        multiple
        :hide-selected-options="false"
        :searchable="false"
        :close-on-select="false"
        :min-menu-width="300"
        :max-menu-height="400"
        :options="filteredOptions"
        @click-outside="applySelections">
        <template
            slot="trigger"
            slot-scope="{ toggleMenu }">
            <pendo-button
                prefix-icon="plus"
                theme="app"
                type="link"
                :label="triggerLabel"
                @click="toggleMenu" />
        </template>
        <div
            slot="header"
            class="checkbox-select__header">
            <pendo-multiselect-input
                class="checkbox-select__input"
                :is-search-icon-visible="true"
                :computed-placeholder="searchLabel"
                :update-input-value="updateSearchString" />
        </div>
        <div
            slot="beforeList"
            slot-scope="{ model, removeSelection }"
            :class="{ empty: !model.length }"
            class="checkbox-select__selected">
            <div class="checkbox-select__selected-header">
                <div class="checkbox-select__selected-group-label">
                    Selected ({{ internalSelectedOptions.length }})
                </div>
                <pendo-button
                    v-if="model.length"
                    type="link"
                    label="Clear"
                    @click="clearSelections" />
            </div>
            <div class="checkbox-select__selected-options">
                <pendo-checkbox-option
                    v-for="(selectedOption, index) of internalSelectedOptions"
                    :key="index"
                    :option="selectedOption"
                    @click="removeSelection(selectedOption)" />
            </div>
        </div>
        <pendo-checkbox-option
            slot="option"
            slot-scope="{ option }"
            :option="option" />
        <div
            slot="footer"
            slot-scope="{ toggleMenu }"
            class="checkbox-select__footer">
            <pendo-button
                size="mini"
                type="tertiary"
                theme="via"
                label="Cancel"
                @click="cancelSelections(toggleMenu)" />
            <pendo-button
                size="mini"
                theme="via"
                label="Apply"
                @click="applySelections(toggleMenu)" />
        </div>
    </pendo-multiselect>
</template>
<script>
import capitalize from 'lodash/capitalize';
import isFunction from 'lodash/isFunction';
import { normalizeValue } from '@/utils/pages';
import { PendoMultiselect, PendoMultiselectInput, PendoCheckboxOption, PendoButton } from '@pendo/components';

export default {
    name: 'CheckboxSelect',
    components: {
        PendoMultiselect,
        PendoButton,
        PendoMultiselectInput,
        PendoCheckboxOption
    },
    props: {
        options: {
            type: Array,
            required: true
        },
        selectedOptions: {
            type: Array,
            default: () => []
        },
        entityType: {
            type: String,
            required: true
        },
        searchFilter: {
            type: Function,
            required: true
        }
    },
    data () {
        return {
            internalOptions: [],
            internalSelectedOptions: [],
            searchString: '',
            normalizedValue: ''
        };
    },
    computed: {
        triggerLabel () {
            if (this.selectedOptions.length) {
                return `Manage ${capitalize(this.entityType)}`;
            }

            return `Add ${capitalize(this.entityType)}`;
        },
        searchLabel () {
            return `Search ${capitalize(this.entityType)}`;
        },
        filteredOptions () {
            return this.searchFilter(this.internalOptions, this.normalizedValue);
        }
    },
    watch: {
        options (newOptions) {
            this.internalOptions = newOptions;
        },
        selectedOptions (newSelectedOptions) {
            this.internalSelectedOptions = newSelectedOptions;
        },
        async searchString (value) {
            this.normalizedValue = await normalizeValue(value);
        }
    },
    created () {
        this.internalOptions = this.options;
        this.internalSelectedOptions = this.selectedOptions;
    },
    methods: {
        resetInternalState () {
            // use nextTick to avoid flicker during the close animation of the dropdown
            this.$nextTick(() => {
                this.updateSearchString('');
            });
        },
        clearSelections () {
            this.internalSelectedOptions = [];
        },
        cancelSelections (toggleMenuFn) {
            this.internalSelectedOptions = this.selectedOptions;
            if (isFunction(toggleMenuFn)) toggleMenuFn();
            this.resetInternalState();
        },
        applySelections (toggleMenuFn) {
            this.$emit('select', this.internalSelectedOptions);
            if (isFunction(toggleMenuFn)) toggleMenuFn();
            this.resetInternalState();
        },
        updateSearchString (newString) {
            this.searchString = newString;
            this.normalizedValue = newString;
        }
    }
};
</script>
<style lang="scss" scoped>
.checkbox-select__header,
.checkbox-select__footer {
    padding: 16px;
}

.checkbox-select__selected-header {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-auto-flow: column;

    .pendo-button {
        font-size: 12px;
    }
}

.checkbox-select__input {
    padding: 0 8px;
    height: 34px;
    border: 1px solid $gray-lighter-6;
    border-radius: 3px;
    justify-content: start;
    box-sizing: border-box;
    grid-template-columns: 16px calc(100% - 26px);

    .pendo-multiselect__search-icon-wrapper {
        color: $gray-lighter-4;
    }
}

.checkbox-select__selected-group-label {
    font-weight: normal;
    font-size: 12px;
    display: block;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    cursor: default;
    color: $gray-lighter-2;
    margin-top: 8px;
    margin-bottom: 8px;
}

.checkbox-select__option,
.checkbox-select__selected-option {
    display: flex;
    align-items: center;
}

.checkbox-select__header,
.checkbox-select__selected {
    border-bottom: 1px solid $gray-lighter-6;
}

.checkbox-select__selected.empty {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.checkbox-select__selected {
    padding: 0 16px 8px;
    margin-bottom: 8px;
}

.checkbox-select__footer {
    border-top: 1px solid $gray-lighter-6;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    justify-content: end;
}
</style>
