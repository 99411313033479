<template>
    <guide-chart-card
        :is-fetching="isFetchingViewsByPeriod"
        data-cy="guide-activity-chart"
        class="guide-activity-chart">
        <template slot="header">
            <pendo-multiselect
                :value="selectedPeriod"
                :allow-empty="false"
                :options="periodOptions"
                @select="changeSelectedPeriod">
                <pendo-chart-trigger
                    slot="trigger"
                    theme="via" />
            </pendo-multiselect>
            <pendo-multiselect
                :value="selectedMetric"
                :allow-empty="false"
                :options="metricOptions"
                @select="changeSelectedMetric">
                <pendo-chart-trigger
                    slot="trigger"
                    theme="via" />
            </pendo-multiselect>
        </template>
        <template slot="filters">
            <pendo-tag
                :prefix-icon="firstTimePrefixIcon"
                :label="`First Time ${yAxisLabel}`"
                type="filter" />
            <pendo-tag
                :prefix-icon="repeatPrefixIcon"
                :label="`Repeat ${yAxisLabel}`"
                type="filter" />
        </template>
        <template slot="chart">
            <div
                ref="guide-activity"
                :class="{ empty: totalViews <= 0 }"
                class="pendo-highcharts-container" />
        </template>
        <template slot="summary">
            <guide-activity-segment-summary />
        </template>
    </guide-chart-card>
</template>

<script>
import { mapMutations, mapActions, mapGetters, mapState } from 'vuex';
import { PendoChartTrigger, PendoMultiselect, PendoTag } from '@pendo/components';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import GuideActivitySegmentSummary from '@/components/guides/metrics/GuideActivitySegmentSummary';
import GuideChartCard from '@/components/guides/metrics/GuideChartCard';
import Highcharts from '@/utils/highcharts';
import { getSubscriptionUtcOffset } from '@/utils/moment';
import { validPeriodsForCount } from '@/utils/time-series';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';

export default {
    name: 'GuideActivityChart',
    components: {
        GuideActivitySegmentSummary,
        GuideChartCard,
        PendoChartTrigger,
        PendoMultiselect,
        PendoTag
    },
    data () {
        return {
            chart: null,
            chartConfig: null,
            selectedPeriod: null
        };
    },
    computed: {
        ...mapState({
            guideId: (state) => state.guides.activeId,
            viewsByPeriod: (state) => state.guideAnalytics.viewsByPeriod,
            isFetchingViewsByPeriod: (state) => state.guideAnalytics.isFetchingViewsByPeriod,
            activeMetric: (state) => state.guideAnalytics.metric,
            activeTimePeriod: (state) => state.guideAnalytics.timePeriod,
            activeDateRange: (state) => state.filters.dateRange
        }),
        ...mapGetters({
            guide: 'guides/active'
        }),
        periodOptions () {
            const periods = validPeriodsForCount(this.activeDateRange.count);

            return periods.map((id) => {
                return {
                    label: capitalize(id),
                    id
                };
            });
        },
        selectedMetric () {
            return this.metricOptions.find((metric) => metric.id === this.activeMetric);
        },
        metricOptions () {
            return ['views', 'visitors'].map((id) => {
                return {
                    label: capitalize(id),
                    id
                };
            });
        },
        colors () {
            return ['#229CA8', '#0D3E42'];
        },
        firstTimePrefixIcon () {
            return {
                type: 'circle',
                stroke: this.colors[1],
                fill: this.colors[1],
                size: 12
            };
        },
        repeatPrefixIcon () {
            return {
                type: 'circle',
                stroke: this.colors[0],
                fill: this.colors[0],
                size: 12
            };
        },
        totalViews () {
            return this.viewsByPeriod.reduce((sum, period) => {
                return sum + period.total;
            }, 0);
        },
        yAxisLabel () {
            const metric = this.selectedMetric || this.metricOptions[0];

            return metric.label;
        },
        series () {
            return [
                {
                    name: `Repeat ${this.yAxisLabel}`,
                    data: this.viewsByPeriod.map((period) => {
                        return {
                            y: period.total - period.firstTime,
                            x: period.time
                        };
                    })
                },
                {
                    name: `First Time ${this.yAxisLabel}`,
                    data: this.viewsByPeriod.map((period) => {
                        return {
                            y: period.firstTime,
                            x: period.time
                        };
                    })
                }
            ];
        }
    },
    watch: {
        series () {
            if (!this.chart) return;
            this.chart.update({
                series: this.series,
                yAxis: {
                    title: {
                        text: `Guide ${this.yAxisLabel}`
                    }
                }
            });
        }
    },
    created () {
        this.changeSelectedPeriod({ id: this.activeTimePeriod });
        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, () => {
            this.changeSelectedPeriod({ id: this.activeTimePeriod });
        });
    },
    mounted () {
        this.initChart();
    },
    destroyed () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        ...mapMutations({
            setMetric: 'guideAnalytics/setMetric',
            setTimePeriod: 'guideAnalytics/setTimePeriod'
        }),
        ...mapActions({
            fetchGuideViewsByPeriod: 'guideAnalytics/fetchGuideViewsByPeriod'
        }),
        initChart () {
            this.chartConfig = this.getChartConfig();

            if (this.$refs['guide-activity']) {
                this.chart = Highcharts.chart(this.$refs['guide-activity'], this.chartConfig);
            }
        },
        async changeSelectedPeriod (selectedPeriod) {
            let timePeriod;
            const validPeriodOption = this.periodOptions.find((period) => period.id === selectedPeriod.id);
            if (validPeriodOption) {
                timePeriod = validPeriodOption;
            } else {
                [timePeriod] = this.periodOptions;
            }

            this.selectedPeriod = timePeriod;
            this.setTimePeriod({ timePeriod: timePeriod.id });
            await this.refreshChart();
        },
        async changeSelectedMetric (selectedMetric) {
            this.setMetric({ metric: selectedMetric.id });
            await this.refreshChart();
        },
        async refreshChart () {
            await this.fetchGuideViewsByPeriod({
                guideId: this.guideId,
                segmentId: get(this.guide, 'audienceUiHint.filters[0].segmentId', null),
                createdAt: this.guide.createdAt
            });
        },
        getChartConfig () {
            const vm = this;

            return {
                series: this.series,
                chart: {
                    type: 'column'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal'
                    }
                },
                legend: {
                    enabled: false
                },
                xAxis: {
                    startOfWeek: 0,
                    dateTimeLabelFormats: {
                        week: '%b %e',
                        month: '%b %Y',
                        day: '%b %d'
                    },
                    type: 'datetime'
                },
                yAxis: {
                    stackLabels: {
                        enabled: true,
                        style: {
                            color: '#BABCC5',
                            fontWeight: 'normal',
                            textOutline: null
                        },
                        formatter () {
                            return this.total !== 0 ? this.total : '';
                        }
                    },
                    title: {
                        text: `Guide ${this.yAxisLabel}`
                    },
                    min: 0,
                    allowDecimals: false
                },
                tooltip: {
                    useHTML: true,
                    formatter () {
                        const format = vm.activeTimePeriod === 'hourly' ? '%H:%M' : '%b %d';
                        let tooltip = vm.chart.time.dateFormat(format, this.x);
                        tooltip = `${tooltip}<br><b>${this.series.name}</b>: ${this.y}`;

                        return `${tooltip}<br>Total ${vm.yAxisLabel}: ${this.total}`;
                    }
                },
                colors: this.colors,
                time: {
                    timezoneOffset: getSubscriptionUtcOffset()
                }
            };
        }
    }
};
</script>

<style lang="scss">
.guide-activity-chart {
    .pendo-card__header {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 16px;
        justify-content: start;
    }
}
</style>
