<template>
    <forgot-password
        :email="model.email"
        :is-white-label="isWhiteLabel"
        @form-validated="sendResetPasswordLink"
        @on-input="updateInput">
        <template #logo>
            <slot name="logo" />
        </template>
        <template #nightLogo>
            <slot name="nightLogo" />
        </template>
    </forgot-password>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import get from 'lodash/get';
import { PendoNotification } from '@pendo/components';
import { ForgotPassword } from '@pendo/login';

export default {
    name: 'ResetPasswordForm',
    components: {
        ForgotPassword
    },
    data () {
        return {
            model: {
                email: ''
            }
        };
    },
    computed: {
        ...mapState({
            route: (state) => state.route,
            unauthenticatedTrainingAttributes: (state) => state.subscriptions.unauthenticatedTrainingAttributes
        }),
        isWhiteLabel () {
            return get(this.unauthenticatedTrainingAttributes, 'whiteLabelSettings');
        }
    },
    created () {
        if (this.route.query.email) {
            this.model.email = this.route.query.email;
        }
    },
    methods: {
        ...mapActions({
            _sendResetPasswordLinkV2: 'auth/sendResetPasswordLinkV2'
        }),
        updateInput (input) {
            this.model.email = input;
        },
        sendResetPasswordLink () {
            this._sendResetPasswordLinkV2(this.model);
            PendoNotification({
                type: 'success',
                message: 'An email with next steps has been sent.',
                duration: 10000,
                customClass: 'forgot-pw-success'
            });
        }
    }
};
</script>

<style lang="scss">
.el-notification.pendo-notification.forgot-pw-success {
    @media only screen and (max-width: 767px) {
        width: 80%;
    }
}
</style>
