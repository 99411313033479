<template>
    <pendo-modal
        :visible="visible"
        class="add-custom-page-modal"
        height="auto"
        title="Add Custom Pages"
        @close="closeModal">
        <template #body>
            <p class="add-custom-page-modal--description">
                The page list is a great way to keep track of visitor usage. If a page is not represented in your
                current list, input the URL of the page you’d like to add.
            </p>
            <p
                v-if="customTaggingDescription"
                class="add-custom-page-modal--description--custom">
                {{ customTaggingDescription }}
            </p>
            <pendo-divider stroke="#dadce5" />
            <pendo-form
                :call-validate="callValidate"
                :call-reset-fields="callResetFields"
                :form-defaults="model"
                :form-ref="formRef"
                @formValidated="handleValidForm"
                @invalidForm="handleInvalidForm"
                @fieldsReset="handleFieldsReset">
                <pendo-form-item
                    :rules="rules.customUrl"
                    label="Custom URL*"
                    prop="customUrl">
                    <pendo-input
                        v-model="model.customUrl"
                        maxlength="1000"
                        placeholder="https://" />
                </pendo-form-item>
            </pendo-form>
            <div class="add-custom-page-modal--body">
                <svg
                    class="url-bracket"
                    width="9"
                    height="40"
                    viewBox="0 0 9 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1 0H0V39V40H9V39H1V0Z"
                        fill="#DADCE5" />
                </svg>
                <pendo-input
                    v-model="model.pageDisplayName"
                    maxlength="1000"
                    placeholder="Page Name"
                    label="Page Display Name*" />
            </div>
            <pendo-alert
                v-if="addCustomPageError"
                class="add-custom-page-error"
                type="error">
                Something went wrong. Try again - if the problem persists contact Pendo support.
            </pendo-alert>
        </template>
        <template #footer>
            <pendo-button
                theme="via"
                type="secondary"
                label="Cancel"
                @click="closeModal" />
            <pendo-button
                :disabled="disableAddPageButton"
                theme="via"
                type="primary"
                label="Add Page"
                @click="validateForm" />
        </template>
    </pendo-modal>
</template>

<script>
import {
    PendoAlert,
    PendoButton,
    PendoDivider,
    PendoForm,
    PendoFormItem,
    PendoInput,
    PendoModal
} from '@pendo/components';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { isValidUrl, normalizeUrl } from '@/utils/utils';
import { createCustomChildPage } from '@/state/modules/pages.module';
import get from 'lodash/get';

export default {
    name: 'AddCustomChildPagesModal',
    components: {
        PendoAlert,
        PendoButton,
        PendoDivider,
        PendoForm,
        PendoFormItem,
        PendoInput,
        PendoModal
    },
    props: {
        eligibleParentPages: {
            type: Array,
            required: true
        },
        childPages: {
            type: Array,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            formRef: 'addCustomPageForm',
            model: {
                customUrl: '',
                pageDisplayName: ''
            },
            rules: {
                customUrl: [
                    {
                        validator: this.validateUrl,
                        trigger: 'blur'
                    }
                ]
            },
            parsedPageId: null,
            parsedParameter: null,
            callResetFields: false,
            callValidate: false,
            addCustomPageError: false
        };
    },
    computed: {
        ...mapGetters({
            activeSub: 'subscriptions/active'
        }),
        customTaggingDescription () {
            return get(this.activeSub, 'trainingAttributes.customPageInstructions');
        },
        disableAddPageButton () {
            return !this.model.customUrl || !this.model.pageDisplayName;
        }
    },
    methods: {
        ...mapActions({
            fetchSinglePageWithAnalytics: 'pages/fetchSinglePageWithAnalytics'
        }),
        ...mapMutations({
            setMapWithAnalyticsAtKey: 'pages/setMapWithAnalyticsAtKey'
        }),
        closeModal () {
            this.$emit('close');
            this.callResetFields = true;
            this.model.pageDisplayName = '';
            this.addCustomPageError = false;
        },
        validateForm () {
            this.callValidate = true;
        },
        handleValidForm () {
            this.callValidate = false;

            this.onAddCustomPage();
        },
        handleInvalidForm () {
            this.callValidate = false;
        },
        handleFieldsReset () {
            this.callResetFields = false;
        },
        async validateUrl (rule, value, callback) {
            if (!isValidUrl(value)) {
                callback(new Error('URL is invalid.'));
            } else {
                const normalized = await normalizeUrl(value);
                if (!this.doesUrlMatchAnyPage(normalized)) {
                    callback(new Error('URL does not match a tagged page.'));
                } else if (this.isDuplicateChildPage(normalized)) {
                    callback(new Error('This page has already been added.'));
                } else {
                    callback();
                }
            }
        },
        doesUrlMatchAnyPage (url) {
            return this.eligibleParentPages.some((page) => {
                return page.rules.find((rule) => {
                    const regexMatch = url.match(RegExp(rule.parsedRule));

                    // storing a potential match in local state
                    // to avoid extra future computation
                    if (!regexMatch || !regexMatch[1]) return false;

                    this.parsedPageId = page.id;
                    this.parsedParameter = regexMatch[1];

                    return true;
                });
            });
        },
        isDuplicateChildPage (url) {
            return this.childPages.some((page) => {
                return page.rules.find((rule) => {
                    return RegExp(rule.parsedRule).test(url);
                });
            });
        },
        async onAddCustomPage () {
            this.addCustomPageError = false;

            try {
                const displayName = this.model.pageDisplayName;
                const customPageInfo = await createCustomChildPage({
                    parentPageId: this.parsedPageId,
                    parameter: this.parsedParameter,
                    displayName
                });

                this.closeModal();
                const page = {
                    pageId: customPageInfo.childPageId,
                    displayName,
                    isChildPage: true,
                    ...customPageInfo
                };
                this.fetchSinglePageWithAnalytics({ page });
            } catch (err) {
                this.addCustomPageError = true;
            }
        }
    }
};
</script>

<style lang="scss">
.add-custom-page-modal {
    /deep/.el-dialog__body {
        padding-top: 12px;
    }

    &--description {
        margin-top: 0;

        &--custom {
            white-space: pre-line;
        }
    }

    &--body {
        display: flex;
        margin-top: 12px;

        .url-bracket {
            margin-right: 8px;
        }
    }

    .add-custom-page-error {
        margin-top: 24px;
    }
}
</style>
