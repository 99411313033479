<template>
    <page-header
        :title="name"
        :only-title="true"
        :show-banner="true"
        :specific-app="app"
        class="app-settings-header">
        <template #top>
            <div class="app-settings-header--back">
                <router-link to="/settings/subscription">
                    <pendo-button
                        theme="via"
                        type="link"
                        size="mini"
                        prefix-icon="chevron-left"
                        label="Back to Subscription Settings" />
                </router-link>
            </div>
        </template>
        <template #actions>
            <disable-extension-injection :app="app" />
        </template>
    </page-header>
</template>

<script>
import { PendoButton } from '@pendo/components';
import PageHeader from '@/components/PageHeader';
import DisableExtensionInjection from '@/components/settings/app-settings/DisableExtensionInjection';

export default {
    name: 'AppSettingsHeader',
    components: {
        DisableExtensionInjection,
        PageHeader,
        PendoButton
    },
    props: {
        app: {
            type: Object,
            required: true
        }
    },
    computed: {
        name () {
            return `${this.app.displayName} App Settings`;
        }
    }
};
</script>
