import { render, staticRenderFns } from "./AddCustomPagesModal.vue?vue&type=template&id=f67f9990&"
import script from "./AddCustomPagesModal.vue?vue&type=script&lang=js&"
export * from "./AddCustomPagesModal.vue?vue&type=script&lang=js&"
import style0 from "./AddCustomPagesModal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports