<template>
    <div
        v-if="canShowModule"
        class="resource-center-module"
        data-cy="rc-add-module-card"
        :class="{ 'is-selected': selected }">
        <pendo-card
            :subtitle="moduleDescription"
            shadow="never"
            :title="moduleName"
            :disabled="disabled">
            <template slot="media">
                <div class="icon-wrapper">
                    <pendo-icon :type="moduleIcon" />
                </div>
                <div class="check-icon-wrapper">
                    <i
                        v-if="selected"
                        class="el-icon el-icon-success" />
                </div>
            </template>
            <template slot="overlay">
                <pendo-button
                    v-if="!disableButton"
                    theme="app"
                    type="secondary"
                    :label="selected ? 'Deselect Module' : 'Select Module'"
                    @click="$emit('onToggle')" />
                <tooltip
                    v-if="disableButton"
                    :content="disableButton"
                    placement="top">
                    <pendo-button
                        class="is-disabled"
                        theme="app"
                        type="secondary"
                        :label="selected ? 'Deselect Module' : 'Select Module'" />
                </tooltip>
            </template>
        </pendo-card>
    </div>
</template>

<script>
import { PendoCard, PendoButton, PendoIcon } from '@pendo/components';
import { Tooltip } from 'element-ui';
import { BuildingBlockLayouts } from '@pendo/services/BuildingBlocks';

const { moduleIds } = BuildingBlockLayouts;

export default {
    name: 'ResourceCenterModule',
    components: {
        PendoCard,
        PendoButton,
        PendoIcon,
        Tooltip
    },
    props: {
        moduleName: {
            type: String,
            default: ''
        },
        moduleDescription: {
            type: String,
            default: ''
        },
        moduleIcon: {
            type: String,
            default: ''
        },
        moduleType: {
            type: String,
            default: ''
        },
        selected: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        // Disable just the button and display
        // a tooltip on it on hover.
        disableButton: {
            type: String,
            default: ''
        },
        preventCodeInjection: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        canShowModule () {
            if (this.moduleType === moduleIds.sandbox && this.preventCodeInjection) {
                return false;
            }

            return true;
        }
    }
};
</script>

<style scoped lang="scss">
.resource-center-module {
    /deep/ .pendo-card__media-content {
        height: 100%;
        width: 100%;
        padding: 8px;
    }

    .icon-wrapper {
        display: flex;
        height: 100%;

        .pendo-icon {
            width: 54px;
            height: 54px;
            margin: auto;
            stroke-width: 30px;

            /deep/ .pendo-icon__content {
                stroke-width: 1.5;
            }
        }
    }

    .check-icon-wrapper {
        position: absolute;
        left: 5px;
        top: 5px;
        height: -100%;
        width: 100%;

        .el-icon-success {
            font-size: 22px;

            &:before {
                color: $teal-primary;
            }
        }

        .circle-icon {
            position: absolute;

            circle {
                color: $teal-primary;
                fill: $teal-primary;
            }
        }

        .check-icon {
            position: absolute;
            width: 14px;
            height: 14px;
            stroke-width: 3;

            polyline {
                color: $white;
            }
        }
    }

    &.is-selected {
        /deep/.pendo-card {
            // don't grow 2px on the box shadow, it makes text jump
            // instead, change the border color, and pick up the other 1px via box-shadow
            box-shadow: 0 0 0 1px $teal-primary;
            border-color: $teal-primary;
        }
    }
}
</style>
