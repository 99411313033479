<template>
    <pendo-table
        v-pendo-loading:feather="isFetchingPageList"
        :data="pages"
        :columns="columns"
        :max-height="448"
        :resizable="true"
        condensed
        title="Top Pages"
        class="top-pages-table"
        @column-resize="onResizeColumn">
        <template #headerActions>
            <pendo-button
                theme="via"
                type="link"
                prefix-icon="page"
                label="View All Pages"
                @click="routeToPageList" />
        </template>
        <div
            slot="empty"
            class="top-pages-table--empty">
            <pendo-empty-state
                :icon="{ type: 'page', size: 32, 'stroke-width': 1.5 }"
                title="Pages not Found"
                description="Try clearing filters. Pages without view data will not appear." />
        </div>
    </pendo-table>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { PendoEmptyState, PendoButton, PendoTable, PendoLoading } from '@pendo/components';
import { formatDataByType } from '@/utils/table-formatters';
import compact from 'lodash/compact';
import cloneDeep from 'lodash/cloneDeep';

export default {
    name: 'TopPages',
    components: {
        PendoButton,
        PendoEmptyState,
        PendoTable
    },
    directives: {
        PendoLoading
    },
    computed: {
        ...mapGetters({
            pageList: 'pages/listWithAnalytics',
            usesMultiApp: 'subscriptions/usesMultiApp'
        }),
        ...mapState({
            isFetchingPageList: (state) => state.pages.isFetchingWithAnalytics,
            topPagesListUserSettings: (state) => state.pages.topPagesListColumns
        }),
        columns () {
            const columns = compact([
                {
                    prop: 'displayName',
                    label: 'Page',
                    minWidth: 200,
                    schema: 'string',
                    allowResize: true
                },
                this.usesMultiApp && {
                    prop: 'app.displayName',
                    minWidth: 200,
                    label: 'App',
                    schema: 'string',
                    allowResize: false
                },
                {
                    prop: 'pageLoads',
                    width: 150,
                    minWidth: 150,
                    label: 'Number of Views',
                    schema: 'integer',
                    allowResize: false,
                    formatter: (row) => formatDataByType('integer', row.pageLoads)
                }
            ]);
            if (!this.topPagesListUserSettings) {
                return columns;
            }

            return columns.map((column) => {
                const savedColumn = this.topPagesListUserSettings.find((col) => col.prop === column.prop);
                if (savedColumn && savedColumn.allowResize && savedColumn.width) {
                    column.width = savedColumn.width;
                }

                return column;
            });
        },
        pages () {
            const pages = cloneDeep(this.pageList);

            return pages.sort((a, b) => b.pageLoads - a.pageLoads).slice(0, 9);
        }
    },
    created () {
        this.fetchPages();
    },
    methods: {
        ...mapMutations({
            setTopPagesListColumns: 'pages/setTopPagesListColumns'
        }),
        ...mapActions({
            fetchPages: 'pages/fetchWithAnalytics',
            updateUserSetting: 'userSettings/updateAppNamespaceSetting'
        }),
        routeToPageList () {
            this.$router.push({ name: 'pagesDashboard' });
        },
        onResizeColumn (column) {
            const columns = cloneDeep(this.columns);
            const resizedColumn = columns.find((col) => col.column === column.prop);
            resizedColumn.width = column.width;

            this.updateUserSetting({
                name: 'topPagesListColumns',
                value: JSON.stringify(columns)
            });
            this.setTopPagesListColumns({ topPagesListColumns: columns });
        }
    }
};
</script>

<style lang="scss">
.top-pages-table {
    &--empty {
        height: 350px;
    }

    .pendo-table__active-count {
        display: none;
    }
}
</style>
