<template>
    <div class="guide-details-settings">
        <div class="guide-details--row">
            <guide-details-content-preview
                v-pendo-loading="loadingConfig"
                class="guide-details--content-preview"
                :is-localization-enabled="localizationEnabled" />
        </div>
        <div class="guide-details--row">
            <guide-details-activation
                v-pendo-loading="loadingConfig"
                class="guide-details--activation" />
            <guide-details-segment
                v-pendo-loading="loadingConfig"
                class="guide-details--segment" />
            <guide-details-schedule
                v-pendo-loading="loadingConfig"
                class="guide-details--schedule" />
            <guide-details-localization
                v-if="localizationEnabled"
                v-pendo-loading="loadingConfig"
                :guide="guide"
                class="guide-details--localization" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import get from 'lodash/get';
import { PendoLoading } from '@pendo/components';
import GuideDetailsActivation from '@/components/guides/settings/GuideDetailsActivation';
import GuideDetailsSchedule from '@/components/guides/settings/GuideDetailsSchedule';
import GuideDetailsContentPreview from '@/components/guides/settings/GuideDetailsContentPreview';
import GuideDetailsSegment from '@/components/guides/settings/GuideDetailsSegment';
import GuideDetailsLocalization from '@/components/guides/settings/GuideDetailsLocalization';

export default {
    name: 'GuideDetailsSettings',
    components: {
        GuideDetailsActivation,
        GuideDetailsContentPreview,
        GuideDetailsSchedule,
        GuideDetailsSegment,
        GuideDetailsLocalization
    },
    directives: {
        PendoLoading
    },
    computed: {
        ...mapGetters({
            guide: 'guides/active',
            appFromGuide: 'apps/appFromGuide'
        }),
        ...mapState({
            isUpdating: (state) => state.guides.isUpdating
        }),
        localizationEnabled () {
            const guideApp = this.appFromGuide(this.guide);

            return get(guideApp, 'applicationFlags.guideLocalization', false);
        },
        loadingConfig () {
            return {
                loading: this.isUpdating,
                type: 'feather',
                spinnerProps: {
                    background: 'rgba(255, 255, 255, 0.5)'
                }
            };
        }
    }
};
</script>

<style lang="scss">
.guide-details-settings {
    display: grid;
    grid-gap: 32px;

    .guide-details {
        &--row {
            display: grid;
            grid-gap: 32px;
            grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        }

        &--content-preview,
        &--activation,
        &--segment,
        &--schedule {
            .pendo-card__body {
                display: grid;
            }
        }
    }
}
</style>
