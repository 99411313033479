import { operators as o } from '@pendo/aggregations';
import { request } from '@/utils/aggregations';
import { pathAggregation, processResponse } from '@pendo/services/Paths';
import { parseSegmentIdForAggregation } from '@/utils/aggregations';

export async function getPath ({ path, aggregation }) {
    const spec = o.aggregation('path', aggregation);
    const { messages } = await request(spec, { rowsOnly: false });

    return processResponse(path, messages[0].rows);
}

export function buildPathSpec ({ timeSeries, config }) {
    config.segmentId = parseSegmentIdForAggregation(config.segmentId);

    return pathAggregation.build({ config, timeSeries });
}
