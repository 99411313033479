<template>
    <page-header
        title="Paths"
        class="paths-header"
        :show-banner="true">
        <template #actions>
            <pendo-button
                theme="via"
                type="primary"
                prefix-icon="plus"
                label="Create Path"
                @click="onCreatePathClick" />
        </template>
    </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import { PendoButton } from '@pendo/components';

export default {
    name: 'PathsHeader',
    components: {
        PendoButton,
        PageHeader
    },
    methods: {
        onCreatePathClick () {
            this.$router.push({ name: 'path', params: { id: 'new' } });
        }
    }
};
</script>
