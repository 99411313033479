<template>
    <pendo-table
        v-pendo-loading:feather="isFetchingFeatureList"
        :data="features"
        :columns="columns"
        :max-height="448"
        :resizable="true"
        condensed
        title="Top Features"
        class="top-features-table"
        @column-resize="onResizeColumn">
        <template #headerActions>
            <pendo-button
                theme="via"
                type="link"
                prefix-icon="feature"
                label="View All Features"
                @click="routeToFeatureList" />
        </template>
        <div
            slot="empty"
            class="top-features-table--empty">
            <pendo-empty-state
                :icon="{ type: 'feature', size: 32, 'stroke-width': 1.5 }"
                title="Features not Found"
                description="Try clearing filters. Features without view data will not appear." />
        </div>
    </pendo-table>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { PendoEmptyState, PendoButton, PendoTable, PendoLoading } from '@pendo/components';
import { formatDataByType } from '@/utils/table-formatters';
import compact from 'lodash/compact';
import cloneDeep from 'lodash/cloneDeep';

export default {
    name: 'TopFeatures',
    components: {
        PendoButton,
        PendoEmptyState,
        PendoTable
    },
    directives: {
        PendoLoading
    },
    computed: {
        ...mapGetters({
            featureList: 'features/listWithAnalytics',
            usesMultiApp: 'subscriptions/usesMultiApp'
        }),
        ...mapState({
            isFetchingFeatureList: (state) => state.features.isFetchingWithAnalytics,
            topFeaturesListUserSettings: (state) => state.features.topFeaturesListColumns
        }),
        columns () {
            const columns = compact([
                {
                    prop: 'displayName',
                    label: 'Feature',
                    minWidth: 200,
                    allowResize: true
                },
                this.usesMultiApp && {
                    prop: 'app.displayName',
                    minWidth: 200,
                    label: 'App',
                    schema: 'string',
                    allowResize: false
                },
                {
                    prop: 'numEvents',
                    width: 150,
                    minWidth: 150,
                    label: 'Number of Clicks',
                    schema: 'integer',
                    allowResize: false,
                    formatter: (row) => formatDataByType('integer', row.numEvents)
                }
            ]);
            if (!this.topFeaturesListUserSettings) {
                return columns;
            }

            return columns.map((column) => {
                const savedColumn = this.topFeaturesListUserSettings.find((col) => col.prop === column.prop);
                if (savedColumn && savedColumn.allowResize && savedColumn.width) {
                    column.width = savedColumn.width;
                }

                return column;
            });
        },
        features () {
            const features = cloneDeep(this.featureList);

            return features.sort((a, b) => b.numEvents - a.numEvents).slice(0, 9);
        }
    },
    created () {
        this.fetchFeatures();
    },
    methods: {
        ...mapMutations({
            setTopFeaturesListColumns: 'features/setTopFeaturesListColumns'
        }),
        ...mapActions({
            fetchFeatures: 'features/fetchWithAnalytics',
            updateUserSetting: 'userSettings/updateAppNamespaceSetting'
        }),
        routeToFeatureList () {
            this.$router.push({ name: 'featuresDashboard' });
        },
        onResizeColumn (column) {
            const columns = cloneDeep(this.columns);
            const resizedColumn = columns.find((col) => col.column === column.prop);
            resizedColumn.width = column.width;

            this.updateUserSetting({
                name: 'topFeaturesListColumns',
                value: JSON.stringify(columns)
            });
            this.setTopFeaturesListColumns({ topFeaturesListColumns: columns });
        }
    }
};
</script>

<style lang="scss">
.top-features-table {
    &--empty {
        height: 350px;
    }

    .pendo-table__active-count {
        display: none;
    }
}
</style>
