<template>
    <modal
        ref="viaModal"
        :class="[{ modal: modalAttrs.modal }]"
        :classes="['pendo-modal', 'via']"
        v-bind="modalAttrs"
        name="via-modal"
        @before-open="beforeOpened"
        @before-close="beforeClosed"
        @opened="opened"
        @closed="closed">
        <div
            v-if="title"
            class="pendo-modal__header">
            <span class="pendo-modal__title">
                {{ title }}
            </span>
            <pendo-icon
                type="x"
                size="20"
                class="pendo-modal__close-btn"
                @click="$modal.hide('via-modal')" />
        </div>
        <div
            v-if="body || bodyComponent"
            class="pendo-modal__body">
            <span v-if="body">
                {{ body }}
            </span>
            <component
                :is="bodyComponent"
                v-if="bodyComponent"
                v-bind="componentProps" />
        </div>
        <div
            v-if="buttons.length"
            class="pendo-modal__footer">
            <pendo-button
                v-for="(button, index) in buttons"
                :key="`pendo-modal-button-${index}`"
                v-bind="button"
                theme="via"
                @click.stop="handleClick(index, $event)" />
        </div>
        <!-- when passing component, the component is responsible for body/footer sections of modal  -->
        <component
            :is="component"
            v-if="component"
            v-bind="componentProps" />
    </modal>
</template>
<script>
import get from 'lodash/get';
import { PendoButton, PendoIcon } from '@pendo/components';
import InviteNewUserModal from '@/components/settings/InviteNewUserModal';
import RemoveUserModal from '@/components/settings/RemoveUserModal';
import SettingsChangePasswordModal from '@/components/settings/ChangePasswordModal';
import LaunchDesignerModal from '@/components/LaunchDesignerModal';
import LaunchPreviewModal from '@/components/LaunchPreviewModal';
import ClearGuideDataModal from '@/components/ClearGuideDataModal';
import ItemDeleteWarning from '@/components/ItemDeleteWarning';
import GuideContentValidation from '@/components/guides/list/GuideContentValidation';
import ScheduledStatusModalBody from '@/components/guides/common/ScheduledStatusModalBody';

export const MODAL_DEFAULTS = {
    component: null,
    componentProps: {},
    title: null,
    body: null,
    buttons: [],
    height: 'auto',
    minWidth: 0,
    minHeight: 0,
    maxWidth: Infinity,
    maxHeight: Infinity,
    pivotX: 0.5,
    pivotY: 0.5,
    reset: true,
    adaptive: true,
    clickToClose: false,
    transition: 'fade',
    width: 400,
    modal: true
};

export default {
    name: 'ViaModal',
    components: {
        PendoButton,
        PendoIcon,
        InviteNewUserModal,
        RemoveUserModal,
        SettingsChangePasswordModal,
        LaunchDesignerModal,
        LaunchPreviewModal,
        ClearGuideDataModal,
        ItemDeleteWarning,
        GuideContentValidation,
        ScheduledStatusModalBody
    },
    data () {
        return {
            modalAttrs: {
                ...MODAL_DEFAULTS
            }
        };
    },
    computed: {
        title () {
            return get(this.modalAttrs, 'title', null);
        },
        body () {
            return get(this.modalAttrs, 'body', null);
        },
        buttons () {
            return get(this.modalAttrs, 'buttons', null);
        },
        component () {
            return get(this.modalAttrs, 'component', null);
        },
        componentProps () {
            return get(this.modalAttrs, 'componentProps', null);
        },
        bodyComponent () {
            return get(this.modalAttrs, 'bodyComponent', null);
        }
    },
    methods: {
        async beforeOpened (event) {
            this.toggleScrollBlock('add');
            this.modalAttrs = {
                ...this.modalAttrs,
                ...event.params
            };
            await this.$nextTick();
            // calls the vue-js-modal fn to update bound size attributes
            // (width, height, min and max height/width)
            this.$refs.viaModal.setInitialSize();

            this.$emit('before-opened', this.modalAttrs);
        },
        opened (event) {
            this.$emit('opened', event);
        },
        beforeClosed (event) {
            this.toggleScrollBlock('remove');
            this.$emit('before-closed', event);
        },
        closed (event) {
            this.$emit('closed', event);
            this.modalAttrs = {
                ...MODAL_DEFAULTS
            };
        },
        handleClick (index, event, source = 'click') {
            const button = this.buttons[index];
            if (button && typeof button.handler === 'function') {
                button.handler(index, event, { source });
            } else {
                this.$modal.hide('via-modal');
            }
        },
        toggleScrollBlock (next) {
            document.body.classList[next]('v--modal-block-scroll');
        }
    }
};
</script>
<style lang="scss">
.pendo-modal {
    &.via {
        text-align: left;
        border-radius: 3px;
        padding: 0;
        box-shadow: $modal-box-shadow;
        background: $white;
    }

    &__header {
        display: grid;
        grid-template-columns: 1fr 60px;
        align-items: center;
        border-bottom: $modal-border;
    }

    &__close-btn {
        cursor: pointer;
        color: $gray-lighter-2;
        display: grid;
        justify-content: center;
        transition: color 200ms;

        &:focus,
        &:hover {
            color: $gray-primary;
        }
    }

    &__title {
        padding: $modal-header-padding;
        line-height: $modal-header-line-height;
        font-size: $modal-header-font-size;
        font-weight: $modal-header-font-weight;
        display: grid;
        align-items: center;
    }

    &__body {
        padding: $modal-body-padding;
        line-height: 1.4;
        font-size: $modal-body-font-size;
    }

    &__footer {
        padding: $modal-footer-padding;
        text-align: right;
        border-top: 1px solid $gray-lighter-5;
    }
}
</style>
