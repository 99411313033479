<template>
    <svg
        width="213px"
        height="35px"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 727.8 105.4"
        style="enable-background:new 0 0 727.8 105.4;"
        xml:space="preserve">
        <title>Adopt</title>
        <g enable-background="new">
            <path
                :fill="fill"
                d="M522.2,68.1h-30.4l-5.3,13.3h-8.3l24.1-60h9.3l24.2,60h-8.3L522.2,68.1z M494,61.4h26L507,28L494,61.4z" />
            <path
                :fill="fill"
                d="M576.6,74.9c-3.2,4.4-8.5,7.6-14.7,7.6c-11.3,0-19.3-8.6-19.3-22.8c0-13.9,7.9-22.9,19.3-22.9
                c5.8,0,11.2,2.9,14.7,7.6V21.4h6.7v60h-6.7V74.9z M576.6,49.9c-2.4-3.8-7.6-7-13-7c-8.7,0-13.9,7.2-13.9,16.8
                c0,9.6,5.2,16.7,13.9,16.7c5.4,0,10.6-3.1,13-6.8V49.9z" />
            <path
                :fill="fill"
                d="M596.1,59.6c0-12.6,8.3-22.8,21.5-22.8s21.5,10.2,21.5,22.8c0,12.6-8.3,22.9-21.5,22.9S596.1,72.2,596.1,59.6z
                M632,59.6c0-8.8-5.1-16.7-14.4-16.7s-14.5,7.9-14.5,16.7c0,8.9,5.2,16.8,14.5,16.8S632,68.5,632,59.6z" />
            <path
                :fill="fill"
                d="M651.9,97.9v-60h6.7v6.5c3.1-4.4,8.5-7.6,14.7-7.6c11.4,0,19.3,8.6,19.3,22.8c0,14-7.9,22.9-19.3,22.9
                c-5.9,0-11.2-2.8-14.7-7.6v23.1H651.9z M671.6,42.9c-5.3,0-10.6,3.1-13,6.9v19.6c2.3,3.8,7.6,7,13,7c8.7,0,13.9-7.2,13.9-16.8
                C685.6,50,680.4,42.9,671.6,42.9z" />
            <path
                :fill="fill"
                d="M706.7,72.3V43.9h-7.2v-5.9h7.2V26h6.7v11.9h8.8v5.9h-8.8v27c0,3.2,1.4,5.6,4.4,5.6c1.9,0,3.7-0.8,4.6-1.8l2,5
                c-1.7,1.6-4.1,2.8-8.1,2.8C709.9,82.5,706.7,78.8,706.7,72.3z" />
        </g>
        <path
            :fill="fill"
            d="M140.8,22.7c-7.3,0-14.4,2.7-19.8,7.8v-7.8h-9.4V101h9.4V73.2c11.8,10.9,30.2,10.2,41.2-1.6s10.2-30.2-1.6-41.2
            C155.2,25.5,148.1,22.7,140.8,22.7z M160.6,51.8c0,10.9-8.9,19.8-19.8,19.8S121,62.8,121,51.9s8.9-19.8,19.8-19.8c0,0,0,0,0,0
            C151.7,32.1,160.6,40.9,160.6,51.8z" />
        <path
            :fill="fill"
            d="M206.3,22.6c-16.1,0-29.1,13-29.1,29.1c0,16.1,13,29.1,29.1,29.1c0,0,0,0,0,0c9,0,17.2-3.4,22.6-9.2l2.8-3
            l0.4-0.4l-0.4-0.4l-6.1-5.6l-0.4-0.4l-0.4,0.4l-2.8,3c-3.6,3.9-9.5,6.2-15.8,6.2c-9.1,0-17-6.2-19.2-15.1h47.6v-4.7
            C234.6,35.5,222.2,22.6,206.3,22.6z M187.1,47.1c2.2-8.8,10.1-15.1,19.2-15.1c9,0,16.7,6.3,18.5,15.1H187.1z" />
        <path
            :fill="fill"
            d="M270,22.6c-6.7,0-13.1,2.6-18,7.1v-7.1h-9.4v58.7h9.4v-30c0-10.7,8.1-19.4,18-19.4s18.1,8.7,18.1,19.4v30h9.4
            v-30C297.5,35.5,285.2,22.6,270,22.6z" />
        <path
            :fill="fill"
            d="M354.9,3h-0.5v27.7c-5.4-5-12.4-7.8-19.8-7.8c-16.1,0-29.1,13-29.1,29.1c0,16.1,13,29.1,29.1,29.1c0,0,0,0,0,0
            c7.3,0,14.4-2.8,19.8-7.8v7.8h9.4V3H354.9z M354.3,52.2c0,10.9-8.9,19.8-19.8,19.8s-19.8-8.9-19.8-19.8s8.9-19.8,19.8-19.8
            c0,0,0,0,0,0C345.5,32.4,354.3,41.3,354.3,52.2z" />
        <path
            :fill="fill"
            d="M400.9,23c-16.1,0-29.1,13-29.1,29.1s13,29.1,29.1,29.1s29.1-13,29.1-29.1C430,36.1,417,23,400.9,23z
            M420.7,52.1c0,10.9-8.8,19.8-19.7,19.8s-19.8-8.8-19.8-19.7s8.8-19.8,19.7-19.8c0,0,0,0,0,0C411.8,32.4,420.7,41.3,420.7,52.1
            L420.7,52.1z" />

        <component
            :is="activeChevron"
            :x="2"
            :y="13"
            :width="88"
            :height="88" />
    </svg>
</template>

<script>
import { CHEVRONS, activeChevronMixin } from './ActiveChevronMixin';

export default {
    components: {
        ...CHEVRONS
    },
    mixins: [activeChevronMixin],
    props: {
        textColor: {
            type: String,
            default: 'dark',
            validator: (textColor) => ['light', 'dark'].includes(textColor)
        }
    },
    computed: {
        fill () {
            if (this.textColor === 'light') {
                return '#FFFFFF';
            }

            return '#2a2c35';
        }
    }
};
</script>
