<template>
    <div class="segment-summary">
        <header
            v-if="showName"
            class="segment-summary--header">
            <span class="segment-summary--title">
                Segment
            </span>
            <span class="segment-summary--header--name">
                {{ segmentIsEmpty(segment) ? 'Unknown' : segment.name }}
            </span>
        </header>
        <main class="segment-summary--rule-set">
            <span class="segment-summary--title">
                Rules Summary
            </span>
            <div v-if="segmentIsEmpty(segment)">
                <pendo-alert type="warning">
                    This Segment used by this Guide was deleted. This Guide will not be displayed to any Visitors until
                    the Segment is changed.
                </pendo-alert>
            </div>
            <div v-if="!segmentIsEmpty(segment)">
                <div
                    v-for="(rule, index) in summary"
                    :key="`segment-rule-${index}`"
                    class="segment-summary--rule">
                    <div
                        v-for="(orRule, orIndex) in rule"
                        :key="`segment-rule-${index}-${orIndex}`"
                        class="segment-summary--or-rule">
                        <div class="segment-summary--or-rule--summary">
                            <pendo-icon
                                v-if="orRule.schema"
                                :type="getIconFromSchema(orRule.schema)"
                                class="segment-summary--or-rule--summary--icon"
                                size="12" />
                            <span>
                                {{ orRule.text }}
                            </span>
                        </div>
                        <div
                            v-if="orIndex < rule.length - 1"
                            class="segment-summary--or-rule--divider">
                            OR
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { PendoIcon, PendoAlert } from '@pendo/components';
import { parseSegmentSummary } from '@/utils/segments';

export default {
    name: 'SegmentSummary',
    components: {
        PendoIcon,
        PendoAlert
    },
    props: {
        segment: {
            type: Object,
            required: false,
            default: null
        },
        showName: {
            type: Boolean,
            required: false,
            default: true
        },
        guideMap: {
            type: Object,
            default: () => ({})
        },
        pageMap: {
            type: Object,
            default: () => ({})
        },
        featureMap: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        summary () {
            return parseSegmentSummary(this.segment, this.guideMap, this.pageMap, this.featureMap);
        }
    },
    methods: {
        getIconFromSchema (schema) {
            let icon = '';

            switch (schema) {
                case 'poll':
                    icon = 'list';
                    break;
                case 'time':
                    icon = 'calendar';
                    break;
                case 'page':
                    icon = 'page';
                    break;
                case 'feature':
                    icon = 'feature';
                    break;
                case 'string':
                default:
                    icon = 'users';
                    break;
            }

            return icon;
        },
        segmentIsEmpty (segmentProp) {
            return isEmpty(segmentProp);
        }
    }
};
</script>

<style lang="scss">
.segment-summary {
    &--header {
        margin-bottom: 1em;
    }

    &--title {
        font-weight: bold;
        display: block;
        margin-bottom: 0.5em;
    }

    &--rule {
        display: flex;
        flex-flow: column nowrap;

        &::after {
            content: 'AND';
            display: block;
            margin-top: 1em;
            margin-bottom: 1.2em;
            font-size: 0.875em;
        }

        &:last-of-type::after {
            content: '';
            display: none;
        }
    }

    &--or-rule {
        margin-bottom: 0.5em;

        &:last-of-type {
            margin-bottom: 0;
        }

        &--summary {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            margin-bottom: 0.25em;

            &--icon {
                margin-right: 0.5em;
            }
        }

        &--divider {
            font-size: 0.875em;
        }
    }
}
</style>
