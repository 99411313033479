import uuid from 'uuid';

export const operatorRequiresUnitCountFormat = {
    withinLast: true,
    '!withinLast': true
};

// keys match `/api/s/_SID_/metadata/:kind/schema` schema obj.schema
const numberOperators = [
    {
        id: uuid(),
        value: '==',
        label: 'equals'
    },
    {
        id: uuid(),
        value: '!=',
        label: 'does not equal'
    },
    {
        id: uuid(),
        value: '>=',
        label: 'greater than or equal to'
    },
    {
        id: uuid(),
        value: '<=',
        label: 'less than or equal to'
    }
];

export const SEGMENT_OPERATORS = {
    integer: numberOperators,
    float: numberOperators,
    time: [
        {
            id: uuid(),
            value: '<=',
            label: 'before',
            multivalue: false
        },
        {
            id: uuid(),
            value: '>=',
            label: 'since',
            multivalue: false
        },
        {
            id: uuid(),
            value: 'between',
            label: 'between',
            multivalue: true
        },
        {
            id: uuid(),
            value: 'withinLast',
            label: 'within last',
            multivalue: true
        },
        {
            id: uuid(),
            value: '!withinLast',
            label: 'not within last',
            multivalue: true
        }
    ],
    string: [
        {
            id: uuid(),
            value: 'contains',
            label: 'contains'
        },
        {
            id: uuid(),
            value: '!contains',
            label: 'does not contain'
        },
        {
            id: uuid(),
            value: '==',
            label: 'equals'
        },
        {
            id: uuid(),
            value: '!=',
            label: 'does not equal'
        },
        {
            id: uuid(),
            value: 'listContains',
            label: 'csv contains'
        },
        {
            id: uuid(),
            value: '!listContains',
            label: 'csv does not contain'
        }
    ],
    boolean: [
        {
            id: uuid(),
            value: '==',
            unary: true,
            label: 'is true'
        },
        {
            id: uuid(),
            value: '!=',
            unary: true,
            label: 'is false'
        }
    ],
    list: [
        {
            id: uuid(),
            value: 'contains',
            label: 'contains'
        },
        {
            id: uuid(),
            value: '!contains',
            label: 'does not contain'
        }
    ],
    guide: [
        {
            id: uuid(),
            value: 'viewed',
            label: 'seen'
        },
        {
            id: uuid(),
            value: 'notviewed',
            label: 'not seen'
        }
    ],
    page: [
        {
            id: uuid(),
            value: 'viewed',
            label: 'seen'
        },
        {
            id: uuid(),
            value: 'notviewed',
            label: 'not seen'
        }
    ],
    feature: [
        {
            id: uuid(),
            value: 'clicked',
            label: 'clicked'
        },
        {
            id: uuid(),
            value: 'notclicked',
            label: 'not clicked'
        }
    ],
    poll: [
        {
            id: uuid(),
            value: '==',
            label: 'equals'
        },
        {
            id: uuid(),
            value: '!=',
            label: 'does not equal'
        },
        {
            id: uuid(),
            value: 'responded',
            label: 'responded'
        },
        {
            id: uuid(),
            value: 'notresponded',
            label: 'not responded'
        }
    ],
    application: [
        {
            id: uuid(),
            value: 'used',
            label: 'used'
        },
        {
            id: uuid(),
            value: 'notused',
            label: 'not used'
        }
    ]
};

export const PAGE_TIME_RANGE_OPTIONS = [
    { label: 'ever', value: 'ever' },
    { label: 'within last', value: 'withinlast' },
    { label: 'since', value: 'since' }
];

export const FEATURE_TIME_RANGE_CLICKED_OPTIONS = [
    { label: 'ever', value: 'ever' },
    { label: 'within last', value: 'withinlast' },
    { label: 'since', value: 'since' },
    { label: 'at least', value: 'atleast' },
    { label: 'at most', value: 'atmost' }
];

export const FEATURE_TIME_RANGE_NOT_CLICKED_OPTIONS = [
    { label: 'ever', value: 'ever' },
    { label: 'within last', value: 'withinlast' },
    { label: 'since', value: 'since' }
];

export const APP_USAGE_TIME_RANGE_OPTIONS = [
    { label: 'ever', value: 'ever' },
    { label: 'within last', value: 'withinlast' },
    { label: 'since', value: 'since' }
];

export const DEFAULT_SEGMENT_IDS_MAP = {
    _VIA_DEFAULT_FV0to30: true,
    _VIA_DEFAULT_FV30to60: true,
    _VIA_DEFAULT_FV60to90: true,
    _VIA_DEFAULT_FVover30: true,
    _PENDO_DEFAULT_FV0to30: true,
    _PENDO_DEFAULT_FVover30: true,
    _PENDO_DEFAULT_FV30to60: true,
    _PENDO_DEFAULT_FV60to90: true,
    _PENDO_DEFAULT_BrChrome: true,
    _PENDO_DEFAULT_BrSafari: true,
    _PENDO_DEFAULT_BrMSIE: true
};
