<template>
    <pendo-collapse-item
        :name="`app--${app.uid}`"
        :class="{ 'is-active': isActive }"
        :collapsible="hasMultipleAccounts"
        :suffix-icon="hasMultipleAccounts"
        @click="updateApp(null)">
        <template #prefix>
            <div
                :style="{ color, backgroundColor }"
                class="app--icon">
                {{ appIcon }}
            </div>
        </template>
        <template #title>
            <div class="app--details">
                <div class="app--details--name">
                    {{ app.displayName }}
                </div>
                <div
                    v-if="accountName && !activeIsDigitalAdoption"
                    class="app--details--account-name">
                    {{ accountName }}
                </div>
            </div>
        </template>
        <ul class="account-list">
            <li
                v-for="account in accounts"
                :key="account.uid"
                :class="{
                    'active-account': isActive && activeAccount.id === account.id
                }"
                class="account-list--account"
                @click="updateApp(account.id)">
                <span
                    :title="account.displayName || account.id"
                    class="account-list--account--name">
                    {{ account.displayName || account.id }}
                </span>
            </li>
        </ul>
    </pendo-collapse-item>
</template>

<script>
import head from 'lodash/head';
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import { PendoCollapseItem } from '@pendo/components';
import { getContrastingTextColor } from '@/utils/whitelabel';

export default {
    name: 'AppSelectRow',
    components: {
        PendoCollapseItem
    },
    props: {
        app: {
            type: Object,
            required: true
        },
        isActive: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            getAccountList: 'subscriptions/getAccountList',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            activeAccount: 'subscriptions/activeAccount'
        }),
        accounts () {
            return this.getAccountList(this.app);
        },
        accountName () {
            if (!this.hasMultipleAccounts) {
                return this.accounts[0].displayName || this.accounts[0].id;
            }

            return null;
        },
        appIcon () {
            return head(this.app.displayName);
        },
        hasMultipleAccounts () {
            return this.accounts.length > 1;
        },
        backgroundColor () {
            return get(this.app, 'whiteLabelSettings.primaryColor', '#2a2c35');
        },
        color () {
            return getContrastingTextColor(this.backgroundColor);
        }
    },
    methods: {
        updateApp (accountId) {
            const { app } = this;
            if (this.hasMultipleAccounts && !accountId) {
                return;
            }

            let account;
            if (accountId) {
                account = this.accounts.find((account) => account.id === accountId);
            }
            if (!account) {
                account = head(this.accounts);
            }

            this.$emit('app-selected', { app, accountId: account.id });
        }
    }
};
</script>

<style lang="scss" scoped>
.app--icon {
    font-weight: 700;
    width: 32px;
    height: 32px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(42, 44, 53, 0.3);
    font-size: 1rem;
    text-transform: uppercase;
}

.app--details {
    overflow: hidden;
}

.app--details--name {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.1875rem;
    @include ellipsis;
}

.app--details--account-name {
    @include ellipsis;
    font-size: 0.875rem;
    line-height: 1.0875rem;
}

.account-list {
    @include ul-reset();
    padding: 8px 0;

    &--account {
        line-height: 2.45em;
        font-weight: 600;
        padding: 0 16px;
        font-size: 0.875rem;
        @include ellipsis;

        &:hover {
            cursor: pointer;
            background-color: $gray-lighter-6;
        }

        &.active-account {
            background-color: $gray-lighter-6;
        }
    }
}
</style>
