<template>
    <pendo-form
        :form-defaults="$workflows.workingWorkflow"
        form-ref="workflow-settings">
        <div class="workflows-query-container">
            <div
                class="workflows-query-datepicker"
                data-cy="workflows-datepicker-multiselect">
                <pendo-form-item label="Date Range">
                    <date-range-picker
                        :disabled="!canEditWorkflow"
                        :value="$workflows.workingWorkflow.dateRange"
                        full-width
                        @change="onFieldUpdated('dateRange', $event.dateRange)" />
                </pendo-form-item>
            </div>

            <div
                class="workflows-query-segment-chooser"
                data-cy="workflows-segment-multiselect">
                <pendo-form-item label="Segment">
                    <segment-chooser
                        :disabled="!canEditWorkflow"
                        full-width
                        :guide-targeting="false"
                        :value="$workflows.workingWorkflow.segment"
                        @input="onFieldUpdated('segment', $event.id)" />
                </pendo-form-item>
            </div>

            <div
                class="workflows-query-max-time"
                data-cy="workflows-max-time-multiselect">
                <pendo-form-item>
                    <pendo-multiselect
                        class="workflows-query-max-time--dropdown"
                        :disabled="!canEditWorkflow"
                        full-width
                        :options="maxDurationOptions"
                        value-key="value"
                        label-key="label"
                        :value="$workflows.workingWorkflow.maxDuration"
                        @select="onFieldUpdated('maxDuration', $event)">
                        <template #option="{ option }">
                            <pendo-icon-option :option="option" />
                        </template>

                        <template #selectedLabel="{ option }">
                            <pendo-icon-option :option="option" />
                        </template>
                        <template slot="topLabel">
                            Maximum Time Between Steps
                            <pendo-icon
                                v-pendo-tooltip="{
                                    trigger: 'hover',
                                    arrow: true,
                                    content: maxDurationTooltipText,
                                    multiline: true
                                }"
                                size="14"
                                display="inline"
                                type="info" />
                        </template>
                    </pendo-multiselect>
                </pendo-form-item>
            </div>

            <div
                class="workflows-query-app-start"
                data-cy="workflows-start-app-multiselect">
                <pendo-form-item
                    label="Workflow Start"
                    prop="start"
                    :rules="rules.start">
                    <pendo-multiselect
                        :disabled="!canEditWorkflow"
                        full-width
                        :value="appSelected.start"
                        :options="appList"
                        value-key="id"
                        label-key="displayName"
                        placeholder="Search Apps..."
                        @select="onAppSelected('start', $event)">
                        <template #placeholder>
                            Select an App
                        </template>

                        <template #option="{ option }">
                            <pendo-icon-option :option="option" />
                        </template>

                        <template #selectedLabel="{ option }">
                            <pendo-icon-option :option="option" />
                        </template>
                    </pendo-multiselect>
                </pendo-form-item>
            </div>

            <div
                class="workflows-query-countable workflows-query-countable-start"
                data-cy="workflows-start-tag-multiselect">
                <pendo-multiselect
                    :disabled="!appSelected.start || !canEditWorkflow"
                    full-width
                    group-options-key="options"
                    group-label-key="key"
                    :value="$workflows.workingWorkflow.start"
                    label-key="name"
                    :options="getDropdownOptions('start')"
                    placeholder="Search Pages and Features..."
                    searchable
                    value-key="id"
                    @select="onFieldUpdated('start', $event)">
                    <template #placeholder>
                        Select a Page or Feature
                    </template>

                    <template #noData>
                        There are no tagged Pages or Features for this App.
                    </template>

                    <template #selectedLabel="{ option }">
                        <pendo-icon-option :option="option" />
                    </template>
                </pendo-multiselect>
            </div>

            <div
                class="workflows-query-app-end"
                data-cy="workflows-end-app-multiselect">
                <pendo-form-item
                    label="Workflow End"
                    prop="end"
                    :rules="rules.end">
                    <pendo-multiselect
                        :disabled="!canEditWorkflow"
                        full-width
                        :value="appSelected.end"
                        :options="appList"
                        value-key="id"
                        label-key="displayName"
                        placeholder="Search Apps..."
                        @select="onAppSelected('end', $event)">
                        <template #placeholder>
                            Select an App
                        </template>

                        <template #option="{ option }">
                            <pendo-icon-option :option="option" />
                        </template>

                        <template #selectedLabel="{ option }">
                            <pendo-icon-option :option="option" />
                        </template>
                    </pendo-multiselect>
                </pendo-form-item>
            </div>

            <div
                class="workflows-query-countable workflows-query-countable-end"
                data-cy="workflows-end-tag-multiselect">
                <pendo-multiselect
                    :disabled="!appSelected.end || !canEditWorkflow"
                    full-width
                    group-options-key="options"
                    group-label-key="key"
                    label-key="name"
                    :value="$workflows.workingWorkflow.end"
                    :options="getDropdownOptions('end')"
                    placeholder="Search Pages or Features..."
                    searchable
                    value-key="id"
                    @select="onFieldUpdated('end', $event)">
                    <template #placeholder>
                        Select a Page or Feature
                    </template>

                    <template #noData>
                        There are no tagged Pages or Features for this App.
                    </template>

                    <template #selectedLabel="{ option }">
                        <pendo-icon-option :option="option" />
                    </template>
                </pendo-multiselect>
            </div>
        </div>

        <hr
            v-if="!isReadOnly"
            class="workflows-query-divider">

        <div
            v-if="!isReadOnly"
            class="workflows-query-visibility"
            data-cy="workflows-visibility-multiselect">
            <pendo-form-item
                label="Visibility"
                prop="shared">
                <pendo-multiselect
                    :disabled="!canEditWorkflow || !canEditVisibility"
                    full-width
                    :value="visibility"
                    :options="visibilityList"
                    value-key="id"
                    label-key="name"
                    @select="onFieldUpdated('shared', $event.id)">
                    <template #option="{ option }">
                        <pendo-icon-option :option="option" />
                    </template>

                    <template #selectedLabel="{ option }">
                        <pendo-icon-option :option="option" />
                    </template>
                </pendo-multiselect>
            </pendo-form-item>
        </div>
    </pendo-form>
</template>

<script>
import {
    PendoForm,
    PendoFormItem,
    PendoIcon,
    PendoIconOption,
    PendoMultiselect,
    PendoTooltip
} from '@pendo/components';
import DateRangePicker from '@/components/filters/DateRangePicker';
import SegmentChooser from '@/components/segments/SegmentChooser';
import get from 'lodash/get';
import { mapActions, mapState, mapGetters } from 'vuex';
import { maxDurationOptions } from '@/utils/workflows';

export default {
    name: 'WorkflowsQueryBuilder',
    components: {
        DateRangePicker,
        PendoForm,
        PendoFormItem,
        PendoIcon,
        PendoIconOption,
        PendoMultiselect,
        SegmentChooser
    },
    directives: {
        PendoTooltip
    },
    inject: ['$workflows'],
    data () {
        return {
            appSelected: {
                end: null,
                start: null
            },
            rules: {
                end: [
                    {
                        required: true,
                        message: 'Workflow End is required',
                        trigger: ['change']
                    }
                ],
                start: [
                    {
                        required: true,
                        message: 'Workflow Start is required',
                        trigger: ['change']
                    }
                ]
            },
            visibilityList: [
                {
                    icon: {
                        type: 'user'
                    },
                    id: false,
                    name: 'Only Me'
                },
                {
                    icon: {
                        type: 'users'
                    },
                    id: true,
                    name: 'Everyone'
                }
            ],
            maxDurationOptions,
            maxDurationTooltipText:
                'The Workflow will be considered incomplete if the time between steps exceeds the specified time.'
        };
    },
    computed: {
        ...mapGetters({
            features: 'features/list',
            getAppList: 'apps/getList',
            isReadOnly: 'auth/isReadOnly',
            pages: 'pages/list'
        }),
        ...mapState({
            activeSubId: (state) => state.subscriptions.activeId,
            activeUserId: (state) => state.auth.user.id,
            isFetching: (state) => state.workflows.isFetching,
            isSaving: (state) => state.workflows.isSaving
        }),
        appList () {
            return this.getAppList(this.activeSubId);
        },
        canEditVisibility () {
            return get(this.$workflows.workingWorkflow, 'createdByUser.id', this.activeUserId) === this.activeUserId;
        },
        canEditWorkflow () {
            return !this.isFetching && !this.isSaving && !this.$workflows.isAggregationInFlight;
        },
        visibility () {
            return this.$workflows.workingWorkflow.shared ? this.visibilityList[1] : this.visibilityList[0];
        }
    },
    async created () {
        if (this.$workflows.id !== 'new') {
            // Need to wait till WorkflowDetails has fetched Workflows and set `currentWorkflow`.
            await this.fetchWorkflows();

            if (this.$workflows.currentWorkflow) {
                this.appSelected.start = this.appList.find(
                    (app) => app.id === this.$workflows.currentWorkflow.start.appId
                );
                this.appSelected.end = this.appList.find((app) => app.id === this.$workflows.currentWorkflow.end.appId);
            }
        }
    },
    methods: {
        ...mapActions({
            fetchWorkflows: 'workflows/fetch'
        }),
        getDropdownGroup (groupKey, groupName, kind, icon, countables, disabledCountable) {
            return {
                id: groupKey,
                key: groupKey,
                name: groupName,
                options: countables.map((countable) => {
                    return {
                        appId: countable.appId,
                        disabled: countable.id === disabledCountable,
                        icon: {
                            type: icon
                        },
                        id: countable.id,
                        kind,
                        name: countable.name
                    };
                })
            };
        },
        getDropdownOptions (field) {
            const { id: appId } = this.appSelected[field] || {};
            const options = [];
            const oppositeField = field === 'start' ? 'end' : 'start';
            const disabledCountable =
                this.$workflows.workingWorkflow[oppositeField] !== null
                    ? this.$workflows.workingWorkflow[oppositeField].id
                    : '';
            const filteredPages = this.pages.filter((page) => page.appId === appId);
            const filteredFeatures = this.features.filter((feature) => feature.appId === appId);

            if (filteredPages.length !== 0) {
                options.push(this.getDropdownGroup('pages', 'Pages', 'Page', 'page', filteredPages, disabledCountable));
            }

            if (filteredFeatures.length !== 0) {
                options.push(
                    this.getDropdownGroup(
                        'features',
                        'Features',
                        'Feature',
                        'feature',
                        filteredFeatures,
                        disabledCountable
                    )
                );
            }

            return options;
        },
        onAppSelected (field, app) {
            this.appSelected[field] = app;
            this.$workflows.workingWorkflow[field] = null;
        },
        onFieldUpdated (field, value) {
            this.$workflows.workingWorkflow[field] = value;
        }
    }
};
</script>

<style lang="scss">
.workflows-query {
    &-container {
        display: grid;
        grid-gap: 21px;
        grid-template-areas:
            'dateRange segment maxTime'
            'appStart countableStart .'
            'appEnd countableEnd .';
        grid-template-columns: repeat(3, minmax(200px, 324px));
        padding: 10px 9px;
        max-width: 100%;
    }

    &-datepicker {
        grid-area: dateRange;
    }

    &-segment-chooser {
        grid-area: segment;
    }

    &-max-time {
        grid-area: maxTime;

        &--dropdown {
            .pendo-multiselect__label--top {
                display: flex;
                min-width: 230px;
            }

            .pendo-icon {
                margin-top: 0.1em;
                margin-left: 0.25em;
            }
        }
    }

    &-app-start {
        grid-area: appStart;
    }

    &-countable {
        padding-top: 24px;

        &-start {
            grid-area: countableStart;
        }

        &-end {
            grid-area: countableEnd;
        }
    }

    &-app-end {
        grid-area: appEnd;
    }

    &-divider {
        border: 1px solid #dadce5;
        margin: 9px 9px 24px;
    }

    &-visibility {
        padding-left: 9px;
        max-width: 324px;
    }
}
</style>
