<template>
    <div class="view--404">
        <h1>422</h1>
        <h3 data-cy="invitation-error-message">
            Invalid Invitation Key. Your link is broken.
        </h3>
        <div>
            <router-link to="/">
                <pendo-button
                    type="secondary"
                    theme="via"
                    class="btn--home">
                    Go Home
                </pendo-button>
            </router-link>
        </div>
    </div>
</template>

<script>
import { PendoButton } from '@pendo/components';

export default {
    name: 'UnprocessableEntity',
    components: {
        PendoButton
    }
};
</script>

<style lang="scss" scoped>
.view--404 {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 15%;

    h1 {
        font-size: 5em;
        color: $pink-primary;
        margin: 0 auto;
    }

    h3 {
        margin: 0 auto 1em;
    }

    .btn--home {
        margin-bottom: 5em;
    }
}
</style>
