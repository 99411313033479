<template>
    <pendo-card
        :data-cy="isResourceCenter ? 'resource-center-localization-settings' : 'guide-localization-settings'"
        class="guide-settings--localization"
        title="Localization">
        <template slot="headerRight">
            <pendo-button
                v-if="!isEditing && isEditable"
                theme="via"
                type="link"
                label="Edit"
                size="mini"
                prefix-icon="edit-2"
                @click="isEditing = true" />
            <pendo-button
                v-if="isEditing"
                theme="via"
                type="primary"
                label="Done"
                size="mini"
                @click="isEditing = false" />
        </template>
        <template slot="body">
            <div
                v-if="!isEditing"
                class="localization-info">
                <div class="localization-info--translated-languages-count">
                    <div v-if="languagesInitialized">
                        {{ translatedLanguages.length }} of
                        {{ translatedLanguages.length + untranslatedLanguages.length }}
                    </div>
                    <div v-else>
                        -- of --
                    </div>
                </div>
                <div class="localization-info--translated-languages-label">
                    Languages Translated
                </div>
            </div>
            <div
                v-if="isEditing"
                class="localization-edit">
                <language-export
                    :authored-language="authoredLanguage"
                    :guide="guide"
                    :languages="enabledLanguages" />
                <pendo-collapse
                    :value="activePanel"
                    :panels="panels"
                    theme="p2-light"
                    accordion>
                    <div slot="translated_languages">
                        <language-list
                            :guide-id="guide.id"
                            :is-resource-center="isResourceCenter"
                            :languages="translatedWithoutAuthored"
                            empty-state-heading="No languages have been translated yet."
                            empty-state-subtext="Import languages in the untranslated section."
                            :is-showing-translated-languages="true" />
                    </div>
                    <div slot="untranslated_languages">
                        <language-list
                            :app-id="app.id"
                            :guide-id="guide.id"
                            :is-resource-center="isResourceCenter"
                            :languages="untranslatedLanguages"
                            :all-languages-translated="allLanguagesTranslated"
                            :empty-state-heading="getUntranslatedEmptyStateText()" />
                    </div>
                </pendo-collapse>
            </div>
        </template>
        <div
            v-if="!isEditing"
            slot="footer"
            class="localization-footer">
            <div class="translated-languages">
                <div class="translated-languages--label">
                    Translated Languages:
                </div>
                <span
                    v-for="(translatedLanguage, index) of translatedLanguages"
                    :key="translatedLanguage.name">
                    {{ translatedLanguage.name }}
                    <span v-if="index !== translatedLanguages.length - 1 && translatedLanguages.length > 1">,</span>
                </span>
            </div>
            <div class="untranslated-languages">
                <div class="untranslated-languages--label">
                    Untranslated Languages:
                </div>
                <span
                    v-for="(untranslatedLanguage, index) of untranslatedLanguages"
                    :key="untranslatedLanguage.name">
                    {{ untranslatedLanguage.name
                    }}<span
                        v-if="index !== untranslatedLanguages.length - 1 && untranslatedLanguages.length > 1">,</span>
                </span>
            </div>
        </div>
    </pendo-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { formatLanguages, isTranslated } from '@/utils/localization.js';
import { STATES } from '@/utils/resource-center';
import { PendoCard, PendoButton, PendoCollapse } from '@pendo/components';
import LanguageExport from '@/components/guides/settings/localization/LanguageExport.vue';
import LanguageList from '@/components/guides/settings/localization/LanguageList.vue';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export default {
    name: 'DetailsLocalization',
    components: {
        LanguageExport,
        LanguageList,
        PendoCard,
        PendoButton,
        PendoCollapse
    },
    props: {
        guide: {
            type: Object,
            required: true
        },
        isResourceCenter: {
            type: Boolean,
            default: false
        },
        activeState: {
            type: String,
            default: 'draft'
        }
    },
    data () {
        return {
            isEditing: false,
            enabledLanguages: [],
            STATES,
            panels: [
                {
                    name: 'translated_languages',
                    title: 'Translated'
                },
                {
                    name: 'untranslated_languages',
                    title: 'Untranslated'
                }
            ],
            languagesInitialized: false
        };
    },
    computed: {
        ...mapGetters({
            getAppFromGuide: 'apps/appFromGuide',
            getAppFromResourceCenter: 'apps/appFromResourceCenter'
        }),
        app () {
            return this.isResourceCenter ? this.getAppFromResourceCenter(this.guide) : this.getAppFromGuide(this.guide);
        },
        localizationSettings () {
            return get(this.app, 'localizationSettings', {});
        },
        appId () {
            return get(this.guide, 'appId', '');
        },
        isEditable () {
            return !this.isResourceCenter || this.activeState === STATES.DRAFT;
        },
        authoredLanguage () {
            const languageCode = this.isResourceCenter
                ? get(this.guide, 'draft.homeView.authoredLanguage', '')
                : get(this.guide, 'authoredLanguage', '');
            const authoredLanguage =
                this.enabledLanguages && this.enabledLanguages.find((language) => language.code === languageCode);

            return authoredLanguage || this.defaultLanguage;
        },
        defaultLanguage () {
            return this.enabledLanguages && this.enabledLanguages.find((language) => language.isDefaultLanguage);
        },
        activePanel () {
            if (this.translatedWithoutAuthored.length > 0) return 'translated_languages';
            if (this.untranslatedLanguages.length > 0 || this.enabledLanguages.length === 1) {
                return 'untranslated_languages';
            }

            return '';
        },
        translationStates () {
            return this.isResourceCenter
                ? get(this, `guide[${this.activeState}].translationStates`, [])
                : get(this, 'guide.translationStates', []);
        },
        translatedLanguages () {
            const languages = this.enabledLanguages.filter(
                (language) => isTranslated(language.translatedState) || language.code === this.authoredLanguage.code
            );

            return languages;
        },
        translatedWithoutAuthored () {
            return this.translatedLanguages.filter((language) => language.code !== this.authoredLanguage.code);
        },
        untranslatedLanguages () {
            const languages = this.enabledLanguages.filter(
                (language) => !isTranslated(language.translatedState) && language.code !== this.authoredLanguage.code
            );

            return languages;
        },
        allLanguagesTranslated () {
            return this.translatedWithoutAuthored.length > 0 && this.untranslatedLanguages.length === 0;
        }
    },
    watch: {
        guide () {
            this.fetchLanguages(false);
        }
    },
    created () {
        this.fetchLanguages(true);
    },
    methods: {
        ...mapActions({
            fetchLocalizationSettingsForApp: 'apps/fetchLocalizationSettingsForApp'
        }),
        async fetchLanguages (noCache) {
            if (isEmpty(this.app)) return;
            await this.fetchLocalizationSettingsForApp({ app: this.app, noCache });
            const enabledLanguages = formatLanguages(this.localizationSettings, this.translationStates);
            this.sortLanguages(enabledLanguages);
        },
        sortLanguages (enabledLanguagesArray) {
            this.enabledLanguages = [];

            enabledLanguagesArray.forEach((enabledLanguage) => {
                const { name, isDefaultLanguage, translatedState, code, lastUpdated, isDeprecated } = enabledLanguage;

                this.enabledLanguages.push({
                    name,
                    isDefaultLanguage,
                    translatedState,
                    code,
                    lastUpdated,
                    isDeprecated
                });
            });
            this.languagesInitialized = true;
        },
        getUntranslatedEmptyStateText () {
            return this.allLanguagesTranslated
                ? 'All enabled languages have been translated.'
                : 'No languages have been enabled.';
        }
    }
};
</script>

<style lang="scss">
.guide-settings--localization {
    .pendo-card__body {
        display: grid;
        grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
        overflow-y: auto;
        padding: 0;

        > .localization-info {
            display: grid;
            justify-items: center;
            align-self: center;
            grid-gap: 8px;
            padding: 16px;
        }
    }

    .localization-info {
        &--translated-languages-count {
            font-size: 28px;
            line-height: 28px;
            font-weight: 300;
        }

        &--translated-languages-label {
            font-size: 12px;
            line-height: 14px;
            color: $gray-lighter-2;
            text-transform: uppercase;
        }
    }

    .localization-edit {
        display: grid;
        grid-template-rows: max-content;
    }

    .localization-footer {
        .translated-languages,
        .untranslated-languages {
            color: $gray-lighter-2;
            line-height: 21px;
            padding-bottom: 8px;

            &--label {
                font-weight: 700;
                color: $gray-primary;
            }
        }
    }
}
</style>
