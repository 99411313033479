<template>
    <pendo-card
        :title="title"
        :class="{ 'show-summary': showSummary }"
        class="guide-chart-card">
        <template
            v-if="$slots.header"
            slot="header">
            <slot name="header" />
        </template>
        <template slot="filters">
            <slot name="filters" />
        </template>
        <div
            v-pendo-loading:feather="{
                loading: isFetching,
                spinnerProps: {
                    background: '#ffffff'
                }
            }"
            class="guide-chart-card__chart">
            <slot name="chart" />
        </div>
        <div
            v-if="showSummary"
            class="guide-chart-card__summary-container">
            <div
                v-pendo-loading:feather="isFetching"
                class="guide-chart-card__summary">
                <slot name="summary" />
            </div>
        </div>
    </pendo-card>
</template>

<script>
import { PendoCard, PendoLoading } from '@pendo/components';

export default {
    name: 'GuideChartCard',
    components: {
        PendoCard
    },
    directives: {
        PendoLoading
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        isFetching: {
            type: Boolean,
            default: false
        },
        showSummary: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style lang="scss">
.show-summary {
    .pendo-card__body {
        display: grid;
        grid-template-areas:
            'filter summary'
            'chart  summary';
        grid-template-columns: calc(100% - 400px) 400px;
    }
}

.guide-chart-card {
    .pendo-card__body {
        position: relative;
        padding: 0;
    }

    .pendo-card__filters {
        grid-area: filter;
        padding: 12px;
        margin-bottom: 0;

        .pendo-tag + .pendo-tag {
            margin-left: 4px;
        }

        > * {
            margin: 4px;
        }
    }

    &__chart {
        grid-area: chart;
        padding: 16px;
        position: relative;
        height: 340px;

        .pendo-highcharts-container {
            height: 100%;

            &.empty {
                &:after {
                    content: 'No Data Available';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    font-weight: bold;
                }

                .highcharts-yaxis-labels,
                .highcharts-yaxis-grid {
                    display: none;
                }
            }
        }
    }

    .highcharts-tooltip {
        text-align: center;
    }

    &__summary-container {
        grid-area: summary;
        grid-row: span 2;
        border-left: 1px solid $gray-lighter-5;
        padding: 16px;
    }

    &__summary {
        display: grid;
        background: $gray-lighter-8;
        border: 1px solid $gray-lighter-5;
        border-radius: 3px;
        text-align: center;
        height: 100%;
        overflow: hidden;
    }

    &__label {
        color: $gray-lighter-3;
    }

    &__metric {
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 1.6rem;
    }
}
</style>
