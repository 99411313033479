<template>
    <pendo-modal
        :visible="visible"
        class="edit-modal"
        height="auto"
        type="basic"
        title="Rename Custom Page"
        message="Change the display name of your custom page:"
        @close="closeModal">
        <template #body>
            <pendo-alert
                v-if="editCustomPageError"
                type="error">
                Something went wrong. Try again - if the problem persists contact Pendo support.
            </pendo-alert>
            <div class="edit-modal--helper-text">
                Change the display name of your custom page:
            </div>
            <pendo-input
                v-model="model.displayName"
                label="Display Name" />
        </template>
        <template #footer>
            <pendo-button
                theme="via"
                type="secondary"
                label="Cancel"
                @click="closeModal" />
            <pendo-button
                :loading="isUpdatingCustomPageDisplayName"
                :disabled="displayNameChangeInvalid"
                theme="via"
                type="primary"
                label="Save"
                @click="handleEditCustomPage" />
        </template>
    </pendo-modal>
</template>

<script>
import { PendoAlert, PendoButton, PendoInput, PendoModal, PendoLoading, PendoTooltip } from '@pendo/components';
import { editCustomPageDisplayName } from '@/state/modules/pages.module';

export default {
    name: 'PagesList',
    components: {
        PendoAlert,
        PendoButton,
        PendoInput,
        PendoModal
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    props: {
        customPage: {
            type: Object,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            model: {
                displayName: ''
            },
            isUpdatingCustomPageDisplayName: false,
            editCustomPageError: false
        };
    },
    computed: {
        displayNameChangeInvalid () {
            return this.model.displayName === '' || this.model.displayName === this.customPage.displayName;
        }
    },
    watch: {
        visible (newVal) {
            if (newVal) {
                this.model.displayName = this.customPage.displayName;
            }
        }
    },
    methods: {
        closeModal () {
            this.$emit('close');
        },
        async handleEditCustomPage () {
            this.editCustomPageError = false;
            this.isUpdatingCustomPageDisplayName = true;
            try {
                await editCustomPageDisplayName(this.customPage.id, this.model.displayName);
                const customPage = {
                    ...this.customPage,
                    displayName: this.model.displayName
                };
                this.$emit('close', customPage);
            } catch (err) {
                this.editCustomPageError = true;
            }
            this.isUpdatingCustomPageDisplayName = false;
        }
    }
};
</script>

<style lang="scss">
.edit-modal {
    &--helper-text {
        margin-bottom: 24px;
    }
}

.edit-modal {
    .el-alert {
        margin-bottom: 18px;
    }
}
</style>
