<template>
    <div class="no-active-subscriptions">
        <div class="no-active-subscriptions--main">
            <h2 class="no-active-subscriptions--header">
                No Subscription Found
            </h2>
            <p class="no-active-subscriptions--message">
                This account does not appear to belong to any active Adopt subscriptions. Please contact your
                administrator for more information.
            </p>
        </div>
        <div class="no-active-subscriptions--footer">
            <pendo-button
                data-cy="logout"
                type="primary"
                theme="via"
                label="Log Out"
                @click="logout" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { PendoButton } from '@pendo/components';

export default {
    name: 'NoActiveSubscriptions',
    components: {
        PendoButton
    },
    computed: {
        ...mapState({
            route: (state) => state.route
        })
    },
    methods: {
        ...mapActions({
            _logout: 'auth/logout'
        }),
        async logout () {
            try {
                await this._logout();
                this.$router.push({ name: 'login', query: this.route.query });
            } catch (err) {
                window.location.reload();
            }
        }
    }
};
</script>

<style lang="scss">
.no-active-subscriptions {
    display: grid;
    grid-template-rows: 1fr auto;

    .no-active-subscriptions--header {
        margin: 0.25rem 0;
    }

    .no-active-subscriptions--message {
        margin-top: 0;
        margin-bottom: 1rem;
        line-height: 1.4;
    }

    .no-active-subscriptions--main {
        padding: 0 1.25rem;
    }

    .no-active-subscriptions--footer {
        padding: 1.25rem;
        border-top: 1px solid $gray-lighter-6;
        display: grid;
        grid-auto-flow: column;
        justify-content: end;
    }
}
</style>
