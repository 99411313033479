<template>
    <pendo-page-content tabs>
        <pendo-tabs
            v-model="activeTab"
            :tabs="tabs"
            theme="via"
            class="app-settings-tabs">
            <template #appDetails>
                <app-details :app="app" />
            </template>
            <template #rawEvents>
                <raw-events :app="app" />
            </template>
        </pendo-tabs>
    </pendo-page-content>
</template>

<script>
import { mapState } from 'vuex';
import { PendoPageContent, PendoTabs } from '@pendo/components';
import AppDetails from '@/components/settings/app-settings/AppDetails';
import RawEvents from '@/components/settings/RawEvents';

export default {
    name: 'AppSettingsContent',
    components: {
        PendoPageContent,
        PendoTabs,
        AppDetails,
        RawEvents
    },
    props: {
        app: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            route: (state) => state.route
        }),
        activeTab: {
            get () {
                return this.route.name || 'appDetails';
            },
            set (tab) {
                this.$router.push({ name: tab });
            }
        },
        tabs () {
            const tabs = [
                {
                    prop: 'appDetails',
                    label: 'App Details',
                    lazy: true
                },
                {
                    prop: 'rawEvents',
                    label: 'Raw Events',
                    lazy: true
                }
            ];

            return tabs;
        }
    }
};
</script>
