<template>
    <pendo-input-number
        class="number-input"
        :class="`${field}-number-input`"
        :value="value"
        :labels="labels"
        :min="min"
        :max="max"
        input-width="100%"
        @change="onNumberChange" />
</template>

<script>
import { PendoInputNumber } from '@pendo/components';
import isNumber from 'lodash/isNumber';

export default {
    name: 'NumberInput',
    components: {
        PendoInputNumber
    },
    props: {
        // eslint-disable-next-line vue/require-default-prop
        value: {
            type: null
        },
        field: {
            type: String,
            default: 'value'
        },
        labels: {
            type: Object,
            default: () => ({})
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: Infinity
        }
    },
    created () {
        const value = isNumber(this.value) ? this.value : 1;
        this.onNumberChange(value);
    },
    methods: {
        onNumberChange (value) {
            if (value == null) {
                value = 0;
            }
            this.$emit('input', { field: this.field, value });
        }
    }
};
</script>

<style lang="scss">
.number-input {
    /deep/.el-input-number {
        max-width: unset;
        grid-template-columns: 1fr max-content;

        .el-input-number__decrease,
        .el-input-number__increase {
            justify-self: end;
        }

        .el-input-number__suffix-label {
            padding: 0 8px;
            width: auto;
        }
    }
}
</style>
