import Vue from 'vue';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import get from 'lodash/get';
import filter from 'lodash/filter';
import http from '@/utils/http';

let loadAllPromise;

function getInitialState () {
    loadAllPromise = null;

    return {
        activeReportId: undefined,
        pathsListCreatedByFilter: 'anyone',
        map: {},
        error: {},
        fetching: false
    };
}

export const state = getInitialState();

export const mutations = {
    setActiveReport (state, { report }) {
        state.activeReportId = report.id;
    },
    setPathsListCreatedByFilter (state, { pathsListCreatedByFilter }) {
        state.pathsListCreatedByFilter = pathsListCreatedByFilter;
    },
    deleteMapKey (state, { id }) {
        Vue.delete(state.map, id);
    },
    setError (state, { error }) {
        state.error = error;
    },
    setFetching (state, { fetching }) {
        state.fetching = fetching;
    },
    setMap (state, { map }) {
        state.map = map;
    },
    setMapAtKey (state, { id, report }) {
        Vue.set(state.map, id, report);
    },
    reset (state) {
        Object.assign(state, getInitialState());
    }
};

export const actions = {
    clearActiveReport ({ commit }) {
        commit('setActiveReport', { report: { id: undefined } });
    },
    async loadAll ({ commit, rootGetters }, { noCache = false } = {}) {
        if (loadAllPromise && noCache !== true) {
            await loadAllPromise;

            return;
        }

        commit('setFetching', { fetching: true });
        loadAllPromise = fetchReports();

        let allReports;

        try {
            allReports = await loadAllPromise;
        } catch (error) {
            loadAllPromise = null;
            commit('setError', { error });
            commit('setFetching', { fetching: false });
            throw error;
        }

        if (rootGetters['subscriptions/usesMultiApp']) {
            commit('setMap', { map: keyBy(allReports, 'id') });
        } else {
            const activeAppId = rootGetters['apps/activeId'];
            const appSpecificReports = allReports.filter((report) => {
                const reportAppId = get(report, 'definition.config.appId');

                return reportAppId === activeAppId;
            });
            commit('setMap', { map: keyBy(appSpecificReports, 'id') });
        }

        commit('setFetching', { fetching: false });
    },
    async loadOne ({ commit, state }, { noCache = false, id } = {}) {
        if (!noCache && state.map[id]) {
            commit('setActiveReport', { report: { id } });

            return;
        }

        commit('setFetching', { fetching: true });

        try {
            const report = await fetchReport(id);
            commit('setMapAtKey', { id: report.id, report });
            commit('setActiveReport', { report });
            commit('setFetching', { fetching: false });
        } catch (error) {
            commit('setError', { error });
            commit('setFetching', { fetching: false });
            throw error;
        }
    },
    async create ({ commit }, { report } = {}) {
        commit('setFetching', { fetching: true });
        try {
            const newReport = await createReport(report);
            commit('setMapAtKey', {
                id: newReport.id,
                report: newReport
            });
            commit('setActiveReport', { report: newReport });
            commit('setFetching', { fetching: false });
        } catch (error) {
            commit('setError', { error });
            commit('setFetching', { fetching: false });
            throw error;
        }
    },
    async update ({ commit }, { report }) {
        commit('setFetching', { fetching: true });
        try {
            const updatedReport = await updateReport(report);
            commit('setMapAtKey', {
                id: updatedReport.id,
                report: updatedReport
            });
            commit('setActiveReport', { report: updatedReport });
            commit('setFetching', { fetching: false });
        } catch (error) {
            commit('setError', { error });
            commit('setFetching', { fetching: false });
            throw error;
        }
    },
    async remove ({ commit }, { id }) {
        commit('setFetching', { fetching: true });
        try {
            await deleteReport(id);
            commit('deleteMapKey', { id });
            commit('setActiveReport', { report: { id: undefined } });
            commit('setFetching', { fetching: false });
        } catch (error) {
            commit('setError', { error });
            commit('setFetching', { fetching: false });
            throw error;
        }
    }
};

export const getters = {
    activeReport: (state) => state.map[state.activeReportId] || {},
    list: (state) => values(state.map),
    reportById: (state) => (id) => state.map[id],
    listByType: (state, getters) => (filterType) =>
        filter(getters.list, (report) => {
            return report.type === filterType;
        })
};

export function createReport (report) {
    return http.post('/api/s/_SID_/report', report).then((res) => res.data);
}

export function deleteReport (id) {
    return http.delete(`/api/s/_SID_/report/${id}`);
}

export function fetchReport (id) {
    return http.get(`/api/s/_SID_/report/${id}`).then((res) => res.data);
}

export function fetchReports () {
    return http.get('/api/s/_SID_/report').then((res) => res.data);
}

export function updateReport (report) {
    console.log(report.id);

    return http.put(`/api/s/_SID_/report/${report.id}`, report).then((res) => res.data);
}

export function __resetLoadAllPromiseForUnitTests () {
    loadAllPromise = null;
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
