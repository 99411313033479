var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"workflow-visitor-table"},[(!this.$workflows.isAggregationInFlight)?_c('pendo-table',{attrs:{"columns":_vm.columns,"row-key":"visitorId","data":this.$workflows.workflowVisitors,"manage-columns":"","title":"Workflows","resizable":"","csv-download":"","max-height":500,"default-sort":_vm.sort},on:{"column-resize":_vm.onColumnResize,"column-change":_vm.onColumnChange,"sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"workflow-visitor-table--empty"},[_c('pendo-icon',{staticClass:"workflow-visitor-table--empty-icon",attrs:{"type":"alert-circle","stroke":"#9a9ca5","size":"24"}}),_c('span',{staticClass:"workflow-visitor-table--empty-text"},[_vm._v(" No data found. ")])],1)]},proxy:true},{key:"visitorId",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":{
                    name: 'visitorDetails',
                    params: { visitorId: _vm.encodeIdForUri(row.visitorId) }
                },"target":"_blank"}},[_vm._v(" "+_vm._s(row.visitorId)+" ")])]}},{key:"status",fn:function(ref){
                var row = ref.row;
return [(row && row.status === 'Completed')?_c('div',{staticClass:"workflow-visitor-table--status"},[_c('pendo-icon',{staticClass:"workflow-visitor-table--icon--completed",attrs:{"type":"check-circle","stroke":"#00C583","size":"18"}}),_c('div',{staticClass:"workflow-visitor-table--status-label"},[_vm._v(" Completed ")])],1):(row && row.status === 'Incomplete')?_c('div',{staticClass:"workflow-visitor-table--status"},[_c('pendo-icon',{staticClass:"workflow-visitor-table--icon--incomplete",attrs:{"type":"clock","stroke":"#DBA211","size":"18"}}),_c('div',{staticClass:"workflow-visitor-table--status-label"},[_vm._v(" Incomplete ")])],1):(row && row.status === 'Not Started')?_c('div',{staticClass:"workflow-visitor-table--status"},[_c('pendo-icon',{staticClass:"workflow-visitor-table--icon--not-started",attrs:{"type":"alert-triangle","stroke":"#DB1111","size":"18"}}),_c('div',{staticClass:"workflow-visitor-table--status-label"},[_vm._v(" Not Started ")])],1):_vm._e()]}}],null,false,753691350)}):_vm._e(),(this.$workflows.isAggregationInFlight)?_c('pendo-card',[_c('div',{staticClass:"workflow-visitor-table--loading"},[_c('pendo-loading-indicator',{staticClass:"workflow-visitor-table--loading-indicator",attrs:{"loading":"","size":"large"}}),_c('span',{staticClass:"workflow-visitor-table__loading-text"},[_vm._v("Data for this report is running. This could take a few minutes...")])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }