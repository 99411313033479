<template>
    <div class="feature-rule">
        <pendo-multiselect
            :value="rule.operator"
            :allow-empty="false"
            preselect-first
            full-width
            value-key="value"
            :options="operatorOptions"
            :disabled="!operatorOptions"
            class="segment-rule--operator"
            @select="handleFeatureOperatorSelection" />
        <pendo-multiselect
            :value="featureTimeLabelObject(rule.time)"
            value-key="value"
            label-key="label"
            min-trigger-width="100%"
            :allow-empty="false"
            :preselect-first="true"
            :options="featureTimeRangeOptions"
            class="segment-rule--feature-time-operator"
            @select="handleFeatureTimeSelection" />
        <single-date
            v-if="rule.time === 'since'"
            class="segment-rule--row--item"
            :value="rule.value"
            @input="$emit('input', $event)" />
        <relative-date-range
            v-if="rule.time === 'withinlast'"
            class="segment-rule--row--item complex-rule"
            :value="{ count: rule.value[0], granularity: rule.value[1] }"
            @input="$emit('input', $event)" />
        <number-input
            v-if="showNumberInputComponent"
            class="segment-rule--row--item complex-rule number-input--max-height"
            :labels="{ suffix: 'times' }"
            :value="rule.value"
            :min="1"
            @input="$emit('input', $event)" />
    </div>
</template>

<script>
import RelativeDateRange from '@/components/segments/builder/rule-components/RelativeDateRange.vue';
import SingleDate from '@/components/segments/builder/rule-components/SingleDate.vue';
import NumberInput from '@/components/segments/builder/rule-components/NumberInput.vue';
import {
    SEGMENT_OPERATORS,
    FEATURE_TIME_RANGE_CLICKED_OPTIONS,
    FEATURE_TIME_RANGE_NOT_CLICKED_OPTIONS
} from '@/constants/segments';
import { PendoMultiselect } from '@pendo/components';

export default {
    name: 'FeatureRule',
    components: {
        PendoMultiselect,
        RelativeDateRange,
        SingleDate,
        NumberInput
    },
    props: {
        rule: {
            type: Object,
            required: true
        }
    },
    computed: {
        operatorOptions () {
            return [...SEGMENT_OPERATORS[this.rule.schema.schema]];
        },
        featureTimeRangeOptions () {
            return this.rule.operator.value === 'clicked'
                ? FEATURE_TIME_RANGE_CLICKED_OPTIONS
                : FEATURE_TIME_RANGE_NOT_CLICKED_OPTIONS;
        },
        showNumberInputComponent () {
            return (
                this.rule.operator.value === 'clicked' && (this.rule.time === 'atmost' || this.rule.time === 'atleast')
            );
        }
    },
    methods: {
        featureTimeLabelObject (timeString) {
            return this.featureTimeRangeOptions.find((opt) => opt.value === timeString);
        },
        handleFeatureOperatorSelection ($event) {
            this.$emit('input', { field: 'operator', value: $event });

            // handle switching to `not clicked` where `at least / at most` not supported
            if ($event.value === 'notclicked' && (this.rule.time === 'atleast' || this.rule.time === 'atmost')) {
                this.$emit('input', { field: 'time', value: 'ever' });
            }
        },
        handleFeatureTimeSelection ($event) {
            const { value } = $event;

            // only 'time' type to require value as an array
            if (value === 'withinlast') {
                this.$emit('input', { field: 'value', value: [30, 'days'] });
            } else {
                this.$emit('input', { field: 'value', value: 1 });
            }
            this.$emit('input', { field: 'time', value });
        }
    }
};
</script>

<style lang="scss">
.number-input {
    &--max-height {
        height: 36px;
    }
}
</style>
