<template>
    <div>
        <pendo-form
            class="user-form"
            :call-validate="true"
            :form-defaults="form"
            :form-ref="formRef"
            @formValidated="onValidForm"
            @invalidForm="onInvalidForm">
            <pendo-form-item
                prop="first"
                label="First Name"
                data-cy="first-name-input-group"
                :rules="rules.first">
                <pendo-input
                    v-model="form.first"
                    class="user-details-input"
                    autofocus
                    placeholder="First Name" />
            </pendo-form-item>
            <pendo-form-item
                prop="last"
                label="Last Name"
                data-cy="last-name-input-group"
                :rules="rules.last">
                <pendo-input
                    v-model="form.last"
                    class="user-details-input"
                    placeholder="Last Name" />
            </pendo-form-item>
            <pendo-form-item
                prop="username"
                label="Email"
                data-cy="email-input-group"
                :rules="rules.username">
                <span v-if="!isEmpty(originalUser)">{{ originalUser.username }}</span>
                <pendo-input
                    v-else
                    v-model="form.username"
                    class="user-details-input"
                    :debounce="true"
                    placeholder="Email" />
            </pendo-form-item>
        </pendo-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { PendoForm, PendoFormItem, PendoInput } from '@pendo/components';

const EMPTY_FORM = Object.freeze({
    first: '',
    last: '',
    username: ''
});

export default {
    name: 'UserDetails',
    components: {
        PendoForm,
        PendoFormItem,
        PendoInput
    },
    props: {
        originalUser: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        }
    },
    data () {
        return {
            form: cloneDeep(EMPTY_FORM),
            callResetFields: false,
            hasAllRequiredFields: false,
            formRef: 'form',
            opsFlag: false,
            permissionGroup: '',
            isValid: false
        };
    },
    computed: {
        ...mapGetters({
            findUserByUsername: 'users/userByUsername'
        }),
        rules () {
            const isFirstRequired = isEmpty(this.originalUser) || this.originalUser.first;
            const isLastRequired = isEmpty(this.originalUser) || this.originalUser.last;

            return {
                first: [{ required: isFirstRequired, message: 'First name is required', trigger: ['blur', 'change'] }],
                last: [{ required: isLastRequired, message: 'Last name is required', trigger: ['blur', 'change'] }],
                username: [
                    {
                        validator: this.validateUsername,
                        trigger: ['blur', 'change']
                    },
                    {
                        required: isEmpty(this.originalUser),
                        message: 'Email address is required',
                        trigger: ['blur', 'change']
                    },
                    { type: 'email', message: 'Please input a valid email address', trigger: ['blur'] }
                ]
            };
        }
    },
    mounted () {
        this.populateForm();
    },
    methods: {
        isEmpty,
        populateForm () {
            Object.assign(this.form, EMPTY_FORM);

            if (!isEmpty(this.originalUser)) {
                const { first, last, username } = this.originalUser;

                Object.assign(this.form, { first, last, username });
            }
        },
        onValidForm () {
            this.$emit('valid', this.form);
        },
        onInvalidForm () {
            this.$emit('invalid', this.form);
        },
        validateUsername (rule, value, callback) {
            if (isEmpty(this.originalUser) && this.findUserByUsername(value)) {
                callback(new Error('User already exists in this subscription. Please enter another email.'));

                return;
            }

            callback();
        }
    }
};
</script>

<style lang="scss" scoped>
.user-form {
    margin-top: 22px;
    margin-bottom: 24px;
}

.user-details-input {
    max-width: 250px;
}
</style>
