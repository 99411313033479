<template>
    <pendo-card title="Activation">
        <template slot="headerRight">
            <pendo-button
                v-if="!activeIsDigitalAdoption"
                type="link"
                theme="via"
                class="manage-in-app--button"
                label="Edit"
                prefix-icon="edit-2"
                @click="editGuideActivation" />
        </template>
        <template slot="body">
            <div class="launch-method">
                <div
                    v-if="!activeLaunchMethodsMap.api"
                    :class="{ disabled: !activeLaunchMethodsMap.auto }"
                    class="launch-method--item">
                    <pendo-icon
                        :size="30"
                        type="half-loader"
                        class="launch-method--item--icon" />
                    <span class="launch-method--item--text">Automatic</span>
                </div>
                <div
                    v-if="!activeLaunchMethodsMap.api"
                    :class="{ disabled: !activeLaunchMethodsMap.badge }"
                    class="launch-method--item">
                    <pendo-icon
                        :size="30"
                        type="info"
                        class="launch-method--item--icon" />
                    <span class="launch-method--item--text">Badge</span>
                </div>
                <div
                    v-if="!activeLaunchMethodsMap.api"
                    :class="{ disabled: !activeLaunchMethodsMap.dom }"
                    class="launch-method--item">
                    <pendo-icon
                        :size="30"
                        type="target-element"
                        class="launch-method--item--icon" />
                    <span class="launch-method--item--text">Target Element</span>
                </div>
                <div
                    v-if="activeLaunchMethodsMap.api"
                    class="launch-method--item">
                    <activation-api class="launch-method--item--icon" />
                    <span class="launch-method--item--text">Programmatically</span>
                </div>
            </div>
        </template>
        <template slot="footer">
            <div class="activation-text">
                <div class="activation-text--label">
                    How does this guide activate?
                </div>
                {{ launchText }}.
            </div>
            <div
                v-if="pageLocation"
                class="page-location">
                <div class="page-location--label">
                    Page Location
                </div>
                {{ pageLocation }}
            </div>
            <div
                v-if="targetElement"
                class="target-element">
                <div class="target-element--label">
                    Element
                </div>
                {{ targetElement }}
            </div>
        </template>
    </pendo-card>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import { PendoCard, PendoButton, PendoIcon } from '@pendo/components';
import ActivationApi from '@/components/icons/ActivationApi';
import { LAUNCH_METHODS } from '@/utils/guides';
import { propName } from '@/utils/utils';

export default {
    name: 'ActivationCard',
    components: {
        PendoCard,
        PendoButton,
        PendoIcon,
        ActivationApi
    },
    computed: {
        ...mapGetters({
            guide: 'guides/active',
            pages: 'pages/list',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption'
        }),
        targetElement () {
            return get(this.guide, 'steps[0].elementPathRule', null);
        },
        pageLocation () {
            const guidePageId = get(this.guide, 'steps[0].pageId');

            if (!guidePageId) {
                return 'Sitewide';
            }
            const page = this.pages.find((item) => {
                const singlePageId = item.id || item.pageId;

                return singlePageId === guidePageId;
            });

            return get(page, 'displayName', 'Location not found');
        },
        launchMethod () {
            if (!this.guide.launchMethod) {
                return [LAUNCH_METHODS.auto];
            }

            return this.guide.launchMethod.split('-').map((type) => {
                return LAUNCH_METHODS[type];
            });
        },
        launchText () {
            if (this.guide.launchMethod === 'api') {
                return LAUNCH_METHODS.api.text;
            }

            return 'This guide appears '.concat(this.launchMethod.map((method) => method.text).join('; and '));
        },
        activeLaunchMethodsMap () {
            if (!this.launchMethod) {
                return { auto: true };
            }

            if (this.launchMethod === 'api') {
                return { api: true };
            }

            return this.launchMethod.reduce((map, method) => {
                map[propName(LAUNCH_METHODS, method)] = true;

                return map;
            }, {});
        },
        hasTranslations () {
            return !!this.guide.translationStates;
        }
    },
    methods: {
        editGuideActivation () {
            const [step] = this.guide.steps;

            this.$modal.show('via-modal', {
                title: 'Edit Guide Activation',
                component: 'LaunchDesignerModal',
                width: '465',
                componentProps: {
                    guideId: step.guideId,
                    stepId: step.id,
                    type: 'guide:activation',
                    translationWarning: this.hasTranslations
                }
            });
        }
    }
};
</script>

<style lang="scss">
.guide-details--activation {
    .pendo-card__body {
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    }

    .launch-method {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-evenly;
        align-items: center;

        &--item {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;

            &.disabled {
                opacity: 0.4;
            }

            &--text {
                text-transform: uppercase;
                font-size: 0.875em;
                margin-top: 1em;
            }
        }
    }

    .activation-text,
    .page-location,
    .target-element,
    .throttle-override {
        color: $gray-lighter-2;
        font-weight: 400;
        line-height: 1.5em;
        padding-bottom: 0.5em;

        &--label {
            font-weight: 700;
            color: $gray-primary;
        }
    }
}
</style>
