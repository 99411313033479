<template>
    <pendo-form
        :call-validate="callValidate"
        :call-reset-fields="callResetFields"
        :form-defaults="model"
        :form-ref="formRef"
        class="team-invite-modal"
        @fieldsReset="handleFieldsReset"
        @invalidForm="handleInvalidForm"
        @formValidated="handleValidForm">
        <div class="pendo-modal__body team-invite-modal--body">
            <template v-if="!inviteSuccess">
                <div class="team-invite-modal--message">
                    We'll send an email to the address entered below inviting them to join.
                </div>
                <pendo-form-item
                    :rules="rules.email"
                    label="Email Address"
                    prop="email">
                    <pendo-input
                        :invalid="showInviteError"
                        v-model.trim="model.email"
                        placeholder="name@example.com" />
                    <div
                        v-if="showInviteError"
                        class="el-form-item__error">
                        {{ inviteNewUserError }}
                    </div>
                </pendo-form-item>
            </template>
            <div
                v-if="inviteSuccess"
                class="team-invite-modal--success">
                We’ve successfully created a new user and sent an invitation instructing them to finish creating their account.
            </div>
        </div>
        <div class="pendo-modal__footer team-invite-modal--footer">
            <pendo-button
                :label="inviteSuccess ? 'Finished' : 'Cancel'"
                theme="via"
                type="tertiary"
                @click="closeModal" />
            <pendo-button
                v-if="inviteSuccess"
                label="Invite More People"
                theme="via"
                type="primary"
                @click="resetForm" />
            <pendo-button
                v-if="!inviteSuccess"
                :loading="isInvitingNewUser"
                label="Send Invite"
                theme="via"
                type="primary"
                @click="validateForm" />
        </div>
    </pendo-form>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { PendoButton, PendoForm, PendoFormItem, PendoInput } from '@pendo/components';

export default {
    name: 'SettingsTeamInviteModal',
    components: {
        PendoButton,
        PendoForm,
        PendoFormItem,
        PendoInput
    },
    data () {
        return {
            rules: {
                email: [
                    { required: true, message: 'Email address is required', trigger: ['blur', 'change'] },
                    { type: 'email', message: 'Please input a valid email address', trigger: ['blur'] }
                ]
            },
            model: {
                email: ''
            },
            callResetFields: false,
            callValidate: false,
            formRef: 'inviteNewUserForm',
            showInviteError: false,
            inviteSuccess: false
        };
    },
    computed: {
        ...mapState({
            isInvitingNewUser: (state) => state.users.isInvitingNewUser,
            inviteNewUserError: (state) => state.users.inviteNewUserError
        })
    },
    methods: {
        ...mapActions({
            _inviteNewUser: 'users/inviteNewUser'
        }),
        async inviteNewUser ({ email }) {
            await this._inviteNewUser({ email });

            if (this.inviteNewUserError) {
                this.showInviteError = true;
                const unwatch = this.$watch(
                    'model',
                    () => {
                        this.showInviteError = false;
                        unwatch && unwatch();
                    },
                    { deep: true }
                );

                return;
            }

            this.inviteSuccess = true;
        },
        closeModal () {
            this.$modal.hide('via-modal');
            this.callResetFields = true;
        },
        handleValidForm () {
            this.callValidate = false;
            this.inviteNewUser(this.model);
        },
        handleInvalidForm () {
            this.callValidate = false;
        },
        handleFieldsReset () {
            this.callResetFields = false;
        },
        resetForm () {
            this.model.email = '';
            this.inviteSuccess = false;
            this.callResetFields = true;
        },
        validateForm () {
            this.callValidate = true;
        }
    }
};
</script>
<style lang="scss">
.team-invite-modal {
    &--message {
        margin-bottom: 16px;
    }

    &--success {
        line-height: 1.75;
    }

    &--footer {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        justify-content: end;
    }
}
</style>
