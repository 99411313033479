import keyBy from 'lodash/keyBy';
import { BuildingBlockLayouts } from '@pendo/services/BuildingBlocks';

export default function getDefaultLayouts (theme) {
    const layoutsToKeep = keyBy([
        'Lightbox: Start From Scratch',
        'Banner: Start From Scratch',
        'Tooltip: Start From Scratch'
    ]);

    const defaultLayouts = BuildingBlockLayouts.PrebuiltLayouts.DefaultLayouts(theme, { platform: 'web' });

    return defaultLayouts.filter((layout) => layoutsToKeep[layout.name] && layout.supportedPlatforms.web);
}
