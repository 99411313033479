<template>
    <div class="create-account">
        <set-adopt-password-form v-if="!hasRegistrationTokenError" />
        <invalid-token-error v-if="hasRegistrationTokenError" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import SetAdoptPasswordForm from '@/components/register/adopt-v2-register/SetAdoptPasswordForm.vue';
import InvalidTokenError from '@/components/register/InvalidTokenError';

export default {
    name: 'CreateAdoptAccount',
    components: {
        SetAdoptPasswordForm,
        InvalidTokenError
    },
    computed: {
        ...mapState({
            hasRegistrationTokenError: (state) => state.auth.hasRegistrationTokenError
        })
    }
};
</script>
