var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"features-list"},[_c('pendo-table',{directives:[{name:"pendo-loading",rawName:"v-pendo-loading:feather",value:(_vm.isFetchingFeatureList),expression:"isFetchingFeatureList",arg:"feather"}],ref:"visitorListTable",staticClass:"features-list--table",attrs:{"data":_vm.formattedFeaturesList,"scroll-config":{ rowHeight: null },"filters":_vm.filters,"columns":_vm.columns,"default-sort":_vm.sort,"cell-class-name":_vm.determineCellClasses,"auto-height":true,"auto-height-offset":96,"csv-download":"","title":"Features","resizable":"","row-key":"id"},on:{"column-resize":_vm.onResizeColumn},scopedSlots:_vm._u([{key:"headerActions",fn:function(){return [_c('div',{staticClass:"features-list--table-header"},[_c('div',{staticClass:"visibility-note"},[_vm._v(" Note: Only features with click data will appear below. ")])])]},proxy:true},{key:"displayName",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"features-list--table--display-name",class:{
                    'is-disabled': _vm.isCustomFeatureNameEditDisabled(row)
                }},[(_vm.isCustomFeature(row))?[_c('pendo-editable-content',{staticClass:"features-list--table--display-name-inline-edit",attrs:{"value":row.displayName,"before-exit":_vm.updateDisplayName.bind(null, row)},on:{"enterEditMode":function($event){return _vm.onEnterDisplayNameEdit(row)},"exitEditMode":_vm.onExitDisplayNameEdit},scopedSlots:_vm._u([{key:"append",fn:function(ref){
                var confirm = ref.confirm;
                var cancel = ref.cancel;
                var pending = ref.pending;
return [_c('pendo-button',{attrs:{"loading":pending,"theme":"app","icon":"check","type":"primary"},on:{"click":confirm}}),_c('pendo-button',{attrs:{"theme":"app","type":"secondary","icon":"x"},on:{"click":cancel}})]}}],null,true)})]:_vm._e(),(!_vm.isCustomFeature(row))?[_vm._v(" "+_vm._s(row.displayName)+" ")]:_vm._e()],2)]}},{key:"actions",fn:function(ref){
                var row = ref.row;
return [(_vm.activeIsDigitalAdoption)?_c('pendo-actions-cell',{attrs:{"row":row,"actions":[
                    {
                        type: 'delete',
                        icon: 'trash-2',
                        disabled: !_vm.isCustomFeature(row),
                        tooltip: _vm.deleteTooltip(row)
                    }
                ]},on:{"delete":_vm.openDeleteCustomEntityModal}}):_vm._e()]}}])},[_c('div',{staticClass:"features-list--table--empty",attrs:{"slot":"empty"},slot:"empty"},[_c('pendo-icon',{staticClass:"empty-icon",attrs:{"type":"alert-circle","stroke":"#9a9ca5","size":"24"}}),_c('span',{staticClass:"empty-text"},[_vm._v(" No data found. ")])],1)]),_c('delete-custom-entity-modal',{attrs:{"visible":_vm.isDeleteCustomEntityModalVisible,"entity":_vm.currentlyDeletingRow,"type":"feature"},on:{"close":_vm.closeDeleteCustomEntityModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }