import http from '@/utils/http';
import moment, { DATE_FORMAT } from '@/utils/moment';
import { getStartDateForCount } from '@/utils/time-series';
import { maxDurationOptions } from '@/utils/workflows';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import startCase from 'lodash/startCase';
import Vue from 'vue';

let fetchPromise;

function getInitialState () {
    fetchPromise = null;

    return {
        isFetching: false,
        isRemoving: false,
        isSaving: false,
        map: {},
        workflowsListUserSettings: {},
        workflowsVisitorListColumns: null,
        workflowsVisitorListSettings: null
    };
}

export const state = getInitialState();

export const mutations = {
    deleteMapKey (state, { id }) {
        Vue.delete(state.map, id);
    },
    reset (state) {
        Object.assign(state, getInitialState());
    },
    setFetching (state, { isFetching }) {
        state.isFetching = isFetching;
    },
    setMap (state, { map }) {
        Vue.set(state, 'map', map);
    },
    setMapAtKey (state, { id, workflow }) {
        Vue.set(state.map, id, workflow);
    },
    setRemoving (state, { isRemoving }) {
        state.isRemoving = isRemoving;
    },
    setSaving (state, { isSaving }) {
        state.isSaving = isSaving;
    },
    setWorkflowsListUserSettings (state, { workflowsListUserSettings }) {
        state.workflowsListUserSettings = workflowsListUserSettings;
    },
    setWorkflowsVisitorListColumns (state, { workflowsVisitorListColumns }) {
        state.workflowsVisitorListColumns = workflowsVisitorListColumns;
    },
    setWorkflowsVisitorListSettings (state, { workflowsVisitorListSettings }) {
        state.workflowsVisitorListSettings = workflowsVisitorListSettings;
    }
};

export const actions = {
    async fetch ({ commit, dispatch, state, rootGetters, rootState }, { noCache = false } = {}) {
        if (state.isFetching) {
            await fetchPromise;
        }

        if (!isEmpty(state.map) && !noCache) {
            return;
        }

        commit('setFetching', { isFetching: true });

        fetchPromise = fetchAllWorkflowsForSubscription(rootGetters['auth/isSuper']);

        const list = await fetchPromise;
        await dispatch('pages/fetch', { noCache: false }, { root: true });
        await dispatch('features/fetch', { noCache: false }, { root: true });

        const workflowListWithApps = list.map((workflow) => {
            return translateWorkflow(workflow, rootState.features.map, rootState.pages.map);
        });

        commit('setMap', { map: keyBy(workflowListWithApps, 'id') });
        commit('setFetching', { isFetching: false });
    },
    async create ({ commit, rootGetters, rootState }, { workflow } = {}) {
        commit('setSaving', { isSaving: true });
        try {
            const newWorkflow = await createWorkflow(rootGetters['auth/isSuper'], buildPayload(workflow));
            commit('setMapAtKey', {
                id: newWorkflow.id,
                workflow: translateWorkflow(newWorkflow, rootState.features.map, rootState.pages.map)
            });
            commit('setSaving', { isSaving: false });

            return newWorkflow.id;
        } catch (error) {
            commit('setSaving', { isSaving: false });
            throw error;
        }
    },
    async update ({ commit, rootGetters, rootState }, { workflow } = {}) {
        commit('setSaving', { isSaving: true });
        try {
            const updatedWorkflow = await updateWorkflow(
                rootGetters['auth/isSuper'],
                workflow.id,
                buildPayload(workflow)
            );
            commit('setMapAtKey', {
                id: updatedWorkflow.id,
                workflow: translateWorkflow(updatedWorkflow, rootState.features.map, rootState.pages.map)
            });
            commit('setSaving', { isSaving: false });
        } catch (error) {
            commit('setSaving', { isSaving: false });
            throw error;
        }
    },
    async remove ({ commit, rootGetters }, { id }) {
        commit('setRemoving', { isRemoving: true });
        try {
            await deleteWorkflow(rootGetters['auth/isSuper'], id);
            commit('deleteMapKey', { id });
            commit('setRemoving', { isRemoving: false });
        } catch (error) {
            commit('setRemoving', { isRemoving: false });
            throw error;
        }
    },
    updateWorkflowsListUserSettings ({ commit, dispatch }, { workflowsListUserSettings }) {
        dispatch(
            'userSettings/updateSubNamespaceSetting',
            {
                name: 'workflowsListUserSettings',
                value: JSON.stringify(workflowsListUserSettings)
            },
            {
                root: true
            }
        );
        commit('setWorkflowsListUserSettings', { workflowsListUserSettings });
    },
    updateWorkflowsVisitorListSettings ({ commit, dispatch }, { workflowsVisitorListSettings }) {
        commit('setWorkflowsVisitorListSettings', { workflowsVisitorListSettings });
        dispatch(
            'userSettings/updateSubNamespaceSetting',
            {
                name: 'workflowsVisitorListSettings',
                value: JSON.stringify(workflowsVisitorListSettings)
            },
            {
                root: true
            }
        );
    }
};

export const getters = {
    list: (state) => Object.values(state.map),
    workflowById: (state) => (id) => state.map[id],
    workflowsVisitorListColumns (state, getters, rootState, rootGetters) {
        const defaultColumnSchemaIds = ['visitor_auto_id', 'visitor_auto_lastvisit'];
        const defaultColumns = [
            {
                prop: 'visitorId',
                label: 'Visitor ID',
                fixed: true,
                functionName: 'visitorIdColumn',
                schema: 'string',
                visible: true
            },
            {
                prop: 'timeOfLastMaxStep',
                label: 'Last Completed Date',
                fieldName: 'visitor.timeOfLastMaxStep',
                functionName: 'timeOfLastMaxStep',
                schema: 'date',
                visible: true
            },
            {
                prop: 'status',
                label: 'Completed Status',
                functionName: 'status',
                schema: 'string',
                visible: true
            }
        ];

        const metadataColumns = rootGetters['filters/schemaForColumns'](defaultColumnSchemaIds).map((schema) => {
            return {
                prop: schema.field,
                label: schema.name,
                fieldName: schema.field,
                functionName: 'metadataField',
                schema: schema.schema
            };
        });

        return defaultColumns.concat(metadataColumns);
    }
};

export function buildPayload (workflow) {
    return {
        classification: workflow.classification,
        name: workflow.name,
        permission: workflow.shared ? 'edit' : 'restricted',
        segmentId: workflow.segment,
        timeSeries:
            workflow.dateRange.id === 'custom'
                ? {
                    end: moment(workflow.dateRange.value.end).valueOf(),
                    start: moment(workflow.dateRange.value.start).valueOf(),
                    type: 'custom'
                }
                : {
                    type: workflow.dateRange.label.toLowerCase()
                },
        workflowSteps: [
            {
                countableId: workflow.start.id,
                type: 'start'
            },
            {
                countableId: workflow.end.id,
                type: 'end'
            }
        ],
        maxDuration: workflow.maxDuration.value,
        maxInterval: workflow.maxDuration.value
    };
}

export function translateWorkflow (workflow, features, pages) {
    let end = null;
    const endStep = workflow.workflowSteps.find((step) => step.type === 'end');
    if (endStep !== undefined) {
        if (features[endStep.countableId]) {
            const feature = features[endStep.countableId];
            end = {
                appId: feature.appId,
                disabled: false,
                icon: {
                    type: 'feature'
                },
                id: feature.id,
                kind: 'Feature',
                name: feature.name
            };
        } else if (pages[endStep.countableId]) {
            const page = pages[endStep.countableId];
            end = {
                appId: page.appId,
                disabled: false,
                icon: {
                    type: 'page'
                },
                id: page.id,
                kind: 'Page',
                name: page.name
            };
        }
    }

    let start = null;
    const startStep = workflow.workflowSteps.find((step) => step.type === 'start');
    if (startStep !== undefined) {
        if (features[startStep.countableId]) {
            const feature = features[startStep.countableId];
            start = {
                appId: feature.appId,
                disabled: false,
                icon: {
                    type: 'feature'
                },
                id: feature.id,
                kind: 'Feature',
                name: feature.name
            };
        } else if (pages[startStep.countableId]) {
            const page = pages[startStep.countableId];
            start = {
                appId: page.appId,
                disabled: false,
                icon: {
                    type: 'page'
                },
                id: page.id,
                kind: 'Page',
                name: page.name
            };
        }
    }

    let dateRange = null;
    if (workflow.timeSeries.type === 'custom') {
        dateRange = {
            count: 0,
            id: 'custom',
            label: `${moment(workflow.timeSeries.start).format(DATE_FORMAT.short)} - ${moment(
                workflow.timeSeries.end
            ).format(DATE_FORMAT.short)}`,
            value: {
                end: moment(workflow.timeSeries.end).format(DATE_FORMAT.iso),
                start: moment(workflow.timeSeries.start).format(DATE_FORMAT.iso)
            }
        };
    } else {
        const id = workflow.timeSeries.type.replace(/\s/g, '');
        const countMap = {
            today: 0,
            yesterday: 1,
            last7days: 7,
            last14days: 14,
            last30days: 30,
            last60days: 60,
            last90days: 90
        };

        const startDate = getStartDateForCount(countMap[id]);
        const endDate = workflow.timeSeries.type === 'today' ? getStartDateForCount(0) : getStartDateForCount(1);

        dateRange = {
            aside:
                countMap[id] <= 1
                    ? `(${moment(startDate).format(DATE_FORMAT.short)})`
                    : `(${moment(startDate).format(DATE_FORMAT.short)} - ${moment(endDate).format(DATE_FORMAT.short)})`,
            count: countMap[id],
            id,
            label: startCase(workflow.timeSeries.type),
            value: {
                end: moment(endDate).format(DATE_FORMAT.iso),
                start: moment(startDate).format(DATE_FORMAT.iso)
            }
        };
    }

    return {
        classification: workflow.classification,
        createdAt: workflow.createdAt,
        createdByUser: workflow.createdByUser,
        dateRange,
        end,
        id: workflow.id,
        lastUpdatedAt: workflow.lastUpdatedAt,
        lastUpdatedByUser: workflow.lastUpdatedByUser,
        name: workflow.name,
        segment: workflow.segmentId,
        shared: workflow.subscriptionSharing.permission === 'edit',
        start,
        maxDuration: workflow.maxDuration
            ? maxDurationOptions.find((option) => option.value === workflow.maxDuration)
            : {
                label: '24 Hours',
                value: 86400
            }
    };
}

export function fetchAllWorkflowsForSubscription (isSuper) {
    return http.get(isSuper ? '/api/super/ss/_SNAME_/workflow' : '/api/s/_SID_/workflow').then((res) => res.data);
}

export function createWorkflow (isSuper, workflow) {
    return http
        .post(isSuper ? '/api/super/ss/_SNAME_/workflow' : '/api/s/_SID_/workflow', workflow)
        .then((res) => res.data);
}

export function updateWorkflow (isSuper, id, workflow) {
    return http
        .put(isSuper ? `/api/super/ss/_SNAME_/workflow/${id}` : `/api/s/_SID_/workflow/${id}`, workflow)
        .then((res) => res.data);
}

export function deleteWorkflow (isSuper, id) {
    return http.delete(isSuper ? `/api/super/ss/_SNAME_/workflow/${id}` : `/api/s/_SID_/workflow/${id}`);
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
