<template>
    <pendo-page-content>
        <div class="workflows-list">
            <div class="workflows-list__filter-bar">
                <global-filters
                    :show-segment-picker="false"
                    :show-date-picker="false"
                    class="workflows-dashboard__filter-bar">
                    <template #right>
                        <pendo-multiselect
                            class="workflows-list__created-by-filter"
                            :value="selectedCreatedByFilter"
                            :class="{ 'left-spacing': usesMultiApp }"
                            :allow-empty="false"
                            :options="createdByFilterOptions"
                            @select="onSelectCreatedByFilter" />

                        <search
                            v-model="searchValue"
                            class="workflows-dashboard__search-input"
                            placeholder="Search Workflows..." />
                    </template>
                </global-filters>
            </div>
            <pendo-table
                class="workflows-list__table"
                title="Workflows"
                empty-text="No Workflows found."
                :loading="isFetchingWorkflows"
                :data="workflowsForAppFilter"
                :columns="columns"
                :filters="filters"
                manage-columns
                resizable
                csv-download
                auto-height
                :default-sort="sort"
                @column-resize="onColumnResize"
                @column-change="onColumnChange"
                @sort-change="onSortChanged">
                <template #actions="{ row }">
                    <pendo-actions-cell
                        :row="row"
                        :actions="tableRowActions"
                        @delete="openDeleteWorkflowModal" />
                </template>

                <template #empty>
                    <div
                        slot="empty"
                        class="workflows-list__empty-state">
                        <img
                            class="workflows-list__empty-state-image"
                            src="@/img/workflows-list-empty-state.png">

                        <h3
                            v-if="workflows.length === 0"
                            class="workflows-list__empty-state-title">
                            No workflows have been created yet
                        </h3>

                        <h3
                            v-if="workflows.length !== 0"
                            class="workflows-list__empty-state-title">
                            No workflows found
                        </h3>

                        <p
                            v-if="workflows.length === 0 && isReadOnly"
                            class="workflows-list__empty-state-body">
                            Start analyzing process completion and
                            <router-link to="/workflows/new">
                                run a workflow.
                            </router-link>
                        </p>

                        <p
                            v-if="workflows.length === 0 && !isReadOnly"
                            class="workflows-list__empty-state-body">
                            Start analyzing process completion and
                            <router-link to="/workflows/new">
                                create a new workflow.
                            </router-link>
                        </p>

                        <p
                            v-if="workflows.length !== 0"
                            class="workflows-list__empty-state-body">
                            There are no workflows found matching the current filters. Consider adjusting any applied
                            filters.
                        </p>
                    </div>
                </template>
            </pendo-table>

            <workflows-delete-modal
                :visible="deleteModalVisible"
                :name="deleteModalWorkflowName"
                @deleteWorkflow="onDeleteWorkflow"
                @modalClose="onDeleteModalClose" />
        </div>
    </pendo-page-content>
</template>

<script>
import WorkflowsDeleteModal from '../WorkflowsDeleteModal.vue';
import GlobalFilters from '@/components/filters/GlobalFilters';
import Search from '@/components/Search';
import moment, { DATE_FORMAT } from '@/utils/moment';
import {
    PendoMultiselect,
    PendoTable,
    PendoLoading,
    PendoActionsCell,
    PendoPageContent,
    PendoNotification
} from '@pendo/components';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    name: 'WorkflowsListContent',
    components: {
        GlobalFilters,
        PendoActionsCell,
        PendoMultiselect,
        PendoPageContent,
        PendoTable,
        Search,
        WorkflowsDeleteModal
    },
    directives: {
        PendoLoading
    },
    data () {
        return {
            createdByFilterOptions: [
                {
                    id: 'anyone',
                    label: 'Created by anyone'
                },
                {
                    id: 'me',
                    label: 'Created by me'
                }
            ],
            defaultSort: {
                prop: 'name',
                order: 'descending'
            },
            deleteModalVisible: false,
            deleteModalWorkflowId: '',
            deleteModalWorkflowName: '',
            searchValue: ''
        };
    },
    computed: {
        ...mapGetters({
            isAppIdsFilterInUse: 'filters/isAppIdsFilterInUse',
            isReadOnly: 'auth/isReadOnly',
            getAppById: 'apps/appById',
            usesMultiApp: 'subscriptions/usesMultiApp',
            workflows: 'workflows/list'
        }),
        ...mapState({
            user: (state) => state.auth.user,
            appIdsFilter: (state) => state.filters.appIdsFilter,
            workflowsListCreatedByFilter: (state) => state.workflows.workflowsListCreatedByFilter,
            isFetchingWorkflows: (state) => state.workflows.isFetching,
            workflowsListUserSettings: (state) => state.workflows.workflowsListUserSettings
        }),
        columns () {
            return [
                {
                    fixed: true,
                    label: 'Workflow Name',
                    prop: 'name',
                    sortable: true,
                    to: (row) => ({ name: 'workflowsDetails', params: { id: row.id } }),
                    type: 'link',
                    visible: true,
                    width: get(this.workflowsListUserSettings, 'sizes.name')
                },
                {
                    label: 'Starting App',
                    prop: 'startApp.displayName',
                    sortable: true,
                    visible: this.visibleColumnProps.includes('startApp.displayName'),
                    width: get(this.workflowsListUserSettings, ['sizes', 'startApp.displayName'])
                },
                {
                    label: 'Ending App',
                    prop: 'endApp.displayName',
                    sortable: true,
                    visible: this.visibleColumnProps.includes('endApp.displayName'),
                    width: get(this.workflowsListUserSettings, ['sizes', 'endApp.displayName'])
                },
                {
                    label: 'Created By',
                    prop: 'createdByUser.username',
                    sortable: true,
                    visible: this.visibleColumnProps.includes('createdByUser.username'),
                    width: get(this.workflowsListUserSettings, ['sizes', 'createdByUser.username'])
                },
                {
                    formatter: (row) => moment(row.createdAt).format(DATE_FORMAT.full),
                    label: 'Created Date',
                    prop: 'createdAt',
                    sortable: true,
                    visible: this.visibleColumnProps.includes('createdAt'),
                    width: get(this.workflowsListUserSettings, 'sizes.createdAt')
                },
                {
                    formatter: (row) => (row.shared ? 'Everyone' : 'Only Me'),
                    label: 'Visibility',
                    prop: 'shared',
                    sortable: true,
                    visible: this.visibleColumnProps.includes('shared'),
                    width: get(this.workflowsListUserSettings, 'sizes.shared')
                },
                {
                    label: 'Last Updated By',
                    prop: 'lastUpdatedByUser.username',
                    sortable: true,
                    visible: this.visibleColumnProps.includes('lastUpdatedByUser.username'),
                    width: get(this.workflowsListUserSettings, ['sizes', 'lastUpdatedByUser.username'])
                },
                {
                    formatter: (row) => moment(row.lastUpdatedAt).format(DATE_FORMAT.full),
                    label: 'Last Updated Date',
                    prop: 'lastUpdatedAt',
                    sortable: true,
                    visible: this.visibleColumnProps.includes('lastUpdatedAt'),
                    width: get(this.workflowsListUserSettings, 'sizes.lastUpdatedAt')
                },
                {
                    type: 'actions',
                    visible: !this.isReadOnly,
                    width: 60
                }
            ].sort((a, b) => {
                // Name always first
                if (a.prop === 'name') return -1;
                if (b.prop === 'name') return 1;

                // Actions always last
                if (a.type === 'actions') return 1;
                if (b.type === 'actions') return -1;

                return this.visibleColumnProps.indexOf(a.prop) - this.visibleColumnProps.indexOf(b.prop);
            });
        },
        filters () {
            return [
                {
                    prop: ['createdByUser.username'],
                    type: 'exact',
                    value: get(this.selectedCreatedByFilter, 'id') === 'me' ? this.user.email : ''
                },
                {
                    prop: ['name', 'createdByUser.username', 'lastUpdatedByUser.username', 'app.displayName'],
                    value: this.searchValue
                }
            ];
        },
        selectedCreatedByFilter () {
            const selectedId = get(this.workflowsListUserSettings, 'filters.createdBy', 'anyone');

            return this.createdByFilterOptions.find((option) => option.id === selectedId);
        },
        sort () {
            const sortUserSettings = get(this.workflowsListUserSettings, 'sort', null);
            if (!sortUserSettings) return this.defaultSort;

            const sortedColumnDoesNotExist = !this.columns.find((column) => column.prop === sortUserSettings.prop);
            if (sortedColumnDoesNotExist) return this.defaultSort;

            return sortUserSettings;
        },
        tableRowActions () {
            return [
                {
                    type: 'delete',
                    icon: 'trash-2',
                    tooltip: 'Delete'
                }
            ];
        },
        visibleColumnProps () {
            return this.workflowsListUserSettings && this.workflowsListUserSettings.columns
                ? this.workflowsListUserSettings.columns
                : [
                    'name',
                    'startApp.displayName',
                    'endApp.displayName',
                    'createdByUser.username',
                    'shared',
                    'lastUpdatedByUser.username',
                    'lastUpdatedAt'
                ];
        },
        workflowsForAppFilter () {
            return cloneDeep(this.workflows).reduce((workflows, workflow) => {
                let startApp = null;
                if (workflow.start) {
                    const app = this.getAppById(workflow.start.appId);
                    if (app) {
                        startApp = app;
                    }
                }

                let endApp = null;
                if (workflow.end) {
                    const app = this.getAppById(workflow.end.appId);
                    if (app) {
                        endApp = app;
                    }
                }

                if (
                    this.isAppIdsFilterInUse &&
                    !this.appIdsFilter.includes(startApp.id) &&
                    !this.appIdsFilter.includes(endApp.id)
                ) {
                    return workflows;
                }

                workflows.push({
                    ...workflow,
                    startApp,
                    endApp
                });

                return workflows;
            }, []);
        }
    },
    async created () {
        await this.fetchWorkflows();
    },
    methods: {
        ...mapActions({
            fetchWorkflows: 'workflows/fetch',
            removeWorkflow: 'workflows/remove',
            updateWorkflowsListUserSettings: 'workflows/updateWorkflowsListUserSettings',
            updateUserSetting: 'userSettings/updateAppNamespaceSetting'
        }),
        ...mapMutations({
            setWorkflowsListUserSettings: 'workflows/setWorkflowsListUserSettings'
        }),
        onColumnChange ({ columns }) {
            const workflowsListUserSettings = cloneDeep(this.workflowsListUserSettings) || {};
            workflowsListUserSettings.columns = columns;

            this.updateWorkflowsListUserSettings({
                workflowsListUserSettings
            });
        },
        onColumnResize ({ column, width }) {
            const workflowsListUserSettings = cloneDeep(this.workflowsListUserSettings) || {};
            if (!workflowsListUserSettings.sizes) {
                workflowsListUserSettings.sizes = {};
            }

            workflowsListUserSettings.sizes[column] = width;
            this.updateWorkflowsListUserSettings({
                workflowsListUserSettings
            });
        },
        onDeleteModalClose () {
            this.deleteModalVisible = false;
            this.deleteModalWorkflowId = '';
            this.deleteModalWorkflowName = '';
        },
        async onDeleteWorkflow () {
            try {
                await this.removeWorkflow({ id: this.deleteModalWorkflowId });
                PendoNotification({
                    type: 'success',
                    message: `${this.deleteModalWorkflowName} deleted!`
                });
            } catch (error) {
                PendoNotification({
                    type: 'error',
                    message: `There was an error deleting ${this.deleteModalWorkflowName}. Please try again.`
                });
            }

            this.deleteModalVisible = false;
            this.deleteModalWorkflowId = '';
            this.deleteModalWorkflowName = '';
        },
        onSelectCreatedByFilter (option) {
            const workflowsListUserSettings = cloneDeep(this.workflowsListUserSettings) || {};
            if (!workflowsListUserSettings.filters) {
                workflowsListUserSettings.filters = {};
            }

            workflowsListUserSettings.filters.createdBy = option.id;
            this.updateWorkflowsListUserSettings({
                workflowsListUserSettings
            });
        },
        onSortChanged ({ order, prop }) {
            if (this.isFetching) {
                return;
            }

            const workflowsListUserSettings = cloneDeep(this.workflowsListUserSettings) || {};
            workflowsListUserSettings.sort = {
                order,
                prop
            };
            this.updateWorkflowsListUserSettings({
                workflowsListUserSettings
            });
        },
        openDeleteWorkflowModal (row) {
            this.deleteModalWorkflowId = row.id;
            this.deleteModalWorkflowName = row.name;
            this.deleteModalVisible = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.workflows-dashboard__filter-bar {
    .workflows-dashboard__search-input {
        margin-left: auto;
    }
}

.workflows-list {
    &__created-by-filter.left-spacing {
        margin-left: 1em;
    }

    &__empty-state {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 56px auto;
        text-align: left;
        width: 300px;

        &-image {
            width: 250px;
        }

        &-title {
            font-size: 22px;
            font-weight: 600;
            line-height: 120%;
            margin: 24px 0 12px;
        }

        &-body {
            font-size: 18px;
            line-height: 25px;
            margin: 0;
        }
    }
}
</style>
