<template>
    <pendo-card
        v-pendo-loading:feather="isFetching"
        :title="chartTitle"
        class="visitors-over-time">
        <template slot="filters">
            <active-segment-tag />
            <active-date-range-tag />
            <pendo-tag
                :prefix-icon="{
                    type: 'circle',
                    stroke: filterLegendColorMap('visitors'),
                    fill: filterLegendColorMap('visitors'),
                    size: 12
                }"
                type="filter"
                label="Visitors" />
        </template>
        <div
            ref="visitors-over-time"
            class="pendo-highcharts-container visitors-over-time--chart" />
    </pendo-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import { getVisitorsOverTime } from '@/aggregations/visitors-over-time';
import { PendoCard, PendoLoading, PendoTag } from '@pendo/components';
import { getSubscriptionUtcOffset } from '@/utils/moment';
import Highcharts, { CHART_COLORS } from '@/utils/highcharts';
import ActiveSegmentTag from '@/components/filters/ActiveSegmentTag';
import ActiveDateRangeTag from '@/components/filters/ActiveDateRangeTag';
import { isCancel } from 'axios';

export default {
    name: 'VisitorsOverTime',
    components: {
        ActiveSegmentTag,
        ActiveDateRangeTag,
        PendoCard,
        PendoTag
    },
    directives: {
        PendoLoading
    },
    data () {
        return {
            chart: null,
            chartConfig: null,
            isFetching: false,
            aggCancel: null,
            visitorsOverTime: [],
            unsubscribeFilterBarListener: null
        };
    },
    computed: {
        ...mapState({
            activeDateRange: (state) => state.filters.dateRange,
            activeSegmentId: (state) => state.filters.activeSegmentId,
            appIdsFilter: (state) => state.filters.appIdsFilter
        }),
        ...mapGetters({
            activeAppId: 'apps/activeId',
            usesMultiApp: 'subscriptions/usesMultiApp'
        }),
        seriesData () {
            return this.visitorsOverTime.map(({ visitorCount, timestamp }) => ({ x: timestamp, y: visitorCount }));
        },
        xDateFormat () {
            if (this.activeDateRange.count > 30) {
                return 'Week of %b %d';
            }

            return '%b %d';
        },
        chartTitle () {
            return `Visitors Over Time${this.usesMultiApp ? ' Over All Apps' : ''}`;
        }
    },
    watch: {
        visitorsOverTime () {
            if (this.isFetching || !this.chart) {
                return;
            }
            const { xAxis } = this.getChartConfig();

            this.chart.series[0].setData(this.seriesData);
            this.chart.update({
                xAxis, // setting this again forces highcharts to update the x-axis
                plotOptions: {
                    series: {
                        tooltip: { xDateFormat: this.xDateFormat }
                    }
                }
            });
        }
    },
    created () {
        this.refreshChart();

        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, () => {
            this.refreshChart();
        });
    },
    destroyed () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        filterLegendColorMap (filter) {
            return {
                visitors: CHART_COLORS.green100
            }[filter];
        },
        async refreshChart () {
            await this.fetchVisitorsOverTime();
            this.chartConfig = this.getChartConfig();

            if (this.$refs['visitors-over-time']) {
                this.chart = Highcharts.chart(this.$refs['visitors-over-time'], this.chartConfig);
            }
        },
        getChartConfig () {
            const seriesConfig = {
                name: 'Visitors',
                type: 'area',
                data: this.seriesData,
                showInLegend: false
            };

            return {
                series: [seriesConfig],
                chart: {
                    type: 'area',
                    height: 316
                },
                time: {
                    timezoneOffset: getSubscriptionUtcOffset()
                },
                plotOptions: {
                    series: {
                        tooltip: { xDateFormat: this.xDateFormat },
                        fillColor: {
                            linearGradient: [0, 0, 0, 300],
                            stops: [[0, CHART_COLORS.green50], [1, CHART_COLORS.green10]]
                        },
                        lineColor: '#22998F',
                        marker: {
                            fillColor: CHART_COLORS.green10,
                            lineColor: CHART_COLORS.green50,
                            states: {
                                hover: {
                                    fillColor: CHART_COLORS.green100,
                                    lineColor: CHART_COLORS.green10
                                }
                            }
                        }
                    }
                },
                yAxis: {
                    allowDecimals: false
                },
                xAxis: {
                    startOfWeek: 0,
                    dateTimeLabelFormats: {
                        day: '%b %d',
                        week: '%b %e',
                        month: '%b %Y'
                    },
                    type: 'datetime',
                    labels: {
                        style: {
                            whiteSpace: 'nowrap',
                            textOverflow: 'none'
                        }
                    }
                }
            };
        },
        async fetchVisitorsOverTime () {
            this.isFetching = true;

            if (this.aggCancel) {
                this.aggCancel.abort();
            }

            this.aggCancel = new AbortController();

            const appIdParam = this.usesMultiApp ? this.appIdsFilter : this.activeAppId;

            try {
                this.visitorsOverTime = await getVisitorsOverTime({
                    segmentId: this.activeSegmentId,
                    appId: appIdParam,
                    signal: this.aggCancel.signal,
                    dateRange: this.activeDateRange
                });

                this.isFetching = false;
            } catch (err) {
                // If we've cancelled our aggs because a segment/app/whatever changed, things are still loading
                if (!isCancel(err)) {
                    this.isFetching = false;
                }
            }
        }
    }
};
</script>
