import has from 'lodash/get';
import http from '@/utils/http';
import { generateAppUid } from '@/utils/apps';
import {
    reduceUserSettingsForAppAndSub,
    parseDateRangeUserSetting,
    parseAppIdsFilter,
    parseViewByMetadataField
} from '@/utils/user-settings';

export const state = {};

export const mutations = {};

export const actions = {
    async fetch ({ dispatch, rootState }) {
        // dispatched from `apps/updateActive` which requires the sub id to be set
        if (!has(rootState, 'subscriptions.activeId')) {
            return;
        }

        const { data: settings } = await http.get('/api/s/_SID_/user/_UID_/setting');
        const { activeAppUid } = rootState.apps;
        const subscriptionId = String(rootState.subscriptions.activeId);
        const userSettings = reduceUserSettingsForAppAndSub(activeAppUid, subscriptionId, settings);
        await dispatch('hydrate', { userSettings });
    },
    async updateAppNamespaceSetting ({ rootState, rootGetters }, { name, value, appId }) {
        let appUid = rootState.apps.activeAppUid;

        if (rootGetters['subscriptions/usesMultiApp'] && appId) {
            appUid = generateAppUid(rootState.subscriptions.activeId, appId);
        }

        const namespacedKey = `${appUid}--${name}`;

        await http.put(`/api/s/_SID_/user/_UID_/setting/${namespacedKey}`, {
            value: value.toString(),
            name: namespacedKey,
            userId: '{{uid}}'
        });
    },
    async updateSubNamespaceSetting ({ rootState }, { name, value }) {
        const subscriptionId = rootState.subscriptions.activeId;
        const namespaceKey = `${subscriptionId}--${name}`;
        await http.put(`/api/s/_SID_/user/_UID_/setting/${namespaceKey}`, {
            value: value.toString(),
            name: namespaceKey,
            userId: '{{uid}}'
        });
    },
    async hydrate ({ commit, dispatch }, { userSettings }) {
        const {
            activeSegmentId,
            appUsageListUserSettings,
            hideVisitorsNoEvents,
            lastDesignerUrl,
            visitorListColumns,
            workflowsListUserSettings,
            workflowsVisitorListSettings,
            guideActivityColumns,
            guideActivityTableUserSettings,
            pollResponseColumns,
            numDaysBeforeLast,
            dateRange,
            appIdsFilter,
            viewByMetadataField,
            isViewingApplicationsByMetadata,
            pageUsageChart,
            pagesListColumns,
            topPagesListColumns,
            featuresListColumns,
            topFeaturesListColumns,
            pathsListCreatedByFilter,
            guidesListUserSettings,
            applicationSettings
        } = userSettings;
        const designerLastUrlsByApp = Object.keys(applicationSettings).reduce((acc, appId) => {
            acc[appId] = applicationSettings[appId].lastDesignerUrl;

            return acc;
        }, {});

        commit(
            'filters/setDateRange',
            { dateRange: parseDateRangeUserSetting({ numDaysBeforeLast, dateRange }) },
            { root: true }
        );
        commit('filters/setHideVisitorsNoEvents', { value: hideVisitorsNoEvents === 'true' }, { root: true });
        commit('filters/setAppIdsFilter', { appIds: parseAppIdsFilter(appIdsFilter) }, { root: true });
        commit(
            'filters/setViewByMetadataField',
            { metadata: parseViewByMetadataField(viewByMetadataField) },
            { root: true }
        );
        commit(
            'filters/setPivotDataToggle',
            { isViewingApplicationsByMetadata: isViewingApplicationsByMetadata === 'true' },
            { root: true }
        );
        commit('designer/setDesignerUrl', lastDesignerUrl || '', { root: true });
        commit('designer/setLastAppUrlMap', designerLastUrlsByApp, { root: true });
        commit(
            'analytics/setVisitorListColumns',
            { visitorListColumns: JSON.parse(visitorListColumns || 'null') },
            { root: true }
        );
        commit(
            'pages/setPagesListColumns',
            { pagesListColumns: JSON.parse(pagesListColumns || 'null') },
            { root: true }
        );
        commit(
            'pages/setTopPagesListColumns',
            { topPagesListColumns: JSON.parse(topPagesListColumns || 'null') },
            { root: true }
        );
        commit(
            'features/setFeaturesListColumns',
            { featuresListColumns: JSON.parse(featuresListColumns || 'null') },
            { root: true }
        );
        commit(
            'features/setTopFeaturesListColumns',
            { topFeaturesListColumns: JSON.parse(topFeaturesListColumns || 'null') },
            { root: true }
        );
        commit(
            'workflows/setWorkflowsVisitorListSettings',
            { workflowsVisitorListSettings: JSON.parse(workflowsVisitorListSettings || '{}') },
            { root: true }
        );
        commit(
            'guideAnalytics/setGuideActivityColumns',
            { guideActivityColumns: JSON.parse(guideActivityColumns || 'null') },
            { root: true }
        );
        commit(
            'guideAnalytics/setGuideActivityTableUserSettings',
            { guideActivityTableUserSettings: JSON.parse(guideActivityTableUserSettings || '{}') },
            { root: true }
        );
        commit(
            'guideAnalytics/setPollResponseColumns',
            { pollResponseColumns: JSON.parse(pollResponseColumns || 'null') },
            { root: true }
        );
        commit('analytics/setPageUsageChart', { pageUsageChart: JSON.parse(pageUsageChart || 'null') }, { root: true });
        commit(
            'reports/setPathsListCreatedByFilter',
            { pathsListCreatedByFilter: pathsListCreatedByFilter || 'anyone' },
            { root: true }
        );
        commit(
            'portfolio/setAppUsageListUserSettings',
            { appUsageListUserSettings: JSON.parse(appUsageListUserSettings || 'null') },
            { root: true }
        );
        commit(
            'workflows/setWorkflowsListUserSettings',
            { workflowsListUserSettings: JSON.parse(workflowsListUserSettings || 'null') },
            { root: true }
        );
        commit(
            'guides/setGuidesListUserSettings',
            { guidesListUserSettings: JSON.parse(guidesListUserSettings || 'null') },
            { root: true }
        );
        await dispatch('filters/updateActiveSegmentId', { id: activeSegmentId || 'everyone' }, { root: true });
    }
};

export const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
