<template>
    <pendo-date-picker
        class="single-date"
        full-width
        is-required
        :value="date"
        :min-date="appFirstVisit"
        :max-date="yesterday"
        @select-date="updateDate" />
</template>

<script>
import { PendoDatePicker } from '@pendo/components';
import moment, { DATE_FORMAT } from '@/utils/moment';
import { mapGetters } from 'vuex';

export default {
    name: 'SingleDate',
    components: {
        PendoDatePicker
    },
    props: {
        value: {
            type: null,
            default: null
        },
        field: {
            type: String,
            default: 'value'
        }
    },
    computed: {
        ...mapGetters({
            appFirstVisit: 'apps/firstVisit'
        }),
        date () {
            return moment(this.value).toDate();
        },
        valid () {
            return moment(this.value, DATE_FORMAT.iso, true).isValid();
        },
        yesterday () {
            return moment(Date.now())
                .subtract(1, 'day')
                .toDate();
        }
    },
    created () {
        const value = this.valid ? this.date : moment(new Date()).format(DATE_FORMAT.iso);
        this.updateDate({ value });
    },
    methods: {
        updateDate (date) {
            const value = moment(date.value).format(DATE_FORMAT.iso);
            this.$emit('input', { field: this.field, value });
        }
    }
};
</script>
