<template>
    <div class="segment-list-header">
        <page-header
            title="Segments"
            class="segment-header"
            :show-banner="true">
            <template #actions>
                <pendo-button
                    theme="via"
                    type="primary"
                    class="create-segment"
                    prefix-icon="plus"
                    label="Create Segment"
                    @click="showSegmentBuilder = true" />
            </template>
        </page-header>
        <app-segment-builder
            v-if="showSegmentBuilder"
            @close="showSegmentBuilder = false" />
    </div>
</template>

<script>
import { PendoButton } from '@pendo/components';
import PageHeader from '@/components/PageHeader';
import AppSegmentBuilder from '@/components/segments/builder/AppSegmentBuilder.vue';

export default {
    name: 'SegmentListHeader',
    components: {
        AppSegmentBuilder,
        PendoButton,
        PageHeader
    },
    data () {
        return {
            showSegmentBuilder: false
        };
    }
};
</script>
