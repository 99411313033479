import Vue from 'vue';
import isArray from 'lodash/isArray';
import keyBy from 'lodash/keyBy';
import { DATE_COLUMN_WIDTH } from '@/utils/table-formatters';

export function getInitialState () {
    return {
        visitorListColumns: null,
        visitorCounts: {},
        defaultVisitorListColumns: [
            {
                prop: 'visitorId',
                label: 'Visitor ID',
                functionName: 'visitorIdColumn',
                schema: 'string'
            },
            {
                prop: 'lastVisit',
                label: 'Last Visit',
                fieldName: 'visitor.auto.lastvisit',
                functionName: 'metadataField',
                schema: 'time',
                width: DATE_COLUMN_WIDTH
            },
            {
                prop: 'daysActive',
                label: 'Days Active',
                functionName: 'daysActive',
                schema: 'number'
            },
            {
                prop: 'eventCount',
                label: 'Events',
                functionName: 'eventCount',
                schema: 'number'
            }
        ],
        visitorsOverTime: [],
        pageUsageChart: null,
        pageUsageOverTime: [],
        featureUsageOverTime: []
    };
}

export const state = getInitialState();

export const mutations = {
    setVisitorListColumns (state, { visitorListColumns }) {
        state.visitorListColumns = visitorListColumns;
    },
    setPageUsageChart (state, { pageUsageChart }) {
        state.pageUsageChart = pageUsageChart;
    },
    setVisitorCounts (state, { today, yesterday, last7, last30 }) {
        Vue.set(state.visitorCounts, 'today', today);
        Vue.set(state.visitorCounts, 'yesterday', yesterday);
        Vue.set(state.visitorCounts, 'last7', last7);
        Vue.set(state.visitorCounts, 'last30', last30);
    },
    setVisitorsOverTime (state, { visitorsOverTime }) {
        state.visitorsOverTime = visitorsOverTime;
    },
    setPageUsageOverTime (state, { pageUsageOverTime }) {
        state.pageUsageOverTime = pageUsageOverTime;
    },
    setFeatureUsageOverTime (state, { featureUsageOverTime }) {
        state.featureUsageOverTime = featureUsageOverTime;
    },
    reset (state) {
        Object.assign(state, getInitialState(), {
            // preserve this stuff across resets
            // reset gets called whenever analytics refresh, which blows away
            // some state that we'd rather keep. The downside is that this state
            // does not reset on logout, but since the settings should be re-fetched
            // on a new login anyway, it shouldn't bleed state between sessions.
            visitorListColumns: state.visitorListColumns,
            pageUsageChart: state.pageUsageChart
        });
    }
};

export const actions = {};

export const getters = {
    visitorListColumns (state, getters) {
        if (isArray(state.visitorListColumns)) {
            const lookup = keyBy(getters.availableVisitorListColumns, 'prop');

            return state.visitorListColumns.filter((prop) => prop && lookup[prop]).map((prop) => lookup[prop]);
        }

        return state.defaultVisitorListColumns.slice();
    },
    availableVisitorListColumns (state, getters, rootState, rootGetters) {
        const defaultColumnSchemaIds = ['visitor_auto_id', 'visitor_auto_lastvisit'];
        const visitorListColumns = state.defaultVisitorListColumns.slice();

        const usesMultiApp = rootGetters['subscriptions/usesMultiApp'];
        if (usesMultiApp) {
            const lastVisitedAppColumn = {
                prop: 'lastVisitAppDisplayName',
                label: 'Last Visited App',
                functionName: 'metadataField',
                schema: 'string'
            };
            visitorListColumns.push(lastVisitedAppColumn);
        }

        return visitorListColumns.concat(
            rootGetters['filters/schemaForColumns'](defaultColumnSchemaIds).map((schema) => {
                return {
                    prop: schema.id,
                    label: schema.name,
                    fieldName: schema.field,
                    functionName: 'metadataField',
                    schema: schema.schema
                };
            })
        );
    },
    areAggregationsInFlight (state) {
        const inflightAggregations = Object.values(state.isFetchingMap).filter(Boolean);

        return !!inflightAggregations.length;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
