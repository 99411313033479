<template>
    <pendo-card>
        <template #body>
            <div class="empty-state">
                <pendo-empty-state
                    :icon="{
                        type: 'file',
                        size: '22'
                    }"
                    :title="cardHeader">
                    <template #description>
                        {{ description }}
                    </template>
                    <template #actions>
                        <add-app-modal-wrapper :show-add-app-button="true" />
                    </template>
                </pendo-empty-state>
            </div>
        </template>
    </pendo-card>
</template>

<script>
import { PendoCard, PendoEmptyState } from '@pendo/components';
import AddAppModalWrapper from './app-settings/AddAppModalWrapper.vue';

export default {
    name: 'EmptyState',
    components: {
        PendoCard,
        PendoEmptyState,
        AddAppModalWrapper
    },
    data () {
        return {
            cardHeader: 'Add an application to begin collecting adoption analytics and delivering guidance!',
            description:
                'Pendo applications represent the tools your employees use in their browser. Each application should be assigned one or more websites, which designate where Pendo should track actions and deliver guidance for that application.'
        };
    }
};
</script>

<style lang="scss">
.empty-state {
    min-height: 400px;
}
</style>
