<template>
    <pendo-form
        :call-validate="callValidate"
        :form-defaults="model"
        form-ref="viaLaunchDesignerForm"
        @formValidated="handleValidForm"
        @invalidForm="handleInvalidForm">
        <div class="pendo-modal__body launch-designer-modal">
            <pendo-alert
                v-if="translationWarning"
                type="warning">
                Editing the content of this guide may cause a mismatch between languages.
            </pendo-alert>
            <div class="launch-designer-modal--message">
                {{ bodyText }}
            </div>
            <pendo-form-item
                v-if="!launched"
                :rules="urlValidationRules"
                prop="url">
                <pendo-input
                    v-model="model.url"
                    class="launch-modal-input"
                    placeholder="https://acme.io"
                    @keyup.enter.native="launchDesigner" />
            </pendo-form-item>
            <pendo-form-item
                v-if="!launched && isPendoUser && isLookasideInputVisible"
                :rules="lookasideHostValidationRules"
                prop="lookasideHost">
                <label class="lookaside-host-label el-form-item__label">Lookaside Host</label>
                <pendo-icon
                    v-pendo-tooltip="{
                        content: 'Lookaside host option is only visible to users with @pendo.io in their emails',
                        arrow: true,
                        classes: 'is-multi-line'
                    }"
                    display="inline"
                    class="lookaside-host-icon"
                    type="info"
                    size="14" />
                <pendo-input
                    v-model="model.lookasideHost"
                    :autofocus="true"
                    placeholder="[name]-dot-pendo-[env].appspot.com (no trailing slash)">
                    <template slot="prepend">
                        https://
                    </template>
                </pendo-input>
            </pendo-form-item>
            <a
                v-if="launched && !supportsSecondaryLaunch"
                :href="helpLinkURL"
                target="_blank">
                <br>
                {{ `Don't see ${guideEditorName}?` }}
            </a>
            <pendo-button
                v-if="launched && supportsSecondaryLaunch"
                :suffix-icon="showSecondarySteps ? 'chevron-up' : 'chevron-down'"
                class="secondary-instructions"
                theme="via"
                type="link"
                @click="showSecondarySteps = !showSecondarySteps">
                {{ `Don't see ${guideEditorName}?` }}
            </pendo-button>
            <div v-if="supportsSecondaryLaunch && showSecondarySteps">
                <p>
                    Sometimes things don't work perfectly the first time. Let's try a different way to launch the
                    designer on your application.
                </p>
                <p>
                    Proceed to any browser tab with your application open and logged in. Then enter the following
                    keyboard shortcut:
                </p>
                <div class="keyboard-shortcut">
                    <div class="keyboard-shortcut--keys">
                        <span class="keyboard-shortcut--key">Alt</span>
                        <span class="keyboard-shortcut--key">Shift</span>
                        <span class="keyboard-shortcut--key">7</span>
                    </div>
                    <span class="keyboard-shortcut--subtext">
                        Alt (Option on Mac) + Shift + 7
                    </span>
                </div>
                <p>
                    {{ `Tried the shortcut and still not seeing ${guideEditorName}?` }}
                    <a
                        href="https://help.pendo.io/resources/support-library/guides/help-launching-guide-designer.html"
                        target="_blank">
                        Learn more
                    </a>
                </p>
            </div>
        </div>
        <div class="pendo-modal__footer launch-designer-modal--footer">
            <pendo-button
                v-if="!launched && isPendoUser"
                icon="git-merge"
                type="tertiary"
                class="toggle-lookaside-button"
                @click="toggleLookasideInputVisibility" />
            <div class="launch-designer-modal--footer-right">
                <pendo-button
                    v-if="launched"
                    type="primary"
                    theme="via"
                    label="Close"
                    @click="closeModal" />
                <pendo-button
                    v-if="!launched"
                    type="tertiary"
                    theme="via"
                    label="Cancel"
                    @click="cancelLaunch" />
                <pendo-button
                    v-if="!launched"
                    class="open-external-link"
                    :disabled="!isValidForm"
                    :data-gid="guideId"
                    :data-sid="stepId"
                    :label="`Launch ${guideEditorName}`"
                    theme="via"
                    type="primary"
                    icon="external-link"
                    @click="launchDesigner" />
            </div>
        </div>
    </pendo-form>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {
    PendoAlert,
    PendoButton,
    PendoForm,
    PendoFormItem,
    PendoInput,
    PendoIcon,
    PendoTooltip
} from '@pendo/components';
import { urlValidationRules, lookasideHostValidationRules, isValidLaunchDesignerForm } from '@/utils/utils';
import {
    updateVisitorMetadata,
    writeTokenToLocalStorage,
    launchViaWindowOpener,
    DESIGNER_LAUNCH_TOKEN_LS_KEY,
    WHITELABEL_SETTINGS_TOKEN_LS_KEY,
    getBestDesignerUrl,
    getFormattedDesignerUrl
} from '@/utils/designer';
import { meetsMinimumAgentVersion, getDesignerAppName } from '@/utils/apps';
import get from 'lodash/get';
import { EDITOR_TYPES, PENDO_HELP_LINKS } from '@pendo/services/Constants';

export default {
    name: 'LaunchDesignerModal',
    components: {
        PendoAlert,
        PendoButton,
        PendoForm,
        PendoFormItem,
        PendoInput,
        PendoIcon
    },
    directives: {
        PendoTooltip
    },
    props: {
        stepId: {
            type: String,
            default: null
        },
        guideId: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: 'feature'
        },
        translationWarning: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isLookasideInputVisible: false,
            formRef: 'viaLaunchDesignerForm',
            supportsSecondaryLaunch: false,
            model: {
                url: '',
                lookasideHost: null
            },
            launched: false,
            callValidate: false,
            urlValidationRules,
            lookasideHostValidationRules,
            showSecondarySteps: false
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            designerUrl: (state) => state.designer.url
        }),
        ...mapGetters({
            app: 'apps/active',
            appId: 'apps/activeId',
            appWhiteLabelSettings: 'apps/whiteLabelSettings',
            getAppFromGuide: 'apps/appFromGuide',
            productName: 'subscriptions/productName',
            activeUsesV2Adopt: 'subscriptions/activeUsesV2Adopt',
            activeUsesDADesigner: 'subscriptions/activeUsesDADesigner',
            usesMultiApp: 'subscriptions/usesMultiApp',
            getGuideById: 'guides/getGuideById',
            isPendoUser: 'auth/isPendoUser',
            lastUrlByAppId: 'designer/lastUrlByAppId',
            activeIsAEU: 'subscriptions/activeIsTrainingSubscription',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption'
        }),
        domainPatterns () {
            return this.app.extensionDomainPatterns && this.app.extensionDomainPatterns.length > 0
                ? [...this.app.extensionDomainPatterns]
                : [this.app.extensionDomainPatterns];
        },
        guideEditorName () {
            return getDesignerAppName(this.useDADesigner, this.type);
        },
        bodyText () {
            if (this.type === 'feature') {
                return `This will open a new tab and launch ${this.guideEditorName}. In ${
                    this.guideEditorName
                }, you can tag new features or edit existing ones.`;
            }
            if (this.type === 'page') {
                return `Enter your app's URL. This will open a new tab and launch ${
                    this.guideEditorName
                } so you can edit your tagged page.`;
            }
            const notLaunched = `Enter the URL where you'd like to see your guide. This will open a new tab and launch ${
                this.guideEditorName
            }.`;
            const launched = `You should see a new tab with ${this.guideEditorName} overlaying the URL you specified.`;

            return this.launched ? launched : notLaunched;
        },
        isValidForm () {
            const {
                model: { url, lookasideHost }
            } = this;

            return isValidLaunchDesignerForm({ url, lookasideHost });
        },
        useDADesigner () {
            const editorType = get(this, 'guide.editorType', EDITOR_TYPES.ADOPT_UI);
            if (this.activeIsAEU && !this.activeIsDigitalAdoption) return false;
            if (['page', 'feature'].includes(this.type)) return false;
            if (editorType === EDITOR_TYPES.ADOPT_STUDIO) return true;
            if (editorType === EDITOR_TYPES.VDS) return false;
            if (editorType === EDITOR_TYPES.ENGAGE_UI) return false;

            return this.activeUsesDADesigner;
        },
        guideAppId () {
            return get(this, 'guide.appId');
        },
        guide () {
            return this.getGuideById(this.guideId);
        },
        helpLinkURL () {
            return this.guideEditorName === 'Adopt Studio' ? PENDO_HELP_LINKS.asHelpDocs : PENDO_HELP_LINKS.vdsHelpDocs;
        }
    },
    created () {
        // pre-fill modal url if it exists
        this.model.url = this.getModalUrl();
    },
    methods: {
        ...mapActions({
            updateDesignerUrl: 'designer/updateDesignerUrl'
        }),
        toggleLookasideInputVisibility () {
            this.isLookasideInputVisible = !this.isLookasideInputVisible;
        },
        closeModal () {
            this.$modal.hide('via-modal');
            this.$emit('close', false);
        },
        launchDesigner () {
            if (!this.isValidForm) {
                return;
            }

            const {
                guideId,
                guide,
                stepId,
                type,
                model: { url, lookasideHost }
            } = this;

            let multiAppWhiteLabelSettings = {};
            if (this.usesMultiApp && guideId) {
                const appFromGuide = this.getAppFromGuide(guide);
                multiAppWhiteLabelSettings = get(appFromGuide, 'trainingAttributes.whiteLabelSettings');
            }

            const host = lookasideHost ? `https://${lookasideHost}` : window.location.origin;
            const token = {
                guideId,
                stepId,
                type,
                target: this.useDADesigner ? 'latest' : 'via',
                idForApp: this.guideAppId,
                DADesigner: this.useDADesigner,
                adoptv2: this.activeUsesV2Adopt,
                host
            };

            const whiteLabelToken = this.usesMultiApp
                ? {
                    multiAppWhiteLabelSettings,
                    productName: this.productName
                }
                : {
                    ...this.appWhiteLabelSettings,
                    productName: this.productName
                };

            this.updateDesignerUrl({ url, appId: this.guideAppId });
            writeTokenToLocalStorage(DESIGNER_LAUNCH_TOKEN_LS_KEY, JSON.stringify(token));
            writeTokenToLocalStorage(WHITELABEL_SETTINGS_TOKEN_LS_KEY, JSON.stringify(whiteLabelToken));
            launchViaWindowOpener(url, token);

            this.launched = true;
        },
        cancelLaunch () {
            this.closeModal();
            if (this.supportsSecondaryLaunch) {
                // clear metadata to prevent in-advertent designer launch and override launch token
                const values = { designerenabled: false };
                updateVisitorMetadata({ user: this.user, values });
                writeTokenToLocalStorage(DESIGNER_LAUNCH_TOKEN_LS_KEY, '');
            }
        },
        handleValidForm () {
            this.callValidate = false;
        },
        handleInvalidForm () {
            this.callValidate = false;
        },
        validateForm () {
            this.callValidate = true;
        },
        async initSecondaryLaunch () {
            this.supportsSecondaryLaunch = await meetsMinimumAgentVersion('2.15.12', this.appId);

            if (!this.supportsSecondaryLaunch) {
                return;
            }

            const values = { designerenabled: true };
            const { guideId, stepId, user, type } = this;
            const localStorageToken = {
                guideId,
                stepId,
                type,
                target: this.useDADesigner ? 'latest' : 'via',
                host: window.location.origin,
                DADesigner: this.useDADesigner,
                adoptv2: this.activeUsesV2Adopt
            };

            const whiteLabelToken = {
                ...this.appWhiteLabelSettings,
                productName: this.productName
            };

            // set metadata and write token to local storage when the modal is opened
            updateVisitorMetadata({ user, values });
            writeTokenToLocalStorage(DESIGNER_LAUNCH_TOKEN_LS_KEY, JSON.stringify(localStorageToken));
            writeTokenToLocalStorage(WHITELABEL_SETTINGS_TOKEN_LS_KEY, JSON.stringify(whiteLabelToken));
        },
        getModalUrl () {
            if (this.lastUrlByAppId(this.guideAppId)) {
                return this.lastUrlByAppId(this.guideAppId);
            }
            const unformattedModalUrl = getBestDesignerUrl(this.domainPatterns, this.designerUrl);

            return getFormattedDesignerUrl(unformattedModalUrl);
        }
    }
};
</script>
<style lang="scss">
.launch-designer-modal {
    &--message {
        margin-bottom: 16px;
    }

    /deep/.el-alert {
        margin-bottom: 16px;
    }
}

.keyboard-shortcut {
    background-color: $gray-lighter-7;
    margin: 1em 26%;
    padding: 1em;
    border-radius: 3px;

    &--keys {
        display: flex;
        justify-content: center;
    }

    &--key {
        align-items: center;
        background-color: $white;
        border-radius: 5px;
        border: 1px solid $gray-lighter-5;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.17);
        display: inline-flex;
        justify-content: center;
        margin: 5px;
        height: 32px;
        min-width: 32px;
        padding: 8px;
    }

    &--subtext {
        color: $gray-primary;
        display: flex;
        font-size: 0.875em;
        justify-content: center;
        margin-top: 0.5em;
    }
}

.el-form--label-top .lookaside-host-label.el-form-item__label {
    margin-right: 8px;
    height: 22px;
    padding: 0;
}

.launch-designer-modal--message {
    padding: 0;
}

.secondary-instructions.via.pendo-button--medium {
    padding: 0;
}

.launch-designer-modal--message {
    font-weight: 600;
}

.launch-designer-modal--footer {
    display: grid;
    grid-auto-flow: column;

    .toggle-lookaside-button {
        justify-self: start;
        width: 36px;
    }

    .launch-designer-modal--footer-right {
        justify-self: end;
    }
}
</style>
