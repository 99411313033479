<template>
    <pendo-alert
        v-if="!loading"
        type="warning">
        <slot>
            <p>
                The following pages will be created in <strong>{{ targetApp.displayName }}</strong>:
            </p>
            <ul class="page-list">
                <li
                    v-for="page in createdPages"
                    :key="page.id"
                    class="page-item">
                    {{ page.name }}
                </li>
            </ul>
        </slot>
    </pendo-alert>
</template>
<script>
import { PendoAlert } from '@pendo/components';
import { mapState } from 'vuex';

export default {
    name: 'PageCreateAlert',
    components: {
        PendoAlert
    },
    props: {
        targetApp: {
            type: Object,
            required: true
        },
        pageIds: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            createdPages: [],
            loading: true
        };
    },
    computed: {
        ...mapState({
            pagesMap: (state) => state.pages.map
        })
    },
    created () {
        this.loading = true;
        this.pageIds.map((id) => this.pageById(id));

        this.pageIds.forEach((pageId) => {
            this.createdPages.push(this.pageById(pageId));
        });
        this.loading = false;
    },
    methods: {
        pageById (pageId) {
            return this.pagesMap[pageId];
        }
    }
};
</script>
<style scoped lang="scss">
.page-list {
    list-style-type: none;
    padding-left: 0px;
}

.page-item {
    word-break: break-all;
    margin-bottom: 8px;
}
</style>
