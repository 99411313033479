<template>
    <!-- eslint-disable vue/max-attributes-per-line -->
    <!-- eslint-disable vue/no-v-html -->
    <pre class="highlightjs-code-block"><code :class="className" v-html="highlightedCode" /></pre>
</template>

<script>
//
// Based on the official Vue Plugin ported to Vue 2. (Simplified for our use case.)
// URL: https://github.com/highlightjs/vue-plugin
//

import hljs from 'highlight.js/lib/core';

export default {
    name: 'HighlightJs',
    props: {
        code: {
            type: String,
            required: true
        },
        language: {
            type: String,
            default: ''
        }
    },
    computed: {
        className () {
            if (this.cannotDetectLanguage) {
                return '';
            }

            return `hljs ${this.actualLanguage}`;
        },
        highlightedCode () {
            const result = hljs.highlight(this.code, {
                language: this.language,
                ignoreIllegals: true
            });

            return result.value;
        }
    }
};
</script>

<style lang="scss">
/* stylelint-disable scale-unlimited/declaration-strict-value */

/*

github.com style (c) Vasily Polovnyov <vast@whiteants.net>

Note: This theme is from Highlight.js 10.5.0, the GitHub theme from 11.4.0 is styled differently.

*/

.highlightjs-code-block {
    .hljs {
        display: block;
        overflow-x: auto;
        padding: 0.5em;
        color: #333;
        background: #f8f8f8;
    }

    .hljs-comment,
    .hljs-quote {
        color: #998;
        font-style: italic;
    }

    .hljs-keyword,
    .hljs-selector-tag,
    .hljs-subst {
        color: #333;
        font-weight: bold;
    }

    .hljs-number,
    .hljs-literal,
    .hljs-variable,
    .hljs-template-variable,
    .hljs-tag .hljs-attr {
        color: #008080;
    }

    .hljs-string,
    .hljs-doctag {
        color: #d14;
    }

    .hljs-title,
    .hljs-section,
    .hljs-selector-id {
        color: #900;
        font-weight: bold;
    }

    .hljs-subst {
        font-weight: normal;
    }

    .hljs-type,
    .hljs-class .hljs-title {
        color: #458;
        font-weight: bold;
    }

    .hljs-tag,
    .hljs-name,
    .hljs-attribute {
        color: #000080;
        font-weight: normal;
    }

    .hljs-regexp,
    .hljs-link {
        color: #009926;
    }

    .hljs-symbol,
    .hljs-bullet {
        color: #990073;
    }

    .hljs-built_in,
    .hljs-builtin-name {
        color: #0086b3;
    }

    .hljs-meta {
        color: #999;
        font-weight: bold;
    }

    .hljs-deletion {
        background: #fdd;
    }

    .hljs-addition {
        background: #dfd;
    }

    .hljs-emphasis {
        font-style: italic;
    }

    .hljs-strong {
        font-weight: bold;
    }
}
</style>
