<template>
    <pendo-modal
        class="add-integration-key-modal"
        title="Add New Integration Key"
        height="auto"
        :visible="true"
        size="medium"
        :pivot-y="0.05"
        @close="emitClose">
        <template slot="body">
            <div class="integration-key-label-input">
                <div class="header">
                    Key Description
                </div>
                <pendo-input
                    autofocus
                    :value="integrationKeyLabel"
                    @input="onLabelInputChange" />
            </div>
            <div
                v-if="activeUsesV2Adopt"
                class="integration-key-rw-checkbox">
                <pendo-checkbox
                    label="Allow Write Access"
                    :value="accessFlags.rw"
                    @change="onFlagChange($event, 'rw')" />
            </div>
        </template>
        <template slot="footer">
            <pendo-button
                theme="app"
                type="secondary"
                label="Cancel"
                @click="emitClose" />
            <pendo-button
                theme="app"
                label="Save"
                :loading="saving"
                :disabled="!isModalValid"
                @click="createIntegrationKey" />
        </template>
    </pendo-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import { PendoButton, PendoModal, PendoInput, PendoCheckbox } from '@pendo/components';

export default {
    name: 'AddIntegrationKeyModal',
    components: {
        PendoButton,
        PendoModal,
        PendoInput,
        PendoCheckbox
    },
    data () {
        return {
            integrationKeyLabel: '',
            accessFlags: {
                rw: false
            },
            saving: false
        };
    },
    computed: {
        ...mapGetters({
            activeUsesV2Adopt: 'subscriptions/activeUsesV2Adopt'
        }),
        isModalValid () {
            return this.integrationKeyLabel !== '';
        }
    },
    methods: {
        emitClose () {
            this.$emit('close', true);
        },
        async createIntegrationKey () {
            this.$emit('createKey', { label: this.integrationKeyLabel, accessFlags: this.accessFlags });
        },
        onLabelInputChange (value) {
            this.integrationKeyLabel = value;
        },
        onFlagChange (enabled, flag) {
            this.accessFlags[flag] = enabled;
        }
    }
};
</script>
<style scoped lang="scss">
.integration-key-label-input {
    margin-bottom: 32px;
}

.submission-error-alert {
    margin-bottom: 16px;
}

.header {
    font-weight: bold;
}

/deep/ .el-checkbox__label {
    font-weight: bold;
}
</style>
