<template>
    <div class="workflow-visitor-table">
        <pendo-table
            v-if="!this.$workflows.isAggregationInFlight"
            :columns="columns"
            row-key="visitorId"
            :data="this.$workflows.workflowVisitors"
            manage-columns
            title="Workflows"
            resizable
            csv-download
            :max-height="500"
            :default-sort="sort"
            @column-resize="onColumnResize"
            @column-change="onColumnChange"
            @sort-change="onSortChange">
            <template #empty>
                <div class="workflow-visitor-table--empty">
                    <pendo-icon
                        type="alert-circle"
                        class="workflow-visitor-table--empty-icon"
                        stroke="#9a9ca5"
                        size="24" />
                    <span class="workflow-visitor-table--empty-text">
                        No data found.
                    </span>
                </div>
            </template>

            <template #visitorId="{ row }">
                <router-link
                    :to="{
                        name: 'visitorDetails',
                        params: { visitorId: encodeIdForUri(row.visitorId) }
                    }"
                    target="_blank">
                    {{ row.visitorId }}
                </router-link>
            </template>

            <template #status="{ row }">
                <div
                    v-if="row && row.status === 'Completed'"
                    class="workflow-visitor-table--status">
                    <pendo-icon
                        type="check-circle"
                        class="workflow-visitor-table--icon--completed"
                        stroke="#00C583"
                        size="18" />

                    <div class="workflow-visitor-table--status-label">
                        Completed
                    </div>
                </div>
                <div
                    v-else-if="row && row.status === 'Incomplete'"
                    class="workflow-visitor-table--status">
                    <pendo-icon
                        type="clock"
                        class="workflow-visitor-table--icon--incomplete"
                        stroke="#DBA211"
                        size="18" />

                    <div class="workflow-visitor-table--status-label">
                        Incomplete
                    </div>
                </div>
                <div
                    v-else-if="row && row.status === 'Not Started'"
                    class="workflow-visitor-table--status">
                    <pendo-icon
                        type="alert-triangle"
                        class="workflow-visitor-table--icon--not-started"
                        stroke="#DB1111"
                        size="18" />
                    <div class="workflow-visitor-table--status-label">
                        Not Started
                    </div>
                </div>
            </template>
        </pendo-table>

        <pendo-card v-if="this.$workflows.isAggregationInFlight">
            <div class="workflow-visitor-table--loading">
                <pendo-loading-indicator
                    class="workflow-visitor-table--loading-indicator"
                    loading
                    size="large" />
                <span
                    class="workflow-visitor-table__loading-text">Data for this report is running. This could take a few minutes...</span>
            </div>
        </pendo-card>
    </div>
</template>

<script>
import { PendoTable, PendoIcon, PendoCard, PendoLoadingIndicator } from '@pendo/components';
import { rowFormatter } from '@/utils/table-formatters';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { encodeIdForUri } from '@/utils/utils';
import { mapGetters, mapActions, mapState } from 'vuex';
import { progressCircleColors } from '@/utils/paths';

export default {
    name: 'WorkflowsVisitorTable',
    components: {
        PendoTable,
        PendoIcon,
        PendoCard,
        PendoLoadingIndicator
    },
    inject: ['$workflows'],
    data () {
        return {
            defaultSort: { prop: 'visitorId', order: 'ascending' },
            progressCircleColors
        };
    },
    computed: {
        ...mapGetters({
            workflowsVisitorListColumns: 'workflows/workflowsVisitorListColumns',
            activeUsesV2Adopt: 'subscriptions/activeUsesV2Adopt'
        }),
        ...mapState({
            workflowsVisitorListSettings: (state) => state.workflows.workflowsVisitorListSettings
        }),
        sort () {
            const sortUserSettings = get(this.workflowsVisitorListSettings, 'sort', null);
            if (!sortUserSettings) return this.defaultSort;
            const sortedColumnDoesNotExist = !this.columns.find((column) => column.prop === sortUserSettings.prop);
            if (sortedColumnDoesNotExist) return this.defaultSort;

            return sortUserSettings;
        },
        columns () {
            const defaultColumnIds = ['visitorId', 'timeOfLastMaxStep', 'status'];
            const hasSettings = !isEmpty(this.workflowsVisitorListSettings.order);
            const sortArray = get(this.workflowsVisitorListSettings, 'order', defaultColumnIds);

            return this.workflowsVisitorListColumns
                .map((col) => {
                    const isDefault = defaultColumnIds.includes(col.prop);

                    return {
                        ...col,
                        sortable: true,
                        formatter: (row) => rowFormatter(row, col),
                        visible: get(
                            this.workflowsVisitorListSettings,
                            `visible["${col.prop}"]`,
                            hasSettings ? false : isDefault
                        ),
                        width: get(this.workflowsVisitorListSettings, `sizes["${col.prop}"]`, null)
                    };
                })
                .sort((a, b) => {
                    if (sortArray.includes(a.prop) && !sortArray.includes(b.prop)) {
                        return -1;
                    }
                    if (!sortArray.includes(a.prop) && sortArray.includes(b.prop)) {
                        return 1;
                    }
                    if (sortArray.includes(a.prop) && sortArray.includes(b.prop)) {
                        return sortArray.indexOf(a.prop) - sortArray.indexOf(b.prop);
                    }

                    return 0;
                });
        }
    },
    methods: {
        ...mapActions({
            updateWorkflowsVisitorListSettings: 'workflows/updateWorkflowsVisitorListSettings'
        }),
        onColumnResize ({ column, width }) {
            const settings = cloneDeep(this.workflowsVisitorListSettings) || {};
            if (!settings.sizes) {
                settings.sizes = {};
            }
            settings.sizes[column] = width;
            this.updateWorkflowsVisitorListSettings({
                workflowsVisitorListSettings: settings
            });
        },
        onColumnChange ({ columns }) {
            const workflowsVisitorListSettings = cloneDeep(this.workflowsVisitorListSettings) || {};
            workflowsVisitorListSettings.visible = {};
            workflowsVisitorListSettings.order = columns;
            columns.map((col) => {
                workflowsVisitorListSettings.visible[col] = true;

                return col;
            });
            this.updateWorkflowsVisitorListSettings({ workflowsVisitorListSettings });
        },
        onSortChange ({ order, prop }) {
            if (this.$workflows.isAggregationInFlight) {
                return;
            }
            const workflowsVisitorListSettings = cloneDeep(this.workflowsVisitorListSettings) || {};
            workflowsVisitorListSettings.sort = {
                order,
                prop
            };
            this.updateWorkflowsVisitorListSettings({
                workflowsVisitorListSettings
            });
        },
        encodeIdForUri
    }
};
</script>

<style lang="scss">
.workflow-visitor-table {
    .pendo-table__cell {
        display: flex;
    }

    &--empty {
        display: flex;
        align-items: center;
        justify-content: center;

        &-icon {
            margin-right: 0.5em;
            display: flex;
        }

        &-text {
            color: $gray-primary;
        }
    }

    &--loading {
        display: flex;
        flex-direction: column;
        font-size: 1.2rem;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin: 50px;

        &-indicator {
            margin: 50px;
        }

        &-text {
            line-height: 2rem;
            margin: 2rem 0;
        }
    }

    &--status {
        display: flex;

        &-label {
            margin-left: 5.7px;
            line-height: 18px;
        }
    }
}
</style>
