<template>
    <div class="copy-text-area">
        <div class="copy-text-area--header">
            <div>
                <div
                    v-if="isDescriptionPosition('top')"
                    class="copy-text-area--helper-text">
                    {{ description }}
                </div>
            </div>
            <div>
                <pendo-button
                    v-if="isButtonPosition('top')"
                    v-clipboard:copy="codeToCopy"
                    v-clipboard:success="onCopySuccess"
                    v-clipboard:error="onCopyError"
                    theme="app"
                    type="link"
                    prefix-icon="clipboard"
                    :label="buttonLabel" />
            </div>
        </div>
        <div class="copy-text-area--body">
            <div
                v-if="isDescriptionPosition('left')"
                class="copy-text-area--helper-text">
                {{ description }}
            </div>
            <highlight-js
                language="javascript"
                class="copy-text-area--pre"
                :code="codeToCopy" />
            <pendo-button
                v-if="isButtonPosition('right')"
                v-clipboard:copy="codeToCopy"
                v-clipboard:success="onCopySuccess"
                v-clipboard:error="onCopyError"
                theme="app"
                type="link"
                prefix-icon="clipboard"
                :label="buttonLabel" />
        </div>
    </div>
</template>

<script>
import { PendoButton, PendoNotification } from '@pendo/components';
import HighlightJs from './HighlightJs.vue';
import get from 'lodash/get';

export default {
    name: 'CopyTextArea',
    components: {
        HighlightJs,
        PendoButton
    },
    props: {
        codeToCopy: {
            type: String,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        descriptionOptions: {
            type: Object,
            default: () => ({
                description: '',
                position: 'top'
            })
        },
        buttonOptions: {
            type: Object,
            default: () => ({
                label: 'Copy',
                position: 'top'
            })
        }
    },
    computed: {
        buttonLabel () {
            return get(this, 'buttonOptions.label');
        },
        description () {
            return get(this, 'descriptionOptions.description');
        }
    },
    methods: {
        onCopySuccess () {
            PendoNotification({
                type: 'success',
                title: 'Copied!'
            });
        },
        onCopyError () {
            PendoNotification({
                type: 'error',
                title: 'Copy failed – try again!'
            });
        },
        isButtonPosition (position) {
            return get(this, 'buttonOptions.position') === position;
        },
        isDescriptionPosition (position) {
            return get(this, 'descriptionOptions.position') === position;
        }
    }
};
</script>

<style scoped>
.copy-text-area--header {
    display: flex;
    justify-content: space-between;
}

.copy-text-area--body {
    display: flex;
    justify-content: space-between;
}

.copy-text-area--pre {
    margin-bottom: 0;
    padding: 0;
    margin-top: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.copy-text-area--helper-text {
    display: flex;
    align-items: center;
}
</style>
