import get from 'lodash/get';
import invoke from 'lodash/invoke';
import http from '@/utils/http';
import { generateAppUid } from '@/utils/apps';

export const LEGACY_APP_ID = -323232;

export function flattenTrainingAttrs (entityList, additionalProps = {}) {
    return entityList.map((entity) => {
        let flatApps;
        const { trainingAttributes, featureFlags, applications } = entity;
        const newEntity = { ...entity, ...trainingAttributes, ...additionalProps };

        if (featureFlags) newEntity.featureFlags = featureFlags;
        if (applications) {
            flatApps = flattenTrainingAttrs(applications);
            newEntity.applications = flatApps;
        }

        return newEntity;
    });
}

export function hydrateSubscriptionAccounts (subscriptions = []) {
    return subscriptions.map((sub) => {
        if (!sub.accounts) return { ...sub };

        const accounts = Object.entries(sub.accounts).reduce((map, [id, acct]) => {
            map[id] = {
                ...acct,
                id,
                uid: `${sub.id}:${id}`
            };

            return map;
        }, {});

        return { ...sub, accounts };
    });
}

export function createAccountMap (subscriptions, subscriptionId) {
    const sub = subscriptions[subscriptionId];
    const displayName = get(sub, 'displayName');
    const map = displayName
        ? {
            [displayName]: { ...sub, id: displayName }
        }
        : {};

    return map;
}

export function setUniqueAppIds (applications = []) {
    return applications.map((app) => {
        app.uid = generateAppUid(app.subscriptionId, app.id);

        return app;
    });
}

export function isValidUrl (url) {
    // element ui type url uses https://github.com/yiminghe/async-validator/blob/master/src/rule/type.js#L9
    const urlRegex = new RegExp(
        '^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$',
        'i'
    );

    return typeof url === 'string' && !!url.match(urlRegex);
}

export function isValidLookasideHost (url) {
    const urlRegex = new RegExp('^(?!https://|http://).+([a-zA-Z0-9].[a-zA-Z]{2,})(?<!/)$', 'i');

    return !url || (typeof url === 'string' && !!url.match(urlRegex)); // empty/null lookasideHost is valid
}

export function getUrlValidator (validator) {
    return (rule, value, callback) => {
        if (!validator(value)) {
            callback(new Error('URL is invalid.'));

            return;
        }
        callback();
    };
}

export function isValidLaunchDesignerForm ({ url, lookasideHost }) {
    return isValidLookasideHost(lookasideHost) && isValidUrl(url);
}

export const urlValidationRules = [
    {
        required: true,
        message: 'Please input a valid url starting with http:// or https://',
        trigger: 'change'
    },
    {
        required: true,
        message: 'Please input a valid url starting with http:// or https://',
        validator: getUrlValidator(isValidUrl),
        trigger: 'blur'
    }
];

export const lookasideHostValidationRules = [
    {
        required: false,
        message: 'Please input a valid url excluding https:// and trailing slash',
        trigger: 'change'
    },
    {
        required: false,
        message: 'Please input a valid url excluding https:// and trailing slash',
        validator: getUrlValidator(isValidLookasideHost),
        trigger: 'blur'
    }
];

export async function normalizeUrl (url) {
    // if the URL already contains encoded characters, we need to decode them first so they
    // don't get encoded again below.
    try {
        url = decodeURIComponent(url);
    } catch (err) {
        return url;
    }

    return http.post('/api/s/_SID_/helper/urlnorm', { url: encodeURI(url) }).then((res) => res.data);
}

export function inDevEnvironment () {
    const env = get(window, '__via_info__.env', '');
    if (!env.includes('prod')) return true;

    return false;
}

// use kebab case
// add existing/new Pendo track event names to use and create adopt versions with `sendTrackEvent`
export const TRACK_EVENT_NAMES = {
    guideLanguageImport: 'guide-language-import',
    resourceCenterLanguageImport: 'resource-center-language-import'
};

export function sendPendoTrackEvent ({ trackEventKey, properties = {}, adoptSpecific = true }) {
    let trackEventName = TRACK_EVENT_NAMES[trackEventKey];
    if (!trackEventName) throw new Error(`Invalid trackEventKey: ${trackEventKey}`);
    if (adoptSpecific) trackEventName = `adopt-${trackEventName}`;

    invoke(window, 'pendo.track', trackEventName, properties);
}

// periods cause google appengine's magic routing to break
export function encodeIdForUri (id) {
    const periodRegex = /\./g;

    return encodeURIComponent(id).replace(periodRegex, '%2E');
}

export function addHrefToVisitors ({ visitors = [], visitorUrl = '/analytics/visitors' }) {
    return {
        visitors: visitors
            ? visitors.map(({ id }) => ({ id, href: `${visitorUrl}/${encodeURIComponent(encodeIdForUri(id))}` }))
            : []
    };
}

export function propName (obj, type) {
    return Object.keys(obj).find((key) => obj[key] === type);
}
