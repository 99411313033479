import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import store from '@/state/store';

function setupPendoSnippet () {
    const { env } = window.__via_info__;

    let host;
    let apiKey;

    switch (env) {
        case 'prod':
        case 'prod-eu':
            host = 'content.pendo-internal.pendo.io';
            apiKey = '7de2c6a3-6665-430f-52c6-bd6c043db205';
            break;
        case 'atlas':
            host = 'pendo-atlas-static.storage.googleapis.com';
            apiKey = 'ba070a14-8710-40b6-731d-3ad15f4f99a7';
            break;
        case 'test':
            host = 'content.product.pendo-test.pendosandbox.com';
            apiKey = 'fa96db3f-7fa1-4936-4818-61063c36a040';
            break;
        case 'dev':
        case 'eu-dev':
        default:
            host = 'pendo-dev-static.storage.googleapis.com';
            apiKey = '474d9a85-2646-48f6-4b07-5d75357d8221';
            break;
    }

    const src = `https://${host}/agent/static/${apiKey}/pendo.js`;

    // eslint-disable-next-line camelcase
    window.pendo_options = {
        apiKey,
        usePendoAgentAPI: true,
        events: {
            guidesLoaded () {
                return store.dispatch('auth/updateSegmentFlags', {
                    flags: window.pendo.segmentFlags
                });
            },
            guidesFailed () {
                return store.dispatch('auth/updateSegmentFlags', {
                    flags: []
                });
            }
        }
    };

    /* eslint-disable */
    // prettier-ignore
    (function (p, e, n, d, o) {
        let v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
        for (w = 0, x = v.length; w < x; ++w) {
            (function (m) {
                o[m] = o[m] || function () {
                    o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
                };
            })(v[w]);
        }
        y = e.createElement(n);
        y.async = !0;
        y.src = src;
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
    /* eslint-enable */

    return true;
}

function identify ({ visitor, account }) {
    if (!isFunction(window.pendo.identify)) return;
    window.pendo.identify({
        visitor: {
            ...visitor
        },
        account: {
            ...account
        }
    });
}

function formatVisitorMetadata (visitor) {
    const { email, firstname, lastname, id } = visitor;

    return {
        id: email,
        name: `${firstname} ${lastname}`,
        uid: id
    };
}

function formatAccountMetadata (subscription) {
    const { name, id, partnerSubId } = subscription;

    const featureFlags = get(subscription, 'featureFlags', {});

    // named `subscriptionFlags` to be homogenous with how engage identifies sub flags
    const subscriptionFlags = Object.keys(featureFlags).reduce((acc, flagName) => {
        if (featureFlags[flagName]) {
            acc.push(flagName);
        }

        return acc;
    }, []);

    const account = {
        id: name,
        subscriptionFlags,
        sid: id
    };

    if (partnerSubId) account.partnerSubId = partnerSubId;

    return account;
}

function pendolytics (store) {
    store.subscribe(({ type }, state) => {
        const subscription = store.getters['subscriptions/active'];
        const { user } = state.auth;

        // app isn't hydrated, bail early to avoid bad data
        if (!subscription || !user) {
            return;
        }

        const visitor = formatVisitorMetadata(user);
        const account = formatAccountMetadata(subscription);

        switch (type) {
            // any store mutations that update the user/sub can be observed here
            case 'auth/setUser':
            case 'subscriptions/setActiveById':
                // identify visitor with available metadata
                identify({
                    visitor,
                    account
                });
                break;
        }
    });
}

function refreshSegmentFlags () {
    window.pendo.loadGuides();
}

export { setupPendoSnippet, pendolytics, refreshSegmentFlags };
