<template>
    <main class="portfolio-app-list">
        <pendo-table
            v-pendo-loading:feather="isFetchingList"
            :max-height="448"
            class="portfolio-app-list--table"
            :columns="columns"
            csv-download
            :data="sortedUsageList"
            :default-sort="sort"
            :filters="filters"
            :resizable="true"
            :tree-config="{ children: 'children' }"
            title=""
            @column-resize="onColumnResized"
            @sort-change="onSortChanged">
            <template #headerActions>
                <div class="portfolio-app-usage-list--table-search">
                    <search v-model="searchInput" />
                </div>
            </template>

            <template #headerLeft>
                <div class="portfolio-app-usage-list--table-header-left">
                    <div class="portfolio-app-usage-list--table-title">
                        Usage by Application
                    </div>

                    <pendo-multiselect
                        :allow-empty="false"
                        :options="periodOptions"
                        :value="period"
                        @input="onPeriodChanged">
                        <pendo-data-source-trigger slot="trigger" />
                    </pendo-multiselect>

                    <pendo-multiselect
                        v-model="selectedField"
                        :allow-empty="false"
                        :options="selectedFieldOptions">
                        <pendo-data-source-trigger slot="trigger" />
                    </pendo-multiselect>
                </div>
            </template>
            <template #empty>
                <div class="portfolio-app-usage-list--empty">
                    <pendo-icon
                        type="alert-circle"
                        class="empty-icon"
                        stroke="#9a9ca5"
                        size="24" />
                    <span class="empty-text">
                        {{ emptyText }}
                    </span>
                </div>
            </template>
        </pendo-table>
    </main>
</template>

<script>
import Search from '@/components/Search';
import { rowFormatter } from '@/utils/table-formatters';
import { getTimeSeriesByPeriod, validPeriodsForCount } from '@/utils/time-series';
import { PendoDataSourceTrigger, PendoLoading, PendoMultiselect, PendoTable, PendoIcon } from '@pendo/components';
import capitalize from 'lodash/capitalize';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { buildBreakdownDateMap } from '@/utils/portfolio-app-usage';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import { getAppUsageBreakdown } from '@/aggregations/portfolio-app-usage';
import { isCancel } from 'axios';

export default {
    name: 'PortfolioAppUsageList',
    components: {
        PendoDataSourceTrigger,
        PendoMultiselect,
        PendoTable,
        PendoIcon,
        Search
    },
    directives: {
        PendoLoading
    },
    data () {
        return {
            defaultSort: {
                prop: 'visitors',
                order: 'ascending'
            },
            searchInput: '',
            selectedField: {
                id: 'summedMinutes',
                label: 'Time Spent on Application (minutes)'
            },
            selectedFieldOptions: [
                {
                    id: 'summedMinutes',
                    label: 'Time Spent on Application (minutes)'
                },
                {
                    id: 'visitors',
                    label: 'Visitors'
                }
            ],
            appUsagePeriod: null,
            usagePeriodBreakdowns: [],
            emptyText: 'No data found. Try changing filters or selecting different applications.',
            aggCancel: null
        };
    },
    computed: {
        ...mapGetters({
            isAppIdsFilterInUse: 'filters/isAppIdsFilterInUse',
            appMapForActiveSubscription: 'apps/appMapForActiveSubscription'
        }),
        ...mapState({
            appUsageListUserSettings: (state) => state.portfolio.appUsageListUserSettings,
            activeSegmentId: (state) => state.filters.activeSegmentId,
            dateRange: (state) => state.filters.dateRange,
            appIdsFilter: (state) => state.filters.appIdsFilter,
            viewByMetadataField: (state) => state.filters.viewByMetadataField,
            isViewingApplicationsByMetadata: (state) => state.filters.isViewingApplicationsByMetadata,
            isFetchingList: (state) => state.portfolio.isFetchingList,
            metadataFieldTotal: (state) => state.portfolio.metadataFieldTotal
        }),
        sortedUsageList () {
            if (this.isViewingApplicationsByMetadata) {
                return this.appUsageSortedBreakdownsList;
            }

            return this.metadataFieldValuesUsageSortedBreakdownsList;
        },
        appUsageSortedBreakdownsList () {
            const sortedChildrensList = Object.values(this.appUsageBreakdownsMap).map((app) => {
                return {
                    ...app,
                    children: Object.values(app.children).sort((a, b) => {
                        return a.team.toLowerCase() < b.team.toLowerCase() ? -1 : 1;
                    })
                };
            });

            const sortedAppUsageBreakdownsList = sortedChildrensList.sort((a, b) => {
                return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
            });

            return sortedAppUsageBreakdownsList;
        },
        appUsageBreakdownsMap () {
            const appUsageBreakdownsMap = this.usagePeriodBreakdowns.reduce((map, period) => {
                const { time, rows } = period;
                rows.forEach((row) => {
                    const { id, name, groupValue, visitors, summedMinutes } = row;
                    map[id] = map[id] || {
                        id,
                        name, // name of application
                        team: 'All Teams',
                        breakdown: {
                            // 1635739200000: { visitors: 10 summedMinutes: 9 }
                            // 1638334800000: { visitors: 3, summedMinutes: 19 }
                        },
                        children: {
                            // product: { team: 'product', breakdown: { 1635739200000: { visitors: 6, summedMinutes: 3 }, 1638334800000: { visitors: 1, summedMinutes: 3 } } }
                            // design: { team: 'design', breakdown: { 1635739200000: { visitors: 4, summedMinutes: 6 }, 1638334800000: { visitors: 2, summedMinutes: 16 } } }
                        }
                    };

                    // capture period breakdowns for each metadata field value
                    map[id].children[groupValue] = map[id].children[groupValue] || {
                        id,
                        team: groupValue,
                        name: ' '
                    };
                    map[id].children[groupValue].breakdown = map[id].children[groupValue].breakdown || {};
                    map[id].children[groupValue].breakdown[time] = map[id].children[groupValue].breakdown[time] || {};
                    map[id].children[groupValue].breakdown[time] = {
                        ...row,
                        team: row.groupValue
                    };

                    // aggregate the children for 'All Teams'
                    map[id].breakdown[time] = map[id].breakdown[time] || { visitors: 0, summedMinutes: 0 };
                    map[id].breakdown[time].visitors += visitors;
                    map[id].breakdown[time].summedMinutes += summedMinutes;
                });

                return map;
            }, {});

            return appUsageBreakdownsMap;
        },
        metadataFieldValuesUsageSortedBreakdownsList () {
            const sortedChildrensList = Object.values(this.metadataFieldValuesUsageBreakdownsMap).map(
                (metadataField) => {
                    return {
                        ...metadataField,
                        children: Object.values(metadataField.children).sort((a, b) => {
                            return a.name < b.name ? -1 : 1;
                        })
                    };
                }
            );

            const sortedMetadataFieldValuesUsageBreakdownsList = sortedChildrensList.sort((a, b) => {
                return a.team.toLowerCase() < b.team.toLowerCase() ? -1 : 1;
            });

            return sortedMetadataFieldValuesUsageBreakdownsList;
        },
        metadataFieldValuesUsageBreakdownsMap () {
            const metadataFieldValuesUsageAppBreakdownsMap = this.usagePeriodBreakdowns.reduce((map, period) => {
                const { time, rows } = period;
                rows.forEach((row) => {
                    const { id, name, groupValue, visitors, summedMinutes } = row;
                    map[groupValue] = map[groupValue] || {
                        id: groupValue,
                        name: 'All Apps',
                        team: groupValue, // name of metadata field value
                        breakdown: {
                            // 1635739200000: { visitors: 10 summedMinutes: 9 }
                            // 1638334800000: { visitors: 3, summedMinutes: 19 }
                        },
                        children: {
                            // amazonAppId: { team: ' ', name: 'Amazon', breakdown: { 1635739200000: { visitors: 6, summedMinutes: 3 }, 1638334800000: { visitors: 1, summedMinutes: 3 } } }
                            // gmailAppId: { team: ' ', name: 'Gmail', breakdown: { 1635739200000: { visitors: 4, summedMinutes: 6 }, 1638334800000: { visitors: 2, summedMinutes: 16 } } }
                        }
                    };
                    map[groupValue].children[id] = map[groupValue].children[id] || {
                        id,
                        team: ' ',
                        name
                    };
                    map[groupValue].children[id].breakdown = map[groupValue].children[id].breakdown || {};
                    map[groupValue].children[id].breakdown[time] = map[groupValue].children[id].breakdown[time] || {};
                    map[groupValue].children[id].breakdown[time] = {
                        ...row,
                        team: row.id
                    };

                    // aggregate the children for 'All Apps'
                    map[groupValue].breakdown[time] = map[groupValue].breakdown[time] || {
                        visitors: 0,
                        summedMinutes: 0
                    };
                    map[groupValue].breakdown[time].visitors += visitors;
                    map[groupValue].breakdown[time].summedMinutes += summedMinutes;
                });

                return map;
            }, {});

            return metadataFieldValuesUsageAppBreakdownsMap;
        },
        usageBreakdownDate () {
            return Object.values(this.usageBreakdownDateMap).sort((a, b) => (a.value < b.value ? -1 : 1));
        },
        usageBreakdownDateMap () {
            return buildBreakdownDateMap(this.usagePeriodBreakdowns, this.appUsagePeriod);
        },
        columns () {
            const columns = [
                ...this.usageBreakdownDate.map((month) => {
                    return {
                        allowResize: false,
                        label: month.name,
                        prop: `breakdown.${month.id}.${this.selectedField.id}`,
                        schema: 'integer',
                        sortable: true
                    };
                })
            ];

            const appColumn = {
                fixed: true,
                label: 'Application',
                minWidth: 200,
                prop: 'name',
                schema: 'string',
                sortable: true,
                width: get(this.appUsageListUserSettings, 'sizes.name')
            };

            const teamColumn = {
                fixed: true,
                label: 'Team',
                minWidth: 200,
                prop: 'team',
                schema: 'string',
                sortable: true,
                width: get(this.appUsageListUserSettings, 'sizes.team')
            };

            if (this.isViewingApplicationsByMetadata) {
                columns.unshift(appColumn, teamColumn);
            } else {
                columns.unshift(teamColumn, appColumn);
            }

            const hasMultipleTeamsPerApp = this.isViewingApplicationsByMetadata && this.viewByMetadataField;

            if (hasMultipleTeamsPerApp || !this.isViewingApplicationsByMetadata) {
                columns.unshift({
                    type: 'tree',
                    fixed: true,
                    allowReorder: false
                });
            }

            const data = columns.map((col) => {
                if (col.type === 'tree') {
                    return {
                        ...col
                    };
                }

                return {
                    ...col,
                    formatter: (row) => rowFormatter(row, col)
                };
            });

            return data;
        },
        filters () {
            return [
                {
                    prop: ['name', 'team'],
                    value: this.searchInput
                }
            ];
        },
        period () {
            return this.periodOptions.find((period) => period.id === this.appUsagePeriod);
        },
        periodOptions () {
            const { dateRange } = this;

            return validPeriodsForCount(dateRange.count).map((period) => ({
                id: period,
                label: capitalize(period)
            }));
        },
        sort () {
            const sortUserSettings = get(this.appUsageListUserSettings, 'sort', null);
            if (!sortUserSettings) return this.defaultSort;

            const sortedColumnDoesNotExist = !this.columns.find((column) => column.prop === sortUserSettings.prop);
            if (sortedColumnDoesNotExist) return this.defaultSort;

            return sortUserSettings;
        }
    },
    created () {
        this.refreshTable();

        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, () => this.refreshTable());
    },
    destroyed () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        ...mapMutations({
            setFetchingList: 'portfolio/setFetchingList'
        }),
        ...mapActions({
            updateAppUsageListUserSettings: 'portfolio/updateAppUsageListUserSettings',
            updateAppUsagePeriod: 'portfolio/updateAppUsagePeriod'
        }),
        async refreshTable () {
            this.setFetchingList({ isFetchingList: true });

            if (this.aggCancel) {
                this.aggCancel.abort();
            }

            this.aggCancel = new AbortController();

            try {
                const {
                    activeSegmentId: segmentId,
                    appIdsFilter: appId,
                    dateRange,
                    viewByMetadataField: metadataField
                } = this;

                const periods = validPeriodsForCount(dateRange.count);
                let period = this.appUsagePeriod;
                if (!periods.includes(period)) {
                    period = periods[periods.length - 1];
                    this.appUsagePeriod = period;
                }

                const timeSeries = getTimeSeriesByPeriod(period, dateRange);

                const usagePeriodBreakdowns = (await getAppUsageBreakdown({
                    appId,
                    metadataField,
                    defaultMetadataField: this.metadataFieldTotal,
                    timeSeries,
                    segmentId,
                    signal: this.aggCancel.signal
                })).messages;

                this.usagePeriodBreakdowns = usagePeriodBreakdowns.map((period) => {
                    return {
                        ...period,
                        rows: period.rows.map((app) => ({
                            ...app,
                            name: get(this.appMapForActiveSubscription, `${app.id}.displayName`, '')
                        }))
                    };
                });

                this.setFetchingList({ isFetchingList: false });
            } catch (err) {
                if (!isCancel(err)) {
                    this.setFetchingList({ isFetchingList: false });
                }
            }
        },
        onColumnResized ({ column, width }) {
            const appUsageListUserSettings = cloneDeep(this.appUsageListUserSettings) || {};
            if (!appUsageListUserSettings.sizes) {
                appUsageListUserSettings.sizes = {};
            }

            appUsageListUserSettings.sizes[column] = width;
            this.updateAppUsageListUserSettings({
                appUsageListUserSettings
            });
        },
        onPeriodChanged (period) {
            const { id } = period;
            this.appUsagePeriod = id;
            this.refreshTable();
        },
        onSortChanged ({ order, prop }) {
            if (this.isFetchingList) {
                return;
            }

            const appUsageListUserSettings = cloneDeep(this.appUsageListUserSettings) || {};
            appUsageListUserSettings.sort = {
                order,
                prop
            };
            this.updateAppUsageListUserSettings({
                appUsageListUserSettings
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.portfolio-app-usage-list {
    &--table-header-left {
        align-items: center;
        display: flex;
        gap: 8px;
    }

    &--empty {
        display: flex;
        align-items: center;
        justify-content: center;

        .pendo-icon {
            margin-right: 0.5em;
            display: flex;
        }

        .empty-text {
            color: $gray-primary;
        }
    }
}

/deep/ td.pendo-table__column:first-of-type {
    .pendo-table__cell:empty::after {
        content: '';
    }
}
</style>
