<template>
    <page-header
        :editable="true"
        :title="name"
        :show-banner="true"
        :specific-app="app"
        :input-props="{ maxlength: 72 }"
        :before-exit="confirmGuideName"
        class="guide-details-header">
        <template #top>
            <div class="guide-details-header--back">
                <router-link to="/guides">
                    <pendo-button
                        theme="via"
                        type="link"
                        size="mini"
                        prefix-icon="chevron-left"
                        label="Back to Guide List" />
                </router-link>
            </div>
        </template>
        <template #bottom>
            <div class="guide-details-header--details">
                <div
                    v-if="createdBy"
                    class="created-by">
                    <span>Created by {{ createdBy }}</span>
                    <span
                        v-if="createdBy && lastUpdatedBy"
                        class="details-spacer">
                        |
                    </span>
                </div>
                <div
                    v-if="lastUpdatedBy"
                    class="last-updated-by">
                    Last updated by {{ lastUpdatedBy }}
                </div>
            </div>
        </template>
        <template #actions>
            <guide-status-changer
                :guide="guide"
                class="guide-status-changer"
                guide-type="organization" />
            <guide-utilities
                :guide="guide"
                @openGuideCopyModal="openGuideCopyModal">
                <template #default="{ toggleMenu }">
                    <pendo-button
                        theme="via"
                        type="secondary"
                        icon="more-horizontal"
                        icon-size="18"
                        @click="toggleMenu" />
                </template>
            </guide-utilities>
        </template>
    </page-header>
</template>

<script>
import get from 'lodash/get';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { PendoButton } from '@pendo/components';
import PageHeader from '@/components/PageHeader';
import GuideStatusChanger from '@/components/guides/common/GuideStatusChanger';
import GuideUtilities from '@/components/guides/common/GuideUtilities';

export default {
    name: 'GuideDetailsHeader',
    components: {
        PageHeader,
        PendoButton,
        GuideStatusChanger,
        GuideUtilities
    },
    computed: {
        ...mapGetters({
            guide: 'guides/active',
            appFromGuide: 'apps/appFromGuide'
        }),
        name () {
            return get(this.guide, 'name');
        },
        createdBy () {
            return get(this.guide, 'createdByUser.username', null);
        },
        lastUpdatedBy () {
            return get(this.guide, 'lastUpdatedByUser.username', null);
        },
        app () {
            return this.appFromGuide(this.guide);
        }
    },
    methods: {
        ...mapMutations({
            setStatusConfirmationOpen: 'guides/setStatusConfirmationOpen',
            setPendingStatus: 'guides/setPendingStatus'
        }),
        ...mapActions({
            patchGuide: 'guides/patch'
        }),
        async confirmGuideName (name) {
            // dont save if it didnt change
            if (name === this.guide.name) {
                return;
            }

            await this.patchGuide({
                guideId: this.guide.id,
                props: {
                    name
                }
            });
        },
        openGuideCopyModal () {
            this.$emit('openGuideCopyModal');
        }
    }
};
</script>

<style lang="scss">
.guide-details-header {
    &--button-group {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-gap: 8px;
        align-items: center;
        justify-content: end;
    }

    &--details {
        color: $gray-lighter-2;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: 36px;
        font-size: 0.875em;
        @include ellipsis;

        .details-spacer {
            margin-left: 0.5em;
            margin-right: 0.5em;
        }
    }
}
</style>
