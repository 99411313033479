<template>
    <pendo-multiselect
        ref="viewBy"
        :placeholder="placeholderText"
        :loading="isFetchingMetadata"
        :options="viewByOptions"
        label-key="displayName"
        @select="onMetadataFieldSelection">
        <template #header>
            <div class="view-by__header">
                <div
                    class="pendo-multiselect__option"
                    @click="onMetadataFieldSelection('')">
                    <span class="pendo-multiselect__element">
                        Total
                    </span>
                </div>
            </div>
        </template>
    </pendo-multiselect>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import sortBy from 'lodash/sortBy';
import { PendoMultiselect } from '@pendo/components';

const MAX_CARDINALITY = 299;

export default {
    name: 'ViewBy',
    components: {
        PendoMultiselect
    },
    data () {
        return {
            isFetchingMetadata: false
        };
    },
    computed: {
        ...mapState({
            metadataMap: (state) => state.metadata.map,
            viewByMetadataField: (state) => state.filters.viewByMetadataField
        }),
        ...mapGetters({
            metadataListByKind: 'metadata/metadataListByKind'
        }),
        placeholderText () {
            if (!this.viewByMetadataField) {
                return 'View by Total';
            }

            const { displayName } = this.viewByMetadataField;

            return displayName;
        },
        viewByOptions () {
            const visitorMetadata = this.metadataListByKind('visitor', { groupsToExclude: ['auto', 'pendo'] });
            // Aggs break when using a group by on a metadata field that has 300 or more unique values (cardinality)
            const availableVisitorMetadata = visitorMetadata.filter(
                (singleField) => singleField.cardinality <= MAX_CARDINALITY
            );
            const visitorMetaDataWithViewByDisplayName = availableVisitorMetadata.map(({ DisplayName, ...rest }) => ({
                ...rest,
                displayName: `View by ${DisplayName}`
            }));

            return sortBy(visitorMetaDataWithViewByDisplayName, [({ displayName }) => displayName.toLowerCase()]);
        }
    },
    async created () {
        this.loadViewByMetadata();
    },
    methods: {
        ...mapActions({
            loadMetadata: 'metadata/loadAll',
            updateViewByMetadataField: 'filters/updateViewByMetadataField'
        }),
        ...mapMutations({
            setViewByMetadataField: 'filters/setViewByMetadataField'
        }),
        async loadViewByMetadata () {
            this.isFetchingMetadata = true;
            await this.loadMetadata();
            this.isFetchingMetadata = false;
        },
        onMetadataFieldSelection (metadata) {
            const { group: currentGroup, field: currentField, kind } = this.viewByMetadataField || {};
            const { group: selectedGroup, field: selectedField } = metadata;
            if (currentGroup === selectedGroup && currentField === selectedField && kind === 'visitor') {
                this.$refs.viewBy.toggleMenu();

                return;
            }
            this.updateViewByMetadataField({ metadata });
            this.$refs.viewBy.toggleMenu();
        }
    }
};
</script>

<style lang="scss">
.view-by__header {
    padding: 8px 0;
    border-bottom: 1px solid $gray-lighter-6;
    overscroll-behavior: none;

    .pendo-multiselect__element,
    .pendo-multiselect__option {
        height: 26px;

        &:hover {
            background-color: $gray-lighter-7;
        }
    }
}
</style>
