<template>
    <segment-builder
        :visible="true"
        :app-id="activeApp.id"
        :app-first-visit="firstVisit"
        :editing-segment="editingSegment"
        :schema-list="schemaList"
        :guide-list="guideList"
        :rc-modules-list="rcModulesList"
        :guide-map="guideMap"
        :draft-resource-center-map="draftResourceCenterMap"
        :page-list="pageList"
        :page-map="pageMap"
        :feature-list="featureList"
        :feature-map="featureMap"
        :app-list="listAllForActiveSubscription"
        :app-map="appMapForActiveSubscription"
        :saving="isSavingSegment"
        :guide-targeting="editingGuideSegment"
        @save="onSegmentSave"
        @close="closeSegmentBuilder" />
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import http from '@/utils/http';
import SegmentBuilder from '@/components/segments/builder/SegmentBuilder';
import { isSegmentAttachedToGuide, isValidSegmentInContext } from '@/utils/segments';

export default {
    name: 'AppSegmentBuilder',
    components: {
        SegmentBuilder
    },
    props: {
        editingSegment: {
            type: Object,
            default: null
        },
        limitForGuideUsage: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isSavingSegment: false
        };
    },
    computed: {
        ...mapState({
            segmentsMap: (state) => state.filters.segmentsMap,
            guideMap: (state) => state.guides.map,
            pageMap: (state) => state.pages.map,
            featureMap: (state) => state.features.map
        }),
        ...mapGetters({
            segmentsListByGroup: 'filters/segmentsListByGroup',
            listAllForActiveSubscription: 'apps/listAllForActiveSubscription',
            appMapForActiveSubscription: 'apps/appMapForActiveSubscription',
            activeGlobalSegment: 'filters/activeSegment',
            schemaList: 'filters/schemaList',
            firstVisit: 'apps/firstVisit',
            guideList: 'guides/list',
            rcModulesList: 'guides/rcModulesList',
            draftResourceCenterMap: 'resourceCenter/draftResourceCenterMap',
            pageList: 'pages/list',
            featureList: 'features/list',
            activeApp: 'apps/active'
        }),
        editingGuideSegment () {
            return this.limitForGuideUsage || isSegmentAttachedToGuide(this.editingSegment, this.guideMap);
        }
    },
    created () {
        this.fetchSegments();
        this.fetchSchema({ forceRefresh: false });
        this.fetchPages();
        this.fetchGuides();
        this.fetchAllResourceCenters();
        this.fetchFeatures();
    },
    methods: {
        ...mapActions({
            fetchSegments: 'filters/fetchSegments',
            fetchSchema: 'filters/fetchSchema',
            fetchPages: 'pages/fetch',
            fetchGuides: 'guides/fetch',
            fetchAllResourceCenters: 'resourceCenter/fetchAll',
            fetchFeatures: 'features/fetch',
            updateSegment: 'filters/updateSegment'
        }),
        ...mapMutations({
            setNewSegment: 'filters/setNewSegment'
        }),
        async onSegmentSave ({ segment }) {
            const isValid = isValidSegmentInContext(segment, {
                guideMap: this.guideMap,
                usedForGuideTargeting: this.guideTargeting
            });
            if (!isValid) return;

            this.isSavingSegment = true;

            let finalSegment;

            if (segment.id) {
                await this.updateSegment({ segment });
                finalSegment = this.segmentsMap[segment.id];
            } else {
                const { data } = await http.post('/api/s/_SID_/segment', segment);
                this.setNewSegment({ segment: data });
                finalSegment = data;
            }

            this.closeSegmentBuilder();
            this.$emit('save', finalSegment);
            this.isSavingSegment = false;
        },
        closeSegmentBuilder () {
            this.$emit('close');
        }
    }
};
</script>
