<template>
    <ul class="poll-details">
        <li>
            <div class="label">
                App
            </div>
            <div>{{ appName }}</div>
        </li>
        <li>
            <div class="label">
                Poll Type
            </div>
            <div>{{ pollType }}</div>
        </li>
        <li>
            <div class="label">
                Poll Question
            </div>
            <div>{{ poll.question }}</div>
        </li>
        <li>
            <div class="label">
                Poll Step
            </div>
            <div>{{ stepNumber }}</div>
        </li>
        <li>
            <div class="label">
                Visitors Seen
            </div>
            <div>{{ visitorCount }}</div>
        </li>
    </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import { getGuideStepVisitorCount } from '@/aggregations/guide-views';
import get from 'lodash/get';
import includes from 'lodash/includes';

export default {
    name: 'PollDetails',
    props: {
        poll: {
            type: Object,
            required: true
        },
        timeSeries: {
            type: Object,
            required: true
        },
        segment: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            visitorCount: '--'
        };
    },
    computed: {
        ...mapGetters({
            app: 'guides/activeGuideApp',
            guide: 'guides/active'
        }),
        appId () {
            return get(this.guide, 'appId', '');
        },
        pollType () {
            return {
                FreeForm: 'Open Text',
                PickList: 'Multiple Choice',
                PositiveNegative: 'Yes / No',
                NumberScale: 'Number Scale'
            }[get(this, 'poll.attributes.type')];
        },
        appName () {
            return get(this, 'app.trainingAttributes.displayName');
        },
        step () {
            return this.guide.steps.find((step) => includes(step.pollIds, this.poll.id));
        },
        stepNumber () {
            return this.guide.steps.indexOf(this.step) + 1;
        },
        segmentId () {
            const id = get(this.segment, 'id');
            const isEveryone = id === 'everyone';

            return isEveryone ? null : id;
        }
    },
    watch: {
        timeSeries () {
            this.getVisitorCounts(this.segmentId, this.timeSeries);
        },
        segment () {
            this.getVisitorCounts(this.segmentId, this.timeSeries);
        }
    },
    created () {
        this.getVisitorCounts(this.segmentId, this.timeSeries);
    },
    methods: {
        async getVisitorCounts (segmentId, timeSeries) {
            const [{ numVisitors }] = await getGuideStepVisitorCount({
                appId: this.appId,
                timeSeries,
                guideId: this.guide.id,
                guideStepId: this.step.id,
                segmentId
            });
            this.visitorCount = numVisitors;
        }
    }
};
</script>

<style lang="scss">
.poll-details {
    margin: 0;
    padding: 8px 16px;
    list-style: none;

    > li {
        border-bottom: 1px solid $gray-lighter-5;

        > * {
            display: inline-block;
            padding: 9px 0;
        }

        > .label {
            width: 145px;
        }

        &:last-child {
            border-bottom: none;
        }
    }
}
</style>
