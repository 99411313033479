<template>
    <pendo-card
        v-pendo-loading:feather="isFetching"
        :title="chartLabel"
        class="page-feature-usage-over-time"
        data-cy="page-feature-usage-over-time-chart">
        <template #title>
            <div class="page-feature-usage-over-time__title">
                <span>{{ chartLabel }}:</span>
                <pendo-multiselect
                    :value="selectedPeriod"
                    :allow-empty="false"
                    :options="periodOptions"
                    @select="changeSelectedPeriod">
                    <pendo-data-source-trigger slot="trigger" />
                </pendo-multiselect>
                <pendo-multiselect
                    :value="selectedMetric"
                    :allow-empty="false"
                    :options="metricOptions"
                    @select="changeSelectedMetric">
                    <pendo-data-source-trigger slot="trigger" />
                </pendo-multiselect>
            </div>
        </template>
        <template #headerRight>
            <checkbox-multi-select
                class="pages-checkbox-select"
                :options="pageOptions"
                :selected-options="localSelectedPages"
                :search-filter="filterPagesByNameOrUrl"
                entity-type="pages"
                @select="updateChartPageSelections" />
            <pendo-divider
                v-if="showFeaturesWidgets"
                width="40px"
                direction="vertical" />
            <checkbox-multi-select
                v-if="showFeaturesWidgets"
                class="features-checkbox-select"
                :options="featureOptions"
                :selected-options="localSelectedFeatures"
                :search-filter="filterFeaturesByName"
                entity-type="features"
                @select="updateChartFeatureSelections" />
        </template>
        <template
            v-if="selectedEntities.length && chart && !isFetching"
            #filters>
            <pendo-tag
                v-for="entity in selectedEntities"
                :key="entity.id"
                :prefix-icon="{
                    type: 'circle',
                    stroke: chart.get(entity.id) && chart.get(entity.id).color,
                    fill: chart.get(entity.id) && chart.get(entity.id).color,
                    size: 12
                }"
                :label="entity.displayName"
                type="filter" />
        </template>
        <div class="page-feature-usage-over-time__chart">
            <pendo-empty-state
                v-if="(chartEmpty || chartEmptyByAppFilter) && !isFetching"
                :icon="{ type: 'page', size: 32, 'stroke-width': 1.5 }"
                :title="emptyChartTitle"
                :description="emptyChartDescription"
                class="page-feature-usage-over-time__empty" />
            <div
                ref="highchartsContainer"
                class="pendo-highcharts-container" />
        </div>
    </pendo-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import { isCancel } from 'axios';

import {
    PendoCard,
    PendoDataSourceTrigger,
    PendoEmptyState,
    PendoLoading,
    PendoDivider,
    PendoMultiselect,
    PendoTag,
    PendoTooltip
} from '@pendo/components';
import Highcharts, { LINE_CHART_BASE, chartColors } from '@/utils/highcharts';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import { getSubscriptionUtcOffset } from '@/utils/moment';
import { pageFeatureUsageTooltipFormatter, validPeriodsForCount, getTimeSeriesByPeriod } from '@/utils/time-series';
import { filterPagesByNameOrUrl } from '@/utils/pages';
import { getPageUsageOverTime } from '@/aggregations/page-usage-over-time';
import { getFeatureUsageOverTime } from '@/aggregations/feature-usage-over-time';
import CheckboxMultiSelect from '@/components/common/CheckboxMultiSelect.vue';
import { filterEntitiesByAppIds } from '@/utils/apps';

export default {
    name: 'PageFeatureUsageOverTime',
    components: {
        PendoCard,
        PendoDataSourceTrigger,
        PendoEmptyState,
        PendoMultiselect,
        PendoDivider,
        PendoTag,
        CheckboxMultiSelect
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    data () {
        return {
            chart: null,
            chartConfig: null,
            unsubscribeFilterBarListener: null,
            localSelectedPages: [],
            localSelectedFeatures: [],
            selectedPeriod: null,
            selectedMetric: null,
            isFetchingPageFeatureUsageOverTime: false,
            pageUsageOverTime: [],
            featureUsageOverTime: [],
            aggCancel: null
        };
    },
    computed: {
        ...mapGetters({
            pageList: 'pages/list',
            featureList: 'features/list',
            showFeaturesWidgets: 'features/showFeaturesWidgets',
            isAppIdsFilterInUse: 'filters/isAppIdsFilterInUse',
            activeAppId: 'apps/activeId',
            usesMultiApp: 'subscriptions/usesMultiApp'
        }),
        ...mapState({
            activeDateRange: (state) => state.filters.dateRange,
            activeSegmentId: (state) => state.filters.activeSegmentId,
            appIdsFilter: (state) => state.filters.appIdsFilter,
            isFetchingFeatureList: (state) => state.features.isFetching,
            isFetchingPageList: (state) => state.pages.isFetching,
            pagesMap: (state) => state.pages.map,
            pageUsageChart: (state) => state.analytics.pageUsageChart
        }),
        chartLabel () {
            if (!this.showFeaturesWidgets) {
                return 'Page Usage Over Time';
            }

            return 'Page & Feature Usage Over Time';
        },
        emptyChartTitle () {
            if (this.chartEmptyByAppFilter) {
                return `Selected Pages${
                    this.showFeaturesWidgets ? ' and Features ' : ''
                } do not match Application Filter`;
            }

            if (!this.showFeaturesWidgets) {
                return 'No Pages Selected';
            }

            return 'No Pages or Features Selected';
        },
        emptyChartDescription () {
            if (this.chartEmptyByAppFilter) {
                return 'Try updating or clearing the application filter to see usage data.';
            }

            if (!this.showFeaturesWidgets) {
                return 'Add pages to track their usage over time.';
            }

            return 'Add pages or features to track their usage over time.';
        },
        chartEmpty () {
            return !this.localSelectedPages.length && !this.localSelectedFeatures.length;
        },
        chartEmptyByAppFilter () {
            if (this.chartEmpty || !this.usesMultiApp) return false;

            return !this.selectedEntities.length;
        },
        isFetching () {
            let isFetchingEntities = this.isFetchingPageList;

            if (this.showFeaturesWidgets) {
                isFetchingEntities = isFetchingEntities || this.isFetchingFeatureList;
            }

            return isFetchingEntities || this.isFetchingPageFeatureUsageOverTime;
        },
        selectedEntities () {
            if (this.usesMultiApp) {
                const featuresForAppIdsFilter = filterEntitiesByAppIds(this.localSelectedFeatures, this.appIdsFilter);
                const pagesForAppIdsFilter = filterEntitiesByAppIds(this.localSelectedPages, this.appIdsFilter);

                return [...pagesForAppIdsFilter, ...featuresForAppIdsFilter];
            }

            return [...this.localSelectedPages, ...this.localSelectedFeatures];
        },
        savedPageUsageIds () {
            return get(this, 'pageUsageChart.pageIds', []);
        },
        savedFeatureUsageIds () {
            return get(this, 'pageUsageChart.featureIds', []);
        },
        pageOptions () {
            const pageOptions = this.pageList.map((page) => this.buildPageFeatureOption(page));

            if (!this.isAppIdsFilterInUse) return pageOptions;

            const pagesForSelectedApps = filterEntitiesByAppIds(pageOptions, this.appIdsFilter);

            return pagesForSelectedApps;
        },
        featureOptions () {
            const featureOptions = this.featureList.map((feature) => this.buildPageFeatureOption(feature));

            if (!this.isAppIdsFilterInUse) return featureOptions;

            const featuresForSelectedApps = filterEntitiesByAppIds(featureOptions, this.appIdsFilter);

            return featuresForSelectedApps;
        },
        periodOptions () {
            const periods = validPeriodsForCount(this.activeDateRange.count);

            return periods.map((id) => {
                return {
                    label: capitalize(id),
                    id
                };
            });
        },
        metricOptions () {
            const options = [
                {
                    label: 'Visitors',
                    id: 'visitors'
                }
            ];

            const viewsOption = {
                label: 'Views',
                id: 'views'
            };

            if (this.showFeaturesWidgets) {
                viewsOption.label = 'Views / Clicks';
            }

            return [viewsOption, ...options];
        },
        pageSeriesData () {
            if (!this.localSelectedPages.length) return [];

            return this.pageUsageOverTime.map((pageData, index) => {
                const data = pageData.map(({ value, timestamp }) => {
                    return {
                        x: timestamp,
                        y: value,
                        entityType: 'page'
                    };
                });

                return {
                    id: this.localSelectedPages[index].id,
                    name: this.localSelectedPages[index].displayName,
                    entityType: 'page',
                    data,
                    showInLegend: false,
                    marker: { enabled: false, symbol: 'circle' }
                };
            });
        },
        featureSeriesData () {
            if (!this.localSelectedFeatures.length) return [];

            return this.featureUsageOverTime.map((featureData, index) => {
                const data = featureData.map(({ value, timestamp }) => {
                    return {
                        x: timestamp,
                        y: value,
                        entityType: 'feature'
                    };
                });

                return {
                    id: this.localSelectedFeatures[index].id,
                    name: this.localSelectedFeatures[index].displayName,
                    data,
                    showInLegend: false,
                    marker: { enabled: false, symbol: 'circle' }
                };
            });
        },
        chartSeries () {
            return [...this.pageSeriesData, ...this.featureSeriesData];
        },
        yAxisLabel () {
            const metric = this.selectedMetric || this.metricOptions[0];

            return metric.label;
        },
        xDateFormat () {
            switch (this.selectedPeriod) {
                case 'monthly':
                    return 'Month of %b %d';
                case 'weekly':
                    return 'Week of %b %d';
                default:
                    return '%b %d';
            }
        }
    },
    watch: {
        pageUsageOverTime () {
            this.populateChartWithNewData();
        },
        featureUsageOverTime () {
            this.populateChartWithNewData();
        }
    },
    destroyed () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    async created () {
        this.selectedMetric = this.metricOptions[0];

        await Promise.all([this.fetchPages({ noCache: true }), this.fetchFeatures({ noCache: true })]);

        this.localSelectedPages = this.buildSelectedPages();
        this.localSelectedFeatures = this.buildSelectedFeatures();

        await this.changeSelectedPeriod();

        this.chartConfig = this.getChartConfig();
        if (this.$refs.highchartsContainer) {
            this.chart = Highcharts.chart(this.$refs.highchartsContainer, this.chartConfig);
        }

        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, () => {
            this.changeSelectedPeriod(this.selectedPeriod);
        });
    },
    methods: {
        ...mapActions({
            fetchPages: 'pages/fetch',
            fetchFeatures: 'features/fetch',
            updateAppNamespaceSetting: 'userSettings/updateAppNamespaceSetting',
            updateSubNamespaceSetting: 'userSettings/updateSubNamespaceSetting'
        }),
        ...mapMutations({
            setPageUsageChart: 'analytics/setPageUsageChart'
        }),
        filterPagesByNameOrUrl,
        filterFeaturesByName (features, searchString) {
            return features.filter((feature) => {
                return feature.displayName.toLowerCase().includes(searchString.toLowerCase());
            });
        },
        buildPageFeatureOption (entity) {
            let { displayName } = entity;

            if (this.usesMultiApp && entity.app) {
                displayName = `${entity.app.displayName} > ${entity.displayName}`;
            }

            return {
                displayName,
                id: entity.id,
                app: entity.app,
                appId: entity.appId
            };
        },
        buildSelectedPages () {
            if (this.isFetching || !this.savedPageUsageIds) return [];

            return this.savedPageUsageIds.reduce((acc, pageId) => {
                const page = this.pageList.find((page) => page.id === pageId);
                if (!page) return acc;

                const pageOption = this.buildPageFeatureOption(page);

                acc.push(pageOption);

                return acc;
            }, []);
        },
        buildSelectedFeatures () {
            if (!this.showFeaturesWidgets) return [];

            if (this.isFetching || !this.savedFeatureUsageIds) return [];

            return this.savedFeatureUsageIds.reduce((acc, featureId) => {
                const feature = this.featureList.find((feature) => feature.id === featureId);
                if (!feature) return acc;

                const featureOption = this.buildPageFeatureOption(feature);
                acc.push(featureOption);

                return acc;
            }, []);
        },
        async changeSelectedPeriod (selectedPeriod) {
            const selectedPeriodId = get(selectedPeriod, 'id');
            const validPeriodOption = this.periodOptions.find((period) => period.id === selectedPeriodId);
            const timePeriod = validPeriodOption || this.periodOptions[0];

            this.selectedPeriod = timePeriod;

            await this.refreshChartSelections();
        },
        async changeSelectedMetric (selectedMetric) {
            this.selectedMetric = selectedMetric;
            await this.refreshChartSelections();
        },
        getChartConfig () {
            const vm = this;
            const config = { ...LINE_CHART_BASE };

            config.series = this.chartSeries;
            config.tooltip = {
                shared: true,
                useHTML: true,
                className: 'page-feature-usage-over-time-tooltip',
                formatter () {
                    return pageFeatureUsageTooltipFormatter.call(
                        this,
                        vm.xDateFormat,
                        vm.selectedMetric,
                        Highcharts.dateFormat
                    );
                },
                borderColor: '#2A2C35'
            };
            config.chart.height = 316;
            config.plotOptions = {
                line: {
                    softThreshold: false
                }
            };
            config.yAxis[0].title.text = this.yAxisLabel;
            config.colors = chartColors;
            config.time = {
                timezoneOffset: getSubscriptionUtcOffset()
            };

            return config;
        },
        populateChartWithNewData () {
            if (this.isFetchingPageFeatureUsageOverTime || !this.chart) {
                return;
            }

            // handle dynamic addition/removal of plot lines
            while (this.chart.series.length > 0) {
                this.chart.series[0].remove(true);
            }

            this.chartSeries.forEach((newData) => {
                this.chart.addSeries({ data: newData });
            });

            const { xAxis, yAxis } = this.getChartConfig();

            this.chart.update({
                xAxis,
                yAxis,
                series: this.chartSeries,
                plotOptions: {
                    series: {
                        tooltip: { xDateFormat: this.xDateFormat }
                    }
                }
            });
        },
        async updateChartFeatureSelections (selectedFeatures) {
            const hasChanged = this.hasSelectionChanged(selectedFeatures, this.localSelectedFeatures);

            if (!hasChanged) return;

            this.localSelectedFeatures = selectedFeatures;
            await this.refreshChartSelections();
        },
        async updateChartPageSelections (selectedPages) {
            const hasChanged = this.hasSelectionChanged(selectedPages, this.localSelectedPages);

            if (!hasChanged) return;

            this.localSelectedPages = selectedPages;
            await this.refreshChartSelections();
        },
        hasSelectionChanged (originalSelection, newSelection) {
            return !isEqual(sortBy(originalSelection, 'id'), sortBy(newSelection, 'id'));
        },
        async refreshChartSelections () {
            let features = this.localSelectedFeatures;
            let pages = this.localSelectedPages;

            // Collect selected page/feature ids BEFORE filtering by app
            // This ensures the user doesn't lose their selections when they change the app filter
            const featureIds = features.map((feature) => feature.id);
            const pageIds = pages.map((page) => page.id);

            if (this.usesMultiApp) {
                features = filterEntitiesByAppIds(this.localSelectedFeatures, this.appIdsFilter);
                pages = filterEntitiesByAppIds(this.localSelectedPages, this.appIdsFilter);
            }

            this.updateUserSettingsIfChanged({ pageIds, featureIds });

            this.setPageUsageChart({ pageUsageChart: { pageIds, featureIds } });
            await this.fetchPageFeatureUsageOverTime({
                appId: this.activeAppId,
                chartPages: pages,
                chartFeatures: features,
                usageMetric: this.selectedMetric.id,
                timePeriod: this.selectedPeriod.id,
                dateRange: this.activeDateRange,
                segmentId: this.activeSegmentId,
                noCache: true
            });
        },
        updateUserSettingsIfChanged ({ pageIds, featureIds }) {
            const samePageIds = isEqual(pageIds.sort(), this.savedPageUsageIds.slice().sort());
            const sameFeatureIds = isEqual(featureIds.sort(), this.savedFeatureUsageIds.slice().sort());

            if (samePageIds && sameFeatureIds) return;

            const userSettings = {
                name: 'pageUsageChart',
                value: JSON.stringify({ pageIds, featureIds })
            };

            if (this.usesMultiApp) {
                return this.updateSubNamespaceSetting(userSettings);
            }

            this.updateAppNamespaceSetting(userSettings);
        },
        async fetchPageFeatureUsageOverTime ({
            appId,
            chartPages,
            chartFeatures,
            usageMetric,
            timePeriod,
            dateRange,
            segmentId
        }) {
            const noEntitiesToRequest = !chartPages.length && !chartFeatures.length;
            if (noEntitiesToRequest) {
                return;
            }

            if (this.aggCancel) {
                this.aggCancel.abort();
            }

            this.aggCancel = new AbortController();
            this.isFetchingPageFeatureUsageOverTime = true;

            const period = timePeriod;

            try {
                const pagePromises = chartPages.map((page) => {
                    return getPageUsageOverTime({
                        appId: this.usesMultiApp ? page.appId : appId,
                        pageId: page.id,
                        timeSeries: getTimeSeriesByPeriod(period, dateRange),
                        metric: usageMetric.replace(/s$/, ''),
                        segmentId,
                        signal: this.aggCancel.signal
                    });
                });

                const featurePromises = chartFeatures.map((feature) => {
                    return getFeatureUsageOverTime({
                        appId: this.usesMultiApp ? feature.appId : appId,
                        featureId: feature.id,
                        timeSeries: getTimeSeriesByPeriod(period, dateRange),
                        metric: usageMetric.replace(/s$/, ''),
                        segmentId,
                        signal: this.aggCancel.signal
                    });
                });

                const [pageUsageOverTime, featureUsageOverTime] = await Promise.all([
                    Promise.all(pagePromises),
                    Promise.all(featurePromises)
                ]);

                this.pageUsageOverTime = pageUsageOverTime;
                this.featureUsageOverTime = featureUsageOverTime;
                this.isFetchingPageFeatureUsageOverTime = false;
                this.aggCancel = null;
            } catch (err) {
                // If we've cancelled our aggs because a segment/app/whatever changed, things are still loading
                if (!isCancel(err)) {
                    this.isFetchingPageFeatureUsageOverTime = false;
                }
            }
        }
    }
};
</script>
<style lang="scss">
.page-feature-usage-over-time-tooltip {
    z-index: 1;
}

.page-feature-usage-over-time {
    overflow-y: visible;
    z-index: 1;

    &__title {
        .pendo-multiselect {
            margin-left: 8px;
        }
    }

    &__chart {
        position: relative;
    }

    &__empty {
        margin-top: 16px;
        margin-bottom: 24px;
        z-index: 1;
    }

    .pendo-card__filters {
        .pendo-tag,
        .pendo-tag + .pendo-tag {
            margin: 0px 8px 8px 0px;
        }
    }

    &__modal {
        .pendo-card__body {
            padding: 0;
        }

        &__body {
            display: flex;
            flex-direction: column;

            &--description {
                margin-top: 0;
                margin-bottom: 4px;
            }

            &--row {
                display: flex;
                margin-bottom: 28px;

                &-existing {
                    flex-grow: 1;
                    margin-right: 18px;
                }

                &-new {
                    flex-grow: 1;
                    margin-right: 36px;
                }
            }
        }
    }
}
</style>
