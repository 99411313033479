var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mappings-table"},[_c('pendo-modal',{attrs:{"height":"auto","max-height":"85%","append-to-body":"","show-close":false,"visible":_vm.showRemoveSchemaModal,"confirm-button-config":{ type: 'danger', label: 'Confirm', loading: _vm.removingSchema },"title":"Remove Metadata?","type":"confirmation"},on:{"update:visible":function($event){_vm.showRemoveSchemaModal=$event},"confirm":_vm.onConfirmRemoveSchema,"cancel":_vm.onCancelRemoveSchema}},[(_vm.showRemoveSchemaModal)?[_c('div',{staticClass:"remove-modal__description"},[_vm._v(" Are you sure you want to remove this data mapping? It will no longer be available within the Pendo UI. ")]),_c('div',{staticClass:"remove-modal__schema-label"},[_vm._v(" Removing: ")]),_c('div',{staticClass:"remove-modal__schema-name"},[_vm._v(" "+_vm._s(_vm.pendingRemoveSchema.DisplayName || _vm.pendingRemoveSchema.field)+" ")])]:_vm._e()],2),_c('pendo-table',{staticClass:"data-mappings-table",attrs:{"data":_vm.filteredSchemas,"columns":_vm.columns,"row-key":"uniqueKey","auto-height":true,"title":"Metadata","loading":_vm.loading},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var row = ref.row;
return [_c('pendo-tag',{staticClass:"metadata-group-tag",class:("metadata-group-tag--" + (row.group)),attrs:{"label":row.groupDisplayName,"color":_vm.getTagHexColor(row.group),"size":"small"}})]}},{key:"field",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"metadata-name"},[(!row.default && !row.custom && !_vm.isDynamicGroup(row))?_c('pendo-editable-content',{attrs:{"value":row.DisplayName || row.field,"input-props":{ size: 'small' },"before-exit":_vm.onDisplayNameChange.bind(null, row.field),"empty-text":"Add Custom Field"},on:{"enterEditMode":function($event){_vm.currentlyEditingSchema[row.field] = row}},scopedSlots:_vm._u([{key:"append",fn:function(ref){
var confirm = ref.confirm;
var cancel = ref.cancel;
var pending = ref.pending;
return [_c('pendo-button',{attrs:{"loading":pending,"theme":"app","icon":"check","size":"small","type":"primary"},on:{"click":confirm}}),_c('pendo-button',{attrs:{"theme":"app","size":"small","icon":"x","type":"secondary"},on:{"click":cancel}})]}}],null,true)}):_vm._e(),(row.custom)?_c('pendo-input',{attrs:{"size":"small","width":"174px","placeholder":"Add Custom Field"},model:{value:(_vm.custom.DisplayName),callback:function ($$v) {_vm.$set(_vm.custom, "DisplayName", $$v)},expression:"custom.DisplayName"}}):_vm._e(),(row.default || _vm.isDynamicGroup(row))?_c('div',{staticClass:"metadata-name--default"},[_vm._v(" "+_vm._s(row.field)+" ")]):_vm._e()],1)]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"metadata-type"},[(!row.default && !_vm.isDynamicGroup(row))?[_c('pendo-multiselect',{attrs:{"trigger-size":"small","value":row.Type,"value-key":"value","min-trigger-width":120,"min-menu-width":140,"options":row.group === 'custom' ? _vm.customTypes : _vm.types,"loading":_vm.updatingMap[row.field]},on:{"select":function($event){return _vm.onTypeChange($event, row)}}}),(row.Type.value === 'time')?_c('pendo-icon',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:({
                            arrow: true,
                            content: _vm.dateHelpText,
                            trigger: 'click',
                            placement: 'right',
                            html: true
                        }),expression:"{\n                            arrow: true,\n                            content: dateHelpText,\n                            trigger: 'click',\n                            placement: 'right',\n                            html: true\n                        }"}],staticClass:"date-help-icon",attrs:{"type":"info","size":"14"}}):_vm._e(),(row.Type.value === 'list')?_c('pendo-multiselect',{attrs:{"trigger-size":"small","value":row.ElementType,"value-key":"value","placeholder":"Select Type","min-trigger-width":120,"min-menu-width":140,"options":_vm.dateFormats,"loading":_vm.updatingMap[row.field]},on:{"select":function($event){return _vm.onElementTypeChange($event, row)}}}):_vm._e()]:_vm._e(),(row.default || _vm.isDynamicGroup(row))?_c('div',[_vm._v(" "+_vm._s(row.Type.label)+" ")]):_vm._e()],2)]}},{key:"csvKey",fn:function(ref){
                        var row = ref.row;
return [_c('div',{staticClass:"csv-key"},[(row.csvKey)?_c('code',[_vm._v(" "+_vm._s(row.csvKey)+" ")]):_vm._e(),(!row.csvKey)?_c('div',{staticClass:"no-key"},[_vm._v(" Field cannot be updated with csv upload ")]):_vm._e()])]}},{key:"actions",fn:function(ref){
                        var row = ref.row;
return [(row.custom)?_c('pendo-button',{style:({ minWidth: 0 }),attrs:{"size":"small","theme":"app","prefix-icon":"plus","disabled":_vm.disableAdd,"label":"Add","loading":_vm.isAddingCustomField},on:{"click":_vm.onAddCustomSchema}}):_vm._e(),(!row.default && !row.custom && !_vm.isDynamicGroup(row))?_c('pendo-button',{attrs:{"size":"small","theme":"app","label":"Remove"},on:{"click":function($event){return _vm.onRemoveSchema(row)}}}):_vm._e()]}}])},[_c('div',{staticClass:"data-mappings-table--empty",attrs:{"slot":"empty"},slot:"empty"},[_c('pendo-icon',{staticClass:"empty-icon",attrs:{"type":"alert-circle","stroke":"#9a9ca5","size":"24"}}),_c('span',{staticClass:"empty-text"},[_vm._v(" No data found. ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }