<template>
    <csv-upload
        title="Upload Visitor CSV"
        description="Select and upload a file to create segments or target guides to a specific, static group of visitors."
        :value="value"
        :has-error="!!errorObject"
        :has-warning="hasWarning"
        finish-text="Apply to segment"
        @input="$emit('input', $event)"
        @reset="resetUploadStatus"
        @error="onError"
        @warning="onWarning"
        @success="onSuccess">
        <div slot="error-message">
            <div v-if="errorHasMessage">
                <strong>{{ errorObject.status }}: </strong>
                {{ errorObject.message }}.
            </div>
            <div v-if="csvLimitExceeded">
                CSV exceeds the amount of supported entries ({{ csvLimit }}).
            </div>
            <div v-if="hasConflicts">
                Another list is currently being uploaded to your account. Please retry your upload again in a few
                minutes.
            </div>
        </div>
        <div slot="warning-message">
            <div v-if="someVisitorIdsNotMatched">
                <div>
                    We could not find matches for all visitor entries. Your segment using this CSV will only affect the
                    matched visitors ({{ existingVisitorsFound.toLocaleString() }}/{{
                        totalVisitorsInCSV.toLocaleString()
                    }})
                </div>
                <div
                    v-if="visitorIdMismatchList"
                    class="visitor-mismatch">
                    <strong>Unmatched Entries</strong>
                    <div
                        v-for="visitorId in visitorIdMismatchList"
                        :key="visitorId">
                        {{ visitorId }}
                    </div>
                </div>
            </div>
        </div>
    </csv-upload>
</template>

<script>
import get from 'lodash/get';
import CsvUpload from './CsvUpload.vue';

export default {
    name: 'SegmentCsvUpload',
    components: {
        CsvUpload
    },
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            errorObject: null,
            hasWarning: false,
            existingVisitorsFound: 0,
            totalVisitorsInCSV: 0,
            csvLimit: '10,000,000',
            csvLimitExceeded: false,
            hasConflicts: false,
            someVisitorIdsNotMatched: false,
            visitorIdMismatchList: null
        };
    },
    computed: {
        errorHasMessage () {
            return get(this.errorObject, 'status') && get(this.errorObject, 'message');
        }
    },
    methods: {
        onError (error) {
            const { status, statusText } = error;
            const errorMessage = error.error || error.data || '';

            const errorObject = { message: errorMessage, status, statusText };

            switch (status) {
                case 413:
                    errorObject.statusText = 'Request Entity Too Large';
                    errorObject.erroMessage = 'CSV has exceeded the limit. Try uploading a CSV with less entries';
                    break;
                case 400:
                    if (errorMessage.includes('Csv contains greater than')) {
                        try {
                            // format string as millions
                            this.csvLimit = errorMessage.match(/[0-9]+/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        } catch (err) {
                            this.csvLimit = '10,000,000';
                        }

                        this.csvLimitExceeded = true;
                    }
                    break;
                case 409:
                    this.hasConflicts = true;
                    break;
            }

            this.errorObject = errorObject;
        },
        onWarning (warning) {
            const warningData = get(warning, 'errors');

            // This message is specific to adoptv1
            // Adoptv2 and engage do not explicitly surface warnings here
            if (get(warningData, '0.type') === 'VISITOR_IDS_NOT_FOUND') {
                this.hasWarning = true;
                this.someVisitorIdsNotMatched = true;
                this.visitorIdMismatchList = get(warningData, '0.visitors');

                return;
            }
        },
        onSuccess (success) {
            const existingVisitorsFound = parseInt(success.checkFound);
            const totalVisitorsInCSV = parseInt(success.checkTotal);

            if (isNaN(existingVisitorsFound) || isNaN(totalVisitorsInCSV)) {
                return;
            }

            if (existingVisitorsFound < totalVisitorsInCSV) {
                this.hasWarning = true;
                this.existingVisitorsFound = existingVisitorsFound;
                this.totalVisitorsInCSV = totalVisitorsInCSV;
                this.someVisitorIdsNotMatched = true;
            }
        },
        resetUploadStatus () {
            this.errorObject = null;
            this.hasWarning = false;
            this.hasConflicts = false;
            this.csvLimitExceeded = false;
            this.visitorIdMismatchList = null;
        }
    }
};
</script>

<style lang="scss" scoped>
.visitor-mismatch {
    margin-top: 12px;
}
</style>
