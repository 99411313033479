<template>
    <pendo-table
        v-pendo-loading:feather="loading"
        :data="pollResponses"
        :max-height="400"
        :columns="columns"
        title="Poll Responses"
        manage-columns
        csv-download
        borderless
        row-key="uniqueKey"
        @column-change="changeColumns">
        <div
            slot="empty"
            class="guide-activity-list--table--empty">
            <pendo-icon
                type="alert-circle"
                class="empty-icon"
                stroke="#9a9ca5"
                size="24" />
            <span class="empty-text">
                No data found.
            </span>
        </div>
    </pendo-table>
</template>

<script>
import get from 'lodash/get';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { PendoLoading, PendoTable, PendoIcon } from '@pendo/components';
import { rowFormatter, getColumnWidth } from '@/utils/table-formatters';
import { getPollResponseList } from '@/aggregations/poll-responses';
import toNumber from 'lodash/toNumber';

export default {
    name: 'PollResponseList',
    components: {
        PendoTable,
        PendoIcon
    },
    directives: {
        PendoLoading
    },
    props: {
        poll: {
            type: Object,
            required: true
        },
        timeSeries: {
            type: Object,
            required: true
        },
        segment: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            pollResponses: null,
            loading: true
        };
    },
    computed: {
        ...mapGetters({
            guide: 'guides/active',
            selectedPollColumnNames: 'guideAnalytics/pollResponseColumns',
            availablePollColumns: 'guideAnalytics/availablePollResponseColumns'
        }),
        appId () {
            return get(this.guide, 'appId', '');
        },
        columns () {
            const selectedColumns = this.selectedPollColumnNames.map((name) => {
                const column = this.availablePollColumns.find((obj) => {
                    return obj.prop === name;
                });

                const columnConfig = {
                    prop: column.prop,
                    label: column.label,
                    sortable: true,
                    visible: true,
                    formatter: (row) => rowFormatter(row, column),
                    width: getColumnWidth(column)
                };

                return columnConfig;
            });

            const unselectedColumns = this.availablePollColumns
                .filter((col) => {
                    return !this.selectedPollColumnNames.includes(col.prop);
                })
                .map((col) => col);

            return selectedColumns.concat(unselectedColumns);
        },
        pollType () {
            return get(this.poll, 'attributes.type');
        },
        pollResponseMap () {
            const { idResponses } = this.poll;

            const cases = [];

            Object.keys(idResponses).forEach((key) => {
                const value = idResponses[key];
                if (!isNaN(key)) key = toNumber(key);
                cases.push({ value, '==': key });
            });

            return cases;
        },
        segmentId () {
            const id = get(this.segment, 'id');
            const isEveryone = id === 'everyone';

            return isEveryone ? null : id;
        }
    },
    watch: {
        timeSeries () {
            this.getPollResponses(this.segmentId, this.timeSeries);
        },
        segment () {
            this.getPollResponses(this.segmentId, this.timeSeries);
        }
    },
    created () {
        this.getPollResponses(this.segmentId, this.timeSeries);
    },
    methods: {
        ...mapActions({
            updateUserSetting: 'userSettings/updateAppNamespaceSetting'
        }),
        ...mapMutations({
            setPollResponseColumns: 'guideAnalytics/setPollResponseColumns'
        }),
        async getPollResponses (segmentId, timeSeries) {
            this.loading = true;
            const config = {
                appId: this.appId,
                timeSeries,
                guideId: this.guide.id,
                pollId: this.poll.id,
                segmentId
            };

            if (this.pollType !== 'FreeForm' && this.pollType !== 'NumberScale') {
                config.pollResponseMap = this.pollResponseMap;
            }

            this.pollResponses = await getPollResponseList(config);
            this.pollResponses = this.pollResponses.map((res) => {
                const { visitor: metadata } = res;

                return {
                    ...res,
                    metadata,
                    uniqueKey: `${res.browserTime}-${res.visitorId}`
                };
            });
            this.loading = false;
        },
        async changeColumns (columns) {
            const currentColumns = columns.columns;
            this.setPollResponseColumns({ pollResponseColumns: currentColumns });
            await this.updateUserSetting({
                name: 'pollResponseColumns',
                value: JSON.stringify(currentColumns)
            });
        }
    }
};
</script>
