<template>
    <div class="editor-visitor-count">
        <pendo-alert
            v-if="showCsvUploadWarning"
            type="warning">
            Warning: Processing CSV analytics can take up to 15 minutes to correctly show visitor counts.
        </pendo-alert>
        <div class="editor-visitor-count--row">
            <span class="editor-visitor-count--title">
                Eligible Visitors
            </span>
        </div>
        <div
            :class="{ 'count-loading': loading, 'count-undefined': !hasCount }"
            class="editor-visitor-count--row editor-visitor-count--count">
            <span>{{ visitorCount }}</span>
        </div>
        <div class="editor-visitor-count--row">
            <pendo-button
                :disabled="!pipeline"
                :loading="loading"
                label="Check"
                theme="via"
                type="secondary"
                class="editor-visitor-count--button"
                @click="getCountInSegment" />
        </div>
        <div
            class="hidden-pipeline"
            data-cy="segment-pipeline">
            {{ pipeline }}
        </div>
    </div>
</template>

<script>
import { PendoButton, PendoAlert } from '@pendo/components';
import { getVisitorTotalFromPipeline } from '@/aggregations/visitor-counts';

const COUNT_PLACEHOLDER = '- - -';

export default {
    name: 'SegmentVisitorCount',
    components: {
        PendoButton,
        PendoAlert
    },
    props: {
        appId: {
            type: Number,
            required: true
        },
        name: {
            type: String,
            default: 'tmp-segment-name'
        },
        pipeline: {
            type: Array,
            default: null
        },
        showCsvUploadWarning: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            visitorCount: COUNT_PLACEHOLDER,
            loading: false
        };
    },
    computed: {
        hasCount () {
            return this.visitorCount !== COUNT_PLACEHOLDER;
        }
    },
    watch: {
        rules: {
            handler () {
                this.visitorCount = COUNT_PLACEHOLDER;
            },
            deep: true
        }
    },
    created () {
        if (this.name) {
            this.getCountInSegment();
        }
    },
    methods: {
        async getCountInSegment () {
            this.visitorCount = COUNT_PLACEHOLDER;
            this.loading = true;

            const count = await getVisitorTotalFromPipeline(this.pipeline);

            this.visitorCount = count;
            this.loading = false;
        }
    }
};
</script>

<style lang="scss">
.editor-visitor-count {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;

    .pendo-alert {
        margin-bottom: 20px;
    }

    &--row {
        margin-bottom: 1em;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &--title {
        font-weight: 600;
    }

    &--count {
        font-size: 1.375rem;

        &.count-undefined,
        &.count-loading {
            color: $gray-lighter-4;
        }
    }

    .hidden-pipeline {
        display: none;
    }
}
</style>
