import Vue from 'vue';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import http from '@/utils/http';
import { reduceSchema } from '@/utils/schema';
import { DEFAULT_SEGMENT_IDS_MAP } from '@/constants/segments';
import { filterSegmentsByAppId } from '@/utils/apps';
import { getTimeSeriesForFixedRange, getTimeSeriesForCustomRange } from '@/utils/time-series';
import { filterIsAllApps } from '@/utils/filters';

export function getInitialState () {
    return {
        schemaMap: {},
        segmentsMap: {
            everyone: {
                name: 'Everyone',
                id: 'everyone'
            }
        },
        dateRange: {},
        appIdsFilter: [],
        viewByMetadataField: undefined,
        // undefined is important here, because the "everyone" segment is null
        activeSegmentId: undefined,
        hideVisitorsNoEvents: false,
        segmentsLoaded: false,
        segmentsLoadingPromise: null,
        isViewingApplicationsByMetadata: true
    };
}

export const state = getInitialState();

export const mutations = {
    setSchemaMap (state, { schemaMap }) {
        state.schemaMap = schemaMap;
    },
    setDateRange (state, { dateRange }) {
        state.dateRange = dateRange;
    },
    setSegmentsMap (state, { segmentsMap }) {
        state.segmentsLoaded = true;
        state.segmentsLoadingPromise = null;
        state.segmentsMap = {
            ...state.segmentsMap,
            ...segmentsMap
        };
    },
    setAppIdsFilter (state, { appIds }) {
        state.appIdsFilter = appIds;
    },
    setViewByMetadataField (state, { metadata }) {
        state.viewByMetadataField = metadata;
    },
    setPivotDataToggle (state, { isViewingApplicationsByMetadata }) {
        state.isViewingApplicationsByMetadata = isViewingApplicationsByMetadata;
    },
    setSegmentsLoadingPromise (state, { promise }) {
        state.segmentsLoadingPromise = promise;
    },
    setNewSegment (state, { segment }) {
        Vue.set(state.segmentsMap, segment.id, segment);
    },
    setRemoveSegment (state, { id }) {
        Vue.delete(state.segmentsMap, id);
    },
    setActiveSegmentId (state, { id }) {
        if (id === 'everyone') {
            state.activeSegmentId = null;
        } else {
            state.activeSegmentId = id;
        }
    },
    setHideVisitorsNoEvents (state, { value }) {
        state.hideVisitorsNoEvents = value;
    },
    setUpdatedSegment (state, { segment }) {
        Vue.set(state.segmentsMap, segment.id, segment);
    },
    reset (state) {
        Object.assign(state, getInitialState());
    }
};

export const actions = {
    reset ({ commit, dispatch }) {
        commit('reset');
        dispatch('fetchSchema', { forceRefresh: false });
        dispatch('fetchSegments');
    },
    updateDateRange ({ commit, dispatch, state }, { dateRange }) {
        commit('setDateRange', { dateRange });
        dispatch('pages/fetchUpdatesForFilterChange', { segmentChanged: false }, { root: true });
        dispatch('features/fetchUpdatesForFilterChange', { segmentChanged: false }, { root: true });
        dispatch(
            'userSettings/updateAppNamespaceSetting',
            { name: 'dateRange', value: JSON.stringify(state.dateRange) },
            { root: true }
        );
    },
    updateAppFilter ({ commit, dispatch, state }, { appIds }) {
        commit('setAppIdsFilter', { appIds });

        dispatch('pages/fetchUpdatesForFilterChange', { segmentChanged: false }, { root: true });
        dispatch('features/fetchUpdatesForFilterChange', { segmentChanged: false }, { root: true });
        dispatch(
            'userSettings/updateSubNamespaceSetting',
            { name: 'appIdsFilter', value: JSON.stringify(state.appIdsFilter) },
            { root: true }
        );
    },
    updateViewByMetadataField ({ commit, dispatch }, { metadata }) {
        commit('setViewByMetadataField', { metadata });
        dispatch(
            'userSettings/updateSubNamespaceSetting',
            { name: 'viewByMetadataField', value: JSON.stringify(state.viewByMetadataField) },
            { root: true }
        );
    },
    updatePivotDataToggle ({ commit, dispatch }, { isViewingApplicationsByMetadata }) {
        commit('setPivotDataToggle', { isViewingApplicationsByMetadata });
        dispatch(
            'userSettings/updateSubNamespaceSetting',
            { name: 'isViewingApplicationsByMetadata', value: state.isViewingApplicationsByMetadata },
            { root: true }
        );
    },
    async updateActiveSegmentId ({ commit, dispatch, state }, { id }) {
        if (!state.segmentsLoaded) {
            await state.segmentsLoadingPromise;
        }

        let segmentId = id;
        if (!state.segmentsMap[id]) {
            segmentId = 'everyone';
        }

        commit('setActiveSegmentId', { id: segmentId });
        dispatch('pages/fetchUpdatesForFilterChange', { segmentChanged: false }, { root: true });
        dispatch('features/fetchUpdatesForFilterChange', { segmentChanged: false }, { root: true });
        dispatch(
            'userSettings/updateAppNamespaceSetting',
            { name: 'activeSegmentId', value: segmentId },
            { root: true }
        );
    },
    async updateHideVisitorsNoEvents ({ commit, dispatch, state }, { value }) {
        if (state.hideVisitorsNoEvents === value) {
            return;
        }

        commit('setHideVisitorsNoEvents', { value });
        dispatch('userSettings/updateAppNamespaceSetting', { name: 'hideVisitorsNoEvents', value }, { root: true });
    },
    async fetchSegments ({ commit, state, rootGetters }) {
        if (state.segmentsLoaded) {
            return;
        }

        const usesMultiApp = rootGetters['subscriptions/usesMultiApp'];
        let promise;

        if (usesMultiApp) {
            promise = http.get('/api/s/_SID_/segment?expand=*');
        } else {
            promise = http.get('/api/s/_SID_/segment');
        }

        commit('setSegmentsLoadingPromise', { promise });
        const { data: list } = await promise;

        commit('setSegmentsMap', { segmentsMap: keyBy(list, 'id') });
    },
    async fetchSchema ({ commit, state }, { forceRefresh }) {
        if (!forceRefresh && !isEmpty(state.schemaMap)) {
            return;
        }
        const kind = 'visitor';
        const { data } = await http.get(`/api/s/_SID_/metadata/${kind}/schema`);
        const schema = reduceSchema(data, kind);
        commit('setSchemaMap', { schemaMap: keyBy(schema, 'id') });
    },
    async createSegment ({ commit }, { segment }) {
        const { data } = await http.post('/api/s/_SID_/segment', segment);

        commit('setNewSegment', { segment: data });
    },
    async updateSegment ({ commit }, { segment }) {
        const { data } = await http.put(`/api/s/_SID_/segment/${segment.id}`, segment);
        commit('setUpdatedSegment', { segment: data });
    },
    async deleteSegment ({ commit }, { id }) {
        await http.delete(`/api/s/_SID_/segment/${id}`);

        commit('setRemoveSegment', { id });
    }
};

export const getters = {
    segmentsList (state, getters) {
        const { everyone, defaultList, customList } = getters.segmentsListByGroup;

        return [everyone].concat(defaultList, customList);
    },
    segmentsListByGroup (state, getters, rootState, rootGetters) {
        const { everyone, defaultList, customList } = Object.values(state.segmentsMap).reduce(
            (list, segment) => {
                const { id } = segment;

                if (id === 'everyone') {
                    list.everyone = segment;
                } else {
                    const listName = DEFAULT_SEGMENT_IDS_MAP[id] ? 'defaultList' : 'customList';
                    list[listName].push(segment);
                }

                return list;
            },
            { defaultList: [], customList: [], everyone: null }
        );

        const usesMultiApp = rootGetters['subscriptions/usesMultiApp'];

        let filteredCustomSegments = customList.sort((a, b) => {
            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        });

        if (!usesMultiApp) {
            const appId = rootGetters['apps/activeId'];
            filteredCustomSegments = filterSegmentsByAppId(filteredCustomSegments, appId);
        }

        return {
            everyone,
            defaultList,
            customList: filteredCustomSegments
        };
    },
    activeSegment (state) {
        const segment = state.segmentsMap[state.activeSegmentId];

        return segment || state.segmentsMap.everyone;
    },
    activeTimeSeries (state) {
        if (state.dateRange.id === 'custom') {
            return getTimeSeriesForCustomRange(state.dateRange);
        }

        return getTimeSeriesForFixedRange(state.dateRange);
    },
    hideVisitorsNoEvents (state) {
        return state.hideVisitorsNoEvents || false;
    },
    schemaList (state) {
        return Object.values(state.schemaMap);
    },
    schemaForColumns (state, getters, rootState, rootGetters) {
        const hasViewAutoMetadataFlag = rootGetters['auth/hasSegmentFlag']('viewAutoMetadata');
        const activeUsesV2Adopt = rootGetters['subscriptions/activeUsesV2Adopt'];

        const allowedSchemaGroups = ['agent'];
        if (hasViewAutoMetadataFlag) allowedSchemaGroups.push('auto');
        if (activeUsesV2Adopt) allowedSchemaGroups.push('custom');

        return (defaultColumnSchemaIds = []) =>
            getters.schemaList.filter(
                (schema) => allowedSchemaGroups.includes(schema.group) && !defaultColumnSchemaIds.includes(schema.id)
            );
    },
    schemaMap (state) {
        return state.schemaMap;
    },
    appsForAppIdFilter (state, getters, rootState, rootGetters) {
        const { appIdsFilter } = state;
        const appsForActiveSub = rootGetters['apps/appMapForActiveSubscription'];

        // When "All Applications" is selected, return all apps in the subscription
        if (filterIsAllApps(state.appIdsFilter)) {
            return Object.values(appsForActiveSub);
        }

        return appIdsFilter.reduce((acc, appId) => {
            if (!appsForActiveSub[appId]) return acc;

            acc.push(appsForActiveSub[appId]);

            return acc;
        }, []);
    },
    isAppIdsFilterInUse (state, getters, rootState, rootGetters) {
        if (!rootGetters['subscriptions/usesMultiApp']) return false;
        if (state.appIdsFilter.length !== 1) return true;

        return !filterIsAllApps(state.appIdsFilter);
    },
    manuallySelectedDisabledApps (state, getters) {
        // When "All Applications" is selected, no apps have been manually selected
        if (filterIsAllApps(state.appIdsFilter)) {
            return [];
        }

        return getters.appsForAppIdFilter.filter((app) => get(app, 'applicationFlags.disableExtensionInjection'));
    }
};

export function filterBarChangeSubscriber (store, callback) {
    const filterModuleNamespace = 'filters';
    const filterBarMutations = ['setDateRange', 'setActiveSegmentId', 'setAppIdsFilter', 'setViewByMetadataField'];
    const mutationMatchStrings = filterBarMutations.map((mutationName) => `${filterModuleNamespace}/${mutationName}`);

    return store.subscribe((mutation, rootState) => {
        if (rootState.apps.updatingActive) return;

        if (!mutationMatchStrings.includes(mutation.type)) return;

        const { dateRange, activeSegmentId } = rootState[filterModuleNamespace];
        if (isEmpty(dateRange) || activeSegmentId === undefined) return;

        callback();
    });
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
