import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import { Themes } from '@pendo/services/BuildingBlocks';
import { _fetchThemes, _createTheme } from '@/utils/themes';

export function getInitialState () {
    return {
        map: {},
        loading: false
    };
}

export const state = getInitialState();

export const mutations = {
    setLoading (state, { loading }) {
        state.loading = loading;
    },
    setThemes (state, { themes }) {
        state.map = keyBy(themes, 'id');
    },
    reset (state) {
        Object.assign(state, getInitialState());
    }
};

export const actions = {
    async fetch ({ state, commit, rootGetters }, { noCache = false, selectedAppId = null } = {}) {
        if (Object.keys(state.map).length && !noCache) {
            return;
        }
        commit('setLoading', { loading: true });
        const appId = selectedAppId || rootGetters['apps/activeId'];
        const themes = await _fetchThemes(appId);
        let defaultTheme = themes.find(getDefaultTheme);

        if (!defaultTheme) {
            defaultTheme = Themes.createTheme({
                name: 'Default',
                type: 'default',
                platform: 'web'
            });
            defaultTheme.appId = appId;
            const theme = await _createTheme(defaultTheme);
            themes.push(theme);
        }

        commit('setThemes', { themes });
        commit('setLoading', { loading: false });
    }
};

export const getters = {
    defaultTheme (state, getters) {
        return getters.list.find(getDefaultTheme);
    },
    list (state) {
        return values(state.map) || [];
    }
};

export function getDefaultTheme (theme) {
    return theme.tags.toLowerCase().includes('default');
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
