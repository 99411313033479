<template>
    <pendo-card
        title="Applications"
        class="app-list"
        body-min-height="0px">
        <div class="apps-grid">
            <div
                v-for="app in sortedApps"
                :key="app.id">
                <app-card
                    :app="app"
                    :platform-label="app.platform"
                    :icon-label="getIconLabel(app.platform)" />
            </div>
        </div>
    </pendo-card>
</template>

<script>
import { PendoCard } from '@pendo/components';
import AppCard from '@/components/common/AppCard';

import { ICON_LABEL } from '@/constants/icon-label.js';

export default {
    name: 'AppList',
    components: {
        PendoCard,
        AppCard
    },
    props: {
        subscription: {
            type: Object,
            required: true
        }
    },
    computed: {
        sortedApps () {
            const apps = [...this.subscription.applications];
            apps.sort(function (a, b) {
                const nameA = a.displayName.toUpperCase();
                const nameB = b.displayName.toUpperCase();

                return nameA < nameB ? -1 : 1;
            });

            return apps;
        }
    },
    methods: {
        getIconLabel (platform) {
            return ICON_LABEL[platform];
        }
    }
};
</script>

<style lang="scss">
.apps-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 246px);
    grid-gap: 16px;

    .app-loading-indicator {
        flex: 1;
    }
}
</style>
