<template>
    <div class="set-password">
        <div class="set-password--main">
            <h2 class="set-password--header">
                Set Password
            </h2>
            <div class="set-password--description">
                Passwords must be at least 12 characters long, and meet a minimum of "good" strength.
            </div>
            <pendo-input
                v-model.trim="password"
                :labels="{ top: 'Password' }"
                :type="passwordInputType"
                show-password-strength
                class="set-password--input"
                @score="handlePasswordStrengthChange"
                @keyup.enter.native="setPassword">
                <a
                    slot="suffix"
                    class="password-visibility-toggle"
                    @click="toggleShowPassword">
                    <pendo-icon
                        v-if="!showPassword"
                        type="eye-off"
                        stroke="#dadce5"
                        size="18" />
                    <pendo-icon
                        v-if="showPassword"
                        stroke="#2a2c35"
                        type="eye"
                        size="18" />
                </a>
            </pendo-input>
        </div>
        <div class="set-password--footer">
            <pendo-button
                :loading="isPending"
                :disabled="!validPassword"
                type="primary"
                theme="via"
                label="Next"
                @click="setPassword" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { PendoButton, PendoInput, PendoIcon } from '@pendo/components';

export default {
    name: 'SetPasswordForm',
    components: {
        PendoButton,
        PendoInput,
        PendoIcon
    },
    data () {
        return {
            password: '',
            isPending: false,
            passwordStrength: 0,
            showPassword: false
        };
    },
    computed: {
        ...mapState({
            hasLoginError: (state) => state.auth.hasLoginError,
            hasRegistrationPasswordError: (state) => state.auth.hasRegistrationPasswordError,
            registrationToken: (state) => state.route.params.token,
            email: (state) => state.route.params.email
        }),
        validPassword () {
            const length = this.password.length > 11;
            const strength = this.passwordStrength > 2;
            const notEmail = this.password !== this.email;

            return length && strength && notEmail;
        },
        passwordInputType () {
            return this.showPassword ? 'text' : 'password';
        }
    },
    methods: {
        ...mapActions({
            _login: 'auth/login',
            _setPassword: 'auth/setPassword'
        }),
        handlePasswordStrengthChange (score) {
            this.passwordStrength = score;
        },
        async setPassword () {
            if (!this.validPassword) return;

            this.isPending = true;
            await this._setPassword({ token: this.registrationToken, password: this.password });

            if (this.hasRegistrationPasswordError) {
                this.isPending = false;

                return;
            }
            this.attemptLogin();
        },
        async attemptLogin () {
            await this._login({ email: this.email, password: this.password });
            this.isPending = false;

            if (this.hasLoginError) {
                this.$router.push({ name: 'login' });

                return;
            }

            this.$router.push({ name: 'root' });
        },
        toggleShowPassword () {
            this.showPassword = !this.showPassword;
        }
    }
};
</script>
<style lang="scss">
.set-password {
    .set-password--header {
        margin: 0.25rem 0;
    }

    .set-password--description {
        margin-top: 0;
        margin-bottom: 1rem;
        line-height: 1.4;
    }

    .set-password--input {
        margin-bottom: 2.5rem;
    }

    .set-password--main {
        padding: 0 1.25rem;
    }

    .set-password--footer {
        padding: 1.25rem;
        border-top: 1px solid $gray-lighter-6;
        display: grid;
        grid-auto-flow: column;
        justify-content: end;
        grid-gap: 1rem;
    }
}
</style>
