<template>
    <div class="html-attributes">
        <pendo-card
            title="Custom HTML Attributes"
            body-min-height="200px">
            <div class="html-attributes__description">
                Collect HTML attributes in addition to Pendo's
                <a
                    href="https://help.pendo.io/resources/support-library/installation/html-attributes.html"
                    target="_blank">default attributes</a>. These can be used for tagging features and placing tooltip guides.
            </div>
            <div class="html-attributes__editor">
                <div class="html-attributes__add-attribute">
                    <pendo-form
                        form-ref="form"
                        call-validate
                        :form-defaults="newAttributeForm"
                        @invalidForm="onInvalidNewAttribute"
                        @formValidated="onValidNewAttribute">
                        <pendo-form-item
                            :rules="rules"
                            prop="value">
                            <pendo-input
                                v-model="newAttributeForm.value"
                                class="html-attributes__add-attribute-input"
                                placeholder="Enter Attribute"
                                :disabled="isAddingAttribute"
                                @keydown.enter="add" />
                        </pendo-form-item>
                    </pendo-form>
                    <pendo-button
                        :loading="isAddingAttribute"
                        theme="app"
                        :disabled="!isAddAttributeEnabled"
                        type="primary"
                        prefix-icon="plus"
                        label="Add Attribute"
                        @click="add" />
                </div>
                <div
                    v-if="hasAttributes"
                    class="html-attributes__attribute-list">
                    <div
                        v-for="attribute in htmlAttributes"
                        :key="attribute"
                        class="html-attributes__attribute"
                        :class="{
                            'is-hovering': currentHoveredAttribute === attribute,
                            'is-disabled': currentEditingAttribute && currentEditingAttribute !== attribute
                        }"
                        @mouseenter="onAttributeMouseenter(attribute)"
                        @mouseleave="onAttributeMouseleave">
                        <pendo-editable-content
                            class="html-attributes__attribute-inline-edit"
                            :value="attribute"
                            :input-props="{ placeholder: 'Enter Attribute' }"
                            :validation-rules="rules"
                            :before-exit="update.bind(null, attribute)"
                            @enterEditMode="onEnterEditMode(attribute)"
                            @exitEditMode="onExitEditMode">
                            <template
                                slot="append"
                                slot-scope="{ confirm, cancel, pending }">
                                <pendo-button
                                    :loading="pending"
                                    theme="app"
                                    icon="check"
                                    type="primary"
                                    @click="confirm" />
                                <pendo-button
                                    theme="app"
                                    type="secondary"
                                    icon="x"
                                    @click="cancel" />
                            </template>
                        </pendo-editable-content>
                        <div
                            v-if="currentEditingAttribute !== attribute && currentHoveredAttribute === attribute"
                            class="html-attributes__attribute-actions">
                            <pendo-button
                                class="remove-attribute"
                                theme="app"
                                icon="trash-2"
                                type="tertiary"
                                @click="showDeleteAttributeConfirmation(attribute)" />
                        </div>
                    </div>
                </div>
                <div
                    v-if="!hasAttributes"
                    class="html-attributes__empty">
                    Add custom HTML attributes above to choose specific additional attributes for tracking click events.
                    Pendo will not be able to retrieve any data prior to the date when the custom attribute was added.
                </div>
            </div>
        </pendo-card>
        <pendo-modal
            height="auto"
            width="425px"
            type="confirmation"
            :visible="isConfirmDeleteModalVisible"
            title="Delete Custom HTML Attribute?"
            class="delete-attribute-modal"
            @close="onCancelDeleteAttribute">
            <template #body>
                Are you sure you want to delete <strong>{{ pendingAttributeToRemove }}</strong>? This action cannot be undone and may break existing tags that use
                <strong>{{ pendingAttributeToRemove }}</strong>. <br><br>Re-adding <strong>{{ pendingAttributeToRemove }}</strong> later on will result in a data
                gap on impacted features.
            </template>
            <div
                slot="footer"
                class="delete-attribute-modal__footer">
                <pendo-button
                    label="Cancel"
                    theme="app"
                    type="secondary"
                    @click="onCancelDeleteAttribute" />
                <pendo-button
                    label="Delete Attribute"
                    theme="app"
                    type="danger"
                    :loading="isRemovingAttribute"
                    @click="remove(pendingAttributeToRemove)" />
            </div>
        </pendo-modal>
    </div>
</template>

<script>
import {
    PendoCard,
    PendoModal,
    PendoForm,
    PendoFormItem,
    PendoInput,
    PendoButton,
    PendoEditableContent,
    PendoNotification
} from '@pendo/components';
import { mapActions } from 'vuex';
import get from 'lodash/get';

export default {
    name: 'CustomHtmlAttributes',
    components: {
        PendoCard,
        PendoForm,
        PendoModal,
        PendoFormItem,
        PendoEditableContent,
        PendoInput,
        PendoButton
    },
    directives: { PendoNotification },
    props: {
        app: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            isAddingAttribute: false,
            isRemovingAttribute: false,
            pendingAttributeToRemove: null,
            currentHoveredAttribute: null,
            currentEditingAttribute: null,
            isConfirmDeleteModalVisible: false,
            isNewAttributeValid: false,
            htmlAttributes: [],
            newAttributeForm: { value: '' },
            rules: [
                {
                    type: 'string',
                    validator: this.validateSpecialCharacters,
                    trigger: ['blur', 'change']
                },
                {
                    validator: this.validateUnique,
                    trigger: ['blur', 'change']
                }
            ]
        };
    },
    computed: {
        hasAttributes () {
            return this.htmlAttributes.length > 0;
        },
        isAddAttributeEnabled () {
            const newAttributeValue = (this.newAttributeForm.value || '').trim();
            if (newAttributeValue) {
                return this.isNewAttributeValid;
            }

            return false;
        },
        appId () {
            return get(this.app, 'id');
        }
    },
    async created () {
        this.setLocalHtmlAttributes();
    },
    methods: {
        ...mapActions({
            updateApp: 'apps/updateApp'
        }),
        setLocalHtmlAttributes () {
            const attributes = [...new Set(this.app.htmlAttributes || [])];
            this.htmlAttributes = attributes;
        },
        validateSpecialCharacters (rule, value, callback) {
            const specialCharacterPattern = /^(\w+-?)+\*?$/;
            const valueToValidate = value.length ? value.trim() : value;
            if (valueToValidate.length && !specialCharacterPattern.test(valueToValidate)) {
                callback(
                    new Error('Cannot contain spaces or special characters. Wildcards can only be used at the end.')
                );
            } else {
                callback();
            }
        },
        validateUnique (rule, value, callback) {
            const valueToValidate = value.length ? value.trim() : value;
            if (valueToValidate !== this.currentEditingAttribute && this.htmlAttributes.includes(valueToValidate)) {
                callback(new Error('A custom attribute with this name already exists'));
            } else {
                callback();
            }
        },
        showDeleteAttributeConfirmation (attribute) {
            this.isConfirmDeleteModalVisible = true;
            this.pendingAttributeToRemove = attribute;
        },
        onInvalidNewAttribute () {
            this.isNewAttributeValid = false;
        },
        onValidNewAttribute () {
            this.isNewAttributeValid = true;
        },
        onEnterEditMode (attribute) {
            this.currentEditingAttribute = attribute;
        },
        onExitEditMode () {
            this.currentEditingAttribute = null;
        },
        onCancelDeleteAttribute () {
            this.isConfirmDeleteModalVisible = false;
            this.pendingAttributeToRemove = null;
        },
        onAttributeMouseenter (attribute) {
            this.currentHoveredAttribute = attribute;
        },
        onAttributeMouseleave () {
            this.currentHoveredAttribute = null;
        },
        async onAttributesChange (value) {
            await this.updateApp({
                app: { id: this.appId },
                updates: { htmlAttributes: value }
            });
            this.setLocalHtmlAttributes();
        },
        async add () {
            const attribute = this.newAttributeForm.value.trim();
            if (attribute.length === 0 || this.htmlAttributes.includes(attribute)) {
                return;
            }
            this.isAddingAttribute = true;
            try {
                await this.onAttributesChange([attribute, ...this.htmlAttributes]);
            } catch (error) {
                this.$emit('error', 'adding attributes');
            } finally {
                this.isAddingAttribute = false;
                this.newAttributeForm.value = '';
            }
        },
        async remove () {
            this.isRemovingAttribute = true;
            try {
                await this.onAttributesChange(
                    this.htmlAttributes.filter((attr) => attr !== this.pendingAttributeToRemove)
                );
            } catch (error) {
                this.$emit('error', 'removing attributes');
            } finally {
                this.isRemovingAttribute = false;
                this.pendingAttributeToRemove = null;
                this.isConfirmDeleteModalVisible = false;
            }
        },
        async update (oldAttribute, newAttribute) {
            if (oldAttribute === newAttribute) {
                return;
            }
            const index = this.htmlAttributes.indexOf(oldAttribute);
            try {
                await this.onAttributesChange(Object.assign([], this.htmlAttributes, { [index]: newAttribute }));
            } catch (error) {
                this.$emit('error', 'updating attributes');
            } finally {
                this.currentEditingAttribute = null;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.html-attributes {
    /deep/.pendo-card__body {
        padding: 0;
    }
}

.html-attributes__description {
    padding: 16px;
    line-height: 1.4;
}

.html-attributes__editor {
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: inherit;
}

.html-attributes__add-attribute {
    padding: 16px 16px 0 16px;
    background: $gray-lighter-7;
    border-bottom: 1px solid $gray-lighter-6;
    border-top: 1px solid $gray-lighter-6;
    display: grid;
    grid-template-columns: 1fr 124px;
    grid-gap: 16px;
}

.html-attributes__attribute {
    border-bottom: 1px solid $gray-lighter-6;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 45px;
    padding: 0 16px;

    .pendo-editable-content {
        padding-top: 4px;
    }

    /deep/.pendo-form-item.is-error {
        margin-bottom: 32px;
    }

    &.is-hovering {
        background-color: $gray-lighter-8;
    }

    &.is-disabled {
        opacity: 0.7;
        pointer-events: none;
    }
}

.html-attributes__attribute,
.html-attributes__add-attribute {
    /deep/.pendo-editable-content__form-item.is-error,
    /deep/.pendo-form-item.is-error {
        margin-bottom: 24px;
    }
}

.html-attributes__attribute-actions {
    align-self: center;
    display: flex;
}

.html-attributes__empty {
    max-width: 80%;
    color: $gray-lighter-2;
    font-size: 16px;
    line-height: 22px;
    margin: auto;
    text-align: center;
}

.delete-attribute-modal__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
</style>
