var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isDropdown)?_c('pendo-multiselect',{staticClass:"guide-utilities",attrs:{"options":_vm.utilityOptions,"popper-options":{ class: 'guide-utilities-panel', placement: 'bottom-end' },"min-menu-width":180,"stateless":"","value-key":"action"},on:{"select":_vm.handleNextAction,"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var toggleMenu = ref.toggleMenu;
return [_vm._t("default",null,{"toggleMenu":toggleMenu})]}},{key:"option",fn:function(ref){
var option = ref.option;
return _c('pendo-icon-option',{attrs:{"option":option}})}}],null,true)}):_vm._e(),(!_vm.isDropdown)?_c('pendo-actions-cell',{directives:[{name:"pendo-loading",rawName:"v-pendo-loading:material",value:({
            loading: _vm.copying,
            spinnerProps: {
                size: 14,
                background: '#f8f8f9'
            }
        }),expression:"{\n            loading: copying,\n            spinnerProps: {\n                size: 14,\n                background: '#f8f8f9'\n            }\n        }",arg:"material"}],attrs:{"row":_vm.guide,"actions":_vm.tableRowActions},on:{"clone":_vm.cloneGuide,"copy":_vm.copyGuide,"copyToAccount":_vm.openGuideCopyModal,"clear":_vm.confirmClearGuideData,"preview":_vm.previewGuide,"delete":_vm.confirmDeleteGuide}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }