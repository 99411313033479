import Vue from 'vue';
import VueRouter from 'vue-router';
import isEmpty from 'lodash/isEmpty';

// store
import store from '@/state/store';
import routes from '@/router/routes';

Vue.use(VueRouter);

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        // eslint-disable-next-line id-length
        return { x: 0, y: 0 };
    }
});

// https://github.com/chrisvfritz/vue-enterprise-boilerplate/blob/37eacf0dd5604f3c084526d1c8afd42190cb3144/src/router/index.js
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
    // Check if auth is required on this route, including nested routes
    const authRequired = to.matched.some((route) => route.meta.authRequired);

    // If auth isn't required for the route, pass go and collect $200
    if (!authRequired) {
        return next();
    }

    // if the user/me call is still in flight, watch for it's completion
    // then proceed to checking if user is authenticated. if already loaded, proceed
    if (store.getters['auth/isLoading']) {
        store.watch(store.getters['auth/isLoaded'], () => proceed());
    } else {
        proceed();
    }

    // user should be loaded by now, so we can safely check whether they're authenticated
    function proceed () {
        // if user is authenticated
        if (store.getters['auth/isAuthenticated']) {
            const isEmptyStateDigitalAdoption = store.getters['subscriptions/isEmptyStateDigitalAdoption'];
            if (isEmptyStateDigitalAdoption && !isAllowedEmptyStateRoutes(to.name)) {
                next({
                    name: 'subscriptionSettings',
                    params: to.params,
                    query: to.query
                });
            } else if (isEmpty(to.query) && !isEmpty(from.query)) {
                next({
                    name: to.name,
                    params: to.params,
                    query: from.query
                });
            } else {
                next();
            }
        } else {
            // if user is NOT authenticated, redirect to login
            redirectToLogin();
        }
    }

    function isAllowedEmptyStateRoutes (routeName) {
        const allowedEmptyStateRoutes = {
            forgotPassword: true,
            createAccount: true,
            createAdoptAccount: true,
            setPassword: true,
            resetPassword: true,
            inactive: true,
            settings: true,
            userProfile: true,
            manageTeam: true,
            integrations: true,
            manageMetadata: true,
            subscriptionSettings: true,
            confirmation: true,
            notFound: true,
            UnprocessableEntity: true,
            BadRequest: true
        };

        return allowedEmptyStateRoutes[routeName];
    }

    function redirectToLogin () {
        // handle query params (in the case of launching designer when unauthenticated)
        next({
            name: 'login',
            query: to.query
        });
    }
});

router.beforeResolve(async (to, from, next) => {
    // Create a `beforeResolve` hook, which fires whenever
    // `beforeRouteEnter` and `beforeRouteUpdate` would. This
    // allows us to ensure data is fetched even when params change,
    // but the resolved route does not. We put it in `meta` to
    // indicate that it's a hook we created, rather than part of
    // Vue Router (yet?).
    try {
        // For each matched route...
        for (const route of to.matched) {
            // eslint-disable-next-line no-await-in-loop
            await new Promise((resolve, reject) => {
                // If a `beforeResolve` hook is defined, call it with
                // the same arguments as the `beforeEnter` hook.
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(to, from, (...args) => {
                        // If the user chose to redirect...
                        if (args.length) {
                            // Complete the redirect.
                            next(...args);
                            reject(new Error('Redirected'));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    // Otherwise, continue resolving the route.
                    resolve();
                }
            });
        }
        // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
        return;
    }

    // If we reach this point, continue resolving the route.
    next();
});

router.afterEach(() => {
    store.dispatch('router/formatDocumentTitleByRoute', null, { root: true });
});

export default router;
