import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import isArray from 'lodash/isArray';

import { getGuideViews, getGuideTotalViewsByPeriod, getGuideFirstTimeViews } from '@/aggregations/guide-views';
import { getGuideActivity, getVisitorsInGuideSegment } from '@/aggregations/guide-activity';
import { getTimeSeriesByPeriod } from '@/utils/time-series';
import { DATE_COLUMN_WIDTH } from '@/utils/table-formatters';

export function getInitialState () {
    return {
        isFetchingViews: false,
        views: {},
        isFetchingViewsByPeriod: false,
        viewsByPeriod: [],
        timePeriod: 'daily',
        metric: 'views',
        isFetchingGuideActivity: false,
        guideActivity: [],
        guideActivityColumns: null,
        guideActivityTableUserSettings: {},
        defaultGuideActivityColumns: [
            {
                prop: 'visitorId',
                label: 'Visitor ID',
                schema: 'string'
            },
            {
                prop: 'numSeen',
                label: 'Number of Views',
                schema: 'number'
            },
            {
                prop: 'stepsSeen',
                label: 'Steps Seen',
                schema: 'steps'
            },
            {
                prop: 'totalDuration',
                label: 'Time on Guide',
                schema: 'time'
            },
            {
                prop: 'stepStatus',
                label: 'Status',
                schema: 'string'
            },
            {
                prop: 'browserTime',
                label: 'Date',
                schema: 'date',
                width: DATE_COLUMN_WIDTH
            }
        ],
        pollResponseColumns: null,
        defaultPollResponseColumns: [
            {
                prop: 'visitorId',
                label: 'Visitor ID',
                schema: 'string'
            },
            {
                prop: 'browserTime',
                label: 'Date',
                schema: 'date',
                width: DATE_COLUMN_WIDTH
            },
            {
                prop: 'pollResponse',
                label: 'Response',
                schema: 'string'
            }
        ],
        eligibleVisitors: []
    };
}

export const state = getInitialState();

export const mutations = {
    setMetric (state, { metric }) {
        state.metric = metric;
    },
    setTimePeriod (state, { timePeriod }) {
        state.timePeriod = timePeriod;
    },
    setViews (state, { views }) {
        state.views = views;
    },
    setViewsByPeriod (state, { viewsByPeriod }) {
        state.viewsByPeriod = viewsByPeriod;
    },
    setFetchingViews (state, { isFetchingViews }) {
        state.isFetchingViews = isFetchingViews;
    },
    setFetchingViewsByPeriod (state, { isFetchingViewsByPeriod }) {
        state.isFetchingViewsByPeriod = isFetchingViewsByPeriod;
    },
    setGuideActivity (state, { guideActivity }) {
        state.guideActivity = guideActivity;
    },
    setGuideActivityColumns (state, { guideActivityColumns }) {
        state.guideActivityColumns = guideActivityColumns;
    },
    setGuideActivityTableUserSettings (state, { guideActivityTableUserSettings }) {
        state.guideActivityTableUserSettings = guideActivityTableUserSettings;
    },
    setGuideActivityTableColumns (state, { type, guideActivityTableColumns }) {
        const newSettings = cloneDeep(state.guideActivityTableUserSettings);
        set(newSettings, `${type}.columns`, guideActivityTableColumns);
        state.guideActivityTableUserSettings = newSettings;
    },
    setGuideActivityTableSort (state, { type, guideActivityTableSort }) {
        const newSettings = cloneDeep(state.guideActivityTableUserSettings);
        set(newSettings, `${type}.sort`, guideActivityTableSort);
        state.guideActivityTableUserSettings = newSettings;
    },
    setEligibleVisitors (state, { eligibleVisitors }) {
        state.eligibleVisitors = eligibleVisitors;
    },
    setFetchingGuideActivity (state, { isFetchingGuideActivity }) {
        state.isFetchingGuideActivity = isFetchingGuideActivity;
    },
    setPollResponseColumns (state, { pollResponseColumns }) {
        state.pollResponseColumns = pollResponseColumns;
    },
    reset (state) {
        Object.assign(state, getInitialState(), {
            guideActivityColumns: state.guideActivityColumns
        });
    }
};

export const actions = {
    async fetchGuideViews ({ commit, rootState, rootGetters }) {
        commit('setFetchingViews', { isFetchingViews: true });
        const timeSeries = {
            ...rootGetters['filters/activeTimeSeries'],
            period: 'dayRange'
        };

        const appIds = rootGetters['subscriptions/usesMultiApp']
            ? rootState.filters.appIdsFilter
            : [rootGetters['apps/activeId']];
        const views = await getGuideViews({
            appIds,
            segmentId: rootState.filters.activeSegmentId,
            timeSeries
        });
        commit('setFetchingViews', { isFetchingViews: false });
        commit('setViews', { views: keyBy(views, 'guideId') });
    },
    async fetchGuideViewsByPeriod ({ state, commit, rootGetters, rootState }, { guideId, createdAt }) {
        commit('setViewsByPeriod', { viewsByPeriod: [] });
        commit('setFetchingViewsByPeriod', { isFetchingViewsByPeriod: true });
        const guide = rootGetters['guides/getGuideById'](guideId);
        const { appId } = guide;
        const period = state.timePeriod;
        const timeSeries = getTimeSeriesByPeriod(period, rootState.filters.dateRange);
        const { activeSegmentId } = rootState.filters;

        const [viewsByPeriod, firstTimeViews] = await Promise.all([
            getGuideTotalViewsByPeriod({
                appId,
                guideId,
                metric: state.metric.replace(/s$/, ''),
                segmentId: activeSegmentId,
                timeSeries
            }),
            getGuideFirstTimeViews({
                appId,
                guideId,
                createdAt,
                timeSeries,
                segmentId: activeSegmentId
            })
        ]);
        commit('setFetchingViewsByPeriod', { isFetchingViewsByPeriod: false });

        const firstTimeViewsLookup = keyBy(firstTimeViews, 'firstTime');

        const formattedViews = viewsByPeriod.messages.map((message) => {
            const firstTime = firstTimeViewsLookup[message.time] || { count: 0 };

            return {
                time: message.time,
                total: message.rows[0].count,
                firstTime: firstTime.count
            };
        });

        commit('setViewsByPeriod', { viewsByPeriod: formattedViews });
    },
    async fetchGuideActivity ({ commit, state, rootState, rootGetters }, { guide }) {
        if (state.isFetchingGuideActivity) {
            return;
        }

        const { activeSegmentId } = rootState.filters;
        let guideActivity;
        let eligibleVisitors;

        const { appId } = guide;
        const timeSeries = rootGetters['filters/activeTimeSeries'];

        const { isMultiStep, id: guideId, steps, audienceUiHint } = guide;
        const segmentId = get(audienceUiHint, 'filters[0].segmentId', null);

        commit('setGuideActivity', { guideActivity: [] });
        commit('setEligibleVisitors', { eligibleVisitors: [] });
        commit('setFetchingGuideActivity', { isFetchingGuideActivity: true });

        try {
            [guideActivity, eligibleVisitors] = await Promise.all([
                getGuideActivity({
                    appId,
                    guideId,
                    lastStepId: steps[steps.length - 1],
                    isWalkthrough: isMultiStep,
                    segmentId: activeSegmentId,
                    timeSeries: {
                        ...timeSeries,
                        period: 'dayRange'
                    }
                }),
                getVisitorsInGuideSegment({ segmentId })
            ]);
        } catch (error) {
            commit('setFetchingGuideActivity', { isFetchingGuideActivity: false });

            return;
        }

        const guideActivityLookup = keyBy(guideActivity, 'visitorId');

        const formattedActivity = eligibleVisitors.map((visitor) => {
            const hasSeenGuide = guideActivityLookup[visitor.visitorId];

            return {
                ...visitor,
                ...hasSeenGuide,
                hasSeenGuide: !!hasSeenGuide
            };
        });

        commit('setGuideActivity', { guideActivity: formattedActivity });
        commit('setEligibleVisitors', { eligibleVisitors });
        commit('setFetchingGuideActivity', { isFetchingGuideActivity: false });
    }
};

export const getters = {
    guideActivityColumns (state) {
        if (isArray(state.guideActivityColumns) && state.guideActivityColumns.length > 0) {
            return state.guideActivityColumns;
        }

        return state.defaultGuideActivityColumns.map((col) => col.prop);
    },
    availableGuideActivityColumns (state, getters, rootState, rootGetters) {
        const defaultColumnSchemaIds = ['visitor_auto_id'];

        return state.defaultGuideActivityColumns.concat(
            rootGetters['filters/schemaForColumns'](defaultColumnSchemaIds).map((schema) => ({
                prop: `metadata.${schema.field.substring(8)}`,
                label: schema.name,
                schema: schema.schema
            }))
        );
    },
    pollResponseColumns (state) {
        if (isArray(state.pollResponseColumns) && state.pollResponseColumns.length > 0) {
            return state.pollResponseColumns;
        }

        return state.defaultPollResponseColumns.map((col) => col.prop);
    },
    availablePollResponseColumns (state, getters, rootState, rootGetters) {
        const defaultColumnSchemaIds = ['visitor_auto_id'];

        return state.defaultPollResponseColumns.concat(
            rootGetters['filters/schemaForColumns'](defaultColumnSchemaIds).map((schema) => {
                return {
                    prop: `metadata.${schema.field.substring(8)}`,
                    label: schema.name,
                    schema: schema.schema
                };
            })
        );
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
