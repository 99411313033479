<template>
    <div class="workflows-tag-row">
        <pendo-tag
            data-cy="workflows-date-range-tag"
            :label="dateRangeLabel"
            type="filter"
            :prefix-icon="dateRangeIcon" />

        <pendo-tag
            data-cy="workflows-segment-tag"
            :label="segmentLabel"
            type="filter"
            :prefix-icon="segmentIcon" />

        <pendo-tag
            data-cy="workflows-start-tag"
            :label="startLabel"
            type="filter"
            :prefix-icon="startIcon" />

        <pendo-tag
            data-cy="workflows-end-tag"
            :label="endLabel"
            type="filter"
            :prefix-icon="endIcon" />
        <pendo-tag
            data-cy="workflows-maxDuration-tag"
            :label="maxDurationLabel"
            type="filter"
            :prefix-icon="maxDurationIcon" />
    </div>
</template>

<script>
import { PendoTag } from '@pendo/components';
import get from 'lodash/get';
import { mapState } from 'vuex';

export default {
    name: 'WorkflowsQueryViewer',
    components: {
        PendoTag
    },
    inject: ['$workflows'],
    data () {
        const iconBase = {
            stroke: '#2a2c35',
            size: 12
        };

        return {
            dateRangeIcon: {
                ...iconBase,
                type: 'calendar'
            },
            endIcon: {
                ...iconBase,
                type: 'log-in'
            },
            segmentIcon: {
                ...iconBase,
                type: 'users'
            },
            startIcon: {
                ...iconBase,
                type: 'log-out'
            },
            maxDurationIcon: {
                ...iconBase,
                type: 'clock'
            }
        };
    },
    computed: {
        ...mapState({
            activeSubId: (state) => state.subscriptions.activeId,
            apps: (state) => state.apps.map,
            features: (state) => state.features.map,
            pages: (state) => state.pages.map,
            segments: (state) => state.filters.segmentsMap
        }),
        dateRangeLabel () {
            return get(this.$workflows.lastRanWorkflow, 'dateRange.label', '');
        },
        endLabel () {
            return this.getCountableLabel(
                'End',
                get(this.$workflows.lastRanWorkflow, 'end.kind'),
                get(this.$workflows.lastRanWorkflow, 'end.id')
            );
        },
        segmentLabel () {
            const segmentId = get(this.$workflows.lastRanWorkflow, 'segment');
            if (!segmentId) {
                return '';
            }

            const segment = this.segments[segmentId];
            if (!segment) {
                return '';
            }

            return segment.name;
        },
        startLabel () {
            return this.getCountableLabel(
                'Start',
                get(this.$workflows.lastRanWorkflow, 'start.kind'),
                get(this.$workflows.lastRanWorkflow, 'start.id')
            );
        },
        maxDurationLabel () {
            return get(this.$workflows.lastRanWorkflow, 'maxDuration.label');
        }
    },
    methods: {
        getCountableLabel (prefix, kind, countableId) {
            if (!kind || !countableId) {
                return '';
            }

            const countable = kind === 'Feature' ? this.features[countableId] : this.pages[countableId];
            if (!countable) {
                return '';
            }
            const app = this.apps[`${this.activeSubId}:${countable.appId}`];

            return `${prefix}: ${app.displayName} => ${countable.name}`;
        }
    }
};
</script>
