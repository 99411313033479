<template>
    <pendo-modal
        :show-close="false"
        :visible="isVisible"
        :confirm-button-config="CONFIG"
        :esc-to-close="true"
        :click-to-close="true"
        class="public-enable-rc-modal"
        width="400px"
        :message="MESSAGE_CONTENT.MESSAGE"
        type="confirmation"
        @cancel="$emit('onCancel')"
        @confirm="!isUpdating && $emit('onSave')">
        <template #title>
            <div class="el-dialog__title">
                {{ MESSAGE_CONTENT.TITLE }}
            </div>
            <div class="el-dialog__close-button">
                <pendo-icon
                    type="x"
                    size="16"
                    @click="$emit('onCancel')" />
            </div>
            <div class="public-enable-rc-modal__header__divider" />
        </template>
    </pendo-modal>
</template>

<script>
import { PendoModal, PendoIcon } from '@pendo/components';

const CONFIG = Object.freeze({
    type: 'primary',
    label: 'Turn On',
    theme: 'via'
});

const MESSAGE_CONTENT = Object.freeze({
    TITLE: 'Set Resource Center to Public?',
    MESSAGE: 'By confirming this action, your Resource Center will be visible to all eligible end users.'
});

export default {
    name: 'ResourceCenterEnablePublicModal',
    components: {
        PendoModal,
        PendoIcon
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        isUpdating: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            MESSAGE_CONTENT,
            CONFIG
        };
    }
};
</script>

<style lang="scss" scoped>
.el-dialog__close-button {
    grid-row: 1;
}

.public-enable-rc-modal__header__divider {
    height: 0px;
    border-top: 1px solid #dadce5;
    margin-bottom: 25px;
    width: 100%;
    grid-column: 1/3;
    grid-row: 3;
}
</style>
