<template>
    <div class="view--guide-list">
        <guide-list-header
            :show-guide-create="showGuideCreate"
            @showGuideCreateChange="showGuideCreate = $event" />
        <guide-list-content @showGuideCreateChange="showGuideCreate = $event" />
    </div>
</template>

<script>
import GuideListHeader from '@/components/guides/list/GuideListHeader';
import GuideListContent from '@/components/guides/list/GuideListContent';

export default {
    name: 'GuideListView',
    components: {
        GuideListHeader,
        GuideListContent
    },
    data () {
        return {
            showGuideCreate: false
        };
    }
};
</script>
