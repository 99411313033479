<template>
    <div class="reset-password">
        <invalid-token-error v-if="hasResetPasswordTokenError" />
        <reset-password-form v-if="!hasResetPasswordTokenError" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ResetPasswordForm from '@/components/register/ResetPasswordForm';
import InvalidTokenError from '@/components/register/InvalidTokenError';

export default {
    name: 'ResetPassword',
    components: {
        InvalidTokenError,
        ResetPasswordForm
    },
    computed: {
        ...mapState({
            hasResetPasswordTokenError: (state) => state.auth.hasResetPasswordTokenError,
            route: (state) => state.route
        })
    }
};
</script>
