<template>
    <pendo-guide-elements-table
        :guide-step-names="guideStepNames"
        :elements-activity-agg-status="elementsActivityAggStatus"
        :elements-activity="elementsActivity"
        :elements-table-columns="elementsTableColumns"
        :csv-download-config="csvDownloadConfig"
        :loading-options="loadingOptions"
        @toggle-group-by-step="toggleGroupByStep"
        @toggle-show-deleted="toggleShowDeleted"
        @on-metric-click="openDrawer" />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { getGuideElementsActivity, getGuideElementsActivityClicks } from '@/aggregations/guide-elements-activity';
import { getStepsWithBuildingBlocks } from '@/utils/guides';
import { addHrefToVisitors } from '@/utils/utils';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import {
    getElementsTableColumns,
    getCurrentElementData,
    formatPercentClicksOnStep,
    processGuideElementsAggResponse
} from '@/utils/guide-elements-activity';
import {
    PendoGuideElementsDrawerHeader,
    PendoGuideMetricsDrawerContent,
    PendoGuideElementsTable
} from '@pendo/components';

export default {
    name: 'GuideElementsActivityTable',
    components: {
        PendoGuideElementsTable
    },
    props: {
        csvDownloadAggId: {
            type: String,
            default: () => 'guide-elements'
        },
        uiElement: {
            type: Object,
            default: () => ({})
        },
        guideElementsClicks: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            currentIds: null,
            currentElementData: null,
            elementsActivityAggStatus: '',
            elementsActivity: [],
            groupByStep: true,
            unsubscribeFilterBarListener: null
        };
    },
    computed: {
        ...mapState({
            activeSegmentId: (state) => state.filters.activeSegmentId
        }),
        ...mapGetters({
            guide: 'guides/active',
            activeSegment: 'filters/activeSegment',
            activeTimeSeries: 'filters/activeTimeSeries',
            isAdoptV2Sub: 'subscriptions/activeUsesV2Adopt'
        }),
        appId () {
            return get(this.guide, 'appId', '');
        },
        visitorUrl () {
            if (!this.isAdoptV2Sub) return null;

            return '/analytics/visitors';
        },
        loadingOptions () {
            return {
                loadingType: 'feather',
                loadingSpinnerSize: 24
            };
        },
        elementActionColumnWidth () {
            if (!this.elementsActivity) return undefined;

            const minWidth = Math.max(...this.elementsActivity.map(({ uiElementActions }) => uiElementActions.length));

            // 172 = longest possible label text of `Go to Step (deleted)`
            return minWidth * 172;
        },
        elementsTableColumns () {
            const { groupByStep, elementActionColumnWidth } = this;

            return getElementsTableColumns({ groupByStep, elementActionColumnWidth });
        },
        csvDownloadConfig () {
            const segment = get(this.activeSegment, 'name');
            const dateRange = get(this.activeTimeSeries, 'label');

            return {
                columns: getElementsTableColumns({ groupByStep: false, csv: true }),
                filename: `Guide Elements - ${dateRange} - ${segment}`
            };
        },
        guideStepNames () {
            return this.guide.steps.map(({ id, name }) => ({ id, name }));
        }
    },
    async created () {
        await this.setGuideElements();
        await this.refreshTable();
        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, async () => {
            await this.refreshTable();
        });
    },
    destroyed () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        async setGuideElements () {
            const guide = cloneDeep(this.guide);
            guide.steps = await getStepsWithBuildingBlocks({ steps: this.guide.steps });
            this.currentElementData = guide.steps.reduce((acc, step, index) => {
                const stepElementData = getCurrentElementData(JSON.parse(step.dom)).map((element) => {
                    return {
                        ...element,
                        guideId: this.guide.id,
                        guideStepId: step.id,
                        guideStepName: step.name,
                        guideStepIndex: index + 1
                    };
                });
                acc.push(...stepElementData);

                return acc;
            }, []);
            this.currentIds = this.currentElementData.map((element) => element.uiElementId);
        },
        async refreshTable () {
            this.elementsActivityAggStatus = 'loading';
            const elements = await getGuideElementsActivity({
                appId: this.appId,
                guideId: this.guide.id,
                currentIds: this.currentIds,
                segmentId: this.activeSegmentId,
                timeSeries: { ...this.activeTimeSeries, period: 'dayRange' }
            });
            const elementsActivity = processGuideElementsAggResponse(elements, this.guide, this.currentElementData);
            this.elementsActivityAggStatus = elementsActivity.length ? 'resolved' : 'empty';
            this.elementsActivity = elementsActivity;
        },
        toggleGroupByStep (newVal) {
            this.groupByStep = newVal;
        },
        toggleShowDeleted (newVal) {
            this.elementActivity = formatPercentClicksOnStep(this.elementsActivity, newVal);
        },
        async getGuideElementsActivityClicksWithHref (aggParams) {
            const response = await getGuideElementsActivityClicks(aggParams);
            const visitorsWithHref = addHrefToVisitors(response[0]);

            return visitorsWithHref;
        },
        async openDrawer (row) {
            const timeSeries = {
                ...this.activeTimeSeries,
                period: 'dayRange'
            };
            this.$emit('update-ui-element', row);

            this.setSidePanelState({
                row,
                loading: true
            });
            const visitors = await this.getGuideElementsActivityClicksWithHref({
                appId: this.appId,
                guideId: this.guide.id,
                timeSeries,
                segmentId: this.activeSegmentId,
                uiElementId: row.uiElementId
            });

            this.$emit('update-guide-elements-clicks', visitors);

            this.setSidePanelState({
                row,
                data: visitors,
                loading: false
            });
        },
        setSidePanelState ({ row, data, loading, csvModalStatus = 'loading', csvUrl = '' }) {
            const state = {
                visible: true,
                header: {
                    component: PendoGuideElementsDrawerHeader,
                    props: {
                        uiElement: row
                    }
                },
                body: {
                    component: PendoGuideMetricsDrawerContent,
                    props: {
                        csvDownloadAggId: this.csvDownloadAggId,
                        data,
                        loading,
                        csvModalStatus,
                        csvUrl,
                        visitorUrl: this.visitorUrl,
                        showAccountsTab: false,
                        showCreateSegment: false,
                        theme: 'via'
                    }
                }
            };
            this.$emit('open-side-panel', { ...state, visible: true });
        }
    }
};
</script>
