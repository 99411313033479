import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { PENDO_APP_NAMES } from '@pendo/services/Constants';
import http from '@/utils/http';
import store from '@/state/store';
import { filterIsAllApps } from '@/utils/filters';

export const PROPERTY_ENDPOINT_MAP = {
    extensionDomainPatterns: 'extensionDomainPatterns'
};

export async function meetsMinimumAgentVersion (version, appId) {
    const isDigitalAdoptionSub = store.getters['subscriptions/activeIsDigitalAdoption'];

    // Probably not the optimal solution, but DAP subs don't manage an agent, and are generally on latest
    if (isDigitalAdoptionSub) return true;

    const agentVersionsMap = await http
        .get('/api/s/_SID_/environment/versioncheck', {
            params: {
                v: version // eslint-disable-line id-length
            }
        })
        .then((res) => res.data);
    const { production } = get(agentVersionsMap, appId, false);

    return production;
}

export function generateAppUid (subId, appId) {
    return `${subId}:${appId}`;
}

export function getEndpointForProperty (propertyName) {
    return PROPERTY_ENDPOINT_MAP[propertyName];
}

export function updateAppProperty (id, propertyName, propertyValue) {
    const endpoint = getEndpointForProperty(propertyName);
    const formattedPropertyValue = formatValueForWebRequest(propertyName, propertyValue);

    return http.put(`/api/s/_SID_/application/${id}/${endpoint}`, formattedPropertyValue).then((res) => res.data);
}

export function formatValueForWebRequest (propertyName, propertyValue) {
    switch (propertyName) {
        case 'extensionDomainPatterns':
            return { extensionDomainPatterns: propertyValue };
        default:
            return propertyValue;
    }
}

export function updateApp (app) {
    return http.put(`/api/s/_SID_/application/${app.id}`, app).then((res) => res.data);
}

export function updateAppFlag (id, flag, enabled) {
    return http.put(`/api/s/_SID_/application/${id}/flag/${flag}`, { set: enabled }).then((res) => res.data);
}

export function fetchExtensionPlatformTypes () {
    return http.get('/api/s/_SID_/daextension/schema/types').then((res) => res.data);
}

export function filterEntitiesByAppIds (entityList = [], appIdFilter = []) {
    if (filterIsAllApps(appIdFilter)) return entityList;

    return entityList.filter((entity) => appIdFilter.includes(entity.appId));
}

export function addAppToEntityList (entityList, appMap) {
    return entityList.map((entity) => {
        const entityClone = cloneDeep(entity);
        entityClone.app = appMap[entityClone.appId];

        return entityClone;
    });
}

export function filterSegmentsByAppId (segmentList, appId) {
    return segmentList.filter((segment) => {
        const [filter] = segment.definition.filters;
        let selectedId;

        if (get(filter, 'or')) {
            selectedId = get(filter, 'or[0].selectedApp.id');
        } else {
            selectedId = get(filter, 'selectedApp.id');
        }

        return selectedId === appId;
    });
}

export function getDesignerAppName (adoptDADesigner, type = 'guide') {
    return type === 'guide' && adoptDADesigner ? PENDO_APP_NAMES.ADOPT_STUDIO : PENDO_APP_NAMES.VDS;
}
