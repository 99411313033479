import { format, parse } from 'url';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import http from '@/utils/http';

export const DESIGNER_LAUNCH_TOKEN_LS_KEY = 'pendo-designer-launch-token';

export const WHITELABEL_SETTINGS_TOKEN_LS_KEY = 'adopt-white-label-token';

export function generateDesignerToken (config) {
    return encodeURIComponent(btoa(JSON.stringify(config)));
}

export function getDesignerOpenerUrl (appUrl, config, { origin } = {}) {
    const token = generateDesignerToken({
        target: 'via',
        host: origin,
        ...config
    });

    return appendTokenToUrl(appUrl, token);
}

export function openDesignerHere (config, { origin } = {}, win = window, whiteLabelSettings) {
    const launch = get(win.pendo, 'designerv2.launchInAppDesigner', noop);
    const launchConfig = {
        target: 'via',
        host: origin
    };
    writeTokenToLocalStorage(
        DESIGNER_LAUNCH_TOKEN_LS_KEY,
        JSON.stringify({
            ...launchConfig,
            ...config
        })
    );
    writeTokenToLocalStorage(WHITELABEL_SETTINGS_TOKEN_LS_KEY, JSON.stringify(whiteLabelSettings));
    launch(launchConfig);
}

export function appendTokenToUrl (url, token) {
    const parsedUrl = parse(url);

    if (parsedUrl.search) {
        parsedUrl.search += `&pendo-designer=${token}`;
    } else {
        parsedUrl.search = `pendo-designer=${token}`;
    }

    return format(parsedUrl);
}

export function writeTokenToLocalStorage (key, token) {
    window.localStorage.setItem(key, token);
}

export function updateVisitorMetadata ({ user, values }) {
    const { visitorIds } = user;

    if (!visitorIds || !values) {
        return;
    }

    const payload = visitorIds.map((visitorId) => ({
        visitorId,
        values
    }));

    http.post('/api/s/_SID_/metadata/visitor/pendo/value', payload).catch(noop);
}

export function launchViaWindowOpener (url, token) {
    if (window.designerLaunchListener) {
        window.removeEventListener('message', window.designerLaunchListener);
    }

    window.designerLaunchListener = listener;

    window.addEventListener('message', listener);
    const appWindow = window.open(url, '_blank');

    function listener (msg) {
        if (msg.data.type === 'pendo::ready') {
            appWindow.postMessage({ type: 'pendo-designer-launch::launch', token }, url);
        } else if (msg.data.type === 'pendo-designer-launch::ready') {
            window.removeEventListener('message', listener);
        }
    }
}

export function getBestDesignerUrl (domainPatterns, designerUrl) {
    if (!isEmpty(designerUrl)) {
        return designerUrl;
    }
    if (domainPatterns.length > 0 && !isEmpty(domainPatterns[0])) {
        return domainPatterns[0];
    }

    return '';
}

export function getFormattedDesignerUrl (url) {
    if (!url.startsWith('https://') && !url.startsWith('http://')) {
        return `https://${url}`;
    }

    return url;
}
