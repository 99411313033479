<template>
    <pendo-page-content>
        <guide-list-table
            type="organization"
            @showGuideCreateChange="$emit('showGuideCreateChange', $event)" />
    </pendo-page-content>
</template>

<script>
import { PendoPageContent } from '@pendo/components';
import GuideListTable from './GuideListTable';

export default {
    name: 'GuideListContent',
    components: {
        GuideListTable,
        PendoPageContent
    }
};
</script>
