<template>
    <main class="portfolio-app-usage-chart">
        <pendo-card
            v-pendo-loading:feather="isFetchingChart"
            body-min-height="464px">
            <template #title>
                <pendo-multiselect
                    v-model="selectedField"
                    :allow-empty="false"
                    :options="selectedFieldOptions">
                    <pendo-data-source-trigger slot="trigger" />
                </pendo-multiselect>
            </template>
            <template #headerRight>
                <div class="portfolio-app-usage-chart--table-header-right">
                    <pendo-multiselect
                        v-model="selectedSortOption"
                        :allow-empty="false"
                        :options="sortOptions">
                        <pendo-data-source-trigger slot="trigger" />
                    </pendo-multiselect>
                </div>
            </template>

            <template #body>
                <pendo-empty-state
                    v-if="isChartEmpty && !isFetchingChart"
                    :icon="{ type: 'bar-chart', size: 32, 'stroke-width': 1.5 }"
                    :title="emptyChartTitle"
                    :description="emptyChartDescription"
                    class="portfolio-app-usage-chart__empty" />
                <div
                    v-else
                    ref="app-usage"
                    class="portfolio-app-usage-chart--chart" />
            </template>
        </pendo-card>
    </main>
</template>

<script>
import Highcharts from '@/utils/highcharts';
import { getAppUsage } from '@/aggregations/portfolio-app-usage';
import { PendoCard, PendoDataSourceTrigger, PendoLoading, PendoMultiselect, PendoEmptyState } from '@pendo/components';
import { mapGetters, mapState, mapMutations } from 'vuex';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { pluralize } from '@/utils/filters';
import { prettyTime } from '@pendo/services/Formatters';
import { isCancel } from 'axios';

export default {
    name: 'PortfolioAppUsageChart',
    components: {
        PendoCard,
        PendoDataSourceTrigger,
        PendoMultiselect,
        PendoEmptyState
    },
    directives: {
        PendoLoading
    },
    data () {
        const chartColors = [
            '#229CA8',
            '#FF4876',
            '#8DDDB6',
            '#0B9AD3', // '#0B9AD3' replaces '#6A6C75' defined in utils/highcharts.js
            '#A973F2',
            '#EFBE53',
            '#07699B',
            '#65D036',
            '#E14CD3',
            '#FF9237'
        ];

        const selectedFieldOptions = [
            {
                id: 'averageDailyTimeOnApp',
                label: 'Average Daily Time on Application (minutes)'
            },
            {
                id: 'summedMinutes',
                label: 'Time Spent on App (minutes)'
            },
            {
                id: 'visitors',
                label: 'Visitors'
            }
        ];

        const sortOptions = [
            {
                id: 'alphabeticalAscending',
                label: 'Alphabetical A → Z'
            },
            {
                id: 'alphabeticalDescending',
                label: 'Alphabetical Z → A'
            },
            {
                id: 'mostActive',
                label: 'Most → Least Active'
            },
            {
                id: 'leastActive',
                label: 'Least → Most Active'
            }
        ];

        return {
            chart: null,
            chartColors,
            selectedField: selectedFieldOptions[0],
            selectedFieldOptions,
            selectedSortOption: sortOptions[0],
            sortOptions,
            aggCancel: null,
            appUsageMap: {},
            appsSortedList: [],
            metadataFieldValuesUsageMap: {},
            metadataFieldValuesSortedList: [],
            emptyChartTitle: 'Data not Found',
            emptyChartDescription: 'Try changing filters or selecting different applications'
        };
    },
    computed: {
        ...mapGetters({
            activeTimeSeries: 'filters/activeTimeSeries',
            isAppIdsFilterInUse: 'filters/isAppIdsFilterInUse',
            appMapForActiveSubscription: 'apps/appMapForActiveSubscription'
        }),
        ...mapState({
            appUsageListUserSettings: (state) => state.portfolio.appUsageListUserSettings,
            activeSegmentId: (state) => state.filters.activeSegmentId,
            viewByMetadataField: (state) => state.filters.viewByMetadataField,
            appIdsFilter: (state) => state.filters.appIdsFilter,
            dateRange: (state) => state.filters.dateRange,
            isViewingApplicationsByMetadata: (state) => state.filters.isViewingApplicationsByMetadata,
            isFetchingChart: (state) => state.portfolio.isFetchingChart,
            metadataFieldTotal: (state) => state.portfolio.metadataFieldTotal
        }),
        usageList () {
            const appUsageList = Object.values(this.appUsageMap);
            const metadataFieldValuesUsageList = Object.values(this.metadataFieldValuesUsageMap);

            return this.isViewingApplicationsByMetadata ? appUsageList : metadataFieldValuesUsageList;
        },
        sortedUsageList () {
            let sortedUsageList;
            switch (this.selectedSortOption.id) {
                case 'alphabeticalAscending':
                    sortedUsageList = orderBy(this.usageList, [({ name }) => name.toLowerCase()], 'asc');
                    break;
                case 'alphabeticalDescending':
                    sortedUsageList = orderBy(this.usageList, [({ name }) => name.toLowerCase()], 'desc');
                    break;
                case 'mostActive':
                    sortedUsageList = orderBy(
                        this.usageList,
                        [({ yTotals }) => yTotals[this.selectedField.id]],
                        'desc'
                    );
                    break;
                case 'leastActive':
                    sortedUsageList = orderBy(this.usageList, [({ yTotals }) => yTotals[this.selectedField.id]], 'asc');
                    break;
            }

            return sortedUsageList;
        },
        chartCategories () {
            const chartCategories = this.sortedUsageList.map((app) => app.name);

            return chartCategories;
        },
        yValuesSortedList () {
            return this.isViewingApplicationsByMetadata ? this.appsSortedList : this.metadataFieldValuesSortedList;
        },
        chartData () {
            const { label } = this.dateRange;

            const chartData = this.yValuesSortedList.map((yValue) => {
                const data = this.sortedUsageList.map(({ yValues, name }) => {
                    return {
                        y: get(yValues[yValue.id], this.selectedField.id, 0),
                        dateRangeLabel: label,
                        name,
                        yValue: yValue.name,
                        selectedField: this.selectedField.id
                    };
                });

                return {
                    id: yValue.id,
                    name: yValue.name,
                    data,
                    color: this.chartColors[this.yValuesSortedList.indexOf(yValue) % this.chartColors.length],
                    borderWidth: 1,
                    pointPadding: 0,
                    groupPadding: 0
                };
            });

            return chartData;
        },
        isChartEmpty () {
            if (!this.chartData.length) return true;

            return false;
        },
        chartScrollablePlotArea () {
            return 250 + this.sortedUsageList.length * 135;
        },
        chartYAxisTitle () {
            const { id } = this.selectedField;
            const { label } = this.selectedFieldOptions.find((option) => option.id === id);

            return label;
        },
        chartConfig () {
            return {
                chart: {
                    height: 464,
                    scrollablePlotArea: {
                        minWidth: this.chartScrollablePlotArea
                    },
                    spacingBottom: 15,
                    type: 'column'
                },
                colors: this.chartColors,
                exporting: {
                    enabled: false
                },
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    margin: 10
                },
                plotOptions: {
                    column: {
                        pointWidth: 44,
                        stacking: 'normal',
                        borderWidth: 3
                    },
                    series: {
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    }
                },
                series: this.chartData,
                title: {
                    text: null
                },
                tooltip: {
                    useHTML: true,
                    headerFormat: '<span class="header" style="color: {point.color}">\u2B24 {series.name}</span><br>',
                    pointFormatter () {
                        const { y, dateRangeLabel, selectedField } = this;

                        const timeInMs = y * 60 * 1000;
                        const formattedTimeValue = prettyTime(timeInMs);

                        let metric;
                        switch (selectedField) {
                            case 'averageDailyTimeOnApp':
                                metric = `Avg. Daily Time on Application: <b>${formattedTimeValue}</b>`;
                                break;
                            case 'summedMinutes':
                                metric = `Time Spent: <b>${formattedTimeValue}</b>`;
                                break;
                            case 'visitors':
                            default:
                                metric = `<b>${y}</b> ${pluralize('visitor', +y)}`;
                        }

                        return `${dateRangeLabel}<br/>${metric}`;
                    }
                },
                xAxis: {
                    categories: this.chartCategories,
                    crosshair: {
                        color: 'rgba(0,0,0,0)'
                    },
                    labels: {
                        y: 20,
                        style: {
                            color: '#2A2C35',
                            fontSize: '12px'
                        }
                    },
                    maxPadding: 0
                },
                yAxis: {
                    labels: {
                        align: 'right',
                        style: {
                            color: '#6A6C75',
                            fontSize: '12px'
                        },
                        x: -6,
                        y: 4
                    },
                    maxPadding: 0,
                    min: 0,
                    title: {
                        style: {
                            color: '#000000',
                            fontSize: '14px'
                        },
                        text: this.chartYAxisTitle
                    }
                }
            };
        }
    },
    watch: {
        selectedField () {
            this.updateChartAxis();
        },
        selectedSortOption () {
            this.updateChartAxis();
        },
        isViewingApplicationsByMetadata () {
            this.updateChartAxis();
        }
    },
    created () {
        this.refreshChart();

        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, () => this.refreshChart());
    },
    destroyed () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        ...mapMutations({
            setFetchingChart: 'portfolio/setFetchingChart'
        }),
        async refreshChart () {
            await this.fetchAppUsageChart();
            if (this.$refs['app-usage']) {
                this.chart = Highcharts.chart(this.$refs['app-usage'], this.chartConfig);
            }
        },
        updateChartAxis () {
            this.chart.update(
                {
                    chart: {
                        scrollablePlotArea: {
                            minWidth: this.chartScrollablePlotArea
                        }
                    },
                    series: this.chartData,
                    xAxis: {
                        categories: this.chartCategories
                    },
                    yAxis: {
                        title: {
                            text: this.chartYAxisTitle
                        }
                    }
                },
                true,
                true
                // ^^^ oneToOne highcharts parameter; the chart will not pivot correctly without this set
                // https://api.highcharts.com/class-reference/Highcharts.Chart#update
            );
        },
        async fetchAppUsageChart () {
            this.setFetchingChart({ isFetchingChart: true });

            if (this.aggCancel) {
                this.aggCancel.abort();
            }

            this.aggCancel = new AbortController();

            try {
                const { activeSegmentId: segmentId, appIdsFilter: appId, viewByMetadataField: metadataField } = this;

                const usageList = await getAppUsage({
                    appId,
                    metadataField,
                    defaultMetadataField: this.metadataFieldTotal,
                    timeSeries: {
                        ...this.activeTimeSeries,
                        period: 'dayRange'
                    },
                    segmentId,
                    signal: this.aggCancel.signal
                });

                const appUsageWithDisplayNames = usageList.map((app) => {
                    return {
                        name: get(this.appMapForActiveSubscription, `${app.id}.displayName`, ''),
                        ...app
                    };
                });

                // apps
                const { usageMap, yValuesSortedList } = this.createUsageMap(appUsageWithDisplayNames, {
                    xValue: 'id',
                    xName: 'name',
                    yValue: 'groupValue',
                    yName: 'groupValue'
                });
                this.appUsageMap = usageMap;
                this.appsSortedList = yValuesSortedList;

                // metadata;
                const {
                    usageMap: metadataFieldValuesUsageMap,
                    yValuesSortedList: metadataFieldValuesSortedList
                } = this.createUsageMap(appUsageWithDisplayNames, {
                    xValue: 'groupValue',
                    xName: 'groupValue',
                    yValue: 'id',
                    yName: 'name'
                });
                this.metadataFieldValuesUsageMap = metadataFieldValuesUsageMap;
                this.metadataFieldValuesSortedList = metadataFieldValuesSortedList;

                this.setFetchingChart({ isFetchingChart: false });
            } catch (err) {
                if (!isCancel(err)) {
                    this.setFetchingChart({ isFetchingChart: false });
                }
            }
        },
        createUsageMap (usageList, { xValue, xName, yValue, yName }) {
            const uniqueYValues = new Map();

            const usageMap = usageList.reduce((map, app) => {
                uniqueYValues.set(app[yValue], { id: app[yValue], name: app[yName] });
                /**
                 * usageMap end state when x-axis is 'applications', i.e., xValue = 'id', xName = 'name', yValue = 'groupValue' and yName = 'groupValue'
                 * {
                 *      gmailAppId: {
                 *          id: 123456,
                 *          name: 'Gmail',
                 *          yValues: {
                 *              'Product': {
                 *                  id: 123456,
                 *                  name: 'Gmail',
                 *                  groupValue: 'Product',
                 *                  averageDailyTimeOnApp: 66,
                 *                  visitors: 44,
                 *                  summedMinutes: 22
                 *               },
                 *              'Other metadata field value': {}
                 *          },
                 *          yTotals: {
                 *              averageDailyTimeOnApp: 'sum of averageDailyTimeOnApp across all yValues'
                 *              visitors: 'sum of visitors across all yValues'
                 *              summedMinutes: 'sum of summedMinutes across all yValues'
                 *          }
                 *      },
                 *      anotherAppId: {}
                 * }
                 */

                if (!map[app[xValue]]) {
                    map[app[xValue]] = {
                        id: app[xValue],
                        name: app[xName],
                        yValues: {},
                        yTotals: {}
                    };
                }

                map[app[xValue]].yValues[app[yValue]] = map[app[xValue]].yValues[app[yValue]] || {};
                map[app[xValue]].yValues[app[yValue]] = {
                    ...app
                };

                // collect y-axis totals per app, for sorting least/most
                this.selectedFieldOptions.forEach(({ id }) => {
                    if (!map[app[xValue]].yTotals[id]) map[app[xValue]].yTotals[id] = 0;
                    const appValue = app[id] || 0;
                    map[app[xValue]].yTotals[id] += appValue;
                });

                return map;
            }, {});

            // sort to display the group by slices in alphabetical order in the legend
            const yValuesSortedList = [...uniqueYValues.values()].sort((a, b) => {
                return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
            });

            return { usageMap, yValuesSortedList };
        }
    }
};
</script>

<style lang="scss" scoped>
.portfolio-app-usage-chart {
    // To make the chart use up the entire card body.
    /deep/.pendo-card__body {
        padding: 0;
        overflow: hidden;
    }

    // To make the y-axis not show up if the chart is scrollable and the loading indicator is up.
    /deep/.pendo-loading-overlay {
        z-index: 2;
    }

    /deep/ .pendo-multiselect__value {
        overflow: unset;
    }
}

.portfolio-app-usage-list {
    &--table-header-left {
        align-items: center;
        display: flex;
        gap: 8px;
    }
}

.highcharts-tooltip span {
    font-weight: bold;
}
</style>
