import get from 'lodash/get';

export function filterUnsharedResources (resourceMap) {
    return Object.values(resourceMap)
        .sort((a, b) => {
            const aName = a.displayName || a.name;
            const bName = b.displayName || b.name;

            return aName.toLowerCase() > bName.toLowerCase() ? 1 : -1;
        })
        .filter((resource) => {
            const isCustomAEUResource = get(resource, 'rootVersionId', false);
            const isSharedResource = get(resource, 'trainingSettings.enabled', false);

            return isCustomAEUResource || isSharedResource;
        });
}
