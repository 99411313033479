<template>
    <div>
        <div v-if="!activeUsesV2Adopt">
            <manage-team-v1 />
        </div>
        <div
            v-if="activeUsesV2Adopt"
            class="user-list">
            <pendo-table
                v-pendo-loading:feather="isFetchingUserList"
                class="user-list-table"
                :data="userRows"
                :filters="filters"
                :columns="columns"
                :show-all-overflow="true"
                :row-class-name="tableRowClassName"
                :auto-height-offset="96"
                :default-sort="{
                    prop: 'email',
                    order: 'ascending'
                }"
                auto-height
                title="Users"
                row-key="id">
                <template #headerActions>
                    <search
                        v-model="searchInput"
                        placeholder="Search users" />
                </template>
                <div
                    slot="username"
                    slot-scope="{ row }"
                    v-pendo-tooltip="!canEdit(row) && getTooltip(row)">
                    <pendo-button
                        theme="via"
                        type="link"
                        :disabled="!canEdit(row)"
                        :label="row.username"
                        :data-cy="`username-${row.id}-link`"
                        @click="loadEditUserOverlay(row)">
                        {{ row.username }}
                    </pendo-button>
                </div>
                <div
                    v-if="row.id !== currentUser.id"
                    slot="actions"
                    slot-scope="{ row }"
                    :data-user-id="row.id"
                    class="user-list-table-buttons">
                    <pendo-button
                        v-if="canEdit(row)"
                        v-pendo-tooltip="{ content: 'Edit User', delay: { show: 300, hide: 0 }, arrow: true }"
                        theme="via"
                        type="tertiary"
                        size="mini"
                        icon="edit"
                        @click="loadEditUserOverlay(row)" />
                    <pendo-button
                        v-if="!row.hasLoggedIn"
                        v-pendo-tooltip="{
                            content: row.resendInviteSuccess
                                ? 'Invite Sent!'
                                : row.resendInviteError
                                    ? 'Try again'
                                    : 'Resend Invite',
                            delay: { show: 300, hide: 0 },
                            arrow: true
                        }"
                        :icon="row.resendInviteSuccess ? 'check' : row.resendInviteError ? 'x' : 'send'"
                        :loading="row.isResendingInvite"
                        :class="{ 'is-success': row.resendInviteSuccess }"
                        class="user-list-table-buttons--resend-invite"
                        data-cy="resend-invite"
                        theme="via"
                        type="tertiary"
                        size="mini"
                        @click="resendInvite(row)" />
                    <pendo-button
                        v-pendo-tooltip="{ content: 'Remove User', delay: { show: 300, hide: 0 }, arrow: true }"
                        class="user-list-table-buttons--remove-user"
                        data-cy="remove-user"
                        theme="via"
                        type="tertiary"
                        size="mini"
                        icon="trash-2"
                        @click="confirmRemoveUser(row)" />
                </div>
                <div
                    slot="empty"
                    class="user-list-table--empty">
                    <span class="empty-text">
                        Invite users to join your team!
                    </span>
                </div>
            </pendo-table>
            <user-overlay
                :user="selectedUser"
                @closed="selectedUser = {}"
                @remove-user="confirmRemoveUser" />
        </div>
    </div>
</template>

<script>
import ManageTeamV1 from '@/components/settings/ManageTeamV1';
import UserOverlay from '@/components/settings/UserOverlay';
import Search from '@/components/Search';
import { isOpsByUserFlag, isSuperByRole, isAdminByFlag } from '@/utils/auth';
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import { PendoTable, PendoLoading, PendoButton, PendoTooltip } from '@pendo/components';

export default {
    name: 'ManageTeam',
    components: {
        ManageTeamV1,
        UserOverlay,
        PendoTable,
        PendoButton,
        Search
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    data () {
        return {
            tableProps: {
                rowClassName: ({ row }) => {
                    if (row.id === this.currentUser.id) {
                        return '';
                    }

                    return row.hasLoggedIn ? 'is-removable' : 'is-invitable';
                }
            },
            searchInput: '',
            columns: [
                {
                    prop: 'username',
                    label: 'Email',
                    sortable: true,
                    minWidth: 300
                },
                {
                    prop: 'first',
                    label: 'First Name',
                    showOverflowTooltip: true,
                    sortable: true
                },
                {
                    prop: 'last',
                    label: 'Last Name',
                    showOverflowTooltip: true,
                    sortable: true
                },
                {
                    prop: 'permissions',
                    label: 'Permissions',
                    sortable: true
                },
                {
                    type: 'actions',
                    width: 142
                }
            ],
            selectedUser: {},
            nonAdminTooltipContent:
                'This is an approved Pendo Employee that has been granted access to your subscription. ' +
                'You cannot update their permissions, however you can remove this user.',
            adminTooltipContent: 'Please contact another Admin to update your permissions.',
            disabledEditTooltip: {
                multiline: true,
                placement: 'top-start',
                content: this.nonAdminTooltipContent
            }
        };
    },
    computed: {
        ...mapState({
            currentUser: (state) => state.auth.user,
            isFetchingUserList: (state) => state.users.isFetchingUserList,
            hasFetchUserListError: (state) => state.users.hasFetchUserListError
        }),
        ...mapGetters({
            users: 'users/listUsers',
            activeUsesV2Adopt: 'subscriptions/activeUsesV2Adopt',
            activeIsAdmin: 'auth/isAdmin',
            activeIsSuper: 'auth/isSuper',
            activeIsOps: 'auth/isOps'
        }),
        userRows () {
            return this.users.map((user) => {
                let permissions;
                if (isOpsByUserFlag(user)) {
                    permissions = 'Ops';
                } else if (isSuperByRole(user)) {
                    permissions = 'Super';
                } else if (isAdminByFlag(user)) {
                    permissions = 'Admin';
                } else {
                    permissions = 'User';
                }

                return {
                    ...user,
                    permissions
                };
            });
        },
        filters () {
            return [
                {
                    prop: ['username', 'first', 'last', 'permissions'],
                    value: this.searchInput
                }
            ];
        }
    },
    async created () {
        await this.fetchUserList();
    },
    methods: {
        ...mapActions({
            _resendInvite: 'users/resendInvite',
            fetchUserList: 'users/fetchUserList'
        }),
        ...mapMutations({
            setUserOverlayVisible: 'users/setUserOverlayVisible'
        }),
        tableRowClassName ({ row }) {
            if (row.id === this.currentUser.id) {
                return '';
            }

            return row.hasLoggedIn ? 'is-removable' : 'is-invitable';
        },
        confirmRemoveUser (userToRemove) {
            this.$modal.show('via-modal', {
                title: 'Remove User?',
                component: 'RemoveUserModal',
                componentProps: {
                    userToRemove
                }
            });
        },
        async resendInvite (user) {
            if (user.resendInviteSuccess) {
                return;
            }
            await this._resendInvite({ user });
        },
        loadEditUserOverlay (user) {
            this.selectedUser = user;
            this.setUserOverlayVisible({ isVisible: true });
        },
        isSelf (userToEdit) {
            return userToEdit.id === this.currentUser.id;
        },
        isSelfAndV2Admin (userToEdit) {
            const isSelfAndV2Admin = this.isSelf(userToEdit) && this.activeUsesV2Adopt && this.activeIsAdmin;

            return isSelfAndV2Admin;
        },
        getTooltip (userToEdit) {
            return {
                ...this.disabledEditTooltip,
                content: this.isSelfAndV2Admin(userToEdit) ? this.adminTooltipContent : this.nonAdminTooltipContent
            };
        },
        canEdit (userToEdit) {
            if (this.isSelf(userToEdit)) {
                return false;
            }

            if (this.activeIsOps) {
                return true;
            }

            if (isSuperByRole(userToEdit) || isOpsByUserFlag(userToEdit)) {
                return false;
            }

            return !this.isSelf(userToEdit) && (this.activeIsAdmin || this.activeIsSuper);
        }
    }
};
</script>

<style lang="scss">
.user-list-table {
    &--empty {
        display: flex;
        align-items: center;
        justify-content: center;

        .pendo-icon {
            margin-right: 0.5em;
            display: flex;
        }

        .empty-text {
            color: $gray-primary;
        }
    }
}

.user-list-table-buttons {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
}
</style>
