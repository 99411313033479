<template>
    <pendo-multiselect
        multiple
        :show-selected-values="false"
        class="app-filter"
        label-key="displayName"
        value-key="id"
        stateless
        placeholder="Select Applications"
        :value="flatSelectedOptions"
        :close-on-select="false"
        :full-width="true"
        :options="groupedAppOptions"
        group-options-key="options"
        group-label-key="label"
        :max-menu-height="300"
        :max-menu-width="400"
        :min-menu-width="200"
        @select="addAppToFilter"
        @remove="removeAppFromFilter">
        <template #selectedLabel>
            {{ selectedAppsTriggerText }}
        </template>
        <template
            slot="option"
            slot-scope="{ option }">
            <pendo-checkbox-option
                v-if="option.id !== ALL_APPS_AGG_ID"
                :option="option" />
            <div
                v-if="option.id === ALL_APPS_AGG_ID"
                class="all-apps-option">
                {{ option.displayName }}
            </div>
        </template>
        <template #optionGroup="{ option }">
            <div
                v-if="option.id === 'app-groups'"
                class="group-header-label">
                {{ option.label }}
            </div>
            <div
                v-else
                class="group-header-label">
                {{ option.label }} ({{ option.options.length }})
            </div>
        </template>
        <div
            v-if="individualSelectedApps.length"
            slot="beforeList"
            class="user-selected-apps">
            <div class="user-selected-apps-header">
                <div class="group-header-label pendo-multiselect__group">
                    Selected ({{ individualSelectedApps.length }})
                </div>
                <pendo-button
                    type="link"
                    label="Clear"
                    class="clear-selected-apps-button"
                    @click="resetToAllApplications" />
            </div>
            <div class="pendo-multiselect__option">
                <pendo-checkbox-option
                    v-for="(selectedOption, index) of individualSelectedApps"
                    :key="index"
                    :option="selectedOption"
                    @click="removeAppFromFilter(selectedOption)" />
            </div>
            <pendo-divider
                stroke="#dadce5"
                height="16px" />
        </div>
        <div
            slot="header"
            class="application-search-container">
            <pendo-multiselect-input
                class="application-search"
                :input-value="searchTerm"
                :is-search-icon-visible="true"
                computed-placeholder="Search Applications..."
                :update-input-value="updateSearchTerm" />
        </div>
        <template slot="noData">
            <div class="no-data-message">
                No applications found for search text "<strong>{{ searchTerm }}</strong>"
            </div>
        </template>
    </pendo-multiselect>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import {
    PendoMultiselect,
    PendoCheckboxOption,
    PendoMultiselectInput,
    PendoButton,
    PendoDivider
} from '@pendo/components';
import { ALL_APPS_AGG_ID, ALL_EXTENSION_APPS_AGG_ID } from '@/constants/apps';

export default {
    name: 'AppFilter',
    components: {
        PendoMultiselect,
        PendoMultiselectInput,
        PendoCheckboxOption,
        PendoButton,
        PendoDivider
    },
    data () {
        return {
            ALL_APPS_AGG_ID,
            searchTerm: ''
        };
    },
    computed: {
        ...mapState({
            appIdsFilter: (state) => state.filters.appIdsFilter
        }),
        ...mapGetters({
            listAllForActiveSubscription: 'apps/listAllForActiveSubscription',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption'
        }),
        normalizedAppIdsFilter () {
            return this.appIdsFilter.map((app) => {
                if (app === ALL_EXTENSION_APPS_AGG_ID) return ALL_APPS_AGG_ID;

                return app;
            });
        },
        baseOptions () {
            return [
                {
                    id: ALL_APPS_AGG_ID,
                    displayName: 'All Applications'
                }
            ];
        },
        appOptions () {
            return this.listAllForActiveSubscription.map((app) => {
                return {
                    id: app.id,
                    displayName: app.displayName
                };
            });
        },
        groupedAppOptions () {
            const applicationGroups = {
                id: 'app-groups',
                label: 'Application Groups',
                options: this.filterAppListBySearchTerm(this.baseOptions)
            };

            const allAppOptions = {
                id: 'individual-apps',
                label: 'All Applications',
                options: this.filterAppListBySearchTerm(this.appOptions)
            };

            const options = [];

            if (applicationGroups.options.length) {
                options.push(applicationGroups);
            }

            if (allAppOptions.options.length) {
                options.push(allAppOptions);
            }

            return options;
        },
        individualSelectedApps () {
            return this.appOptions.filter((app) => this.normalizedAppIdsFilter.includes(app.id));
        },
        flatSelectedOptions () {
            const flatSelectedOptions = [...this.baseOptions, ...this.appOptions];

            return flatSelectedOptions.filter((app) => this.normalizedAppIdsFilter.includes(app.id));
        },
        selectedAppsTriggerText () {
            return this.flatSelectedOptions.map((app) => app.displayName).join(', ');
        }
    },
    methods: {
        ...mapActions({
            updateAppFilter: 'filters/updateAppFilter'
        }),
        updateSearchTerm (newVal) {
            this.searchTerm = newVal;
        },
        filterAppListBySearchTerm (appList) {
            if (!this.searchTerm) return appList;

            return appList.filter((opt) => {
                return opt.displayName.toLowerCase().includes(this.searchTerm.toLowerCase());
            });
        },
        addAppToFilter (app) {
            if (this.normalizedAppIdsFilter.includes(app.id)) return;

            if (app.id === ALL_APPS_AGG_ID) {
                this.resetToAllApplications();

                return;
            }

            let appFilter = this.normalizedAppIdsFilter.slice();
            if (appFilter.includes(ALL_APPS_AGG_ID)) {
                appFilter = [];
            }

            appFilter.push(app.id);

            this.updateAppFilter({ appIds: appFilter });
        },
        removeAppFromFilter (app) {
            const appFilter = this.normalizedAppIdsFilter.slice();
            const indexOfApp = appFilter.indexOf(app.id);

            if (indexOfApp === -1) return;

            appFilter.splice(indexOfApp, 1);

            if (!appFilter.length) {
                this.resetToAllApplications();
            } else {
                this.updateAppFilter({ appIds: appFilter });
            }
        },
        resetToAllApplications () {
            if (this.activeIsDigitalAdoption) return this.updateAppFilter({ appIds: [ALL_EXTENSION_APPS_AGG_ID] });

            this.updateAppFilter({ appIds: [ALL_APPS_AGG_ID] });
        }
    }
};
</script>
<style lang="scss" scoped>
.app-filter {
    position: relative;
}

.pendo-multiselect__trigger {
    height: 36px;
    min-width: 200px;
    max-width: 400px;
    padding: 0px 8px;
    display: flex;
    justify-content: space-between;
}

.selected-apps-trigger-text {
    max-width: 380px;
    display: block;
    line-height: 20px;
    box-sizing: border-box;
    padding-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.all-apps-option {
    display: flex;
    align-items: center;
}

.user-selected-apps-header {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    box-sizing: border-box;
    height: 26px;

    .clear-selected-apps-button {
        height: 26px;
        font-size: 12px;
    }
}

.application-search {
    padding: 0 8px;
    height: 34px;
    border-bottom: 1px solid $gray-lighter-6;
    border-radius: 3px;
    justify-content: start;

    /deep/ .pendo-multiselect__search-icon-wrapper {
        color: $gray-lighter-4;
    }
}

.group-header-label {
    font-size: 10px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
}
</style>
