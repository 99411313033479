<template>
    <pendo-modal
        class="pendo-add-update-extension-app-modal"
        title="Select an App to Edit Theme"
        height="auto"
        :visible="visible"
        size="medium"
        @close="emitClose">
        <template #body>
            <pendo-multiselect
                :value="app"
                :allow-empty="false"
                :options="appListForActiveSubscription"
                :searchable="true"
                label-key="displayName"
                value-key="id"
                preselect-first
                full-width
                placeholder="Select Application"
                class="guide-them-app-chooser--app-select"
                @select="onAppSelect($event)" />
        </template>
        <template #footer>
            <pendo-button
                theme="via"
                type="secondary"
                label="Cancel"
                @click="emitClose" />
            <pendo-button
                theme="via"
                label="Edit"
                @click="onSubmit" />
        </template>
    </pendo-modal>
</template>
<script>
import { PendoButton, PendoModal, PendoMultiselect } from '@pendo/components';
import { mapGetters } from 'vuex';

export default {
    name: 'GuideThemeAppChooser',
    components: {
        PendoButton,
        PendoModal,
        PendoMultiselect
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            app: null
        };
    },
    computed: {
        ...mapGetters({
            appListForActiveSubscription: 'apps/listAllForActiveSubscription'
        })
    },
    methods: {
        emitClose () {
            this.$emit('close');
        },
        onAppSelect (app) {
            this.app = app;
        },
        onSubmit () {
            this.$emit('launchThemeEditor', this.app);
        }
    }
};
</script>
