<template>
    <div
        v-if="hasGuides && !isLoading"
        v-custom-pendo-draggable="{ isEditing, dragOptions }"
        class="guide-list-table-container">
        <div
            :class="{
                'guide-list-table': true,
                'has-localization': canLocalizeResourceCenter,
                'is-editing': isEditing
            }"
            pendo-draggable-container>
            <div :class="{ 'is-editing': isEditing, 'table-headings': true }">
                <div
                    v-for="column in availableColumns"
                    :key="column.label"
                    :class="`${column.class} heading`">
                    {{ column.label }}
                    <pendo-icon
                        v-if="column.hasTooltipIcon"
                        v-pendo-tooltip="{
                            ...tooltipOptions,
                            content: column.tooltipContent,
                            multiline: true
                        }"
                        stroke="#128297"
                        size="14"
                        type="info" />
                </div>
                <div v-if="isEditing" />
            </div>
            <div
                v-for="guide in processedGuides"
                :key="guide.id"
                :class="{ 'is-editing': isEditing, 'list-item': true }"
                pendo-draggable-item>
                <div
                    v-for="column in availableColumns"
                    :key="column.label"
                    :class="column.guideClass">
                    <div
                        v-if="isColumn(column, 'Name')"
                        class="table-drag-column">
                        <span
                            v-if="isEditing && !isAnnouncementsModule"
                            :class="{ 'drag-area': true, 'drag-area-visible': !isAnnouncementsModule }">
                            <pendo-icon
                                :center="true"
                                :inline="true"
                                size="14"
                                type="more-vertical" />
                        </span>
                        <div
                            v-pendo-tooltip="{ ...tooltipOptions, content: overflowTooltipContent }"
                            class="tooltip-container">
                            <a
                                :href="guide.href"
                                target="_blank"
                                :class="{ 'is-editing': isEditing }"
                                @mouseover="isOverflowing($event, guide.name)">
                                {{ guide.name }}
                            </a>
                        </div>
                    </div>
                    <div
                        v-if="isColumn(column, 'DisplayName')"
                        pendo-draggable-ignore>
                        <div
                            v-pendo-tooltip="{ ...tooltipOptions, content: overflowTooltipContent }"
                            class="tooltip-container"
                            @mouseover="isOverflowing($event, guide.title)">
                            <pendo-editable-content
                                v-if="isEditing"
                                v-model="guide.title"
                                :disabled="!isEditing"
                                :allow-empty="false"
                                class="display-name-input"
                                exit-event="blur"
                                @input="$emit('userHasChanges')"
                                @exitEditMode="updateDisplayName($event, guide)" />
                            <span v-if="!isEditing">
                                {{ guide.title }}
                            </span>
                        </div>
                    </div>
                    <div v-if="isColumn(column, 'Status')">
                        <div
                            :class="guide.state"
                            class="status-indicator">
                            <pendo-icon
                                :center="true"
                                :inline="true"
                                :fill="guide.stateColor"
                                :stroke="guide.stateColor"
                                size="14"
                                type="circle" />
                            <span>{{ guide.state }}</span>
                        </div>
                    </div>
                    <div v-if="isColumn(column, 'Localization')">
                        <div class="translation-indicator">
                            <pendo-popover
                                v-if="guide.hasUntranslatedGuides"
                                placement="top-start"
                                arrow="true">
                                <pendo-icon
                                    :center="true"
                                    :inline="true"
                                    fill="#dba211"
                                    stroke="#fff"
                                    size="16"
                                    type="alert-circle-solid" />
                                <template slot="popover">
                                    <p class="l10n-warning-text">
                                        {{ getTranslationWarningText }}
                                    </p>
                                </template>
                            </pendo-popover>
                            <pendo-icon
                                v-if="!guide.hasUntranslatedGuides"
                                :center="true"
                                :inline="true"
                                fill="#00c583"
                                stroke="#fff"
                                size="16"
                                type="check-circle-2-solid" />
                        </div>
                        {{ guide.untranslatedGuidesText }}
                    </div>
                    <div v-if="isColumn(column, 'Segment')">
                        <div class="guide-segment-content">
                            <span
                                v-pendo-tooltip="{
                                    ...tooltipOptions,
                                    content: getTooltipText(guide.selectedSegmentGuide)
                                }"
                                class="tooltip-container">
                                {{ guide.selectedSegmentGuide }}
                            </span>
                        </div>
                    </div>
                    <div v-if="isColumn(column, 'Activation')">
                        {{ guide.launchMethod }}
                    </div>
                    <div v-if="isColumn(column, 'PageLocation')">
                        <div
                            v-pendo-tooltip="{ ...tooltipOptions, content: overflowTooltipContent }"
                            class="page-location-icons">
                            <pendo-icon
                                size="14"
                                :type="guide.pageLocation === 'Sitewide' ? 'globe' : 'file'"
                                stroke="#2A2C35" />
                            <span @mouseover="isOverflowing($event, guide.pageLocation)">
                                {{ guide.pageLocation }}
                            </span>
                        </div>
                    </div>
                    <div v-if="isColumn(column, 'StartDate')">
                        {{ guide.startDate }}
                    </div>
                </div>
                <div
                    v-if="isEditing && !isAnnouncementsModule"
                    class="hover-action-container"
                    pendo-draggable-ignore>
                    <pendo-multiselect
                        :options="[
                            {
                                name: EVENTS.remove,
                                guide,
                                icon: { type: 'trash-2' }
                            }
                        ]"
                        :popper-options="{
                            placement: 'bottom-end',
                            modifiers: { flipVariations: { enabled: false } }
                        }"
                        stateless
                        label-key="name"
                        value-key="name"
                        :value="guide"
                        @select="onEditMenuSelect">
                        <template #trigger="{ toggleMenu }">
                            <slot>
                                <pendo-button
                                    icon="more-horizontal"
                                    type="tertiary"
                                    icon-size="24"
                                    @click="toggleMenu" />
                            </slot>
                        </template>
                        <pendo-icon-option
                            slot="option"
                            slot-scope="{ option }"
                            :option="option" />
                    </pendo-multiselect>
                </div>
                <div
                    v-if="isAnnouncementsModule"
                    v-pendo-tooltip="announcementTooltipMessage"
                    class="delete-guide"
                    data-cy="delete-announcement-guide-link">
                    <pendo-icon
                        v-if="isEditing && isAnnouncementsModule"
                        size="14"
                        type="trash-2"
                        class="delete-guide-icon"
                        @click.native="showDeleteModal(guide)" />
                    <a
                        v-if="isEditing && isAnnouncementsModule"
                        class="delete-guide-link"
                        href="#"
                        @click="showDeleteModal(guide)">
                        Remove
                    </a>
                </div>
            </div>
        </div>
        <pendo-modal
            :show-close="false"
            :visible="isDeleteModalVisible"
            :confirm-button-config="{ ...deleteModalButtonConfig, label: deleteModalButtonText }"
            :title="deleteModalButtonText"
            type="confirmation"
            :message="deleteModalContentText"
            width="400px"
            @confirm="onDeleteConfirm"
            @cancel="hideDeleteModal" />
    </div>
</template>

<script>
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import range from 'lodash/range';
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';
import {
    PendoTooltip,
    PendoEditableContent,
    PendoIcon,
    PendoIconOption,
    PendoPopover,
    PendoMultiselect,
    PendoButton,
    PendoModal,
    PendoDraggable
} from '@pendo/components';

export const COLUMNS = Object.freeze({
    Name: {
        label: 'Name',
        class: 'guide-name-heading',
        guideClass: 'guide-name-item'
    },
    DisplayName: {
        label: 'Display Name',
        class: '',
        guideClass: 'guide-display-name'
    },
    Status: {
        label: 'Status',
        class: '',
        guideClass: 'guide-status-changer'
    },
    // Localization: {
    //     label: 'Localization Status',
    //     class: '',
    //     guideClass: 'guide-translation-status'
    // },
    Segment: {
        label: 'Segment',
        class: '',
        guideClass: 'guide-segment'
    },
    Activation: {
        label: 'Existing Guide Activation',
        class: '',
        guideClass: ''
    },
    PageLocation: {
        label: 'Page Location',
        class: 'page-location-heading',
        guideClass: 'guide-page-location',
        hasTooltipIcon: true,
        tooltipContent: "Only Guides with a page location of 'Sitewide' will appear throughout your application."
    }
    // StartDate: {
    //     label: 'Start Date',
    //     class: '',
    //     guideClass: ''
    // }
});

export const EVENTS = Object.freeze({
    remove: 'Remove Guide',
    edit: 'Edit Keywords'
});

export default {
    name: 'GuideListContentTable',
    components: {
        PendoEditableContent,
        PendoIcon,
        PendoPopover,
        PendoMultiselect,
        PendoButton,
        PendoIconOption,
        PendoModal
    },
    directives: {
        PendoTooltip,
        CustomPendoDraggable: (el, { value }, vnode) => {
            const { isEditing, dragOptions } = value;
            const { inserted, unbind, componentUpdated } = PendoDraggable;

            const options = {
                name: 'pendo-draggable',
                rawName: 'v-pendo-draggable',
                value: { ...dragOptions, disabled: !isEditing },
                expression: '{ dragOptions }',
                modifiers: {},
                def: {},
                oldValue: {}
            };

            if (!isEditing && vnode.context.PendoDraggable) unbind(el, options, vnode);
            if (isEditing && !vnode.context.PendoDraggable) inserted(el, options, vnode);
            if (isEditing && vnode.context.PendoDraggable) componentUpdated(el, options, vnode);
        }
    },
    props: {
        module: {
            type: Object,
            required: true
        },
        guides: {
            type: Array,
            required: true
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isEditing: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            COLUMNS,
            EVENTS,
            dragOptions: {
                events: {
                    'sortable:stop': this.sortRows
                }
            },
            tooltipOptions: {
                placement: 'top',
                arrow: true
            },
            guideToEdit: null,
            announcementTooltipMessage: 'Remove from Announcements Module',
            isDeleteModalVisible: false,
            deleteModalButtonConfig: {
                type: 'danger',
                theme: 'app'
            },
            sortedGuides: null,
            overflowTooltipContent: null
        };
    },
    computed: {
        ...mapGetters({
            appFromGuide: 'apps/appFromGuide',
            getTimezone: 'subscriptions/getTimezone',
            pages: 'pages/list'
        }),
        getTranslationWarningText () {
            const guideType = this.isAnnouncementsModule ? 'announcement' : 'guide';

            return `Missing or partial translations for these languages. View ${guideType} for details.`;
        },
        availableColumns () {
            return Object.values(this.COLUMNS).filter((column) => {
                switch (column.label) {
                    case get(COLUMNS, 'Activation.label'):
                    case get(COLUMNS, 'DisplayName.label'):
                    case get(COLUMNS, 'PageLocation.label'):
                    case get(COLUMNS, 'StartDate.label'):
                        if (this.isAnnouncementsModule) return false;
                        break;
                    case get(COLUMNS, 'Localization.label'):
                        if (!this.canLocalizeResourceCenter) return false;
                }

                return true;
            });
        },
        processedGuides () {
            return this.guides.map((guide) => {
                guide.href = this.buildGuideDetailsLink(guide);
                guide.startDate = this.getStartDate(guide.showsAfter);
                guide.selectedSegmentGuide = this.selectedSegmentName(guide);
                guide.hasUntranslatedGuides = this.hasUntranslatedGuides(guide);
                guide.untranslatedGuidesText = guide.hasUntranslatedGuides
                    ? guide.untranslatedLangs
                    : 'Fully translated';
                guide.stateColor = this.getStateIconColor(guide.state);
                guide.pageLocation = this.getPageLocationDisplayName(guide);

                return guide;
            });
        },
        hasGuides () {
            return get(this, 'guides.length', 0) > 0;
        },
        moduleType () {
            return get(this, 'module.attributes.resourceCenter.moduleId', '');
        },
        moduleTypeLabel () {
            return this.isAnnouncementsModule ? 'Announcement' : 'Guide';
        },
        isAnnouncementsModule () {
            return this.moduleType === 'AnnouncementsModule';
        },
        canLocalizeResourceCenter () {
            const moduleApp = this.appFromGuide(this.module);

            return get(moduleApp, 'applicationFlags.guideLocalization', false);
        },
        deleteModalContentText () {
            return `This ${
                this.moduleTypeLabel
            } will be removed from your Resource Center immediately once saved and will not require you to Push to Public. Are you sure you want to remove?`;
        },
        deleteModalButtonText () {
            return `Remove ${this.moduleTypeLabel}`;
        }
    },
    methods: {
        sortRows ({ data }) {
            const { oldIndex, newIndex } = data;

            if (oldIndex === newIndex) return;

            const change = oldIndex < newIndex ? 1 : -1;
            const rangeArr = range(oldIndex + change, newIndex + change);
            const copy = cloneDeep(this.guides);

            while (rangeArr.length > 0) {
                const next = rangeArr[0];
                const curr = next - change;
                copy[curr] = this.guides[next];
                rangeArr.shift();
            }

            copy[newIndex] = this.guides[oldIndex];
            this.$emit('order', copy);
        },
        isColumn (column, name) {
            return COLUMNS[name] && column.label === COLUMNS[name].label;
        },
        buildGuideDetailsLink (guide) {
            return `/guides/${guide.id}/settings`;
        },
        hasUntranslatedGuides (guide) {
            return get(guide, 'untranslatedLangs', []).length > 0;
        },
        selectedSegmentName (guide) {
            if (!guide.segment) {
                return 'Everyone';
            }

            return guide.segment.id === 'custom' ? 'Custom Segment' : guide.segment.name;
        },
        getTooltipText (segment) {
            return segment === 'Everyone' || segment === 'Custom Segment' ? '' : segment;
        },
        updateDisplayName (name, guideToEdit) {
            const copy = cloneDeep(this.guides);
            const newGuideList = copy.map((guide) => {
                if (guide.id === guideToEdit.id) guide.title = name;

                return guide;
            });

            this.$emit('nameChange', newGuideList);
        },
        hideDeleteModal () {
            this.guideToEdit = null;
            this.isDeleteModalVisible = false;
        },
        showDeleteModal (guide) {
            this.guideToEdit = guide;
            this.isDeleteModalVisible = true;
        },
        onDeleteConfirm () {
            const newGuideList = this.guides.filter((guide) => guide.id !== this.guideToEdit.id);
            this.$emit('set', newGuideList);
            this.hideDeleteModal();
        },
        onEditMenuSelect (event) {
            switch (event.name) {
                case this.EVENTS.remove:
                    this.showDeleteModal(event.guide);
                    break;
                case this.EVENTS.edit:
                default:
                    break;
            }
        },
        getStartDate (showsAfter) {
            return showsAfter
                ? moment(showsAfter)
                    .tz(this.getTimezone)
                    .format('MMM D, YYYY h:mma z')
                : '--';
        },
        getStateIconColor (state) {
            switch (state) {
                case 'public':
                    return 'rgb(0, 170, 98)';
                case 'draft':
                    return 'rgb(7, 105, 155)';
                case 'disabled':
                default:
                    return 'rgb(106, 108, 117)';
            }
        },
        getPageLocationDisplayName (guide) {
            const guidePageId = get(guide, 'steps[0].pageId');

            if (!guidePageId) {
                return 'Sitewide';
            }

            const page = this.pages.find((item) => {
                const singlePageId = item.id || item.pageId;

                return singlePageId === guidePageId;
            });

            return get(page, 'displayName', 'Location not found');
        },
        isOverflowing (event, text) {
            this.overflowTooltipContent = null;
            const { scrollWidth, offsetWidth } = event.target;

            if (scrollWidth > offsetWidth) this.overflowTooltipContent = text;
        }
    }
};
</script>

<style lang="scss" scoped>
.loading-container {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/deep/ .pendo-card {
    .pendo-card__body {
        padding: 0;
    }
}

.table-headings {
    background-color: $gray-lighter-6;
    height: 40px;
    font-weight: bold;
    position: sticky;
    top: 0px;
}

.page-location-heading {
    display: flex;

    .pendo-icon {
        margin-left: 10px;
    }
}

.page-location-icons {
    display: flex;

    .pendo-icon {
        margin-right: 10px;
    }
}

.table-headings,
.list-item {
    padding-left: 16px;
    display: grid;
    grid-auto-flow: column;
    overflow: auto;
    column-gap: 8px;
    align-items: center;
    grid-template-columns: repeat(6, minmax(0, 1fr));

    &.is-editing {
        grid-template-columns: repeat(6, minmax(0, 1fr)) 32px;
    }

    .hover-action-container,
    .delete-guide {
        justify-self: center;
    }
}

.has-localization {
    .table-headings,
    .list-item {
        grid-template-columns: repeat(6, minmax(0, 1fr));

        &.is-editing {
            grid-template-columns: repeat(6, minmax(0, 1fr)) 80px;
        }
    }
}

.is-announcements-module {
    .table-headings,
    .list-item {
        grid-template-columns: repeat(5, minmax(0, 1fr)) 80px;

        &.is-editing {
            grid-template-columns: repeat(5, minmax(0, 1fr)) 80px;
        }
    }
}

.has-localization.is-announcements-module {
    .table-headings,
    .list-item {
        grid-template-columns: repeat(5, minmax(0, 1fr));
        column-gap: 8px;
        align-content: center;

        &.is-editing {
            grid-template-columns: repeat(5, minmax(0, 1fr)) 80px;
        }
    }
}

.status-indicator,
.drag-area,
.table-drag-column,
.custom-option {
    display: flex;
    align-items: center;
}

.guide-list {
    min-height: 400px;
}

.guide-list-table {
    min-width: 665px;
    max-height: 535px;
    cursor: pointer;
}

.guide-translation-status {
    * {
        height: 14px;
        display: flex;

        .translation-indicator {
            margin-right: 8px;
        }
    }
}

/deep/ .pendo-editable-content__form {
    overflow: hidden;
}

/deep/ .guide-name-item a,
/deep/ .guide-display-name span,
.guide-segment-content,
.guide-page-location span {
    overflow: hidden;
    height: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 0;
    display: block;
}

.tooltip-container {
    width: 100%;
}

/deep/ .guide-name-item a {
    width: 100%;

    &.is-editing {
        width: 85%;
    }
}

.l10n-warning-text {
    max-width: 300px;
    text-align: center;
}

.no-guides-msg-wrapper {
    display: flex;
    flex-direction: column;

    .no-guides-msg {
        font-weight: 300;
        line-height: 1.4;
        margin: 0 24px;
        padding: 150px 0px;
        text-align: center;

        .header {
            font-size: 24px;
        }

        .body {
            color: $gray-lighter-2;
            font-size: 14px;
        }

        .existing-announcements-button {
            margin-right: 8px;
        }
    }
}

.announcement-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

/deep/ .display-name-input {
    &.pendo-editable-content {
        grid-template-columns: unset;
        width: 101%;

        span {
            height: unset;
        }
    }
}

.status-circle {
    margin-right: 8px;
}

.list-item,
.announcements-list-item {
    min-height: 60px;
    border-bottom: 1px solid $gray-lighter-5;
    background-color: $white;

    .drag-area {
        visibility: hidden;
        top: -1px;
        width: 22px;
        height: 22px;
        text-align: center;
        margin-right: 1px;
    }

    &.is-editing {
        .drag-area.drag-area-visible {
            visibility: visible;
        }
    }

    &.sortable-chosen {
        .remove-guide-btn {
            visibility: hidden;
        }
    }

    &.sortable-ghost {
        border-top: 1px solid $link-dark;

        td {
            border: none;
        }
    }

    &:hover {
        background-color: $gray-lighter-8;
    }

    .delete-guide {
        display: flex;

        &-icon {
            margin-right: 5px;
            color: $link-dark;
        }
    }
}

.select-guide-container {
    padding: 20px;
}

.pendo-select {
    display: inline-block;
    width: 236px;

    /deep/ .multiselect {
        max-width: 236px;
    }
}

.add-btn {
    margin-left: 8px;
}

.bottom-line {
    border-bottom: 1px solid $gray-lighter-5;
}

.status-indicator {
    margin-right: 4px;

    > span {
        margin-left: 4px;
    }
}

.guide-status-changer {
    text-transform: capitalize;
}
</style>
