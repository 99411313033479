<template>
    <pendo-card
        class="snippet-card"
        body-min-height="0"
        title="API Key">
        <div class="code-snippets--body">
            <div>
                To install, paste this configuration into the API Value at
                <a
                    href="https://admin.google.com"
                    target="_blank">https://admin.google.com</a>
            </div>
            <div>
                <copy-code-block
                    language="javascript"
                    :button-options="{ label: 'Copy Code', position: 'top' }"
                    :code-to-copy="extensionConfigBlock" />
            </div>
        </div>
    </pendo-card>
</template>

<script>
import { PendoCard } from '@pendo/components';
import CopyCodeBlock from '@/components/common/CopyCodeBlock.vue';

export default {
    name: 'ExtensionApiKey',
    components: {
        PendoCard,
        CopyCodeBlock
    },
    props: {
        subscription: {
            type: Object,
            required: true
        }
    },
    computed: {
        extensionApiKey () {
            if (!this.subscription) return '';

            return this.subscription.extensionApiKey;
        },
        extensionConfigBlock () {
            const config = {
                APIKey: {
                    Value: this.extensionApiKey
                }
            };

            return JSON.stringify(config, null, 4);
        }
    }
};
</script>

<style></style>
