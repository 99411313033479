import isPlainObject from 'lodash/isPlainObject';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import find from 'lodash/find';
import keyBy from 'lodash/keyBy';
import { SEGMENT_OPERATORS, operatorRequiresUnitCountFormat } from '@/constants/segments';
import moment, { DATE_FORMAT } from '@/utils/moment';
import { pluralize } from '@/utils/filters';

// rules -> segment

export function validateAndBuildSegment ({ rules, name, appId }) {
    const isValid = validateSegmentRules(rules);
    if (!isValid) return null;

    return buildSegment({ rules, name, appId });
}

export function validateSegmentRules (rules) {
    return rules.every((orRules) => {
        return orRules.every((rule) => isRuleValid(rule));
    });
}

export function isRuleValid (rule) {
    const { schema, operator, value, time, guide, poll, page, feature, app } = rule;
    const noSourceEntryProvided = value == null && guide == null && page == null && feature == null && app == null;

    if (isEmpty(schema) || isEmpty(operator) || noSourceEntryProvided) {
        return false;
    }

    if (schema.schema === 'poll') {
        if (!guide) return false;
        if (!poll) return false;
        if (!RegExp(/^(not)?responded$/).test(operator.value)) return value != null;
    }

    if (schema.schema === 'page') {
        if (!page) return false;
        if (time === 'withinlast' && !value) return false;
        if (time === 'since' && !value) return false;
        if (time === 'ever') return true;
    }

    // duplicating this logic since we're adding at least/at most in the future
    if (schema.schema === 'feature') {
        if (!feature) return false;
        if (time === 'withinlast' && !value) return false;
        if (time === 'since' && !value) return false;
        if (time === 'atleast' && !value) return false;
        if (time === 'atmost' && !value) return false;
        if (time === 'ever') return true;
    }

    if (schema.schema === 'application') {
        if (time === 'ever') return true;
    }

    if (!operator.multivalue) {
        return true;
    }

    if (operator.value === 'between') {
        return !!value.start && !!value.end;
    }

    const isCsvUploadOperator = operator.value === 'listContains' || operator.value === '!listContains';

    // rule is csv upload related, but does not have a reference to an entity tag
    if (isCsvUploadOperator && !rule.tagId) return false;

    if (!value) return false;

    return value.every((val) => val != null);
}

// appId is legacy from before multi-app was introduced, and will be removed
export function buildSegment ({ rules, name, appId }) {
    const filters = parseRulesAsFilters(rules, appId);
    const segment = {
        name,
        shared: true,
        definition: {
            filters,
            advanced: false,
            advancedOptions: { selectedVisitorType: null }
        },
        pipeline: [
            { source: { visitors: null } },
            { identified: 'visitorId' },
            ...generateMergeBlocks(filters),
            ...filters.map(getPipelineFiltersFromFilter),
            { select: { visitorId: 'visitorId' } }
        ]
    };

    return segment;
}

export function parseRulesAsFilters (rules, overrideAppId) {
    // mapRulesToFilters needs to rely on the object reference to keep track of index
    // this MUST match the index in `merge` for `lastState<index>` which is calculated
    // indepedently in `getMergeFromFilter`
    const options = { filterIndex: 0, overrideAppId };

    return rules.map((orRules) => {
        let filter = {};

        if (orRules.length > 1) {
            filter.or = orRules.map(mapRulesToFilters(options));
        } else {
            [filter] = orRules.map(mapRulesToFilters(options));
        }

        return filter;
    });
}

export function mapRulesToFilters (options) {
    return ({ operator, schema, value, ...rule }) => {
        const appId = options.overrideAppId || get(rule, 'app.id', null);

        if (schema.schema === 'guide') {
            const { time, guide } = rule;

            return {
                ...schema,
                type: 'guide',
                guideId: get(guide, 'id', null), // rule may reference deleted guide
                operator: get(operator, 'value', null),
                time: time || 'ever', // future proofing for when we add `time` to guideSeenEver
                selectedApp: { id: appId },
                filterIndex: options.filterIndex++
            };
        }

        if (schema.schema === 'page') {
            const { time, page } = rule;

            return {
                ...schema,
                type: 'page',
                pageId: get(page, 'id', null), // rule may reference deleted page
                operator: get(operator, 'value', null),
                time: time || 'ever',
                value,
                selectedApp: { id: appId },
                filterIndex: options.filterIndex++
            };
        }

        if (schema.schema === 'feature') {
            const { time, feature } = rule;

            return {
                ...schema,
                type: 'feature',
                featureId: get(feature, 'id', null), // rule may reference deleted feature
                operator: get(operator, 'value', null),
                time: time || 'ever',
                value,
                selectedApp: { id: appId },
                filterIndex: options.filterIndex++
            };
        }

        if (schema.schema === 'poll') {
            const { time, guide, poll } = rule;

            return {
                ...schema,
                type: 'poll',
                guideId: get(guide, 'id', null), // rule may reference deleted guide
                pollId: get(poll, 'id', null),
                operator: get(operator, 'value', null),
                time: time || 'ever', // future proofing for when we add `time` to pollSeenEver
                selectedApp: { id: appId },
                filterIndex: options.filterIndex++,
                value: value != null ? formatValueFromSchema(value, 'string') : value
            };
        }

        if (schema.schema === 'application') {
            const { time } = rule;

            return {
                ...schema,
                operator: get(operator, 'value', null),
                time: time || 'ever',
                value,
                selectedApp: { id: appId },
                filterIndex: options.filterIndex++,
                field: `visitor.auto_${appId}.lastVisit`
            };
        }

        // CSV
        if (['listContains', '!listContains'].includes(get(operator, 'value'))) {
            return {
                ...schema,
                type: 'tag',
                tagId: get(rule, 'tagId', null),
                operator: get(operator, 'value', null),
                selectedApp: { id: appId },
                filterIndex: options.filterIndex++,
                value: value != null ? formatValueFromSchema(value, 'string') : value
            };
        }

        return {
            ...schema,
            // when we remove single app aka usesMultiApp becomes the default, remove selectedApp
            selectedApp: { id: appId },
            value: formatValueFromSchema(value, schema.schema, operator.value),
            operator: get(operator, 'value', null)
        };
    };
}

export function formatValueFromSchema (value, schema, operator = null) {
    if (operatorRequiresUnitCountFormat[operator]) {
        schema = 'unitCount'; // eslint-disable-line no-param-reassign
    }

    let formattedValue = null;

    switch (schema) {
        case 'time': {
            formattedValue = formatTimeValue(value);
            break;
        }
        case 'string':
        case 'list': {
            formattedValue = `"${value}"`;
            break;
        }
        case 'unitCount': {
            const [count, unit] = value;
            formattedValue = [Number(count) * -1, formatValueFromSchema(unit, 'string')];
            break;
        }
        case 'integer': {
            formattedValue = parseInt(value, 10);
            break;
        }
        case 'float': {
            formattedValue = parseFloat(value);
            break;
        }
        case 'boolean': {
            formattedValue = value;
            break;
        }
        default:
            throw new Error(`Invalid schema ${schema} for value ${value}`);
    }

    return formattedValue;
}

export function formatTimeValue (value) {
    let formattedValue;

    if (value instanceof Date) {
        formattedValue = value.getTime();
    } else if (isPlainObject(value)) {
        formattedValue = Object.entries(value).reduce((acc, [key, val]) => {
            acc[key] = formatTimeValue(val);

            return acc;
        }, {});
    } else {
        formattedValue = new Date(value).getTime();
    }

    return formattedValue;
}

export function generateMergeBlocks (filters) {
    return filters
        .map(getMergeFromFilter)
        .reduce((list, merge) => {
            if (Array.isArray(merge)) {
                list.push(...merge);

                return list;
            }

            list.push(merge);

            return list;
        }, [])
        .filter(Boolean);
}

export function getMergeFromFilter ({ or, type, guideId, pollId, pageId, featureId, tagId, filterIndex }) {
    if (or) {
        return or.map(getMergeFromFilter);
    }

    let merge;

    switch (type) {
        case 'guide': {
            const lastStateId = `lastState${filterIndex}`;

            merge = {
                fields: ['guideId', 'visitorId'],
                mappings: {
                    [lastStateId]: lastStateId
                },
                pipeline: [
                    { source: { guidesSeenEver: { guideId } } },
                    { identified: 'visitorId' },
                    { group: { group: ['visitorId'], fields: [{ lastState: { first: 'lastState' } }] } },
                    { select: { visitorId: 'visitorId', [lastStateId]: 'lastState' } }
                ]
            };
            break;
        }
        case 'page': {
            const itemSeenKey = `page${filterIndex}_itemSeen`;

            merge = {
                fields: ['visitorId'],
                pipeline: [
                    { source: { visitors: null } },
                    {
                        itemSeen: {
                            [itemSeenKey]: { pageId }
                        }
                    },
                    { identified: 'visitorId' },
                    { select: { visitorId: 'visitorId', [itemSeenKey]: itemSeenKey } }
                ]
            };
            break;
        }
        case 'feature': {
            const itemSeenKey = `feature${filterIndex}_itemSeen`;

            merge = {
                fields: ['visitorId'],
                pipeline: [
                    { source: { visitors: null } },
                    {
                        itemSeen: {
                            [itemSeenKey]: { featureId }
                        }
                    },
                    { identified: 'visitorId' },
                    { select: { visitorId: 'visitorId', [itemSeenKey]: itemSeenKey } }
                ]
            };
            break;
        }
        case 'poll': {
            const pollResponseId = `pollResponse${filterIndex}`;
            const pollTimeId = `pollTime${filterIndex}`;

            merge = {
                fields: ['visitorId'],
                mappings: {
                    [pollResponseId]: pollResponseId,
                    [pollTimeId]: pollTimeId
                },
                pipeline: [
                    { source: { pollsSeenEver: { guideId, pollId } } },
                    { identified: 'visitorId' },
                    { select: { visitorId: 'visitorId', [pollResponseId]: 'response', [pollTimeId]: 'time' } }
                ]
            };
            break;
        }
        case 'tag': {
            merge = {
                fields: ['visitorId'],
                pipeline: [
                    { source: { visitorTags: { visitorTagId: tagId } } },
                    { identified: 'visitorId' },
                    { select: { visitorId: 'visitorId', [`tag${filterIndex}`]: 'true' } }
                ]
            };
            break;
        }
        default:
            return null;
    }

    return { merge };
}

export function getPipelineFiltersFromFilter ({ operator, type, value, or, ...rawFilter }) {
    if (or) {
        return parseOrFilterForPipeline(or);
    }

    if (type === 'guide') {
        return parseGuideFilterPipeline({ operator, ...rawFilter });
    }

    if (type === 'page') {
        return parsePageFilterPipeline({ operator, value, ...rawFilter });
    }

    if (type === 'feature') {
        return parseFeatureFilterPipeline({ operator, value, ...rawFilter });
    }

    if (type === 'poll') {
        return parsePollFilterPipeline({ operator, value, ...rawFilter });
    }

    if (type === 'tag') {
        return parseListContainsFilterPipeline({ operator, ...rawFilter });
    }

    if (type === 'application') {
        return parseApplicationUsageFilterPipeline({ operator, value, ...rawFilter });
    }

    let filter = '';

    switch (operator) {
        case 'contains': {
            filter = { filter: `contains(${type},${value})` };
            break;
        }
        case '!contains': {
            filter = { filter: `!contains(${type},${value})` };
            break;
        }
        case 'between': {
            const { start, end } = value;
            filter = { filter: `${type}>=${start}&&${type}<=${end}` };
            break;
        }
        case 'withinLast': {
            const [count, unit] = value;
            filter = { filter: `${type}<=now()&&${type}>=dateAdd(now(),${count},${unit})` };
            break;
        }
        case '!withinLast': {
            const [count, unit] = value;
            filter = { filter: `${type}>=now()||${type}<=dateAdd(now(),${count},${unit})` };
            break;
        }
        default:
            filter = { filter: `${type}${operator}${value}` };
    }

    return filter;
}

export function parseListContainsFilterPipeline ({ operator, filterIndex }) {
    let filter;

    switch (operator) {
        case 'listContains': {
            filter = { filter: `!isNil(tag${filterIndex})` };
            break;
        }
        case '!listContains': {
            filter = { filter: `isNil(tag${filterIndex})` };
            break;
        }
    }

    return filter;
}

export function parseGuideFilterPipeline ({ operator, filterIndex }) {
    let filter;

    switch (operator) {
        case 'viewed':
            filter = { filter: `!isNull(lastState${filterIndex})` };
            break;
        case 'notviewed':
            filter = { filter: `isNull(lastState${filterIndex})` };
            break;
    }

    return filter;
}

export function parsePageFilterPipeline ({ operator, time, value, filterIndex }) {
    let filterString;

    switch (operator) {
        case 'viewed':
            if (time === 'withinlast') {
                const [count, unit] = value;
                filterString = buildPageSeenWithinLastFilterString(filterIndex, count, unit, '>=');

                break;
            }

            if (time === 'since') {
                filterString = buildPageSeenSinceFilterString(filterIndex, value, '>=');

                break;
            }

            filterString = buildPageSeenEverFilterString(filterIndex, '>');

            break;
        case 'notviewed':
            if (time === 'withinlast') {
                const [count, unit] = value;
                filterString = buildPageSeenWithinLastFilterString(filterIndex, count, unit, '<');

                break;
            }

            if (time === 'since') {
                filterString = buildPageSeenSinceFilterString(filterIndex, value, '<');

                break;
            }

            filterString = buildPageSeenEverFilterString(filterIndex, '<=');

            break;
    }

    return { filter: filterString };
}

export function parseFeatureFilterPipeline ({ operator, time, value, filterIndex }) {
    let filterString;

    switch (operator) {
        case 'clicked':
            if (time === 'withinlast') {
                const [count, unit] = value;
                filterString = buildFeatureClickedWithinLastFilterString(filterIndex, count, unit, '>=');

                break;
            }

            if (time === 'since') {
                filterString = buildFeatureClickedSinceFilterString(filterIndex, value, '>=');

                break;
            }

            if (time === 'atleast') {
                filterString = buildFeatureClickedAtLeastOrMostFilterString(filterIndex, value, '>=');

                break;
            }

            if (time === 'atmost') {
                filterString = buildFeatureClickedAtLeastOrMostFilterString(filterIndex, value, '<=');

                break;
            }

            filterString = buildFeatureClickedEverFilterString(filterIndex, '>');

            break;
        case 'notclicked':
            if (time === 'withinlast') {
                const [count, unit] = value;
                filterString = buildFeatureClickedWithinLastFilterString(filterIndex, count, unit, '<');

                break;
            }

            if (time === 'since') {
                filterString = buildFeatureClickedSinceFilterString(filterIndex, value, '<');

                break;
            }

            filterString = buildFeatureClickedEverFilterString(filterIndex, '<=');

            break;
    }

    return { filter: filterString };
}

export function buildPageSeenEverFilterString (filterIndex, comparisonSymbol) {
    return `page${filterIndex}_itemSeen.count${comparisonSymbol}0`;
}

export function buildPageSeenWithinLastFilterString (filterIndex, countValue, timeUnit, comparisonSymbol) {
    return `page${filterIndex}_itemSeen.lastUsed${comparisonSymbol}dateAdd(now(),${-1 * countValue},"${timeUnit}")`;
}

export function buildPageSeenSinceFilterString (filterIndex, timeValue, comparisonSymbol) {
    return `page${filterIndex}_itemSeen.lastUsed${comparisonSymbol}${formatTimeValue(timeValue)}`;
}

export function buildFeatureClickedEverFilterString (filterIndex, comparisonSymbol) {
    return `feature${filterIndex}_itemSeen.count${comparisonSymbol}0`;
}

export function buildFeatureClickedWithinLastFilterString (filterIndex, countValue, timeUnit, comparisonSymbol) {
    return `feature${filterIndex}_itemSeen.lastUsed${comparisonSymbol}dateAdd(now(),${-1 * countValue},"${timeUnit}")`;
}

export function buildFeatureClickedSinceFilterString (filterIndex, timeValue, comparisonSymbol) {
    return `feature${filterIndex}_itemSeen.lastUsed${comparisonSymbol}${formatTimeValue(timeValue)}`;
}

export function buildFeatureClickedAtLeastOrMostFilterString (filterIndex, countValue, comparisonSymbol) {
    return `feature${filterIndex}_itemSeen.count${comparisonSymbol}${countValue}`;
}

export function parsePollFilterPipeline ({ operator, filterIndex, value }) {
    let filter;

    switch (operator) {
        case 'responded':
            filter = { filter: `!isNull(pollResponse${filterIndex})` };
            break;
        case 'notresponded':
            filter = { filter: `isNull(pollResponse${filterIndex})` };
            break;
        case '!=':
            filter = { filter: `!isNull(pollResponse${filterIndex})&&pollResponse${filterIndex}${operator}${value}` };
            break;
        default:
            filter = { filter: `pollResponse${filterIndex}${operator}${value}` };
    }

    return filter;
}

export function parseOrFilterForPipeline (filters) {
    return filters.map(getPipelineFiltersFromFilter).reduce(
        (acc, rule) => {
            if (!acc.filter) {
                acc.filter = rule.filter;
            } else {
                acc.filter += `||${rule.filter}`;
            }

            return acc;
        },
        { filter: '' }
    );
}

export function parseApplicationUsageFilterPipeline ({ operator, selectedApp }) {
    let filterString;
    const appId = get(selectedApp, 'id', null);
    switch (operator) {
        case 'used':
            return { filter: `!isNil(metadata.auto_${appId}.lastvisit)` };
        case 'notused':
            return { filter: `isNil(metadata.auto_${appId}.lastvisit)` };
    }

    return { filter: filterString };
}

export function isSegmentAttachedToGuide (segment, guideMap) {
    if (!segment) {
        return false;
    }

    return Object.values(guideMap).some((guide) => {
        const segmentId = get(guide, 'audienceUiHint.filters[0].segmentId');

        return segmentId && segment.id === segmentId;
    });
}

export function isValidSegmentInContext (segment, context = {}) {
    const { guideMap = {}, assigningToGuide = false } = context;

    if (isSegmentGuideEligible(segment)) return true;

    // segment is not guide eligible, but is being assigned to a guide
    if (assigningToGuide) return false;

    const alreadyAttachedToGuide = isSegmentAttachedToGuide(segment, guideMap);

    return !alreadyAttachedToGuide;
}

// segment -> rules

export function getRulesFromSegment (segment) {
    const { filters } = segment.definition;

    const rules = filters.reduce((rules, filter) => {
        if (filter.or) {
            rules.push(filter.or.map(parseOrRuleFromFilter));
        } else {
            rules.push([parseOrRuleFromFilter(filter)]);
        }

        return rules;
    }, []);

    return rules;
}

export function parseOrRuleFromFilter ({ operator, value, ...schema }) {
    if (schema.schema === 'guide') {
        const { time, guideId } = schema;

        return {
            schema,
            time,
            guideId,
            operator: findSchemaOperator(operator, schema.schema)
        };
    }

    if (schema.schema === 'poll') {
        const { time, guideId, pollId } = schema;

        return {
            schema,
            time,
            guideId,
            pollId,
            value: parseValueFromDefinition(value, 'string'),
            operator: findSchemaOperator(operator, schema.schema)
        };
    }

    if (schema.schema === 'page') {
        const { time, pageId } = schema;

        return {
            schema,
            time,
            value: value ? value.slice() : value,
            pageId,
            operator: findSchemaOperator(operator, schema.schema)
        };
    }

    if (schema.schema === 'feature') {
        const { time, featureId } = schema;

        return {
            schema,
            time,
            value: get(value, 'length') ? value.slice() : value,
            featureId,
            operator: findSchemaOperator(operator, schema.schema)
        };
    }

    if (schema.schema === 'application') {
        const { time } = schema;

        return {
            schema,
            time,
            value: get(value, 'length') ? value.slice() : value,
            operator: findSchemaOperator(operator, schema.schema)
        };
    }

    if (schema.type === 'tag') {
        const { tagId } = schema;

        return {
            schema,
            tagId,
            value: parseValueFromDefinition(value, schema.schema, operator),
            operator: findSchemaOperator(operator, schema.schema)
        };
    }

    // when we remove single app aka usesMultiApp becomes the default, comment this back in
    // Before you could view multiple apps in adopt, `selectedApp` was added to all rules,
    // even rules that are not technically app-specific
    // for cases where rules are actually subscription-wide, such as visitor comparisons,
    // remove selectedApp to avoid confusion
    // delete schema.selectedApp;

    return {
        schema,
        value: parseValueFromDefinition(value, schema.schema, operator),
        operator: findSchemaOperator(operator, schema.schema)
    };
}

export function parseValueFromDefinition (rawValue, schema, operator) {
    if (operatorRequiresUnitCountFormat[operator]) {
        schema = 'unitCount'; // eslint-disable-line no-param-reassign
    }

    let value;

    switch (schema) {
        case 'string':
        case 'list': {
            value = (rawValue || '').replace(/"/g, '');
            break;
        }
        case 'time': {
            if (isPlainObject(rawValue)) {
                value = Object.entries(rawValue).reduce((acc, [key, val]) => {
                    acc[key] = new Date(val);

                    return acc;
                }, {});
            } else {
                value = new Date(rawValue);
            }
            break;
        }
        case 'unitCount': {
            const [count, unit] = rawValue;
            value = [count * -1, parseValueFromDefinition(unit, 'string')];
            break;
        }
        case 'integer':
        case 'float':
        default:
            value = rawValue;
    }

    return value;
}

export function findSchemaOperator (operator, schema) {
    const list = SEGMENT_OPERATORS[schema];

    return list.find((opt) => opt.value === operator);
}

// segment -> summary

export function parseSegmentSummary (segment, guideMap = {}, pageMap = {}, featureMap = {}) {
    if (!segment || segment.id === 'everyone' || segment.id == null) {
        return [
            [
                {
                    text: 'Everyone in your app will see this'
                }
            ]
        ];
    }

    const { filters } = segment.definition;

    return filters.reduce((summary, filter) => {
        let rule;
        if (filter.or) {
            rule = filter.or.map(getFilterSummary(guideMap, pageMap, featureMap));
        } else {
            rule = [getFilterSummary(guideMap, pageMap, featureMap)(filter)];
        }

        return [...summary, rule];
    }, []);
}

export function getInlineSegmentSummary (segment, guideMap, pageMap, featureMap) {
    if (!segment || segment.id === 'everyone' || segment.id == null) {
        return 'Everyone';
    }

    const summary = parseSegmentSummary(segment, guideMap, pageMap, featureMap);

    return summary
        .map((rule) => {
            if (rule.length > 1) {
                return rule.map((orRule) => orRule.text).join(' OR ');
            }

            return rule[0].text;
        })
        .join(' AND ');
}

function getFilterValue (filter) {
    // default segments use a different format?
    if (filter.value == null && filter.granularity != null && filter.count != null) {
        return [-filter.count, filter.granularity];
    }

    return filter.value;
}

export function getFilterSummary (guideMap = {}, pageMap = {}, featureMap = {}) {
    return (filter) => {
        const { name, schema, operator, type } = filter;
        const { label } = findSchemaOperator(operator, schema);
        const guide = guideMap[filter.guideId];
        const page = pageMap[filter.pageId];
        const feature = featureMap[filter.featureId];
        const guideNotFound = /guide|poll/.test(schema) && !guide;
        const pageNotFound = schema === 'page' && !page;
        const featureNotFound = schema === 'feature' && !feature;

        if (guideNotFound || pageNotFound || featureNotFound) {
            const entityId = filter[`${schema}Id`];

            return {
                schema,
                text: getMissingEntityText(schema, entityId)
            };
        }

        if (schema === 'guide') {
            return {
                schema,
                text: `${capitalize(type)} "${guide.name}" ${label} ${filter.time}`.trim()
            };
        }

        if (schema === 'page') {
            const pageSentenceInfo = `${capitalize(type)} "${page.displayName}"`;
            const seenNotSeen = label;

            let operatorDescription = filter.time.trim();

            if (filter.time === 'withinlast') {
                operatorDescription = `within last ${filter.value[0]} ${filter.value[1]}`;
            }

            if (filter.time === 'since') {
                operatorDescription = `since ${formatTimeFilterSummary(filter.value)}`;
            }

            return {
                schema,
                text: `${pageSentenceInfo} ${seenNotSeen} ${operatorDescription}`
            };
        }

        if (schema === 'feature') {
            const featureSentenceInfo = `${capitalize(type)} "${feature.displayName}"`;
            const seenNotSeen = label;

            let operatorDescription = filter.time.trim();

            if (filter.time === 'withinlast') {
                operatorDescription = `within last ${filter.value[0]} ${filter.value[1]}`;
            }

            if (filter.time === 'since') {
                operatorDescription = `since ${formatTimeFilterSummary(filter.value)}`;
            }

            if (filter.time === 'atleast') {
                operatorDescription = `at least ${filter.value} ${pluralize('time', filter.value)}`;
            }

            if (filter.time === 'atmost') {
                operatorDescription = `at most ${filter.value} ${pluralize('time', filter.value)}`;
            }

            return {
                schema,
                text: `${featureSentenceInfo} ${seenNotSeen} ${operatorDescription}`
            };
        }

        if (schema === 'poll') {
            const poll = find(guide.polls, { id: filter.pollId });

            if (!poll) {
                return {
                    schema,
                    text: getMissingEntityText(schema, filter.pollId)
                };
            }

            const question = get(poll, 'question', 'the poll');
            let responseVal = '';
            if (filter.value != null) {
                responseVal = parseValueFromDefinition(filter.value, 'string');
                responseVal = ` ${poll.idResponses ? poll.idResponses[responseVal] : responseVal}`;
            }

            return {
                schema,
                text: `Response to "${question}" ${label}${responseVal} ${filter.time}`.trim()
            };
        }

        if (['listContains', '!listContains'].includes(operator)) {
            const filename = parseValueFromDefinition(filter.value, schema, operator);
            const operatorDescription = {
                listContains: 'contained within list',
                '!listContains': 'not contained within list'
            }[operator];

            return {
                schema,
                text: `${name} ${operatorDescription} '${filename}'`.trim()
            };
        }

        const value = getFilterValue(filter);
        let val = parseValueFromDefinition(value, schema, operator);

        if (schema === 'time') {
            val = formatTimeFilterSummary(val);
        }

        if (schema === 'boolean') {
            val = '';
        }

        return {
            schema,
            text: `${name} ${label} ${val}`.trim()
        };
    };
}

export function formatTimeFilterSummary (valueFromTimeSchema) {
    let value;

    if (Array.isArray(valueFromTimeSchema)) {
        value = valueFromTimeSchema.join(' ');
    } else if (isPlainObject(valueFromTimeSchema)) {
        value = [
            moment(valueFromTimeSchema.start).format(DATE_FORMAT.short),
            moment(valueFromTimeSchema.end).format(DATE_FORMAT.short)
        ].join(' and ');
    } else {
        value = moment(valueFromTimeSchema).format(DATE_FORMAT.short);
    }

    return value;
}

// general utils

export function getMissingEntityText (type, id) {
    return `Error: ${type} ${id} not found`;
}

export function filterBlacklistedSegments (segmentList, blacklist) {
    const blacklistMap = keyBy(blacklist);

    return segmentList.filter((segment) => {
        const filters = get(segment, 'definition.filters', null);

        // everyone
        if (!filters) {
            return true;
        }

        return !filters.some((filter) => isBlacklistedFilter(filter, blacklistMap));
    });
}

export function isBlacklistedFilter (filter, blacklistMap) {
    if (filter.or) {
        return filter.or.some((f) => isBlacklistedFilter(f, blacklistMap)); // eslint-disable-line id-length
    }

    return !!blacklistMap[filter.type];
}

export function isSegmentBlacklisted (segment, blacklist) {
    return !filterBlacklistedSegments([segment], blacklist).length;
}

export function isDefaultSegment (segment) {
    return ['everyone', 'everyoneExcludingAnonymous', 'anonymousVisitorsOnly', 'blacklistOnly'].includes(segment);
}

export function segmentUsesDeletedGuide (segment, guideMap) {
    const filters = get(segment, 'definition.filters');

    if (!filters) {
        return false;
    }

    return filters.some((filter) => filterUsesDeletedGuide(filter, guideMap));
}

export function filterUsesDeletedGuide (filter, guideMap) {
    if (filter.or) {
        return filter.or.some((f) => filterUsesDeletedGuide(f, guideMap)); // eslint-disable-line id-length
    }

    if (filter.type !== 'guide') {
        return false;
    }

    return !guideMap[filter.guideId];
}

export function isSegmentGuideEligible (segment) {
    const filters = get(segment, 'definition.filters');

    if (!filters) {
        return true;
    }

    return !filters.some(filterContainsLastVisitType);
}

export function filterContainsLastVisitType (filter) {
    if (filter.or) {
        return filter.or.some(filterContainsLastVisitType);
    }

    return filter.type === 'metadata.auto.lastvisit';
}

// segment builder ui utilities

export function getAbsoluteDateRangeConfig (rule) {
    return {
        component: 'absolute-date-range',
        props: {
            value: {
                start: rule.value.start,
                end: rule.value.end
            }
        }
    };
}

export function getRelativeDateRangeConfig (rule) {
    return {
        component: 'relative-date-range',
        props: {
            value: {
                count: rule.value[0],
                granularity: rule.value[1]
            }
        }
    };
}

export function getSingleDateConfig (rule, field = 'value') {
    return {
        component: 'single-date',
        props: {
            field,
            value: rule[field]
        }
    };
}

export function getSegmentCsvConfig (rule) {
    return {
        component: 'segment-csv-upload',
        props: {
            value: {
                tagId: rule.tagId,
                filename: rule.value
            }
        }
    };
}

export function getNumberConfig (rule, field = 'value') {
    return {
        component: 'number-input',
        props: {
            field,
            value: rule[field],
            min: 0
        }
    };
}

export function getStringConfig (rule, field = 'value') {
    return {
        component: 'string-input',
        props: {
            field,
            value: rule[field]
        }
    };
}
