<template>
    <div class="terms-of-use">
        By signing into the service, you agree to the
        <a
            :href="termsOfService"
            target="_blank">Terms of Service</a>
        <span>&nbsp;and&nbsp;</span>
        <a
            :href="privacyPolicy"
            target="_blank">Privacy Policy</a>
    </div>
</template>

<script>
export default {
    name: 'SetPasswordFormModal',
    data () {
        return {
            termsOfService: 'https://www.pendo.io/terms-of-service/',
            privacyPolicy: 'https://www.pendo.io/privacypolicy/'
        };
    }
};
</script>

<style lang="scss">
.terms-of-use {
    z-index: 1000;
    font-size: 0.75rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    height: 32px;
    color: $white;
}
</style>
