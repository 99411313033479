<template>
    <div>
        <page-create-alert
            v-if="warnings.createPageOnTargetApp"
            data-cy="create-page-copy-modal-warning"
            class="copy-modal-warning"
            :target-app="targetApp"
            :page-ids="warnings.createPageOnTargetApp" />
        <div
            class="modal-description"
            data-cy="copy-modal-description">
            <p>
                Clicking "Copy Guide" below will copy your chosen guide into the following account and application.
                Please review the following details to ensure they are correct.
            </p>
            <p>
                <strong>Guide Name: </strong> {{ guide.name }}
                <br>
                <strong>Account: </strong> {{ targetSub.displayName }}
                <br>
                <strong>Application: </strong> {{ targetApp.displayName }}
            </p>
        </div>
    </div>
</template>

<script>
import PageCreateAlert from './warnings/PageCreateAlert';

export default {
    name: 'ConfirmCopy',
    components: {
        PageCreateAlert
    },
    props: {
        guide: {
            type: Object,
            default: () => ({})
        },
        targetSub: {
            type: Object,
            default: () => ({})
        },
        targetApp: {
            type: Object,
            default: () => ({})
        },
        warnings: {
            type: Object,
            default: () => ({})
        }
    }
};
</script>
<style scoped lang="scss">
.copy-modal-warning {
    margin-bottom: 16px;
}

.modal-description p {
    margin-bottom: 14px;
}

.modal-description p:first-child {
    margin-top: 0;
}
</style>
