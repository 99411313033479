import Vue from 'vue';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import * as usersUtil from '@/utils/users';

export function getInitialState () {
    return {
        map: {},
        errors: {},
        isFetchingUserList: false,
        hasFetchUserListError: false,
        isFetchingUser: false,
        fetchUserError: null,
        isInvitingNewUser: false,
        inviteNewUserError: null,
        isUpdatingUser: false,
        updateUserError: null,
        isRemovingUser: false,
        removeUserError: false,
        userOverlayVisible: false
    };
}

export const state = getInitialState();

export const mutations = {
    setMap (state, { map }) {
        state.map = map;
    },
    setUpdate (state, { user }) {
        Vue.set(state.map, user.id, user);
    },
    setRemove (state, { id }) {
        Vue.delete(state.map, id);
    },
    setIsFetchingUserList (state, { isFetchingUserList }) {
        state.isFetchingUserList = isFetchingUserList;
    },
    setFetchUserListError (state, { hasFetchUserListError }) {
        state.hasFetchUserListError = hasFetchUserListError;
    },
    setIsFetchingUser (state, { isFetchingUser }) {
        state.isFetchingUser = isFetchingUser;
    },
    setFetchUserError (state, { fetchUserError }) {
        state.fetchUserError = fetchUserError;
    },
    setIsInvitingNewUser (state, { isInvitingNewUser }) {
        state.isInvitingNewUser = isInvitingNewUser;
    },
    setInviteNewUserError (state, { inviteNewUserError }) {
        state.inviteNewUserError = inviteNewUserError;
    },
    setIsUpdatingUser (state, { isUpdatingUser }) {
        state.isUpdatingUser = isUpdatingUser;
    },
    setUpdateUserError (state, { updateUserError }) {
        state.updateUserError = updateUserError;
    },
    setIsRemovingUser (state, { isRemovingUser }) {
        state.isRemovingUser = isRemovingUser;
    },
    setRemoveUserError (state, { removeUserError }) {
        state.removeUserError = removeUserError;
    },
    setUserOverlayVisible (state, { isVisible }) {
        state.userOverlayVisible = isVisible;
    },
    reset (state) {
        Object.assign(state, getInitialState());
    }
};

export const actions = {
    async fetchUserList ({ commit, rootGetters, state }, { noCache = false } = {}) {
        if (!rootGetters['auth/isAdmin']) {
            return;
        }

        if (!isEmpty(state.map) && !noCache) {
            return;
        }

        commit('setIsFetchingUserList', { isFetchingUserList: true });

        try {
            const userList = await usersUtil._fetchUserList();
            commit('setMap', { map: keyBy(userList, 'id') });
        } catch (err) {
            commit('setFetchUserListError', { hasFetchUserListError: true });
        }

        commit('setIsFetchingUserList', { isFetchingUserList: false });
    },
    async fetchUser ({ commit, state, rootState, rootGetters }, { noCache = false, id } = {}) {
        if (!rootGetters['auth/isAdmin'] && rootState.auth.user.id !== id) {
            return;
        }
        if (state.map[id] && !noCache) {
            return;
        }
        commit('setFetchUserError', { fetchUserError: null });
        commit('setIsFetchingUser', { isFetchingUser: true });

        try {
            const user = await usersUtil._fetchUser(id);
            commit('setUpdate', { user });
        } catch (err) {
            commit('setFetchUserError', {
                fetchUserError: 'There was an error loading this user. Try refreshing the page.'
            });
        }
        commit('setIsFetchingUser', { isFetchingUser: false });
    },
    resetUserList ({ commit }) {
        commit('setMap', { map: {} });
    },
    async inviteNewUser ({ commit, rootGetters }, { user, email }) {
        // backend does most of the heavy lifting for this one
        // checks to see if user is an active training user, otherwise creates them
        // checks to see if user has been previously created, but deleted, and revives them as a new user if needed
        // if already a user, but not part of this Partner/EndUserOrg, adds user to Partner/EndUSerOrg combo
        if (rootGetters['subscriptions/activeUsesV2Adopt']) {
            commit('setInviteNewUserError', { inviteNewUserError: null });
            commit('setIsInvitingNewUser', { isInvitingNewUser: true });
            try {
                const newUser = await usersUtil._inviteNewUserV2(user);
                commit('setUpdate', { user: newUser });
            } catch (err) {
                const inviteNewUserError =
                    get(err, 'response.status') === 429
                        ? `${
                              user.email
                          } has already been invited. They will appear in the user list when they accept the invite.`
                        : `Your request failed. Unable to invite ${user.email} at this time.\n${err}`;
                commit('setInviteNewUserError', { inviteNewUserError });
            }
            commit('setIsInvitingNewUser', { isInvitingNewUser: false });
        } else {
            commit('setInviteNewUserError', { inviteNewUserError: null });
            commit('setIsInvitingNewUser', { isInvitingNewUser: true });

            try {
                const newUser = await usersUtil._inviteNewUser({ email });
                commit('setUpdate', { user: newUser });
            } catch ({ response }) {
                if (response.status === 400) {
                    commit('setInviteNewUserError', {
                        inviteNewUserError: 'It looks like that user is already a member of this team.'
                    });
                } else {
                    commit('setInviteNewUserError', {
                        inviteNewUserError: 'There was an error inviting the user. Please try again.'
                    });
                }
            }

            commit('setIsInvitingNewUser', { isInvitingNewUser: false });
        }
    },
    async update ({ commit }, { user }) {
        commit('setIsUpdatingUser', { isUpdatingUser: true });
        commit('setUpdateUserError', { updateUserError: null });
        try {
            const updatedUser = await usersUtil._updateUser(user);
            commit('setUpdate', { user: updatedUser });
        } catch (err) {
            commit('setUpdateUserError', {
                updateUserError: `Your request failed. Unable to update ${user.username} at this time.\n${err}`
            });
        }
        commit('setIsUpdatingUser', { isUpdatingUser: false });
    },
    async updateFlags ({ commit, dispatch }, { user, flags }) {
        let flagUpdateSuccess;
        commit('setIsUpdatingUser', { isUpdatingUser: true });
        commit('setUpdateUserError', { updateUserError: null });
        try {
            await usersUtil._updateFlags({ user, flags });
            flagUpdateSuccess = true;
            await dispatch('fetchUser', { noCache: true, id: user.id });
        } catch (err) {
            if (!flagUpdateSuccess) {
                commit('setUpdateUserError', {
                    updateUserError: `Your request failed. Unable to update ${user.username} at this time.\n${err}`
                });
            }
        }
        commit('setIsUpdatingUser', { isUpdatingUser: false });
    },
    async removeUser ({ commit, rootGetters }, { id }) {
        // this does NOT actually delete a user, simply removes them from the current partner/endUserOrg combo
        commit('setIsRemovingUser', { isRemovingUser: true });

        try {
            await (rootGetters['subscriptions/activeUsesV2Adopt']
                ? usersUtil._removeV2SubUser(id)
                : usersUtil._removeUser(id));

            commit('setRemove', { id });
            commit('setRemoveUserError', { removeUserError: false });
        } catch (err) {
            commit('setRemoveUserError', { removeUserError: true });
        }
        commit('setIsRemovingUser', { isRemovingUser: false });
    },
    async resendInvite ({ commit }, { user }) {
        // update a specific user to show inviting status on a per row basis
        commit('setUpdate', { user: { ...user, isResendingInvite: true } });

        try {
            await usersUtil._resendInvite(user.id);
            commit('setUpdate', { user: { ...user, isResendingInvite: false, resendInviteSuccess: true } });
        } catch ({ response }) {
            // 400 with specific error message means that the user somehow logged in between the last time we
            // fetched the user list and the time the admin re-invited them. In that case, show in the UI that
            // the call was successful since we can guarantee the user will show as active next time we fetch the list
            if (response.status === 400 && response.message === 'This user has already logged in.') {
                commit('setUpdate', { user: { ...user, isResendingInvite: false, resendInviteSuccess: true } });
            } else {
                commit('setUpdate', { user: { ...user, isResendingInvite: false, resendInviteError: true } });
            }
        }
    }
};

export const getters = {
    listUsers (state) {
        return Object.values(state.map);
    },
    userByUsername: (state, getters) => (username) => {
        return getters.listUsers.find((user) => user.username.toLowerCase().trim() === username.toLowerCase().trim());
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
