import http from '@/utils/http';

const latencyThreshold = 100;
let totalRequests = 0;
let failedRequests = [];
let completedRequests = 0;

function setComplete () {
    totalRequests = 0;
    completedRequests = 0;
    failedRequests = [];
}

export async function copyGuideV1 ({ id, removeSegment, toAccount }) {
    totalRequests++;
    let newGuide;
    try {
        newGuide = await http
            .post(`/api/s/_SID_/guide/${id}/clone`, { toAccount, removeSegment })
            .then((res) => res.data);
    } catch (err) {
        // treat a failed request as success to prevent the Promise.all from rejecting
        // push the ID to an array that the UI will user to notify the user of success/fail
        failedRequests.push(id);
    }

    return newGuide;
}

function onRequestComplete (onProgress) {
    setTimeout(() => {
        ++completedRequests;
        if (onProgress) {
            onProgress({ total: totalRequests, completed: completedRequests, failed: failedRequests });
        }

        if (completedRequests >= totalRequests) {
            setComplete();
        }
    }, latencyThreshold + 50);
}

export async function copyGuides ({ guides, targetSubId, targetAppId }, getProgress) {
    const total = guides.length;
    const successes = [];
    const failures = [];

    const copyRequests = guides.map((guide) => {
        const { id, pageMap } = guide;

        return copyGuide({ id, targetSubId, targetAppId, pageMap })
            .then((guide) => {
                successes.push(guide);
            })
            .catch(() => {
                failures.push(guide);
            })
            .finally(() => {
                getProgress({ total, successes: successes.length, failed: failures.length });
            });
    });

    await Promise.all(copyRequests);

    return {
        successes,
        failures
    };
}

export async function copyGuide ({ id, targetSubId, targetAppId, pageMap }) {
    return http
        .post(`/api/s/_SID_/guide/${id}/adopt/copy`, { targetSubId, targetAppId, pageMap })
        .then((res) => res.data);
}

export function copyGuidesV1 ({ guideIds, toAccount, removeSegment }, onProgress) {
    const copyRequests = guideIds.map((id) =>
        copyGuideV1({ id, toAccount, removeSegment }).then((guide) => {
            onRequestComplete(onProgress);

            return guide;
        })
    );

    return Promise.all(copyRequests);
}

export async function loadTargetAppPages ({ targetSubId, targetAppId }) {
    return http.get(`/api/s/${targetSubId}/page?appId=${targetAppId}`);
}
