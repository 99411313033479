<template>
    <pendo-collapse-item
        :name="`app--${displayName}`"
        :class="{ 'is-active': isActive }"
        :collapsible="hasMultipleAccounts"
        :suffix-icon="hasMultipleAccounts"
        @click="!hasMultipleAccounts && updateApp(accounts[0])">
        <template #prefix>
            <div
                class="app--icon"
                :style="{ color, backgroundColor }">
                {{ appIcon }}
            </div>
        </template>
        <template #title>
            <div class="app--details">
                <div
                    v-if="!hideAppName"
                    class="app--details--name">
                    {{ displayName }}
                </div>
                <div
                    v-if="!hasMultipleAccounts"
                    class="app--details--account-name">
                    {{ accounts[0].displayName || accounts[0].id }}
                </div>
            </div>
        </template>
        <ul
            v-if="hasMultipleAccounts"
            class="account-list">
            <li
                v-for="(account, index) in accounts"
                :key="`${account.uid}-${index}`"
                :class="{ 'active-account': isActive && activeAccount.id === account.id }"
                class="account-list--account"
                @click="updateApp(account)">
                <span
                    :title="account.displayName || account.id"
                    class="account-list--account--name">
                    {{ account.displayName || account.id }}
                </span>
            </li>
        </ul>
    </pendo-collapse-item>
</template>

<script>
import head from 'lodash/head';
import { mapGetters } from 'vuex';
import { PendoCollapseItem } from '@pendo/components';
import { getContrastingTextColor } from '@/utils/whitelabel';
import isNil from 'lodash/isNil';
import get from 'lodash/get';

export default {
    name: 'AccountSelectRow',
    components: {
        PendoCollapseItem
    },
    props: {
        displayName: {
            type: String,
            required: true
        },
        apps: {
            type: Array,
            required: true
        },
        hideAppName: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            getAccountList: 'subscriptions/getAccountList',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            activeAccount: 'subscriptions/activeAccount',
            activeApp: 'apps/active'
        }),
        accounts () {
            return this.apps.reduce((accumulator, current) => {
                const currentAccounts = this.getAccountList(current);

                return accumulator.concat(currentAccounts.map((account) => ({ ...account, app: current })));
            }, []);
        },
        appIcon () {
            return head(this.displayName);
        },
        color () {
            return getContrastingTextColor(this.backgroundColor);
        },
        isActive () {
            return !!this.accounts.find((account) => account.app.id === this.activeApp.id);
        },
        backgroundColor () {
            return get(this, 'apps[0].whiteLabelSettings.primaryColor', '#2a2c35');
        },
        hasMultipleAccounts () {
            return this.apps.length > 1;
        }
    },
    methods: {
        updateApp (account) {
            if (isNil(account)) return null;

            const { app, id: accountId } = account;

            if (!accountId) return;

            this.$emit('app-selected', { app, accountId });
        }
    }
};
</script>

<style lang="scss" scoped>
.app--icon {
    font-weight: 700;
    width: 32px;
    height: 32px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(42, 44, 53, 0.3);
    font-size: 1rem;
    text-transform: uppercase;
    color: var(--via-theme--sub-primary-color);
}

.app--details {
    overflow: hidden;
}

.app--details--name {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.1875rem;
    @include ellipsis;
}

.app--details--account-name {
    @include ellipsis;
    font-size: 0.875rem;
    line-height: 1.0875rem;
}

.account-list {
    @include ul-reset();
    padding: 8px 0;

    &--account {
        line-height: 2.45em;
        font-weight: 600;
        padding: 0 16px;
        font-size: 0.875rem;
        @include ellipsis;

        &:hover {
            cursor: pointer;
            background-color: $gray-lighter-6;
        }

        &.active-account {
            background-color: $gray-lighter-6;
        }
    }
}
</style>
