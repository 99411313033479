/*
 * This file will move to shared-services to avoid duplicating code in
 * appengine and via-ui repos. Will move after pendo-io/shared-services#804 merges. APP-50803.
 */

import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import { formatValueForSchema, largestRemainderRound } from '@/utils/formatters';

export function getElementsTableColumns ({ groupByStep, elementActionColumnWidth, csv = false }) {
    const includeGuideStepName = !groupByStep || csv;
    const includePercentClicksOnStep = groupByStep || csv;

    return [
        {
            prop: 'uiElementText',
            label: 'Name',
            minWidth: 260,
            sortable: true
        },
        csv && {
            prop: 'uiElementId',
            label: 'Element Id'
        },
        {
            prop: 'uiElementType',
            label: 'Element Type',
            width: 180,
            sortable: true
        },
        csv && {
            prop: 'guideStepId',
            label: 'Step Id'
        },
        includeGuideStepName && {
            prop: 'guideStepName',
            label: 'Step Name',
            sortable: true
        },
        {
            prop: 'uiElementActions',
            label: 'Action',
            width: elementActionColumnWidth,
            formatter: (row) => row.uiElementActions.map((action) => action.action).join(' & ')
        },
        {
            prop: 'numClicks',
            type: 'link',
            label: 'Number of Clicks',
            sortable: true
        },
        includePercentClicksOnStep && {
            prop: 'percentClicksOnStep',
            label: '% of Clicks (Step)',
            sortable: true,
            formatter: (row) => formatValueForSchema(row.percentClicksOnStep, 'percentage')
        },
        csv && {
            prop: 'uiElementStatus',
            label: 'Element Status',
            formatter: (row) => {
                if (row.isDeleted) {
                    return 'Deleted';
                }

                return 'Active';
            }
        }
    ].filter(Boolean);
}

export function getUiElementType (uiElementId, type) {
    if (uiElementId.includes('pendo-close-guide-')) {
        return 'Close Button';
    }

    return {
        button: 'Button',
        a: 'Link'
    }[type];
}

export function getCurrentElementData (dom) {
    let elements = [];
    const isCorrectType = dom.type && (dom.type === 'button' || dom.type === 'a');
    const uiElementId = get(dom, 'props.id', false);
    if (isCorrectType && uiElementId) {
        const uiElementText = getUiElementText(dom);
        const uiElementType = getUiElementType(uiElementId, dom.type);
        let uiElementActions = get(dom, 'actions');
        if (dom.type === 'a' && !uiElementActions) {
            uiElementActions = [{ action: 'openLink' }];
        }

        elements.push({ uiElementId, uiElementText, uiElementType, uiElementActions });
    }

    if (dom.children) {
        dom.children.forEach((child) => {
            elements = elements.concat(getCurrentElementData(child));
        });
    }

    return elements;
}

export function getUiElementText (dom) {
    const uiElementText = get(dom, 'content', false);
    if (uiElementText) return uiElementText;

    let concatenatedUiText = '';
    if (dom.children) {
        dom.children.forEach((child) => {
            const uiText = getUiElementText(child);
            if (uiText) {
                concatenatedUiText = concatenatedUiText.concat(uiText);
            }
        });
    }

    return concatenatedUiText;
}

export function getStepName (stepId, guide) {
    const step = guide.steps.find((step) => step.id === stepId);

    return get(step, 'name');
}

export function getStepIndex (stepId, guide) {
    return guide.steps.findIndex((step) => step.id === stepId) + 1;
}

export function formatUiElementTextForDisplay (uiElementText, uiElementId) {
    if (!uiElementText) {
        return uiElementId || 'Unknown';
    }

    if (uiElementText === '×') {
        return 'Close Button';
    }

    return uiElementText;
}

export function formatStepNameForDisplay (stepIndex, stepName) {
    if (stepIndex === 0) {
        return 'Deleted Step';
    }

    if (!stepName) {
        return `Step ${stepIndex} - Unnamed`;
    }

    return stepName;
}

export function formatPercentClicksOnStep (rows, showDeletedElements = false) {
    const totalClicksByStep = {};
    rows.forEach((row) => {
        if (!showDeletedElements && row.isDeleted) return;
        if (totalClicksByStep[row.guideStepId]) {
            totalClicksByStep[row.guideStepId] += row.numClicks;
        } else {
            totalClicksByStep[row.guideStepId] = row.numClicks;
        }
    });
    rows.forEach((row) => {
        row.percentClicksOnStep = row.numClicks > 0 ? (row.numClicks / totalClicksByStep[row.guideStepId]) * 100 : 0;
    });
    const stepUiElementsMap = rows.reduce((acc, row) => {
        if (!showDeletedElements && row.isDeleted) return acc;
        const { guideStepId, uiElementId, percentClicksOnStep } = row;
        if (acc[guideStepId]) {
            acc[guideStepId][uiElementId] = percentClicksOnStep;
        } else {
            acc[guideStepId] = { [uiElementId]: percentClicksOnStep };
        }

        return acc;
    }, {});
    Object.keys(stepUiElementsMap).forEach((guideStepId) => {
        let formattedPercentClicksOnStep = Object.values(stepUiElementsMap[guideStepId]);
        formattedPercentClicksOnStep = largestRemainderRound(formattedPercentClicksOnStep, 100, 1);
        Object.keys(stepUiElementsMap[guideStepId]).forEach((uiElementId, i) => {
            stepUiElementsMap[guideStepId][uiElementId] = formattedPercentClicksOnStep[i];
        });
    });
    rows.forEach((row) => {
        if (!showDeletedElements && row.isDeleted) return;
        row.percentClicksOnStep = stepUiElementsMap[row.guideStepId][row.uiElementId];
    });

    return rows;
}

export function processGuideElementsAggResponse (rows, guide, currentElementData) {
    rows = formatPercentClicksOnStep(rows);
    const mergedData = merge(keyBy(rows, 'uiElementId'), keyBy(currentElementData, 'uiElementId'));

    const data = Object.values(mergedData).map((row) => {
        const stepName = row.guideStepName || getStepName(row.guideStepId, guide);
        const stepIndex = row.guideStepIndex || getStepIndex(row.guideStepId, guide);

        return {
            ...row,
            numClicks: row.numClicks || 0,
            percentClicksOnStep: row.percentClicksOnStep || 0,
            uiElementTypeIcon: getUiElementTypeIcon(row.uiElementType),
            guideStepName: formatStepNameForDisplay(stepIndex, stepName),
            guideStepIndex: stepIndex,
            uiElementText: formatUiElementTextForDisplay(row.uiElementText),
            uiElementActions: getUiElementActions(row, guide)
        };
    });

    return sortBy(data, 'guideStepIndex');
}

export function getUiElementTypeIcon (elementType) {
    return {
        Button: 'rectangle',
        'Close Button': 'x',
        'Dismiss Button': 'x',
        Link: 'link'
    }[elementType];
}

export function parseActions (actions) {
    try {
        return JSON.parse(actions);
    } catch (error) {
        return actions;
    }
}

export function getUiElementActions (row, guide) {
    const actions = parseActions(row.uiElementActions || '[]');

    if (actions && actions.length) {
        return actions.reduce((acc, action) => {
            switch (action.action) {
                case 'submitPoll':
                    acc.push(
                        processElementAction({ action: 'submitPoll' }, guide),
                        processElementAction({ action: 'advanceGuide' }, guide)
                    );
                    break;
                case 'submitPollAndGoToStep':
                    acc.push(
                        processElementAction({ action: 'submitPoll' }, guide),
                        processElementAction({ ...action, action: 'goToStep' }, guide)
                    );
                    break;
                default:
                    acc.push(processElementAction(action, guide));
            }

            return acc;
        }, []);
    }

    return [{ action: 'Unknown' }];
}

export function processElementAction (action, guide) {
    switch (action.action) {
        case 'openLinkWithoutClosing':
        case 'openLink':
            return { action: 'External URL', icon: 'external-link' };
        case 'goToStep': {
            const stepId = action.guideStepId
                ? action.guideStepId
                : get(action.parameters.find((param) => param.name === 'goToStepId'), 'value');

            const stepIndex = getStepIndex(stepId, guide) || 'Deleted Step';

            return { action: `Go to Step (${stepIndex})`, icon: 'fast-forward' };
        }
        case 'guideSnoozed':
            return { action: 'Snooze Guide', icon: 'clock' };
        case 'showGuide':
        case 'launchGuide':
            return { action: 'Launch Guide', icon: 'guide' };
        case 'advanceGuide':
            return { action: 'Next Step', icon: 'skip-forward' };
        case 'previousStep':
            return { action: 'Previous Step', icon: 'rewind' };
        case 'submitPoll':
            return { action: 'Submit Poll', icon: 'metrics' };
        case 'dismissGuide':
            return { action: 'Dismiss Guide', icon: 'x-circle' };
        case 'openRatingDialog':
            return { action: 'Mobile Rating', icon: 'rating-scale' };
        default:
            return { action: 'Unknown' };
    }
}
