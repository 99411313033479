<template>
    <pendo-page-content tabs>
        <pendo-tabs
            v-model="activeTab"
            :tabs="tabs"
            theme="via"
            class="guide-details-tabs">
            <template #guideSettings>
                <guide-details-settings />
            </template>
            <template #guideMetrics>
                <guide-details-metrics />
            </template>
            <template #guidePolls>
                <guide-details-poll-responses />
            </template>
        </pendo-tabs>
    </pendo-page-content>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { PendoPageContent, PendoTabs } from '@pendo/components';
import GuideDetailsMetrics from '@/components/guides/metrics/GuideDetailsMetrics';
import GuideDetailsSettings from '@/components/guides/settings/GuideDetailsSettings';
import GuideDetailsPollResponses from '@/components/guides/polls/GuideDetailsPollResponses';

export default {
    name: 'GuideDetailsTabs',
    components: {
        GuideDetailsMetrics,
        GuideDetailsPollResponses,
        GuideDetailsSettings,
        PendoTabs,
        PendoPageContent
    },
    data () {
        return {
            allTabs: [
                {
                    prop: 'guideSettings',
                    label: 'Settings',
                    lazy: true
                },
                {
                    prop: 'guideMetrics',
                    label: 'Metrics',
                    lazy: true
                },
                {
                    prop: 'guidePolls',
                    label: 'Poll Responses',
                    lazy: true
                }
            ]
        };
    },
    computed: {
        ...mapState({
            route: (state) => state.route
        }),
        ...mapGetters({
            polls: 'guides/activePolls'
        }),
        activeTab: {
            get () {
                return this.route.name || 'guideSettings';
            },
            set (tab) {
                this.$router.push({ name: tab });
            }
        },
        tabs () {
            return this.allTabs.filter((tab) => {
                return tab.prop !== 'guidePolls' || this.polls.length;
            });
        }
    }
};
</script>
