<template>
    <pendo-card
        v-pendo-loading:feather="isFetching"
        body-min-height="0"
        title="Visitors Overview"
        class="visitor-overview">
        <template slot="filters">
            <active-segment-tag />
            <active-date-range-tag />
        </template>
        <div class="visitor-overview--metrics">
            <pendo-metric
                :value="totalCount"
                type="primary"
                label="Total Visitors" />
            <pendo-metric
                :value="avgTimeFormatted"
                type="primary"
                label="Avg. Daily Time On App" />
        </div>
    </pendo-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import { getTimeOnApp } from '@/aggregations/time-on-app';
import { PendoCard, PendoMetric, PendoLoading } from '@pendo/components';
import ActiveSegmentTag from '@/components/filters/ActiveSegmentTag';
import ActiveDateRangeTag from '@/components/filters/ActiveDateRangeTag';
import { isCancel } from 'axios';

export default {
    name: 'AnalyticsVisitorOverview',
    components: {
        ActiveDateRangeTag,
        ActiveSegmentTag,
        PendoCard,
        PendoMetric
    },
    directives: {
        PendoLoading
    },
    data () {
        return {
            isFetching: false,
            aggCancel: null,
            unsubscribeFilterBarListener: null,
            avgTime: 0,
            uniqueVisitors: 0
        };
    },
    computed: {
        ...mapState({
            activeSegmentId: (state) => state.filters.activeSegmentId
        }),
        ...mapGetters({
            activeTimeSeries: 'filters/activeTimeSeries',
            activeAppId: 'apps/activeId'
        }),
        avgTimeFormatted () {
            const { avgTime } = this;

            if (!avgTime) {
                if (this.isFetching) {
                    return '---';
                }

                return '0m 0s';
            }

            const totalSeconds = avgTime / 1000;
            const minutes = parseInt(totalSeconds / 60, 10); // parseInt always "rounds" down
            const seconds = Math.round(totalSeconds % 60);

            return `${minutes}m ${seconds}s`;
        },
        totalCount () {
            if (typeof this.uniqueVisitors === 'undefined') {
                return '---';
            }

            return this.uniqueVisitors;
        }
    },
    created () {
        this.fetchVisitorTimeOnApp();
        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, () => {
            this.fetchVisitorTimeOnApp();
        });
    },
    destroyed () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        async fetchVisitorTimeOnApp () {
            this.isFetching = true;

            if (this.aggCancel) {
                this.aggCancel.abort();
            }

            this.aggCancel = new AbortController();

            const timeSeries = {
                ...this.activeTimeSeries,
                period: 'dayRange'
            };

            try {
                const { avgTime, uniqueVisitors } = await getTimeOnApp({
                    timeSeries,
                    segmentId: this.activeSegmentId,
                    appId: this.activeAppId,
                    signal: this.aggCancel.signal
                });

                this.avgTime = avgTime;
                this.uniqueVisitors = uniqueVisitors;
                this.isFetching = false;
            } catch (err) {
                // If we've cancelled our aggs because a segment/app/whatever changed, things are still loading
                if (!isCancel(err)) {
                    this.isFetching = false;
                }
            }
        }
    }
};
</script>

<style lang="scss">
.visitor-overview {
    .pendo-card__body {
        display: grid;
        grid-template-rows: auto 1fr;
    }

    &--metrics {
        display: grid;
        grid-gap: 16px;
        align-items: center;
        justify-items: center;
        justify-content: center;
        padding: 0 0 16px 0;
        grid-template-columns: repeat(auto-fit, minmax(148px, 190px));
    }
}
</style>
