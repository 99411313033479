import Vue from 'vue';
import Vuex from 'vuex';
import { pendolytics } from './plugins/pendo.js';
import { theme } from './plugins/theme.js';

import modules from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules,
    plugins: [pendolytics, theme],
    strict: process.env.NODE_ENV !== 'production'
});

export default store;
