<template>
    <div>
        <div
            v-if="!showNewPublicView"
            class="view--public">
            <div>
                <pendo-card body-min-height="0">
                    <div class="brand">
                        <adopt-logo
                            v-if="!brandLogoUrl"
                            text-color="dark" />
                        <img
                            v-if="brandLogoUrl"
                            :src="brandLogoUrl"
                            class="brand--logo">
                        <svg
                            v-if="brandName"
                            class="divider">
                            <path d="M0 0v22" />
                        </svg>
                        <div
                            v-if="brandName"
                            class="brand--product-name">
                            {{ brandName }}
                        </div>
                    </div>
                    <pendo-divider
                        direction="horizontal"
                        stroke="#dadce5" />
                    <component :is="component" />
                </pendo-card>
                <powered-by
                    v-if="showAdoptPoweredBy"
                    class="powered-by"
                    text-color="light" />
            </div>
        </div>
        <public-view-2 v-if="showNewPublicView" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { PendoCard, PendoDivider } from '@pendo/components';
import AdoptLogo from '@/components/icons/AdoptLogo';
import CreateAdoptAccount from '@/components/register/adopt-v2-register/CreateAdoptAccount';
import Confirmation from '@/components/register/adopt-v2-register/Confirmation';
import NewToAdoptLoginForm from '@/components/register/adopt-v2-register/NewToAdoptLoginForm';
import LoginForm from '@/components/LoginForm';
import NoActiveSubscriptions from '@/components/NoActiveSubscriptions';
import CreateAccount from '@/components/register/CreateAccount';
import ResetPassword from '@/components/register/ResetPassword';
import ForgotPassword from '@/components/register/ForgotPasswordForm';
import PublicView2 from '@/views/Public2';
import PoweredBy from '@/components/PoweredBy';
import get from 'lodash/get';
// List of updated components that use the new pendo-background component as part of the unified login work
const COMPONENTS = ['ForgotPassword', 'ExpiredLinkPage'];

export default {
    name: 'PublicView',
    components: {
        AdoptLogo,
        CreateAccount,
        CreateAdoptAccount,
        NewToAdoptLoginForm,
        ForgotPassword,
        PublicView2,
        LoginForm,
        NoActiveSubscriptions,
        PendoCard,
        PendoDivider,
        ResetPassword,
        PoweredBy,
        Confirmation
    },
    computed: {
        ...mapState({
            component: (state) => state.route.meta.component,
            unauthenticatedTrainingAttributes: (state) => state.subscriptions.unauthenticatedTrainingAttributes
        }),
        brandLogoUrl () {
            return get(this.unauthenticatedTrainingAttributes, 'whiteLabelSettings.logoUrl');
        },
        brandName () {
            return get(this.unauthenticatedTrainingAttributes, 'productName');
        },
        showAdoptPoweredBy () {
            return get(this.unauthenticatedTrainingAttributes, 'whiteLabelSettings');
        },
        isDevEnv () {
            return (
                window.__via_info__.env !== 'test' &&
                window.__via_info__.env !== 'prod' &&
                window.__via_info__.env !== 'prod-eu'
            );
        },
        showNewPublicView () {
            return this.isDevEnv && COMPONENTS.includes(this.component);
        }
    },
    watch: {
        unauthenticatedTrainingAttributes () {
            this.formatDocumentTitleByRoute();
        }
    },
    mounted () {
        this.$modal.show('splitModal');
    },
    methods: {
        ...mapActions({
            formatDocumentTitleByRoute: 'router/formatDocumentTitleByRoute'
        })
    }
};
</script>

<style lang="scss">
.view--public {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $page-bg-dark;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .pendo-card {
        width: 380px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.25);
        border-radius: 5px;

        .pendo-card__body {
            padding: 0;
        }
    }

    .brand {
        padding: 1.5rem 1.25rem 0 1.25rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .brand--logo {
            max-width: 7rem;
            max-height: 3rem;
            vertical-align: middle;
        }

        .divider {
            height: 22px;
            width: 1px;
            margin-left: 0.5rem;

            path {
                stroke: $gray-lighter-5;
                stroke-width: 2;
            }
        }

        .brand--product-name {
            color: $gray-lighter-2;
            font-size: 0.9rem;
            font-weight: 600;
            padding-left: 1rem;
            line-height: 1.25rem;
        }
    }

    .powered-by {
        position: absolute;
        padding-left: 0;
    }
}
</style>
