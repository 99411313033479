<template>
    <pendo-modal
        :visible="visible"
        :width="400"
        :title="modalTitle"
        :show-close="!isCopying"
        height="auto"
        @close="closeModal">
        <template #body>
            <pendo-alert
                v-if="errors.failedCopyError"
                data-cy="failed-copy-copy-modal-error"
                class="copy-modal-error"
                type="error">
                <slot>
                    <p>
                        We did not copy "{{ guide.name }}" to <strong>{{ targetSub.displayName }}</strong> for
                        <strong>{{ targetApp.displayName }}</strong> becasue of <em>{{ errorMessage.failedCopy }}</em>
                    </p>
                </slot>
            </pendo-alert>
            <div
                v-if="ineligibleGuide && currentModalState === MODAL_STATES.START"
                class="guide-copy-modal__ineligible-warning">
                <pendo-alert
                    type="warning"
                    title="The selected guide has a custom segment that must be modified to copy." />
                <div class="guide-copy-modal__segment-consent">
                    <pendo-checkbox v-model="removeSegmentConfirmation" />
                    <label>I understand this guide will be copied with the segment defaulted to 'Everyone' in a Draft
                        state.</label>
                </div>
            </div>
            <select-target
                v-if="currentModalState === MODAL_STATES.START"
                ref="selectTarget"
                :guide="guide"
                :prior-selected-target="{ targetSub, targetApp }"
                @targetSubChanged="resetAllSelectedValues"
                @targetsSelected="handleTarget" />
            <select-page
                v-if="currentModalState === MODAL_STATES.PAGE_CHECK"
                :guide="guide"
                :target-sub="targetSub"
                :target-app="targetApp"
                :target-pages="targetPages"
                :target-page-map="pageMap"
                @pageSelected="handlePage" />
            <confirm-copy
                v-if="currentModalState === MODAL_STATES.CONFIRM"
                :guide="guide"
                :target-sub="targetSub"
                :target-app="targetApp"
                :warnings="warnings" />
        </template>
        <div
            slot="footer"
            class="guide-copy-modal-footer"
            :class="{ 'back-button-visible': currentModalState !== MODAL_STATES.START }">
            <pendo-button
                v-if="currentModalState !== MODAL_STATES.START"
                class="back-button"
                label="Back"
                theme="via"
                type="link"
                :disabled="false"
                @click="previousModalState" />
            <div v-pendo-tooltip="removeSegmentTooltip">
                <pendo-button
                    label="Cancel"
                    theme="via"
                    type="secondary"
                    :disabled="false"
                    @click="closeModal" />
                <pendo-button
                    v-if="currentModalState !== MODAL_STATES.CONFIRM"
                    label="Next"
                    theme="via"
                    type="primary"
                    :loading="loadingModalState"
                    :disabled="nextButtonDisabled"
                    @click="nextButtonHandler" />
                <pendo-button
                    v-if="currentModalState === MODAL_STATES.CONFIRM"
                    label="Copy Guide"
                    theme="via"
                    type="primary"
                    :loading="isCopying"
                    :disabled="false"
                    @click="onSubmitCopy" />
            </div>
        </div>
    </pendo-modal>
</template>

<script>
import get from 'lodash/get';
import {
    PendoTooltip,
    PendoAlert,
    PendoButton,
    PendoModal,
    PendoLoading,
    PendoNotification,
    PendoCheckbox
} from '@pendo/components';
import { copyGuide, loadTargetAppPages } from '@/utils/copy-guides';
import SelectTarget from './copy-guides/SelectTarget';
import SelectPage from './copy-guides/SelectPage';
import ConfirmCopy from './copy-guides/ConfirmCopy';

const MODAL_STATES = Object.freeze({
    START: 'start',
    PAGE_CHECK: 'pageActivatedCheck',
    CONFIRM: 'confirm'
});

export default {
    name: 'GuideCopyModal',
    components: {
        PendoModal,
        PendoAlert,
        PendoButton,
        PendoCheckbox,
        SelectTarget,
        SelectPage,
        ConfirmCopy
    },
    directives: {
        PendoTooltip,
        PendoLoading
    },
    props: {
        guide: {
            type: Object,
            default: () => ({})
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            removeSegmentConfirmation: false,
            isCopying: false,
            conditionsToHandle: [],
            conditionsHandled: [],
            targetPages: [],
            currentModalState: MODAL_STATES.START,
            targetSub: null,
            targetApp: null,
            pageMap: null,
            loadingModalState: false,
            errors: {
                failedCopyError: false
            },
            warnings: {},
            MODAL_STATES
        };
    },
    computed: {
        ineligibleGuide () {
            const segmentId = get(this.guide, 'audienceUiHint.filters[0].segmentId', null);

            return segmentId && segmentId !== 'everyone';
        },
        removeSegmentTooltip () {
            const tooltipContent =
                "Please confirm you understand some guides will have their segments updated to 'Everyone'.";

            if (this.currentModalState === MODAL_STATES.START && !this.removeSegmentConfirmationRequiredAndChecked) {
                return {
                    multiline: true,
                    arrow: true,
                    content: tooltipContent
                };
            }

            return {};
        },
        removeSegmentConfirmationRequiredAndChecked () {
            return this.ineligibleGuide ? this.removeSegmentConfirmation : true;
        },
        nextButtonHandler () {
            return this.currentModalState === MODAL_STATES.START ? this.checkPreflightConditions : this.nextModalState;
        },
        nextButtonDisabled () {
            switch (this.currentModalState) {
                case MODAL_STATES.START:
                    return !this.removeSegmentConfirmationRequiredAndChecked || !this.targetSub || !this.targetApp;
                case MODAL_STATES.PAGE_CHECK:
                    return !this.pageMap;
                default:
                    return !this.targetSub || !this.targetApp;
            }
        },
        modalTitle () {
            switch (this.currentModalState) {
                case MODAL_STATES.PAGE_CHECK:
                    return 'Select Activation Page';
                default:
                    return 'Copy Guide to Account';
            }
        },
        errorMessage () {
            return {
                unsupported: '',
                failedCopy: ''
            };
        }
    },
    methods: {
        async checkPreflightConditions () {
            this.conditionsToHandle = [];
            this.warnings = {};
            await this.loadTargetAppPages();
            const pageActivatedCheck = this.guideStepsTargetPage();

            if (pageActivatedCheck) this.conditionsToHandle.push(MODAL_STATES.PAGE_CHECK);
            this.conditionsToHandle.push(MODAL_STATES.CONFIRM);
            this.conditionsHandled.unshift(MODAL_STATES.START);
            this.nextModalState();
        },
        async loadTargetAppPages () {
            try {
                const response = await loadTargetAppPages({
                    targetSubId: this.targetSub.id,
                    targetAppId: this.targetApp.id
                });
                this.targetPages = response.data;
            } catch (error) {
                this.targetPages = [];
            }
        },
        guideStepsTargetPage () {
            const { steps } = this.guide;

            return steps.some((step) => step.pageId);
        },
        pageExistsInTargetSub (pageId) {
            return this.targetPages.find((page) => page.id === pageId);
        },
        handleTarget (payload) {
            this.loadingModalState = false;
            this.resetErrors();

            this.targetSub = payload.targetSub;
            this.targetApp = payload.targetApp;
        },
        handlePage (payload) {
            this.loadingModalState = false;
            this.pageMap = payload.pageMap;

            if (payload.pagesToCreate && payload.pagesToCreate.length) {
                this.warnings.createPageOnTargetApp = payload.pagesToCreate;
            } else {
                this.warnings.createPageOnTargetApp = null;
            }
        },
        resetAllSelectedValues () {
            this.pageMap = null;
        },
        resetErrors () {
            this.errors = {
                failedCopyError: false
            };
        },
        nextModalState () {
            this.loadingModalState = true;
            const condition = this.conditionsToHandle.shift();
            this.currentModalState = condition;

            this.conditionsHandled.push(condition);
        },
        previousModalState () {
            this.resetErrors();
            this.conditionsToHandle.unshift(this.conditionsHandled.pop());
            this.conditionsToHandle.unshift(this.conditionsHandled.pop());
            this.nextModalState();
        },
        closeModal () {
            if (this.currentModalState === MODAL_STATES.START) this.$refs.selectTarget.reset();
            this.currentModalState = MODAL_STATES.START;
            this.targetSub = null;
            this.targetApp = null;
            this.conditionsToHandle = [];
            this.conditionsHandled = [];
            this.pageMap = null;
            this.resetErrors();
            this.warnings = {};
            this.isCopying = false;
            this.loadingModalState = false;
            this.removeSegmentConfirmation = false;
            this.$emit('close');
        },
        async onSubmitCopy () {
            this.isCopying = true;
            this.errors.failedCopyError = false;

            try {
                await copyGuide({
                    id: this.guide.id,
                    targetSubId: parseInt(this.targetSub.id),
                    targetAppId: parseInt(this.targetApp.id),
                    pageMap: this.pageMap
                });
                const message = `We've successfully copied "${this.guide.name}"
                                 to <strong>${this.targetSub.displayName}</strong>
                                 for <strong>${this.targetApp.displayName}</strong>`;

                PendoNotification({
                    type: 'success',
                    title: 'Guide Copied to Account',
                    dangerouslyUseHTMLString: true,
                    message,
                    duration: 5000
                });

                this.closeModal();
            } catch (error) {
                this.errors.failedCopyError = true;
                this.errorMessage.failedCopy = get(error, 'response.data', '') || 'failure';
                this.isCopying = false;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.guide-copy-modal__ineligible-warning {
    font-weight: bold;
    display: grid;
    grid-gap: 4px;

    .guide-copy-modal__segment-consent {
        margin-bottom: 14px;
        display: flex;

        .pendo-checkbox {
            margin-top: 2px;
        }
    }
}

.copy-modal-error {
    margin-bottom: 16px;
}

.copy-modal-error p {
    margin-bottom: 8px;
}

.guide-copy-modal-footer {
    display: flex;
    justify-content: flex-end;
}

.guide-copy-modal-footer {
    display: flex;
    justify-content: flex-end;

    .back-button {
        margin-left: 4px;
    }
}

.guide-copy-modal-footer.back-button-visible {
    display: flex;
    justify-content: space-between;
}
</style>
