<template>
    <pendo-card
        class="workflows-stats"
        :title="status"
        body-min-height="183px">
        <template slot="body">
            <div class="card-stats">
                <pendo-loading-indicator
                    v-if="loading"
                    :loading="true"
                    size="medium" />

                <div
                    v-if="!loading"
                    class="visitor-stats">
                    <div class="percentage">
                        {{ formattedPercentage }}%
                    </div>
                    <div class="visitors">
                        ({{ currentVisitors }} out of {{ totalVisitors }} Visitors)
                    </div>
                </div>

                <div
                    v-if="!loading"
                    class="description">
                    {{ description }}
                </div>
            </div>
        </template>
    </pendo-card>
</template>

<script>
import { PendoCard, PendoLoadingIndicator } from '@pendo/components';

export default {
    name: 'WorkflowsStatusCard',
    components: {
        PendoCard,
        PendoLoadingIndicator
    },
    props: {
        status: {
            type: String,
            default: ''
        },
        currentVisitors: {
            type: Number,
            default: 0
        },
        totalVisitors: {
            type: Number,
            default: 0
        },
        percentage: {
            type: Number,
            default: 0
        },
        description: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        formattedPercentage () {
            return (this.percentage * 100).toLocaleString(undefined, {
                maximumFractionDigits: 0
            });
        }
    }
};
</script>

<style>
.workflows-stats {
    flex: 1;
}

.card-stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: inherit;
    padding: 8px;
}

.visitor-stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.percentage {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 15%;
}

.visitors {
    font-size: 16px;
}

.description {
    font-size: 14px;
    justify-self: flex-end;
}
</style>
