import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import VueJsModal from 'vue-js-modal/dist/ssr.nocss.js';
import ResizeObserver from 'resize-observer-polyfill';
import App from '@/App.vue';
import router from '@/router';
import store from '@/state/store';
import get from 'lodash/get';
import { setupPendoSnippet } from '@/state/plugins/pendo';
import { startDesignerBridge } from '@/state/designer-bridge';
import './registerServiceWorker';
import VueClipboard from 'vue-clipboard2';
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import sanitizeHTML from 'sanitize-html';

Vue.prototype.$sanitize = sanitizeHTML;

if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver;
}

window.__via_info__ = {
    env: get(__PROCESS__, 'PENDO_ENV'),
    version: get(__PROCESS__, 'version'),
    gitHash: get(__PROCESS__, 'hash')
};

setupPendoSnippet();
startDesignerBridge();

sync(store, router);

store.dispatch('subscriptions/fetchUnauthenticatedTrainingAttributes');
store.dispatch('auth/init');

Vue.config.productionTip = false;
Vue.use(VueJsModal);

Vue.use(VueClipboard);

hljs.registerLanguage('javascript', javascript);

const app = new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app');

if (window.Cypress) {
    window.__app__ = app;
}
