<template>
    <pendo-modal
        :visible="visible"
        :width="400"
        :title="modalTitle"
        :show-close="!isCopyingGuides"
        height="auto"
        @close="closeModal"
        @closed="onModalClosed">
        <template v-if="isDefaultState">
            <div class="guide-copy-modal__description">
                Select the account where you would like copies of the selected guides to appear.
            </div>
            <div class="guide-copy-modal__status-warning">
                Guides will be set to 'Draft' state when copied to the account.
            </div>
            <pendo-divider stroke="#dadce5" />
            <pendo-multiselect
                v-model="selectedAccount"
                :labels="{ top: 'Copy to Account' }"
                :disabled="accountOptions.length === 1"
                :options="accountOptions"
                full-width
                preselect-first
                placeholder="-- Select Account --" />
            <div
                v-if="ineligibleGuides.length"
                class="guide-copy-modal__ineligible-list">
                <pendo-alert
                    type="warning"
                    title="The selected guides have custom segments that must be modified to copy." />
                <div class="guide-copy-modal__ineligible-guides">
                    <div
                        v-for="guide in ineligibleGuides"
                        :key="guide.id"
                        class="guide-copy-modal__ineligible-guide">
                        {{ guide.name }}
                    </div>
                </div>
                <pendo-divider stroke="#dadce5" />
                <div class="guide-copy-modal__segment-consent">
                    <pendo-checkbox v-model="removeSegmentConfirmation" />
                    <label>I understand these guides will be copied with the segment defaulted to 'Everyone' in a Draft
                        state.</label>
                </div>
            </div>
        </template>
        <template v-if="isProgressState">
            <div class="guide-copy-modal__progress">
                <pendo-progress-bar
                    ref="copyGuidesProgressBar"
                    :colors="progressBarColors"
                    :value="copyStatus.progress"
                    :width="368"
                    trickle />
                <div class="guide-copy-modal__progress-status">
                    {{ copyStatus.success }} of {{ copyStatus.total }} guides copied
                </div>
            </div>
        </template>
        <template v-if="isResultState">
            <div class="guide-copy-modal__status-container">
                <pendo-alert
                    v-if="copyStatus.failed.length"
                    type="error">
                    <div class="guide-copy-modal__failure-alert">
                        {{ failedAlertText }}
                    </div>
                </pendo-alert>
                <pendo-alert
                    v-if="!copyStatus.failed.length"
                    type="success">
                    <div class="guide-copy-modal__success-alert">
                        Guides Copied
                    </div>
                </pendo-alert>
                <div class="guide-copy-modal__success-count-message">
                    Successfully copied {{ copyStatus.success }} of {{ copyStatus.total }} guides to
                    <span class="strong">{{ selectedAccount.label }}</span>.
                </div>
                <div
                    v-if="failedGuideList"
                    class="guide-copy-modal__failure-list">
                    <div>The following guides failed to copy:</div>
                    <div
                        v-for="guide in failedGuideList"
                        :key="guide.id"
                        class="guide-copy-modal__failed-guide">
                        {{ guide.name }}
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <div
                v-if="isDefaultState"
                class="guide-copy-modal__footer">
                <pendo-button
                    label="Cancel"
                    theme="via"
                    data-cy="guide-copy-modal--cancel"
                    type="secondary"
                    @click="closeModal" />
                <div v-pendo-tooltip="!removeSegmentConfirmation && removeSegmentTooltip">
                    <pendo-button
                        :disabled="!removeSegmentConfirmation"
                        label="Copy Guides"
                        theme="via"
                        data-cy="guide-copy-modal--copy"
                        type="primary"
                        @click="copyGuides(undefined)" />
                </div>
            </div>
            <div v-if="isResultState">
                <pendo-button
                    label="Close"
                    data-cy="guide-copy-modal--close"
                    theme="via"
                    type="secondary"
                    @click="closeModal" />
                <pendo-button
                    v-if="copyStatus.failed.length"
                    label="Retry Failures"
                    data-cy="guide-copy-modal--retry"
                    theme="via"
                    type="primary"
                    @click="retryFailedCopies" />
            </div>
        </template>
    </pendo-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import {
    PendoProgressBar,
    PendoTooltip,
    PendoAlert,
    PendoButton,
    PendoModal,
    PendoMultiselect,
    PendoCheckbox,
    PendoDivider
} from '@pendo/components';
import { copyGuidesV1 } from '@/utils/copy-guides';
import { PROGRESS_COLORS } from '@/constants/progress';

const MODAL_STATES = Object.freeze({
    DEFAULT: 'default',
    PROGRESS: 'progress',
    RESULT: 'result'
});

export default {
    name: 'GuideCopyModal',
    components: {
        PendoCheckbox,
        PendoModal,
        PendoAlert,
        PendoMultiselect,
        PendoButton,
        PendoProgressBar,
        PendoDivider
    },
    directives: {
        PendoTooltip
    },
    props: {
        guides: {
            type: Array,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            removeSegmentConfirmation: false,
            isCopyingGuides: false,
            selectedAccount: null,
            copyStatus: {
                completed: 0,
                failed: [],
                total: 0,
                percent: 0,
                success: 0
            }
        };
    },
    computed: {
        ...mapGetters({
            activeAccount: 'subscriptions/activeAccount',
            getAccountListForActiveApp: 'subscriptions/getAccountListForActiveApp'
        }),
        isDefaultState () {
            return this.modalState === MODAL_STATES.DEFAULT;
        },
        isProgressState () {
            return this.modalState === MODAL_STATES.PROGRESS;
        },
        isResultState () {
            return this.modalState === MODAL_STATES.RESULT;
        },
        modalState () {
            if (this.isCopyingGuides) {
                return MODAL_STATES.PROGRESS;
            }

            if (this.copyStatus.percent === 100) {
                return MODAL_STATES.RESULT;
            }

            return MODAL_STATES.DEFAULT;
        },
        modalTitle () {
            return {
                default: 'Copy Guides To Account',
                progress: 'Copying Guides',
                result: 'Copying Guides Complete'
            }[this.modalState];
        },
        progressBarColors () {
            // default -> success
            let complete = PROGRESS_COLORS.SUCCESS;

            // warning -> some requests passed, some failed
            if (this.copyStatus.success !== this.copyStatus.total) {
                complete = PROGRESS_COLORS.WARNING;
            }

            // error -> all requests failed
            if (!this.copyStatus.success) {
                complete = PROGRESS_COLORS.ERROR;
            }

            return {
                default: PROGRESS_COLORS.DEFAULT,
                progress: PROGRESS_COLORS.PROGRESS,
                complete
            };
        },
        failedGuideList () {
            if (this.copyStatus.failed.length) {
                return this.copyStatus.failed.map((id) => {
                    return this.guides.find((guide) => guide.id === id);
                });
            }

            return false;
        },
        failedAlertText () {
            const failedCount = this.copyStatus.failed.length;
            if (failedCount > 1) {
                return `${failedCount} guides failed to copy to account.`;
            }

            return `${failedCount} guide failed to copy to account.`;
        },
        ineligibleGuides () {
            return this.guides.filter((guide) => {
                const segmentId = get(guide, 'audienceUiHint.filters[0].segmentId', null);

                return segmentId && segmentId !== 'everyone';
            });
        },
        guideIdsToCopy () {
            return this.guides.map((guide) => guide.id);
        },
        accountOptions () {
            if (this.getAccountListForActiveApp) {
                return this.getAccountListForActiveApp
                    .filter((account) => account.id !== this.activeAccount.id)
                    .map((account) => ({
                        id: account.id,
                        label: account.displayName || account.id
                    }));
            }

            return [];
        },
        removeSegmentTooltip () {
            const tooltipContent =
                "Please confirm you understand some guides will have their segments updated to 'Everyone'.";

            return {
                multiline: true,
                arrow: true,
                content: tooltipContent
            };
        }
    },
    watch: {
        visible () {
            if (this.visible) {
                this.selectedAccount = this.accountOptions[0];
                if (!this.ineligibleGuides.length) {
                    this.removeSegmentConfirmation = true;
                }
            }
        }
    },
    methods: {
        closeModal () {
            this.$emit('close');
        },
        onModalClosed () {
            this.$emit('closed');

            this.selectedAccount = null;
            this.removeSegmentConfirmation = false;
            this.copyStatus = {
                completed: 0,
                failed: [],
                total: 0,
                percent: 0,
                success: 0
            };
        },
        retryFailedCopies () {
            const guideIds = this.copyStatus.failed;
            this.copyGuides(guideIds);
        },
        async copyGuides (guideIds = this.guideIdsToCopy) {
            this.copyStatus = {
                completed: 0,
                failed: [],
                total: guideIds.length,
                percent: 0,
                success: 0
            };

            const toAccount = this.selectedAccount.id;
            this.isCopyingGuides = true;
            // wait for progress bar to be rendered to DOM
            await this.$nextTick();
            this.$refs.copyGuidesProgressBar.start();

            await copyGuidesV1({ guideIds, removeSegment: this.removeSegmentConfirmation, toAccount }, this.onProgress);
        },
        onProgress (progress) {
            const copyStatus = {
                ...progress,
                success: progress.completed - progress.failed.length
            };

            if (progress.completed === progress.total) {
                copyStatus.percent = 100;
            } else {
                copyStatus.percent = (progress.completed / progress.total - 0.1) * 100;
            }

            if (copyStatus.percent === 100) {
                this.$refs.copyGuidesProgressBar.done();
                setTimeout(() => {
                    this.isCopyingGuides = false;
                }, 1000);
            }

            this.copyStatus = copyStatus;
        }
    }
};
</script>

<style lang="scss">
.guide-copy-modal__description {
    margin-top: 0;
    margin-bottom: 16px;
}

.guide-copy-modal__success-alert,
.guide-copy-modal__failure-alert,
.guide-copy-modal__status-warning {
    font-weight: 700;
}

.guide-copy-modal__status-container {
    display: grid;
    grid-gap: 16px;
}

.guide-copy-modal__ineligible-list,
.guide-copy-modal__failure-list {
    font-weight: bold;
    display: grid;
    grid-gap: 4px;
}

.guide-copy-modal__ineligible-list {
    margin-top: 16px;

    .guide-copy-modal__ineligible-guides {
        margin-top: 16px;
        max-height: 200px;
        overflow-y: scroll;
    }

    .guide-copy-modal__ineligible-guide {
        font-weight: 400;
        line-height: 1.4;
        color: $red-error;
    }

    .guide-copy-modal__segment-consent {
        display: flex;

        .pendo-checkbox {
            margin-top: 2px;
        }
    }
}

.guide-copy-modal__failed-guide {
    font-weight: 400;
}

.guide-copy-modal__progress {
    display: grid;
    grid-gap: 16px;
}

.guide-copy-modal__progress-status {
    color: $gray-lighter-2;
    font-weight: 600;
    text-align: center;
}

.guide-copy-modal__footer {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    justify-content: end;
}
</style>
