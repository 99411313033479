<template>
    <pendo-card
        key="selectTarget"
        body-min-height="inherit"
        class="select-target-card">
        <select-target
            ref="selectTarget"
            class="target-selector"
            bulk-copy
            :prior-selected-target="{ targetSubscription, targetApplication }"
            @targetsSelected="handleTarget" />
    </pendo-card>
</template>

<script>
import { PendoCard } from '@pendo/components';
import SelectTarget from '@/components/guides/list/copy-guides/SelectTarget';

export default {
    name: 'SelectTargetLocation',
    components: {
        PendoCard,
        SelectTarget
    },
    props: {
        guides: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            targetSubscription: null,
            targetApplication: null
        };
    },
    methods: {
        handleTarget (payload) {
            this.targetSubscription = payload.targetSub;
            this.targetApplication = payload.targetApp;
            this.$emit('targetsSelected', payload);
        }
    }
};
</script>
