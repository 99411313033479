<template>
    <nav
        id="navbar"
        :class="{ 'show-menu': showMenu }"
        class="navbar">
        <div class="navbar-brand">
            <router-link
                :class="{ disabled: brand.disabled }"
                :to="{ name: brand.name, query: brand.query }"
                class="brand--link">
                <img
                    v-if="brand.logoUrl"
                    :src="brand.logoUrl"
                    class="brand--logo">
                <adopt-logo
                    v-if="!brand.logoUrl"
                    class="brand--logo" />
            </router-link>
            <svg
                v-if="brand.productName"
                class="divider">
                <path d="M0 0v22" />
            </svg>
            <div
                v-if="brand.productName"
                class="brand--product-name">
                {{ brand.productName }}
            </div>
        </div>
        <div
            v-if="showNavbarLinks"
            ref="navbarActions"
            class="navbar-actions">
            <router-link
                v-for="link in routerLinks"
                :key="link.value"
                :to="link.to"
                class="action--link">
                <pendo-button
                    :label="link.label"
                    type="tertiary"
                    theme="via" />
            </router-link>
        </div>
        <div
            v-if="isEmptyStateDigitalAdoption"
            class="navbar-logout">
            <pendo-button
                type="tertiary"
                block
                label="Log Out"
                @click="onLogout" />
        </div>
        <div
            v-else
            class="navbar-switchers">
            <app-select />
        </div>
    </nav>
</template>

<script>
import get from 'lodash/get';
import { mapState, mapGetters, mapActions } from 'vuex';
import { PendoButton } from '@pendo/components';
import AdoptLogo from '@/components/icons/AdoptLogo';
import AppSelect from '@/components/app-picker/AppSelect';

export default {
    name: 'Navbar',
    components: {
        PendoButton,
        AdoptLogo,
        AppSelect
    },
    data () {
        return {
            showMenu: false,
            menuBreakpoint: null,
            resizeObserver: null,
            mutationObserver: null,
            dropdownProps: {
                placement: 'bottom-end',
                dropdownMenuStyles: {
                    border: '1px solid #eaecf1'
                }
            }
        };
    },
    computed: {
        ...mapState({
            route: (state) => state.route
        }),
        ...mapGetters({
            isEmptyStateDigitalAdoption: 'subscriptions/isEmptyStateDigitalAdoption',
            activeSub: 'subscriptions/active',
            activeSubHasAnalytics: 'subscriptions/activeHasAnalytics',
            activeAppHasAnalytics: 'apps/activeHasAnalytics',
            activeHasResourceCenter: 'subscriptions/activeHasResourceCenter',
            activeApp: 'apps/active'
        }),
        showNavbarLinks () {
            return !(this.showMenu || this.isEmptyStateDigitalAdoption);
        },
        brand () {
            const { query } = this.route;
            const logoUrl = get(this.activeApp, 'trainingAttributes.whiteLabelSettings.logoUrl');
            const productName = get(this.activeSub, 'trainingAttributes.productName');

            return {
                name: 'guideList',
                query,
                logoUrl,
                productName
            };
        },
        routerLinks () {
            const links = [
                {
                    value: 'guideList',
                    label: 'Guides',
                    to: {
                        name: 'guideList',
                        query: this.route.query
                    }
                }
            ];

            if (this.activeHasResourceCenter) {
                links.push({
                    value: 'resourceCenter',
                    label: 'Resource Center',
                    to: {
                        name: 'resourceCenter',
                        query: this.route.query
                    }
                });
            }

            if (this.activeSubHasAnalytics || this.activeAppHasAnalytics) {
                links.push({
                    value: 'analytics',
                    label: 'Analytics',
                    to: {
                        name: 'analyticsDashboard',
                        query: this.route.query
                    }
                });
            }

            links.push({
                value: 'settings',
                label: 'Settings',
                to: {
                    name: 'userProfile',
                    query: this.route.query
                }
            });

            return links;
        }
    },
    beforeMount () {
        this.mutationObserver = new MutationObserver(() => this.handleNavbarChange());
        this.resizeObserver = new ResizeObserver(() => this.handleNavbarChange());
    },
    mounted () {
        this.resizeObserver.observe(this.$el);
        this.mutationObserver.observe(this.$el, {
            attributes: true,
            childList: true,
            subtree: true
        });
    },
    beforeDestroy () {
        this.mutationObserver.disconnect();
        this.mutationObserver = null;
        this.resizeObserver.disconnect();
        this.resizeObserver = null;
    },
    methods: {
        ...mapActions({
            logout: 'auth/logout'
        }),
        async onLogout () {
            try {
                await this.logout();
                this.$router.push({ name: 'login', query: this.route.query });
            } catch (err) {
                window.location.reload(true);
            }
        },
        handleNavbarChange () {
            const { scrollWidth, offsetWidth } = this.$el;
            if (offsetWidth < scrollWidth) {
                this.showMenu = true;
                this.menuBreakpoint = scrollWidth;
            } else if (offsetWidth >= scrollWidth && scrollWidth > this.menuBreakpoint) {
                this.showMenu = false;
            }
        },
        handleMenuItemSelect ({ to }) {
            return this.$router.push(to);
        }
    }
};
</script>

<style lang="scss" scoped>
.navbar {
    z-index: 2;
    background-color: $navbar-background-color;
    position: sticky;
    top: 0;
    height: 4rem;
    box-shadow: $navbar-box-shadow;
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-gap: 16px;

    &.show-menu {
        grid-template-columns: repeat(2, 1fr);
    }

    &-brand {
        padding: 0 0 0 2rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .brand--link {
            margin-right: 16px;

            &.disabled {
                pointer-events: none;
            }
        }

        .brand--logo {
            max-width: 148px;
            max-height: 28px;
            vertical-align: middle;
        }

        .divider {
            height: 22px;
            width: 1px;

            path {
                stroke: $gray-lighter-5;
                stroke-width: 2;
            }
        }

        .brand--product-name {
            font-size: 0.75em;
            font-weight: bold;
            line-height: 0.9rem;
            color: $navbar-details-color;
            padding-left: 1rem;
            cursor: default;
            white-space: nowrap;
        }
    }

    &-actions,
    &-switchers,
    &-logout {
        .action--link {
            text-decoration: none;
            color: $gray-primary;
        }
    }

    &-actions,
    &-logout {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        grid-gap: 32px;
        padding: 0 16px;

        .action--link {
            text-decoration: none;
            color: $gray-primary;

            &.disabled {
                pointer-events: none;
            }

            .pendo-button--link,
            .pendo-button--link:hover {
                text-decoration: none;
                color: $gray-primary;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &-switchers {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 8px;
        align-items: center;
        justify-content: end;
        padding: 0 2rem 0 0;

        .action--route-switcher {
            .pendo-button {
                background: $white;
                box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.21);
                transition: all 200ms;
                border: 1px solid $transparent;
            }

            &.is-active {
                .pendo-button {
                    box-shadow: none;
                    border: 1px solid $gray-lighter-6;
                }
            }
        }

        .action--sub-switcher {
            padding-left: 0;
            padding-right: 0;

            &--open {
                color: $gray-primary;
                display: block;
                transition: background-color 0.25s;

                &:hover {
                    background-color: $gray-lighter-6;
                }
            }

            &-button {
                width: 100%;
                background: $white;
                box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.21);

                &:hover {
                    background: $white;
                }

                &:active {
                    box-shadow: inset 0 0 0 1px $gray-lighter-6;
                }
            }
        }
    }
}
</style>
