import axios from 'axios';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import store from '@/state/store';

const SUB_ID_URL_PLACEHOLDER = '/_SID_/';
const SUB_NAME_URL_PLACEHOLDER = '/_SNAME_/';
const USER_ID_URL_PLACEHOLDER = '/_UID_/';
const USER_ID_PAYLOAD_PLACEHOLDER = '{{uid}}';

const client = axios.create({
    xsrfCookieName: 'PendoXSRFToken',
    xsrfHeaderName: 'X-Pendo-Xsrf-Token'
});

export function interceptRequest (config) {
    const configClone = cloneDeep(config);

    if (configClone.url.includes('/api/signup/user/resetpassword/')) {
        configClone.headers['x-adopt-v2'] = true;
    }

    if (configClone.url.includes('/api/signup/user/pwresetrequest')) {
        configClone.headers['x-adopt-v2'] = true;
    }

    if (configClone.url.includes('/api/signup/invitation/confirm/')) {
        configClone.headers['x-adopt-v2'] = true;
    }

    // inject subscription id
    if (configClone.url.includes(SUB_ID_URL_PLACEHOLDER)) {
        const subId = get(store, 'state.subscriptions.activeId', null);

        if (subId === null) {
            throw new Error('active subscription id not found.');
        }

        configClone.url = configClone.url.replace(SUB_ID_URL_PLACEHOLDER, `/${subId}/`);
    }

    // inject subscription name
    if (configClone.url.includes(SUB_NAME_URL_PLACEHOLDER)) {
        const subId = get(store, 'state.subscriptions.activeId', null);
        if (subId === null) {
            throw new Error('active subscription id not found.');
        }

        const subName = get(store, `state.subscriptions.map.${subId}.name`, null);
        if (subId === null) {
            throw new Error('active subscription name not found.');
        }

        configClone.url = configClone.url.replace(SUB_NAME_URL_PLACEHOLDER, `/${subName}/`);
    }

    // inject user id
    if (configClone.url.includes(USER_ID_URL_PLACEHOLDER)) {
        const userId = get(store, 'state.auth.user.id', null);

        if (userId === null) {
            throw new Error('current user id not found.');
        }

        configClone.url = configClone.url.replace(USER_ID_URL_PLACEHOLDER, `/${userId}/`);
    }

    if (store.getters['subscriptions/activeUsesV2Adopt']) {
        configClone.headers['x-adopt-v2'] = true;
    }

    // return early if there's no request body
    if (isEmpty(configClone.data)) {
        return configClone;
    }

    const payload = JSON.stringify(configClone.data);
    if (payload.includes(USER_ID_PAYLOAD_PLACEHOLDER)) {
        const userId = get(store, 'state.auth.user.id', null);

        if (userId === null) {
            throw new Error('current user id not found.');
        }

        const replaced = payload.replace(USER_ID_PAYLOAD_PLACEHOLDER, userId);

        configClone.data = JSON.parse(replaced);
    }

    return configClone;
}

export function interceptResponse (response) {
    return response;
}

export async function interceptError (error) {
    const { response, config } = error;

    if (!response) {
        return Promise.reject(error);
    }

    // 401 means we're already logged out, dont call the endpoint again
    const ignoreRoutes = ['/user/me', '/login', '/api/register', '/logout'];
    const routeShouldLogOut = !ignoreRoutes.find((route) => config.url.includes(route));
    if (response.status === 401 && routeShouldLogOut) {
        await store.dispatch('auth/logout', { postLogout: false });

        // NOTE: using router.push(path) here may (and has in the past) cause various issues.
        // it is safer and easier to just call location.reload
        window.location.reload();
    }

    const isUnauthorized = response.status === 401;
    const isRouteToRetryWithV2Header = config.url.includes('/user/me') || config.url.includes('/logout');
    const tryAdoptV2 = isRouteToRetryWithV2Header && isUnauthorized && !get(config.headers, 'x-adopt-v2');
    if (tryAdoptV2) {
        const configClone = cloneDeep(config);
        configClone.headers['x-adopt-v2'] = true;

        return client(configClone);
    }

    // only retry server errors, if the user did something dumb it needs to be handled by the callee
    if (response.status >= 500 && config.retry && config.retry > 0) {
        --config.retry;

        return client(config);
    }

    return Promise.reject(error);
}

client.interceptors.request.use(interceptRequest);
client.interceptors.response.use(interceptResponse, interceptError);

export default client;
