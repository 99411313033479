var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.activeUsesV2Adopt)?_c('div',[_c('manage-team-v1')],1):_vm._e(),(_vm.activeUsesV2Adopt)?_c('div',{staticClass:"user-list"},[_c('pendo-table',{directives:[{name:"pendo-loading",rawName:"v-pendo-loading:feather",value:(_vm.isFetchingUserList),expression:"isFetchingUserList",arg:"feather"}],staticClass:"user-list-table",attrs:{"data":_vm.userRows,"filters":_vm.filters,"columns":_vm.columns,"show-all-overflow":true,"row-class-name":_vm.tableRowClassName,"auto-height-offset":96,"default-sort":{
                prop: 'email',
                order: 'ascending'
            },"auto-height":"","title":"Users","row-key":"id"},scopedSlots:_vm._u([{key:"headerActions",fn:function(){return [_c('search',{attrs:{"placeholder":"Search users"},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})]},proxy:true},{key:"username",fn:function(ref){
            var row = ref.row;
return _c('div',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:(!_vm.canEdit(row) && _vm.getTooltip(row)),expression:"!canEdit(row) && getTooltip(row)"}]},[_c('pendo-button',{attrs:{"theme":"via","type":"link","disabled":!_vm.canEdit(row),"label":row.username,"data-cy":("username-" + (row.id) + "-link")},on:{"click":function($event){return _vm.loadEditUserOverlay(row)}}},[_vm._v(" "+_vm._s(row.username)+" ")])],1)}},{key:"actions",fn:function(ref){
            var row = ref.row;
return (row.id !== _vm.currentUser.id)?_c('div',{staticClass:"user-list-table-buttons",attrs:{"data-user-id":row.id}},[(_vm.canEdit(row))?_c('pendo-button',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:({ content: 'Edit User', delay: { show: 300, hide: 0 }, arrow: true }),expression:"{ content: 'Edit User', delay: { show: 300, hide: 0 }, arrow: true }"}],attrs:{"theme":"via","type":"tertiary","size":"mini","icon":"edit"},on:{"click":function($event){return _vm.loadEditUserOverlay(row)}}}):_vm._e(),(!row.hasLoggedIn)?_c('pendo-button',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:({
                        content: row.resendInviteSuccess
                            ? 'Invite Sent!'
                            : row.resendInviteError
                                ? 'Try again'
                                : 'Resend Invite',
                        delay: { show: 300, hide: 0 },
                        arrow: true
                    }),expression:"{\n                        content: row.resendInviteSuccess\n                            ? 'Invite Sent!'\n                            : row.resendInviteError\n                                ? 'Try again'\n                                : 'Resend Invite',\n                        delay: { show: 300, hide: 0 },\n                        arrow: true\n                    }"}],staticClass:"user-list-table-buttons--resend-invite",class:{ 'is-success': row.resendInviteSuccess },attrs:{"icon":row.resendInviteSuccess ? 'check' : row.resendInviteError ? 'x' : 'send',"loading":row.isResendingInvite,"data-cy":"resend-invite","theme":"via","type":"tertiary","size":"mini"},on:{"click":function($event){return _vm.resendInvite(row)}}}):_vm._e(),_c('pendo-button',{directives:[{name:"pendo-tooltip",rawName:"v-pendo-tooltip",value:({ content: 'Remove User', delay: { show: 300, hide: 0 }, arrow: true }),expression:"{ content: 'Remove User', delay: { show: 300, hide: 0 }, arrow: true }"}],staticClass:"user-list-table-buttons--remove-user",attrs:{"data-cy":"remove-user","theme":"via","type":"tertiary","size":"mini","icon":"trash-2"},on:{"click":function($event){return _vm.confirmRemoveUser(row)}}})],1):_vm._e()}}],null,true)},[_c('div',{staticClass:"user-list-table--empty",attrs:{"slot":"empty"},slot:"empty"},[_c('span',{staticClass:"empty-text"},[_vm._v(" Invite users to join your team! ")])])]),_c('user-overlay',{attrs:{"user":_vm.selectedUser},on:{"closed":function($event){_vm.selectedUser = {}},"remove-user":_vm.confirmRemoveUser}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }