<template>
    <div>
        <pendo-multiselect
            v-if="isDropdown"
            :options="utilityOptions"
            :popper-options="{ class: 'guide-utilities-panel', placement: 'bottom-end' }"
            :min-menu-width="180"
            class="guide-utilities"
            stateless
            value-key="action"
            @select="handleNextAction"
            @close="$emit('close')">
            <template #trigger="{ toggleMenu }">
                <slot :toggle-menu="toggleMenu" />
            </template>
            <pendo-icon-option
                slot="option"
                slot-scope="{ option }"
                :option="option" />
        </pendo-multiselect>
        <pendo-actions-cell
            v-if="!isDropdown"
            v-pendo-loading:material="{
                loading: copying,
                spinnerProps: {
                    size: 14,
                    background: '#f8f8f9'
                }
            }"
            :row="guide"
            :actions="tableRowActions"
            @clone="cloneGuide"
            @copy="copyGuide"
            @copyToAccount="openGuideCopyModal"
            @clear="confirmClearGuideData"
            @preview="previewGuide"
            @delete="confirmDeleteGuide" />
    </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters, mapState } from 'vuex';
import {
    PendoNotification,
    PendoActionsCell,
    PendoLoading,
    PendoMultiselect,
    PendoIconOption,
    PendoTooltip
} from '@pendo/components';
import { getSegmentsUsingGuide } from '@/utils/guides';
import { copyGuidesV1 } from '@/utils/copy-guides';
import get from 'lodash/get';

export default {
    name: 'GuideUtilities',
    components: {
        PendoActionsCell,
        PendoIconOption,
        PendoMultiselect
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    props: {
        guide: {
            type: Object,
            required: true
        },
        isDropdown: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            copying: false
        };
    },
    computed: {
        ...mapState({
            canPreviewPublicGuide: (state) => state.guides.canPreviewPublicGuide,
            canPreviewNonPublicGuide: (state) => state.guides.canPreviewNonPublicGuide,
            activeGuideId: (state) => state.guides.activeId
        }),
        ...mapGetters({
            activeAccount: 'subscriptions/activeAccount',
            segmentsListByGroup: 'filters/segmentsListByGroup',
            isGuideValidationEnabled: 'subscriptions/isGuideValidationEnabled',
            isAdoptV2Sub: 'subscriptions/activeUsesV2Adopt',
            isTrainingSub: 'subscriptions/activeIsTrainingSubscription',
            hasMultipleTrainingSubs: 'subscriptions/hasMultipleTrainingSubs',
            subscriptions: 'subscriptions/listAll'
        }),
        canCopyGuides () {
            return this.isTrainingSub && this.hasMultipleTrainingSubs;
        },
        canPreview () {
            if (!get(this.guide, 'steps.length')) return false;

            return this.guide.state === 'public' ? this.canPreviewPublicGuide : this.canPreviewNonPublicGuide;
        },
        affectedSegments () {
            return getSegmentsUsingGuide(this.guide.id, this.segmentsListByGroup.customList);
        },
        tableRowActions () {
            const actions = [
                {
                    type: 'delete',
                    icon: 'trash-2',
                    tooltip: 'Delete'
                }
            ];

            if (this.canPreview) {
                actions.push({
                    type: 'preview',
                    icon: 'play-circle',
                    tooltip: 'Preview'
                });
            }

            if (this.isAdoptV2Sub) {
                actions.splice(0, 0, {
                    type: 'clone',
                    icon: 'copy',
                    tooltip: 'Copy'
                });
            } else {
                actions.splice(0, 0, {
                    type: 'copy',
                    icon: 'copy',
                    tooltip: 'Copy'
                });
            }

            if (this.canCopyGuides) {
                actions.splice(0, 0, {
                    type: 'copyToAccount',
                    icon: 'layers',
                    tooltip: 'Copy to Account'
                });
            }

            return actions;
        },
        utilityOptions () {
            const guideContentValidationOption = this.isGuideValidationEnabled
                ? {
                    label: 'Validation Content',
                    action: 'validate',
                    icon: {
                        type: 'check'
                    }
                }
                : null;

            return [
                this.canCopyGuides && {
                    label: 'Copy Guide to Account',
                    action: 'copyToAccount',
                    icon: {
                        type: 'layers'
                    }
                },
                {
                    label: 'Copy Guide',
                    action: 'copy',
                    icon: {
                        type: 'copy'
                    }
                },
                {
                    label: 'Clear Guide Data',
                    action: 'clear',
                    icon: {
                        type: 'eye-off'
                    }
                },
                {
                    label: 'Delete Guide',
                    action: 'delete',
                    icon: {
                        type: 'trash-2'
                    }
                },
                {
                    label: 'Preview Guide',
                    action: 'preview',
                    icon: {
                        type: 'play-circle'
                    },
                    disabled: !this.canPreview
                },
                guideContentValidationOption
            ].filter(Boolean);
        }
    },
    methods: {
        ...mapActions({
            _deleteGuide: 'guides/delete',
            _clearGuide: 'guides/clearViewData',
            _clone: 'guides/clone',
            updateGuide: 'guides/update',
            getBuildingBlocks: 'guides/getBuildingBlocks',
            updateActiveSegmentId: 'filters/updateActiveSegmentId'
        }),
        ...mapMutations({
            setActiveGuideById: 'guides/setActiveById',
            addGuideToStore: 'guides/setUpdate'
        }),
        handleNextAction ({ action }) {
            return {
                copy: this.isAdoptV2Sub ? this.cloneGuide : this.copyGuide,
                clear: this.confirmClearGuideData,
                delete: this.confirmDeleteGuide,
                preview: this.previewGuide,
                validate: this.showValidationContent,
                copyToAccount: this.openGuideCopyModal
            }[action]();
        },
        async cloneGuide () {
            try {
                this.copying = true;
                if (!this.isDropdown) await this.getBuildingBlocks({ guide: this.guide });

                await this._clone({ guide: this.guide });

                this.$router.push({ name: 'guideSettings', params: { id: this.activeGuideId } });
                this.copying = false;
            } catch (error) {
                this.copying = false;
                PendoNotification({
                    type: 'error',
                    title: 'Something went wrong!',
                    message: 'Please try again or contact support if the issue persists.',
                    duration: 3000
                });
            }
        },
        async copyGuide () {
            const { id, audienceUiHint, audience } = this.guide;
            try {
                this.copying = true;
                const [newGuide] = await copyGuidesV1({
                    guideIds: [id],
                    toAccount: this.activeAccount.id,
                    removeSegment: true
                });
                newGuide.audienceUiHint = audienceUiHint;
                newGuide.audience = audience;
                newGuide.name = `Copy of ${newGuide.name}`;
                this.addGuideToStore({ guide: newGuide });
                await this.updateGuide({ guide: newGuide, setActive: false });
                this.copying = false;
                const notification = PendoNotification({
                    type: 'success',
                    title: 'Guide successfully copied!',
                    dangerouslyUseHTMLString: true,
                    message: '<a style="cursor: pointer" id="goToDetailsLink">View Guide Details</a>',
                    duration: 5000
                });
                // add click listener to link in PendoNotification so that we can leverage $router
                const guideDetailsLink = document.getElementById('goToDetailsLink');
                if (guideDetailsLink) {
                    guideDetailsLink.addEventListener('click', () => {
                        notification.close();
                        this.setActiveGuideById({ id: newGuide.id });
                        this.$router.push({ name: 'guideSettings', params: { id: newGuide.id } });
                    });
                }
            } catch (error) {
                this.copying = false;
                PendoNotification({
                    type: 'error',
                    title: 'Something went wrong!',
                    message: 'Please try again or contact support if the issue persists.',
                    duration: 5000
                });
            }
        },
        confirmClearGuideData () {
            const { clearGuideData } = this;

            this.$modal.show('via-modal', {
                title: 'Clear & Reset Guide Data?',
                component: 'ClearGuideDataModal',
                componentProps: {
                    clearFn: clearGuideData
                }
            });
        },
        confirmDeleteGuide () {
            const { affectedSegments, deleteGuide } = this;
            const title = affectedSegments.length ? 'Segment Dependency Detected' : 'Delete Guide?';

            this.$modal.show('via-modal', {
                title,
                component: 'ItemDeleteWarning',
                componentProps: {
                    kind: 'guide',
                    impactedKind: 'segment',
                    impactedItems: affectedSegments,
                    deleteFn: deleteGuide
                }
            });
        },
        previewGuide () {
            const [step] = this.guide.steps;

            this.$modal.show('via-modal', {
                title: 'Preview Guide',
                component: 'LaunchPreviewModal',
                componentProps: {
                    guideId: step.guideId,
                    stepId: step.id
                }
            });
        },
        showValidationContent () {
            const { guide } = this;

            this.$modal.show('via-modal', {
                title: 'Guide Content Validation',
                component: 'GuideContentValidation',
                componentProps: { guide }
            });
        },
        async clearGuideData () {
            try {
                await this._clearGuide({ guide: this.guide });
                PendoNotification({
                    type: 'success',
                    title: 'Guide Data Successfully Reset',
                    duration: 5000
                });
            } catch (error) {
                PendoNotification({
                    type: 'error',
                    title: 'Something went wrong!',
                    message: 'Please try again or contact support if the issue persists.',
                    duration: 5000
                });
            }
        },
        async deleteGuide (deleteBreaksSegment) {
            await this._deleteGuide({ guide: this.guide });
            this.$modal.hide('via-modal');
            this.$router.push('/guides');

            if (deleteBreaksSegment) {
                this.updateActiveSegmentId({ id: 'everyone' });
            }
        },
        openGuideCopyModal () {
            this.$emit('openGuideCopyModal', this.guide);
        }
    }
};
</script>

<style lang="scss">
.guide-utilities {
    &--buttons {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 8px;
    }
}
</style>
