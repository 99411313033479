<template>
    <div>
        <div class="pendo-modal__body">
            <div class="clear-data-modal--message">
                This will clear all data associated with the guide. In addition, visitors who close the guide may see it
                again if it is automatically activated.
            </div>
            <div class="clear-data-modal--message-confirm">
                Confirm by typing in <span class="clear-data-modal--message-red">clear-guide-data</span> below.
            </div>
            <pendo-input v-model="textInput" />
        </div>
        <div class="pendo-modal__footer">
            <pendo-button
                type="secondary"
                theme="via"
                label="Cancel"
                @click.stop="closeModal" />
            <pendo-button
                :disabled="!validateInput"
                label="Clear Data"
                theme="via"
                type="danger"
                @click.stop="clearData" />
        </div>
    </div>
</template>

<script>
import { PendoButton, PendoInput } from '@pendo/components';

export default {
    name: 'ClearGuideDataModal',
    components: {
        PendoButton,
        PendoInput
    },
    props: {
        clearFn: {
            type: Function,
            required: true
        }
    },
    data () {
        return {
            textInput: ''
        };
    },
    computed: {
        validateInput () {
            return this.textInput === 'clear-guide-data';
        }
    },
    methods: {
        closeModal () {
            this.$modal.hide('via-modal');
        },
        clearData () {
            this.clearFn();
            this.$modal.hide('via-modal');
        }
    }
};
</script>

<style lang="scss">
.clear-data-modal--message {
    margin-bottom: 12px;
}

.clear-data-modal--message-confirm {
    font-weight: 600;
    margin-bottom: 8px;
}

.clear-data-modal--message-red {
    color: $red-error;
}
</style>
