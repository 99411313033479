import prettyMilliseconds from 'pretty-ms';
import { convertToSubscriptionTimezone, DATE_FORMAT } from '@/utils/moment';
import get from 'lodash/get';

const dateFormatColumns = ['firstvisit', 'lastvisit', 'browsertime'];

export const DATE_COLUMN_WIDTH = 200;

export function getColumnFormatSchema (column) {
    const schemaIsTime = column.schema === 'time';
    const isVisitorCustomMetadata = column.prop.startsWith('visitor_custom');
    const isDateFormatMetadata = dateFormatColumns.some((metadata) => column.prop.toLowerCase().includes(metadata));
    const isDate = schemaIsTime && (isVisitorCustomMetadata || isDateFormatMetadata);

    return isDate ? 'date' : column.schema;
}

export function getColumnWidth (column) {
    let width;
    if (getColumnFormatSchema(column) === 'date') width = DATE_COLUMN_WIDTH;

    return width;
}

export function formatDataByType (type, value) {
    switch (type) {
        case 'boolean':
            return value && String(value);
        case 'list':
            return value && `[ ${value.join(', ')} ]`;
        case 'object':
            return value && `[ ${value.join(', ')} ]`;
        case 'string':
            return value;
        case 'number':
            return value;
        case 'integer':
            return (
                value &&
                `${value.toLocaleString(undefined, {
                    maximumFractionDigits: 0
                })}`
            );
        case 'steps':
            return value && value.length;
        case 'time':
            return value && prettyMilliseconds(value, { secondsDecimalDigits: 0 });
        case 'date':
            if (value === 0) value = null;

            return value && convertToSubscriptionTimezone(value).format(DATE_FORMAT.full);
    }

    return value;
}

export function rowFormatter (row, column) {
    const columnVal = get(row, column.prop);
    const schema = getColumnFormatSchema(column);

    return formatDataByType(schema, columnVal);
}
