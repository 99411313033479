var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"integration-key-list"},[(_vm.activeUsesV2Adopt && !_vm.usesMultiApp)?_c('pendo-card',{staticClass:"integration-app-info",attrs:{"title":"Application Information","body-min-height":"60px","data-cy":"integration-app-info-card"}},[_c('div',{staticClass:"app-id"},[_c('div',{staticClass:"app-id--label"},[_vm._v(" Application ID ")]),_vm._v(" "+_vm._s(_vm.activeAppId)+" ")])]):_vm._e(),_c('pendo-table',{ref:"integrationKeyTable",staticClass:"integration-key-table",attrs:{"loading":_vm.isFetchingKeys,"data":_vm.integrationKeys,"columns":_vm.columns,"auto-height-offset":96,"default-sort":{
            prop: 'integrationkey',
            order: 'ascending'
        },"auto-height":"","title":"API Access"},scopedSlots:_vm._u([{key:"headerRight",fn:function(){return [(_vm.integrationKeys.length)?_c('pendo-button',{attrs:{"loading":_vm.isGeneratingKey,"type":"link","theme":"via","label":"Generate Integration Key","prefix-icon":"plus"},on:{"click":_vm.addIntegrationKey}}):_vm._e()]},proxy:true},{key:"integrationkey",fn:function(ref){
        var row = ref.row;
return [_c('div',{staticClass:"integration-key-table--copy"},[_c('span',[_vm._v(" "+_vm._s(row.integrationkey)+" ")]),_c('pendo-icon',{directives:[{name:"pendo-clipboard",rawName:"v-pendo-clipboard",value:(row.integrationkey),expression:"row.integrationkey"}],attrs:{"size":14,"type":"clipboard"}})],1)]}},{key:"label",fn:function(ref){
        var row = ref.row;
return [(_vm.activeUsesV2Adopt)?_c('span',[_vm._v(" "+_vm._s(row.label)+" ")]):_c('pendo-editable-content',{attrs:{"value":row.label,"input-props":{ size: 'small' },"before-exit":_vm.onLabelChange,"allow-empty":"","empty-text":"Click to add description"},on:{"enterEditMode":function($event){_vm.currentlyEditingRow = row}},scopedSlots:_vm._u([{key:"append",fn:function(ref){
        var confirm = ref.confirm;
        var cancel = ref.cancel;
        var pending = ref.pending;
return [_c('pendo-button',{attrs:{"loading":pending,"theme":"via","icon":"check","size":"small","type":"primary"},on:{"click":confirm}}),_c('pendo-button',{attrs:{"theme":"app","size":"small","icon":"x","type":"secondary"},on:{"click":cancel}})]}}],null,true)})]}},{key:"readwrite",fn:function(ref){
        var row = ref.row;
return [_c('pendo-icon',{attrs:{"size":16,"stroke-width":3,"type":row.accessFlags.rw ? 'check' : 'x'}})]}},{key:"actions",fn:function(ref){
        var row = ref.row;
return [_c('pendo-actions-cell',{attrs:{"row":row,"actions":[
                    {
                        type: 'delete',
                        icon: 'trash-2',
                        tooltip: 'Delete'
                    }
                ]},on:{"delete":_vm.showConfirmDeleteModal}})]}}])},[_c('div',{staticClass:"integration-key-table--empty",attrs:{"slot":"empty"},slot:"empty"},[_c('pendo-empty-state',{attrs:{"icon":{ type: 'box', size: 36, 'stroke-width': 1.5 },"description":"You haven’t generated an integration key yet."}},[_c('div',{staticClass:"integration-key-table--empty-actions",attrs:{"slot":"actions"},slot:"actions"},[_c('pendo-button',{staticClass:"integration-key-generate-button",attrs:{"loading":_vm.isGeneratingKey,"type":"primary","theme":"via","label":"Generate Integration Key","prefix-icon":"plus"},on:{"click":_vm.addIntegrationKey}})],1)])],1)]),(_vm.activeUsesV2Adopt && _vm.usesMultiApp)?_c('pendo-table',{staticClass:"application-info-table",attrs:{"title":"Application Information","height":"500px","csv-download":"","data":_vm.listAllForActiveSubscription,"columns":_vm.applicationIdColumns,"filters":_vm.applicationTableFilters,"default-sort":{
            prop: 'displayName',
            order: 'ascending'
        }},scopedSlots:_vm._u([{key:"headerActions",fn:function(){return [_c('search',{staticClass:"application-table-search",attrs:{"placeholder":"Search Applications..."},model:{value:(_vm.applicationTableSearchValue),callback:function ($$v) {_vm.applicationTableSearchValue=$$v},expression:"applicationTableSearchValue"}})]},proxy:true}],null,false,494799125)}):_vm._e(),_c('pendo-modal',{staticClass:"delete-integration-key-modal",attrs:{"visible":_vm.isConfirmDeleteModalVisible,"type":"confirmation","title":"Delete Integration Key?","message":"Are you sure you want to delete this integration? Data will no longer be pulled to any sources depending on this integration key."},on:{"close":function($event){_vm.isConfirmDeleteModalVisible = false}}},[_c('div',{staticClass:"delete-integration-key-modal__footer",attrs:{"slot":"footer"},slot:"footer"},[_c('pendo-button',{staticClass:"cancel-delete-key",attrs:{"theme":"via","type":"secondary","label":"Cancel"},on:{"click":function($event){_vm.isConfirmDeleteModalVisible = false}}}),_c('pendo-button',{staticClass:"delete-integration-key delete-integration-key-confirm",attrs:{"loading":_vm.isDeletingKey,"theme":"via","type":"danger","label":"Delete Key"},on:{"click":_vm.onConfirmDeleteKey}})],1)]),(_vm.isAddKeyModalVisible)?_c('add-integration-key-modal',{on:{"close":function($event){_vm.isAddKeyModalVisible = false},"createKey":_vm.generateKey}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }