<template>
    <pendo-card
        v-pendo-loading:feather="isFetchingFeatureList"
        title="Feature Use By Visitor"
        class="feature-use-by-visitor">
        <template slot="filters">
            <active-segment-tag />
            <active-date-range-tag />
        </template>
        <div class="feature-use-by-visitor__chart">
            <pendo-empty-state
                v-if="!isFetchingFeatureList && !featureList.length"
                :icon="{ type: 'feature', size: 32, 'stroke-width': 1.5 }"
                title="Features not Found"
                description="Try clearing filters. Features without click data will not appear."
                class="feature-use-by-visitor__empty" />
            <div
                ref="featureUseByVisitor"
                class="pendo-highcharts-container" />
        </div>
    </pendo-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { PendoCard, PendoEmptyState, PendoLoading } from '@pendo/components';
import { pluralize } from '@/utils/filters';
import Highcharts, { SCATTER_CHART_BASE, chartColors } from '@/utils/highcharts';
import ActiveSegmentTag from '@/components/filters/ActiveSegmentTag';
import ActiveDateRangeTag from '@/components/filters/ActiveDateRangeTag';

export default {
    name: 'FeatureUseByVisitor',
    components: {
        ActiveSegmentTag,
        ActiveDateRangeTag,
        PendoCard,
        PendoEmptyState
    },
    directives: {
        PendoLoading
    },
    data () {
        return {
            chart: null,
            chartConfig: null,
            isInitialLoading: true
        };
    },
    computed: {
        ...mapGetters({
            allApps: 'apps/appMapForActiveSubscription',
            featureList: 'features/listWithAnalytics',
            usesMultiApp: 'subscriptions/usesMultiApp'
        }),
        ...mapState({
            isFetchingFeatureList: (state) => state.features.isFetchingWithAnalytics
        }),
        appIds () {
            return Object.keys(this.allApps);
        },
        seriesData () {
            const seriesData = {};
            this.featureList.forEach(({ app, displayName, numVisitors, numEvents }) => {
                if (app && !seriesData[app.id]) {
                    seriesData[app.id] = {
                        data: [],
                        name: app.displayName,
                        marker: {
                            symbol: 'circle'
                        },
                        showInLegend: this.usesMultiApp
                    };
                    if (this.usesMultiApp) {
                        seriesData[app.id].color =
                            chartColors[this.appIds.indexOf(app.id.toString()) % chartColors.length];
                    }
                }
                const name = displayName;
                const x = numVisitors;
                const y = Math.round(numEvents / numVisitors);
                if (app) seriesData[app.id].data.push({ name, x, y });
            });

            return Object.values(seriesData);
        }
    },
    watch: {
        featureList: {
            handler () {
                if (this.isFetchingFeatureList || !this.chart) {
                    return;
                }

                const diff = this.chart.series.length - this.seriesData.length;
                if (diff > 0) {
                    for (let i = this.chart.series.length; i > this.seriesData.length; i--) {
                        this.chart.series[i - 1].remove(true);
                    }
                } else if (diff < 0) {
                    for (let i = this.chart.series.length; i < this.seriesData.length; i++) {
                        this.chart.addSeries({});
                    }
                }

                this.chart.update({
                    series: this.seriesData
                });
            },
            deep: true
        }
    },
    async created () {
        await this.fetchFeatures();
        this.chartConfig = this.getChartConfig();
        this.chart = Highcharts.chart(this.$refs.featureUseByVisitor, this.chartConfig);
    },
    methods: {
        ...mapActions({
            fetchFeatures: 'features/fetchWithAnalytics'
        }),
        getChartConfig () {
            const config = { ...SCATTER_CHART_BASE };

            config.series = this.seriesData;
            config.chart.height = 316;
            config.tooltip.pointFormatter = function () {
                const { name, x, y } = this;

                return `<label>${name}</label><br/>Used by <b>${x}</b> ${pluralize(
                    'Visitor',
                    x
                )}<br/><b>${y}</b> ${pluralize('Click', y)} per Visitor`;
            };
            config.yAxis[0].title.text = 'Average Clicks Per Visitor';
            config.xAxis[0].title.text = 'Number of Visitors';

            return config;
        }
    }
};
</script>
<style lang="scss">
.feature-use-by-visitor {
    .feature-use-by-visitor__chart {
        position: relative;
    }

    .feature-use-by-visitor__empty {
        z-index: 1;
    }
}
</style>
