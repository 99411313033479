export const maxDurationOptions = [
    {
        label: '1 Hour',
        value: 3600,
        icon: {
            type: 'clock'
        }
    },
    {
        label: '3 Hours',
        value: 10800,
        icon: {
            type: 'clock'
        }
    },
    {
        label: '8 Hours',
        value: 28800,
        icon: {
            type: 'clock'
        }
    },
    {
        label: '12 Hours',
        value: 43200,
        icon: {
            type: 'clock'
        }
    },
    {
        label: '24 Hours',
        value: 86400,
        icon: {
            type: 'clock'
        }
    }
];
