import Vue from 'vue';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import http from '@/utils/http';
import { addAppToEntityList } from '@/utils/apps';
import { getPageList, getPageActivity, getSinglePageActivity } from '@/aggregations/page-activity';
import { filterUnsharedResources } from '@/utils/modules';
import { isCancel } from 'axios';

let fetchPromise;

export function getInitialState () {
    return {
        isFetching: false,
        isCreatingPage: false,
        isUpdatingPage: false,
        isDeletingPage: false,
        isFetchingWithAnalytics: false,
        map: {},
        error: null,
        mapWithAnalytics: {},
        pagesListColumns: null,
        topPagesListColumns: null,
        aggCancel: null
    };
}

export const state = getInitialState();

export const mutations = {
    setMap (state, { map }) {
        Vue.set(state, 'map', map);
    },
    setMapAtKey (state, { key }) {
        Vue.set(state.map, key.id, key);
    },
    deleteMapAtKey (state, { key }) {
        Vue.delete(state.map, key.id);
    },
    setMapWithAnalytics (state, { mapWithAnalytics }) {
        state.mapWithAnalytics = mapWithAnalytics;
    },
    setMapWithAnalyticsAtKey (state, { key }) {
        Vue.set(state.mapWithAnalytics, key.id, key);
    },
    deleteMapWithAnalyticsAtKey (state, { key }) {
        Vue.delete(state.mapWithAnalytics, key.id);
    },
    setFetching (state, { isFetching }) {
        state.isFetching = isFetching;
    },
    setCreatingPage (state, { isCreatingPage }) {
        state.isCreatingPage = isCreatingPage;
    },
    setUpdatingPage (state, { isUpdatingPage }) {
        state.isUpdatingPage = isUpdatingPage;
    },
    setDeletingPage (state, { isDeletingPage }) {
        state.isDeletingPage = isDeletingPage;
    },
    setFetchingWithAnalytics (state, { isFetchingWithAnalytics }) {
        state.isFetchingWithAnalytics = isFetchingWithAnalytics;
    },
    setError (state, { error }) {
        state.error = error;
    },
    reset (state) {
        Object.assign(state, getInitialState());
    },
    setPagesListColumns (state, { pagesListColumns }) {
        state.pagesListColumns = pagesListColumns;
    },
    setTopPagesListColumns (state, { topPagesListColumns }) {
        state.topPagesListColumns = topPagesListColumns;
    },
    setAggCancel (state, { aggCancel }) {
        state.aggCancel = aggCancel;
    }
};

export const actions = {
    async fetch ({ commit, state, rootGetters }, { noCache = false } = {}) {
        if (state.isFetching) {
            await fetchPromise;
        }

        if (!isEmpty(state.map) && !noCache) {
            return;
        }

        commit('setFetching', { isFetching: true });

        const usesMultiApp = rootGetters['subscriptions/usesMultiApp'];

        if (usesMultiApp) {
            fetchPromise = fetchAllPagesForSubscription();
        } else {
            fetchPromise = getPageList({
                appId: rootGetters['apps/activeId']
            });
        }

        const list = await fetchPromise;
        const appMapForActiveSubscription = rootGetters['apps/appMapForActiveSubscription'];
        const pagesForAvailableApps = list.filter((page) => !!appMapForActiveSubscription[page.appId]);

        const displayNameFormattedList = pagesForAvailableApps.map((page) => {
            const displayName = get(page, 'trainingSettings.displayName', page.name);
            page.displayName = displayName;

            return page;
        });

        commit('setMap', { map: keyBy(displayNameFormattedList, 'id') });
        commit('setFetching', { isFetching: false });
    },
    async fetchWithAnalytics ({ commit, state, rootState, rootGetters }, { noCache = false } = {}) {
        if (state.aggCancel) {
            state.aggCancel.abort();
        }

        if (!isEmpty(state.mapWithAnalytics) && !noCache) {
            return;
        }

        commit('setAggCancel', { aggCancel: new AbortController() });
        commit('setFetchingWithAnalytics', { isFetchingWithAnalytics: true });

        const timeSeries = {
            ...rootGetters['filters/activeTimeSeries'],
            period: 'dayRange'
        };

        const { activeSegmentId, appIdsFilter } = rootState.filters;
        const usesMultiApp = rootGetters['subscriptions/usesMultiApp'];
        const appId = usesMultiApp ? appIdsFilter : rootGetters['apps/activeId'];

        let list = [];

        try {
            list = await getPageActivity({
                appId,
                timeSeries,
                segmentId: activeSegmentId,
                signal: get(state, 'aggCancel.signal')
            });
            commit('setFetchingWithAnalytics', { isFetchingWithAnalytics: false });
        } catch (err) {
            if (!isCancel(err)) {
                commit('setFetchingWithAnalytics', { isFetchingWithAnalytics: false });
            }
        }
        const appMapForActiveSubscription = rootGetters['apps/appMapForActiveSubscription'];
        const pagesForAvailableApps = list.slice().filter((page) => !!appMapForActiveSubscription[page.appId]);
        const displayNameFormattedList = pagesForAvailableApps.map((page) => {
            const displayName = get(page, 'trainingSettings.displayName', page.name);
            page.displayName = displayName;

            return page;
        });

        commit('setMapWithAnalytics', { mapWithAnalytics: keyBy(displayNameFormattedList, 'id') });
    },

    async fetchSinglePageWithAnalytics ({ commit, rootState, rootGetters }, { page }) {
        const { pageId } = page;
        const displayName = page.displayName || get(page, 'trainingSettings.displayName', page.name);
        const timeSeries = {
            ...rootGetters['filters/activeTimeSeries'],
            period: 'dayRange'
        };

        const { activeSegmentId } = rootState.filters;
        const rows = await getSinglePageActivity({
            pageId,
            appId: rootGetters['apps/activeId'],
            timeSeries,
            segmentId: activeSegmentId
        });

        const pageWithAnalytics = { id: pageId, displayName, ...page, ...rows[0] };

        commit('setMapWithAnalyticsAtKey', { key: pageWithAnalytics });
        commit('setMapAtKey', { key: pageWithAnalytics });
    },

    fetchUpdatesForFilterChange ({ dispatch, rootGetters }) {
        const clearPagesCache = !rootGetters['subscriptions/usesMultiApp'];
        dispatch('fetch', { noCache: clearPagesCache });
        dispatch('fetchWithAnalytics', { noCache: true });
    },

    async deleteCustomChildPage ({ commit }, { customPage }) {
        try {
            await deleteCustomChildPage(customPage.id);
            commit('deleteMapAtKey', { key: customPage });
            commit('deleteMapWithAnalyticsAtKey', { key: customPage });
        } catch (err) {
            throw err;
        }
    },

    async createCustomPage ({ dispatch, commit }, { customPage }) {
        commit('setError', { err: null });
        commit('setCreatingPage', { isCreatingPage: true });

        try {
            await createCustomPage(customPage);
            dispatch('fetch', { noCache: true });
        } catch (error) {
            commit('setError', { error });
        }

        commit('setCreatingPage', { isCreatingPage: false });
    },

    async updateCustomPage ({ dispatch, commit }, { customPage }) {
        commit('setError', { err: null });
        commit('setUpdatingPage', { isUpdatingPage: true });

        try {
            await updateCustomPage(customPage);
            dispatch('fetch', { noCache: true });
            dispatch('fetchWithAnalytics', { noCache: true });
        } catch (error) {
            commit('setError', { error });
        }

        commit('setUpdatingPage', { isUpdatingPage: false });
    },

    async deleteCustomPage ({ dispatch, commit }, { customPage }) {
        commit('setError', { err: null });
        commit('setDeletingPage', { isDeletingPage: true });

        try {
            await deleteCustomPage(customPage);
            dispatch('fetch', { noCache: true });
            dispatch('fetchWithAnalytics', { noCache: true });
        } catch (error) {
            commit('setError', { error });
        }

        commit('setDeletingPage', { isDeletingPage: false });
    }
};

export const getters = {
    pageById: (state) => (id) => get(state, `map['${id}']`, null),
    list (state, getters, rootState, rootGetters) {
        const filteredResources = filterUnsharedResources(state.map);

        return addAppToEntityList(filteredResources, rootGetters['apps/appMapForActiveSubscription']);
    },
    listAll (state, getters, rootState, rootGetters) {
        return addAppToEntityList(
            Object.values(state.mapWithAnalytics),
            rootGetters['apps/appMapForActiveSubscription']
        );
    },
    listContainsParentPage (state, getters) {
        return getters.list.some((page) => {
            return page.allowChildPages;
        });
    },
    listWithAnalytics (state, getters, rootState, rootGetters) {
        const filteredResources = filterUnsharedResources(state.mapWithAnalytics);

        return addAppToEntityList(filteredResources, rootGetters['apps/appMapForActiveSubscription']);
    }
};

export function createCustomPage (customPage, subId = null) {
    const { name, appId, rules } = customPage;

    if (!subId) {
        return http.post('/api/s/_SID_/page', { name, rules, appId }).then((res) => res.data);
    }

    return http.post(`/api/s/${subId}/page`, { name, rules, appId }).then((res) => res.data);
}

function deleteCustomPage (customPage) {
    const { pageId, appId } = customPage;

    return http.delete(`/api/s/_SID_/page/${pageId}`, { appId }).then((res) => res.data);
}

function updateCustomPage (customPage) {
    const { appId, pageId, name, rules } = customPage;

    return http
        .put(`/api/s/_SID_/page/${pageId}`, {
            appId,
            rules,
            ...(name && { name })
        })
        .then((res) => res.data);
}

export function createCustomChildPage (customPage) {
    const { parentPageId, parameter, displayName } = customPage;

    return http
        .post('/api/s/_SID_/childpage', {
            parentPageId,
            parameter,
            displayName
        })
        .then((res) => res.data);
}

export function editCustomPageDisplayName (customPageId, displayName) {
    return http
        .put(`/api/s/_SID_/childpage/${customPageId}`, {
            displayName
        })
        .then((res) => res.data);
}

export function deleteCustomChildPage (customPageId) {
    return http.delete(`/api/s/_SID_/childpage/${customPageId}`).then((res) => res.data);
}

export function fetchAllPagesForSubscription () {
    return http.get('/api/s/_SID_/page?expand=*').then((res) => res.data);
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
