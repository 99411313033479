<template>
    <div class="set-password">
        <h2 class="set-password--header">
            {{ heading }}
        </h2>
        <div class="set-password--description">
            Passwords must be at least 12 characters long, and meet a minimum of "good" strength.
        </div>
        <pendo-form
            :form-defaults="model"
            :form-ref="formRef">
            <pendo-form-item
                :rules="rules.password"
                label="Password"
                prop="password">
                <pendo-input
                    v-model.trim="model.password"
                    type="password"
                    show-password-strength
                    class="set-adopt-password--input"
                    @score="handlePasswordStrengthChange" />
            </pendo-form-item>
            <pendo-form-item
                :rules="rules.confirmPassword"
                label="Confirm Password"
                prop="confirmPassword">
                <pendo-input
                    v-model.trim="model.confirmPassword"
                    type="password"
                    class="set-password--confirm-input"
                    @keyup.enter.native="setPassword" />
            </pendo-form-item>
        </pendo-form>
        <div class="content-footer">
            <pendo-button
                :loading="isPending"
                :disabled="disableSubmit"
                type="primary"
                theme="via"
                block
                :label="buttonLabel"
                @click="setPassword" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { PendoButton, PendoInput, PendoForm, PendoFormItem } from '@pendo/components';

export default {
    name: 'SetAdoptPasswordForm',
    components: {
        PendoButton,
        PendoInput,
        PendoForm,
        PendoFormItem
    },
    data () {
        return {
            isInvite: false,
            formRef: 'setPasswordForm',
            model: {
                password: '',
                confirmPassword: ''
            },
            isPending: false,
            passwordStrength: 0,
            rules: {
                confirmPassword: [
                    {
                        validator: this.validatePasswordMatch,
                        trigger: 'blur'
                    }
                ]
            }
        };
    },
    computed: {
        ...mapState({
            hasLoginError: (state) => state.auth.hasLoginError,
            hasRegistrationTokenError: (state) => state.auth.hasRegistrationTokenError,
            hasRegistrationPasswordError: (state) => state.auth.hasRegistrationPasswordError,
            registrationToken: (state) => state.route.params.token,
            email: (state) => state.route.params.email
        }),
        ...mapGetters({
            subscriptions: 'subscriptions/listAll'
        }),
        validPassword () {
            const length = this.model.password.length > 11;
            const strength = this.passwordStrength > 2;
            const notEmail = this.model.password !== this.email;

            return length && strength && notEmail;
        },
        passwordsMatch () {
            return this.model.password === this.model.confirmPassword;
        },
        disableSubmit () {
            return !this.validPassword || !this.passwordsMatch;
        },
        buttonLabel () {
            return this.isInvite ? 'Create Account' : 'Reset Password';
        },
        heading () {
            return this.isInvite ? 'Create Your Account' : 'Reset Your Password';
        }
    },
    created () {
        const { type } = this.$route.query;

        if (type === 'invite') this.isInvite = true;
    },
    methods: {
        ...mapActions({
            _login: 'auth/login',
            _setPassword: 'auth/setAdoptPassword'
        }),
        handlePasswordStrengthChange (score) {
            this.passwordStrength = score;
        },
        validatePasswordMatch (rule, value, callback) {
            if (value !== this.model.password) {
                callback(new Error('Passwords must match'));
            } else {
                callback();
            }
        },
        async setPassword () {
            if (!this.validPassword) return;

            this.isPending = true;
            await this._setPassword({ token: this.registrationToken, password: this.model.password });

            if (this.hasRegistrationPasswordError || this.hasRegistrationTokenError) {
                this.isPending = false;

                return;
            }
            this.attemptLogin();
        },
        async attemptLogin () {
            await this._login({ email: this.email, password: this.model.password });
            this.isPending = false;

            if (this.hasLoginError) {
                this.$router.push({ name: 'login' });

                return;
            }

            if (!this.subscriptions || !this.subscriptions.length) {
                this.$router.push({ name: 'inactive' });

                return;
            }

            this.$router.push({ name: 'root' });
        }
    }
};
</script>
<style lang="scss" scoped>
.set-password {
    padding: 0 1.25rem 1.5rem;

    .set-password--header {
        margin: 0.25rem 0;
    }

    .set-adopt-password--input {
        margin-bottom: 1rem;
    }

    .content-footer {
        margin-top: 1.25rem;
    }
}
</style>
