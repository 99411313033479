import { request } from '@/utils/aggregations';

import * as Agg2 from '@pendo/aggregations';

export async function runWorkflow (workflow) {
    const spec = buildWorkflowAggSpec(workflow);

    return request(spec);
}

export function buildWorkflowAggSpec (workflow) {
    const { dateRange, start: startCountable, end: endCountable, segment, maxDuration } = workflow;
    const timeSeries = {
        period: 'dayRange',
        first: `date("${dateRange.value.start}")`,
        last: `date("${dateRange.value.end}")`
    };
    const { operators: o } = Agg2;

    return o.aggregation(
        'RunWorkflow',
        o.pipeline(
            o.sources.singleEvents({ appId: [startCountable.appId, endCountable.appId], timeSeries }),
            o.segment(segment === 'everyone' ? null : segment), // * important, 'everyone' is equivalent to not having a segment.

            o.identified('visitorId'),
            o.group(
                'visitorId',
                o.groupField(
                    'funnel',
                    o.funnel(
                        [
                            {
                                type: startCountable.kind.toLowerCase(),
                                ...startCountable
                            },
                            {
                                type: endCountable.kind.toLowerCase(),
                                ...endCountable
                            }
                        ],
                        maxDuration.value || 86400,
                        maxDuration.value || 86400
                    )
                )
            ),
            o.fork(
                o.pipeline(o.unwind('funnel'), o.group('visitorId', o.groupField('maxStep', { max: 'funnel.steps' }))),
                o.pipeline(o.cat())
            ),
            o.join('visitorId'),
            o.fork(
                o.pipeline(
                    o.reduce({
                        totalVisitors: { count: null },
                        neverStarted: { countIf: { if: 'maxStep==null' } },
                        incompleted: { countIf: { if: 'maxStep==1' } },
                        completed: { countIf: { if: 'maxStep==2' } }
                    }),
                    o.select({
                        completedCount: 'completed',
                        neverStartedCount: 'neverStarted',
                        incompletedCount: 'incompleted',
                        completedPercentage: 'if(totalVisitors == 0, 0, completed / totalVisitors)',
                        incompletedPercentage: 'if(totalVisitors == 0, 0, incompleted / totalVisitors)',
                        neverStartedPercentage: 'if(totalVisitors == 0, 0, neverStarted / totalVisitors)'
                    })
                ),
                o.pipeline(
                    o.unwind('funnel', { keepEmpty: true }),
                    o.filter('funnel.steps == maxStep'),
                    o.group(
                        'visitorId',
                        o.groupField('maxStep', { first: 'maxStep' }),
                        o.groupField('timeOfLastMaxStep', { max: 'funnel.start' })
                    ),
                    o.bulkExpand('visitor'),
                    o.select({
                        visitorId: 'visitorId',
                        maxStep: 'maxStep',
                        timeOfLastMaxStep: 'timeOfLastMaxStep',
                        visitor: 'visitor'
                    })
                )
            )
        )
    );
}
