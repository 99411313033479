/* eslint-disable id-length */
import isArray from 'lodash/isArray';
import * as Agg2 from '@pendo/aggregations';
import { request } from '@/utils/aggregations';
import { augmentVisitorRowWithLastActiveApp } from '@/utils/metadata';

export async function getVisitorList ({
    appId,
    timeSeries,
    segmentId,
    columns,
    filterNoEvents,
    appList = [],
    addLastVisitByApp,
    signal
}) {
    const spec = buildVisitorListSpec({ appId, timeSeries, segmentId, columns, filterNoEvents, appList });

    const rawVisitorList = await request(spec, { signal });

    if (!addLastVisitByApp) {
        return rawVisitorList;
    }

    return rawVisitorList.map((visitorRow) => augmentVisitorRowWithLastActiveApp(visitorRow));
}

export function buildVisitorListSpec ({ appId, timeSeries, segmentId, columns, filterNoEvents, appList = [] }) {
    const { operators: o, common: c } = Agg2;

    const appIdParam = isArray(appId) ? appId : [appId];
    const primaryKeys = ['visitorId'];
    const common = {
        primaryKeys,
        kind: 'visitor'
    };

    const mappedColumns = columns.map((column) => {
        return {
            ...common,
            ...column,
            columnName: column.prop
        };
    });

    const lastVisitedAppColumns = generateLastVisitByAppColumns(appList);

    const allVisitorColumns = [...mappedColumns, ...lastVisitedAppColumns];

    const params = {
        columns: mappedColumns,
        appId: appIdParam,
        dateRange: timeSeries,
        includeAnonymous: 'visitorId',
        kind: 'visitor',
        presetSource: { events: { appId: appIdParam } }
    };

    const visitorsOrEventsAgg = filterNoEvents
        ? o.sources.events({ timeSeries, appId: appIdParam })
        : o.sources.visitors();

    return o.aggregation(
        'visitor-list',
        o.pipeline(
            visitorsOrEventsAgg,
            o.identified(params.includeAnonymous),
            o.segment(segmentId),
            o.group(primaryKeys),
            ...c.callColumnGeneratorsWithDefaults(allVisitorColumns, params, primaryKeys),
            c.selectColumns(allVisitorColumns)
        )
    );
}

export function generateLastVisitByAppColumns (appList) {
    return appList.map((app) => {
        return {
            columnName: `visitor_auto_${app.id}_lastvisit`,
            fieldName: `visitor.auto_${app.id}.lastvisit`,
            functionName: 'metadataField',
            kind: 'visitor',
            label: `${app.displayName} Last Visit`,
            primaryKeys: ['visitorId'],
            schema: 'time'
        };
    });
}
