<template>
    <div>
        <pendo-button
            v-if="showAddAppButton"
            data-cy="add-extension-app"
            theme="via"
            type="primary"
            prefix-icon="plus"
            label="Add Application"
            @click="openAddAppModal" />
        <pendo-add-update-extension-app-modal
            :visible="showAddAppModal"
            theme="via"
            :create-mode="true"
            :extension-platform-type-options="extensionPlatformTypeOptions"
            :saving="saving"
            :submission-error="submissionError"
            @create="createApplication"
            @close="closeAddAppModal" />
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { PendoButton, PendoAddUpdateExtensionAppModal } from '@pendo/components';
import get from 'lodash/get';
import { getLabelForPlatformType, getDataCollectionConsentsForPlatformType } from '@pendo/services/Apps';
import { fetchExtensionPlatformTypes } from '@/utils/apps';
import { fetchFullSubscription } from '@/utils/subscriptions';
import { TAGGING_CLASS_EXCLUSIONS, TAGGING_ID_EXCLUSIONS } from '@pendo/services/Constants';

export default {
    name: 'AddAppModalWrapper',
    components: {
        PendoButton,
        PendoAddUpdateExtensionAppModal
    },
    props: {
        showAddAppButton: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            showAddAppModal: false,
            extensionPlatformTypeOptions: [],
            isLoadingPlatformExtensionTypes: false,
            submissionError: null,
            saving: false
        };
    },
    computed: {
        ...mapGetters({
            hasAppOfPlatformType: 'apps/hasAppOfPlatformType'
        })
    },
    async created () {
        this.isLoadingPlatformExtensionTypes = true;
        await this.loadExtensionPlatformTypeOptions();
        this.isLoadingPlatformExtensionTypes = false;
    },
    methods: {
        ...mapActions({
            createApp: 'apps/createApp'
        }),
        openAddAppModal () {
            this.showAddAppModal = true;
        },
        closeAddAppModal () {
            this.showAddAppModal = false;
        },
        async loadExtensionPlatformTypeOptions () {
            const subscription = await fetchFullSubscription();
            const isDAParentSub = get(subscription, 'autoSharePageFeature', false);
            const options = await fetchExtensionPlatformTypes();

            const platformTypeOptions = options
                .filter((platform) => {
                    if (isDAParentSub) {
                        return platform.type !== 'other';
                    }

                    return platform.state === 'configured';
                })
                .map((platform) => {
                    const disabled = isDAParentSub
                        ? ['configured', 'invalid'].includes(platform.state)
                        : this.hasAppOfPlatformType(platform.type);

                    return {
                        disabled,
                        label: getLabelForPlatformType(platform.type),
                        consentOptions: getDataCollectionConsentsForPlatformType(platform.type),
                        ...platform
                    };
                });
            const allAvailablePlatformsConfigured = platformTypeOptions.every((option) => option.disabled);

            if (isDAParentSub && allAvailablePlatformsConfigured) {
                return;
            }

            this.extensionPlatformTypeOptions = platformTypeOptions;
        },
        async createApplication (appJson) {
            this.submissionError = null;

            if (appJson.extensionPlatformType !== 'other') {
                appJson = {
                    ...appJson,
                    taggingClassExclusions: TAGGING_CLASS_EXCLUSIONS[appJson.extensionPlatformType],
                    taggingIdExclusions: TAGGING_ID_EXCLUSIONS[appJson.extensionPlatformType]
                };
            }
            try {
                this.saving = true;
                await this.createApp({ appJson });
                this.saving = false;
                this.closeAddAppModal();
            } catch (error) {
                this.submissionError = {};
                this.submissionError.errorMessage = get(
                    error,
                    'data.error',
                    'Unable to create application at this time.'
                );
                this.submissionError.duplicatePatternErrors = get(error, 'data.duplicates', null);
                this.submissionError.invalidPatternErrors = get(error, 'data.invalidPatterns', null);
                this.saving = false;
            }
        }
    }
};
</script>
