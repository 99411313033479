<template>
    <main class="features-list">
        <pendo-table
            ref="visitorListTable"
            v-pendo-loading:feather="isFetchingFeatureList"
            :data="formattedFeaturesList"
            :scroll-config="{ rowHeight: null }"
            :filters="filters"
            :columns="columns"
            :default-sort="sort"
            :cell-class-name="determineCellClasses"
            :auto-height="true"
            :auto-height-offset="96"
            csv-download
            title="Features"
            resizable
            row-key="id"
            class="features-list--table"
            @column-resize="onResizeColumn">
            <template #headerActions>
                <div class="features-list--table-header">
                    <div class="visibility-note">
                        Note: Only features with click data will appear below.
                    </div>
                </div>
            </template>
            <template #displayName="{ row }">
                <div
                    class="features-list--table--display-name"
                    :class="{
                        'is-disabled': isCustomFeatureNameEditDisabled(row)
                    }">
                    <template v-if="isCustomFeature(row)">
                        <pendo-editable-content
                            class="features-list--table--display-name-inline-edit"
                            :value="row.displayName"
                            :before-exit="updateDisplayName.bind(null, row)"
                            @enterEditMode="onEnterDisplayNameEdit(row)"
                            @exitEditMode="onExitDisplayNameEdit">
                            <template
                                slot="append"
                                slot-scope="{ confirm, cancel, pending }">
                                <pendo-button
                                    :loading="pending"
                                    theme="app"
                                    icon="check"
                                    type="primary"
                                    @click="confirm" />
                                <pendo-button
                                    theme="app"
                                    type="secondary"
                                    icon="x"
                                    @click="cancel" />
                            </template>
                        </pendo-editable-content>
                    </template>
                    <template v-if="!isCustomFeature(row)">
                        {{ row.displayName }}
                    </template>
                </div>
            </template>
            <template #actions="{ row }">
                <pendo-actions-cell
                    v-if="activeIsDigitalAdoption"
                    :row="row"
                    :actions="[
                        {
                            type: 'delete',
                            icon: 'trash-2',
                            disabled: !isCustomFeature(row),
                            tooltip: deleteTooltip(row)
                        }
                    ]"
                    @delete="openDeleteCustomEntityModal" />
            </template>
            <div
                slot="empty"
                class="features-list--table--empty">
                <pendo-icon
                    type="alert-circle"
                    class="empty-icon"
                    stroke="#9a9ca5"
                    size="24" />
                <span class="empty-text">
                    No data found.
                </span>
            </div>
        </pendo-table>
        <delete-custom-entity-modal
            :visible="isDeleteCustomEntityModalVisible"
            :entity="currentlyDeletingRow"
            type="feature"
            @close="closeDeleteCustomEntityModal" />
    </main>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import {
    PendoActionsCell,
    PendoButton,
    PendoEditableContent,
    PendoIcon,
    PendoTable,
    PendoLoading
} from '@pendo/components';
import { countableType, isCustomCountable } from '@pendo/services/Countables';
import { filterEntitiesByAppIds } from '@/utils/apps';
import DeleteCustomEntityModal from '@/components/common/DeleteCustomEntityModal.vue';

export default {
    name: 'FeaturesList',
    components: {
        PendoButton,
        PendoIcon,
        PendoTable,
        PendoEditableContent,
        PendoActionsCell,
        DeleteCustomEntityModal
    },
    directives: {
        PendoLoading
    },
    props: {
        searchString: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            currentlyEditingRow: {},
            isDeleteCustomEntityModalVisible: false,
            currentlyDeletingRow: {},
            sort: {
                prop: 'numVisitors',
                order: 'descending'
            }
        };
    },
    computed: {
        ...mapGetters({
            featuresList: 'features/listAll',
            usesMultiApp: 'subscriptions/usesMultiApp',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption'
        }),
        ...mapState({
            isFetchingFeatureList: (state) => state.features.isFetchingWithAnalytics,
            appIdsFilter: (state) => state.filters.appIdsFilter,
            featuresListUserSettings: (state) => state.features.featuresListColumns,
            featuresError: (state) => state.features.error
        }),
        features () {
            const features = cloneDeep(this.featuresList);

            return filterEntitiesByAppIds(features, this.appIdsFilter).filter((feature) => {
                return feature.numEvents || feature.numVisitors || isCustomCountable(feature);
            });
        },
        formattedFeaturesList () {
            const lineHeightAddition = 30;
            const charactersPerLineApproximation = 80;

            return this.features.map((feature) => {
                // Prevent vuex mutation errors
                const newFeature = { ...feature };
                newFeature.height = 56;

                if (this.activeIsDigitalAdoption) {
                    newFeature.type = countableType(feature);
                }

                if (newFeature.description) {
                    const numberOfExtraLinesApproximation = parseInt(
                        newFeature.description.length / charactersPerLineApproximation
                    );
                    const additionalHeightForDescription = lineHeightAddition * numberOfExtraLinesApproximation;
                    newFeature.height += additionalHeightForDescription;
                } else {
                    newFeature.description = '---';
                }

                return newFeature;
            });
        },
        filters () {
            return [
                {
                    prop: ['displayName', 'description', 'app.displayName'],
                    value: this.searchString
                }
            ];
        },
        columns () {
            const columns = [
                {
                    sortable: true,
                    prop: 'displayName',
                    label: 'Feature Name',
                    allowResize: true,
                    minWidth: 288
                }
            ];

            if (this.activeIsDigitalAdoption) {
                columns.push({
                    sortable: true,
                    prop: 'type',
                    allowResize: false,
                    width: 120,
                    label: 'Type'
                });
            }

            if (this.usesMultiApp) {
                columns.push({
                    sortable: true,
                    prop: 'app.displayName',
                    allowResize: false,
                    label: 'Application'
                });
            }

            columns.push(
                {
                    sortable: true,
                    prop: 'numVisitors',
                    allowResize: false,
                    label: 'Number of Visitors'
                },
                {
                    sortable: true,
                    prop: 'numEvents',
                    allowResize: false,
                    label: 'Number of Clicks'
                },
                {
                    sortable: false,
                    prop: 'description',
                    label: 'Description',
                    showOverflow: false,
                    allowResize: false,
                    minWidth: 300
                },
                {
                    type: 'actions',
                    allowResize: false,
                    width: 48
                }
            );

            if (!this.featuresListUserSettings) {
                return columns;
            }

            return columns.map((column) => {
                const savedColumn = this.featuresListUserSettings.find((col) => col.prop === column.prop);
                if (savedColumn && savedColumn.allowResize && savedColumn.width) {
                    column.width = savedColumn.width;
                }

                return column;
            });
        }
    },
    methods: {
        ...mapMutations({
            setFeaturesListColumns: 'features/setFeaturesListColumns'
        }),
        ...mapActions({
            updateUserSetting: 'userSettings/updateAppNamespaceSetting',
            updateCustomFeature: 'features/updateCustomFeature'
        }),
        isCustomFeature (row) {
            return isCustomCountable(row);
        },
        isCustomFeatureNameEditDisabled (row) {
            if (get(this.currentlyEditingRow, 'id')) {
                return this.currentlyEditingRow.id !== row.id;
            }

            return false;
        },
        determineCellClasses ({ column }) {
            return `${column.columnKey}-cell`;
        },
        onResizeColumn (column) {
            const columns = cloneDeep(this.columns);
            const resizedColumn = columns.find((col) => col.column === column.prop);
            resizedColumn.width = column.width;

            this.updateUserSetting({
                name: 'featuresListColumns',
                value: JSON.stringify(columns)
            });
            this.setFeaturesListColumns({ featuresListColumns: columns });
        },
        onEnterDisplayNameEdit (row) {
            this.currentlyEditingRow = row;
        },
        onExitDisplayNameEdit () {
            this.currentlyEditingRow = {};
        },
        async updateDisplayName (feature, newDisplayName) {
            if (feature.displayName === newDisplayName) {
                return;
            }

            const customFeature = {
                ...feature,
                name: newDisplayName
            };

            await this.updateCustomFeature({ customFeature });

            if (this.featuresError) {
                throw this.featuresError;
            }
        },
        openDeleteCustomEntityModal (row) {
            this.currentlyDeletingRow = row;
            this.isDeleteCustomEntityModalVisible = true;
        },
        closeDeleteCustomEntityModal () {
            this.currentlyDeletingRow = {};
            this.isDeleteCustomEntityModalVisible = false;
        },
        deleteTooltip (row) {
            switch (countableType(row)) {
                case 'Inherited':
                    return 'Inherited Features cannot be deleted';
                case 'Inactive':
                    return 'Inactive Features cannot be deleted';
                default:
                    return null;
            }
        }
    }
};
</script>

<style lang="scss">
.features-list {
    &--table {
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-grow: 1;
        }

        &--display-name {
            &.is-disabled {
                opacity: 0.7;
                pointer-events: none;
            }
        }

        &--empty {
            display: flex;
            align-items: center;
            justify-content: center;

            .pendo-icon {
                margin-right: 0.5em;
                display: flex;
            }

            .empty-text {
                color: $gray-primary;
            }
        }

        .pendo-table__title-actions {
            grid-template-columns: auto 36px;
            flex-grow: 1;
        }

        .pendo-table__cell {
            word-break: normal;
        }

        .description-cell {
            color: $gray-lighter-2;
        }
    }

    .visibility-note {
        font-size: 0.875rem;
        padding-left: 10px;
    }
}
</style>
