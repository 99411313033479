<template>
    <pendo-layout-chooser
        :default-layouts="defaultLayouts"
        :loading="isLoading"
        :apps="formattedAppOptions"
        :selected-app="selectedAppForLayoutChooser"
        @select-layout="chooseLayout"
        @select-app="chooseApp"
        @close="close" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PendoLayoutChooser } from '@pendo/components';
import getDefaultLayouts from '@/utils/default-layouts';
import { BuildingBlock } from '@pendo/services/BuildingBlocks';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';

export default {
    name: 'LayoutChooser',
    components: {
        PendoLayoutChooser
    },
    data () {
        return {
            isLoading: true,
            selectedAppForLayoutChooser: null,
            defaultLayouts: []
        };
    },
    computed: {
        ...mapGetters({
            productName: 'subscriptions/productName',
            activeUsesV2Adopt: 'subscriptions/activeUsesV2Adopt',
            defaultTheme: 'themes/defaultTheme',
            webLayoutsWithoutCodeBlocks: 'layouts/webLayoutsWithoutCodeBlocks',
            listAllForActiveSubscription: 'apps/listAllForActiveSubscription',
            usesMultiApp: 'subscriptions/usesMultiApp'
        }),
        formattedAppOptions () {
            if (!this.usesMultiApp) return null;

            return this.listAllForActiveSubscription.map((app) => {
                return {
                    id: app.id,
                    name: app.displayName
                };
            });
        },
        selectedAppId () {
            if (!this.usesMultiApp) return null;

            return get(this.selectedAppForLayoutChooser, 'id');
        }
    },
    async created () {
        await this.fetchThemes({ selectedAppId: this.selectedAppId });
        await this.fetchLayouts();
        this.defaultLayouts = await this.buildLayoutsAsync();
        this.resetLoading();
    },
    methods: {
        ...mapActions({
            fetchLayouts: 'layouts/fetchLayouts',
            fetchThemes: 'themes/fetch'
        }),
        async resetLoading () {
            await this.$nextTick();
            setTimeout(() => {
                this.isLoading = false;
                if (window.pendo) window.pendo.flexElement(window.document);
            }, 250);
        },
        createPreview (dom) {
            const buildNodeFromJSON = get(window, 'pendo.buildNodeFromJSON');
            const createBBTooltip = get(window, 'pendo.BuildingBlocks.BuildingBlockTooltips.createBBTooltip');

            return BuildingBlock.generatePreviewHTML(buildNodeFromJSON, createBBTooltip, dom);
        },
        chooseLayout (layout) {
            this.$emit('onChooseLayout', layout, this.selectedAppId);
        },
        async chooseApp (app) {
            this.isLoading = true;
            this.selectedAppForLayoutChooser = app;
            await this.fetchThemes({ noCache: true, selectedAppId: this.selectedAppId });
            this.defaultLayouts = await this.buildLayoutsAsync();
            this.resetLoading();
        },
        close () {
            this.$emit('onClose');
        },
        buildLayoutsAsync () {
            return new Promise((resolve) => {
                if (!this.webLayoutsWithoutCodeBlocks) return [];

                const allLayouts = getDefaultLayouts(this.activeUsesV2Adopt ? this.defaultTheme : null).concat(
                    this.webLayoutsWithoutCodeBlocks
                );

                const subtext = this.productName ? `${this.productName} Default` : 'Pendo Default';
                const layouts = allLayouts.reduce((layouts, layout, index) => {
                    const { tags = [], ...rest } = layout;
                    if (tags.includes('isAnnouncement')) return layouts;

                    let buildingBlocks = cloneDeep(
                        isArray(layout.buildingBlocks) ? layout.buildingBlocks[0] : layout.buildingBlocks
                    );
                    let hasCustomTheme = true;

                    if (this.activeUsesV2Adopt) {
                        const containerBlock = BuildingBlock.findBlockByDomId(buildingBlocks, 'pendo-guide-container');
                        hasCustomTheme =
                            !!containerBlock &&
                            (!containerBlock.web.themeStyle || containerBlock.web.themeStyle === 'custom');
                        buildingBlocks = BuildingBlock.applyThemeToBuildingBlocks({
                            theme: this.defaultTheme,
                            buildingBlocks
                        });
                    }

                    const dom = BuildingBlock.buildingBlocksToDom(buildingBlocks);
                    layouts.push({
                        ...rest,
                        id: rest.id || index,
                        active: true,
                        buildingBlocks,
                        previewHTML: this.createPreview(dom),
                        hasCustomTheme,
                        subtext
                    });

                    return layouts;
                }, []);

                resolve(this.sortByName(layouts));
            });
        },
        sortByName (layouts) {
            layouts.sort((a, b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();

                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;

                return 0;
            });

            return layouts;
        }
    }
};
</script>

<style lang="scss">
.pendo-layout-chooser {
    .pendo-card__media-subtitle > div {
        @include ellipsis;
    }
}

.custom-layout-note {
    padding-top: 14px;
    padding-left: 24px;
    padding-right: 24px;
}

.layout-chooser-empty-state {
    height: calc(100vh - 230px);

    .pendo-empty-state {
        position: relative;
    }

    .pendo-card {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.pendo-layout-chooser {
    &__app-theme-card {
        margin-top: -40px;
        margin-bottom: 30px !important; /* stylelint-disable-line */
    }
}
</style>
