<template>
    <div>
        <pendo-page-header
            :title="moduleName"
            :editable="!localModuleDisabled"
            :disabled="isLoading"
            :before-exit="patchModuleName">
            <template #top>
                <router-link
                    :to="{
                        name: 'resourceCenter',
                        params: { originId: $route.params.originId, publishedState: 'draft' }
                    }">
                    &lsaquo; Back to Resource Center Home
                </router-link>
            </template>
            <template #actions>
                <pendo-button
                    v-if="!isLoading && !localModuleDisabled"
                    v-pendo-tooltip.left-start="{ content: 'Delete Module', offset: 5 }"
                    class="delete-module"
                    data-cy="delete-module"
                    icon="trash-2"
                    theme="app"
                    type="secondary"
                    icon-size="16"
                    @click="toggleDeleteModal(true)" />
            </template>
            <template #bottom>
                <pendo-editable-content
                    v-if="localSubtitle && !localModuleDisabled"
                    exit-event="blur"
                    content-slot-class="subtitle-text"
                    :value="localSubtitle"
                    auto-width
                    @exitEditMode="updateSubtitle">
                    <template #content>
                        <span class="subtitle-text">
                            {{ localSubtitle }}
                        </span>
                    </template>
                </pendo-editable-content>
                <span
                    v-if="localSubtitle && localModuleDisabled"
                    class="subtitle-text">
                    {{ localSubtitle }}
                </span>
            </template>
        </pendo-page-header>
        <pendo-page-content>
            <div class="module-body">
                <div class="alert-container">
                    <pendo-alert
                        v-if="localModuleDisabled"
                        type="warning"
                        data-cy="module-removed-alert"
                        title="This module has been removed from Draft, so it is view only. Push this change upwards to completely delete this module from all versions of the Resource Center." />
                </div>
                <div
                    v-if="!isLocalModuleEmpty"
                    class="content-container">
                    <guide-list
                        v-if="!isIframeModule"
                        :parent-loading="isLoading"
                        :module="localModule"
                        @on-save="setLocalModule" />
                    <iframe-content
                        v-if="isIframeModule"
                        :parent-loading="isLoading"
                        :module="localModule"
                        @on-save="setLocalModule" />
                    <app-card-overlay :is-editing="isEditingSegment">
                        <template slot="card">
                            <guide-details-segment
                                v-pendo-loading="loadingConfig"
                                class="guide-details--segment"
                                :is-local-module-disabled="localModuleDisabled"
                                confirm-rc-module-segment-before-save
                                @is-editing-segment="toggleEditingSegment"
                                @segment-update="segmentUpdate">
                                <template slot="footer-message">
                                    <div class="title">
                                        What is "Segment per Module"?
                                    </div>
                                    <div class="subtitle">
                                        Setting a segment for your Module means that only the users in this Segment will
                                        see this section of the Resource Center. If your user falls in that segment AND
                                        matches the segment for guides, then they will be eligible.
                                    </div>
                                </template>
                            </guide-details-segment>
                        </template>
                    </app-card-overlay>
                </div>
            </div>
            <pendo-modal
                height="auto"
                max-height="80%"
                type="basic"
                class="remove-draft-module-modal"
                :is-visible="showDeleteModal"
                title="Remove module from Draft?"
                @close="toggleDeleteModal(false)">
                <template #body>
                    <span v-if="moduleExistsInPublicVersion">
                        This module is currently live in your Public Resource Center. This module will not be removed
                        from the Public Resource Center until you push changes upward.
                    </span>
                    <span v-if="!moduleExistsInPublicVersion">
                        Are you sure you would like to delete this module? This action cannot be undone.
                    </span>
                </template>
                <template #footer>
                    <pendo-button
                        theme="via"
                        type="secondary"
                        label="Cancel"
                        @click="toggleDeleteModal(false)" />
                    <pendo-button
                        theme="via"
                        type="danger"
                        label="Delete Module"
                        data-cy="delete-module-modal-btn"
                        :loading="isDeletingModule"
                        @click="deleteModule" />
                </template>
            </pendo-modal>
        </pendo-page-content>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
    PendoButton,
    PendoPageHeader,
    PendoPageContent,
    PendoEditableContent,
    PendoNotification,
    PendoTooltip,
    PendoAlert,
    PendoModal,
    PendoLoading
} from '@pendo/components';
import GuideList from '@/components/resource-center/edit-module/GuideList';
import IframeContent from '@/components/resource-center/edit-module/IframeContent';
import GuideDetailsSegment from '@/components/guides/settings/GuideDetailsSegment';
import AppCardOverlay from '@/components/common/AppCardOverlay';
import { getModuleTitleProperty, getModuleSubtitleProperty } from '@/utils/resource-center';

export default {
    name: 'EditModule',
    components: {
        PendoButton,
        PendoPageHeader,
        PendoPageContent,
        PendoEditableContent,
        PendoAlert,
        PendoModal,
        GuideList,
        IframeContent,
        GuideDetailsSegment,
        AppCardOverlay
    },
    directives: {
        PendoTooltip,
        PendoNotification,
        PendoLoading
    },
    data () {
        return {
            localModule: {},
            localSubtitle: '',
            showDeleteModal: false,
            isEditingSegment: false
        };
    },
    computed: {
        ...mapState({
            isDeletingModule: (state) => state.resourceCenter.isDeletingModule,
            errorOnUpdate: (state) => state.resourceCenter.error,
            isUpdating: (state) => state.resourceCenter.isUpdating,
            isFetching: (state) => state.resourceCenter.isFetching
        }),
        ...mapGetters({
            activeResourceCenter: 'resourceCenter/getActiveResourceCenter',
            activeHasResourceCenter: 'subscriptions/activeHasResourceCenter',
            getGuideById: 'guides/getGuideById',
            publicModules: 'resourceCenter/getPublicModules',
            activeGuide: 'guides/active',
            usesMultiApp: 'subscriptions/usesMultiApp'
        }),
        loadingConfig () {
            return {
                loading: this.isLoading,
                type: 'feather',
                spinnerProps: {
                    background: 'rgba(255, 255, 255, 0.5)'
                }
            };
        },
        isLoading () {
            return this.isFetching || this.isUpdating;
        },
        isIframeModule () {
            return get(this.localModule, 'attributes.resourceCenter.moduleId') === 'IframeModule';
        },
        moduleId () {
            return this.$route.params.moduleId;
        },
        moduleName () {
            return this.getLocalModuleBuildingBlocks !== null
                ? getModuleTitleProperty(this.getLocalModuleBuildingBlocks)
                : get(this.localModule, 'name');
        },
        isLocalModuleEmpty () {
            return isEmpty(this.localModule);
        },
        moduleExistsInPublicVersion () {
            return !!this.publicModules.find((mod) => mod.id === this.localModule.id);
        },
        localModuleDisabled () {
            return get(this, 'localModule.state', '') === 'disabled';
        },
        getLocalModuleBuildingBlocks () {
            return get(this.localModule, 'steps[0].buildingBlocks', null);
        }
    },
    async created () {
        if (!this.activeHasResourceCenter) return this.$router.push({ name: 'guideList' });

        const routeId = get(this.$route, 'params.originId', null);

        if (!this.usesMultiApp) {
            const originId = get(this.activeResourceCenter, 'id', null);
            if (originId !== routeId) {
                this.$router.push({
                    name: 'resourceCenter',
                    params: {
                        activeState: 'draft',
                        originId
                    }
                });
            }
        }

        if (this.usesMultiApp) {
            await this.updateActiveEditingAppByOriginId({ originId: routeId });
        }

        await this.setLocalModule();

        this.localSubtitle = getModuleSubtitleProperty(this.activeResourceCenter.draft.homeView, this.moduleId);
    },
    methods: {
        ...mapActions({
            getResourceCenter: 'resourceCenter/get',
            disableDraftModule: 'resourceCenter/disableDraftModule',
            updateModuleTitle: 'resourceCenter/updateModuleTitle',
            updateModuleSubtitle: 'resourceCenter/updateModuleSubtitle',
            updateActiveEditingAppByOriginId: 'resourceCenter/updateActiveEditingAppByOriginId',
            fetchAllResourceCenters: 'resourceCenter/fetchAll'
        }),
        ...mapMutations({
            setActiveGuideId: 'guides/setActiveById'
        }),
        async setLocalModule () {
            if (this.isLocalModuleEmpty) {
                await this.getResourceCenter();
            }
            this.fetchAllResourceCenters({ noCache: true });
            const { modules } = this.activeResourceCenter.draft;
            this.localModule = modules.find(({ id }) => id === this.moduleId);
            this.setActiveGuideId({ id: this.localModule.id });
        },
        toggleDeleteModal (show) {
            this.showDeleteModal = show;
        },
        async deleteModule () {
            let failed = false;
            try {
                await this.disableDraftModule({ moduleGuideToDisable: this.localModule });
            } catch (err) {
                failed = true;
            }

            this.localModule = this.getGuideById(this.localModule.id);
            this.toggleDeleteModal(false);
            this.fetchAllResourceCenters({ noCache: true });

            if (failed) {
                this.showErrorToast('Please try again or contact support if the issue persists.');

                return;
            }

            if (!this.localModule) {
                this.$router.replace({
                    name: 'resourceCenter',
                    params: {
                        originId: get(this.activeResourceCenter, 'id', null),
                        publishedState: 'draft'
                    }
                });
            }
        },
        showErrorToast (message) {
            PendoNotification({
                type: 'error',
                title: 'Something went wrong!',
                message,
                duration: 5000
            });
        },
        toggleEditingSegment (value) {
            this.isEditingSegment = value;
        },
        async patchModuleName (name) {
            if (name === this.moduleName) {
                return;
            }

            await this.updateModuleTitle({
                module: this.localModule,
                name
            });

            if (this.errorOnUpdate !== null) {
                this.showErrorToast('Action not available. Please try again later.');

                return;
            }

            await this.setLocalModule();
        },
        async updateSubtitle (subtitle) {
            if (subtitle === this.localSubtitle) return;

            const temp = this.localSubtitle;
            this.localSubtitle = subtitle;

            await this.updateModuleSubtitle({
                homeview: this.activeResourceCenter.draft.homeView,
                module: this.localModule,
                subtitle
            });

            if (this.errorOnUpdate !== null) {
                this.localSubtitle = temp;
                this.showErrorToast('Action not available. Please try again later.');

                return;
            }

            await this.setLocalModule();
        },
        async segmentUpdate () {
            this.localModule = get(this, 'activeResourceCenter.draft.modules', []).find(
                (singleModule) => singleModule.id === this.moduleId
            );
        }
    }
};
</script>

<style scoped lang="scss">
.delete-module {
    background-color: $white;
    color: $gray-lighter-2;
    margin-left: 0;

    &:hover {
        background-color: $gray-lighter-6;
        color: $gray-primary;
    }
}

.subtitle-text {
    color: $gray-lighter-2;
    font-size: 16px;
}

.module-body {
    display: flex;
    flex-direction: column;

    .pendo-alert {
        margin-bottom: 16px;
        width: 100%;
    }

    .content-container {
        display: flex;
    }

    .module-deleted-warning {
        width: 100%;
    }
}

/deep/ .footer-message {
    border-radius: 3px;
    background-color: $gray-lighter-7;
    padding: 20px 28px;
    margin-top: 20px;

    .title {
        font-size: 14px;
        font-weight: bold;
    }

    .subtitle {
        font-size: 12px;
        margin-top: 10px;
        color: $gray-lighter-2;
    }
}

.guide-list-container,
.iframe-content-container {
    flex: 2;
    min-height: 500px;
    height: 100%;
    margin-bottom: 32px;
    margin-right: 32px;

    // Needed to ensure inner elements scroll horizontally
    min-width: 0px;

    /deep/ .pendo-card__body {
        padding: 0px;
    }
}

.content-container {
    .guide-details--segment {
        max-width: 400px;
    }

    /deep/ .pendo-card {
        min-height: 585px;
        min-width: 400px;

        .pendo-card__body {
            display: grid;
        }
    }
}

@media (max-width: 1200px) {
    .guide-list-container {
        margin-right: 0;
    }

    .content-container {
        flex-direction: column;

        /deep/ .pendo-card {
            flex: auto;
            max-width: initial;
            min-width: 0;

            .pendo-card-footer {
                display: flex;
                justify-content: space-between;

                .footer-message {
                    max-width: 400px;
                    margin-top: 0px;
                }
            }
        }
    }
}
</style>

<style lang="scss">
input.el-input__inner.subtitle-text {
    font-size: 16px;
}
</style>
