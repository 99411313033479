<template>
    <div class="guide-details-poll-responses">
        <global-filters />
        <div class="guide-details-polls--content">
            <poll-questions-card />
        </div>
    </div>
</template>

<script>
import GlobalFilters from '@/components/filters/GlobalFilters';
import PollQuestionsCard from '@/components/guides/polls/PollQuestionsCard';

export default {
    name: 'GuideDetailsPollResponses',
    components: {
        GlobalFilters,
        PollQuestionsCard
    }
};
</script>
<style lang="scss">
.guide-details-polls--content {
    display: grid;
    grid-gap: 32px;
}
</style>
