<template>
    <!-- eslint-disable vue/no-v-html -->
    <div class="step-preview">
        <div
            ref="preview"
            class="preview"
            v-html="previewHtml" />
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { BuildingBlock } from '@pendo/services/BuildingBlocks';

const emptyPreview = '<div style="width:100%;height:100%"></div>';

export default {
    name: 'GuideStepPreview',
    props: {
        dom: {
            type: String,
            default: ''
        },
        watermarkDom: {
            type: String,
            default: ''
        },
        stepCount: {
            type: Number,
            default: 0
        },
        guides: {
            type: Array,
            default: null
        },
        step: {
            type: Object,
            default: null
        },
        isResourceCenterPreview: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            previewHtml: null
        };
    },
    watch: {
        guides () {
            this.previewHtml = this.buildPreview();
        },
        dom () {
            this.previewHtml = this.buildPreview();
        },
        watermarkDom () {
            this.previewHtml = this.buildPreview();
        },
        stepCount () {
            this.onResize();
        }
    },
    created () {
        this.$nextTick(() => this.updateGuideSize());
    },
    mounted () {
        this.onResize = debounce(() => {
            this.resizePreview();
        }, 16);

        window.addEventListener('resize', this.onResize);
        window.addEventListener('focus', this.onResize);
        this.previewHtml = this.buildPreview();
        this.$nextTick(() => this.onResize());
        if (window.pendo.flexElement) {
            window.pendo.flexElement(window.document);
        }
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.onResize);
        window.removeEventListener('focus', this.onResize);
    },
    updated () {
        if (window.pendo.flexElement) {
            window.pendo.flexElement(this.$el);
        }
        this.updateGuideSize();
    },
    methods: {
        buildPreview () {
            const { pendo } = window;

            if (!pendo || !this.dom || !pendo.buildNodeFromJSON) {
                return emptyPreview;
            }

            const parsedDom = BuildingBlock.makeThumbnailPreview(JSON.parse(this.dom), {
                isResourceCenterGuide: this.isResourceCenterPreview
            });
            const createBBTooltip = get(pendo, 'BuildingBlocks.BuildingBlockTooltips.createBBTooltip');

            const mockStep = cloneDeep(this.step);
            mockStep.attachEvent = function () {};
            // agent uses `guide.language` in `generateGuideDataTextBlock()` to translate date
            const mockGuides = [...this.guides];
            const html = BuildingBlock.renderStep(
                pendo.buildNodeFromJSON,
                parsedDom,
                createBBTooltip,
                mockStep,
                mockGuides
            );

            const htmlParser = new DOMParser();
            const guideDoc = htmlParser.parseFromString(html, 'text/html');
            const guideBase = guideDoc.querySelector("[id^='pendo-g']");
            const width = guideBase.style.width || '900px';
            const guideContainer = guideDoc.getElementById('pendo-guide-container');
            const border = guideContainer.style.border || '';
            const borderRadius = this.isResourceCenterPreview ? '3px' : guideContainer.style['border-radius'];
            const boxShadow = guideContainer.style['box-shadow'] || '';
            const tooltip = guideDoc.querySelector('.pendo-tooltip-caret');
            const tooltipBorder = guideDoc.querySelector('.pendo-tooltip-caret-border');

            Object.assign(guideContainer.style, {
                border,
                'box-shadow': boxShadow,
                outline: '',
                position: '',
                width,
                borderRadius
            });

            this.$refs.preview.style['border-radius'] = borderRadius;

            if (border) {
                if (tooltip) {
                    Object.assign(tooltip.style, {
                        top: parseInt(tooltip.style.top) - parseInt(border) + 1, // adding 1px helps avoid a phantom bottom line at certain sizes
                        left: parseInt(tooltip.style.left) - parseInt(border)
                    });
                }
                if (tooltipBorder) {
                    Object.assign(tooltipBorder.style, {
                        top: parseInt(tooltipBorder.style.top) - parseInt(border),
                        left: parseInt(tooltipBorder.style.left) - parseInt(border)
                    });
                }
            }
            if (tooltip) guideContainer.appendChild(tooltip);
            if (tooltipBorder) guideContainer.appendChild(tooltipBorder);

            if (this.watermarkDom) {
                const parsedWatermarkDom = BuildingBlock.makeThumbnailPreview(JSON.parse(this.watermarkDom));
                const watermarkHtml = BuildingBlock.renderStep(
                    pendo.buildNodeFromJSON,
                    parsedWatermarkDom,
                    createBBTooltip,
                    {
                        attachEvent: () => null
                    }
                );
                const watermarkDoc = new DOMParser().parseFromString(watermarkHtml, 'text/html');
                const watermarkElem = watermarkDoc.getElementById('pendo-watermark');
                const isBottomAligned = guideBase.getAttribute('data-vertical-alignment') === 'Bottom Aligned';
                Object.assign(watermarkElem.style, {
                    position: 'absolute',
                    right: 0,
                    [isBottomAligned ? 'bottom' : 'top']: '100%'
                });
                guideContainer.appendChild(watermarkElem);
            }

            return guideContainer.outerHTML;
        },
        updateGuideSize () {
            if (this.previewHtml === emptyPreview) {
                return;
            }

            this.resizePreview();
        },
        resizePreview () {
            const $preview = get(this, '$refs.preview', {});
            const $guideContainer = get($preview, 'firstChild', false);

            if (!$guideContainer) return;

            const guideHeight = $guideContainer.offsetHeight;
            const guideWidth = $guideContainer.offsetWidth;
            const componentHeight = this.$el.offsetHeight;
            const componentWidth = this.$el.offsetWidth;

            if (!componentWidth || !componentHeight || !guideWidth || !guideHeight) {
                return;
            }

            const watermarkOffset = this.watermarkDom ? 55 : 0;
            const scale = Math.min(componentWidth / guideWidth, componentHeight / (guideHeight + watermarkOffset));
            const newWidth = Math.min(Math.floor(scale * guideWidth), guideWidth);
            const newHeight = Math.min(Math.floor(scale * guideHeight), guideHeight);

            if (scale < 1) {
                $guideContainer.style.transform = `scale(${scale})`;
            }

            Object.assign($preview.style, {
                width: `${newWidth}px`,
                height: `${newHeight}px`,
                visibility: 'visible',
                margin: 'auto'
            });
        }
    }
};
</script>

<style lang="scss">
.step-preview {
    .preview {
        max-height: 100% !important; /* stylelint-disable-line */
        box-shadow: 0 5px 10px 0 rgba(169, 121, 121, 0.2); // overidden by guide's box-shadow if present

        & > #pendo-guide-container {
            position: relative;
            transform-origin: top left;
        }
    }
}
</style>
