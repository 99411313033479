var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.activeApp && _vm.activeApp.id)?_c('div',{staticClass:"guide-list"},[(_vm.showDAEmptyState)?_c('guide-list-empty',{attrs:{"loading":_vm.loading},on:{"showGuideCreateChange":function($event){return _vm.$emit('showGuideCreateChange', $event)}}}):_vm._e(),(!_vm.showDAEmptyState)?_c('div',[_c('global-filters',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('search',{attrs:{"placeholder":"Search Guides"},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})]},proxy:true}],null,false,1190280122)}),_c('pendo-table',{ref:"guideListTable",staticClass:"guide-list-table",attrs:{"data":_vm.filteredList,"filters":_vm.filters,"columns":_vm.columns,"default-sort":_vm.sort,"loading":_vm.loading,"row-class-name":_vm.getRowClassName,"csv-download":"","auto-height":"","title":"Guides","row-key":"id","resizable":true},on:{"column-resize":_vm.onColumnResized,"select-change":_vm.onSelectRowChange,"select-clear":_vm.onSelectRowClear},scopedSlots:_vm._u([{key:"headerLeft",fn:function(){return [_c('div',{staticClass:"guide-list-table--table-header-left"},[_c('div',{staticClass:"guide-list-table--table-title"},[_vm._v(" Guides ")]),_c('div',{staticClass:"guide-list-table--active-count"},[_vm._v(" ("+_vm._s(_vm.selectedAndTotalGuidesCount)+") ")]),_c('bulk-actions-header',{attrs:{"selected-row-count":_vm.selectedTableRows.length},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('pendo-button',{attrs:{"data-cy":"guide-list--copy-guides","theme":"via","type":"link","prefix-icon":"repeat","label":"Copy to Account"},on:{"click":_vm.openBulkCopy}})]},proxy:true}],null,false,1841459610)})],1)]},proxy:true},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"guide-list-table--link",attrs:{"to":("guides/" + (row.id) + "/settings")}},[_vm._v(" "+_vm._s(row.name)+" ")])]}},{key:"state",fn:function(ref){
var row = ref.row;
return [_c('guide-status-changer',{attrs:{"guide":row,"guide-type":_vm.type,"size":"mini"}})]}},{key:"editorType",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"editor-type-cell"},[_c('pendo-icon',{attrs:{"size":"14","type":_vm.editorTypeToHuman(row) === 'Adopt Studio' ? 'adopt-studio' : 'visual-design-studio'}}),_vm._v(" "+_vm._s(_vm.editorTypeToHuman(row))+" ")],1)]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('guide-utilities',{attrs:{"is-dropdown":false,"guide":row},on:{"openGuideCopyModal":_vm.openGuideCopyModal}})]}}],null,false,557435335)},[_c('div',{staticClass:"guide-list-table--empty",attrs:{"slot":"empty"},slot:"empty"},[_c('pendo-icon',{staticClass:"empty-icon",attrs:{"type":"alert-circle","stroke":"#9a9ca5","size":"24"}}),_c('span',{staticClass:"empty-text"},[_vm._v(" "+_vm._s(_vm.emptyText)+" ")])],1)]),_c('guide-copy-modal-v1',{attrs:{"visible":_vm.showGuideCopyModalV1,"guides":_vm.selectedTableRows},on:{"close":_vm.closeGuideCopyModalV1,"closed":_vm.onBulkCopyClose}}),_c('guide-copy-modal',{attrs:{"visible":_vm.showGuideCopyModal,"guide":_vm.guide},on:{"close":_vm.closeGuideCopyModal}}),_c('bulk-guide-copy-drawer',{ref:"bulkCopyDrawer",attrs:{"visible":_vm.showBulkCopyDrawer,"guides":_vm.selectedTableRows},on:{"close":_vm.closeBulkCopyDrawer,"closed":_vm.onBulkCopyClose}})],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }