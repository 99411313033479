<template>
    <pendo-modal
        class="path-delete-modal"
        height="auto"
        type="confirmation"
        :visible="isVisible"
        title="Delete Path?"
        :confirm-button-config="{
            type: 'danger',
            label: 'Delete Path',
            theme: 'via',
            loading: isRemoving
        }"
        :cancel-button-config="{ type: 'secondary', theme: 'via' }"
        :message="modalMessage"
        @close="$emit('close')"
        @cancel="$emit('close')"
        @confirm="deleteReport" />
</template>

<script>
import { PendoModal } from '@pendo/components';
import { mapActions } from 'vuex';

export default {
    name: 'PathDeleteModal',
    components: {
        PendoModal
    },
    props: {
        report: {
            type: Object,
            default: () => ({
                name: ''
            })
        },
        isVisible: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            isRemoving: false
        };
    },
    computed: {
        modalMessage () {
            return `"${
                this.report.name
            }" will no longer be accessible and cannot be recovered. This action cannot be undone.`;
        }
    },
    methods: {
        ...mapActions('reports', ['remove']),
        async deleteReport () {
            this.isRemoving = true;
            await this.remove(this.report);
            this.isRemoving = false;

            if (this.$route.name !== 'pathsDashboard') {
                this.$router.push({
                    name: 'pathsDashboard'
                });
            }

            this.$emit('deleted');
        }
    }
};
</script>
