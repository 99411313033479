<template>
    <div class="user-list">
        <pendo-table
            v-pendo-loading:feather="isFetchingUserList"
            :data="users"
            :columns="columns"
            :row-class-name="tableRowClassName"
            :auto-height-offset="96"
            :default-sort="{
                prop: 'email',
                order: 'ascending'
            }"
            auto-height
            title="Users"
            row-key="id"
            class="user-list-table">
            <div
                v-if="row.id !== currentUser.id"
                slot="actions"
                slot-scope="{ row }"
                :data-user-id="row.id"
                class="user-list-table-buttons">
                <pendo-button
                    v-if="!row.hasLoggedIn"
                    v-pendo-tooltip="{
                        content: row.resendInviteSuccess
                            ? 'Invite Sent!'
                            : row.resendInviteError
                                ? 'Try again'
                                : 'Resend Invite',
                        delay: { show: 300, hide: 0 }
                    }"
                    :icon="row.resendInviteSuccess ? 'check' : row.resendInviteError ? 'x' : 'send'"
                    :loading="row.isResendingInvite"
                    :class="{ 'is-success': row.resendInviteSuccess }"
                    class="user-list-table-buttons--resend-invite"
                    data-cy="resend-invite"
                    icon-size="18"
                    theme="via"
                    type="tertiary"
                    size="medium"
                    @click="resendInvite(row)" />
                <pendo-button
                    v-pendo-tooltip="{ content: 'Remove User', delay: { show: 300, hide: 0 } }"
                    class="user-list-table-buttons--remove-user"
                    data-cy="remove-user"
                    icon-size="18"
                    theme="via"
                    type="tertiary"
                    size="medium"
                    icon="trash-2"
                    @click="confirmRemoveUser(row)" />
            </div>
            <div
                slot="empty"
                class="user-list-table--empty">
                <span class="empty-text">
                    Invite users to join your team!
                </span>
            </div>
        </pendo-table>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { PendoTable, PendoLoading, PendoButton, PendoTooltip } from '@pendo/components';

export default {
    name: 'ManageTeamV1',
    components: {
        PendoTable,
        PendoButton
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    data () {
        return {
            tableProps: {
                rowClassName: ({ row }) => {
                    if (row.id === this.currentUser.id) {
                        return '';
                    }

                    return row.hasLoggedIn ? 'is-removable' : 'is-invitable';
                }
            },
            columns: [
                {
                    prop: 'email',
                    label: 'Email',
                    sortable: true,
                    showOverflowTooltip: true
                },
                {
                    prop: 'firstname',
                    label: 'First Name',
                    showOverflowTooltip: true,
                    sortable: true
                },
                {
                    prop: 'lastname',
                    label: 'Last Name',
                    showOverflowTooltip: true,
                    sortable: true
                },
                {
                    type: 'actions',
                    width: 142
                }
            ]
        };
    },
    computed: {
        ...mapState({
            currentUser: (state) => state.auth.user,
            isFetchingUserList: (state) => state.users.isFetchingUserList,
            hasFetchUserListError: (state) => state.users.hasFetchUserListError
        }),
        ...mapGetters({
            users: 'users/listUsers'
        })
    },
    methods: {
        ...mapActions({
            _resendInvite: 'users/resendInvite',
            fetchUserList: 'users/fetchUserList'
        }),
        tableRowClassName ({ row }) {
            if (row.id === this.currentUser.id) {
                return '';
            }

            return row.hasLoggedIn ? 'is-removable' : 'is-invitable';
        },
        confirmRemoveUser (userToRemove) {
            this.$modal.show('via-modal', {
                title: 'Remove User?',
                component: 'RemoveUserModal',
                componentProps: {
                    userToRemove
                }
            });
        },
        async resendInvite (user) {
            if (user.resendInviteSuccess) {
                return;
            }
            await this._resendInvite({ user });
        }
    }
};
</script>

<style lang="scss">
.user-list-table {
    &--empty {
        display: flex;
        align-items: center;
        justify-content: center;

        .pendo-icon {
            margin-right: 0.5em;
            display: flex;
        }

        .empty-text {
            color: $gray-primary;
        }
    }
}

.user-list-table-buttons {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
}
</style>
