<template>
    <pendo-form
        :call-validate="callValidate"
        :form-defaults="model"
        form-ref="viaLaunchPreviewForm"
        @formValidated="handleValidForm"
        @invalidForm="handleInvalidForm">
        <div class="pendo-modal__body">
            <div
                v-if="!error"
                class="launch-preview-modal--message">
                {{ bodyText }}
            </div>
            <pendo-form-item
                v-if="!launched"
                :rules="urlValidationRule"
                prop="url">
                <pendo-input
                    v-model="model.url"
                    placeholder="https://acme.io" />
            </pendo-form-item>
            <div v-if="launched && error">
                We were unable to launch the preview. Please check that Pendo is installed at the location you entered.
            </div>
            <pendo-alert
                v-if="isResourceCenter"
                type="info">
                <slot>
                    At least one guide must be set to Public and be present on a page a user is actively on for the
                    onboarding or guides list module to be visible.
                    <a
                        :href="RC_PREVIEW_ARTICLE"
                        target="_blank">Learn more</a>.
                </slot>
            </pendo-alert>
        </div>
        <div class="pendo-modal__footer">
            <pendo-button
                v-if="launched"
                type="primary"
                theme="via"
                label="Close"
                @click="closeModal" />
            <pendo-button
                v-if="!launched"
                type="tertiary"
                theme="via"
                label="Cancel"
                @click="cancelLaunch" />
            <pendo-button
                v-if="!launched"
                :disabled="!isValidUrl"
                label="Launch Preview"
                theme="via"
                type="primary"
                @click="launchPreview" />
        </div>
    </pendo-form>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { PendoButton, PendoForm, PendoFormItem, PendoInput, PendoAlert } from '@pendo/components';
import { isValidUrl } from '@/utils/utils';
import { launchPreview } from '@/utils/preview';
import get from 'lodash/get';

const RC_PREVIEW_ARTICLE = 'https://adoptsupport.pendo.io/hc/en-us/articles/4405441318683';

export default {
    name: 'LaunchPreviewModal',
    components: {
        PendoAlert,
        PendoButton,
        PendoForm,
        PendoFormItem,
        PendoInput
    },
    props: {
        stepId: {
            type: String,
            required: true
        },
        guideId: {
            type: String,
            required: true
        },
        isResourceCenter: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            formRef: 'viaLaunchPreviewForm',
            model: {
                url: ''
            },
            launched: false,
            error: null,
            callValidate: false,
            urlValidationRule: [
                { required: true, message: 'Required', trigger: 'change' },
                { type: 'url', message: 'Please input a valid url starting with http:// or https://', trigger: 'blur' }
            ],
            RC_PREVIEW_ARTICLE
        };
    },
    computed: {
        ...mapState({
            subId: (state) => get(state, 'subscriptions.activeId'),
            designerUrl: (state) => state.designer.url
        }),
        ...mapGetters({
            isAdoptV2Sub: 'subscriptions/activeUsesV2Adopt',
            watermark: 'guides/watermark',
            guideById: 'guides/getGuideById',
            lastUrlByAppId: 'designer/lastUrlByAppId'
        }),
        rcOrGuideText () {
            return this.isResourceCenter ? 'Resource Center' : 'guide';
        },
        bodyText () {
            const notLaunched = `Enter the URL where you’d like to see your ${
                this.rcOrGuideText
            }. This will open a new tab and launch the ${this.rcOrGuideText} preview.`;
            const launched = `You should see a new tab with the ${this.rcOrGuideText} preview and this ${
                this.rcOrGuideText
            } overlaying the URL you specified.`;

            return this.launched ? launched : notLaunched;
        },
        isValidUrl () {
            return isValidUrl(this.model.url);
        },
        isWatermarkDisabled () {
            return get(this, 'watermark.state', '') === 'disabled';
        },
        appId () {
            const guide = this.guideById(this.guideId);

            return get(guide, 'appId');
        }
    },
    created () {
        this.model.url = this.lastUrlByAppId(this.appId) || this.designerUrl;
    },
    methods: {
        ...mapActions({
            updateDesignerUrl: 'designer/updateDesignerUrl'
        }),
        closeModal () {
            this.$modal.hide('via-modal');
        },
        async launchPreview () {
            this.updateDesignerUrl({ url: this.model.url, appId: this.appId });
            const headers = { 'x-adopt-v2': true };
            const guideType = this.isResourceCenter ? 'resourcecenter' : 'guide';
            const guideUrl = `/api/s/${this.subId}/${guideType}/${this.guideId}/preview`;

            const agentConfig = {
                stepId: this.stepId,
                guideId: this.guideId,
                isResourceCenter: this.isResourceCenter,
                guideUrl,
                headers: this.isAdoptV2Sub ? headers : null,
                subscriptionId: this.subId
            };

            if (!this.isWatermarkDisabled && this.watermark) {
                agentConfig.watermarkConfig = {
                    stepId: this.watermark.steps[0].id,
                    guideId: this.watermark.id,
                    isResourceCenter: false,
                    guideUrl: `/api/s/${this.subId}/guide/${this.watermark.id}/preview`,
                    subscriptionId: this.subId
                };
            }

            try {
                this.error = false;
                this.launched = true;
                await launchPreview(this.model.url, agentConfig);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                this.error = true;
            }
        },
        cancelLaunch () {
            this.closeModal();
        },
        handleValidForm () {
            this.callValidate = false;
        },
        handleInvalidForm () {
            this.callValidate = false;
        },
        validateForm () {
            this.callValidate = true;
        }
    }
};
</script>

<style lang="scss">
.launch-preview-modal--message {
    margin-bottom: 16px;
}
</style>
